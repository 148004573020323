import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { Typography } from '@v2/components/typography/typography.component';
import {
  ChecklistItemDto,
  CreateChecklistItemDto,
  RelativeAssignment,
  RelativeAssignmentValues,
  UpdateChecklistItemDto,
} from '@v2/feature/task/subfeature/checklist/checklist-item.dto';
import { LaunchFormDrawer } from '@v2/feature/task/subfeature/checklist/components/launch-form-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useLocation, useParams } from 'react-router-dom';

import type { ChecklistDto } from '@v2/feature/task/subfeature/checklist/checklist.dto';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { TooltipCell } from '@/v2/components/table/components/tooltip-cell.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ChecklistItemFormModal } from '@/v2/feature/task/subfeature/checklist/components/checklist-item-form-modal.component';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface ChecklistItemsCardProps {
  readonly checklist: ChecklistDto;
  readonly refresh: () => void;
  readonly setIsAddTaskModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly isAddTaskModalOpen: boolean;
}

const iconSize = { width: 14, height: 14 } as const;

const ChecklistItemsCard = ({
  checklist,
  refresh,
  setIsAddTaskModalOpen,
  isAddTaskModalOpen,
}: ChecklistItemsCardProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const location = useLocation();
  const locationState = location.state as { openNewTaskModal: boolean };
  const [taskToEdit, setTaskToEdit] = useState<ChecklistItemDto | null>(null);
  const [searchInput, setSearchInput] = useState<string>('');
  const [checklistItems, setChecklistItems] = useState<readonly ChecklistItemDto[]>([...checklist.checklistItems]);

  const checklistId = checklist.id;

  const getOffSetNumberBasedOnUnit = (duration: number, unit: string): number => {
    let offset = 0;
    switch (unit) {
      case 'Days':
        offset = duration;
        break;

      case 'Weeks':
        offset = duration * 7;
        break;

      case 'Months':
        offset = duration * 30;
        break;

      default:
        break;
    }

    return offset;
  };

  const handleNewTask = useCallback(() => {
    setIsAddTaskModalOpen(true);
    setTaskToEdit(null);
  }, [setIsAddTaskModalOpen]);

  useEffect(() => {
    if (locationState?.openNewTaskModal) handleNewTask();
  }, [handleNewTask, locationState]);

  useEffect(() => {
    const filteredList = [...checklist.checklistItems].filter((task) =>
      task.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setChecklistItems(filteredList);
  }, [searchInput, checklist.checklistItems]);

  const [showMessage] = useMessage();
  const deleteChecklistItem = useCallback(
    async (checklistItemId: number): Promise<void> => {
      try {
        await TaskAPI.deleteChecklistItemById(checklist.id, checklistItemId);
        showMessage(polyglot.t('ChecklistItemsCard.successMessages.delete'), 'success');
        await refresh();
      } catch (error) {
        showMessage(`${polyglot.t('ChecklistItemsCard.errorMessages.delete')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [polyglot, checklist.id, refresh, showMessage]
  );
  const columns = useMemo<ColumnDef<ChecklistItemDto, ChecklistItemDto>[]>(
    () => [
      {
        id: 'name',
        header: () => polyglot.t('ChecklistItemsCard.tasks'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.name),
        cell: ({ row: { original } }) => (
          <TooltipCell
            title={original.name ?? ''}
            maxWidth={260}
            cell={<div>{original.name ? <Box>{original.name}</Box> : <EmptyCell />}</div>}
          />
        ),
      },
      {
        id: 'assignedToPlaceholder',
        header: () => polyglot.t('ChecklistItemsCard.assignedToPlaceholder'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        cell: ({ row: { original } }) => (
          <TooltipCell
            title={original.assignedToPlaceholder ?? ''}
            maxWidth={260}
            cell={
              <div>
                <div>
                  {original.assignedUser?.userId && (
                    <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
                      <UserAvatar userId={original.assignedUser.userId} size="xxsmall" />
                      <div>{original.assignedUser?.firstName + ' ' + original?.assignedUser?.lastName}</div>
                    </Typography>
                  )}
                </div>
                {original.assignedToPlaceholder !== RelativeAssignmentValues.someoneElse && (
                  //@ts-ignore
                  <Box>{RelativeAssignment[original.assignedToPlaceholder]}</Box>
                )}
              </div>
            }
          />
        ),
      },
      {
        id: 'requestedForPlaceholder',
        header: () => polyglot.t('ChecklistItemsCard.requestedForPlaceholder'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        cell: ({ row: { original } }) => (
          <TooltipCell
            title={original.requestedForPlaceholder ?? ''}
            maxWidth={260}
            cell={
              <div>
                <div>
                  {original.requestedFor?.userId && (
                    <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: spacing.m5 }}>
                      <UserAvatar userId={original.requestedFor.userId} size="xxsmall" />
                      <div>{original.requestedFor?.firstName + ' ' + original?.requestedFor?.lastName}</div>
                    </Typography>
                  )}
                </div>
                {original.requestedForPlaceholder !== RelativeAssignmentValues.someoneElse && (
                  //@ts-ignore
                  <Box>{RelativeAssignment[original.requestedForPlaceholder]}</Box>
                )}
              </div>
            }
          />
        ),
      },
      {
        id: 'dueDateOffsetDuration',
        header: () => polyglot.t('ChecklistItemsCard.dueDateOffsetDuration'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortNumeric(a, b, (item) =>
            getOffSetNumberBasedOnUnit(Number(item.dueDateOffsetDuration), item.dueDateOffsetUnit)
          ),
        cell: ({ row: { original } }) => (
          <TooltipCell
            title={String(original.dueDateOffsetDuration) ?? ''}
            maxWidth={260}
            cell={
              <div>
                {original.dueDateOffsetDuration !== 0 && (
                  <Box>
                    {original.dueDateOffsetDuration > 0
                      ? polyglot.t('ChecklistItemsCard.afterDate', {
                          count: Math.abs(original.dueDateOffsetDuration),
                          unit: original.dueDateOffsetUnit?.toLowerCase() ?? 'days',
                        })
                      : polyglot.t('ChecklistItemsCard.beforeDate', {
                          count: Math.abs(original.dueDateOffsetDuration),
                          unit: original.dueDateOffsetUnit?.toLowerCase() ?? 'days',
                        })}{' '}
                  </Box>
                )}
                {original.dueDateOffsetDuration === 0 && <Box>{polyglot.t('ChecklistItemsCard.onChecklistDate')}</Box>}
              </div>
            }
          />
        ),
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        maxSize: 100,
        minSize: 80,
        cell: ({ row: { original } }) => {
          const task = original as ChecklistItemDto;
          return (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: spacing.m5 }}>
              <IconButton
                onClick={() => {
                  deleteChecklistItem(original.id);
                }}
                sx={tableIconButtonSx}
              >
                <Trash {...iconSize} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setTaskToEdit(task);
                  setIsAddTaskModalOpen(true);
                }}
                sx={tableIconButtonSx}
              >
                <Edit {...iconSize} />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [polyglot, setIsAddTaskModalOpen, deleteChecklistItem]
  );

  const closeTaskFormModal = (): void => {
    setTaskToEdit(null);
    refresh();
    setIsAddTaskModalOpen(false);
  };

  const addTaskToChecklist = async (data: CreateChecklistItemDto): Promise<void> => {
    try {
      const newChecklistItem: CreateChecklistItemDto = {
        name: data.name,
        description: data.description,
        assignedToPlaceholder: data.assignedToPlaceholder,
        requestedForPlaceholder: data.requestedForPlaceholder,
        assignedUserId: data.assignedUserId,
        assignedUserIds: data.assignedUserIds,
        requestedForId: data.requestedForId,
        dueDate: data.dueDate,
        userAssignWithPlaceholder: data.userAssignWithPlaceholder,
        dueDateOffsetDuration: data.dueDateOffsetDuration,
        dueDateOffsetUnit: data.dueDateOffsetUnit,
        attachmentValues: data.attachmentValues,
        assignedToCustomRule: data.assignedToCustomRule,
      };
      await TaskAPI.addChecklistItemToChecklist(checklistId, newChecklistItem);
      showMessage(polyglot.t('ChecklistItemsCard.successMessages.add'), 'success');
      await refresh();
      setTaskToEdit(null);
    } catch (error) {
      showMessage(`${polyglot.t('ChecklistItemsCard.errorMessages.add')}. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const updateChecklistItem = async (data: ChecklistItemDto): Promise<void> => {
    const checklistItemId = data.id;
    if (!checklist?.id || !checklistItemId) return;
    try {
      const update: UpdateChecklistItemDto = {
        name: data.name,
        description: data.description,
        assignedToPlaceholder: data.assignedToPlaceholder,
        requestedForPlaceholder: data.requestedForPlaceholder,
        assignedUserId: data.assignedUserId,
        assignedUserIds: data.assignedUserIds,
        requestedForId: data.requestedForId,
        dueDate: data.dueDate,
        userAssignWithPlaceholder: data.userAssignWithPlaceholder,
        dueDateOffsetDuration: data.dueDateOffsetDuration,
        dueDateOffsetUnit: data.dueDateOffsetUnit,
        attachmentValues: data.attachmentValues,
        assignedToCustomRule: data.assignedToCustomRule,
      };

      await TaskAPI.updateChecklistItem(checklist.id, checklistItemId, update);

      showMessage('Task updated successfully', 'success');
      await refresh();
      setTaskToEdit(null);
    } catch (error) {
      showMessage(`Could not update checklist item. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const handleChecklistItemFormSubmit = async (data: Partial<ChecklistItemDto>) => {
    if (data.id) {
      await updateChecklistItem(data as ChecklistItemDto);
    } else {
      await addTaskToChecklist(data as CreateChecklistItemDto);
    }
  };

  return (
    <>
      {checklist.checklistItems.length > 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TableSearch
              query={searchInput}
              handleChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
            <ButtonComponent
              id="add_task_button"
              onClick={() => handleNewTask()}
              sizeVariant="small"
              colorVariant="primary"
            >
              {polyglot.t('ChecklistItemsCard.new')}
            </ButtonComponent>
          </Box>
          <Box sx={{ ...spacing.mt20 }}>
            <BasicTable
              rowData={[...checklistItems]}
              columnData={columns}
              initialSort={[{ id: 'dueDateOffsetDuration', desc: true }]}
            />
          </Box>
        </>
      )}

      {/* ADD MODAL */}
      <DrawerModal isOpen={isAddTaskModalOpen} setIsOpen={setIsAddTaskModalOpen}>
        <ChecklistItemFormModal
          onClose={closeTaskFormModal}
          formData={taskToEdit}
          action={handleChecklistItemFormSubmit}
          deleteAction={deleteChecklistItem}
        />
      </DrawerModal>
    </>
  );
};

export const TasksChecklistOverview = (): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const [checklist, setChecklist] = useState<ChecklistDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState<boolean>(false);
  const [launchChecklistOpen, setLaunchChecklistOpen] = useState<boolean>(false);

  const [showMessage] = useMessage();

  const params = useParams<{ checklistId: string }>();
  const checklistId = Number(params.checklistId);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const checklist = await TaskAPI.getChecklistById(checklistId);
        setChecklist(checklist);
      } catch (error) {
        showMessage(
          `${polyglot.t('TasksChecklistOverview.errorMessages.retrieve')}. ${nestErrorMessage(error)}`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [polyglot, checklistId, showMessage]);

  const refresh = async () => {
    const checklist = await TaskAPI.getChecklistById(checklistId);
    setChecklist(checklist);
  };

  return (
    <RootStyle>
      <TopHeader
        title={checklist?.name ?? 'Checklist'}
        showAction={true}
        actions={
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            disabled={!checklist?.checklistItems || checklist?.checklistItems.length === 0}
            onClick={() => setLaunchChecklistOpen(true)}
          >
            {polyglot.t('General.launch')}
          </ButtonComponent>
        }
        showBack={true}
      />
      <ContentWrapper loading={loading} sx={{ ...spacing.pt20 }}>
        <Box>
          {checklist && checklist?.checklistItems?.length < 1 && (
            <Box sx={{ maxWidth: '600px' }}>
              <Typography variant="title2">{polyglot.t('TasksChecklistOverview.create')}</Typography>
              <Typography variant="caption" sx={{ mt: spacing.m5 }}>
                {polyglot.t('TasksChecklistOverview.createTaskList')}
              </Typography>

              <Box sx={{ ...spacing.mt20 }}>
                <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsAddTaskModalOpen(true)}>
                  {polyglot.t('TasksChecklistOverview.newTask')}
                </ButtonComponent>
              </Box>
            </Box>
          )}

          {checklist && (
            <ChecklistItemsCard
              checklist={checklist}
              refresh={refresh}
              setIsAddTaskModalOpen={setIsAddTaskModalOpen}
              isAddTaskModalOpen={isAddTaskModalOpen}
            />
          )}

          <DrawerModal isOpen={launchChecklistOpen} setIsOpen={setLaunchChecklistOpen}>
            {checklist ? (
              <LaunchFormDrawer
                checklist={checklist}
                onClose={async () => {
                  setLaunchChecklistOpen(false);
                  await refresh();
                }}
              />
            ) : (
              <></>
            )}
          </DrawerModal>
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
