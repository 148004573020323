import { useCallback, useMemo, useState } from 'react';

import { Box, Stack, SwipeableDrawer } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { getCountryNameForCode } from '@/v2/feature/payments/payments.util';
import { formatPayPeriod } from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { SetupPayrollDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-intro/set-up-payroll-drawer.component';
import { PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { caseInsensitiveSort, filterByTextSearch } from '@/v2/util/array.util';

export const PayrollCompanySettingsPayrollList = () => {
  const { polyglot } = usePolyglot();
  const [searchInput, setSearchInput] = useState('');
  const routerHistory = useHistory();
  const [showPayrollSetupDrawer, setShowPayrollSetupDrawer] = useState(false);

  const { data: payrolls, isLoading } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });

  const sortedPayrolls = useMemo(
    () => payrolls?.items.sort((a, b) => caseInsensitiveSort(a, b, (entry) => entry.entity.legalName)) ?? [],
    [payrolls]
  );

  const filteredPayrolls = useMemo(
    () => filterByTextSearch(searchInput, sortedPayrolls, (payroll) => [payroll.entity.legalName]),
    [searchInput, sortedPayrolls]
  );

  const goToPayrollSettings = useCallback(
    (payrollId: number) => {
      routerHistory.push(generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId }));
    },
    [routerHistory]
  );

  const launchNewPayroll = useCallback(() => {
    setShowPayrollSetupDrawer(true);
  }, []);

  return (
    <ContentWrapper loading={payrolls === undefined}>
      <SettingsSectionContent
        title={polyglot.t('CompanyPayroll.salary')}
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent onClick={() => launchNewPayroll()} sizeVariant="small" colorVariant="primary">
            {polyglot.t('PayrollSettings.addPayroll')}
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          loadingAll={isLoading}
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value)} />,
                },
                {
                  type: SectionItemType.Component,
                  value: filteredPayrolls ? (
                    <Box sx={flexContainerStyle}>
                      {(filteredPayrolls || []).map((item) => {
                        return (
                          <SettingsItemCard
                            key={item.id}
                            title={
                              <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
                                <PayrollLogoZelt height="1.2em" width="1.2em" padding=".25em" />
                                <Typography variant="caption">Zelt</Typography>
                              </Stack>
                            }
                            boxSx={responsiveCardStyle}
                            contentItemsSets={[
                              {
                                name: getCountryNameForCode(item.entity.address?.countryCode) ?? '–',
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                              {
                                name: item.entity.legalName,
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                              {
                                name: item.schedule ? formatPayPeriod(item.schedule.payPeriod, polyglot) : '–',
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                            ]}
                            boxAction={() => goToPayrollSettings(item.id)}
                          />
                        );
                      })}
                    </Box>
                  ) : (
                    <Typography variant="caption" color="Grey">
                      No payroll found
                    </Typography>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>

      <SwipeableDrawer
        anchor="right"
        open={showPayrollSetupDrawer}
        onOpen={() => {}}
        onClose={() => setShowPayrollSetupDrawer(false)}
      >
        {showPayrollSetupDrawer && <SetupPayrollDrawer />}
      </SwipeableDrawer>
    </ContentWrapper>
  );
};
