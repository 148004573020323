import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_REQUEST_FORMS_EDIT_ROUTE, SETTINGS_REQUEST_FORMS_NEW_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { RequestFormsAPI, RequestFormsEndpoints } from '@/v2/feature/requests/request-forms.api';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { pluralText } from '@/v2/util/string.util';

export const RequestFormsPage = () => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const [checkingRequestCreation, setCheckingRequestCreation] = useState<boolean>(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const { data: templates, mutate: refreshForms, isValidating } = useApiClient(
    RequestFormsEndpoints.getFormTemplates(),
    {
      suspense: false,
    }
  );

  const canRequestBeCreatedThenForwardOrDeny = async () => {
    try {
      setCheckingRequestCreation(true);
      // check if request can be created
      await RequestFormsAPI.canRequestBeCreatedOnPlan();
      routerHistory.push(SETTINGS_REQUEST_FORMS_NEW_ROUTE);
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    } finally {
      setCheckingRequestCreation(false);
    }
    return true;
  };

  const filteredForms = useMemo(() => {
    if (!templates || templates === null) return [];
    if (!searchInput) return templates;
    return templates.filter((tem) => tem.name.toLowerCase().includes(searchInput.toLowerCase()));
  }, [templates, searchInput]);

  return (
    <ContentWrapper>
      <SettingsSectionContent
        title={polyglot.t('RequestFormsPage.requestForms')}
        contentWidth="100%"
        headerWidth="100%"
        loading={isValidating}
        buttons={[
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            onClick={() => canRequestBeCreatedThenForwardOrDeny()}
            loading={checkingRequestCreation}
          >
            {polyglot.t('RequestFormsPage.new')}
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <TableSearch
                      query={searchInput}
                      handleChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value:
                    filteredForms?.length === 0 ? (
                      <Typography variant="caption" color="Grey">
                        {polyglot.t('RequestFormsPage.noFormToShow')}
                      </Typography>
                    ) : (
                      <Box sx={flexContainerStyle}>
                        {(filteredForms || []).map((item) => (
                          <SettingsItemCard
                            key={item.id}
                            title={item.name}
                            description={item.description}
                            boxSx={responsiveCardStyle}
                            boxAction={(event) => {
                              routerHistory.push(generatePath(SETTINGS_REQUEST_FORMS_EDIT_ROUTE, { formId: item.id }));
                              event?.stopPropagation();
                            }}
                            contentItemsSets={[
                              {
                                name: `${item.layout.items.length || 0} item${pluralText(
                                  item.layout.items.length || 0
                                )}`,
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                            ]}
                            advanceActionOptions={[
                              {
                                label: 'Delete',
                                icon: <Trash />,
                                handler: async (e) => {
                                  try {
                                    await RequestFormsAPI.deleteFormTemplate(item.id);
                                    refreshForms?.(filteredForms?.filter((t) => t.id !== item.id));
                                    e?.stopPropagation();
                                  } catch (error) {
                                    showMessage(
                                      polyglot.t('ErrorMessages.somethingWentWrong', {
                                        errorMessage: nestErrorMessage(error),
                                      }),
                                      'error'
                                    );
                                  }
                                },
                              },
                            ]}
                          />
                        ))}
                      </Box>
                    ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
      <UpgradeToProModal
        isOpen={upgradeModalOpen}
        setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
        planName={PlanNames.PEOPLE_PRO}
        messageSuffix="proGeneric"
      />
    </ContentWrapper>
  );
};
