import { Box, Typography } from '@mui/material';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { AppleDevices, DeviceModelOs, DevicePossessionType } from '@v2/feature/device/device.interface';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const NotEnrollledRisk = ({
  enrollPath,
  devicePossession,
}: {
  readonly enrollPath: string;
  readonly devicePossession: DevicePossessionDto;
}) => {
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const handleEnol = async () => {
    if (devicePossession?.possessionType === DevicePossessionType.User) {
      if (
        devicePossession &&
        devicePossession.device &&
        devicePossession.device.os &&
        !AppleDevices.includes(devicePossession.device?.os?.toLowerCase() as DeviceModelOs)
      ) {
        routerHistory.push(
          generatePath(enrollPath, {
            userId: devicePossession?.possessionId,
            devicePossessionId: devicePossession?.id,
          })
        );
      }
    } else {
      showMessage('Can not enrol device that is not owned by an user.', 'error');
    }
  };
  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
        <Mistake width={20} height={20} fill={themeColors.GreyMiddle} />
        <Typography sx={themeFonts.title2}>Device not enroled</Typography>
      </Box>

      <Typography sx={{ ...themeFonts.caption, mt: spacing.m10, color: themeColors.DarkGrey }}>
        Download Zelt MDM and secure your sensitive data, recover lost devices and configure settings. See your device
        status live on Zelt and manage it remotely when needed.
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', ...spacing.mt20, gap: spacing.g5, maxWidth: '35%' }}>
        <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={async () => await handleEnol()} fullWidth>
          Download
        </ButtonComponent>
        <ButtonComponent
          sizeVariant="small"
          colorVariant="secondary"
          fullWidth
          onClick={() => window.open('https://zelt.app/blog/protect-your-device-with-zelt-mdm', '_blank')}
        >
          Learn more
        </ButtonComponent>
      </Box>
    </Box>
  );
};
