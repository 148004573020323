import React, { useCallback, useMemo, useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { PayRunDto, PayRunEntryDto } from '@v2/feature/payroll/payroll.dto';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { UserPayrollStatusCell } from '@/v2/feature/payroll/components/user-payroll-status-cell.component';
import { getUserStatusFromPayrunEntry } from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { SalarySummaryDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/salary-summary-drawer.component';
import {
  CurrencyWithDiff,
  ValueWithDiff,
} from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component';
import { PayrollLocalEndpoints } from '@/v2/feature/payroll/payroll-local.api';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { filterByTextSearch, sum } from '@/v2/util/array.util';

type PayrunSalaryTableProps = {
  entries: PayRunEntryDto[];
  localPayRun: PayRunDto;
  previousEntries: PayRunEntryDto[];
  searchQuery?: string;
  sx?: SxProps<Theme>;
  stickyHeader?: boolean;
};

export const PayrunSummaryTable = ({
  localPayRun,
  entries,
  previousEntries,
  searchQuery,
  sx,
  stickyHeader,
}: PayrunSalaryTableProps) => {
  const { getCachedUserById } = useCachedUsers();
  const [salarySummary, setSalarySummary] = useState<{ userId: number; payrunEntry: PayRunEntryDto }>();
  const { data: customPayCodes } = useApiClient(PayrollLocalEndpoints.getPayrunPayCodes(localPayRun.id), {
    suspense: false,
  });

  const [summaryTotals, previousSummaryTotals] = useMemo(() => {
    const calculateTotals = (entries: PayRunEntryDto[]) => ({
      income: sum(entries, (e) => e.income ?? 0),
      deductions: sum(entries, (e) => e.deductions ?? 0),
      takeHomePay: sum(entries, (e) => e.takeHomePay ?? 0),
      employerCost: sum(entries, (e) => e.employerCost ?? 0),
    });
    return [
      calculateTotals(entries),
      previousEntries.length
        ? calculateTotals(previousEntries)
        : ({} as Record<keyof ReturnType<typeof calculateTotals>, undefined>),
    ];
  }, [entries, previousEntries]);

  const getUserDisplayName = useCallback(
    (userId: number) => {
      const user = getCachedUserById(userId);
      if (user) return UserCell.getDisplayedName(user);
      return `(User ${userId})`;
    },
    [getCachedUserById]
  );

  const filteredEntries = useMemo(() => {
    return filterByTextSearch(searchQuery, entries, (item) => [getUserDisplayName(item.userId)]);
  }, [entries, getUserDisplayName, searchQuery]);

  const columnData = useMemo<ColumnDef<PayRunEntryDto, PayRunEntryDto>[]>(() => {
    const previousById = new Map<string, PayRunEntryDto>(previousEntries.map((item) => [item.employee.id, item]));
    return [
      {
        id: 'employee',
        header: () => 'Employee',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getUserDisplayName(item.userId)),
        cell: (c) => <UserCell userId={c.row.original.userId} />,
        footer: () => 'Total',
        size: 100,
      },
      {
        id: 'income',
        header: () => 'Income',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.income ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={previousById.get(c.getValue().employee.id)}
            getValue={(item) => item.income ?? 0}
          />
        ),
        footer: () => (
          <CurrencyWithDiff currentValue={summaryTotals.income} previousValue={previousSummaryTotals.income} />
        ),
        size: 60,
      },
      {
        id: 'deductions',
        header: () => 'Deductions',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.deductions ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={previousById.get(c.getValue().employee.id)}
            getValue={(item) => item.deductions ?? 0}
          />
        ),
        footer: () => (
          <CurrencyWithDiff currentValue={summaryTotals.deductions} previousValue={previousSummaryTotals.deductions} />
        ),
        size: 80,
      },
      {
        id: 'take-home',
        header: () => 'Take home pay',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.takeHomePay ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={previousById.get(c.getValue().employee.id)}
            getValue={(item) => item.takeHomePay ?? 0}
          />
        ),
        footer: () => (
          <CurrencyWithDiff
            currentValue={summaryTotals.takeHomePay}
            previousValue={previousSummaryTotals.takeHomePay}
          />
        ),
        size: 100,
      },
      {
        id: 'employer-cost',
        header: () => 'Employer cost',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.employerCost ?? 0),
        cell: (c) => (
          <ValueWithDiff
            current={c.getValue()}
            previous={previousById.get(c.getValue().employee.id)}
            getValue={(item) => item.employerCost ?? 0}
          />
        ),
        footer: () => (
          <CurrencyWithDiff
            currentValue={summaryTotals.employerCost}
            previousValue={previousSummaryTotals.employerCost}
          />
        ),
        size: 100,
      },
      {
        id: 'status',
        header: () => 'Status',
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getUserStatusFromPayrunEntry(item).label),
        cell: (c) => {
          return <UserPayrollStatusCell status={getUserStatusFromPayrunEntry(c.row.original)} />;
        },
        size: 60,
      },
    ];
  }, [previousEntries, summaryTotals, previousSummaryTotals, getUserDisplayName]);

  return (
    <>
      <Box sx={{ display: 'flex', flexFlow: 'column', ...sx }}>
        <BasicTable
          rowData={filteredEntries}
          columnData={columnData}
          hidePagination
          showFooter
          rowClick={(row) => {
            setSalarySummary({ userId: row.original.userId, payrunEntry: row.original });
          }}
          stickyHeader={stickyHeader}
        />
      </Box>
      <SalarySummaryDrawer
        userId={salarySummary?.userId}
        payrunEntry={salarySummary?.payrunEntry}
        onClose={() => setSalarySummary(undefined)}
        customPayCodes={customPayCodes ?? []}
      />
    </>
  );
};
