import { ApproverStep } from '@v2/feature/approval-rule/approval-rule.interface';
import { AttendanceDto, AttendanceScheduleDto, AttendanceSettingsDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceShiftDto } from '@v2/feature/attendance/subfeatures/attendance-shift/attendance-shift.dto';
import { UserCustomBenefitRequestType } from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { UserCustomBenefitRequestStatus } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DeviceTransitStatus } from '@v2/feature/device/device.interface';
import { ContractorInvoiceStatus, ExpenseStatus } from '@v2/feature/payments/payments.interface';
import { CurrencyShort } from '@v2/infrastructure/currency/currency.interface';

import { AbsenceDto, AbsencePolicyDto } from '@/v2/feature/absence/absence.dto';
import {
  AbsenceBalanceCarryOver,
  AbsenceLengthUnit,
  AbsencePolicyAllowanceType,
  AbsenceStatus,
  ProRatedAbsenceAllowanceBreakdown,
} from '@/v2/feature/absence/absence.interface';
import { ScheduleTrackingTimesheetType } from '@/v2/feature/attendance/attendance.interface';
import { ViewSettingEnum } from '@/v2/feature/calendar/calendar.interface';
import { ReviewerTypes } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { ReviewEntry } from '@/v2/feature/growth/reviews/interfaces/review-entry.interface';
import { SurveyEntry } from '@/v2/feature/growth/surveys/interfaces/survey-entry.interface';
import { SiteDto } from '@/v2/feature/site/site.dto';
import { ContractObject } from '@/v2/feature/templates/templates.interface';
import { MissingField } from '@/v2/feature/user/user.interface';

export type TaskTodo = {
  type: DomainTypes.Tasks;
  kind: KindTypes.IncompleteTasks;
  id: number;
  name: string;
  dueDate: string;
  userId: number;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverSteps?: ApproverStep[]; // undefined now
  approverIds: number[];
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type AbsenceTodo = {
  type: DomainTypes.Time;
  kind: KindTypes.PendingRequest;
  id: number;
  userId: number;
  start: string;
  end: string | null;
  cancellationRequested: boolean;
  updatedAt: Date;
  morningOnly: boolean;
  afternoonOnly: boolean;
  startHour: string | null;
  endHour: string | null;
  policyName: string | null;
  color: string;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverSteps?: ApproverStep[];
  approverIds?: number[];
  approvedByIds: number[];
  rejectedByIds: number[];
  isPublicHoliday?: boolean;
  deletionRequestId?: number | null;
  canRequestPHDeletion?: boolean;
  canDeletePHCancellation?: boolean;
};

export type PHDeletionTodo = {
  type: DomainTypes.Time;
  kind: KindTypes.PendingPHCancellation;
  id: number;
  userId: number;
  start: string;
  end: string | null;
  updatedAt: Date;
  holidayName: string | null;
  status: AbsenceStatus;
  createdBy: number;
  createdAt: Date;
  policyId: number;
  workdayCount: number;
  totalLength: number;
  policy: Pick<AbsencePolicyDto, 'name' | 'allowanceType'>;
  approverSteps: ApproverStep[];
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals everywhere - should not be used
  approverIds?: number[]; // TODO: This should be entirely deleted once we'll use the new approvals everywhere - should not be used
  approvedByIds: number[];
  rejectedByIds: number[];
  deletionRequestId: number | null;
  canApprove: boolean;
  canReject: boolean;
  canForceApprove: boolean;
  canForceReject: boolean;
  canRequestPHDeletion: boolean;
  canDeletePHCancellation: boolean;
};

export type AppRequestTodo = {
  type: DomainTypes.Apps;
  kind: KindTypes.PendingRequests;
  id: number;
  userId: number;
  appStub: string;
  appName: string;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type DeviceTransitTodo = {
  type: DomainTypes.Devices;
  kind: KindTypes.DeviceTransit;
  id: number;
  senderPossession: DevicePossessionDto;
  receiverPossession: DevicePossessionDto;
  userId: number;
  modelName: string;
  status: DeviceTransitStatus;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type DocumentTodo = {
  type: DomainTypes.Documents;
  kind: KindTypes.MissingDocuments;
  id: number;
  userId: number;
  name: string;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type ContractTodo = {
  type: DomainTypes.Contracts;
  kind: KindTypes.PendingContracts;
  id: number;
  userId: number;
  updatedAt: string;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
  name: string;
  contract: ContractObject;
};

export type RequestFormTodo = {
  type: DomainTypes.Requests;
  kind: KindTypes.SubmittedRequestForm;
  id: number;
  userId: number;
  formName: string;
  updatedAt: Date;
  status: string;
  approverId?: number; // not used anymore - should be deleted once everything is moved to new approval logic
  approverIds?: number[]; // not used anymore - should be deleted once everything is moved to new approval logic
  approverSteps: ApproverStep[];
  approvedByIds: number[];
  rejectedByIds: number[];
  canEdit: boolean;
  canDelete: boolean;
  canApprove: boolean;
  canReject: boolean;
  canForceApprove: boolean;
  canForceReject: boolean;
  canRequestCancellation: boolean;
  canApproveCancellation: boolean;
  canRejectCancellation: boolean;
};

export type ExpenseRequestTodo = {
  type: DomainTypes.Expenses;
  kind: KindTypes.ExpenseRequest;
  id: string;
  userId: number;
  userName: string;
  amount: number;
  status: ExpenseStatus;
  currency: CurrencyShort;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[];
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type InvoiceRequestTodo = {
  type: DomainTypes.Invoices;
  kind: KindTypes.InvoiceRequest;
  id: string;
  userId: number;
  userName: string;
  amount: number;
  status: ContractorInvoiceStatus;
  currency: CurrencyShort;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type BenefitRequestTodo = {
  type: DomainTypes.CustomBenefitRequests;
  kind: KindTypes.BenefitRequest;
  id: string;
  userId: number;
  userName: string;
  requestType: UserCustomBenefitRequestType;
  customBenefitId: number;
  userCustomBenefitId: number;
  status: UserCustomBenefitRequestStatus;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type PendingBenefitPaymentTodo = {
  type: DomainTypes.CustomBenefitPayments;
  kind: KindTypes.PendingBenefitPayment;
  id: string;
  userId: number;
  userName: string;
  customBenefitId: number;
  customBenefitName: string;
  userCustomBenefitId: number;
  amount: number;
  currency: CurrencyShort;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type ReviewsTodos = {
  type: DomainTypes.Reviews;
  kind: KindTypes.PendingReviews;
  id: string;
  cycleName: string;
  cycleId: string;
  dueDate: string;
  updatedAt: string;
  reviewType: ReviewerTypes;
  entry: ReviewEntry;
  userId: number;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type SurveysTodos = {
  type: DomainTypes.Surveys;
  kind: KindTypes.PendingSurveys;
  id: string;
  cycleName: string;
  cycleId: string;
  dueDate: string;
  updatedAt: string;
  entry: SurveyEntry;
  userId: number;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
};

export type SubmittedAttendanceTodos = {
  type: DomainTypes.Attendance;
  kind: KindTypes.SubmittedAttendance;
  id: number;
  userId: number;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
  year: number;
  weekNo: number;
  timesheetType: ScheduleTrackingTimesheetType;
  logDate: string;
};

export type UnfinishedAttendanceShiftsTodos = {
  type: DomainTypes.Shifts;
  kind: KindTypes.UnfinishedAttendance;
  id: number;
  userId: number;
  updatedAt: Date;
  approverId?: number; // TODO: This should be entirely deleted once we'll use the new approvals
  approverIds: number[];
  approverSteps?: ApproverStep[]; // undefined now
  approvedByIds: number[];
  rejectedByIds: number[];
  logDate: string;
};

export type OverdueTaskBigWidget = {
  domain: DomainTypes.Tasks;
  kind: KindTypes.IncompleteTasks;
  tasks: TaskTodo[];
  count: number;
};

export type OverdueTaskSmallWidget = {
  domain: DomainTypes.Tasks;
  kind: KindTypes.IncompleteTasks;
  count: number;
};

export type PendingRequestBigWidget = {
  domain: DomainTypes.Time;
  kind: KindTypes.PendingRequest;
  time: AbsenceTodo[];
  count: number;
};

export type PendingRequestSmallWidget = {
  domain: DomainTypes.Time;
  kind: KindTypes.PendingRequest;
  count: number;
};

export type PHDeletionPendingBigWidget = {
  domain: DomainTypes.Time;
  kind: KindTypes.PendingPHCancellation;
  time: PHDeletionTodo[];
  count: number;
};

export type PHDeletionPendingSmallWidget = {
  domain: DomainTypes.Time;
  kind: KindTypes.PendingPHCancellation;
  count: number;
};

export type SubmittedAttendanceBigWidget = {
  domain: DomainTypes.Attendance;
  kind: KindTypes.SubmittedAttendance;
  attendance: SubmittedAttendanceTodos[];
  count: number;
};

export type SubmittedAttendanceSmallWidget = {
  domain: DomainTypes.Attendance;
  kind: KindTypes.SubmittedAttendance;
  count: number;
};

export type UnfinishedAttendanceShiftsBigWidget = {
  domain: DomainTypes.Shifts;
  kind: KindTypes.UnfinishedAttendance;
  shifts: UnfinishedAttendanceShiftsTodos[];
  count: number;
};

export type UnfinishedAttendanceShiftsSmallWidget = {
  domain: DomainTypes.Shifts;
  kind: KindTypes.UnfinishedAttendance;
  count: number;
};

export type MissingDocumentsBigWidget = {
  domain: DomainTypes.Documents;
  kind: KindTypes.MissingDocuments;
  documents: DocumentTodo[];
  count: number;
};

export type DeviceTransitTaskBigWidget = {
  domain: DomainTypes.Devices;
  kind: KindTypes.DeviceTransit;
  devices: DeviceTransitTodo[];
  count: number;
};

export type CustomBenefitPaymentsBigWidget = {
  domain: DomainTypes.CustomBenefitPayments;
  kind: KindTypes.PendingBenefitPayment;
  customBenefitPayments: PendingBenefitPaymentTodo[];
  count: number;
};

export type ExpenseRequestBigWidget = {
  domain: DomainTypes.Expenses;
  kind: KindTypes.ExpenseRequest;
  expenses: ExpenseRequestTodo[];
  count: number;
};

export type ExpenseRequestSmallWidget = {
  domain: DomainTypes.Expenses;
  kind: KindTypes.ExpenseRequest;
  count: number;
};

export type InvoiceRequestBigWidget = {
  domain: DomainTypes.Invoices;
  kind: KindTypes.InvoiceRequest;
  invoices: InvoiceRequestTodo[];
  count: number;
};

export type InvoiceRequestSmallWidget = {
  domain: DomainTypes.Invoices;
  kind: KindTypes.InvoiceRequest;
  count: number;
};

export type BenefitRequestsBigWidget = {
  domain: DomainTypes.CustomBenefitRequests;
  kind: KindTypes.BenefitRequest;
  customBenefitRequests: BenefitRequestTodo[];
  count: number;
};

export type BenefitRequestsSmallWidget = {
  domain: DomainTypes.CustomBenefitRequests;
  kind: KindTypes.BenefitRequest;
  count: number;
};

export type CustomBenefitPaymentsSmallWidget = {
  domain: DomainTypes.CustomBenefitPayments;
  kind: KindTypes.PendingBenefitPayment;
  count: number;
};

export type MissingDocumentsSmallWidget = {
  domain: DomainTypes.Documents;
  kind: KindTypes.MissingDocuments;
  count: number;
};

export type PendingContractsBigWidget = {
  domain: DomainTypes.Contracts;
  kind: KindTypes.PendingContracts;
  contracts: ContractTodo[];
  count: number;
};

export type PendingContractsSmallWidget = {
  domain: DomainTypes.Contracts;
  kind: KindTypes.PendingContracts;
  count: number;
};

export type PendingRequestsBigWidget = {
  domain: DomainTypes.Apps;
  kind: KindTypes.PendingRequests;
  apps: AppRequestTodo[];
  count: number;
};

export type PendingRequestsSmallWidget = {
  domain: DomainTypes.Apps;
  kind: KindTypes.PendingRequests;
  count: number;
};

export type PendingReviewsBigWidget = {
  domain: DomainTypes.Reviews;
  kind: KindTypes.PendingReviews;
  reviews: ReviewsTodos[];
  count: number;
};

export type PendingReviewsSmallWidget = {
  domain: DomainTypes.Reviews;
  kind: KindTypes.PendingReviews;
  count: number;
};

export type PendingSurveysBigWidget = {
  domain: DomainTypes.Surveys;
  kind: KindTypes.PendingSurveys;
  surveys: SurveysTodos[];
  count: number;
};

export type PendingSurveysSmallWidget = {
  domain: DomainTypes.Surveys;
  kind: KindTypes.PendingSurveys;
  count: number;
};

export type SubmittedRequestsBigWidget = {
  domain: DomainTypes.Requests;
  kind: KindTypes.SubmittedRequestForm;
  requests: RequestFormTodo[];
  count: number;
};

export type SubmittedRequestsSmallWidget = {
  domain: DomainTypes.Requests;
  kind: KindTypes.SubmittedRequestForm;
  count: number;
};

export type TodoWidgetBigItems =
  | OverdueTaskBigWidget
  | PendingRequestBigWidget
  | PHDeletionPendingBigWidget
  | MissingDocumentsBigWidget
  | PendingContractsBigWidget
  | PendingRequestsBigWidget
  | PendingReviewsBigWidget
  | PendingSurveysBigWidget
  | SubmittedAttendanceBigWidget
  | UnfinishedAttendanceShiftsBigWidget
  | SubmittedRequestsBigWidget
  | CustomBenefitPaymentsBigWidget
  | ExpenseRequestBigWidget
  | BenefitRequestsBigWidget
  | DeviceTransitTaskBigWidget
  | InvoiceRequestBigWidget;

export type TodoWidgetSmallItems =
  | OverdueTaskSmallWidget
  | PendingRequestSmallWidget
  | PHDeletionPendingSmallWidget
  | MissingDocumentsSmallWidget
  | PendingContractsSmallWidget
  | PendingRequestsSmallWidget
  | PendingReviewsSmallWidget
  | PendingSurveysSmallWidget
  | SubmittedAttendanceSmallWidget
  | UnfinishedAttendanceShiftsSmallWidget
  | SubmittedRequestsSmallWidget
  | CustomBenefitPaymentsSmallWidget
  | ExpenseRequestSmallWidget
  | BenefitRequestsSmallWidget
  | InvoiceRequestSmallWidget;

// PROFILE WIDGET

interface MissingFieldsByDomain {
  readonly payroll?: MissingField[];
  readonly personal?: MissingField[];
}

export interface ProfileWidgetReach {
  isMissingInfo: boolean;
  missingInformation: MissingFieldsByDomain;
}

export interface ProfileWidget {
  profile: { isMissingInfo: boolean; missingInformation: MissingFieldsByDomain };
  type: WidgetTypes;
}

export interface TodoWidgetBigReach {
  personal: { todosItems: TodoWidgetBigItems[]; totalCount: number };
}

export interface TodoWidgetBig {
  todosBig: TodoWidgetBigReach;
}

export interface TodoWidgetSmallReach {
  personal: { todosItems: TodoWidgetSmallItems[]; totalCount: number };
}

export interface TodoWidgetSmall {
  todosSmall: TodoWidgetSmallReach;
}

export interface CalendarWidgetReach {
  time: { totalCount: 0; requests: AbsenceDto[] };
  view: ViewSettingEnum;
  fellowReportees: number[];
}

export interface CalendarWidgetBigWidget {
  calendarBig: CalendarWidgetReach;
  type: WidgetTypes;
}

export interface CalendarWidgetSmallWidget {
  calendarSmall: CalendarWidgetReach;
  type: WidgetTypes;
}

export interface AttendanceWidgetReach {
  companyAttendanceSettings: AttendanceSettingsDto | null;
  attendanceSchedule: AttendanceScheduleDto;
  currentWeekAttendance: AttendanceDto[];
}

export interface ShiftWidgetReach {
  companyAttendanceSettings: AttendanceSettingsDto | null;
  attendanceSchedule: AttendanceScheduleDto;
  todayShift: AttendanceShiftDto | null;
  userSite: SiteDto | null;
  isShiftWorker: boolean;
  currentWeekAttendance: AttendanceDto[];
}

export interface AttendanceWidget {
  attendance: AttendanceWidgetReach;
}

export interface ShiftWidget {
  shift: ShiftWidgetReach;
}

// TIME PLANNER WIDGET
export interface TimePlannerBigWidget {
  balancesBig: AbsenceTimeWidgetBreakdown[];
  type: WidgetTypes;
}

export interface TimePlannerSmallWidget {
  balancesSmall: AbsenceTimeWidgetBreakdown[];
  type: WidgetTypes;
}

interface TimeOffUnitsStatsByType {
  history: number;
  upcoming: number;
  total: number;
}

export interface AbsenceTimeWidgetBreakdown {
  holidayYear: number;
  lengthUnit: AbsenceLengthUnit;
  userAllowance: number | null;
  currentBalance: number | null;
  currentBalanceInDays: number | null;
  proRatedAbsenceAllowance: ProRatedAbsenceAllowanceBreakdown;
  holidayAccrued: number | null;
  totalAllowanceForCycle: number | null;
  oneOffAdjustment: number;
  unitsTaken: TimeOffUnitsStatsByType;
  carryOver: AbsenceBalanceCarryOver;
  policyName: string;
  policyId: number;
  policyColor: string;
  policyAllowanceType: AbsencePolicyAllowanceType;
  policyAllowance: number;
  policyOrder: number;
  isOnRegularSchedule: boolean;
  currentAverageWorkDayLength: number;
}

export enum WidgetTypes {
  USER_PROFILE_SMALL = 'USER_PROFILE_SMALL',
  USER_TODOS_BIG = 'USER_TODOS_BIG',
  USER_TODOS_SMALL = 'USER_TODOS_SMALL',
  USER_CALENDAR_BIG = 'USER_CALENDAR_BIG',
  USER_CALENDAR_SMALL = 'USER_CALENDAR_SMALL',
  USER_ATTENDANCE_BIG = 'USER_ATTENDANCE_BIG',
  USER_SHIFT_SMALL = 'USER_SHIFT_SMALL',
  USER_TIME_PLANNER_SMALL = 'USER_TIME_PLANNER_SMALL',
  USER_TIME_PLANNER_BIG = 'USER_TIME_PLANNER_BIG',
}

export const AllWidgetConfiguration = [
  {
    type: WidgetTypes.USER_PROFILE_SMALL,
    size: 'small',
    isDeletable: false,
    isStatic: true,
    isResizable: false,
    settings: null,
  },
  {
    type: WidgetTypes.USER_TODOS_BIG,
    size: 'big',
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
  },
  {
    type: WidgetTypes.USER_TODOS_SMALL,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: 'small',
  },
  {
    type: WidgetTypes.USER_CALENDAR_BIG,
    size: 'big',
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
  },
  {
    type: WidgetTypes.USER_CALENDAR_SMALL,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: 'small',
  },
  {
    type: WidgetTypes.USER_TIME_PLANNER_SMALL,
    size: 'small',
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
  },
  {
    type: WidgetTypes.USER_ATTENDANCE_BIG,
    size: 'big',
    isDeletable: false,
    isStatic: false,
    isResizable: false,
    settings: null,
  },
  {
    type: WidgetTypes.USER_TIME_PLANNER_BIG,
    size: 'big',
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
  },
];

export const AddWidgetConfigurationByType = [
  {
    type: WidgetTypes.USER_TODOS_SMALL,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: 'small',
  },
  {
    type: WidgetTypes.USER_CALENDAR_SMALL,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: 'small',
  },
  {
    type: WidgetTypes.USER_TIME_PLANNER_SMALL,
    isDeletable: true,
    isStatic: false,
    isResizable: true,
    settings: null,
    size: 'small',
  },
];

export const AddWidgetType = [
  WidgetTypes.USER_TODOS_SMALL,
  WidgetTypes.USER_CALENDAR_SMALL,
  WidgetTypes.USER_TIME_PLANNER_SMALL,
];

export type WidgetInformation =
  | ProfileWidget
  | CalendarWidgetBigWidget
  | CalendarWidgetSmallWidget
  | AttendanceWidget
  | ShiftWidget
  | TodoWidgetBig
  | TodoWidgetSmall
  | TimePlannerBigWidget
  | TimePlannerSmallWidget;

export type WidgetPreferences = {
  id: string;
  size: 'small' | 'big';
  type: WidgetTypes;
  isStatic: boolean;
  settings: Record<string, unknown> | null;
  isDeletable: boolean;
  isResizable: boolean;
};

export type WigdetData = {
  data: WidgetInformation[];
};

export enum UserNotificationType {
  LastDay = 'lastDay',
  Birthday = 'birthday',
  NewJoiner = 'newJoiner',
  WorkAnniversary = 'workAnniversary',
}

export type WishBody = {
  userId: number;
  type: UserNotificationType;
  note: string;
  gifSrc: string | null;
};

export type WidgetConfiguration = {
  order: string[];
  preference: WidgetPreferences[];
  companyWallpaper: {
    showWallpaper: boolean;
    wallpaper: string | null;
  };
};

export type ActionListItems =
  | TaskTodo
  | AbsenceTodo
  | PHDeletionTodo
  | AppRequestTodo
  | DocumentTodo
  | ContractTodo
  | ReviewsTodos
  | SurveysTodos
  | SubmittedAttendanceTodos
  | UnfinishedAttendanceShiftsTodos
  | RequestFormTodo
  | PendingBenefitPaymentTodo
  | ExpenseRequestTodo
  | BenefitRequestTodo
  | DeviceTransitTodo
  | InvoiceRequestTodo;

export enum KindTypes {
  IncompleteTasks = 'incomplete-tasks',
  PendingRequest = 'pending-request',
  PendingPHCancellation = 'pending-ph-cancellation',
  PendingRequests = 'pending-requests',
  MissingDocuments = 'missing-documents',
  PendingContracts = 'pending-contracts',
  PendingReviews = 'pending-reviews',
  PendingSurveys = 'pending-surveys',
  UnfinishedAttendance = 'unfinished-attendance',
  SubmittedAttendance = 'submitted-attendance',
  SubmittedRequestForm = 'submitted-request-form',
  PendingBenefitPayment = 'pending-benefit-payment',
  ExpenseRequest = 'expense-request',
  BenefitRequest = 'benefit-request',
  InvoiceRequest = 'invoice-request',
  DeviceTransit = 'device-transit',
}

export enum DomainTypes {
  Tasks = 'tasks',
  Time = 'time',
  Apps = 'apps',
  Documents = 'documents',
  Contracts = 'contracts',
  Reviews = 'reviews',
  Surveys = 'surveys',
  Attendance = 'attendance',
  Shifts = 'shifts',
  Requests = 'requests',
  CustomBenefitPayments = 'customBenefitPayments',
  Expenses = 'expenses',
  Invoices = 'invoices',
  CustomBenefitRequests = 'customBenefitRequests',
  Devices = 'devices',
}

export const DomainTypesArray: DomainTypes[] = Object.values(DomainTypes);
