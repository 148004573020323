import { useContext } from 'react';

import { Box, Divider, List, ListItem, ListItemText, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

import { OauthClientAPI, OauthClientEndpoints } from '@/api-client/oauth-client.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Scope } from '@/models/scopes.model';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { PersistentNotification } from '@/v2/feature/entity-import/wizard/components/user/persistent-notification.component';
import {
  APIDescriptions,
  APIScopeMapping,
  ReadAPIAction,
  WriteAPIAction,
} from '@/v2/feature/public-api/public-api.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

// const zeltLogo = '/app-icons-v2/images/zelt.png';

export const ConsentPage = () => {
  const qs = new URLSearchParams(useLocation().search);
  const noParamsAvailable = qs.size === 0;
  const clientId = qs.get('client_id');
  const responseType = qs.get('response_type');
  const stateValue = qs.get('state');
  const redirectUri = qs.get('redirect_uri');

  const { data: clientDetails, isValidating: loadingClient } = useApiClient(
    clientId && redirectUri && responseType === 'code'
      ? OauthClientEndpoints.getSpecificClientForCompanyForConsentStart(clientId, redirectUri)
      : { url: undefined },
    {
      suspense: false,
    }
  );

  const { getCachedUserById } = useCachedUsers();
  const [state] = useContext(GlobalContext);
  const currentCompanyId = state?.user?.company?.companyId;
  const [showMessage] = useMessage();

  const privateAppUsage = clientDetails?.companyId === currentCompanyId;

  const publishedAppForOtherCompanies =
    clientDetails?.published === true && clientDetails?.companyId !== currentCompanyId;

  const clientCreatedBy = clientDetails ? getCachedUserById(clientDetails.createdBy) : undefined;
  const appName = clientDetails ? `app (${clientDetails.name})` : 'app';
  const creatorName = clientCreatedBy
    ? `${clientCreatedBy.displayName} (${clientCreatedBy.emailAddress})`
    : 'an employee';

  const onConsentHandler = async () => {
    try {
      if (clientId && stateValue && redirectUri) {
        const response = await OauthClientAPI.validateAuthorizationAttempt(clientId, stateValue, redirectUri);
        if (response.status === 200) {
          const redirectUrl = response.headers['location'];
          window.location.href = redirectUrl; // Redirect to the provided URL from the Location header
        } else {
          showMessage(`Failed to authorize - Unexpected response status: ${response.status}`, 'error');
        }
      } else {
        showMessage(`Failed to authorize - Missing required params`, 'error');
      }
    } catch (error) {
      console.error(error);
      showMessage(`Failed to authorize: ${nestErrorMessage(error)}`, 'error');
    }
  };

  const onDenyHandler = () => {
    window.location.href = `${redirectUri}?error=access_denied`;
  };

  const AppLogoStack = ({ appLogo }: { appLogo: string }) => {
    const appLogoLink = `${state.publicURL}/${appLogo}`;
    return (
      <Stack direction="row" justifyContent={'center'}>
        <img width={'100px'} src={appLogoLink} alt={'zelt'} />
      </Stack>
    );
  };

  const WhatCanAppDo = ({ configuredScopes }: { configuredScopes: string[] }) => {
    const categorizeScopes = () => {
      const readDescriptions: string[] = [];
      const writeDescriptions: string[] = [];

      configuredScopes.forEach((scope) => {
        // Check read actions
        Object.entries(APIScopeMapping.read).forEach(([action, scopes]) => {
          if (scopes.includes(scope as Scope)) {
            const description = APIDescriptions.read[action as ReadAPIAction];
            if (description && !readDescriptions.includes(description)) {
              readDescriptions.push(description);
            }
          }
        });

        // Check write actions
        Object.entries(APIScopeMapping.write).forEach(([action, scopes]) => {
          if (scopes.includes(scope as Scope)) {
            const description = APIDescriptions.write[action as WriteAPIAction];
            if (description && !writeDescriptions.includes(description)) {
              writeDescriptions.push(description);
            }
          }
        });
      });

      return { readDescriptions, writeDescriptions };
    };

    const { readDescriptions, writeDescriptions } = categorizeScopes();
    return (
      <Box
        sx={{ maxWidth: '600px', margin: '0 auto', p: 2, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 2 }}
      >
        {clientDetails && privateAppUsage && (
          <ul style={{ paddingLeft: '16px', ...themeFonts.caption, listStyle: 'none' }}>
            <li>
              <b>Client ID:</b> <i>{clientDetails.clientId}</i>
            </li>
            <li>
              <b>Client name:</b> <i>{clientDetails.name}</i>
            </li>
            <li>
              <b>Description:</b> <i>{clientDetails.description}</i>
            </li>
            <li>
              <b>Redirect URI:</b> <i>{clientDetails.redirectionUri}</i>
            </li>
            <li>
              <b>Created by:</b> <i>{creatorName}</i>
            </li>
            <li>
              <b>Created on:</b> <i>{new Date(clientDetails.createdAt).toUTCString()}</i>
            </li>
          </ul>
        )}
        {clientDetails && publishedAppForOtherCompanies && (
          <ul style={{ paddingLeft: '16px', ...themeFonts.caption, listStyle: 'none' }}>
            <li>
              <b>Client name:</b> <i>{clientDetails.name}</i>
            </li>
            <li>
              <b>Description:</b> <i>{clientDetails.description}</i>
            </li>
          </ul>
        )}
        <Divider />
        <Typography sx={{ ...themeFonts.title3, mt: 2, textDecoration: 'underline' }}>
          What can <b>{appName}</b> read?
        </Typography>
        <Box sx={{ maxHeight: '100px', overflowY: 'auto', mb: 2 }}>
          <List dense>
            {readDescriptions.map((eachAbility, index) => (
              <ListItem key={index} disablePadding>
                <ListItemText primary={eachAbility} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Typography sx={{ ...themeFonts.title3, mb: 2, textDecoration: 'underline' }}>
          What can <b>{appName}</b> do?
        </Typography>
        <Box sx={{ maxHeight: '100px', overflowY: 'auto' }}>
          <List dense>
            {writeDescriptions.map((eachAbility, index) => (
              <ListItem key={index} disablePadding>
                <ListItemText primary={eachAbility} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    );
  };

  return (
    <Stack sx={{ margin: 'auto', justifyContent: 'center' }}>
      {noParamsAvailable ? (
        <PersistentNotification inUse={true} message={'Cannot authorize unknown app'} maxWidth="700px" />
      ) : loadingClient ? (
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4 }}
        />
      ) : !clientDetails ? (
        <PersistentNotification inUse={true} message={'Cannot authorize unknown app'} maxWidth="700px" />
      ) : (
        <>
          {privateAppUsage && (
            <PersistentNotification
              inUse={true}
              message={`This ${appName} was created by ${creatorName} within your Zelt account`}
              maxWidth="700px"
            />
          )}
          {/* Published app not to display persistent notification */}
          {/* {publishedAppForOtherCompanies && (
            <PersistentNotification
              inUse={true}
              message={`This ${appName} was created outside your organisation`}
              maxWidth="700px"
            />
          )} */}
          <Paper
            sx={{
              padding: 3,
              maxWidth: 700,
              width: '100%',
            }}
            elevation={3}
          >
            <Typography sx={{ ...themeFonts.title3, mb: 2 }}>
              {clientDetails ? clientDetails.name : 'Client'} is requesting access to your Zelt account.
            </Typography>
            {clientDetails && clientDetails.logo && <AppLogoStack appLogo={clientDetails.logo} />}
            <WhatCanAppDo configuredScopes={clientDetails?.scopes} />
            <Stack direction="row" sx={{ margin: 'auto', justifyContent: 'center', gap: spacing.g30 }}>
              <ButtonComponent
                colorVariant="secondary"
                sizeVariant="medium"
                style={{ marginTop: '16px' }}
                onClick={() => onDenyHandler()}
              >
                Deny
              </ButtonComponent>
              <LoaderButton
                name="Allow access"
                sizeVariant="medium"
                colorVariant="primary"
                style={{ marginTop: '16px' }}
                onClick={onConsentHandler}
                loading={false}
              />
            </Stack>
          </Paper>
        </>
      )}
    </Stack>
  );
};
