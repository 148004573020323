import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { UserSelectFiltersOptions } from '@v2/components/user-select-type/user-select.interface';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { isHourlyPolicy } from '@v2/feature/absence/absence.util';
import { ApprovalRuleDto } from '@v2/feature/approval-rule/approval-rule.dto';
import { CompanyUnitDto } from '@v2/feature/company/company-settings/features/company-settings.dto';
import { SiteDto } from '@v2/feature/site/site.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { borders } from '@v2/styles/borders.styles';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import update from 'immutability-helper';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE } from '@/lib/routes';
import { CompanyDepartmentDto } from '@/models/company-department.model';
import { ChipComponent } from '@/v2/components/chip/chip.component';

export const SmallCircle = () => (
  <Box sx={{ width: '3px', height: '3px', bgcolor: '#2F2F2F', borderRadius: '15px' }}></Box>
);

interface DraggableAbsencePoliciesProps {
  readonly absencePolicies: readonly AbsencePolicyDto[];
  readonly sites: readonly SiteDto[];
  readonly departments: readonly CompanyDepartmentDto[];
  readonly entities: readonly CompanyUnitDto[];
  readonly approvalRules: readonly ApprovalRuleDto[];
}

export const DraggableAbsencePolicies = ({
  absencePolicies,
  sites,
  departments,
  entities,
  approvalRules,
}: DraggableAbsencePoliciesProps) => {
  const { polyglot } = usePolyglot();
  const [policies, setPolicies] = useState([...absencePolicies]);
  const [initialOrder, setInitialOrder] = useState<number[]>(policies.map((policy) => policy.id));
  const [showMessage] = useMessage();

  useEffect(() => {
    setPolicies([...absencePolicies]);
  }, [absencePolicies]);

  const orderPolicies = useCallback(
    async (updatedPoliciesOrder: number[]) => {
      // If current order === initial order, don't send the request to BE
      if (JSON.stringify(initialOrder) === JSON.stringify(updatedPoliciesOrder)) return;

      try {
        await AbsenceAPI.orderAbsencePolicies(updatedPoliciesOrder);
        setInitialOrder(updatedPoliciesOrder);
      } catch (error) {
        showMessage(polyglot.t('DraggableAbsencePolicies.error', { nestError: nestErrorMessage(error) }), 'error');
      }
    },
    [polyglot, showMessage, initialOrder]
  );

  const handleDragDrop = async (results: DropResult) => {
    const { source, destination, type } = results;
    if (!destination) return;
    if (source.index === destination.index) return;
    if (type === 'group') {
      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      setPolicies((prev) =>
        update(prev, {
          $splice: [
            [sourceIndex, 1],
            [destinationIndex, 0, prev[sourceIndex]],
          ],
        })
      );
    }
  };

  useEffect(() => {
    const updatedPoliciesOrder = policies.map((policy) => policy.id);
    orderPolicies(updatedPoliciesOrder);
  }, [orderPolicies, policies]);

  return (
    <DragDropContext onDragEnd={handleDragDrop}>
      <Droppable droppableId="ROOT" type="group">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10 }}>
              {policies.map((policy, index) => (
                <Draggable draggableId={policy.id.toString()} key={policy.id} index={index}>
                  {(provided) => (
                    <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                      <PolicyCard
                        absencePolicy={policy}
                        index={index}
                        key={index}
                        sites={sites}
                        departments={departments}
                        entities={entities}
                        approvalRules={approvalRules}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </Box>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

interface CardProps {
  readonly absencePolicy: AbsencePolicyDto;
  readonly index: number;
  readonly sites: readonly SiteDto[];
  readonly departments: readonly CompanyDepartmentDto[];
  readonly entities: readonly CompanyUnitDto[];
  readonly approvalRules: readonly ApprovalRuleDto[];
}

const PolicyCard = ({ absencePolicy, index, sites, departments, entities, approvalRules }: CardProps) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();

  const isArchived = useMemo(() => Boolean(absencePolicy.deletedAt), [absencePolicy.deletedAt]);

  const goToPolicy = useCallback(() => {
    routerHistory.push(generatePath(SETTINGS_TIME_POLICY_DETAILS_GENERAL_ROUTE, { policyId: absencePolicy.id }));
  }, [absencePolicy.id, routerHistory]);

  const policyAllowance = useMemo(() => {
    if (absencePolicy.allowance === null) return polyglot.t('AbsencePolicyAllowanceSection.unlimited');
    const isHourly = isHourlyPolicy(absencePolicy);

    return isHourly
      ? polyglot.t('DraggableAbsencePolicies.numHours', { smart_count: absencePolicy.allowance })
      : polyglot.t('DraggableAbsencePolicies.numDays', { smart_count: absencePolicy.allowance });
  }, [polyglot, absencePolicy]);

  const policyMembers = useMemo(() => {
    if (absencePolicy.membersRule === UserSelectFiltersOptions.Everyone)
      return polyglot.t('DraggableAbsencePolicies.membersAll');
    if (absencePolicy.membersRule === UserSelectFiltersOptions.SelectSpecific)
      return polyglot.t('DraggableAbsencePolicies.membersCount', {
        count: absencePolicy.selectedMembersIds?.length ?? 0,
      });

    if (absencePolicy.membersRule === UserSelectFiltersOptions.CustomRule) {
      if (!absencePolicy.customRule) return polyglot.t('DraggableAbsencePolicies.membersCustomRuleUndefined');

      const [key, ids] = absencePolicy.customRule?.split('=');

      if (key === 'site') {
        const siteNames = ids
          .split(',')
          .map((id) => sites.find((site) => site.id === Number(id))?.name)
          .filter(Boolean)
          .join(', ');
        return polyglot.t('DraggableAbsencePolicies.membersSite', { siteNames });
      }
      if (key === 'department') {
        const departmentNames = ids
          .split(',')
          .map((id) => departments.find((dep) => dep.id === Number(id))?.name)
          .filter(Boolean)
          .join(', ');
        return polyglot.t('DraggableAbsencePolicies.membersDepartment', { departmentNames });
      }
      if (key === 'entity') {
        const entityNames = ids
          .split(',')
          .map((id) => entities.find((entity) => entity.id === Number(id))?.legalName)
          .filter(Boolean)
          .join(', ');
        return polyglot.t('DraggableAbsencePolicies.membersEntity', { entityNames });
      }

      return polyglot.t('DraggableAbsencePolicies.membersCustomRuleDefault', {
        customRule: absencePolicy.customRule ?? '??',
      });
    }

    return polyglot.t('DraggableAbsencePolicies.membersNotSpecified');
  }, [polyglot, absencePolicy, sites, departments, entities]);

  const approvalIsRequired = useMemo(() => {
    const approvalRule = approvalRules.find((rule) => rule.id === absencePolicy.backoffApprovalRuleId);
    if (approvalRule?.name === 'Auto approve') return polyglot.t('DraggableAbsencePolicies.approvalIsNotRequired');

    return polyglot.t('DraggableAbsencePolicies.approvalIsRequired');
  }, [polyglot, absencePolicy, approvalRules]);

  return (
    <Box
      key={index}
      sx={{
        width: '600px',
        padding: spacing.s2,
        borderRadius: '15px',
        backgroundColor: themeColors.white,
        cursor: 'move',
        border: borders.middle,
        '&:hover': {
          transform: 'scale(1.03)',
          bgcolor: themeColors.TableHover,
        },
        filter: isArchived ? 'grayscale(75%)' : 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: spacing.s3,
        boxSizing: 'border-box',
        transition: 'transform 0.2s ease-in-out, background-color 0.2s ease-in-out',
      }}
      onDoubleClick={goToPolicy}
    >
      <Box
        sx={{
          display: 'flex',
          gap: spacing.g10,
          cursor: 'pointer',
        }}
        onClick={(e) => {
          goToPolicy();
          e.stopPropagation();
        }}
      >
        <Box sx={{ display: 'flex', width: '14px', alignItems: 'center' }}>
          <Box sx={{ width: '14px', height: '14px', borderRadius: '50%', bgcolor: absencePolicy.color }} />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Typography sx={themeFonts.title4}>{absencePolicy.fullName}</Typography>
        </Box>

        {isArchived && (
          <Box sx={{ display: 'flex', alignItems: 'center', color: themeColors.Grey }}>
            <Typography sx={themeFonts.caption}>{polyglot.t('DraggableAbsencePolicies.archived')}</Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '5px',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <ChipComponent
          name={policyAllowance}
          backgroundColor="white"
          textColor="DarkGrey"
          textVariant="caption"
          border="middle"
        />
        <ChipComponent
          name={policyMembers}
          backgroundColor="white"
          textColor="DarkGrey"
          textVariant="caption"
          border="middle"
        />
        <ChipComponent
          name={approvalIsRequired}
          backgroundColor="white"
          textColor="DarkGrey"
          textVariant="caption"
          border="middle"
        />
      </Box>
    </Box>
  );
};
