import { JSX } from 'react';

import Polyglot from 'node-polyglot';

import NewGrowthLoop from '@/animations/Growth/Growth_loop_30x30.json';
import NewGrowthFill from '@/animations/Growth/Growth_loop_FILL_30x30.json';
import NewHomeLoop from '@/animations/Home/Home_loop_30x30.json';
import NewHomeFill from '@/animations/Home/Home_loop_FILL_30x30.json';
import NewTechLoop from '@/animations/IT/IT_loop_30x30.json';
import NewTechFill from '@/animations/IT/IT_loop_FILL_30x30.json';
import NewMoneyLoop from '@/animations/Money/Money_loop_30x30.json';
import NewMoneyFill from '@/animations/Money/Money_loop_FILL_30x30.json';
import NewPeopleLoop from '@/animations/People/People_loop_30x30.json';
import NewPeopleFill from '@/animations/People/People_loop_FILL_30x30.json';
import NewReportsLoop from '@/animations/Reports/Reports_loop_30x30.json';
import NewReportsFill from '@/animations/Reports/Reports_loop_FILL_30x30.json';
import NewTimeLoop from '@/animations/Time/Time_loop_30x30.json';
import NewTimeFill from '@/animations/Time/Time_loop_FILL_30x30.json';
import { ReactComponent as NewDashboard } from '@/images/side-bar-icons/NewDashboard.svg';
import { ReactComponent as NewDashboardFilled } from '@/images/side-bar-icons/NewDashboardFilled.svg';
import { ReactComponent as NewGrowth } from '@/images/side-bar-icons/NewGrowth.svg';
import { ReactComponent as NewGrowthFilled } from '@/images/side-bar-icons/NewGrowthFilled.svg';
import { ReactComponent as NewIt } from '@/images/side-bar-icons/NewIt.svg';
import { ReactComponent as NewItFilled } from '@/images/side-bar-icons/NewItFilled.svg';
import { ReactComponent as NewMoney } from '@/images/side-bar-icons/NewMoney.svg';
import { ReactComponent as NewMoneyFilled } from '@/images/side-bar-icons/NewMoneyFilled.svg';
import { ReactComponent as NewPeople } from '@/images/side-bar-icons/NewPeople.svg';
import { ReactComponent as NewPeopleFilled } from '@/images/side-bar-icons/NewPeopleFilled.svg';
import { ReactComponent as NewReports } from '@/images/side-bar-icons/NewReports.svg';
import { ReactComponent as NewReportsFilled } from '@/images/side-bar-icons/NewReportsFilled.svg';
import { ReactComponent as NewTime } from '@/images/side-bar-icons/NewTime.svg';
import { ReactComponent as NewTimeFilled } from '@/images/side-bar-icons/NewTimeFilled.svg';
import { DASHBOARD_ROUTE } from '@/lib/routes';
import { canAccessScopes, checkScopes } from '@/lib/scopes';
import { CurrentUser, Scope } from '@/models';
import {
  TIME_ABSENCE_BALANCES_ROUTES,
  TIME_ABSENCE_BALANCES_SCOPES,
  TIME_ABSENCE_REQUESTS_ROUTES,
  TIME_ABSENCE_REQUESTS_SCOPES,
} from '@/v2/feature/absence/absence-tabs.util';
import {
  TECH_APPS_OVERVIEW_ROUTES,
  TECH_APPS_OVERVIEW_SCOPES,
  TECH_APPS_REQUEST_ROUTES,
  TECH_APPS_REQUEST_SCOPES,
  TECH_APPS_STORE_ROUTES,
  TECH_APPS_STORE_SCOPES,
} from '@/v2/feature/app-integration/app-integration-tabs.util';
import {
  NavConfigItem,
  SubItemsProps,
  SubMenuProps,
} from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import {
  MainMenuSectionEnum,
  MainMenuSectionProps,
  RoleRoutes,
  RoleScopes,
  RoleTypes,
  ScopeCheck,
  SubItemConfig,
  SubMenuConfig,
} from '@/v2/feature/app-layout/features/v2/menu/menu.interface';
import { TIME_ATTENDANCE_ROUTES, TIME_ATTENDANCE_ROUTES_SCOPES } from '@/v2/feature/attendance/attendance-tabs.util';
import { MONEY_BENEFITS_ROUTES, MONEY_BENEFITS_SCOPES } from '@/v2/feature/benefits/benefits-tabs.util';
import { TIME_CALENDAR_ROUTES } from '@/v2/feature/calendar/calendar-tabs.util';
import {
  TECH_DEVICE_DIRECTORY_ROUTES,
  TECH_DEVICE_DIRECTORY_SCOPES,
  TECH_DEVICE_STORE_ROUTES,
  TECH_DEVICE_STORE_SCOPES,
} from '@/v2/feature/device/devices-tabs.util';
import {
  PEOPLE_DOCUMENTS_OVERVIEW_ROUTES,
  PEOPLE_DOCUMENTS_OVERVIEW_SCOPES,
} from '@/v2/feature/documents/documents-tabs.util';
// import { MONEY_INVOICES_OVERVIEW_ROUTES } from '@/v2/feature/invoice/invoice-tabs.util';
import { MONEY_EXPENSE_SCOPES, MONEY_EXPENSES_OVERVIEW_ROUTES } from '@/v2/feature/expense/expense-tabs.util';
import {
  GROWTH_REVIEWS_INVITE_ROUTES,
  GROWTH_REVIEWS_INVITE_SCOPES,
  GROWTH_REVIEWS_ME_ROUTES,
  GROWTH_REVIEWS_ONGOING_ROUTES,
  GROWTH_REVIEWS_ONGOING_SCOPES,
  GROWTH_REVIEWS_RESULT_ROUTES,
  GROWTH_REVIEWS_RESULT_SCOPES,
} from '@/v2/feature/growth/reviews/reviews-tabs.util';
import {
  GROWTH_SURVEYS_INVITE_ROUTES,
  GROWTH_SURVEYS_INVITE_SCOPES,
  GROWTH_SURVEYS_ME_ROUTES,
  GROWTH_SURVEYS_ONGOING_ROUTES,
  GROWTH_SURVEYS_ONGOING_SCOPES,
} from '@/v2/feature/growth/surveys/surveys-tabs.util';
import { MONEY_INVOICE_SCOPES, MONEY_INVOICES_OVERVIEW_ROUTES } from '@/v2/feature/invoice/invoice-tabs.util';
import { MONEY_PAYMENTS_OVERVIEW_ROUTES, MONEY_PAYMENTS_SCOPES } from '@/v2/feature/payments/payments-tabs.util';
import {
  MONEY_SALARY_PAYROLL_HUB_ROUTES,
  MONEY_SALARY_SCOPES,
  MONEY_SALARY_SUMMARY_ROUTES,
} from '@/v2/feature/payroll/payroll-tabs.util';
import {
  REPORT_REPORTS_DASHBOARD_PEOPLE_ROUTES,
  REPORT_REPORTS_DASHBOARD_ROUTES,
  REPORT_REPORTS_OVERVIEW_ROUTES,
  REPORT_REPORTS_V2_NEW_OVERVIEW_ROUTES,
  REPORTS_COMPANY_SALARY_ROUTE,
} from '@/v2/feature/reports/reports-tabs.util';
import { PEOPLE_TASKS_OVERVIEW_ROUTES, PEOPLE_TASKS_OVERVIEW_SCOPES } from '@/v2/feature/task/task-tabs.util';
import {
  PEOPLE_COMPANY_ACTION_ITEMS_ROUTES,
  PEOPLE_DIRECTORY_ROUTES,
  PEOPLE_DIRECTORY_SCOPES,
  PEOPLE_OFFBOARDING_OVERVIEW_ROUTES,
  PEOPLE_ONBOARDING_OVERVIEW_ROUTES,
} from '@/v2/feature/user/user-tabs.util';

const iconSize = { width: 20, height: 20 } as const;
/*
STEPS:
      1. Add the main domain to getSidebarConfig (for main menu)
      2. Add the sub domain to respective domain config
      3. main domain = createSection, sub domain = createSubItem and sub menu item = createSubMenuItem
*/

const createSubMenuItem = (
  title: string,
  stub: string,
  role: RoleTypes,
  user: CurrentUser,
  route: RoleRoutes,
  scopes: Scope[],
  isHidden: boolean
): SubMenuProps => {
  return {
    title,
    path: getRoleCommonRoute(route, role),
    stub,
    isHidden: isHidden || !canAccessScopes(user, scopes) || !getRoleCommonRoute(route, role),
    hasChildren: false,
  };
};

const createSubItem = ({
  title,
  stub,
  user,
  routes,
  scopes,
  isHidden,
  hasChildren,
  role,
  subMenu,
  deprecated,
}: SubItemConfig): SubItemsProps => {
  const routeExists = routes.find((r) => getRoleCommonRoute(r, role));
  return {
    title,
    stub,
    path: !hasChildren && routeExists ? routeExists[role] : undefined,
    isHidden: isHidden || !canAccessScopes(user, scopes) || !routeExists,
    hasChildren,
    subMenu: subMenu
      ? subMenu.map((item) =>
          createSubMenuItem(item.title, item.stub, role, user, item.route, item.scopes, item.isHidden)
        )
      : undefined,
    deprecated,
  };
};

const createSection = ({
  title,
  stub,
  path,
  icon,
  iconActive,
  loopAnimation,
  loopFilledAnimation,
  isHidden,
  hasChildren,
  subItems,
}: {
  title: string;
  stub: string;
  path: string;
  icon: JSX.Element;
  iconActive: JSX.Element;
  loopAnimation: unknown;
  loopFilledAnimation: unknown;
  isHidden: boolean;
  hasChildren: boolean;
  subItems?: NavConfigItem[];
}): NavConfigItem => {
  return {
    title,
    path,
    stub,
    icon,
    iconActive,
    loopAnimation,
    loopFilledAnimation,
    isHidden,
    hasChildren,
    subItems: subItems,
  };
};

const scopeChecks: ScopeCheck[] = [
  {
    domain: MainMenuSectionEnum.people,
    scopes: {
      me: ['user'],
      team: ['documents:manager', 'task:manager'],
      company: ['user.onboard:all', 'task:all', 'documents:all', 'company.settings:all'],
    },
  },
  {
    domain: MainMenuSectionEnum.time,
    scopes: {
      me: ['absence'],
      team: ['absence:manager', 'absence:read:manager', 'attendance:manager'],
      company: ['absence:all'],
    },
  },
  {
    domain: MainMenuSectionEnum.money,
    scopes: {
      me: ['payroll', 'pension', 'insurance', 'payroll:read', 'expenses', 'invoices'],
      team: ['payments:manager', 'expenses:manager', 'invoices:manager'],
      company: [
        'payroll:read:all',
        'payroll:all',
        'pension:all',
        'insurance:all',
        'payments:all',
        'expenses:all',
        'invoices:all',
      ],
    },
  },
  {
    domain: MainMenuSectionEnum.growth,
    scopes: {
      me: ['reviews', 'surveys'],
      team: ['reviews:manager', 'surveys:manager'],
      company: ['reviews:all', 'surveys:all'],
    },
  },
  {
    domain: MainMenuSectionEnum.report,
    scopes: {
      company: ['reports:all'],
      team: ['reports:manager'],
    },
  },
  {
    domain: MainMenuSectionEnum.tech,
    scopes: {
      me: ['devices', 'apps'],
      company: ['devices:all', 'apps:all'],
    },
  },
];

export const getRolesByDomain = (domain: string | undefined, user: CurrentUser): RoleTypes[] => {
  let roles: RoleTypes[] = [];
  const currentScopeCheck = scopeChecks.find((sc) => sc.domain === domain);
  if (currentScopeCheck) {
    if (
      currentScopeCheck.scopes.me &&
      user.scopes2?.some((eachScope) => currentScopeCheck.scopes.me!.includes(eachScope.scope))
    ) {
      roles.push(RoleTypes.me);
    }

    if (
      currentScopeCheck.scopes.team &&
      user.reports &&
      user.reports.length > 0 &&
      user.scopes2?.some((eachScope) => currentScopeCheck.scopes.team!.includes(eachScope.scope))
    ) {
      roles.push(RoleTypes.team);
    }

    if (
      currentScopeCheck.scopes.company &&
      user.scopes2?.some((eachScope) => currentScopeCheck.scopes.company!.includes(eachScope.scope))
    ) {
      roles.push(RoleTypes.company);
    }
  }

  return roles.reverse();
};

const MainMenuSection = (user: CurrentUser, polyglot: Polyglot): MainMenuSectionProps => {
  return {
    dashboard: {
      title: polyglot?.t('getSidebarConfig.home'),
      path: DASHBOARD_ROUTE,
      stub: '',
      icon: <NewDashboard {...iconSize} />,
      iconActive: <NewDashboardFilled {...iconSize} />,
      loopAnimation: NewHomeLoop,
      loopFilledAnimation: NewHomeFill,
      isHidden: false,
      hasChildren: false,
    },
    people: {
      title: polyglot.t('getSidebarConfig.people'),
      stub: 'people',
      path: '/people',
      icon: <NewPeople {...iconSize} />,
      iconActive: <NewPeopleFilled {...iconSize} />,
      loopAnimation: NewPeopleLoop,
      loopFilledAnimation: NewPeopleFill,
      isHidden: false,
      hasChildren: true,
    },
    time: {
      title: polyglot.t('getSidebarConfig.time'),
      stub: 'time',
      path: '/time',
      icon: <NewTime {...iconSize} />,
      iconActive: <NewTimeFilled {...iconSize} />,
      loopAnimation: NewTimeLoop,
      loopFilledAnimation: NewTimeFill,
      isHidden: !checkScopes(user, ['absence'], { userId: user.userId }),
      hasChildren: true,
    },
    growth: {
      title: polyglot.t('getSidebarConfig.growth'),
      stub: 'growth',
      path: '/growth',
      icon: <NewGrowth {...iconSize} />,
      iconActive: <NewGrowthFilled {...iconSize} />,
      loopAnimation: NewGrowthLoop,
      loopFilledAnimation: NewGrowthFill,
      isHidden: !checkScopes(user, ['reviews', 'surveys'], { userId: user.userId }),
      hasChildren: true,
    },
    money: {
      title: polyglot.t('getSidebarConfig.money'),
      stub: 'money',
      path: '/money',
      icon: <NewMoney {...iconSize} />,
      iconActive: <NewMoneyFilled {...iconSize} />,
      loopAnimation: NewMoneyLoop,
      loopFilledAnimation: NewMoneyFill,
      isHidden:
        Boolean(user?.navigation?.salary.isHidden) &&
        Boolean(user?.navigation?.benefits.isHidden) &&
        !canAccessScopes(user, ['payments:manager']) &&
        !canAccessScopes(user, ['expenses']) &&
        !canAccessScopes(user, ['invoices']),
      hasChildren: true,
    },
    tech: {
      title: polyglot.t('getSidebarConfig.tech'),
      stub: 'tech',
      path: '/tech',
      icon: <NewIt {...iconSize} />,
      iconActive: <NewItFilled {...iconSize} />,
      loopAnimation: NewTechLoop,
      loopFilledAnimation: NewTechFill,
      isHidden:
        !checkScopes(user, ['apps', 'apps:all'], { userId: user.userId }) &&
        !checkScopes(user, ['devices', 'devices:all', 'devices.store'], { userId: user.userId }),
      hasChildren: true,
    },
    report: {
      title: polyglot.t('getSidebarConfig.reports'),
      stub: 'report',
      path: '/report',
      icon: <NewReports {...iconSize} />,
      iconActive: <NewReportsFilled {...iconSize} />,
      isHidden: !canAccessScopes(user, ['reports:manager', 'payroll:manager', 'company.settings:all']),
      loopAnimation: NewReportsLoop,
      loopFilledAnimation: NewReportsFill,
      hasChildren: true,
    },
  };
};
const getRoleCommonRoute = (allRoutes: RoleRoutes, role?: RoleTypes): string | undefined => {
  if (!role) return undefined;
  return allRoutes[role];
};

const getIsHiddenByRouteAndScope = (roleScopes: RoleScopes[], user: CurrentUser, role: RoleTypes): boolean => {
  const currentRoleScope = roleScopes
    .map((row) => row[role])
    .flat()
    .filter(Boolean);

  if (!currentRoleScope || currentRoleScope.length === 0) return true;
  if (role === RoleTypes.team && user.reports && user.reports.length > 0) {
    return !canAccessScopes(user, currentRoleScope as Scope[]);
  }
  return !canAccessScopes(user, currentRoleScope as Scope[]);
};

export const getSidebarConfig = (
  user: CurrentUser,
  role: RoleTypes | undefined,
  polyglot: Polyglot,
  showHiddenFeatures = false
): readonly NavConfigItem[] => {
  return user.navigation
    ? [
        createSection({
          ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.dashboard],
        }),
        createSection({
          ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.people],
          subItems: [...(getPeopleConfig(user, role, polyglot)?.subItems ?? [])],
        }),
        createSection({
          ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.time],
          subItems: [...(getTimeConfig(user, role, polyglot)?.subItems ?? [])],
        }),
        createSection({
          ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.growth],
          subItems: [...(getGrowthConfig(user, role, polyglot)?.subItems ?? [])],
        }),
        createSection({
          ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.money],
          subItems: [...(getMoneyConfig(user, role, polyglot)?.subItems ?? [])],
        }),
        createSection({
          ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.tech],
          subItems: [...(getTechConfig(user, role, polyglot)?.subItems ?? [])],
        }),
        createSection({
          ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.report],
          subItems: [...(getReportsConfig(user, role, polyglot, showHiddenFeatures)?.subItems ?? [])],
        }),
      ]
    : [];
};

export const getPeopleConfig = (user: CurrentUser | null, role: RoleTypes | undefined, polyglot: Polyglot) => {
  if (!user || !role) return undefined;

  const subItems: NavConfigItem[] = [
    createSubItem({
      title: polyglot.t('getSidebarConfig.directory'),
      stub: 'directory',
      user,
      routes: [PEOPLE_DIRECTORY_ROUTES],
      scopes: ['user:read:all'],
      isHidden: getIsHiddenByRouteAndScope([PEOPLE_DIRECTORY_SCOPES], user, role),
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.onboarding'),
      stub: 'onboarding',
      user,
      routes: [PEOPLE_ONBOARDING_OVERVIEW_ROUTES],
      scopes: ['user.onboard:all'],
      isHidden: false,
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.offboarding'),
      stub: 'offboarding',
      user,
      routes: [PEOPLE_OFFBOARDING_OVERVIEW_ROUTES],
      scopes: ['user.onboard:all'],
      isHidden: false,
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.actionItems'),
      stub: 'action-items',
      user,
      routes: [PEOPLE_COMPANY_ACTION_ITEMS_ROUTES],
      scopes: ['user:read:all'],
      isHidden: false,
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.tasks'),
      stub: 'tasks',
      user,
      routes: [PEOPLE_TASKS_OVERVIEW_ROUTES],
      scopes: ['task'],
      isHidden: getIsHiddenByRouteAndScope([PEOPLE_TASKS_OVERVIEW_SCOPES], user, role),
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot?.t('getSidebarConfig.documents'),
      stub: 'documents',
      user,
      routes: [PEOPLE_DOCUMENTS_OVERVIEW_ROUTES],
      scopes: ['documents'],
      isHidden: getIsHiddenByRouteAndScope([PEOPLE_DOCUMENTS_OVERVIEW_SCOPES], user, role),
      hasChildren: false,
      role,
    }),
  ];

  return createSection({
    ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.people],
    subItems,
  });
};

export const getTimeConfig = (user: CurrentUser | null, role: RoleTypes | undefined, polyglot: Polyglot) => {
  if (!user || !role) return undefined;

  const subMenu: { absence: SubMenuConfig[] } = {
    absence: [
      {
        title: polyglot?.t('getSidebarConfig.requests'),
        stub: 'requests',
        route: TIME_ABSENCE_REQUESTS_ROUTES,
        scopes: ['absence'],
        isHidden: getIsHiddenByRouteAndScope([TIME_ABSENCE_REQUESTS_SCOPES], user, role),
      },
      {
        title: polyglot?.t('getSidebarConfig.balances'),
        stub: 'balances',
        route: TIME_ABSENCE_BALANCES_ROUTES,
        scopes: ['absence'],
        isHidden: getIsHiddenByRouteAndScope([TIME_ABSENCE_BALANCES_SCOPES], user, role),
      },
    ] as SubMenuConfig[],
  };

  const subItems: NavConfigItem[] = [
    createSubItem({
      title: polyglot.t('getSidebarConfig.calendar'),
      stub: 'calendar',
      user,
      routes: [TIME_CALENDAR_ROUTES],
      scopes: ['absence'],
      isHidden: false,
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.absence'),
      stub: 'absence',
      user,
      routes: [TIME_ABSENCE_REQUESTS_ROUTES, TIME_ABSENCE_BALANCES_ROUTES],
      scopes: ['absence'],
      isHidden:
        getIsHiddenByRouteAndScope([TIME_ABSENCE_REQUESTS_SCOPES], user, role) &&
        getIsHiddenByRouteAndScope([TIME_ABSENCE_BALANCES_SCOPES], user, role),
      hasChildren: true,
      role,
      subMenu: subMenu.absence,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.attendance'),
      stub: 'attendance',
      user,
      routes: [TIME_ATTENDANCE_ROUTES],
      scopes: ['attendance'],
      isHidden: getIsHiddenByRouteAndScope([TIME_ATTENDANCE_ROUTES_SCOPES], user, role),
      hasChildren: false,
      role,
    }),
  ];

  return createSection({
    ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.time],
    subItems,
  });
};

export const getMoneyConfig = (user: CurrentUser | null, role: RoleTypes | undefined, polyglot: Polyglot) => {
  if (!user || !role) return undefined;
  const subItems: NavConfigItem[] = [
    createSubItem({
      title: polyglot.t('getSidebarConfig.payments'),
      stub: 'payments',
      user,
      routes: [MONEY_PAYMENTS_OVERVIEW_ROUTES],
      scopes: ['payments:manager'],
      isHidden: getIsHiddenByRouteAndScope([MONEY_PAYMENTS_SCOPES], user, role),
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.payroll'),
      stub: 'salary',
      user,
      routes: [
        {
          company: MONEY_SALARY_PAYROLL_HUB_ROUTES.company,
          me:
            Boolean(user.contractType === 'Employee') && !Boolean(user?.navigation?.salary?.isHidden)
              ? MONEY_SALARY_SUMMARY_ROUTES.me
              : undefined,
        },
      ],
      scopes: ['payroll:read'],
      isHidden:
        getIsHiddenByRouteAndScope([MONEY_SALARY_SCOPES], user, role) || Boolean(user?.navigation?.salary?.isHidden),
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.benefits'),
      stub: 'benefits',
      user,
      routes: [MONEY_BENEFITS_ROUTES],
      scopes: ['insurance', 'pension'],
      isHidden:
        getIsHiddenByRouteAndScope([MONEY_BENEFITS_SCOPES], user, role) || Boolean(user.navigation?.benefits?.isHidden),
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.expenses'),
      stub: 'expenses',
      user,
      routes: [MONEY_EXPENSES_OVERVIEW_ROUTES],
      scopes: ['expenses'],
      isHidden: getIsHiddenByRouteAndScope([MONEY_EXPENSE_SCOPES], user, role),
      hasChildren: false,
      role,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.invoices'),
      stub: 'invoices',
      user,
      routes: [MONEY_INVOICES_OVERVIEW_ROUTES],
      scopes: ['invoices'],
      isHidden: getIsHiddenByRouteAndScope([MONEY_INVOICE_SCOPES], user, role),
      hasChildren: false,
      role,
    }),
  ];

  return createSection({
    ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.money],
    subItems,
  });
};

export const getReportsConfig = (
  user: CurrentUser | null,
  role: RoleTypes | undefined,
  polyglot: Polyglot,
  _showHiddenFeatures: boolean
) => {
  if (!user || !role) return undefined;

  const subMenu: { insights: SubMenuConfig[] } = {
    insights: [
      {
        title: polyglot.t('getSidebarConfig.people'),
        stub: 'people',
        route: REPORT_REPORTS_DASHBOARD_PEOPLE_ROUTES,
        scopes: ['company.settings:all'],
        isHidden: false,
      },
      {
        title: polyglot.t('getSidebarConfig.salary'),
        stub: 'salary',
        route: REPORTS_COMPANY_SALARY_ROUTE,
        scopes: ['payroll:all'],
        isHidden: false,
      },
    ] as SubMenuConfig[],
  };

  const subItems: NavConfigItem[] = [
    createSubItem({
      title: polyglot.t('getSidebarConfig.reportsV2'),
      stub: 'reports-advanced',
      user,
      routes: [REPORT_REPORTS_V2_NEW_OVERVIEW_ROUTES],
      scopes: ['reports:manager'],
      isHidden: !canAccessScopes(user, ['reports:manager']),
      hasChildren: false,
      role,
    }),
    // createSubItem({
    //   title: 'Test reports',
    //   stub: 'reports-test',
    //   user,
    //   routes: [{ company: REPORT_COMPANY_REPORTS_TEST }],
    //   scopes: ['reports:all'],
    //   isHidden: !_showHiddenFeatures,
    //   hasChildren: false,
    //   role,
    // }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.insights'),
      stub: 'insights',
      user,
      routes: [REPORT_REPORTS_DASHBOARD_ROUTES],
      scopes: ['company.settings:all', 'payroll:all'],
      isHidden: false,
      hasChildren: true,
      role,
      subMenu: subMenu.insights,
    }),
    createSubItem({
      title: polyglot.t('getSidebarConfig.reports'),
      stub: 'reports',
      user,
      routes: [REPORT_REPORTS_OVERVIEW_ROUTES],
      scopes: ['reports:all'],
      isHidden: false,
      hasChildren: false,
      role,
      deprecated: true,
    }),
  ];

  return createSection({
    ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.report],
    subItems,
  });
};

export const getGrowthConfig = (user: CurrentUser | null, role: RoleTypes | undefined, polyglot: Polyglot) => {
  if (!user || !role) return undefined;

  const subMenu: { reviews: SubMenuConfig[]; surveys: SubMenuConfig[] } = {
    reviews: [
      {
        title: 'Invites',
        stub: 'invite',
        route: GROWTH_REVIEWS_INVITE_ROUTES,
        scopes: ['reviews'],
        isHidden: getIsHiddenByRouteAndScope([GROWTH_REVIEWS_INVITE_SCOPES], user, role),
      },
      {
        title: 'Results',
        stub: 'result',
        route: GROWTH_REVIEWS_RESULT_ROUTES,
        scopes: ['reviews'],
        isHidden: getIsHiddenByRouteAndScope([GROWTH_REVIEWS_RESULT_SCOPES], user, role),
      },
    ],
    surveys: [
      {
        title: 'Invites',
        stub: 'invite',
        route: GROWTH_SURVEYS_INVITE_ROUTES,
        scopes: ['surveys'],
        isHidden: getIsHiddenByRouteAndScope([GROWTH_SURVEYS_INVITE_SCOPES], user, role),
      },
      // {
      //   title: 'Results',
      //   stub: 'result',
      //   route: GROWTH_SURVEYS_RESULT_ROUTES,
      //   scopes: ['surveys'],
      //   isHidden: getIsHiddenByRouteAndScope([GROWTH_SURVEYS_RESULT_SCOPES], user, role),
      // },
    ],
  };

  const subItems: NavConfigItem[] = [
    createSubItem({
      title: polyglot?.t('getSidebarConfig.reviews'),
      stub: 'reviews',
      user,
      routes: [GROWTH_REVIEWS_ONGOING_ROUTES, GROWTH_REVIEWS_ME_ROUTES],
      scopes: ['reviews'],
      isHidden: getIsHiddenByRouteAndScope([GROWTH_REVIEWS_ONGOING_SCOPES], user, role),
      hasChildren: role === RoleTypes.me,
      role,
      subMenu: role === RoleTypes.me ? subMenu.reviews : undefined,
    }),
    createSubItem({
      title: polyglot?.t('getSidebarConfig.surveys'),
      stub: 'surveys',
      user,
      routes: [GROWTH_SURVEYS_ONGOING_ROUTES, GROWTH_SURVEYS_ME_ROUTES],
      scopes: ['surveys'],
      isHidden: getIsHiddenByRouteAndScope([GROWTH_SURVEYS_ONGOING_SCOPES], user, role),
      hasChildren: role === RoleTypes.me,
      role,
      subMenu: role === RoleTypes.me ? subMenu.surveys : undefined,
    }),
  ];

  return createSection({
    ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.growth],
    subItems,
  });
};

export const getTechConfig = (user: CurrentUser | null, role: RoleTypes | undefined, polyglot: Polyglot) => {
  if (!user || !role) return undefined;

  const subMenu: { devices: SubMenuConfig[]; apps: SubMenuConfig[] } = {
    devices: [
      {
        title: polyglot?.t('getSidebarConfig.directory'),
        stub: 'directory',
        route: TECH_DEVICE_DIRECTORY_ROUTES,
        scopes: ['devices'],
        isHidden: getIsHiddenByRouteAndScope([TECH_DEVICE_DIRECTORY_SCOPES], user, role),
      },
      {
        title: polyglot?.t('getSidebarConfig.store'),
        stub: 'store',
        route: TECH_DEVICE_STORE_ROUTES,
        scopes: ['devices.store', 'devices:all'],
        isHidden: getIsHiddenByRouteAndScope([TECH_DEVICE_STORE_SCOPES], user, role),
      },
    ],
    apps: [
      {
        title: polyglot?.t('getSidebarConfig.overview'),
        stub: 'overview',
        route: TECH_APPS_OVERVIEW_ROUTES,
        scopes: ['apps', 'apps:all'],
        isHidden: getIsHiddenByRouteAndScope([TECH_APPS_OVERVIEW_SCOPES], user, role),
      },
      {
        title: polyglot?.t('getSidebarConfig.requests'),
        stub: 'requests',
        route: TECH_APPS_REQUEST_ROUTES,
        scopes: ['apps:all'],
        isHidden: getIsHiddenByRouteAndScope([TECH_APPS_REQUEST_SCOPES], user, role),
      },
      {
        title: polyglot?.t('getSidebarConfig.store'),
        stub: 'store',
        route: TECH_APPS_STORE_ROUTES,
        scopes: ['apps', 'apps:all'],
        isHidden: getIsHiddenByRouteAndScope([TECH_APPS_STORE_SCOPES], user, role),
      },
    ],
  };
  const subItems: NavConfigItem[] = [
    createSubItem({
      title: polyglot?.t('getSidebarConfig.devices'),
      stub: 'devices',
      user,
      routes: [TECH_DEVICE_DIRECTORY_ROUTES, TECH_DEVICE_STORE_ROUTES],
      scopes: ['devices', 'devices.store'],
      isHidden:
        getIsHiddenByRouteAndScope([TECH_DEVICE_DIRECTORY_SCOPES], user, role) &&
        getIsHiddenByRouteAndScope([TECH_DEVICE_STORE_SCOPES], user, role),
      hasChildren: true,
      role,
      subMenu: subMenu.devices,
    }),
    createSubItem({
      title: polyglot?.t('getSidebarConfig.apps'),
      stub: 'apps',
      user,
      routes: [TECH_APPS_OVERVIEW_ROUTES, TECH_APPS_REQUEST_ROUTES, TECH_APPS_STORE_ROUTES],
      scopes: ['apps'],
      isHidden:
        getIsHiddenByRouteAndScope([TECH_APPS_OVERVIEW_SCOPES], user, role) &&
        getIsHiddenByRouteAndScope([TECH_APPS_REQUEST_SCOPES], user, role) &&
        getIsHiddenByRouteAndScope([TECH_APPS_STORE_SCOPES], user, role),
      hasChildren: true,
      role,
      subMenu: subMenu.apps,
    }),
  ];

  return createSection({
    ...MainMenuSection(user, polyglot)[MainMenuSectionEnum.tech],
    subItems,
  });
};
