const urlOrBase64Regex = /^(https?:\/\/[^\s]+|data:[\w/\-+]+;base64,[A-Za-z0-9+/=]+)(?!.*(\/|\\))/;

//Used for microsoft word copy and paste
export const isLocalFilePath = (imgSrc: string): boolean => {
  return !urlOrBase64Regex.test(imgSrc);
};

export const blobToBase64 = async (blob: Blob): Promise<string | ArrayBuffer | null> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
