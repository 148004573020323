import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useReducer, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { PaginationState, SortingState } from '@tanstack/react-table';
import {
  documentFormModalOwnershipToDto,
  getDocumentParentCategory,
  getDocumentTypeListBasedOnAudience,
  initialiseState,
  OTHER_TYPE,
  reduceDocumentFormModal,
  uploadDocumentIfNeeded,
} from '@v2/feature/documents/documents.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { constVoid, pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as RA from 'fp-ts/lib/ReadonlyArray';
import Polyglot from 'node-polyglot';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import {
  DocumentBulkEditValues,
  DocumentConfirmBulkEditDrawer,
} from './components/document-confirm-bulk-edit-modal.component';

import { TemplateAPI } from '@/api-client/templates.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/components/ArrowDown.svg';
import { ReactComponent as Edit } from '@/images/side-bar-icons/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { CreateZeltDocument, ZeltDocument, ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { ScopeContext } from '@/models';
import { TabFilterButtons, TabFilterItem } from '@/v2/components/tab-filter-buttons.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DocumentConfirmBulkDeleteDrawer } from '@/v2/feature/documents/components/document-confirm-bulk-delete-modal.component';
import { DocumentEmptyOverview } from '@/v2/feature/documents/components/document-empty-overview.component';
import { DocumentFormModal } from '@/v2/feature/documents/components/document-form-modal.component';
import { DocumentTable } from '@/v2/feature/documents/components/document-table.component';
import { NewDocumentButton } from '@/v2/feature/documents/components/new-document-action-button.component';
import { PinnedDocument } from '@/v2/feature/documents/components/pinned-document.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import {
  BufferData,
  DocumentAction,
  DocumentOrderType,
  DocumentStatus,
  PreviewPayload,
} from '@/v2/feature/documents/documents.interface';
import { DocPreviewer } from '@/v2/feature/payroll/features/payroll-uk/user-payroll/components/doc-previewer.component';
import { SelectDeselectIdRows } from '@/v2/feature/task/components/task-table/select-deselect-string-id-rows.component';
import { ContractModal } from '@/v2/feature/templates/components/contract-modal.component';
import { MissingTemplateFieldModal } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { SelectTemplateForContractModal } from '@/v2/feature/templates/components/select-template-for-contract-modal.component';
import {
  ContractTemplate,
  RequiredContractFields,
  VerifyTemplateResult,
} from '@/v2/feature/templates/templates.interface';
import { TemplatesScopes } from '@/v2/feature/templates/templates.scopes';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { getDisplayName } from '@/v2/util/string-format.util';

interface Props {
  readonly reach: 'company' | 'team';
}

const TabFilter = (polyglot: Polyglot): TabFilterItem<DocumentStatus>[] => {
  return [
    { name: polyglot.t('DocumentStatus.all'), value: DocumentStatus.All },
    { name: polyglot.t('DocumentStatus.pending'), value: DocumentStatus.Pending },
  ];
};

const initialState = initialiseState([]);

export const DocumentsCompanyPage = ({ reach }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [globalState, globalDispatch] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const currentUserIsDocumentAdmin = hasScopes(
    ['documents:all', 'documents:manager'],
    getScopesContext({ userId: globalState.user?.userId })
  );
  const currentUserIsTemplateAdmin = hasScopes(
    TemplatesScopes.LIST_CONTRACT_TEMPLATES,
    getScopesContext({ userId: globalState.user?.userId })
  );

  const hasDocumentsManager = useMemo(() => {
    const reporteeScopeContext: ScopeContext = {
      userId: globalState.user?.reports?.length > 0 ? globalState.user?.reports[0] : 0,
    };
    return checkScopes(globalState.user, ['documents:manager'], reporteeScopeContext);
  }, [globalState.user]);

  const [showMessage] = useMessage();
  const { trackPage } = useJune();

  const [documentState, documentDispatch] = useReducer(reduceDocumentFormModal, initialState);

  const [documents, setDocuments] = useState<readonly ZeltDocument[] | undefined>(undefined);
  const [pinnedDocuments, setPinnedDocuments] = useState<readonly ZeltDocument[]>([]);

  // for document preview
  const [selectedDocBuffer, setSelectedDocBuffer] = useState<BufferData | null>();
  const [selectedDocName, setSelectedDocName] = useState('');
  const [selectedDocContentType, setSelectedDocContentType] = useState<string>('');
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const [templates, setTemplates] = useState<readonly ContractTemplate[]>([]);
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [openContractModal, setOpenContractModal] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ContractTemplate | undefined>(undefined);

  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<RequiredContractFields>();
  const [contractRecipientUserId, setContractRecipientUserId] = useState<number>();
  const [contractCompanySignatoryUserId, setContractCompanySignatoryUserId] = useState<number>();

  const [confirmBulkDeleteDrawerOpen, setConfirmBulkDeleteDrawerOpen] = useState(false);
  const [confirmBulkEditDrawerOpen, setConfirmBulkEditDrawerOpen] = useState(false);

  //this is for empty state
  const [isDocumentExist, setIsDocumentExist] = useState<boolean>(false);
  const [filterString, setFilterString] = useState<string>('');

  const [documentFormMode, setDocumentFormMode] = useState<DocumentAction>('add');
  const [open, setOpen] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>('');
  const [statusFilterValue, setStatusFilterValue] = useState<string>(DocumentStatus.All);

  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  function closeUploadModal() {
    setOpen(false);
  }

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(true);

  const { nonTerminatedCachedUsers, loaded } = useCachedUsers({ refresh: true });
  const allUsers = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => ({ value: u.userId, label: u.displayName }));
  }, [nonTerminatedCachedUsers]);

  const [users, setUsers] = useState<readonly CachedUser[]>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const fetchDocuments = useCallback(
    async (params?: {
      page: number;
      pageSize: number;
      searchQuery: string;
      filterString: string;
      statusFilter: string;
      shouldLimitResult: boolean;
      order?: DocumentOrderType;
    }) => {
      try {
        setLoadingDocuments(true);
        const { documents, pagination: paginationInfo, documentsExist } = await (reach === 'team'
          ? DocumentAPI.listTeamDocuments(params)
          : DocumentAPI.listDocumentsV2(params));
        setIsDocumentExist(documentsExist);
        setDocuments([...documents]);
        const { totalPages, totalCount } = paginationInfo;
        setTotalPages(totalPages);
        setTotalItems(totalCount);
      } catch (error) {
        showMessage(
          polyglot.t('DocumentsCompanyPage.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoadingDocuments(false);
      }
    },
    [polyglot, reach, showMessage]
  );

  useLayoutEffect(() => {
    const filteredUsers = {
      company: () => nonTerminatedCachedUsers,
      team() {
        const reportsAndMe = new Set(globalState.user.reports);
        reportsAndMe.add(globalState.user.userId);
        return nonTerminatedCachedUsers.filter((u) => reportsAndMe.has(u.userId));
      },
    }[reach]();
    setUsers(filteredUsers);
  }, [reach, globalState.user.reports, globalState.user.userId, nonTerminatedCachedUsers]);

  // extract ASC or DESC from an array of this format [{id: 'createdAt', desc: true}]
  const getSortDirection = (sort: { id: string; desc: boolean }[]) => {
    if (sort.length === 0) return undefined;
    const { id, desc } = sort[0];
    if (id !== 'createdAt') return undefined;
    return desc ? 'DESC' : 'ASC';
  };

  useEffect(() => {
    let ignore = false;
    (async () => {
      try {
        setLoading(true);
        const typeData = await DocumentAPI.listDocumentTypes();

        await fetchDocuments({
          page: pageIndex,
          pageSize: pageSize,
          searchQuery,
          filterString,
          statusFilter: statusFilterValue,
          shouldLimitResult: true,
          order: getSortDirection(sorting),
        });

        if (!ignore) {
          documentDispatch({ kind: 'set_types', value: typeData });
        }
      } catch (error) {
        showMessage(
          polyglot.t('DocumentsCompanyPage.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      ignore = true;
    };
  }, [
    polyglot,
    reach,
    globalState.user.userId,
    globalDispatch,
    showMessage,
    fetchDocuments,
    sorting,
    pageIndex,
    pageSize,
    searchQuery,
    filterString,
    statusFilterValue,
  ]);

  const getPinnedDocuments = useCallback(async () => {
    const pinnedDocuments = await DocumentAPI.getPinnedDocuments({ reach });
    setPinnedDocuments(pinnedDocuments);
  }, [reach]);

  useEffect(() => {
    getPinnedDocuments();
  }, [getPinnedDocuments]);

  const completeSave = async () => {
    try {
      let dynamicFileUuid;
      if (documentState.documentLink && !documentState.fileUuid) {
        dynamicFileUuid = await uploadDocumentIfNeeded(documentState.documentLink);
      }
      let documentObject: CreateZeltDocument = {
        name: documentState.name || '',
        type: documentState.type?.value ?? OTHER_TYPE.value,
        belongsTo: documentFormModalOwnershipToDto(documentState),
        fileUuid: documentState.fileUuid ?? dynamicFileUuid,
        note: documentState.note,
        expirySettings: documentState?.expirySettings ?? { expiryDate: '', documentExpires: false },
      };
      if (documentState.id && documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.updateDocument(documentState.id, documentObject);
        showMessage(polyglot.t('DocumentsCompanyPage.successMessages.update'), 'success');
      } else if (documentState.name) {
        if (documentState.type?.category === 'Company Documents') {
          documentObject = { ...documentObject, isPinned: documentState.pinned ?? false };
        } else if (documentState.visibility) {
          documentObject = { ...documentObject, visibilitySettings: documentState.visibility };
        }
        await DocumentAPI.createDocument(documentObject);
        showMessage(
          documentFormMode === 'request'
            ? polyglot.t('DocumentsCompanyPage.successMessages.request')
            : polyglot.t('DocumentsCompanyPage.successMessages.save'),
          'success'
        );
      }

      await fetchDocuments({
        page: pageIndex,
        pageSize: pageSize,
        searchQuery,
        filterString,
        statusFilter: statusFilterValue,
        shouldLimitResult: true,
      });
      await getPinnedDocuments();
    } catch (error) {
      showMessage(
        polyglot.t('DocumentsCompanyPage.errorMessages.save', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
    closeUploadModal();
  };

  const fetchTemplates = useCallback(
    async (params?: { searchQuery: string }) => {
      try {
        setLoading(true);
        const templates = await TemplateAPI.getTemplates(params);
        setTemplates(templates || []);
        setLoading(false);
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setLoading(false);
      }
    },
    [polyglot, showMessage]
  );

  const handleUploadDocument = () => {
    documentDispatch({ kind: 'add_document' });
    setDocumentFormMode('add');
    setFilterValue(
      getDocumentParentCategory(
        reach,
        'add',
        undefined,
        currentUserIsDocumentAdmin,
        documentState.types as ZeltDocumentType[]
      )
    );
    setOpen(true);
  };

  const handleRequestDocument = () => {
    documentDispatch({
      kind: 'request_document',
      owner: { label: getDisplayName(globalState?.user), value: globalState?.user?.userId },
      reach: 'company',
    });
    setDocumentFormMode('request');
    setFilterValue(
      getDocumentParentCategory(
        reach,
        'request',
        undefined,
        currentUserIsDocumentAdmin,
        documentState.types as ZeltDocumentType[]
      )
    );
    setOpen(true);
  };

  const handleBulkUploadDocument = () => {
    routerHistory.push(DOCUMENTS_COMPANY_BULK_UPLOAD_ROUTE);
  };

  const handleTemplateClick = () => {
    setOpenTemplateModal(true);
  };

  const handlePreviewClick = useCallback(
    async (zeltDocument: ZeltDocument) => {
      try {
        const fileUuidFromAttachment =
          zeltDocument.attachments && zeltDocument.attachments[0] && zeltDocument.attachments[0]?.fileUuid;
        const finalUuid = zeltDocument.fileUuid ?? fileUuidFromAttachment;
        if (!finalUuid) return;
        await DocumentAPI.previewViaUuid(finalUuid).then(async ({ contentType, file }: PreviewPayload) => {
          setSelectedDocName(zeltDocument.name);
          setSelectedDocBuffer(file);
          setSelectedDocContentType(contentType);
          setOpenPreviewModal(true);
        });
      } catch (e) {
        console.error('::URL Download error', e);
        showMessage(
          polyglot.t('DocumentsCompanyPage.errorMessages.preview', { errorMessage: nestErrorMessage(e) }),
          'error'
        );
      }
    },
    [polyglot, setOpenPreviewModal, setSelectedDocBuffer, setSelectedDocName, showMessage]
  );
  const debouncedFilter = useDebouncedCallback(async (filterString: string) => {
    try {
      setFilterString(filterString);
      // Resets pagination index once a new filter is applied
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage(polyglot.t('DocumentsCompanyPage.errorMessages.filter'), 'error');
    }
  }, 500);
  const handleFilter = useCallback((event: string) => debouncedFilter.callback(event), [debouncedFilter]);

  const onMissingField = (params: {
    templateVerificationResult: VerifyTemplateResult;
    contractRecipientUserId: number;
  }) => {
    const { contractRecipientUserId, templateVerificationResult } = params;
    setContractRecipientUserId(contractRecipientUserId);
    setContractCompanySignatoryUserId(templateVerificationResult.companySignatoryUserId);
    setMissingFields(templateVerificationResult.fields);
    setOpenMissingFieldContractModal(true);
  };

  useEffect(() => {
    trackPage(`Documents ${reach} page`);
    if (currentUserIsTemplateAdmin) fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshMissingFieldsForTemplate = useCallback(
    async (templateId: string, contractRecipientUserId: number, companySignatoryUserId: number | undefined) => {
      try {
        const templateResult = await TemplateAPI.verifyTemplateParameters({
          templateId,
          contractRecipientUserId,
          companySignatoryUserId,
        });

        setMissingFields(templateResult.fields);
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.verify', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, showMessage]
  );

  const refreshDocuments = async () => {
    await fetchDocuments({
      page: pageIndex,
      pageSize: DEFAULT_PAGE_SIZE,
      searchQuery,
      filterString,
      statusFilter: statusFilterValue,
      shouldLimitResult: true,
    });
  };

  const bulkDeleteDocuments = async () => {
    try {
      const { deletedCount } = await DocumentAPI.bulkDeleteDocuments(selectionModel);
      setSelectionModel([]);
      refreshDocuments();
      showMessage(polyglot.t('DocumentFormModal.successMessages.bulkDelete', { deletedCount }), 'success');
    } catch (error) {
      showMessage(
        polyglot.t('DocumentFormModal.errorMessages.bulkDelete', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const bulkEditDocuments = async (updatedValues: DocumentBulkEditValues) => {
    try {
      await DocumentAPI.bulkEditDocuments(selectionModel, updatedValues);
      setSelectionModel([]);
      refreshDocuments();
      showMessage(
        polyglot.t('DocumentFormModal.successMessages.bulkEdit', { updateCount: selectionModel.length }),
        'success'
      );
    } catch (error) {
      showMessage(
        polyglot.t('DocumentFormModal.errorMessages.bulkEdit', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const getDocumentBulkActionsOptions = () => {
    return [
      {
        icon: <Trash {...iconSize} />,
        handler: () => setConfirmBulkDeleteDrawerOpen(true),
        label: 'Delete',
        disabled: selectionModel.length === 0,
      },
      {
        icon: <Edit {...iconSize} />,
        handler: () => setConfirmBulkEditDrawerOpen(true),
        label: 'Bulk Edit',
        disabled: selectionModel.length === 0,
      },
    ];
  };

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('DocumentsCompanyPage.documents')}
          </Typography>
        }
        showAction={isDocumentExist}
        actions={
          <NewDocumentButton
            onUploadClick={handleUploadDocument}
            onRequestClick={handleRequestDocument}
            onBulkUploadClick={currentUserIsDocumentAdmin ? handleBulkUploadDocument : undefined}
            onTemplateClick={currentUserIsTemplateAdmin ? handleTemplateClick : undefined}
            isAdmin={currentUserIsDocumentAdmin || (reach === 'team' && hasDocumentsManager)}
            documentTypes={[...documentState.types]}
          />
        }
      />
      <ContentWrapper
        loading={!loaded}
        sx={{ ...(pinnedDocuments.length > 0 ? spacing.pt40 : spacing.pt20), overflow: 'hidden' }}
      >
        {!loading && !isDocumentExist && documents?.length === 0 && (
          <DocumentEmptyOverview
            onUploadClick={handleUploadDocument}
            onRequestClick={handleRequestDocument}
            isAdmin={currentUserIsDocumentAdmin}
          />
        )}

        <>
          {pinnedDocuments.length > 0 && (
            <Box sx={{ ...spacing.pb40 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: spacing.g60,
                  rowGap: spacing.g20,
                  flexWrap: 'wrap',
                }}
              >
                <PinnedDocument
                  pinnedDocuments={pinnedDocuments}
                  onEditClick={(value) => {
                    documentDispatch({ kind: 'edit_document', value, userList: allUsers });
                    setDocumentFormMode('edit');
                    setOpen(true);
                    setFilterValue(
                      getDocumentParentCategory(
                        reach,
                        'edit',
                        value,
                        currentUserIsDocumentAdmin,
                        documentState.types as ZeltDocumentType[]
                      )
                    );
                  }}
                  documentTypes={[...documentState.types]}
                  onPreviewClick={handlePreviewClick}
                />
              </Box>
            </Box>
          )}

          {documentState.types.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g5 }}>
                <TabFilterButtons
                  filters={TabFilter(polyglot)}
                  setFilterValue={setStatusFilterValue}
                  filterValue={statusFilterValue}
                  onFilterChange={({ filterValue }) => {
                    setSelectionModel([]);
                    setStatusFilterValue(filterValue);
                    // setSearchInput(searchInput);
                  }}
                />
                <CategoryFilters
                  filterTypes={{
                    type: getDocumentTypeListBasedOnAudience(documentState.types, currentUserIsDocumentAdmin).map(
                      (d) => {
                        return { value: d.value, label: d.label };
                      }
                    ),
                  }}
                  setFilterString={handleFilter}
                  filterString={filterString}
                />
                <TableSearch
                  query={searchQuery}
                  handleChange={(e) => {
                    setSearchQuery(e.target.value?.trim() ?? '');
                    setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
                  }}
                  placeholder={polyglot.t('DocumentsCompanyPage.search')}
                />
              </Box>
              {/* <Box sx={{ display: 'flex', justifyContent: 'start', gap: spacing.g5 }}>
                <TabFilterButtons
                  filters={TabFilter(polyglot)}
                  setFilterValue={setFilterValue}
                  filterValue={filterValue}
                  hasSearch
                  onFilterChange={({ filterValue, searchInput }) => {
                    setSelectionModel([]);
                    setFilterValue(filterValue);
                    setSearchInput(searchInput);
                  }}
                />
              </Box> */}
              <Box sx={{ display: 'flex', justifyContent: 'end', gap: spacing.g5, marginLeft: spacing.ml10 }}>
                <SelectDeselectIdRows<number>
                  selectionModel={selectionModel}
                  setSelectionModel={setSelectionModel}
                  rows={documents ?? []}
                  hideSelectAll
                />
                {selectionModel?.length > 0 ? (
                  <StyledMenuComponent
                    options={getDocumentBulkActionsOptions()}
                    actionButtonDetails={{
                      type: 'button',
                      colorVariant: 'secondary',
                      sizeVariant: 'small',
                      title: 'Actions',
                      icon: <ArrowDown {...iconSize} />,
                      iconPosition: 'end',
                    }}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          )}

          <Box sx={{ ...spacing.mt20 }}>
            <DocumentTable
              documents={documents ? [...documents] : []}
              loading={loading || loadingDocuments}
              documentTypes={[...documentState.types]}
              onEditClick={(value) => {
                documentDispatch({ kind: 'edit_document', value, userList: allUsers });
                setDocumentFormMode('edit');
                setFilterValue(
                  getDocumentParentCategory(
                    reach,
                    'edit',
                    value,
                    currentUserIsDocumentAdmin,
                    documentState.types as ZeltDocumentType[]
                  )
                );
                setOpen(true);
              }}
              onVerified={(doc, documentVerification) => {
                pipe(
                  documents ?? [],
                  RA.findIndex((d) => d.id === doc.id),
                  O.fold(constVoid, (idx) => {
                    const verifiedDoc: ZeltDocument = { ...doc, documentVerification };
                    pipe(documents ?? [], RA.updateAt(idx, verifiedDoc), O.fold(constVoid, setDocuments));
                  })
                );
              }}
              sorting={sorting}
              setSorting={setSorting}
              pagination={pagination}
              setPagination={setPagination}
              totalPages={totalPages}
              totalItems={totalItems}
              onDelete={async () => {
                await fetchDocuments({
                  page: pageIndex,
                  pageSize: DEFAULT_PAGE_SIZE,
                  searchQuery,
                  filterString,
                  statusFilter: statusFilterValue,
                  shouldLimitResult: true,
                });
              }}
              setSelectedDocBuffer={setSelectedDocBuffer}
              setSelectedDocContentType={setSelectedDocContentType}
              setSelectedDocName={setSelectedDocName}
              setOpenPreviewModal={setOpenPreviewModal}
              setSelectionModel={setSelectionModel}
              selectionModel={selectionModel}
              stickyHeader
            />
          </Box>
        </>
      </ContentWrapper>

      {users && (
        <DocumentFormModal
          mode={documentFormMode}
          state={documentState}
          currentUserIsAdmin={currentUserIsDocumentAdmin}
          dispatch={documentDispatch}
          newDocumentTypes={documentState.types as ZeltDocumentType[]}
          onSubmit={completeSave}
          users={users}
          canSelectEveryone={documentFormMode === 'request' ? false : reach === 'company'}
          canChangeOwnership
          setOpen={setOpen}
          open={open}
          setLoading={setLoading}
          getDocuments={async () => {
            await fetchDocuments({
              page: pageIndex,
              pageSize: DEFAULT_PAGE_SIZE,
              searchQuery,
              filterString,
              statusFilter: statusFilterValue,
              shouldLimitResult: true,
            });
          }}
          setFilterValue={setFilterValue}
          filterValue={filterValue}
        />
      )}

      <DocumentConfirmBulkDeleteDrawer
        isOpen={confirmBulkDeleteDrawerOpen}
        setIsOpen={setConfirmBulkDeleteDrawerOpen}
        onActionConfirm={bulkDeleteDocuments}
        drawerTitle={'Delete documents'}
        confirmationText={'Are you sure you want to delete selected documents?'}
        documentCountForDeletion={selectionModel.length ?? 0}
      />

      <DocumentConfirmBulkEditDrawer
        isOpen={confirmBulkEditDrawerOpen}
        setIsOpen={setConfirmBulkEditDrawerOpen}
        onBulkEdit={bulkEditDocuments}
        drawerTitle={'Edit documents'}
        confirmationText={`You are about to edit ${selectionModel?.length} documents. Privacy settings change will only apply to personal documents and not company documents.`}
        documentCountForEdit={selectionModel.length ?? 0}
      />

      {selectedDocBuffer && openPreviewModal && (
        <DocPreviewer
          fileBuffer={selectedDocBuffer}
          contentType={selectedDocContentType}
          visible
          onClose={() => setOpenPreviewModal(false)}
          title={selectedDocName}
        />
      )}

      {selectedTemplate && openContractModal && (
        <ContractModal
          open={openContractModal}
          setOpen={setOpenContractModal}
          contractTemplate={selectedTemplate}
          onMissingField={onMissingField}
        />
      )}

      {selectedTemplate && missingFields && contractRecipientUserId && openMissingFieldContractModal && (
        <MissingTemplateFieldModal
          open={openMissingFieldContractModal}
          setOpen={setOpenMissingFieldContractModal}
          templateId={selectedTemplate.id}
          missingFields={missingFields}
          contractRecipientId={contractRecipientUserId}
          companySignatoryUserId={contractCompanySignatoryUserId}
          refreshMissingFields={refreshMissingFieldsForTemplate}
        />
      )}

      {setSelectedTemplate && templates && openTemplateModal && (
        <SelectTemplateForContractModal
          open={openTemplateModal}
          setOpen={setOpenTemplateModal}
          templateList={templates}
          setSelectedTemplate={(template?: ContractTemplate) => {
            setSelectedTemplate(template ?? undefined);
            setOpenContractModal(true);
          }}
        />
      )}
    </RootStyle>
  );
};
