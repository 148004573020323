import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { TemplateAPI } from '@/api-client/templates.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { ReactComponent as InfoIcon } from '@/images/side-bar-icons/InfoTransparent.svg';
import { ZeltDocumentType } from '@/lib/documents';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from '@/lib/routes';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItem,
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { GeneralisedRemoveConfirmDrawer } from '@/v2/feature/app-integration/features/app-details/components/generalised-remove-confirm-drawer-drawer.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import { ContractModal } from '@/v2/feature/templates/components/contract-modal.component';
import { MissingTemplateFieldModal } from '@/v2/feature/templates/components/missing-template-field-modal.component';
import { RenameTemplateModal } from '@/v2/feature/templates/components/rename-template-modal.component';
import { TemplateModal } from '@/v2/feature/templates/components/template-modal.component';
import {
  ContractTemplate,
  RequiredContractFields,
  VerifyTemplateResult,
} from '@/v2/feature/templates/templates.interface';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { OnboardingEndpoints } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { localFormat } from '@/v2/util/date-format.util';

export const TemplatesPage = (): JSX.Element => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const [templates, setTemplates] = useState<readonly ContractTemplate[]>([]);
  const [openTemplateModal, setOpenTemplateModal] = useState<boolean>(false);
  const [openContractModal, setOpenContractModal] = useState<boolean>(false);
  const [openRenameTemplateModal, setOpenRenameTemplateModal] = useState<boolean>(false);
  const [openMissingFieldContractModal, setOpenMissingFieldContractModal] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<RequiredContractFields>();
  const [contractRecipientUserId, setContractRecipientUserId] = useState<number>();
  const [contractCompanySignatoryUserId, setContractCompanySignatoryUserId] = useState<number>();
  const [selectedTemplate, setSelectedTemplate] = useState<ContractTemplate>();
  const [loading, setLoading] = useState<boolean>(true);
  const [documentTypes, setDocumentTypes] = useState<ZeltDocumentType[]>([]);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const { data: contractTemplateCurrentlyUsedInOnboarding } = useApiClient(
    selectedTemplate
      ? OnboardingEndpoints.isContractTemplateCurrentlyUsedInOnboarding(selectedTemplate?.id)
      : { url: undefined },
    {
      suspense: false,
    }
  );

  const [_state] = useContext(GlobalContext);
  // const { user } = state;
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);

  const routerHistory = useHistory();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const fetchTemplates = useCallback(
    async (params?: { searchQuery: string }) => {
      try {
        setLoading(true);
        const templates = await TemplateAPI.getTemplates(params);
        setTemplates(templates || []);
        setLoading(false);
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setLoading(false);
      }
    },
    [polyglot, showMessage]
  );

  const deleteTemplate = useCallback(
    async (params: { templateId: string }) => {
      try {
        await TemplateAPI.deleteTemplate(params);
        showMessage(polyglot.t('TemplatesPage.successMessages.delete'), 'success');
        await fetchTemplates();
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.delete', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
        setLoading(false);
      }
    },
    [polyglot, showMessage, fetchTemplates]
  );

  const duplicateTemplate = useCallback(
    async (params: { templateId: string }) => {
      try {
        await TemplateAPI.duplicateTemplate({ id: params.templateId });
        showMessage(polyglot.t('TemplatesPage.successMessages.duplicate'), 'success');
        await fetchTemplates();
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(
            polyglot.t('TemplatesPage.errorMessages.duplicate', { errorMessage: nestErrorMessage(error) }),
            'error'
          );
        }
      }
    },
    [polyglot, showMessage, fetchTemplates]
  );

  const onMissingField = (params: {
    templateVerificationResult: VerifyTemplateResult;
    contractRecipientUserId: number;
  }) => {
    const { contractRecipientUserId, templateVerificationResult } = params;
    setContractRecipientUserId(contractRecipientUserId);
    setContractCompanySignatoryUserId(templateVerificationResult.companySignatoryUserId);
    setMissingFields(templateVerificationResult.fields);
    setOpenMissingFieldContractModal(true);
  };

  const refreshMissingFieldsForTemplate = useCallback(
    async (templateId: string, contractRecipientUserId: number, companySignatoryUserId: number | undefined) => {
      try {
        const templateResult = await TemplateAPI.verifyTemplateParameters({
          templateId,
          contractRecipientUserId,
          companySignatoryUserId,
        });

        setMissingFields(templateResult.fields);
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.verify', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    },
    [polyglot, showMessage]
  );

  useEffect(() => {
    (async () => {
      try {
        const documentTypes = await DocumentAPI.listDocumentTypes();
        // Stop filtering to ONLY allowed template types - instead show ALL visible document types
        // setDocumentTypes(documentTypes.filter((d) => ALLOWED_TEMPLATE_TYPES.includes(d.category)));
        setDocumentTypes(documentTypes);

        await fetchTemplates({ searchQuery });
      } catch (error) {
        showMessage(
          polyglot.t('TemplatesPage.errorMessages.badRequest', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [polyglot, showMessage, fetchTemplates, searchQuery]);

  const CONTRACT_IN_USE_CONFIRMATION_MESSAGE = useMemo(() => {
    const defaultMessage = polyglot.t('DocumentTemplateConfirmRemove.normalConfirmMessage');
    if (!contractTemplateCurrentlyUsedInOnboarding) {
      return defaultMessage;
    } else if (contractTemplateCurrentlyUsedInOnboarding) {
      return polyglot.t('DocumentTemplateConfirmRemove.templateUsedInOnboardingConfirmMessage');
    }
    return defaultMessage;
  }, [contractTemplateCurrentlyUsedInOnboarding, polyglot]);

  return (
    <ContentWrapper loading={false} sx={{ ...spacing.pt20 }}>
      <SettingsSectionContent
        title="Templates"
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={() => {
              setOpenTemplateModal(true);
            }}
          >
            {polyglot.t('TemplatesPage.newTemplate')}
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <TableSearch
                      query={searchQuery}
                      handleChange={(e) => setSearchQuery(e.target.value?.trim() ?? '')}
                      placeholder={polyglot.t('TemplatesPage.search')}
                    />
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {templates.map((item) => (
                        <SettingsItemCard
                          key={item.name}
                          title={item.name}
                          loading={loading}
                          boxSx={responsiveCardStyle}
                          contentItemsSets={[
                            {
                              name: `${documentTypes.find((d) => d.value === item.type)?.label ?? ''}`,
                              type: ContentItemType.chip,
                              textColor: 'DarkGrey',
                              backgroundColor: 'white',
                              border: 'middle',
                            },
                            ...(item.createdBy
                              ? [
                                  {
                                    type: ContentItemType.component,
                                    contentComponent: (
                                      <ChipComponent
                                        name={<UserCell userId={item.createdBy} nameColor="DarkGrey" />}
                                        backgroundColor="white"
                                        textColor="DarkGrey"
                                        textVariant="caption"
                                        border="middle"
                                      />
                                    ),
                                  } as ContentItem,
                                ]
                              : []),
                            {
                              name: `${localFormat('dd MMM yyyy', new Date(item.createdAt))}`,
                              type: ContentItemType.chip,
                              textColor: 'DarkGrey',
                              backgroundColor: 'white',
                              border: 'middle',
                            },
                          ]}
                          boxAction={() => {
                            routerHistory.push(
                              generatePath(SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, {
                                templateId: item.id,
                              }),
                              { returnPath: generatePath(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE) }
                            );
                          }}
                          advanceActionOptions={[
                            {
                              icon: <InfoIcon {...iconSize} />,
                              handler: () => {
                                setSelectedTemplate(item as ContractTemplate);
                                setOpenRenameTemplateModal(true);
                              },
                              label: polyglot.t('TemplatesPage.rename'),
                              disabled: false,
                            },
                            {
                              icon: <Copy {...iconSize} />,
                              handler: () => {
                                duplicateTemplate({ templateId: item.id });
                              },
                              label: polyglot.t('TemplatesPage.duplicate'),
                              disabled: false,
                            },
                            {
                              icon: <Trash {...iconSize} />,
                              handler: () => {
                                setSelectedTemplate(item);
                                setIsRemovalModalOpen(true);
                              },
                              label: polyglot.t('TemplatesPage.delete'),
                              disabled: false,
                            },
                          ]}
                        />
                      ))}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>

      {openTemplateModal && (
        <TemplateModal open={openTemplateModal} setOpen={setOpenTemplateModal} documentTypes={documentTypes} />
      )}

      {selectedTemplate && openContractModal && (
        <ContractModal
          open={openContractModal}
          setOpen={setOpenContractModal}
          contractTemplate={selectedTemplate}
          onMissingField={onMissingField}
        />
      )}

      {selectedTemplate && missingFields && contractRecipientUserId && openMissingFieldContractModal && (
        <MissingTemplateFieldModal
          open={openMissingFieldContractModal}
          setOpen={setOpenMissingFieldContractModal}
          templateId={selectedTemplate.id}
          missingFields={missingFields}
          contractRecipientId={contractRecipientUserId}
          companySignatoryUserId={contractCompanySignatoryUserId}
          refreshMissingFields={refreshMissingFieldsForTemplate}
        />
      )}

      {selectedTemplate && openRenameTemplateModal && (
        <RenameTemplateModal
          documentTypes={documentTypes}
          open={openRenameTemplateModal}
          setOpen={setOpenRenameTemplateModal}
          template={selectedTemplate}
          onUpdate={fetchTemplates}
        />
      )}

      <UpgradeToProModal
        isOpen={upgradeModalOpen}
        setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
        planName={PlanNames.PEOPLE_PRO}
        messageSuffix="proGeneric"
      />

      <GeneralisedRemoveConfirmDrawer
        isOpen={isRemovalModalOpen}
        setIsOpen={setIsRemovalModalOpen}
        drawerTitle={polyglot.t('DocumentTemplateConfirmRemove.title')}
        confirmationMessage={CONTRACT_IN_USE_CONFIRMATION_MESSAGE}
        onClose={() => setIsRemovalModalOpen(false)}
        onConfirm={async () => {
          if (selectedTemplate?.id) deleteTemplate({ templateId: selectedTemplate?.id });
        }}
      />
    </ContentWrapper>
  );
};
