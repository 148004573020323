import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ReportConfigSection } from '@v2/feature/reports/reports-advanced/components/report-config.section';
import { ReportViewResult } from '@v2/feature/reports/reports-advanced/components/report-view-result.component';
import { ReportsAPI, ReportsEndpoints } from '@v2/feature/reports/reports.api';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { ReportResponse, SelectedColumnsRequest, SelectedFiltersRequest } from '@v2/feature/reports/reports.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { deepCopy } from '@v2/infrastructure/object/object.util';
import { RootStyle } from '@v2/styles/root.styles';
import { LocalDate } from '@v2/util/local-date';
import { useParams } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { Typography } from '@/v2/components/typography/typography.component';

interface PageProps {
  readonly reports: ReportDto[];
  readonly refreshReportsList: () => Promise<void>;
  readonly isLoading: boolean;
  readonly target: 'all' | 'team';
}

export const ReportsAdvancedViewPage = ({ reports, refreshReportsList, isLoading, target }: PageProps) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const params = useParams<{ readonly reportId: string }>();
  const reportId = Number(params.reportId);

  const { data: filtersAndColumns, isLoading: isLoadingConfig } = useApiClient(
    ReportsEndpoints.getTestReportsFiltersOptions(),
    {
      suspense: false,
    }
  );

  const report = useMemo(() => reports.find((r) => r.id === reportId), [reports, reportId]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [reportResponse, setReportResponse] = useState<ReportResponse | null>(null);

  const [selectedColumns, setSelectedColumns] = useState<SelectedColumnsRequest>(report?.columns ?? {});
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersRequest>(report?.filters ?? {});
  const [effectiveDate, setEffectiveDate] = useState<string>(new LocalDate().toDateString());

  useEffect(() => {
    if (!report) return;
    setSelectedColumns(deepCopy(report.columns ?? {}));
    setSelectedFilters(deepCopy(report.filters ?? {}));
  }, [report]);

  const generateReport = useCallback(async () => {
    if (!report) {
      setReportResponse(null);
      return;
    }
    try {
      setIsGenerating(true);
      const response = await ReportsAPI.generateReport(selectedColumns, selectedFilters, effectiveDate, target);
      setReportResponse(response);
    } catch (error) {
      setReportResponse(null);
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setIsGenerating(false);
  }, [report, selectedColumns, selectedFilters, effectiveDate, showMessage, polyglot, target]);

  useEffect(() => {
    generateReport();
  }, [generateReport]);

  return report ? (
    <RootStyle style={{ display: 'flex', flexDirection: 'row' }}>
      <ReportConfigSection
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        reportColumns={filtersAndColumns?.columns ?? []}
        reportFilters={filtersAndColumns?.filters ?? []}
        target={target}
        effectiveDate={effectiveDate}
        setEffectiveDate={setEffectiveDate}
      />
      <ReportViewResult
        report={report}
        reportResponse={reportResponse}
        isLoading={isLoading || isGenerating || !!isLoadingConfig}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        refreshReportsList={refreshReportsList}
        refreshReport={generateReport}
        target={target}
      />
    </RootStyle>
  ) : (
    <Box>
      <Typography variant="title2">Report not found</Typography>
    </Box>
  );
};
