import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { Box, IconButton } from '@mui/material';
import { ColumnDef, PaginationState, Row, SortingState } from '@tanstack/react-table';
import { sortString } from '@v2/components/table/table-sorting.util';
import { getTaskStatus } from '@v2/feature/task/components/task.util';
import { TaskDto } from '@v2/feature/task/task.dto';
import { TaskStatuses } from '@v2/feature/task/task.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ScopesControl } from '@/component/widgets/Scopes';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Chose } from '@/images/side-bar-icons/Chose.svg';
import { ReactComponent as Mail } from '@/images/side-bar-icons/Mail.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { TooltipCell } from '@/v2/components/table/components/tooltip-cell.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { BasicServerTable } from '@/v2/components/table/server-side-table.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { emojiOptions } from '@/v2/feature/task/subfeature/checklist.interface';
import { tablePrimaryIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { PaginatedResponse } from '@/v2/util/pagination.util';

const iconSize = { width: 14, height: 14 } as const;

interface TaskTableProperties {
  readonly tasks: PaginatedResponse<TaskDto>;
  readonly openSelectedTaskModal: Function;
  readonly openEditTaskModal: Function;
  readonly markAsComplete: Function;
  readonly showUserLink: boolean;
  readonly taskEditable?: boolean;
  readonly loading?: boolean;
  readonly setSelectionModel: React.Dispatch<React.SetStateAction<number[]>>;
  readonly selectionModel: number[];
  readonly currentUserId: number;
  deleteAction: (taskId: number) => Promise<void>;
  remindAction: (taskId: number) => Promise<void>;
  sorting: [SortingState, Dispatch<SetStateAction<SortingState>>];
  pagination: [PaginationState, Dispatch<SetStateAction<PaginationState>>];
  readonly reach: 'team' | 'company' | 'me';
  readonly stickyHeader?: boolean;
}

export const TaskTable = ({
  tasks,
  openSelectedTaskModal,
  openEditTaskModal,
  markAsComplete,
  taskEditable = true,
  loading = false,
  setSelectionModel,
  selectionModel,
  currentUserId,
  deleteAction,
  remindAction,
  sorting: [sortingState, setSortingState],
  pagination: [paginationState, setPaginationState],
  reach,
  stickyHeader,
}: TaskTableProperties): JSX.Element => {
  const { polyglot } = usePolyglot();

  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: currentUserId });
  const hasTaskAllcope = hasScopes(['task:all'], scopesContext);

  const sortedTaskItems = useMemo(
    () =>
      // for some reason, items sorted in ascending order appear in reverse order in the table
      // so we manually reverse them here so they end up displayed in the correct order.
      sortingState[0]?.desc === false ? tasks.items.slice().reverse() : tasks.items.slice(),
    [sortingState, tasks.items]
  );

  const getTasksActionsOptions = useCallback(
    (original: TaskDto) => {
      const menuOptions = [
        {
          icon: <Edit {...iconSize} />,
          handler: () => {
            openEditTaskModal(original);
          },
          label: polyglot.t('TaskTable.edit'),
          disabled: false,
        },
        {
          icon: <Trash {...iconSize} />,
          handler: () => {
            deleteAction(original.id);
          },
          label: polyglot.t('TaskTable.delete'),
          disabled: false,
        },
      ];
      if (original.status !== TaskStatuses.COMPLETE)
        menuOptions.push({
          icon: <Mail {...iconSize} />,
          handler: async () => {
            await remindAction(original.id);
          },
          label: polyglot.t('TaskTable.remind'),
          disabled: false,
        });
      return menuOptions;
    },
    [polyglot, openEditTaskModal, deleteAction, remindAction]
  );

  const columns = useMemo<ColumnDef<TaskDto, TaskDto>[]>(
    () => [
      ...(hasTaskAllcope
        ? [
            {
              id: 'select',
              enableSorting: false,
              minSize: 20,
              maxSize: 20,
              header: () => {
                const displayedTaskIds = new Set(tasks.items.map(({ id }) => id));
                const allSelected =
                  selectionModel.length > 0 &&
                  selectionModel.length === displayedTaskIds.size &&
                  selectionModel.every((id) => displayedTaskIds.has(id));
                return (
                  <Box sx={{ display: 'initial' }} onClick={(e) => e.stopPropagation()}>
                    <CheckboxComponent
                      label={undefined}
                      name={'allSelected'}
                      checked={allSelected}
                      value="allSelceted"
                      onChange={(_, checked) => {
                        setSelectionModel(checked ? [...displayedTaskIds] : []);
                      }}
                    />
                  </Box>
                );
              },
              cell: ({ row: { original } }: { row: Row<TaskDto> }) => (
                <Box onClick={(e) => e.stopPropagation()}>
                  <CheckboxComponent
                    label={undefined}
                    name={original.id?.toString()}
                    checked={selectionModel.includes(original.id)}
                    value={original.id?.toString()}
                    onChange={() => {
                      let finalArray: number[];
                      if (selectionModel?.includes(original.id)) {
                        finalArray = selectionModel.filter((sm) => sm !== original.id);
                      } else finalArray = [...selectionModel, original.id];
                      setSelectionModel(finalArray);
                    }}
                  />
                </Box>
              ),
            },
          ]
        : []),
      {
        id: 'name',
        header: () => polyglot.t('TaskTable.name'),
        accessorFn: (row) => row.name,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name ?? ''),
        maxSize: 200,
        minSize: 100,
        cell: ({ row: { original } }) => (
          <TooltipCell
            title={original.name ?? ''}
            maxWidth={200}
            cell={<div>{original.name ? <Box>{original.name}</Box> : <EmptyCell />}</div>}
          />
        ),
      },
      // Temporarily hidden, on Chris' suggestion
      // {
      //   id: 'description',
      //   header: () => 'Description',
      //   accessorFn: (row) => row,
      //   enableSorting: true,
      //   sortingFn: (a, b) => sortString(a, b, (item) => item.description),
      //   maxSize: 260,
      //   minSize: 100,
      //   cell: ({ row: { original } }) => (
      //     <TooltipCell
      //       title={original.description ?? ''}
      //       maxWidth={260}
      //       cell={
      //         <div>
      //           {original.description ? (
      //             <Box>
      //               <Typography variant="caption" dangerouslySetInnerHTML={{ __html: original.description ?? '' }} truncateHtml />
      //             </Box>
      //           ) : (
      //             <EmptyCell />
      //           )}
      //         </div>
      //       }
      //     />
      //   ),
      // },
      {
        id: 'checklist',
        header: () => polyglot.t('TaskTable.checklist'),
        accessorFn: (row) => row,
        enableSorting: false,
        maxSize: 80,
        // minSize: 50,
        cell: ({ row: { original } }) => (
          <div>
            {original?.checklist?.bgImg && (
              <Box>
                {emojiOptions.find((emo) => emo.value === original?.checklist?.bgImg)?.label ??
                  original?.checklist?.bgImg}
              </Box>
            )}
          </div>
        ),
      },
      {
        id: 'assignedUser',
        header: () => polyglot.t('TaskTable.assignedUser'),
        accessorFn: (row) => row,
        enableSorting: true,
        maxSize: 160,
        // minSize: 130,
        cell: (c) => {
          const assignedUserId = c.getValue().assignedUserId;
          return assignedUserId ? <UserCell userId={assignedUserId} /> : <EmptyCell />;
        },
      },
      {
        id: 'requestedFor',
        header: () => polyglot.t('TaskTable.requestedFor'),
        accessorFn: (row) => row,
        enableSorting: true,
        maxSize: 160,
        // minSize: 130,
        cell: (c) => {
          const requestedForId = c.getValue().requestedForId;
          return requestedForId ? <UserCell userId={requestedForId} /> : <EmptyCell />;
        },
      },
      {
        id: 'dueDate',
        header: () => polyglot.t('TaskTable.dueDate'),
        enableSorting: true,
        accessorFn: (row) => row,
        maxSize: 100,
        minSize: 80,
        cell: ({ row: { original } }) => (
          <div>{original.dueDate ? getDateString(original.dueDate) : <EmptyCell />}</div>
        ),
      },
      {
        id: 'status',
        header: () => polyglot.t('TaskTable.status'),
        accessorFn: (row) => row,
        maxSize: 100,
        minSize: 80,
        enableSorting: true,
        cell: ({ row: { original } }) => <div>{getTaskStatus(original, polyglot) ?? <EmptyCell />}</div>,
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        maxSize: 80,
        cell: ({ row: { original } }: { row: Row<TaskDto> }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: spacing.g5 }}>
            {taskEditable && (
              <ScopesControl
                scopes={['task:all']}
                context={original.assignedUserId ? { userId: original.assignedUserId } : undefined}
              >
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <StyledMenuComponent
                    options={getTasksActionsOptions(original)}
                    actionButtonDetails={{
                      type: 'iconButton',
                      colorVariant: 'secondary',
                      sizeVariant: 'small',
                      title: 'actions',
                      icon: <ActionsSmall {...iconSize} />,
                    }}
                  />
                </Box>
              </ScopesControl>
            )}
            {original &&
              original.status === TaskStatuses.INCOMPLETE &&
              currentUserId === original.assignedUserId &&
              reach !== 'me' && (
                <ScopesControl
                  scopes={['task', 'task:all']}
                  context={original.assignedUserId ? { userId: original.assignedUserId } : undefined}
                >
                  <IconButton
                    onClick={(e) => {
                      markAsComplete(original);
                      e.stopPropagation();
                    }}
                    aria-label="mark-as-complete"
                    sx={tablePrimaryIconButtonSx}
                  >
                    <Chose {...iconSize} />
                  </IconButton>
                </ScopesControl>
              )}
          </Box>
        ),
      },
    ],
    [
      hasTaskAllcope,
      tasks.items,
      selectionModel,
      setSelectionModel,
      polyglot,
      taskEditable,
      getTasksActionsOptions,
      currentUserId,
      reach,
      markAsComplete,
    ]
  );

  const handleRowClick = useCallback(
    (row: Row<TaskDto>) => {
      openSelectedTaskModal?.(row.original);
    },
    [openSelectedTaskModal]
  );

  return (
    <>
      <BasicServerTable<TaskDto>
        loading={loading}
        rowData={sortedTaskItems}
        columnData={columns}
        rowClick={handleRowClick}
        pagination={paginationState}
        setPagination={setPaginationState}
        totalPages={tasks.totalPages}
        totalItems={tasks.totalItems}
        sorting={sortingState}
        setSorting={setSortingState}
        stickyHeader={stickyHeader}
      />
    </>
  );
};
