import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { SiteWithMembersDto } from '@v2/feature/site/site.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { SiteAPI } from '@/api-client/index.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SiteFormModal } from '@/v2/feature/site/site-settings/features/components/site-form-modal.component';
import { SiteListingTable } from '@/v2/feature/site/site-settings/features/components/site-listing-table.component';
import { SiteViewModal } from '@/v2/feature/site/site-settings/features/components/site-view-modal.component';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SiteSettingsPage = (): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [sites, setSites] = useState<SiteWithMembersDto[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [showMessage] = useMessage();
  const [selectedSite, setSelectedSite] = useState<SiteWithMembersDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const { trackPage } = useJune();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);

  const refreshSites = useCallback(async () => {
    setLoading(true);
    try {
      const siteData = await SiteAPI.getSitesWithMemberDetails();
      setSites(siteData);
    } catch (error) {
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refreshSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredSites = useMemo(() => {
    return searchInput.length > 0
      ? sites.filter((user) => user.name.toLowerCase().includes(searchInput.toLowerCase()))
      : sites;
  }, [sites, searchInput]);

  useEffect(() => {
    trackPage('Company site settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">Sites</Typography>}
        showBack
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsFormOpen(true)}>
            {polyglot.t('SiteSettingsPage.addNewSite')}
          </ButtonComponent>
        }
        showAction
      />
      <ContentWrapper>
        <TableSearch
          query={searchInput}
          handleChange={(e) => {
            setSearchInput(e.target.value);
          }}
        />
        <Box sx={{ ...spacing.mt20 }}>
          <SiteListingTable
            sites={filteredSites}
            loading={loading}
            setIsFormOpen={setIsFormOpen}
            setIsViewOpen={setIsViewOpen}
            setSelectedSite={setSelectedSite}
          />

          <SiteFormModal
            isOpen={isFormOpen}
            setIsOpen={setIsFormOpen}
            selectedSite={selectedSite}
            refreshSites={refreshSites}
            closePage={() => {
              setIsViewOpen(false);
              setIsFormOpen(false);
              setTimeout(() => setSelectedSite(undefined), 500);
            }}
          />

          <SiteViewModal
            isOpen={isViewOpen}
            setIsOpen={setIsViewOpen}
            selectedSite={selectedSite}
            refreshSites={refreshSites}
            setIsFormOpen={setIsFormOpen}
            closePage={() => {
              setIsViewOpen(false);
              setTimeout(() => setSelectedSite(undefined), 500);
            }}
          />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
