import { useCallback, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { TableSearch } from '@v2/components/table/table-search.component';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { GrowthFactorFormModal } from '@/v2/feature/growth/growth-factor/components/growth-factor-form-modal.component';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { iconSize } from '@/v2/feature/onboarding/onboarding-template-edit.page';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const GrowthFactorPage = () => {
  const { data: allGrowthFactors, isLoading, mutate: refreshFactors } = useApiClient(
    GrowthFactorEndpoints.getGrowthFactorsByCompanyId(),
    {
      suspense: false,
    }
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedFactor, setSelectedFactor] = useState<GrowthFactor | undefined>(undefined);
  const [searchInput, setSearchInput] = useState('');

  const filteredFactors = useMemo(() => {
    if (!searchInput || !allGrowthFactors) return allGrowthFactors ?? [];

    const search = searchInput.toLowerCase();

    return allGrowthFactors.filter((f) => f.name.toLowerCase().includes(search));
  }, [searchInput, allGrowthFactors]);

  const getGrowthFactorActionsOptions = useCallback((original: GrowthFactor) => {
    return [
      {
        icon: <Edit {...iconSize} />,
        handler: () => {
          setSelectedFactor(original);
          setIsOpen(true);
        },
        label: 'Edit',
        disabled: false,
      },
    ];
  }, []);

  return (
    <ContentWrapper loading={false}>
      <SettingsSectionContent
        title="Factors"
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsOpen(true)}>
            New factor
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          loadingAll={isLoading}
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <TableSearch
                      query={searchInput}
                      handleChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {(filteredFactors || []).map((item) => {
                        const avatar =
                          item.companyId === null ? (
                            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
                              <PayrollLogoZelt height="0.9em" width="0.9em" padding=".25em" />
                              Zelt
                            </Stack>
                          ) : (
                            <UserCell userId={item.createdBy} />
                          );
                        return (
                          <SettingsItemCard
                            key={item.name}
                            title={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
                                <div
                                  style={{
                                    backgroundColor: item.color ?? themeColors.Grey,
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: radius.br25,
                                  }}
                                />
                                <Typography variant="title4">{item.name}</Typography>
                              </Box>
                            }
                            boxSx={responsiveCardStyle}
                            contentItemsSets={[
                              {
                                type: ContentItemType.component,
                                contentComponent: (
                                  <ChipComponent
                                    name={avatar}
                                    backgroundColor="white"
                                    textColor="DarkGrey"
                                    textVariant="caption"
                                    border="middle"
                                  />
                                ),
                              },
                              {
                                name: `${new LocalDate(item.createdAt).getDate().toLocaleDateString(undefined, {
                                  day: 'numeric',
                                  month: 'short',
                                  year: 'numeric',
                                })}`,
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                            ]}
                            advanceActionHidden={!item.companyId}
                            advanceActionOptions={getGrowthFactorActionsOptions(item)}
                          />
                        );
                      })}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>

      <GrowthFactorFormModal
        growthFactor={selectedFactor}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setSelectedFactor(undefined);
        }}
        refresh={async () => {
          await refreshFactors?.();
        }}
      />
    </ContentWrapper>
  );
};
