import React, { ReactElement, ReactNode } from 'react';

import { Box, SxProps, Theme } from '@mui/material';
import { Typography, TypographyVariant } from '@v2/components/typography/typography.component';
import {
  ActivationLock,
  AppliedDevicePoliciesDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurableAutomaticOsUpdate,
  DeviceDto,
  DeviceModelDto,
  DevicePossessionDto,
  FileVaultOptions,
  FirewallOptions,
  ICloudRestrictionOptions,
  LoginItemsOptions,
  ParentalControlsContentFilterOptions,
  PasswordOption,
  RestrictionAdditionalPasswordOption,
  RestrictionOsUpdatesOptions,
  RestrictionScreenOptions,
  RestrictionSecurityOptions,
  ScreenLoginSettingsOptions,
  WifiOptions,
} from '@v2/feature/device/device.dto';
import {
  ConfigurablePoliciesByZeltMdm,
  DeliveryMethod,
  DeviceContractLength,
  DeviceExternalMatching,
  DeviceMapping,
  DeviceOrderStatus,
  DevicePolicyType,
  DevicePossessionType,
  DeviceShippingMethod,
  DeviceTransitStatus,
  DeviceType,
  OrderDeliveryCountry,
  SecurityStatus,
} from '@v2/feature/device/device.interface';
import { SiteDto } from '@v2/feature/site/site.dto';
import { themeColors } from '@v2/styles/colors.styles';
import { differenceInDays } from 'date-fns';
import Polyglot from 'node-polyglot';

import AcerAspire3 from '@/images/devices/acer-aspire-3.jpg';
import DellInspiron15 from '@/images/devices/dell-inspiron-15.png';
import DellMonitorP2422h from '@/images/devices/dell-monitor-p2422h.jpg';
import DellXPS from '@/images/devices/dell-xps.webp';
import Dell274KMonitor from '@/images/devices/Dell274KMonitor.webp';
import Dell27Monitor from '@/images/devices/Dell27Monitor.webp';
import Dell32Curved4KUHDMonitor from '@/images/devices/Dell32Curved4KUHDMonitor.webp';
import pixel8 from '@/images/devices/Google_Pixel_8.webp';
import HeadsetStore from '@/images/devices/Headset.webp';
import ipad12pro from '@/images/devices/ipad12pro.webp';
import iphone13pro from '@/images/devices/iphone13pro.webp';
import KeyboardStore from '@/images/devices/Keyboard.webp';
import LaptopStore from '@/images/devices/Laptop.svg';
import LenovoIdeapad from '@/images/devices/lenovo-ideapad.webp';
import LenovoTabM7 from '@/images/devices/lenovo-tab-m7.webp';
import LenovoThinkpadCarbonX1 from '@/images/devices/lenovo-thinkpad-carbon-x1.webp';
import LGGram from '@/images/devices/lg-gram.png';
import MacbookAir from '@/images/devices/macbook-air.webp';
import MacbookPro from '@/images/devices/macbook-pro.webp';
import MicrosoftSurface from '@/images/devices/MicrosoftSurface.webp';
import MonitorStore from '@/images/devices/Monitor.svg';
import MouseStore from '@/images/devices/Mouse.webp';
import OtherStore from '@/images/devices/Other.webp';
import PhoneStore from '@/images/devices/Phone.svg';
import TabletStore from '@/images/devices/Tablet.svg';
import { ReactComponent as Clock } from '@/images/side-bar-icons/Clock.svg';
import { ReactComponent as MistakeIcon } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Checked } from '@/images/side-bar-icons/Ok.svg';
import { ReactComponent as Problem } from '@/images/side-bar-icons/problem.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as Shipping } from '@/images/side-bar-icons/Shipping.svg';
import { ReactComponent as Waiting } from '@/images/side-bar-icons/Waiting.svg';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { EnrollmentStatus } from '@/v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { spacing } from '@/v2/styles/spacing.styles';

export const DEVICES_RENTAL_TAX_RATE = 0.2;

interface Entity {
  readonly value: string | number;
  readonly label: string;
  readonly imgSrc?: string;
}

export const MDMABLE_DEVICE_TYPES = [DeviceType.Laptop, DeviceType.Tablet, DeviceType.Phone, DeviceType.Desktop];

export const DeviceOSMacOS = { value: 'macos', label: 'MacOS' };
export const DeviceOSiOS = { value: 'ios', label: 'iOS' };
export const DeviceOSiPadOS = { value: 'ipados', label: 'iPadOS' };
export const DeviceOSAndroid = { value: 'android', label: 'Android' };
export const DeviceOSWindows = { value: 'windows', label: 'Windows' };
export const DeviceOSLinux = { value: 'linux', label: 'Linux' };
export const DeviceOSOther = { value: 'other', label: 'Other' };
export const DeviceOSValueLabelOptions: Entity[] = [
  DeviceOSMacOS,
  DeviceOSiOS,
  DeviceOSiPadOS,
  DeviceOSWindows,
  DeviceOSLinux,
  DeviceOSAndroid,
  DeviceOSOther,
];
export const DeviceTypesValueLabelOptions: Entity[] = [
  DeviceType.Laptop,
  DeviceType.Desktop,
  DeviceType.Phone,
  DeviceType.Tablet,
  DeviceType.Monitor,
  DeviceType.Headset,
  DeviceType.Mouse,
  DeviceType.Keyboard,
  DeviceType.Other,
].map((type) => ({ label: type, value: type }));

export const ManufacturerOptions = [
  'Acer',
  'Apple',
  'Asus',
  'Dell',
  'Google',
  'Lenovo',
  'LG',
  'Microsoft',
  'Samsung',
  'Other',
];
export const ManufacturerValueLabelOptions: Entity[] = ManufacturerOptions.map((option) => ({
  label: option,
  value: option.toLowerCase(),
}));

export const RamValueLabelOptions: Entity[] = [
  { label: '8GB', value: 8 },
  { label: '16GB', value: 16 },
  { label: '32GB', value: 32 },
];

export const StorageValueLabelOptions = [
  { label: '256GB', value: 256 },
  { label: '512GB', value: 512 },
  { label: '1024GB', value: 1024 },
];

export const IsAvailableValueLabelOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const DeviceConditionValueLabelOptions = [{ label: 'New', value: 'new' }];

export enum DeviceShopFilter {
  Manufacturer = 'Manufacturer',
  Type = 'Type',
  RAM = 'RAM',
  Storage = 'Storage',
  OS = 'OS',
  IsAvailable = 'Is Available',
  Condition = 'Condition',
}

export const DeviceTypeFilterOptions = [
  { label: 'Laptop', value: DeviceType.Laptop },
  { label: 'Desktop', value: DeviceType.Desktop },
  { label: 'Tablet', value: DeviceType.Tablet },
  { label: 'Phone', value: DeviceType.Phone },
  { label: 'Monitor', value: DeviceType.Monitor },
  { label: 'Headset', value: DeviceType.Headset },
  { label: 'Keyboard', value: DeviceType.Keyboard },
  { label: 'Mouse', value: DeviceType.Mouse },
  { label: 'Other', value: DeviceType.Other },
];

export const DeviceOrderStatusToProgressScore: { [key: string]: number } = {
  [DeviceOrderStatus.Cancelled]: 0,
  [DeviceOrderStatus.Rejected]: 0,
  [DeviceOrderStatus.Placed]: 0,
  [DeviceOrderStatus.Accepted]: 35,
  [DeviceOrderStatus.Shipping]: 68,
  [DeviceOrderStatus.Delivered]: 100,
};

export const DEVICE_ORDER_CONTRACT_LENGTH_OPTIONS = [
  { value: DeviceContractLength.Months24, label: '24 months' },
  { value: DeviceContractLength.Months36, label: '36 months' },
];

export const DeviceRentOption = { value: 'rent', label: 'Rent' };
export const DevicePurchaseOption = { value: 'purchase', label: 'Buy' };
export const DeviceOwnershipOptions = [DeviceRentOption, DevicePurchaseOption];

export const DeliveryCountryUK = { value: OrderDeliveryCountry.UK, label: 'UK' };
export const DeliveryCountryEU = { value: OrderDeliveryCountry.EU, label: 'EU' };
export const DeliveryCountryOptions = [DeliveryCountryUK, DeliveryCountryEU];

export const DeliveryMethodsOptions = [
  { value: DeliveryMethod.ShippingToEmployee, label: 'Shipping to Employee' },
  { value: DeliveryMethod.ShippingToSite, label: 'Shipping to Site' },
  { value: DeliveryMethod.Handover, label: 'Handover' },
];

export const DeviceShippingMethodsOptions = [
  { value: DeviceShippingMethod.NoTrackingReassignInstantly, label: 'No tracking, reassign instantly' },
  { value: DeviceShippingMethod.RequestZeltShipping, label: 'Request Zelt shipping' },
];

export const getDeviceOwnerByDevicePossession = (
  devicePossession: DevicePossessionDto | undefined,
  site: SiteDto | undefined | null,
  getCachedUserById: (userId: number) => CachedUser | undefined
): string => {
  if (!devicePossession) return 'Unknown';

  if (devicePossession.possessionType === DevicePossessionType.User) {
    return getCachedUserById(devicePossession.possessionId)?.displayName ?? 'Unknown';
  }
  if (devicePossession.possessionType === DevicePossessionType.CompanySite)
    return site ? `${site.name} Site` : 'Company Site';

  if (devicePossession.possessionType === DevicePossessionType.ZeltStorage) return 'Zelt Storage';

  if (devicePossession.possessionType === DevicePossessionType.ZeltStock) return 'Zelt Stock';

  return 'Unknown';
};

export const getUserNamesKeyedByUserIds = (
  users: readonly { userId: number; firstName: string; lastName: string; displayName?: string }[]
): { [userId: number]: string } => {
  return users.reduce((accumulator: { [key: number]: string }, user) => {
    accumulator[user.userId] = user.displayName ?? `${user.firstName} ${user.lastName}`;
    return accumulator;
  }, {});
};

export const getCompanyNamesKeyedByCompanyIds = (
  companies: readonly { companyId: number; name: string }[]
): { [companyId: number]: string } => {
  return companies.reduce((accumulator: { [key: number]: string }, company) => {
    accumulator[company.companyId] = company.name;
    return accumulator;
  }, {});
};

export const getDeviceOwnerAsSuperadminByDevicePossession = (
  devicePossession: DevicePossessionDto | undefined,
  usersNames: { [userId: number]: string },
  sitesById: { [id: number]: SiteDto }
): string => {
  if (!devicePossession) return 'Unknown';

  if (devicePossession.possessionType === DevicePossessionType.User) {
    return usersNames[devicePossession.possessionId] ?? 'Unknown';
  }
  if (devicePossession.possessionType === DevicePossessionType.CompanySite)
    return sitesById[devicePossession.possessionId]
      ? `${sitesById[devicePossession.possessionId]?.name} Site`
      : 'Company Site';

  if (devicePossession.possessionType === DevicePossessionType.ZeltStorage) return 'Zelt Storage';

  if (devicePossession.possessionType === DevicePossessionType.ZeltStock) return 'Zelt Stock';

  return 'Unknown';
};

export const getDeviceOwnerByDevicePossessionTable = (
  devicePossession: DevicePossessionDto | undefined,
  site: SiteDto | undefined,
  polyglot: Polyglot,
  displayName: string | undefined
): React.JSX.Element | string => {
  if (!devicePossession) return 'Unknown';

  if (devicePossession.possessionType === DevicePossessionType.User && displayName) {
    return devicePossession.possessionId ? (
      <Box
        key={devicePossession.id}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: spacing.m5,
        }}
      >
        <UserAvatar key={`avatar-${devicePossession.id}`} userId={devicePossession.possessionId} size="xxsmall" />
        {polyglot.t(displayName ?? '')}
      </Box>
    ) : (
      'Unknown1'
    );
  }
  if (devicePossession.possessionType === DevicePossessionType.User) return 'Could not identify user owner';
  if (devicePossession.possessionType === DevicePossessionType.CompanySite) {
    return site ? `${site.name} Site` : 'Company Site';
  }

  if (devicePossession.possessionType === DevicePossessionType.ZeltStorage) return 'Zelt Storage';

  if (devicePossession.possessionType === DevicePossessionType.ZeltStock) return 'Zelt Stock';

  return 'Unknown';
};

const deviceImageMapping: Record<DeviceType, string | DeviceMapping> = {
  [DeviceType.Laptop]: {
    lenovo: {
      x1: LenovoThinkpadCarbonX1,
      ideapad: LenovoIdeapad,
      thinkbook: LenovoThinkpadCarbonX1,
    },
    acer: {
      aspire: AcerAspire3,
    },
    dell: {
      xps: DellXPS,
      inspiron: DellInspiron15,
    },
    lg: {
      gram: LGGram,
    },
    macbookair: MacbookAir,
    macbookpro: MacbookPro,
    surface: MicrosoftSurface,
  },
  [DeviceType.Tablet]: {
    lenovotabm7: LenovoTabM7,
    ipad: ipad12pro,
  },
  [DeviceType.Phone]: {
    iphone: iphone13pro,
    pixel: pixel8,
  },
  [DeviceType.Monitor]: {
    dell32curved4k: Dell32Curved4KUHDMonitor,
    dell274k: Dell274KMonitor,
    dell27: Dell27Monitor,
    dellmonitor24: DellMonitorP2422h,
  },
  [DeviceType.Desktop]: MonitorStore,
  [DeviceType.Keyboard]: KeyboardStore,
  [DeviceType.Mouse]: MouseStore,
  [DeviceType.Headset]: HeadsetStore,
  [DeviceType.Other]: OtherStore,
};

const getImageSrc = (type: DeviceType | null | undefined, name: string) => {
  if (!type || type === null) return deviceImageMapping[DeviceType.Other];

  const typeMapping = deviceImageMapping[type] || deviceImageMapping[DeviceType.Other];
  if (typeof typeMapping === 'string') {
    return typeMapping;
  }

  for (const key in typeMapping) {
    if (name.includes(key)) {
      const nestedMapping = (typeMapping as DeviceMapping)[key];
      if (typeof nestedMapping === 'string') {
        return nestedMapping;
      }

      for (const nestedKey in nestedMapping) {
        if (name.includes(nestedKey)) {
          return nestedMapping[nestedKey];
        }
      }
    }
  }

  switch (type) {
    case DeviceType.Laptop:
      return LaptopStore;
    case DeviceType.Tablet:
      return TabletStore;
    case DeviceType.Phone:
      return PhoneStore;
    case DeviceType.Monitor:
      return MonitorStore;
    case DeviceType.Desktop:
      return MonitorStore;
    case DeviceType.Keyboard:
      return KeyboardStore;
    case DeviceType.Mouse:
      return MouseStore;
    case DeviceType.Headset:
      return HeadsetStore;
    default:
      return OtherStore;
  }
};

export const getModelImage = (
  type: DeviceType | null | undefined,
  modelName: string | null | undefined,
  size = {
    width: '100%',
    height: 'auto',
  }
) => {
  const name = (modelName ?? '').toLowerCase().replaceAll(' ', '');
  const imgSrc = getImageSrc(type, name);

  return <img alt={name} src={imgSrc as string} {...size} loading="lazy" style={{ objectFit: 'contain' }} />;
};
const iconSize = { width: 14, height: 14 } as const;

export const getOrderStatus = (status: DeviceOrderStatus): ReactElement => {
  switch (status) {
    case DeviceOrderStatus.Placed:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', color: themeColors.Grey }}>
          <Clock {...iconSize} style={{ fill: themeColors.Grey }} /> Placed
        </Box>
      );
    case DeviceOrderStatus.Requested:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', color: themeColors.Grey }}>
          <Clock {...iconSize} style={{ fill: themeColors.Grey }} />
          {status}
        </Box>
      );

    case DeviceOrderStatus.Rejected:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', color: themeColors.Red }}>
          <Rejected {...iconSize} fill={themeColors.Red} /> {status}
        </Box>
      );

    case DeviceOrderStatus.Cancelled:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Problem {...iconSize} /> {status}
        </Box>
      );

    case DeviceOrderStatus.Accepted:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} /> Accepted
        </Box>
      );

    case DeviceOrderStatus.Shipping:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Shipping {...iconSize} /> Shipping
        </Box>
      );

    case DeviceOrderStatus.Delivered:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <OkGreen {...iconSize} /> {status}
        </Box>
      );

    default:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Waiting {...iconSize} style={{ fill: themeColors.middleGrey }} /> {status}
        </Box>
      );
  }
};

export const getTransitStatus = (status: DeviceTransitStatus): ReactElement => {
  switch (status) {
    case DeviceTransitStatus.Pending:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', color: themeColors.Grey }}>
          <Clock {...iconSize} fill={themeColors.Grey} /> Pending
        </Box>
      );
    case DeviceTransitStatus.Rejected:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Rejected {...iconSize} fill={themeColors.Red} /> {status}
        </Box>
      );

    case DeviceTransitStatus.Cancelled:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Problem {...iconSize} /> {status}
        </Box>
      );

    case DeviceTransitStatus.Shipping:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Shipping {...iconSize} /> Shipping
        </Box>
      );

    case DeviceTransitStatus.Delivered:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} /> {status}
        </Box>
      );

    default:
      return (
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <Waiting {...iconSize} style={{ fill: themeColors.middleGrey }} /> {status}
        </Box>
      );
  }
};

export const getFullPriceDetails = (deviceModel: DeviceModelDto, deliveryCountry: OrderDeliveryCountry) => {
  const price = deliveryCountry === OrderDeliveryCountry.UK ? deviceModel.fullPrice : deviceModel.fullPriceEu;
  return (
    <Box sx={{ display: 'block' }}>
      <Typography variant="title4">Payment details</Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography variant="caption">Purchase price (ex. VAT)</Typography>
        <Typography variant="title2">£{price}</Typography>
      </Box>
    </Box>
  );
};

export const getRentalPaymentDetails = (
  deviceModel: DeviceModelDto,
  contractLength: number,
  deliveryCountry: OrderDeliveryCountry
): ReactNode => {
  const isEUDelivery = deliveryCountry === OrderDeliveryCountry.EU;
  const devicePrice = isEUDelivery
    ? contractLength === 24
      ? deviceModel.priceEu24
      : deviceModel.priceEu36
    : contractLength === 24
    ? deviceModel.price24
    : deviceModel.price36;

  return (
    <Box sx={{ display: 'block' }}>
      <Typography variant="title4">Payment details</Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Typography variant="caption">Monthly Rent (ex. VAT)</Typography>
        <Typography variant="title2">£{devicePrice}</Typography>
      </Box>
    </Box>
  );
};

export const FieldValueComponent = ({
  title,
  value,
  titleSx = {},
  titleVariant = 'caption',
  titleColor,
}: {
  title: string;
  value: string | React.JSX.Element;
  titleSx?: SxProps<Theme>;
  titleVariant?: TypographyVariant;
  titleColor?: any;
}) => {
  return (
    <Box sx={{ display: 'flex', gap: spacing.s1 }}>
      <Typography variant={titleVariant} color={titleColor} sx={titleSx}>
        {title}
      </Typography>
      <Typography variant="title4">{value}</Typography>
    </Box>
  );
};

const getDeviceSecurityScore = (selectedPolicies: string[], device: DeviceDto): number => {
  let counter = 0;

  const checkPolicyApplies = (policyTypeName: string, deviceProperty: boolean | null): void => {
    if (selectedPolicies.includes(policyTypeName) && deviceProperty) {
      counter += 1;
    }
  };
  const checkPolicyApplied = (policyTypeName: string, deviceProperty: boolean | null): boolean => {
    if (selectedPolicies.includes(policyTypeName) && deviceProperty) {
      return true;
    }
    return false;
  };
  const checkHexnodePolicies = (policyType: DevicePolicyType, externalId: number): void => {
    if (selectedPolicies.includes(policyType) && device.policies?.some((p) => p.id === externalId)) {
      counter += 1;
    }
  };

  if (device.inHouseMdm) {
    checkPolicyApplies(ConfigurablePoliciesByZeltMdm.configurablePasscode, device.passwordProtected);
    checkPolicyApplies(ConfigurablePoliciesByZeltMdm.configurableICloudRestriction, device.icloudRestrictionEnabled);
    checkPolicyApplies(ConfigurablePoliciesByZeltMdm.configurableFirewallPolicy, device.firewallEnabled);
    if (device.latestAvailableOsVersion && device.osVersion && device.latestAvailableOsVersion <= device.osVersion)
      counter += 1;
    if (device.availableStorage && device.storage) {
      const memoryPercentage =
        device.storage && device.storage > 0 && device.availableStorage
          ? 100 - (device.availableStorage / device.storage) * 100
          : 0;

      if (memoryPercentage < 80) {
        counter += 1;
      }
    }
    if (device.activationLockBypassKey) {
      counter += 1;
    }
    if (
      checkPolicyApplied(ConfigurablePoliciesByZeltMdm.configurableEncryptionPolicy, device.encryptionEnabled) &&
      device.filevaultKey
    ) {
      counter += 1;
    }
    if (device.lastCheckIn && differenceInDays(new Date(), new Date(device.lastCheckIn)) <= 3) counter += 1;
  } else {
    checkPolicyApplies(DevicePolicyType.passcode.valueOf(), device.passwordProtected);
    checkPolicyApplies(DevicePolicyType.encryption.valueOf(), device.encryptionEnabled);
    checkPolicyApplies(DevicePolicyType.firewall.valueOf(), device.firewallEnabled);
    checkHexnodePolicies(DevicePolicyType.location, 17);
    if (device.lastCheckIn && differenceInDays(new Date(), new Date(device.lastCheckIn)) <= 3) counter += 1;
  }
  return counter;
};

const supportedPoliciesToSecurityScore = [
  'configurableFirewallPolicy',
  'configurableEncryptionPolicy',
  'configurableICloudRestriction',
  'configurablePasscode',
  'configurableActivationLock',
];
const calculateSecurityScore = (
  device: DeviceDto,
  appliedZeltPolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined,
  appliedZeltMobilePolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined,
  appliedHexPolicies: AppliedDevicePoliciesDto | undefined
): { maxScore: number; securityScore: number } => {
  let policiesSelectedToApply: string[] = [];

  function processSettings(
    policyValues:
      | number[]
      | PasswordOption
      | ICloudRestrictionOptions
      | RestrictionAdditionalPasswordOption
      | FileVaultOptions
      | FirewallOptions
      | (RestrictionScreenOptions & ScreenLoginSettingsOptions)
      | ParentalControlsContentFilterOptions
      | RestrictionSecurityOptions
      | LoginItemsOptions
      | WifiOptions
      | ConfigurableAutomaticOsUpdate
      | RestrictionOsUpdatesOptions
      | ActivationLock
  ) {
    if (Array.isArray(policyValues)) {
      return policyValues.length > 0;
    } else if (policyValues !== null && 'ActivationLockAllowedWhileSupervised' in policyValues) {
      if (
        policyValues.ActivationLockAllowedWhileSupervised &&
        policyValues.ActivationLockAllowedWhileSupervised === true
      ) {
        return true;
      }
    } else {
      return Object.keys(policyValues).length > 0;
    }
  }

  if (device.inHouseMdm) {
    const mobileDevices = ['ipados', 'ios'];
    const isMobileAppleDevice = mobileDevices.includes(device.os ?? '');
    if (appliedZeltPolicies && !isMobileAppleDevice) {
      policiesSelectedToApply = Object.keys(appliedZeltPolicies)
        .filter((key) => key.startsWith('configurable') && key !== 'configurableApps')
        .filter((key) => supportedPoliciesToSecurityScore.includes(key))
        .filter((key) => {
          const policyValues = appliedZeltPolicies[key as keyof AppliedDevicePoliciesZeltDtoWithConfigurableFeature];
          return policyValues && processSettings(policyValues);
        })
        .map((a) => a);
    }
    if (appliedZeltMobilePolicies && isMobileAppleDevice) {
      policiesSelectedToApply = Object.keys(appliedZeltMobilePolicies)
        .filter((key) => key.startsWith('configurable') && key !== 'configurableApps')
        .filter((key) => supportedPoliciesToSecurityScore.includes(key))
        .filter((key) => {
          const policyValues =
            appliedZeltMobilePolicies[key as keyof AppliedDevicePoliciesZeltDtoWithConfigurableFeature];
          return policyValues && processSettings(policyValues);
        })
        .map((a) => a);
    }
  } else {
    if (appliedHexPolicies) {
      policiesSelectedToApply = Object.keys(appliedHexPolicies)
        .filter(
          (p) =>
            (appliedHexPolicies as AppliedDevicePoliciesDto)[p as keyof AppliedDevicePoliciesDto].some(
              (a) => a !== 0
            ) &&
            p !== 'apps' &&
            p !== 'icloud' &&
            p !== 'updates'
        )
        .map((a) => a);
    }
  }

  let maxScore = policiesSelectedToApply.length;
  if (device.inHouseMdm) {
    maxScore += 3; //we can check storage, os version, last active
  } else {
    maxScore += 1; //last active we can only check this for hexnode
  }

  return { maxScore, securityScore: getDeviceSecurityScore(policiesSelectedToApply, device) };
};

const getComplianceScoreOfDevicePossessionByMDM = (
  devicePossession: DevicePossessionDto | undefined,
  appliedZeltPolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined,
  appliedZeltMobilePolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined,
  appliedHexPolicies: AppliedDevicePoliciesDto | undefined
): { status: SecurityStatus; tooltip: string | null; missingPoliciesCounter: number | null } => {
  if (!devicePossession) return { status: SecurityStatus.None, tooltip: null, missingPoliciesCounter: null };
  if (!devicePossession?.device) return { status: SecurityStatus.None, tooltip: null, missingPoliciesCounter: null };
  if (devicePossession.device.enrollmentStatus === 'unenrolled' || !devicePossession.device.enrollmentStatus)
    return { status: SecurityStatus.NotEnrolled, tooltip: 'Disenroll', missingPoliciesCounter: null };
  if (
    !Boolean(
      devicePossession.device.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED ||
        devicePossession.device.enrollmentStatus === 'enrolled'
    )
  )
    return { status: SecurityStatus.NotEnrolled, tooltip: 'MDM not installed', missingPoliciesCounter: null };

  const riskScore = calculateSecurityScore(
    devicePossession.device,
    appliedZeltPolicies,
    appliedZeltMobilePolicies,
    appliedHexPolicies
  );
  if (riskScore.maxScore > riskScore.securityScore) {
    return {
      status: SecurityStatus.Alert,
      tooltip: 'Some configurations are missing',
      missingPoliciesCounter: riskScore.maxScore - riskScore.securityScore,
    };
  }

  return { status: SecurityStatus.Good, tooltip: null, missingPoliciesCounter: 0 };
};

export const getSecurityStatusIcon = (
  status: SecurityStatus,
  tooltip: string | null,
  missingPoliciesCounter: number | null
): ReactNode => {
  if (status === SecurityStatus.NotEnrolled)
    return (
      <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
        <MistakeIcon {...iconSize} fill={themeColors.GreyMiddle} />
        <Typography variant="caption" color="Grey">
          {SecurityStatus.NotEnrolled}
        </Typography>
      </Box>
    );

  if (status === SecurityStatus.Alert)
    return (
      <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
        <MistakeIcon {...iconSize} fill={themeColors.Red} />
        <Typography variant="caption" color="Red">
          {missingPoliciesCounter} {missingPoliciesCounter === 1 ? 'alert' : 'alerts'}
        </Typography>
      </Box>
    );

  if (status === SecurityStatus.Good)
    return (
      <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
        <Checked {...iconSize} style={{ fill: themeColors.green }} />
        <Typography variant="caption">Good</Typography>
      </Box>
    );

  return <EmptyCell />;
};

export const getRiskStatus = (
  devicePossession: DevicePossessionDto,
  appliedZeltPolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined,
  appliedZeltMobilePolicies: AppliedDevicePoliciesZeltDtoWithConfigurableFeature | undefined,
  appliedHexPolicies: AppliedDevicePoliciesDto | undefined
): ReactNode => {
  const { status, tooltip, missingPoliciesCounter } = getComplianceScoreOfDevicePossessionByMDM(
    devicePossession,
    appliedZeltPolicies,
    appliedZeltMobilePolicies,
    appliedHexPolicies
  );
  if (devicePossession.device?.mdmable) return getSecurityStatusIcon(status, tooltip, missingPoliciesCounter);

  return <EmptyCell />;
};

export const getTechSpecs = (deviceModel: DeviceModelDto): string[] => {
  let detail: string[] = [];

  if (deviceModel.modelVersion) detail.push(`Model ${deviceModel.modelVersion}`);

  if (deviceModel.screenSize) detail.push(`Size ${deviceModel.screenSize} inch`);

  if (deviceModel.storage) detail.push(`Storage ${deviceModel.storage} GB`);

  if (deviceModel.gpuCores) detail.push(`GPU ${deviceModel.gpuCores} core`);

  if (deviceModel.cpuCores) detail.push(`CPU ${deviceModel.cpuCores} core`);

  if (deviceModel.ram) detail.push(`RAM ${deviceModel.ram} GB`);

  return detail;
};

export const isEnroled = (enrolmentStatus: string | null | undefined) => {
  if (!enrolmentStatus) {
    return false;
  }
  return ['ENROLMENT_FINISHED', 'enrolled'].includes(enrolmentStatus);
};

export const getMdmServer = (device: DeviceDto | null | undefined, matchings: DeviceExternalMatching) => {
  const mdmType = device?.inHouseMdm ? 'Zelt MDM' : 'Hexnode';
  const externalId = device?.id ? matchings[device.id] : null;
  const mdmResult = mdmType === 'Hexnode' && externalId === null ? 'None' : mdmType;
  return mdmResult;
};
