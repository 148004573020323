// eslint-disable-next-line filenames/match-regex
import { HEADER_FONT_PRIMARY } from '@/v2/styles/fonts.styles';

const SMART_FIELD_SECTION_HEADER_STYLE = `fontFamily:${HEADER_FONT_PRIMARY}; fontSize: 9; opacity: 0.4`;

// eslint-disable-next-line filenames/match-regex
export const getPersonalSmartFieldDropDown = () => `
<div class='smartfield__dropdown'>
  <div class='smart__field__options'>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Personal</span>
    <span class='smart__field__option' id='first_name_option'>First name</span>
    <span class='smart__field__option' id='last_name_option'>Last name</span>
    <span class='smart__field__option' id='full_name_option'>Full name</span>
    <span class='smart__field__option' id='dob_option'>Date of birth</span>
    <span class='smart__field__option' id='passport_number_option'>Passport number</span>
    <span class='smart__field__option' id='personal_email_option'>Personal email</span>
    <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Address</span>
    <span class='smart__field__option' id='address_option'>Full address</span>
    <span class='smart__field__option' id='address_line1_option'>Address line 1</span>
    <span class='smart__field__option' id='address_line2_option'>Address line 2</span>
    <span class='smart__field__option' id='address_city_option'>City</span>
    <span class='smart__field__option' id='address_country_option'>Country</span>
    <span class='smart__field__option' id='address_postcode_option'>Postcode</span>
  </div>
</div>`;

export const getWorkSmartFieldDropDown = () => `
<div class='smartfield__dropdown'>
  <div class='smart__field__options'>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Role</span>
  <span class='smart__field__option' id='start_date_option'>Start date</span>
    <span class='smart__field__option' id='role_option'>Job title</span>
    <span class='smart__field__option' id='reports_to_option'>Reports to</span>
    <span class='smart__field__option' id='site_option'>Site</span>
    <span class='smart__field__option' id='department_option'>Department</span>
    <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Contract</span>
    <span class='smart__field__option' id='probation_period_option'>Probation period</span>
    <span class='smart__field__option' id='notice_period_option'>Notice period</span>
    <span class='smart__field__option' id='worker_type_option'>Worker type</span>
    <span class='smart__field__option' id='employment_contract_option'>Employment contract</span>
    <span class='smart__field__option' id='attendance_schedule_option'>Attendance schedule hours</span>
  </div>
</div>`;

// FTE% removed from work options
// <span class='smart__field__option' id='fte_percentage_option'>Attendance schedule FTE%</span>

export const getCompensationSmartFieldDropDown = () => `
<div class='smartfield__dropdown'>
  <div class='smart__field__options'>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Salary</span>
  <span class='smart__field__option' id='salary_basis_option'>Salary basis</span>
  <span class='smart__field__option' id='rate_option'>Amount</span>
  <span class='smart__field__option' id='units_option'>Units</span>
  <span class='smart__field__option' id='pay_schedule_option'>Pay schedule</span>

  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Equity</span>
  <!--<span class='smart__field__option' id='equity_option'>Equity</span>-->
  <span class='smart__field__option' id='equity_type_option'>Equity type</span>
  <span class='smart__field__option' id='equity_grant_date_option'>Grant date</span>
  <span class='smart__field__option' id='equity_amount_option'>Amount</span>
  <span class='smart__field__option' id='equity_vesting_start_option'>Vesting start</span>
  <span class='smart__field__option' id='equity_vesting_period_option'>Vesting period</span>
  <span class='smart__field__option' id='equity_vesting_cliff_option'>Vesting cliff</span>
  <span class='smart__field__option' id='equity_exercise_price_option'>Exercise price</span>

  </div>
</div>`;

export const getSignatureSmartFieldDropDown = () => `
<div class='smartfield__dropdown'>
  <div class='smart__field__options'>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Recipient</span>
  <span class='smart__field__option' id='employee_signature_option'>Recipient Signature</span>
  <span class='smart__field__option' id='employee_signature_timestamp'>Recipient Signature Timestamp</span>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Additional</span>
  <span class='smart__field__option' id='company_signature_option'>Additional Signatory Signature</span>
  <span class='smart__field__option' id='company_signature_timestamp'>Additional Signatory Timestamp</span>
  <span class='smart__field__option' id='company_signature_full_name'>Additional Signatory Full name</span>
  <span class='smart__field__option' id='company_signature_job_title'>Additional Signatory Job title</span>
  <span style='${SMART_FIELD_SECTION_HEADER_STYLE}'>Witness</span>
  <span class='smart__field__option' id='witness_details_option'>Witness Details</span>
  </div>
</div>`;

export const SMART_FIELD_TYPES = {
  // PERSONAL
  FIRST_NAME: '{{employee_first_name}}',
  LAST_NAME: '{{employee_last_name}}',
  FULL_NAME: '{{employee_full_name}}',
  DOB: '{{employee_dob}}',
  PASSPORT_NUMBER: '{{employee_passport_number}}',
  PERSONAL_EMAIL: '{{employee_personal_email}}',

  // ADDRESS
  ADDRESS: '{{employee_address}}',
  ADDRESS_LINE1: '{{employee_address_line1}}',
  ADDRESS_LINE2: '{{employee_address_line2}}',
  ADDRESS_CITY: '{{employee_address_city}}',
  ADDRESS_COUNTRY: '{{employee_address_country}}',
  ADDRESS_POSTCODE: '{{employee_address_postcode}}',

  // ROLE
  START_DATE: '{{employee_start_date}}',
  ROLE: '{{employee_role}}',
  REPORTS_TO: '{{employee_reports_to}}',
  SITE: '{{employee_site}}',
  DEPARTMENT: '{{employee_department}}',

  // CONTRACT
  PROBATION_PERIOD: '{{employee_probation_period}}',
  NOTICE_PERIOD: '{{employee_notice_period}}',
  WORKER_TYPE: '{{employee_worker_type}}',
  EMPLOYMENT_CONTRACT: '{{employee_employment_contract}}',
  ATTENDANCE_SCHEDULE: '{{employee_attendance_schedule}}',

  // COMPENSATION
  PAY_SCHEDULE: '{{compensation_pay_schedule}}',
  RATE: '{{compensation_rate}}',
  UNITS: '{{compensation_units}}',
  CURRENCY: '{{compensation_currency}}',
  SALARY_BASIS: '{{compensation_salary_basis}}',

  // EQUITY
  EQUITY: '{{compensation_equity}}',
  EQUITY_TYPE: '{{compensation_equity_type}}',
  EQUITY_GRANT_DATE: '{{compensation_equity_grant_date}}',
  EQUITY_AMOUNT: '{{compensation_equity_amount}}',
  EQUITY_VESTING_START: '{{compensation_equity_vesting_start}}',
  EQUITY_VESTING_PERIOD: '{{compensation_equity_vesting_period}}',
  EQUITY_VESTING_CLIFF: '{{compensation_equity_vesting_cliff}}',
  EQUITY_EXERCISE_PRICE: '{{compensation_equity_exercise_price}}',
  // PAID_TIME_OFF: '{{compensation_paid_time_off}}',
  //COMPANY_LOGO: '{{company_logo}}',

  // SIGNATURES
  RECIPIENT_SIGNATURE: `<span class='smartfield__signature'> {{employee_signature}} </span>`,
  EMPLOYEE_SIGNATURE_TIMESTAMP: '{{recipient_signature_timestamp}}',
  COMPANY_SIGNATURE: `<span class='smartfield__signature'> {{additional_signature}} </span>`,
  ADDITIONAL_SIGNATORY_TIMESTAMP: '{{additional_signatory_timestamp}}',
  ADDITIONAL_SIGNATORY_FULL_NAME: '{{additional_signatory_full_name}}',
  ADDITIONAL_SIGNATORY_JOB_TITLE: '{{additional_signatory_job_title}}',
  WITNESS_DETAILS: '{{witness_details}}',
} as const;

export type SMART_FIELD_TYPE = {
  label?: string;
  value: string;
  group?: string;
  fontFamily?: string;
};

export const SMART_FIELDS = [
  {
    value: '',
    group: 'Personal',
  },
  {
    label: 'First name',
    value: '{{employee_first_name}}',
  },
  {
    label: 'Last name',
    value: '{{employee_last_name}}',
  },
  {
    label: 'Full name',
    value: '{{employee_full_name}}',
  },
  {
    label: 'Date of birth',
    value: '{{employee_dob}}',
  },
  {
    label: 'Passport number',
    value: '{{employee_passport_number}}',
  },
  {
    label: 'Personal email',
    value: '{{employee_personal_email}}',
  },
  {
    value: '',
    group: 'Address',
  },
  {
    label: 'Full address',
    value: '{{employee_address}}',
  },
  {
    label: 'Address line 1',
    value: '{{employee_address_line1}}',
  },
  {
    label: 'Address line 2',
    value: '{{employee_address_line2}}',
  },
  {
    label: 'City',
    value: '{{employee_address_city}}',
  },
  {
    label: 'Country',
    value: '{{employee_address_country}}',
  },
  {
    label: 'Postcode',
    value: '{{employee_address_postcode}}',
  },
  {
    value: '',
    group: 'Role',
  },
  {
    label: 'Start date',
    value: '{{employee_start_date}}',
  },
  {
    label: 'Job title',
    value: '{{employee_role}}',
  },
  {
    label: 'Reports to',
    value: '{{employee_reports_to}}',
  },
  {
    label: 'Site',
    value: '{{employee_site}}',
  },
  {
    label: 'Department',
    value: '{{employee_department}}',
  },
  {
    value: '',
    group: 'Contract',
  },
  {
    label: 'Probation period',
    value: '{{employee_probation_period}}',
  },
  {
    label: 'Notice period',
    value: '{{employee_notice_period}}',
  },
  {
    label: 'Worker type',
    value: '{{employee_worker_type}}',
  },
  {
    label: 'Employment contract',
    value: '{{employee_employment_contract}}',
  },
  {
    label: 'Attendance schedule hours',
    value: '{{employee_attendance_schedule}}',
  },
  {
    value: '',
    group: 'Salary',
  },
  {
    label: 'Salary basis',
    value: '{{compensation_salary_basis}}',
  },
  {
    label: 'Amount',
    value: '{{compensation_rate}}',
  },
  {
    label: 'Units',
    value: '{{compensation_units}}',
  },
  {
    label: 'Pay schedule',
    value: '{{compensation_pay_schedule}}',
  },
  {
    value: '',
    group: 'Equity',
  },
  {
    label: 'Equity type',
    value: '{{compensation_equity_type}}',
  },
  {
    label: 'Grant date',
    value: '{{compensation_equity_grant_date}}',
  },
  {
    label: 'Amount',
    value: '{{compensation_equity_amount}}',
  },
  {
    label: 'Vesting start',
    value: '{{compensation_equity_vesting_start}}',
  },
  {
    label: 'Vesting period',
    value: '{{compensation_equity_vesting_period}}',
  },
  {
    label: 'Vesting cliff',
    value: '{{compensation_equity_vesting_cliff}}',
  },
  {
    label: 'Exercise price',
    value: '{{compensation_equity_exercise_price}}',
  },
  {
    value: '',
    group: 'Recipient',
  },
  {
    label: 'Recipient Signature',
    value: '{{employee_signature}}',
  },
  {
    label: 'Recipient Signature Timestamp',
    value: '{{recipient_signature_timestamp}}',
  },
  {
    value: '',
    group: 'Additional',
  },
  {
    label: 'Additional Signatory Signature',
    value: '{{additional_signature}}',
  },
  {
    label: 'Additional Signatory Timestamp',
    value: '{{additional_signatory_timestamp}}',
  },
  {
    label: 'Additional Signatory Full name',
    value: '{{additional_signatory_full_name}}',
  },
  {
    label: 'Additional Signatory Job title',
    value: '{{additional_signatory_job_title}}',
  },
  {
    value: '',
    group: 'Witness',
  },
  {
    label: 'Witness Details',
    value: '{{witness_details}}',
  },
] as const;

export const SMART_FIELD_MAP_BY_LABEL: Map<string, string> = new Map();
SMART_FIELDS.forEach((field: SMART_FIELD_TYPE) => {
  if (field.label) {
    SMART_FIELD_MAP_BY_LABEL.set(field.label, field.value);
  }
});

export const defaultButtons = [
  'bold',
  'italic',
  'underline',
  'font',
  'fontsize',
  'indent',
  'left',
  'center',
  'right',
  'justify',
];

export const tableOptions = [
  { label: 'Insert Table', value: 'insert_table' },
  { label: 'Add Column', value: 'add_column' },
  { label: 'Delete Column', value: 'delete_column' },
  { label: 'Add Row', value: 'add_row' },
  { label: 'Delete Row', value: 'delete_row' },
  { label: 'Merge Cells', value: 'merge_cells' },
  { label: 'Split Cell', value: 'split_cell' },
];

export const pixelOptions = [
  { label: '8px', value: '8px' },
  { label: '9px', value: '9px' },
  { label: '10px', value: '10px' },
  { label: '11px', value: '11px' },
  { label: '12px', value: '12px' },
  { label: '14px', value: '14px' },
  { label: '16px', value: '16px' },
  { label: '18px', value: '18px' },
  { label: '24px', value: '24px' },
  { label: '30px', value: '30px' },
  { label: '36px', value: '36px' },
  { label: '48px', value: '48px' },
  { label: '72px', value: '72px' },
  { label: '96px', value: '96px' },
];

export const fontFamilyOptions = [
  { label: 'Times New Roman', value: 'timesnewroman' },
  { label: 'Helvetica', value: 'helvetica' },
  { label: 'Arial', value: 'arial' },
  { label: 'Georgia', value: 'georgia' },
  { label: 'Verdana', value: 'verdana' },
  { label: 'Tahoma', value: 'tahoma' },
  { label: 'Calibri', value: 'calibri' },
  { label: 'Inter', value: 'inter' },
  { label: 'Roboto', value: 'roboto' },
];

export const fontFamilyMap = {
  timesnewroman: 'Times New Roman',
  helvetica: 'helvetica',
  arial: 'arial',
  georgia: 'georgia',
  verdana: 'verdana',
  tahoma: 'tahoma',
  calibri: 'calibri',
  inter: 'inter',
  roboto: 'roboto',
};

export const lineSpacingOptions = [
  { label: 'Single', value: '1' },
  { label: '1.15', value: '1.15' },
  { label: '1.5', value: '1.5' },
  { label: 'Double', value: '2' },
];
