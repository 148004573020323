import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { Typography } from '@v2/components/typography/typography.component';
import { getDateType } from '@v2/feature/reports/util/report.util';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Download } from '@/images/side-bar-icons/Download.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { REPORTS_CREATE_OLD_ROUTE, REPORTS_UPDATE_OLD_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortDate, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { iconSize } from '@/v2/feature/onboarding/onboarding-template-edit.page';
import { ReportsEmptyState } from '@/v2/feature/reports/components/reports-empty-state.component';
import { ReportsAPI, ReportsEndpoints } from '@/v2/feature/reports/reports.api';
import { ReportDto } from '@/v2/feature/reports/reports.dto';
import { DataDomain, ReportTypePeopleSelection } from '@/v2/feature/reports/reports.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserAPI } from '@/v2/feature/user/user.api';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ReportsPage = () => {
  const { polyglot } = usePolyglot();

  const {
    data: reportsData,
    isLoading: loading,
    mutate: refreshReportsList,
  } = useApiClient(ReportsEndpoints.getAllReports(), { suspense: false });

  const routerHistory = useHistory();
  const [filteredData, setFilteredData] = useState<ReportDto[] | undefined>(undefined);
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();
  const { trackPage } = useJune();
  const [searchInput, setSearchInput] = useState<string>('');
  const [state, dispatch] = useContext(GlobalContext);

  const refreshReports = useCallback(async () => {
    if (refreshReportsList) await refreshReportsList();
  }, [refreshReportsList]);

  useEffect(() => {
    let filteredData: ReportDto[];
    if (searchInput.length) {
      filteredData = reportsData?.filter((r) => r.name.includes(searchInput) || r.type.includes(searchInput)) ?? [];
      setFilteredData(filteredData);
    } else {
      setFilteredData(reportsData ?? []);
    }
  }, [searchInput, reportsData]);

  const handleDownloadClick = useCallback(
    async (report: ReportDto) => {
      try {
        const zip = new JSZip();
        if (
          report.config.type === ReportTypePeopleSelection.CurrentRecords &&
          report.config.domain === DataDomain.People
        ) {
          // TODO: should use the same exportReportById, no matter if it is current or change, should decide method in BE
          const allCsvs = await ReportsAPI.exportCurrentReportById(report.id);
          zip.file(`report-${report.name}.csv`, allCsvs);
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, `${report.name}.zip`);
          });
        } else {
          const { allCsvs, allKeys } = await ReportsAPI.exportReportById(report.id);
          allCsvs.map((file, idx) => zip.file(`report-${allKeys[idx]}.csv`, file));
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, `${report.name}.zip`);
          });
        }
      } catch (e) {
        showMessage(polyglot.t('ReportsPage.errorMessages.download'), 'error');
      }
    },
    [showMessage, polyglot]
  );

  const getReportsActionsOptions = useCallback(
    (original: ReportDto) => {
      return [
        {
          icon: <Trash {...iconSize} />,
          handler: async () => {
            try {
              await ReportsAPI.deleteReport(original.id);
              showMessage(polyglot.t('ReportsPage.successMessages.delete'), 'success');
              await refreshReports();
            } catch (e) {
              showMessage(nestErrorMessage(e), 'error');
            }
          },
          label: polyglot.t('ReportsPage.delete'),
          disabled: false,
        },
        {
          icon: <Download {...iconSize} />,
          handler: async () => {
            try {
              await handleDownloadClick(original);
            } catch (e) {
              showMessage(nestErrorMessage(e), 'error');
            }
          },
          label: polyglot.t('ReportsPage.download'),
          disabled: false,
        },
      ];
    },
    [polyglot, showMessage, refreshReports, handleDownloadClick]
  );

  const columns = useMemo<ColumnDef<ReportDto, ReportDto>[]>(
    () => [
      {
        id: 'name',
        header: () => polyglot.t('ReportsPage.name'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.name),
        cell: ({ row: { original } }) => <div>{original.name ? <Box>{original.name}</Box> : <EmptyCell />}</div>,
      },
      {
        id: 'data',
        header: () => polyglot.t('ReportsPage.data'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.config.domain),
        cell: ({ row: { original } }) => (
          <div>{original.config.domain ? <Box>{original.config.domain}</Box> : <EmptyCell />}</div>
        ),
      },
      {
        id: 'type',
        header: () => polyglot.t('ReportsPage.type'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.type),
        cell: ({ row: { original } }) => (
          <div>
            {original.config.type ? (
              <Box sx={{ display: 'flex', gap: spacing.g5 }}>
                <Typography variant="caption">{original.config.type}</Typography>
                {original.config.type === ReportTypePeopleSelection.ChangeReport &&
                  original.config.start &&
                  original.config.end && (
                    <Typography variant="caption">
                      {getDateType(original.config.start, original.config.end, polyglot)}
                    </Typography>
                  )}
              </Box>
            ) : (
              <EmptyCell />
            )}
          </div>
        ),
      },
      {
        id: 'createdAt',
        header: () => polyglot.t('ReportsPage.createdAt'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item?.createdAt),
        cell: ({ row: { original } }) => (
          <div>{original.createdAt ? getDateString(original.createdAt) : <EmptyCell />}</div>
        ),
      },
      {
        header: () => polyglot.t('ReportsPage.createdBy'),
        accessorFn: (row) => row,
        id: 'createdBy',
        maxSize: 200,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => (item.createdBy ? getCachedUserById(item.createdBy)?.displayName : '')),
        cell: ({ row: { original } }) => {
          const user = getCachedUserById(original.createdBy);
          return (
            user && (
              <div>
                <UserCell userId={original.createdBy} />
              </div>
            )
          );
        },
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        maxSize: 80,
        minSize: 60,
        enableSorting: false,
        cell: ({ row: { original } }: { row: Row<ReportDto> }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StyledMenuComponent
                  options={getReportsActionsOptions(original)}
                  actionButtonDetails={{
                    type: 'iconButton',
                    colorVariant: 'secondary',
                    sizeVariant: 'small',
                    title: 'actions',
                    icon: <ActionsSmall {...iconSize} />,
                  }}
                />
              </Box>
            </div>
          );
        },
      },
    ],
    [polyglot, getCachedUserById, getReportsActionsOptions]
  );

  const handleRowClick = useCallback(
    (row: Row<ReportDto>) => {
      routerHistory.push(generatePath(REPORTS_UPDATE_OLD_ROUTE, { reportId: row.original.id }));
    },
    [routerHistory]
  );

  useEffect(() => {
    trackPage('Reports overview');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      {!loading && reportsData && reportsData.length < 1 ? (
        <>
          <TopHeader
            title={
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
                <Typography variant="title2">{polyglot.t('ReportsPage.reports')}</Typography>
                <Typography variant="caption">This app has been replaced with the new reporting app.</Typography>
              </Box>
            }
          />
          <ContentWrapper loading={loading} sx={{ pt: 2, pb: 0, height: '100%' }} border={false}>
            <ReportsEmptyState onClick={() => routerHistory.push(REPORTS_CREATE_OLD_ROUTE)} />
          </ContentWrapper>
        </>
      ) : (
        <>
          <TopHeader
            title={
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
                <Typography variant="title2">{polyglot.t('ReportsPage.reports')}</Typography>
                <Typography variant="caption">This app has been replaced with the new reporting app.</Typography>
              </Box>
            }
            showAction={false}
            actions={
              <StyledTooltip
                open
                placement="left-end"
                disabled={state.user.features?.report?.tooltip?.createReport}
                title={
                  <Stack sx={{ alignItems: 'flex-start', maxWidth: '180px' }}>
                    <Typography variant="title4" color="white" sx={{ m: spacing.m10 }}>
                      {polyglot.t('ReportsPage.create')}
                    </Typography>
                    <ButtonComponent
                      onClick={async () => {
                        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
                          'report',
                          'tooltip',
                          'createReport',
                          true
                        );
                        dispatch({
                          type: GlobalStateActions.UPDATE_USER,
                          payload: updatedGlobalUser,
                        });
                      }}
                      sizeVariant="small"
                      colorVariant="tooltip"
                    >
                      {polyglot.t('ReportsPage.okay')}
                    </ButtonComponent>
                  </Stack>
                }
              >
                <ButtonComponent
                  sizeVariant="small"
                  colorVariant="primary"
                  onClick={() => routerHistory.push(REPORTS_CREATE_OLD_ROUTE)}
                  disabled
                >
                  {polyglot.t('ReportsPage.new')}
                </ButtonComponent>
              </StyledTooltip>
            }
          />
          <ContentWrapper loading={loading} sx={{ mt: spacing.s2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TableSearch
                query={searchInput}
                handleChange={(e) => {
                  setSearchInput(e.target.value);
                }}
              />
            </Box>
            <Box sx={{ mt: spacing.s2 }}>
              <BasicTable
                rowData={filteredData ? [...filteredData] : []}
                columnData={columns}
                loading={loading}
                rowClick={handleRowClick}
              />
            </Box>
          </ContentWrapper>
        </>
      )}
    </RootStyle>
  );
};
