import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Button, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { AppIntegrationAPI, AppIntegrationEndpoints } from '@v2/feature/app-integration/app-integration.api';
import { AppTenant, InstalledAppDto } from '@v2/feature/app-integration/app-integration.dto';
import { AppDetailsAPI } from '@v2/feature/app-integration/features/app-details/app-details.api';
import { AppCredentialDto } from '@v2/feature/app-integration/features/app-details/app-details.dto';
import { AppBasicAuthConnectDrawer } from '@v2/feature/app-integration/features/app-details/components/app-basic-auth-connect-drawer.component';
import {
  LastpassAccountIdInstructions,
  LastpassInfoCard,
  LastpassProvHashInstructions,
} from '@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-lastpass.component';
import { MondayApiKeyInstructions } from '@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-monday.component';
import {
  NotionInfoCard,
  NotionInstallInstructions,
} from '@v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-notion.component';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { GeneralisedRemoveConfirmDrawer } from '../components/generalised-remove-confirm-drawer-drawer.component';

import { ScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE, APPS_COMPANY_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import {
  APPS_WITH_CC_INVITE,
  APPS_WITH_DOMAIN_NAME_CALENDAR_CONFIGURATION,
} from '@/v2/feature/app-integration/app-integration.interface';
import { AppScopes } from '@/v2/feature/app-integration/app.scopes';
import { AppCalendarConfiguration } from '@/v2/feature/app-integration/features/app-details/components/app-calendar-configuration.component';
import { AppCCForNewAccounts } from '@/v2/feature/app-integration/features/app-details/components/app-cc-for-new-accounts.component';
import { JumpcloudApiKeyInstructions } from '@/v2/feature/app-integration/features/app-details/components/app-details-apps/app-details-jumpcloud.component';
import { AppAutomaticOnboardingConfiguration } from '@/v2/feature/app-integration/features/app-details/components/app-details-automatic-onboarding-configuration.component';
import { AppAutomaticOnboardingViaWebhook } from '@/v2/feature/app-integration/features/app-details/components/app-details-automatic-onboarding-via-webhook.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { FieldStructure } from '@/v2/feature/user/features/user-profile/details/components/card-field-structure.component';
import { definitionTermSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { OnboardingEndpoints } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';
import { LocalDate } from '@/v2/util/local-date';

type AppCredentialsProps = {
  readonly [key: string]: Record<string, JSX.Element>;
};

type AppInfoProps = {
  readonly [key: string]: JSX.Element;
};

const appCredentialsInstructions: AppCredentialsProps = {
  notion: { tokenInstallInstructions: <NotionInstallInstructions /> },
  lastpass: {
    hashKeyInstructions: <LastpassProvHashInstructions />,
    accountIdInstructions: <LastpassAccountIdInstructions />,
  },
  monday: {
    mondayApiKeyInstructions: <MondayApiKeyInstructions />,
  },
  jumpcloud: {
    tokenInstallInstructions: <JumpcloudApiKeyInstructions />,
  },
} as const;

const appInfoCards: AppInfoProps = {
  notion: <NotionInfoCard />,
  lastpass: <LastpassInfoCard />,
} as const;

const REMOVE_TENANT_CONFIRM = `Are you sure you want to remove this tenant?`;

export const AppUserSettingPage = (): JSX.Element => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);

  const { hasScopes, getScopesContext } = useScopes();
  const [showMessage] = useMessage();

  const { appStub } = useParams<{ readonly appStub: string }>();
  const [app, setApp] = useState<InstalledAppDto | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [credentialsPlaceholders, setCredentialsPlaceholders] = useState<
    { [key: string]: Record<string, unknown> } | undefined
  >(undefined);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [removeModalMode, setRemoveModalMode] = useState<'deleteIntegration' | 'deleteTenant'>('deleteIntegration');
  const [tenantToDisconnect, setTenantToDisconnect] = useState<string | null>(null);
  const [isQbState, setIsQbState] = useState<string>('default');
  const [credentials, setCredentials] = useState<AppCredentialDto | undefined>(undefined);
  const [appDetailError, setAppDetailError] = useState<string | undefined>(undefined);
  const [basicAuthConnectDrawerOpen, setBasicAuthConnectDrawerOpen] = useState(false);
  const { mutate: refreshUserApps } = useApiClient(AppIntegrationEndpoints.getUserApps(globalState.user.userId), {
    suspense: false,
  });
  const { data: appOnboardingCheck } = useApiClient(OnboardingEndpoints.isAppCurrentlyUsedInOnboarding(appStub), {
    suspense: false,
  });
  const { mutate: refreshInstalledApps } = useApiClient(AppIntegrationEndpoints.getInstalledApps(), {
    suspense: false,
  });
  const { getCachedUserById } = useCachedUsers();

  const fetchAppDetails = useCallback(async () => {
    setLoading(true);
    try {
      const app = await AppDetailsAPI.getIntegrationData(appStub);
      setApp(app);
      setCredentialsPlaceholders(app?.credentialsPlaceholders ?? undefined);
      setLoading(false);
      setCredentials(undefined);
    } catch (error) {
      if ([401, 403].includes(error.response?.data?.statusCode))
        setAppDetailError(`${error?.response?.data?.error}: ${error?.response?.data?.message}`);
      else setAppDetailError(undefined);
      setLoading(false);
      setApp(undefined);
    }
  }, [appStub]);

  useEffect(() => {
    fetchAppDetails();
  }, [fetchAppDetails]);

  const APP_IN_USE_CONFIRMATION_MESSAGE = useMemo(() => {
    const defaultMessage = polyglot.t('AppUserSettingConfirmRemoveIntegration.normalConfirmMessage');
    if (!appOnboardingCheck?.used) {
      return defaultMessage;
    } else if (appOnboardingCheck.used === true) {
      return polyglot.t('AppUserSettingConfirmRemoveIntegration.appUsedInOnboardingConfirmMessage');
    }
    return defaultMessage;
  }, [appOnboardingCheck, polyglot]);

  const disconnectXeroOrganisation = useCallback(async () => {
    if (tenantToDisconnect) await AppIntegrationAPI.disconnectXeroTenant(tenantToDisconnect);
    fetchAppDetails();
    setTenantToDisconnect(null);
    setIsRemovalModalOpen(false);
  }, [fetchAppDetails, tenantToDisconnect]);

  async function onDeleteApp() {
    try {
      await AppIntegrationAPI.revokeToken(appStub);
      showMessage('Successfully removed app integration', 'success');
      setTimeout(() => {
        refreshUserApps!();
        refreshInstalledApps!();
      }, 1300);
      setTimeout(() => routerHistory.push(APPS_COMPANY_ROUTE), 1500);
    } catch (error) {
      showMessage(`${nestErrorMessage(error)}`, 'error');
    }
  }

  function isConfigured() {
    return !app?.requiredConfiguration || (app?.requiredConfiguration && app?.configuration);
  }

  const modeForOnboardingConfig = useMemo(() => {
    if (app?.allowsCandidateManagement) return 'candidate';
    if (app?.allowsEmploymentManagement) return 'employment';
    return undefined;
  }, [app]);

  const editBasicAuth = () => {
    setBasicAuthConnectDrawerOpen(true);
  };

  const connectAccountingApp = useCallback((appStub: 'xero') => {
    window.location.href = AppIntegrationAPI.accountingAppInitiateTenantConnection(appStub);
  }, []);

  const tableColumns = useMemo<ColumnDef<AppTenant, AppTenant>[]>(() => {
    return [
      {
        header: () => polyglot.t('General.name'),
        accessorFn: (row) => row,
        id: 'Name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.tenantName),
        cell: ({ row: { original } }) => {
          return <Typography sx={definitionTermSx}>{original.tenantName}</Typography>;
        },
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        cell: ({ row: { original } }) => {
          return (
            <IconButton
              sx={{ ...tableIconButtonSx, float: 'right' }}
              onClick={() => {
                setTenantToDisconnect(original.tenantId);
                setRemoveModalMode('deleteTenant');
                setIsRemovalModalOpen(true);
              }}
              disabled={app?.tenants?.length === 1}
            >
              <Trash {...iconSize} />
            </IconButton>
          );
        },
      },
    ];
  }, [app?.tenants?.length, polyglot]);

  function configureIntegration() {
    return (
      <div>
        {/* OAUTH CONTENT */}
        {app?.directory &&
          app?.directory.oauth === true &&
          app?.stub !== 'pipedrive' && ( // TODO: remove this after the app is published and can be installed outside the marketplace
            <div>
              {app.authorised ? (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  {app && app?.tenants && app?.tenants?.length > 0 && (
                    <FieldStructure fieldTitle="Xero Organisation" fieldValue={tenantNames() ?? ''} />
                  )}

                  <FieldStructure
                    fieldTitle={polyglot.t('AppUserSettingPage.appOwner')}
                    fieldValue={
                      app.appOwnerId && getCachedUserById(app.appOwnerId) ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
                          <UserAvatar userId={app.appOwnerId} size="xxsmall" />
                          <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                            {getCachedUserById(app.appOwnerId)?.displayName}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>Deleted user</Typography>
                      )
                    }
                  />
                  {app?.createdAt && (
                    <FieldStructure
                      fieldTitle={polyglot.t('AppUserSettingPage.appConnectedOn')}
                      fieldValue={dateAPItoDisplay(app.createdAt)}
                    />
                  )}
                  {!!app?.configuration?.expiresAt && (
                    <FieldStructure
                      fieldTitle={polyglot.t('AppUserSettingPage.appExpiresOn')}
                      fieldValue={dateAPItoDisplay(
                        new LocalDate(app?.configuration?.expiresAt as number).toDateString()
                      )}
                    />
                  )}
                  {app?.slackTeamDetails && app?.slackTeamDetails?.name && (
                    <FieldStructure
                      fieldTitle={polyglot.t('AppUserSettingPage.connectedTeam')}
                      fieldValue={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
                          <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                            {app?.slackTeamDetails?.name}
                          </Typography>
                        </Box>
                      }
                    />
                  )}
                </Box>
              ) : (
                <>
                  <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Basic auth setup</Typography>
                  <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, mt: 2 }}>
                    Connect {app?.name} to manage users and groups
                  </Typography>
                </>
              )}
            </div>
          )}

        {/* BASIC AUTH CONTENT */}
        {!app?.directory && app?.authorised && (
          <>
            <FieldStructure
              fieldTitle="App Owner"
              fieldValue={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
                  <UserAvatar userId={app.appOwnerId} size="xxsmall" />
                  <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                    {getCachedUserById(app.appOwnerId)?.displayName}
                  </Typography>
                </Box>
              }
            />
            {app?.createdAt && (
              <FieldStructure fieldTitle="App connected on" fieldValue={dateAPItoDisplay(app.createdAt)} />
            )}
          </>
        )}
        {app?.directory && app?.directory.credentials && (
          <div>
            {app?.authorised && (
              <FieldStructure
                fieldTitle="App Owner"
                fieldValue={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g10 }}>
                    <UserAvatar userId={app.appOwnerId} size="xxsmall" />
                    <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>
                      {getCachedUserById(app.appOwnerId)?.displayName}
                    </Typography>
                  </Box>
                }
              />
            )}
            {app?.createdAt && (
              <FieldStructure fieldTitle="App connected on" fieldValue={dateAPItoDisplay(app.createdAt)} />
            )}
            {(!app?.directory?.oauth || app?.hasBasicAuthAndOAuth) && (
              <>
                <Typography
                  sx={{
                    ...themeFonts.title2,
                    color: themeColors.DarkGrey,
                    marginTop: spacing.m60,
                  }}
                >
                  Basic auth setup
                </Typography>
                {appInfoCards[app?.stub]}
                {app.directory.credentials.length > 0 &&
                  app.directory.credentials.map((credential, idx) => (
                    <Box key={idx} sx={{ marginTop: spacing.m30 }}>
                      <Grid key={credential.name} container sx={{ width: '100%' }}>
                        <Grid item xs={2} sx={{ maxWidth: '40%' }}>
                          <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey, width: '190px' }}>
                            {credential.label}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ maxWidth: '50%' }}>
                          {!app.authorised && credential.name !== 'zeltCompanyId' && (
                            <TextField
                              label={`Enter ${credential.label}`}
                              variant="standard"
                              required
                              placeholder={
                                credentialsPlaceholders
                                  ? credentialsPlaceholders[credential?.name || '']?.toString()
                                  : undefined
                              }
                              onChange={(event) => {
                                setCredentials({
                                  ...credentials,
                                  [event.target.name]: event.target.value,
                                });
                              }}
                              value={credentials?.[credential.name as keyof AppCredentialDto]}
                              name={credential.name}
                              size="small"
                              sx={{ minWidth: '50%' }}
                            />
                          )}
                          {app.authorised &&
                            credential.name &&
                            credentialsPlaceholders &&
                            credentialsPlaceholders[credential?.name] && (
                              <>
                                <TextField
                                  disabled={true}
                                  variant="standard"
                                  required
                                  value={
                                    credentialsPlaceholders
                                      ? credentialsPlaceholders[credential?.name || '']?.toString()
                                      : undefined
                                  }
                                  name={credential.name}
                                  size="small"
                                  sx={{ minWidth: '50%' }}
                                />
                                <IconButton onClick={() => editBasicAuth()}>
                                  <Edit {...iconSize} />
                                </IconButton>
                              </>
                            )}
                          {credential.name === 'token' &&
                            appCredentialsInstructions[app.stub] &&
                            appCredentialsInstructions[app.stub].tokenInstallInstructions}
                          {credential.name === 'provhash' &&
                            appCredentialsInstructions[app.stub] &&
                            appCredentialsInstructions[app.stub].hashKeyInstructions}
                          {credential.name === 'cid' &&
                            appCredentialsInstructions[app.stub] &&
                            appCredentialsInstructions[app.stub].accountIdInstructions}
                          {credential.name === 'apikey' &&
                            appCredentialsInstructions[app.stub] &&
                            appCredentialsInstructions[app.stub].mondayApiKeyInstructions}
                          {credential.name === 'zeltCompanyId' && (
                            <TextField
                              disabled={true}
                              variant="standard"
                              required
                              value={globalState?.user?.company?.companyId ?? '0'}
                              name={credential.name}
                              size="small"
                              sx={{ minWidth: '50%' }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                {(!app?.directory?.oauth || app?.hasBasicAuthAndOAuth) && (
                  <AppBasicAuthConnectDrawer
                    isOpen={basicAuthConnectDrawerOpen}
                    setIsOpen={setBasicAuthConnectDrawerOpen}
                    appDetails={app}
                    currentUser={globalState.user}
                  />
                )}
              </>
            )}
          </div>
        )}
        {app && app.stub === 'xero' && app?.tenants && app?.tenants?.length > 0 && (
          <SettingsSectionContent
            title={
              <Typography sx={{ font: themeFonts.title3 }}>
                {polyglot.t('AppUserSettingPage.connectedOrganisations')}
              </Typography>
            }
            buttons={[
              <ButtonComponent
                key="connectOrg"
                sizeVariant="small"
                colorVariant="secondary"
                onClick={() => connectAccountingApp('xero')}
              >
                {polyglot.t('AppUserSettingPage.connectXeroOrganisation')}
              </ButtonComponent>,
            ]}
          >
            <SettingsSubsectionContent
              sections={[
                {
                  items: [
                    {
                      type: SectionItemType.Component,
                      value: (
                        <BasicTable<AppTenant> rowData={app.tenants} columnData={tableColumns} hidePagination={true} />
                      ),
                    },
                  ],
                },
              ]}
            />
          </SettingsSectionContent>
        )}
        {app?.allowsOnboardingViaWebhook && (
          <AppAutomaticOnboardingViaWebhook appDetails={app} companyId={globalState.user.company.companyId} />
        )}
        {/* BASIC AUTH */}
        {app?.directory && app?.directory.credentials && isConfigured() && (
          <>
            {/* If app is not authorised provide option to authorise */}
            {!app?.authorised && (
              <ButtonComponent
                sizeVariant="medium"
                colorVariant="primary"
                style={{ marginTop: '60px' }}
                disabled={!areCredentialsCompleted()}
                onClick={async (event) => {
                  if (event) event.preventDefault();
                  await upsertCredentials();
                }}
              >
                Save
              </ButtonComponent>
            )}
            {/* If app is authorised provide option to deauthorise/update */}
            {/* {app.authorised && (
              <div>
                <Button
                  color="primary"
                  size="medium"
                  disabled={!areCredentialsCompleted()}
                  onClick={async (event) => {
                    if (event) event.preventDefault();
                    await upsertCredentials();
                  }}
                >
                  Update
                </Button>
              </div>
            )} */}
          </>
        )}

        {/* OAUTH */}
        {/* {app?.hasBasicAuthAndOAuth && !app?.directory?.marketplaceInstall && (
          Hidden for now as Deel is the only app with such a configuration - and the
          reason for connecting oAuth was contract amendment which is currently not
          available for EOR contracts - might be re-enabled later on
          <OAuthWithBasicConfiguration
            appDetails={app}
            scopeContext={getScopesContext({ userId: globalState.user.userId })}
          />
        )} */}
        {app?.directory && app?.directory.oauth && !app.directory.marketplaceInstall && (
          <>
            {!app?.authorised && (
              <>
                {app?.stub !== 'quickbooks' && (
                  <ScopesControl
                    scopes={['apps.connect', 'apps:all']}
                    context={getScopesContext({ userId: globalState.user.userId })}
                  >
                    <ButtonComponent
                      sizeVariant="small"
                      colorVariant="primary"
                      style={{ marginTop: spacing.m2 }}
                      onClick={async (event) => {
                        if (event) event.preventDefault();
                        window.location.href = AppIntegrationAPI.getInitiateUrl(app.stub);
                      }}
                    >
                      Connect
                    </ButtonComponent>
                  </ScopesControl>
                )}

                {app?.stub === 'quickbooks' && (
                  <ScopesControl
                    scopes={['apps.connect', 'apps:all']}
                    context={getScopesContext({ userId: globalState.user.userId })}
                  >
                    <button
                      onClick={async (event) => {
                        if (event) event.preventDefault();
                        window.location.href = AppIntegrationAPI.getInitiateUrl(app.stub);
                      }}
                      onMouseEnter={() => setIsQbState('hover')}
                      onMouseLeave={() => setIsQbState('default')}
                      disabled={app?.authorised}
                    >
                      <img src={`/app-icons/QB${isQbState}.png`} alt="qb-connect-button" />
                    </button>
                  </ScopesControl>
                )}
              </>
            )}
          </>
        )}

        {/* MARKETPLACE INSTALL */}
        {app?.directory && app?.directory.marketplaceInstall && isConfigured() && (
          <>
            {/* If app is not authorised provide option to install from marketplace */}
            {!app?.authorised && (
              <a href={app?.directory.marketplaceInstall}>
                <Button color="primary" onClick={() => {}}>
                  Install App from Marketplace
                </Button>
              </a>
            )}
          </>
        )}
        {!app?.authorised && app?.directory && app?.directory.marketplaceInstall && <div>Install from Marketplace</div>}
      </div>
    );
  }

  async function upsertCredentials() {
    try {
      if (credentials) {
        await AppDetailsAPI.saveOrUpdateIntegrationCredentials(appStub, credentials);
        const app = await AppDetailsAPI.getIntegrationData(appStub);
        refreshInstalledApps!();
        setTimeout(
          () =>
            routerHistory.push(
              generatePath(APP_INTEGRATION_DETAILS_USER_DIRECTORY_ROUTE, {
                appStub,
              }),
              { app, needToRefreshDirectory: true }
            ),
          700
        );
      }
      await fetchAppDetails();
    } catch (error) {
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
    } finally {
    }
  }

  function areCredentialsCompleted() {
    if (!credentials) {
      return false;
    } else {
      if (app?.directory?.credentials) {
        for (let cred of app?.directory?.credentials) {
          if (cred) {
            const propName = (cred.name as keyof AppCredentialDto) || undefined;
            if (!credentials[propName]) return false;
          }
        }
      }
    }
    return true;
  }

  function tenantNames() {
    return app?.tenants && app?.tenants.map((eachTenant) => eachTenant.tenantName).join(', ');
  }

  const handleDeleteIntegration = async () => {
    setDeleteLoading(true);
    await onDeleteApp();
    setIsRemovalModalOpen(false);
    setDeleteLoading(false);
  };

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {polyglot.t('AppUserSettingPage.title')}
          </Typography>
        }
        showAction={
          !!appDetailError ||
          (app?.authorised && app && hasScopes(AppScopes.DELETE_APP_INTEGRATION, { userId: app.appOwnerId }))
        }
        actions={
          <Tooltip title={appDetailError ?? ''} placement="bottom-start">
            <ButtonComponent
              disabled={deleteLoading}
              sizeVariant="small"
              colorVariant="secondary"
              onClick={() => {
                setRemoveModalMode('deleteIntegration');
                setIsRemovalModalOpen(true);
              }}
            >
              {polyglot.t('AppUserSettingPage.removeIntegration')}
            </ButtonComponent>
          </Tooltip>
        }
      />
      <ContentWrapper loading={loading || deleteLoading} border={false}>
        {configureIntegration()}
        {app?.authorised && APPS_WITH_CC_INVITE.includes(app.stub) && <AppCCForNewAccounts appDetails={app} />}
        {app?.authorised && APPS_WITH_DOMAIN_NAME_CALENDAR_CONFIGURATION.includes(app.stub) && (
          <AppCalendarConfiguration />
        )}
        {/* {app?.authorised && app?.saml && <AppSamlMetadata appDetails={app} />} */}
        {app?.allowsAutomaticImportViaCron && (app?.allowsCandidateManagement || app?.allowsEmploymentManagement) && (
          <AppAutomaticOnboardingConfiguration appDetails={app} mode={modeForOnboardingConfig} />
        )}
        <GeneralisedRemoveConfirmDrawer
          isOpen={isRemovalModalOpen}
          setIsOpen={setIsRemovalModalOpen}
          drawerTitle={polyglot.t('AppUserSettingConfirmRemoveIntegration.title')}
          confirmationMessage={
            removeModalMode === 'deleteIntegration' ? APP_IN_USE_CONFIRMATION_MESSAGE : REMOVE_TENANT_CONFIRM
          }
          onClose={() => setIsRemovalModalOpen(false)}
          onConfirm={async () => {
            if (removeModalMode === 'deleteIntegration') handleDeleteIntegration();
            else if (removeModalMode === 'deleteTenant' && tenantToDisconnect) disconnectXeroOrganisation();
          }}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
