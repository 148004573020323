import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableFilter } from '@v2/components/table/table-filter.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceDto, DeviceModelDto } from '@v2/feature/device/device.dto';
import {
  DeviceOSValueLabelOptions,
  DeviceShopFilter,
  DeviceTypesValueLabelOptions,
  IsAvailableValueLabelOptions,
  ManufacturerValueLabelOptions,
  RamValueLabelOptions,
  StorageValueLabelOptions,
} from '@v2/feature/device/device.util';
import { SuperAdminDeviceModelDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-device-model-drawer.component';
import { filterStringToObject } from '@v2/feature/user/user.util';
import { spacing } from '@v2/styles/spacing.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

interface SuperAdminDeviceModelsPageProps {
  readonly pageConfig: PageConfig;
}

export const SuperAdminDeviceModelsPage = ({ pageConfig }: SuperAdminDeviceModelsPageProps): React.JSX.Element => {
  const [deviceModels, setDeviceModels] = useState<readonly DeviceModelDto[]>([]);
  const [stockDevices, setStockDevices] = useState<readonly DeviceDto[]>([]);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(true);
  const [deviceModelToBeEdited, setDeviceModelToBeEdited] = useState<DeviceModelDto | null>(null);
  const [isDeviceModelDrawerOpen, setIsDeviceModelDrawerOpen] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState('');
  const [filterString, setFilterString] = useState<string>('');
  const [filterTypes, setFilterTypes] = useState({});

  const devicesCountByModelId: { [deviceModelId: number]: number } = stockDevices.reduce(
    (acc: { [deviceModelId: number]: number }, device: DeviceDto) => {
      if (device.deviceModelId)
        acc[device.deviceModelId] = acc[device.deviceModelId] ? acc[device.deviceModelId] + 1 : 1;
      return acc;
    },
    {}
  );

  const refreshDevicesStockAndModels = useCallback(async () => {
    try {
      setLoading(true);
      const [deviceModels, devicesStock] = await Promise.all([
        DeviceAPI.getDeviceModels(true),
        DeviceAPI.getStockDevicesAsSuperadmin(),
      ]);
      setDeviceModels(deviceModels);
      setStockDevices(devicesStock);
    } catch (error) {
      showMessage(`Could not retrieve devices list. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refreshDevicesStockAndModels();
  }, [refreshDevicesStockAndModels]);

  const getFilterOptions = useCallback(async () => {
    const FILTERS = [
      DeviceShopFilter.Manufacturer,
      DeviceShopFilter.RAM,
      DeviceShopFilter.Storage,
      DeviceShopFilter.OS,
      DeviceShopFilter.IsAvailable,
    ];
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case DeviceShopFilter.Manufacturer:
          filters = { ...filters, [filter]: ManufacturerValueLabelOptions };
          break;
        case DeviceShopFilter.RAM:
          filters = { ...filters, [filter]: RamValueLabelOptions };
          break;
        case DeviceShopFilter.Storage:
          filters = { ...filters, [filter]: StorageValueLabelOptions };
          break;
        case DeviceShopFilter.OS:
          filters = { ...filters, [filter]: DeviceOSValueLabelOptions };
          break;
        case DeviceShopFilter.IsAvailable:
          filters = { ...filters, [filter]: IsAvailableValueLabelOptions };
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }, []);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  const tableData = useMemo(() => {
    let filteredDeviceModels = deviceModels.map((deviceModel) => ({
      ...deviceModel,
      count: devicesCountByModelId[deviceModel.id] ?? 0,
    }));
    if (searchInput) {
      filteredDeviceModels = filteredDeviceModels.filter((deviceModel) => {
        const modelName = deviceModel.modelName;
        const id = deviceModel.id;

        return (
          modelName?.toLowerCase().includes(searchInput.toLowerCase()) || String(id).includes(searchInput.toLowerCase())
        );
      });
    }

    if (filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case DeviceShopFilter.Manufacturer: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.manufacturer && filterOptions[key]?.includes(model.manufacturer.toLowerCase())
              );
              break;
            }
            case DeviceShopFilter.RAM: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.ram && filterOptions[key]?.includes(String(model.ram))
              );
              break;
            }
            case DeviceShopFilter.Storage: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.storage && filterOptions[key]?.includes(String(model.storage))
              );
              break;
            }
            case DeviceShopFilter.OS: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) => model.os && filterOptions[key]?.includes(model.os.toLowerCase())
              );
              break;
            }
            case DeviceShopFilter.IsAvailable: {
              filteredDeviceModels = filteredDeviceModels.filter(
                (model) =>
                  (model.isAvailable && filterOptions[key].includes('Yes')) ||
                  (!model.isAvailable && filterOptions[key].includes('No'))
              );
              break;
            }
            default:
              break;
          }
        }
      }
    }

    return filteredDeviceModels;
  }, [deviceModels, devicesCountByModelId, searchInput, filterString]);

  const columnData = useMemo<ColumnDef<DeviceModelDto, DeviceModelDto>[]>(() => {
    return [
      {
        header: () => 'ID',
        accessorFn: (row) => row,
        id: 'id',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return <Box>{deviceModel.id}</Box>;
        },
        size: 20,
      },
      {
        header: () => 'Type',
        accessorFn: (row) => row,
        id: 'type',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return (
            <Box>
              {DeviceTypesValueLabelOptions.find((type) => type.value === deviceModel.type)?.label ?? <EmptyCell />}
            </Box>
          );
        },
        size: 100,
      },
      {
        header: () => 'Model Name',
        accessorFn: (row) => row,
        id: 'modelName',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return <Box>{deviceModel.modelName}</Box>;
        },
        size: 135,
      },
      {
        header: () => 'Model Number',
        accessorFn: (row) => row,
        id: 'modelNumber',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.modelNumber ? <Box>{deviceModel.modelNumber}</Box> : <EmptyCell />;
        },
        size: 100,
      },
      {
        header: () => 'Model Version',
        accessorFn: (row) => row,
        id: 'modelVersion',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.modelVersion ? <Box>{deviceModel.modelVersion}</Box> : <EmptyCell />;
        },
        size: 110,
      },
      {
        header: () => 'Manufacturer',
        accessorFn: (row) => row,
        id: 'manufacturer',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.manufacturer ? <Box>{deviceModel.manufacturer}</Box> : <EmptyCell />;
        },
        size: 110,
      },
      {
        header: () => 'Screen Size',
        accessorFn: (row) => row,
        id: 'screenSize',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.screenSize ? <Box>{deviceModel.screenSize}"</Box> : <EmptyCell />;
        },
        size: 75,
      },

      {
        header: () => 'RAM',
        accessorFn: (row) => row,
        id: 'ram',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.ram ? <Box>{deviceModel.ram}GB</Box> : <EmptyCell />;
        },
        size: 30,
      },
      {
        header: () => 'Storage',
        accessorFn: (row) => row,
        id: 'storage',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.storage ? <Box>{deviceModel.storage}GB</Box> : <EmptyCell />;
        },
        size: 50,
      },
      {
        header: () => 'CPU',
        accessorFn: (row) => row,
        id: 'cpuCores',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.cpuCores ? <Box>{deviceModel.cpuCores}-cores</Box> : <EmptyCell />;
        },
        size: 65,
      },
      {
        header: () => 'GPU',
        accessorFn: (row) => row,
        id: 'gpuCores',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.gpuCores ? <Box>{deviceModel.gpuCores}-cores</Box> : <EmptyCell />;
        },
        size: 65,
      },
      {
        header: () => 'Delivery Days (UK)',
        accessorFn: (row) => row,
        id: 'deliveryDays',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return <Box>{deviceModel.deliveryDays} days</Box>;
        },
        size: 100,
      },
      {
        header: () => 'Delivery Days (EU)',
        accessorFn: (row) => row,
        id: 'deliveryDaysEu',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.deliveryDaysEu ? <Box>{deviceModel.deliveryDaysEu} days</Box> : <EmptyCell />;
        },
        size: 100,
      },
      {
        header: () => 'Is Available',
        accessorFn: (row) => row,
        id: 'isAvailable',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return deviceModel.isAvailable ? <Box>Yes</Box> : <Box>No</Box>;
        },
        size: 60,
      },
      {
        header: () => 'Price (36)',
        accessorFn: (row) => row,
        id: 'price36',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          const price = deviceModel.price36;

          return <Box>£{price}</Box>;
        },
        size: 75,
      },
      {
        header: () => 'Price (24)',
        accessorFn: (row) => row,
        id: 'price24',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          const price = deviceModel.price24;

          return <Box>£{price}</Box>;
        },
        size: 75,
      },
      {
        header: () => 'Price EU (36)',
        accessorFn: (row) => row,
        id: 'priceEu36',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          const priceEu = deviceModel.priceEu36;

          return priceEu ? <Box>£{priceEu}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Price EU (24)',
        accessorFn: (row) => row,
        id: 'priceEu24',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          const priceEu = deviceModel.priceEu24;

          return priceEu ? <Box>£{priceEu}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Full Price',
        accessorFn: (row) => row,
        id: 'fullPrice',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          const fullPrice = deviceModel.fullPrice;

          return fullPrice ? <Box>£{fullPrice}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Full Price (EU)',
        accessorFn: (row) => row,
        id: 'fullPriceEu',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          const fullPriceEu = deviceModel.fullPriceEu;

          return fullPriceEu ? <Box>£{fullPriceEu}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Count',
        accessorFn: (row) => row,
        id: 'count',
        enableSorting: false,
        cell: (info: CellContext<DeviceModelDto, DeviceModelDto>) => {
          const deviceModel: DeviceModelDto = info.getValue();
          return <Box>{deviceModel.count}</Box>;
        },
        size: 60,
      },
    ];
  }, []);

  return (
    <BackofficeRootStyle>
      <TopHeader
        title="Devices"
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsDeviceModelDrawerOpen(true)}>
            Add Model
          </ButtonComponent>
        }
        showAction={true}
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={loading} secondLevel>
        {deviceModels.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', ...spacing.mb20, gap: 2 }}>
            {filterTypes && (
              <TableFilter filterTypes={filterTypes} setFilterString={setFilterString} filterString={filterString} />
            )}
            <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
          </Box>
        )}
        <BasicTable<DeviceModelDto>
          rowData={tableData}
          columnData={columnData}
          rowClick={(row) => {
            setDeviceModelToBeEdited(row.original);
            setIsDeviceModelDrawerOpen(true);
          }}
        />

        <SuperAdminDeviceModelDrawer
          isOpen={isDeviceModelDrawerOpen}
          setIsOpen={setIsDeviceModelDrawerOpen}
          deviceModel={deviceModelToBeEdited}
          closeDrawer={() => setIsDeviceModelDrawerOpen(false)}
          refresh={refreshDevicesStockAndModels}
        />
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
