import { Scope } from '@/models';
import { SelectOption } from '@/v2/infrastructure/forms/forms.interface';

export enum NotificationTypes {
  newJoiner = 'newJoiner',
  workAnniversary = 'workAnniversary',
  lastDay = 'lastDay',
  birthday = 'birthday',
}

export enum SSOAppsEnum {
  'google-workspace' = 'Google Workspace',
  'sso-google-workspace' = 'Google',
  'sso-okta' = 'Okta',
  'sso-azure-ad' = 'Azure AD',
}

export type SSOApp = keyof typeof SSOAppsEnum;

export const SSOAppsOptions = [
  // { label: SSOAppsEnum['google-workspace'], value: 'google-workspace' },
  { label: SSOAppsEnum['sso-google-workspace'], value: 'sso-google-workspace' },
  { label: SSOAppsEnum['sso-okta'], value: 'sso-okta' },
  { label: SSOAppsEnum['sso-azure-ad'], value: 'sso-azure-ad' },
] as SelectOption[];

export interface AuditTrailDto {
  userId: number;
  companyId: number;
  action: string;
  domain: string;
  entityPayload: Record<string, unknown>;
  status: AuditTrailStatus;
  entityId: number;
  createdAt: string;
}

export enum AuditTrailStatus {
  Processed = 'Processed',
  InProgress = 'In progress',
  Failed = 'Failed',
}

export interface NonSensitiveOauthClientConfiguration {
  id: string;
  clientId: string;
  createdBy: number;
  companyId: number;
  name: string;
  description: string;
  logo: string;
  scopes: string[];
  scopeReasons: Record<string, string>;
  redirectionUri: string;
  published: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface SensitiveOauthClientConfiguration extends NonSensitiveOauthClientConfiguration {
  clientSecret: string;
}

export interface TokenResult {
  access_token: string;
  refresh_token: string;
  expiryForAT?: number;
  expiryForRT?: number;
  tokenPairGeneratedOn?: number;
}

export const INTEGRATION_SCOPE_LIST = ['user.basicInfo', 'user.basicInfo:read'] as Scope[];

export const integrationScopeListOptions = INTEGRATION_SCOPE_LIST.map((scope) => ({ label: scope, value: scope }));
