import { NavConfigItem, SubItemsProps } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import Polyglot from 'node-polyglot';

import { CurrentUser } from '@/models';
import { MenuAction, MenuState, RoleTypes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';
import {
  getGrowthConfig,
  getMoneyConfig,
  getPeopleConfig,
  getReportsConfig,
  getTechConfig,
  getTimeConfig,
} from '@/v2/feature/app-layout/features/v2/sidebar-config.util';

export function menuReducer(state: MenuState, action: MenuAction): MenuState {
  switch (action.type) {
    case 'SET_SECOND_MENU_VISIBILITY':
      return { ...state, showSecondMenu: action.payload };
    case 'SET_ACTIVE_PARENT':
      return { ...state, activeParent: action.payload };
    case 'SET_ACTIVE_SUBMENU_PARENT':
      return { ...state, activeSubMenuParent: action.payload };
    case 'SET_ACTIVE_ROLE':
      return { ...state, activeRole: action.payload };
    default:
      return state;
  }
}

export const getSecondMenuItems = (
  activeRole: RoleTypes,
  activeParent: string,
  user: CurrentUser,
  polyglot: Polyglot,
  showHiddenFeatures = false
) => {
  if (!activeRole || !user) return undefined;
  return activeParent === 'people'
    ? (getPeopleConfig(user, activeRole, polyglot) as NavConfigItem)
    : activeParent === 'time'
    ? (getTimeConfig(user, activeRole, polyglot) as NavConfigItem)
    : activeParent === 'money'
    ? (getMoneyConfig(user, activeRole, polyglot) as NavConfigItem)
    : activeParent === 'report'
    ? (getReportsConfig(user, activeRole, polyglot, showHiddenFeatures) as NavConfigItem)
    : activeParent === 'growth'
    ? (getGrowthConfig(user, activeRole, polyglot) as NavConfigItem)
    : activeParent === 'tech'
    ? (getTechConfig(user, activeRole, polyglot) as NavConfigItem)
    : undefined;
};

export function findSubItemByStub(subItems: SubItemsProps[] | undefined, stub: string) {
  return subItems && subItems.find((a) => a.stub === stub);
}

export function findChildByPath(subMenu: SubItemsProps[] | undefined) {
  return subMenu && subMenu.find((a) => a.path && !a.isHidden);
}

export function findSubMenuByPath(subMenu: SubItemsProps[] | undefined) {
  const firstSubMenu = subMenu && subMenu.find((a) => !a.isHidden && a.hasChildren);
  return firstSubMenu && firstSubMenu.subMenu && firstSubMenu.subMenu.find((a) => a.path && !a.isHidden);
}

export function decomposePath(
  path: string
): {
  domain: string;
  role: RoleTypes | '';
  subDomain: string;
  subMenu: string;
} {
  const parts = path.split('/');
  return {
    domain: parts[1] || '',
    role: parts[2] ? (parts[2] as RoleTypes) : '',
    subDomain: parts[3] || '',
    subMenu: parts[4] || '',
  };
}

export const navigateToFirstChildOrParent = (
  parent: SubItemsProps | undefined,
  subMenu: string,
  routedSecondMenuItems: NavConfigItem | undefined,
  routerHistory: any
) => {
  if (parent && parent.hasChildren && parent.subMenu && !parent.isHidden) {
    const child = findSubItemByStub(parent.subMenu, subMenu) || findChildByPath(parent.subMenu);
    if (child && child.path && !child.isHidden) {
      routerHistory.push(child.path);
    } else {
      const firstChild = parent.subMenu.find((a) => a.path && !a.isHidden);
      if (firstChild && firstChild.path) {
        routerHistory.push(firstChild.path);
      }
    }
  } else if (parent && parent.path && !parent.isHidden) {
    routerHistory.push(parent.path);
  } else {
    const firstParent = findChildByPath(routedSecondMenuItems?.subItems);
    if (firstParent && firstParent.path) {
      routerHistory.push(firstParent.path);
    } else {
      const firstSubMenu = findSubMenuByPath(routedSecondMenuItems?.subItems);
      if (firstSubMenu && firstSubMenu.path) routerHistory.push(firstSubMenu.path);
    }
  }
};
