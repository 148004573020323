import { DomainSideMenuContent } from '@v2/components/domain-side-menu-content.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NavConfigItem } from '@v2/feature/app-layout/features/v2/component/navigation-item.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { generatePath, Redirect, Route, Switch, useParams } from 'react-router-dom';

import { DeveloperHubCredentialsSettingsPage } from './developer-hub-credentials-settings.page';
import { DeveloperHubGeneralSettingsPage } from './developer-hub-general-settings.page';
import { DeveloperHubPermissionsSettingsPage } from './developer-hub-permissions-settings.page';
import { DeveloperHubPublishSettingsPage } from './developer-hub-publish-settings.page';

import { OauthClientEndpoints } from '@/api-client/oauth-client.api';
import {
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_CREDENTIALS_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_PERMISSIONS_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_APP_PUBLISH_ROUTE,
  SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE,
} from '@/lib/routes';
import { truncateWithEllipses } from '@/v2/util/string.util';

export const getPageConfig = (appId: string, polyglot: Polyglot): readonly NavConfigItem[] => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: polyglot.t('General.general'),
          stub: 'general',
          path: generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE, {
            appId,
          }),
        },
        {
          title: polyglot.t('DeveloperHub.appCredentials'),
          stub: 'credentials',
          path: generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_CREDENTIALS_ROUTE, {
            appId,
          }),
        },
        {
          title: polyglot.t('DeveloperHub.permissions'),
          stub: 'permissions',
          path: generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_PERMISSIONS_ROUTE, {
            appId,
          }),
        },
        {
          title: polyglot.t('DeveloperHub.publish'),
          stub: 'approval',
          path: generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_PUBLISH_ROUTE, {
            appId,
          }),
        },
      ],
    },
  ] as NavConfigItem[];
};

// interface ExpenseSettingDetailRouterProps {}

export const DeveloperHubAppDetailRouter = ({ refreshAppList }: { refreshAppList: () => void }) => {
  const { appId } = useParams<{ readonly appId: string }>();
  const { data: oauthApp, mutate: refreshApp } = useApiClient(
    appId ? OauthClientEndpoints.getSpecificAppDetails(appId) : { url: undefined },
    {
      suspense: false,
    }
  );
  //   const { data: expenseSetting, mutate: refresh } = useApiClient(PaymentTypeSettingsEndpoints.getTypeSettingById(1), {
  //     suspense: false,
  //   });
  // const { mutate: refreshAllSettings } = useApiClient(
  //   PaymentTypeSettingsEndpoints.getPaymentTypeSettingsForCompanyId(),
  //   { suspense: false }
  // );

  const { polyglot } = usePolyglot();

  return (
    <>
      <DomainSideMenuContent
        title={oauthApp ? truncateWithEllipses(oauthApp?.name) : ''}
        pageConfig={getPageConfig(appId, polyglot)}
        backPath={SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE}
        showBack
        type="Custom"
      />
      <ContentWrapper>
        <Switch>
          <Route exact path={SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE}>
            {oauthApp && (
              <DeveloperHubGeneralSettingsPage
                oauthApp={oauthApp}
                onUpdate={() => refreshApp()}
                onDelete={() => refreshAppList()}
              />
            )}
          </Route>
          <Route exact path={SETTINGS_SECURITY_DEVELOPER_HUB_APP_CREDENTIALS_ROUTE}>
            {oauthApp && <DeveloperHubCredentialsSettingsPage oauthApp={oauthApp} />}
          </Route>

          <Route exact path={SETTINGS_SECURITY_DEVELOPER_HUB_APP_PERMISSIONS_ROUTE}>
            {oauthApp && <DeveloperHubPermissionsSettingsPage oauthApp={oauthApp} onUpdate={() => refreshApp()} />}
          </Route>
          <Route exact path={SETTINGS_SECURITY_DEVELOPER_HUB_APP_PUBLISH_ROUTE}>
            {oauthApp && <DeveloperHubPublishSettingsPage oauthApp={oauthApp} onUpdate={() => refreshApp()} />}
          </Route>
          {/*
            <Route exact path={SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_APPROVAL_ROUTE}>
              <PaymentSettingsApprovalsPage paymentTypeSetting={expenseSetting} refreshPaymentTypeSetting={refresh} />
            </Route>

            <Route exact path={SETTINGS_MONEY_EXPENSE_TYPES_DETAILS_ACCOUNTING_ROUTE}>
              <PaymentTypeAccountingSettingsPage paymentTypeSetting={expenseSetting} refresh={refresh} />
            </Route> */}

          {/* <Redirect to={SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE} /> */}
          <Redirect to={SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE} />
        </Switch>
      </ContentWrapper>
    </>
  );
};
