import { useState } from 'react';

import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { PaymentSettingsPageSection } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-section.component';
import { DeveloperHubPermissionsEditDrawer } from '@/v2/feature/security/security-settings/features/developer-hub/components/developer-hub-permissions-edit-drawer.component';
import { NonSensitiveOauthClientConfiguration } from '@/v2/feature/security/security-settings/security.interface';
import { themeColors } from '@/v2/styles/colors.styles';

interface PageProps {
  oauthApp: NonSensitiveOauthClientConfiguration;
  onUpdate: () => void;
}

export const DeveloperHubPermissionsSettingsPage = ({ oauthApp, onUpdate }: PageProps) => {
  const { polyglot } = usePolyglot();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  return (
    <>
      <PaymentSettingsPageSection
        title={polyglot.t('DeveloperHub.permissions')}
        onEdit={() => {
          setIsEditOpen(true);
        }}
      >
        <Typography variant="caption">
          {polyglot.t('DeveloperHub.permissionsPageExplainer')}
          <ButtonComponent
            sizeVariant="small"
            colorVariant="text"
            onClick={() => {
              setIsEditOpen(true);
            }}
            style={{
              margin: 0,
              padding: 0,
              color: themeColors.RedDark,
              display: 'inline',
              textDecoration: 'underline',
            }}
          >
            documentation
          </ButtonComponent>
          .
        </Typography>
        {isEditOpen && (
          <DeveloperHubPermissionsEditDrawer
            isOpen={isEditOpen}
            setIsOpen={setIsEditOpen}
            oauthApp={oauthApp}
            onClose={function (): void {
              setIsEditOpen(false);
            }}
            onUpdate={onUpdate}
          />
        )}
      </PaymentSettingsPageSection>
    </>
  );
};
