import React, { useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';

interface SectionProps {
  readonly effectiveDate: string;
  readonly setEffectiveDate: React.Dispatch<React.SetStateAction<string>>;
}

export const ReportSettingsSection = ({ effectiveDate, setEffectiveDate }: SectionProps) => {
  const { polyglot } = usePolyglot();
  const [value, setValue] = useState<string>(effectiveDate);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', height: '100%' }}>
      <DatePickerComponent
        name="effectiveDate"
        label={polyglot.t('General.effectiveDate')}
        required
        onChange={(date) => {
          setValue(date);
        }}
        value={value}
      />

      <Box sx={buttonBoxDrawerSx}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="primary"
          onClick={() => {
            setEffectiveDate(value);
          }}
          fullWidth
        >
          Apply
        </ButtonComponent>
      </Box>
    </Box>
  );
};
