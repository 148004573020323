import { Box } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';

import { REQUESTS_SUBMIT_REQUEST_ROUTE } from '@/lib/routes';
import {
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { RequestFormsEndpoints } from '@/v2/feature/requests/request-forms.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';

export const ChooseRequestPage = () => {
  const { data: forms, error, isLoading } = useApiClient(RequestFormsEndpoints.getFormsAvailableForMe(), {
    suspense: false,
  });
  const routerHistory = useHistory();
  useEscapeKey(() => {
    routerHistory.goBack();
  });

  return (
    <ContentWrapper loading={false}>
      {error && <Typography variant="caption">{error.data?.message}</Typography>}
      <SettingsSectionContent title="New request" contentWidth="100%" headerWidth="100%">
        <SettingsSubsectionContent
          loadingAll={isLoading}
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {(forms || []).map((item) => {
                        return (
                          <SettingsItemCard
                            key={item.id}
                            title={item.name}
                            description={item.description}
                            boxSx={responsiveCardStyle}
                            boxAction={() => {
                              routerHistory.push(generatePath(REQUESTS_SUBMIT_REQUEST_ROUTE, { id: item.id }));
                            }}
                          />
                        );
                      })}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
    </ContentWrapper>
  );
};
