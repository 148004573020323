import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

export const ClickExtension = Extension.create({
  name: 'click',

  addOptions() {
    return {
      onClick: () => {},
    };
  },

  addProseMirrorPlugins() {
    const { onClick } = this.options;
    return [
      new Plugin({
        key: new PluginKey('click'),
        props: {
          handleDOMEvents: {
            click(view, event) {
              if (onClick) {
                onClick(view, event);
              }
              return false;
            },
          },
        },
      }),
    ];
  },
});
