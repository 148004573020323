import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, SETTINGS_TASK_CHECKLIST_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { GeneralisedRemoveConfirmDrawer } from '@/v2/feature/app-integration/features/app-details/components/generalised-remove-confirm-drawer-drawer.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { ChecklistDto } from '@/v2/feature/task/subfeature/checklist/checklist.dto';
import { ChecklistFormDrawer } from '@/v2/feature/task/subfeature/checklist/components/checklist-form-drawer.component';
import { emojiOptions } from '@/v2/feature/task/subfeature/checklist.interface';
import { TaskAPI } from '@/v2/feature/task/task.api';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { iconSize } from '@/v2/styles/table.styles';
import { pluralText } from '@/v2/util/string.util';

export const ChecklistsPage = (): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { trackPage } = useJune();

  const [checkLists, setChecklists] = useState<readonly ChecklistDto[]>([]);
  const [newChecklistDrawer, setNewChecklistDrawer] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isRemovalModalOpen, setIsRemovalModalOpen] = useState<boolean>(false);
  const [editChecklistOpen, setEditChecklistOpen] = useState<boolean>(false);
  const [checklist, setChecklist] = useState<ChecklistDto | null>(null);
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');
  const [searchInput, setSearchInput] = useState('');

  const refresh = useCallback(async () => {
    try {
      setLoading(true);
      const checkLists = await TaskAPI.getChecklists();
      setChecklists(checkLists);
    } catch (error) {
      showMessage(`${polyglot.t('ChecklistsPage.errorMessages.load')}. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [polyglot, showMessage]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    trackPage('Company checklists');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTasksActionsOptions = (checklist: ChecklistDto) => {
    return [
      {
        icon: <Edit {...iconSize} />,
        handler: () => {
          setEditChecklistOpen(true);
          setChecklist(checklist);
        },
        label: polyglot.t('General.edit'),
        disabled: false,
      },
      {
        icon: <Copy {...iconSize} />,
        handler: async () => await duplicateChecklist(checklist.id),
        label: polyglot.t('General.copy'),
        disabled: false,
      },
      {
        icon: <Trash {...iconSize} />,
        handler: async () => {
          setIsRemovalModalOpen(true);
          setChecklist(checklist);
        },
        label: polyglot.t('General.delete'),
        disabled: false,
      },
    ];
  };

  const duplicateChecklist = async (checklistId: number) => {
    setLoading(true);
    try {
      await TaskAPI.copyChecklistItemToChecklist(checklistId);
      showMessage(polyglot.t('TasksChecklistOverview.successMessages.copy'), 'success');
    } catch (error) {
      showMessage(`${polyglot.t('TasksChecklistOverview.errorMessages.copy')}. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteChecklist = async (checklistId: number) => {
    try {
      setLoading(true);
      await TaskAPI.deleteChecklistById(checklistId);
      showMessage(polyglot.t('TasksChecklistOverview.successMessages.delete'), 'success');
      routerHistory.push(SETTINGS_TASK_CHECKLIST_ROUTE);
    } catch (error) {
      showMessage(`${polyglot.t('TasksChecklistOverview.errorMessages.delete')}. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const getConfirmationMessage = useCallback(async (): Promise<string> => {
    const defaultMessage = polyglot.t('TasksChecklistConfirmRemove.normalConfirmMessage');

    if (checklist?.id) {
      const checklistCurrentlyUsedInOnboarding = await OnboardingAPI.isChecklistCurrentlyUsedInOnboarding(
        checklist?.id
      );
      if (!checklistCurrentlyUsedInOnboarding) {
        return defaultMessage;
      } else if (checklistCurrentlyUsedInOnboarding) {
        return polyglot.t('TasksChecklistConfirmRemove.checklistUsedInOnboardingConfirmMessage');
      }
    }

    return defaultMessage;
  }, [checklist, polyglot]);

  useEffect(() => {
    if (isRemovalModalOpen && checklist) {
      (async () => {
        const message = await getConfirmationMessage();
        setConfirmationMessage(message);
      })();
    }
  }, [isRemovalModalOpen, checklist, getConfirmationMessage]);

  const filteredChecklist = useMemo(() => {
    if (!checkLists) return [];
    if (!searchInput) return checkLists;

    return checkLists.filter((checklist) => checklist.name?.toLowerCase().includes(searchInput.toLowerCase()));
  }, [checkLists, searchInput]);

  return (
    <ContentWrapper loading={loading}>
      <SettingsSectionContent
        title={polyglot.t('ChecklistsPage.checklists')}
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setNewChecklistDrawer(true)}>
            {polyglot.t('ChecklistsPage.new')}
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <TableSearch
                      query={searchInput}
                      handleChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value: filteredChecklist ? (
                    <Box sx={flexContainerStyle}>
                      {filteredChecklist.map((item) => (
                        <SettingsItemCard
                          key={item.name}
                          title={
                            <Typography variant="title4">
                              {emojiOptions.find((emo) => emo.value === item.bgImg)?.label ?? item.bgImg ?? '🗒️'}{' '}
                              {item?.name ?? ''}
                            </Typography>
                          }
                          boxSx={responsiveCardStyle}
                          contentItemsSets={[
                            {
                              name: `${item?.checklistItems?.length || 0} item${pluralText(
                                item?.checklistItems?.length || 0
                              )}`,
                              type: ContentItemType.chip,
                              textColor: 'DarkGrey',
                              backgroundColor: 'white',
                              border: 'middle',
                            },
                          ]}
                          boxAction={() =>
                            routerHistory.push(
                              generatePath(SETTINGS_TASK_CHECKLIST_MANAGE_ROUTE, { checklistId: item.id })
                            )
                          }
                          advanceActionOptions={getTasksActionsOptions(item)}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="caption">{polyglot.t('ChecklistsPage.noChecklists')}</Typography>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>

      <DrawerModal isOpen={newChecklistDrawer} setIsOpen={setNewChecklistDrawer}>
        <ChecklistFormDrawer
          checklist={null}
          onClose={async () => {
            setNewChecklistDrawer(false);
            await refresh();
          }}
        />
      </DrawerModal>

      <DrawerModal isOpen={editChecklistOpen} setIsOpen={setEditChecklistOpen}>
        <ChecklistFormDrawer
          checklist={checklist}
          onClose={async () => {
            setEditChecklistOpen(false);
            await refresh();
          }}
        />
      </DrawerModal>

      <GeneralisedRemoveConfirmDrawer
        isOpen={isRemovalModalOpen}
        setIsOpen={setIsRemovalModalOpen}
        drawerTitle={polyglot.t('TasksChecklistConfirmRemove.title')}
        confirmationMessage={confirmationMessage}
        onClose={() => setIsRemovalModalOpen(false)}
        onConfirm={async () => {
          if (checklist?.id) deleteChecklist(checklist?.id);
        }}
      />
    </ContentWrapper>
  );
};
