import { Scope } from '@/models/scopes.model';

export interface AuthorizationAttemptResponse {
  status: number;
  data: {
    url?: string;
  };
}

export enum ReadAPIAction {
  ListUsers = 'listUsers',
  ListSites = 'listSites',
  ListDepartments = 'listDepartments',
  ListJobPositions = 'listJobPositions',
  ListPermissionGroupWithMembers = 'listPermissionGroupWithMembers',
  UserBasic = 'userBasic',
  UserPersonal = 'userPersonal',
  UserEventsByUserId = 'userEventsByUserId',
  UserEventCurrent = 'userEventCurrent',
  UserContractByUserId = 'userContractByUserId',
  UserContractCurrentByUserId = 'userContractCurrentByUserId',
  UserCompensationByUserId = 'userCompensationByUserId',
  UserCompensationCurrentByUserId = 'userCompensationCurrentByUserId',
  UserRole = 'userRole',
  UserRoleCurrent = 'userRoleCurrent',
  DocumentTypes = 'documentTypes',
}

export enum WriteAPIAction {
  CreateUser = 'createUser',
  UpdateBasic = 'updateBasic',
  UpdatePersonal = 'updatePersonal',
  UpdateUserEvent = 'updateUserEvent',
  UpdateUserContract = 'updateUserContract',
  DeleteUserContract = 'deleteUserContract',
  CreateNewUserContract = 'createNewUserContract',
  CreateUserCompensation = 'createUserCompensation',
  UpdateUserCompensation = 'updateUserCompensation',
  DeleteUserCompensation = 'deleteUserCompensation',
  CreateNewUserRole = 'createNewUserRole',
  UpdateUserRole = 'updateUserRole',
  DeleteUserRole = 'deleteUserRole',
  RehireUser = 'rehireUser',
  CreateDocument = 'createDocument',
}

interface APIDescriptionsConfig {
  read: Record<ReadAPIAction, string>;
  write: Record<WriteAPIAction, string>;
}

export const APIDescriptions: APIDescriptionsConfig = {
  read: {
    [ReadAPIAction.ListUsers]: 'List all users for company',
    [ReadAPIAction.ListSites]: 'List all sites for company',
    [ReadAPIAction.ListDepartments]: 'List all departments for company',
    [ReadAPIAction.ListJobPositions]: 'List all job positions',
    [ReadAPIAction.ListPermissionGroupWithMembers]: 'List all permission groups with members for the company',
    [ReadAPIAction.UserBasic]: 'Get user basic details',
    [ReadAPIAction.UserPersonal]: 'Get user personal info',
    [ReadAPIAction.UserEventsByUserId]: 'Get user events by user id',
    [ReadAPIAction.UserEventCurrent]: 'Get currently effective user event',
    [ReadAPIAction.UserContractByUserId]: 'Get user contracts by user id',
    [ReadAPIAction.UserContractCurrentByUserId]: 'Get current user contract by user id',
    [ReadAPIAction.UserCompensationByUserId]: 'Get user compensation details',
    [ReadAPIAction.UserCompensationCurrentByUserId]: 'Get currently effective compensation details',
    [ReadAPIAction.UserRole]: 'Get user role details',
    [ReadAPIAction.UserRoleCurrent]: 'Get currently effective user role',
    [ReadAPIAction.DocumentTypes]: 'List all document types for company',
  },
  write: {
    [WriteAPIAction.CreateUser]: 'Create a new user',
    [WriteAPIAction.UpdateBasic]: 'Update user basic details',
    [WriteAPIAction.UpdatePersonal]: 'Update user personal info',
    [WriteAPIAction.UpdateUserEvent]: 'Update a user event by id',
    [WriteAPIAction.UpdateUserContract]: 'Update a user contract record',
    [WriteAPIAction.DeleteUserContract]: 'Delete a user contract record',
    [WriteAPIAction.CreateNewUserContract]: 'Create a new user contract record',
    [WriteAPIAction.CreateUserCompensation]: 'Create a new user compensation record',
    [WriteAPIAction.UpdateUserCompensation]: 'Update user compensation record',
    [WriteAPIAction.DeleteUserCompensation]: 'Delete user compensation record',
    [WriteAPIAction.CreateNewUserRole]: 'Create a new user role record',
    [WriteAPIAction.UpdateUserRole]: 'Update user role record',
    [WriteAPIAction.DeleteUserRole]: 'Delete user role record',
    [WriteAPIAction.RehireUser]: 'Rehire a user',
    [WriteAPIAction.CreateDocument]: 'Create one or multiple documents for company',
  },
};

interface APIScopeMappingConfig {
  read: Record<ReadAPIAction, Scope[]>;
  write: Record<WriteAPIAction, Scope[]>;
}

export const APIScopeMapping: APIScopeMappingConfig = {
  read: {
    [ReadAPIAction.ListUsers]: ['user:read:all'],
    [ReadAPIAction.UserBasic]: ['user.basicInfo:read'],
    [ReadAPIAction.UserPersonal]: ['user.personalInfo:all', 'user.personalInfo:read'],
    [ReadAPIAction.UserEventsByUserId]: ['user.lifecycle:read:all'],
    [ReadAPIAction.UserEventCurrent]: ['user.lifecycle:read:all'],
    [ReadAPIAction.UserContractByUserId]: ['user.contract:read:all'],
    [ReadAPIAction.UserContractCurrentByUserId]: ['user.contract:read:all'],
    [ReadAPIAction.UserCompensationByUserId]: ['user.compensation:read:all'],
    [ReadAPIAction.UserCompensationCurrentByUserId]: ['user.compensation:read:all'],
    [ReadAPIAction.UserRole]: ['user.role:read:all'],
    [ReadAPIAction.UserRoleCurrent]: ['user.role:read:all'],
    [ReadAPIAction.ListSites]: ['company.settings:read'],
    [ReadAPIAction.ListDepartments]: ['company.settings:read'],
    [ReadAPIAction.ListJobPositions]: ['company.settings:read'],
    [ReadAPIAction.ListPermissionGroupWithMembers]: ['company.settings:read'],
    [ReadAPIAction.DocumentTypes]: ['documents:all'],
  },
  write: {
    [WriteAPIAction.UpdateBasic]: ['user.basicInfo:all'],
    [WriteAPIAction.UpdatePersonal]: ['user.personalInfo:all'],
    [WriteAPIAction.CreateUser]: ['user.onboard:all'],
    [WriteAPIAction.UpdateUserEvent]: ['user.lifecycle:all'],
    [WriteAPIAction.RehireUser]: ['user.lifecycle:all'],
    [WriteAPIAction.UpdateUserContract]: ['user.contract:all'],
    [WriteAPIAction.DeleteUserContract]: ['user.contract:all'],
    [WriteAPIAction.CreateNewUserContract]: ['user.contract:all'],
    [WriteAPIAction.CreateUserCompensation]: ['user.compensation:all'],
    [WriteAPIAction.UpdateUserCompensation]: ['user.compensation:all'],
    [WriteAPIAction.DeleteUserCompensation]: ['user.compensation:all'],
    [WriteAPIAction.CreateNewUserRole]: ['user.role:all'],
    [WriteAPIAction.UpdateUserRole]: ['user.role:all'],
    [WriteAPIAction.DeleteUserRole]: ['user.role:all'],
    [WriteAPIAction.CreateDocument]: ['documents:all'],
  },
};
