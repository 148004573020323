import { Dispatch, SetStateAction, useState } from 'react';

import { Box } from '@mui/system';
import { Editor } from '@tiptap/react';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';

import { AnchorEl } from '@/v2/components/forms/document-editor/document-editor.util';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';

interface ImageDimensionsEditorProps {
  editor: Editor;
  anchorEl: AnchorEl | undefined;
  setAnchorEl: any;
  setShowImageModal: Dispatch<SetStateAction<boolean>>;
  showImageModal: boolean;
}

export const ImageDimensionsEditor = ({
  editor,
  anchorEl,
  setAnchorEl,
  setShowImageModal,
  showImageModal,
}: ImageDimensionsEditorProps) => {
  const [imageError, setImageError] = useState<{ height?: boolean; width?: boolean } | undefined>();
  const { polyglot } = usePolyglot();

  return (
    <DrawerModal
      isOpen={showImageModal}
      onClose={() => {
        setAnchorEl(undefined);
        setShowImageModal(false);
      }}
      setIsOpen={(val) => {
        if (!val) setAnchorEl(undefined);
        setShowImageModal(val);
      }}
    >
      <Box sx={drawerContentSx}>
        <Typography variant="title2">{'Update Image Size'}</Typography>
        <Box sx={{ width: '100%', gap: '5px' }}>
          <TextfieldComponent
            name="Height"
            label={'Height'}
            value={anchorEl?.height}
            onChange={(e) => {
              setImageError(undefined);
              setAnchorEl((prevState: AnchorEl | undefined) => {
                if (!prevState) {
                  return;
                }
                return {
                  ...prevState,
                  height: parseInt(e.target.value),
                };
              });
            }}
            type="number"
            fullWidth
            minValue={0}
            error={imageError?.height}
          />
        </Box>
        <Box sx={{ width: '100%', gap: '5px' }}>
          <TextfieldComponent
            name="Width"
            label={'Width'}
            value={anchorEl?.width}
            onChange={(e) => {
              setImageError(undefined);
              setAnchorEl((prevState: AnchorEl | undefined) => {
                if (!prevState) {
                  return;
                }
                return {
                  ...prevState,
                  width: parseInt(e.target.value),
                };
              });
            }}
            type="number"
            fullWidth
            minValue={0}
            error={imageError?.width}
          />
        </Box>
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            loading={false}
            name={polyglot.t('General.save')}
            sizeVariant="medium"
            colorVariant="primary"
            fullWidth
            onClick={() => {
              const view = editor.view;
              if (!anchorEl?.height || (anchorEl && anchorEl.height < 1)) {
                setImageError({ height: true });
                return;
              }
              if (!anchorEl?.width || (anchorEl && anchorEl.width < 1)) {
                setImageError({ width: true });
                return;
              }
              setImageError(undefined);
              //@ts-ignore
              const pos = editor?.view.posAtDOM(anchorEl?.target as Node, 0);
              const tr = view.state.tr;
              const node = view.state.doc.nodeAt(pos);
              const newStyle = `height: ${anchorEl?.height}px; width: ${anchorEl?.width}px;`;
              tr.setNodeMarkup(pos, undefined, {
                ...node?.attrs,
                style: newStyle,
              });
              view.dispatch(tr);
              setAnchorEl(undefined);
              setShowImageModal(false);
            }}
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
