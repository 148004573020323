import React, { Dispatch, ReactNode, SetStateAction, useCallback, useContext, useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DeviceTransitTodo } from '@v2/feature/dashboard/interfaces/dashboard.interface';
import { DeviceAPI, DeviceEndpoints } from '@v2/feature/device/device.api';
import { DeviceTransitDto } from '@v2/feature/device/device.dto';
import { DevicePossessionType, DeviceTransitStatus } from '@v2/feature/device/device.interface';
import { getDeviceOwnerByDevicePossession, getModelImage } from '@v2/feature/device/device.util';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { CachedUser, useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { LocalDate } from '@v2/util/local-date';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const DeviceTransitActionItem = ({
  deviceTransitTodo,
  afterClose,
  refresh,
}: {
  deviceTransitTodo: DeviceTransitTodo;
  afterClose: () => void;
  refresh: () => Promise<void>;
}) => {
  const { data: deviceTransit } = useApiClient(DeviceEndpoints.getTransitsByTransitId(deviceTransitTodo.id), {
    suspense: false,
  });
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(deviceTransitTodo));
  return (
    <DeviceTransitStartModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      afterClose={afterClose}
      onClose={() => setIsOpen(false)}
      refresh={refresh}
      selectedDeviceTransitDevice={deviceTransitTodo}
      deviceTransitDto={deviceTransit ?? undefined}
    />
  );
};

const DeviceTransitStartModal = ({
  isOpen,
  setIsOpen,
  onClose,
  afterClose,
  refresh,
  selectedDeviceTransitDevice,
  deviceTransitDto,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  afterClose: () => void;
  refresh: () => Promise<void>;
  selectedDeviceTransitDevice: DeviceTransitTodo | undefined;
  deviceTransitDto: DeviceTransitDto | undefined;
}) => {
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { polyglot } = usePolyglot();

  const isSender = currentUser.userId === deviceTransitDto?.sender?.possessionId;
  const isReceiver = currentUser.userId === deviceTransitDto?.receiver?.possessionId;

  const { getCachedUserById } = useCachedUsers();

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} afterClose={afterClose}>
      {deviceTransitDto && selectedDeviceTransitDevice ? (
        isSender && deviceTransitDto.status === DeviceTransitStatus.Pending ? (
          <DeviceTransitInShippingConfirmContent
            onClose={() => {
              onClose();
              afterClose();
            }}
            deviceTransitDto={deviceTransitDto}
            selectedDeviceTransitDevice={selectedDeviceTransitDevice}
            getCachedUserById={getCachedUserById}
            refresh={refresh}
          />
        ) : isReceiver && deviceTransitDto.status === DeviceTransitStatus.Shipping ? (
          <DeviceTransitShippedConfirmation
            onClose={() => {
              onClose();
              afterClose();
            }}
            selectedDeviceTransitDevice={selectedDeviceTransitDevice}
            deviceTransitDto={deviceTransitDto}
            getCachedUserById={getCachedUserById}
          />
        ) : (
          <Typography variant="caption" color="Grey">
            {polyglot.t('DeviceTransitActionItem.noActionAvailable')}
          </Typography>
        )
      ) : (
        <Typography variant="caption" color="Grey">
          {polyglot.t('DeviceTransitActionItem.noDeviceSelected')}
        </Typography>
      )}
    </DrawerModal>
  );
};
const DeviceTransitInShippingConfirmContent = ({
  deviceTransitDto,
  selectedDeviceTransitDevice,
  onClose,
  getCachedUserById,
  refresh,
}: {
  deviceTransitDto: DeviceTransitDto;
  selectedDeviceTransitDevice: DeviceTransitTodo;
  onClose: () => void;
  getCachedUserById: (userId: number) => CachedUser | undefined;
  refresh: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showTrackingLinkForm, setShowTrackingLinkForm] = useState<boolean>(false); // New state for tracking link form
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const getUserName = useCallback(
    (assignedUserId: number): string | undefined => {
      const user = getCachedUserById(assignedUserId);
      return polyglot.t(user?.displayName ?? '');
    },
    [getCachedUserById, polyglot]
  );

  const formik = useFormik<DeviceTransitDto>({
    initialValues: {
      id: deviceTransitDto?.id,
      companyId: deviceTransitDto?.companyId,
      deviceId: deviceTransitDto?.deviceId,
      senderId: deviceTransitDto?.senderId,
      receiverId: deviceTransitDto?.receiverId,
      status: deviceTransitDto?.status,
      notes: deviceTransitDto?.notes,
      senderAddress: deviceTransitDto?.senderAddress,
      deliveryAddress: deviceTransitDto?.deliveryAddress,
      createdBy: deviceTransitDto?.createdBy,
      trackingLink: deviceTransitDto?.trackingLink,
      isShippingManagedByZelt: deviceTransitDto?.isShippingManagedByZelt,
      sender: deviceTransitDto?.sender,
      receiver: deviceTransitDto?.receiver,
      device: deviceTransitDto?.device,
      createdAt: deviceTransitDto?.createdAt,
      updatedAt: deviceTransitDto?.updatedAt,
    },
    validationSchema: yup.object({}),
    onSubmit: async () => {
      try {
        setLoading(true);
        setShowTrackingLinkForm(true);
      } catch (error) {
        showMessage(
          `${polyglot.t('DeviceTransitInShippingConfirmContent.errorMessage', {
            errorMessage: nestErrorMessage(error),
          })}`,
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
  });
  if (showTrackingLinkForm) {
    return (
      <DeviceTransitInShippingAddTrackingLink onClose={onClose} refresh={refresh} deviceTransitDto={deviceTransitDto} />
    );
  }
  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g8 }}>
          <Typography variant="title2">{polyglot.t('DeviceTransitInShippingConfirmContent.title')}</Typography>

          <Typography variant="caption">
            {polyglot.t('DeviceTransitInShippingConfirmContent.description', {
              userName: getUserName(deviceTransitDto.createdBy),
            })}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4, mt: 4 }}>
            {getModelImage(deviceTransitDto.device?.type, deviceTransitDto.device?.modelName, {
              width: '200px',
              height: 'auto',
            })}
          </Box>
        </Box>

        {deviceTransitDto.device?.deviceName && (
          <ViewItem label="Device name" value={deviceTransitDto.device?.deviceName} />
        )}
        {deviceTransitDto.device?.modelName && (
          <ViewItem label="Model name" value={deviceTransitDto.device?.modelName} />
        )}
        {deviceTransitDto.device?.serialNumber && (
          <ViewItem label="Serial number" value={deviceTransitDto.device?.serialNumber} />
        )}
        <Typography variant="title4" sx={{ mt: spacing.mt10 }}>
          {polyglot.t('DeviceTransitInShippingConfirmContent.shipTo')}
        </Typography>

        {selectedDeviceTransitDevice && (
          <ViewItem
            label="Receiver"
            value={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {selectedDeviceTransitDevice.receiverPossession.possessionType === DevicePossessionType.User && (
                  <UserAvatar
                    key={`avatar-${selectedDeviceTransitDevice.receiverPossession.possessionId}`}
                    userId={selectedDeviceTransitDevice.receiverPossession.possessionId}
                    size="xxsmall"
                  />
                )}
                <Typography variant="title4">
                  {getDeviceOwnerByDevicePossession(
                    selectedDeviceTransitDevice.receiverPossession,
                    null,
                    getCachedUserById
                  )}
                </Typography>
              </Box>
            }
          />
        )}
        {deviceTransitDto?.deliveryAddress && <ViewItem label="Address" value={deviceTransitDto.deliveryAddress} />}
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name="I've sent it!"
            fullWidth
            type="submit"
            loading={loading}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

const isUrlValid = (url: string): boolean => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z/\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );
  return pattern.test(url);
};

const DeviceTransitInShippingAddTrackingLink = ({
  deviceTransitDto,
  onClose,
  refresh,
}: {
  deviceTransitDto: DeviceTransitDto;
  onClose: () => void;
  refresh: () => Promise<void>;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const formik = useFormik<{ trackingLink: string }>({
    initialValues: {
      trackingLink: deviceTransitDto?.trackingLink ?? '',
    },
    validationSchema: yup.object({}),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const validatedLink = values.trackingLink.startsWith('https://')
          ? values.trackingLink
          : `https://${values.trackingLink}`;
        if (!isUrlValid(validatedLink)) throw new Error('Tracking link is not valid URL!');
        await DeviceAPI.updateDeviceTransitTrackingLink(deviceTransitDto.id, values.trackingLink);
        await DeviceAPI.changeDeviceOwnerSetTransitInShipping(deviceTransitDto.id);
        showMessage('Device transit updated successfully', 'success');
        await refresh();
        onClose();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g8 }}>
          <Typography variant="title2"> {polyglot.t('DeviceTransitInShippingAddTrackingLink.title')}</Typography>

          <Typography variant="caption">{polyglot.t('DeviceTransitInShippingAddTrackingLink.description')}</Typography>
        </Box>

        <TextfieldComponent
          label="Tracking Link"
          name="trackingLink"
          value={formik.values.trackingLink}
          onChange={formik.handleChange}
          placeholder="https://"
          helperText="Enter the tracking link, starting with https://"
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="secondary"
            name="Skip"
            fullWidth
            onClick={async () => {
              await DeviceAPI.changeDeviceOwnerSetTransitInShipping(deviceTransitDto.id);
              showMessage('Device transit updated successfully', 'success');
              await refresh();
            }}
            loading={loading}
          />
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name="Save"
            fullWidth
            type="submit"
            loading={loading}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

const DeviceTransitShippedConfirmation = ({
  deviceTransitDto,
  selectedDeviceTransitDevice,
  onClose,
  getCachedUserById,
}: {
  deviceTransitDto: DeviceTransitDto;
  selectedDeviceTransitDevice: DeviceTransitTodo;
  onClose: () => void;
  getCachedUserById: (userId: number) => CachedUser | undefined;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const formik = useFormik<DeviceTransitDto>({
    initialValues: {
      id: deviceTransitDto?.id,
      companyId: deviceTransitDto?.companyId,
      deviceId: deviceTransitDto?.deviceId,
      senderId: deviceTransitDto?.senderId,
      receiverId: deviceTransitDto?.receiverId,
      status: deviceTransitDto?.status,
      notes: deviceTransitDto?.notes,
      senderAddress: deviceTransitDto?.senderAddress,
      deliveryAddress: deviceTransitDto?.deliveryAddress,
      createdBy: deviceTransitDto?.createdBy,
      trackingLink: deviceTransitDto?.trackingLink,
      isShippingManagedByZelt: deviceTransitDto?.isShippingManagedByZelt,
      sender: deviceTransitDto?.sender,
      receiver: deviceTransitDto?.receiver,
      device: deviceTransitDto?.device,
      createdAt: deviceTransitDto?.createdAt,
      updatedAt: deviceTransitDto?.updatedAt,
    },
    validationSchema: yup.object({}),
    onSubmit: async () => {
      try {
        setLoading(true);
        await DeviceAPI.changeDeviceOwnerSetTransitAsDelivered(deviceTransitDto.id, new LocalDate().toDateString());

        showMessage('Device transit updated successfully', 'success');
        onClose();
      } catch (error) {
        showMessage(`Something went wrong. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('DeviceTransitShippedConfirmation.title')}</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4, mt: 4 }}>
          {getModelImage(deviceTransitDto.device?.type, deviceTransitDto.device?.modelName, {
            width: '200px',
            height: 'auto',
          })}
        </Box>
        {deviceTransitDto.trackingLink && (
          <ViewItem
            label="Tracking link"
            value={
              <a
                href={deviceTransitDto.trackingLink}
                target="_blank"
                rel="noreferrer"
                style={{ color: themeColors.DarkGrey }}
              >
                Click here
              </a>
            }
          />
        )}{' '}
        {deviceTransitDto.device?.modelName && (
          <ViewItem label="Model name" value={deviceTransitDto.device?.modelName} />
        )}
        {deviceTransitDto.device?.serialNumber && (
          <ViewItem label="Serial number" value={deviceTransitDto.device?.serialNumber} />
        )}
        <Typography variant="title4"> {polyglot.t('DeviceTransitShippedConfirmation.shipTo')}</Typography>
        {selectedDeviceTransitDevice && (
          <ViewItem
            label="Receiver"
            value={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {selectedDeviceTransitDevice.receiverPossession.possessionType === DevicePossessionType.User && (
                  <UserAvatar
                    key={`avatar-${selectedDeviceTransitDevice.receiverPossession.possessionId}`}
                    userId={selectedDeviceTransitDevice.receiverPossession.possessionId}
                    size="xxsmall"
                  />
                )}
                <Typography variant="title4">
                  {getDeviceOwnerByDevicePossession(
                    selectedDeviceTransitDevice.receiverPossession,
                    null,
                    getCachedUserById
                  )}
                </Typography>
              </Box>
            }
          />
        )}
        {deviceTransitDto?.deliveryAddress && <ViewItem label="Address" value={deviceTransitDto.deliveryAddress} />}
        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            sizeVariant="medium"
            colorVariant="primary"
            name="Confirm"
            fullWidth
            type="submit"
            loading={loading}
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};

const ViewItem = ({ label, value }: { label: string; value: ReactNode }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g4 }}>
      <Typography variant="caption">{label}:</Typography>
      <Typography variant="title4">{value}</Typography>
    </Box>
  );
};
