import { LocalDate } from '@v2/util/local-date';

export enum ReportDisplayFormat {
  none = 'raw',
  number1 = 'number1', // 1,234.89 (decimal with 2 digits and comma) // DEFAULT
  number2 = 'number2', // 1,234.9 (decimal with 1 digit and comma)
  number3 = 'number3', // 1,234 (integer with comma) // DEFAULT
  number4 = 'number4', // 12.89 (decimal with 2 digits, no comma)
  number5 = 'number5', // 12.9 (decimal with 1 digits, no comma)
  number6 = 'number6', // 12 (integer, no coma)
  dateonly1 = 'dateonly1', // '09/02/2024'
  dateonly2 = 'dateonly2', // '9/02/2024'
  dateonly3 = 'dateonly3', // '2024-02-09'
  dateonly4 = 'dateonly4', // '2/09/2024'
  dateonly5 = 'dateonly5', // '9 Feb 2024'
  dateonly6 = 'dateonly6', // 'February 2024'
  dateonly7 = 'dateonly7', // 'Feb 2024'
  dateonly8 = 'dateonly8', // '02 2024'
  dateonly9 = 'dateonly9', // '02/2024'
  dateonly10 = 'dateonly10', // '02'
  dateonly11 = 'dateonly11', // '2024'
  datetime1 = 'datetime1', // TODO:
  datetime2 = 'datetime2', // TODO:
  datetime3 = 'datetime3', // TODO:
  // time1 = 'time1', // TODO:
}

export const ReportDisplayFormatNumberOptions = [
  { value: ReportDisplayFormat.none, label: 'None' },
  {
    value: ReportDisplayFormat.number1,
    label: '#,###.00',
    description: 'Numbers with commas for thousands, 2 decimals',
  },
  {
    value: ReportDisplayFormat.number2,
    label: '#,###.0',
    description: 'Numbers with commas for thousands and 1 decimal',
  },
  { value: ReportDisplayFormat.number3, label: '#,###', description: 'Numbers with commas for thousands, no decimals' },

  { value: ReportDisplayFormat.number4, label: '1234.00', description: 'Numbers with 2 decimals, no commas' },
  { value: ReportDisplayFormat.number5, label: '1234.0', description: 'Numbers with 1 decimals, no commas' },
  { value: ReportDisplayFormat.number6, label: '1234', description: 'Numbers with decimals or commas' },
];

export const ReportDisplayFormatDateOptions = [
  { value: ReportDisplayFormat.none, label: 'None' },
  { value: ReportDisplayFormat.dateonly1, label: 'DD/MM/YYYY', description: '09/02/2024' },
  { value: ReportDisplayFormat.dateonly2, label: 'D/MM/YYYY', description: '9/02/2024' },
  { value: ReportDisplayFormat.dateonly3, label: 'YYYY-MM-DD', description: '2024-02-09' },
  { value: ReportDisplayFormat.dateonly4, label: 'M/DD/YYYY', description: '2/09/2024' },
  { value: ReportDisplayFormat.dateonly5, label: 'D MMM YYYY', description: '9 Feb 2024' },
  { value: ReportDisplayFormat.dateonly6, label: 'MMMM YYYY', description: 'February 2024' },
  { value: ReportDisplayFormat.dateonly7, label: 'MMM YYYY', description: 'Feb 2024' },
  { value: ReportDisplayFormat.dateonly8, label: 'MM YYYY', description: '02 2024' },
  { value: ReportDisplayFormat.dateonly9, label: 'MM/YYYY', description: '02/2024' },
  { value: ReportDisplayFormat.dateonly10, label: 'MM', description: '02' },
  { value: ReportDisplayFormat.dateonly11, label: 'YYYY', description: '2024' },
];

export const ReportDisplayFormatDateTimeOptions = [
  { value: ReportDisplayFormat.none, label: 'None' },
  { value: ReportDisplayFormat.datetime1, label: 'DD/MM/YYYY HH:MM', description: '' },
  { value: ReportDisplayFormat.datetime2, label: 'YYYY-MM-DD HH:MM', description: '' },
  { value: ReportDisplayFormat.datetime3, label: 'DD/MM/YYYY HH:MM', description: '' },
];

const ReportDisplayFormatHandler: Record<ReportDisplayFormat, (value: string) => string> = {
  [ReportDisplayFormat.none]: (value: string) => value,
  [ReportDisplayFormat.number1]: (value: string) => {
    // 1,234.89
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value) * 100) / 100;
    return numberValue.toLocaleString('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
  [ReportDisplayFormat.number2]: (value: string) => {
    // 1,234.9
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value) * 10) / 10;
    return numberValue.toLocaleString('en-GB', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    });
  },
  [ReportDisplayFormat.number3]: (value: string) => {
    // 1,234
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value));
    return numberValue.toLocaleString('en-GB');
  },
  [ReportDisplayFormat.number4]: (value: string) => {
    // 1234.89
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value) * 100) / 100;
    return numberValue.toFixed(2);
  },
  [ReportDisplayFormat.number5]: (value: string) => {
    // 1234.9
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value) * 10) / 10;
    return numberValue.toFixed(1);
  },
  [ReportDisplayFormat.number6]: (value: string) => {
    // 1234
    if (Number.isNaN(value)) return value;
    const numberValue = Math.floor(Number(value));
    return numberValue.toString();
  },
  // DATEONLY
  [ReportDisplayFormat.dateonly1]: (value: string) => {
    // '09/02/2024'
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;

    return date.getDate().toLocaleDateString('en-GB');
  },
  [ReportDisplayFormat.dateonly2]: (value: string) => {
    // '9/02/2024'
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;

    return date.getDate().toLocaleDateString('en-GB', { day: 'numeric', month: '2-digit', year: 'numeric' });
  },
  [ReportDisplayFormat.dateonly3]: (value: string) => {
    // '2024-02-09'
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;

    return date.toDateString();
  },
  [ReportDisplayFormat.dateonly4]: (value: string) => {
    // '2/09/2024'
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;

    return date.getDate().toLocaleDateString('en-US');
  },
  [ReportDisplayFormat.dateonly5]: (value: string) => {
    // '9 Feb 2024'
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;

    return date.getDate().toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
  },
  [ReportDisplayFormat.dateonly6]: (value: string) => {
    // 'February 2024'
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;

    return date.getDate().toLocaleDateString('en-GB', { month: 'long', year: 'numeric' });
  },
  [ReportDisplayFormat.dateonly7]: (value: string) => {
    // 'Feb 2024'
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;

    return date.getDate().toLocaleDateString('en-GB', { month: 'short', year: 'numeric' });
  },
  [ReportDisplayFormat.dateonly8]: (value: string) => {
    // '02 2024'
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;

    return date.getDate().toLocaleDateString('en-GB', { month: '2-digit', year: 'numeric' }).replace('/', ' ');
  },
  [ReportDisplayFormat.dateonly9]: (value: string) => {
    // '02/2024'
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;

    return date.getDate().toLocaleDateString('en-GB', { month: '2-digit', year: 'numeric' });
  },
  [ReportDisplayFormat.dateonly10]: (value: string) => {
    // '02' // month
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString('en-GB', { month: '2-digit' });
  },
  [ReportDisplayFormat.dateonly11]: (value: string) => {
    // '2024' // year
    if (!value) return '';
    const date = new LocalDate(value);

    if (!date.isDate()) return value;
    return date.getDate().toLocaleDateString('en-GB', { year: 'numeric' });
  },
  // DATE AND TIME
  [ReportDisplayFormat.datetime1]: (value: string) => {
    if (!value) return '';
    const date = new LocalDate(value);
    if (!date.isDate()) return value;

    return date.getDate().toLocaleString('en-GB', {
      day: '2-digit',
      year: '2-digit',
      month: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    });
  },
  [ReportDisplayFormat.datetime2]: (value: string) => {
    if (!value) return '';
    const date = new LocalDate(value);
    if (!date.isDate()) return value;

    return date.toDateTimeString();
  },
  [ReportDisplayFormat.datetime3]: (value: string) => {
    if (!value) return '';
    const date = new LocalDate(value);
    if (!date.isDate()) return value;

    return date.getDate().toLocaleString('en-US', {
      day: 'numeric',
      year: 'numeric',
      month: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  },
  // [ReportDisplayFormat.time1]: (value: string) => {
  //   if (!value) return '';
  //   const date = new LocalDate(value);
  //   if (!date.isDate()) return value;
  //
  //   return date.getDate().toLocaleString(undefined, {
  //     hour: 'numeric',
  //     minute: 'numeric',
  //   });
  // },
};

export function formatReportValue(value: string, format: ReportDisplayFormat): string {
  if (!value) return '';

  if (!format || !Object.values(ReportDisplayFormat).includes(format) || format === ReportDisplayFormat.none)
    return value;

  const handlerFunction = ReportDisplayFormatHandler[format];

  if (!handlerFunction) return value;

  return handlerFunction(value);
}
