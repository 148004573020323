import React, { useCallback, useMemo, useState } from 'react';

import { Box, List, ListItem, ListItemText } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { OauthClientEndpoints } from '@/api-client/oauth-client.api';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as WaitingEmpty } from '@/images/side-bar-icons/WaitingEmpty.svg';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { PublishRequestsApproveRejectDrawerForm } from '@/v2/feature/security/security-settings/features/developer-hub/components/publish-requests-approve-reject-drawer.page';
import { PublishRequestForSuperadmin } from '@/v2/feature/security/security-settings/features/developer-hub/developer-hub.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { toTitleCase } from '@/v2/util/string.util';

export const PublishRequestsBackofficePage = (): React.JSX.Element => {
  const {
    data: publishRequests,
    isLoading: loadingRequests,
    mutate: refresh,
  } = useApiClient(OauthClientEndpoints.getPublishRequestsForAllCompanies(), { suspense: false });

  const [searchInput, setSearchInput] = useState<string>('');
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedRequest, setSelectedRequest] = useState<PublishRequestForSuperadmin | null>(null);

  const filteredRequests = useMemo(() => {
    if (!publishRequests) return [];
    if (!searchInput) return publishRequests;

    const search = searchInput.toLowerCase();

    return publishRequests.filter((request) => {
      const requestIdMatch = request.id.toLowerCase().includes(search);
      const clientIdMatch = request.clientId.toLowerCase().includes(search);
      const companyNameMatch = request.company?.name?.toLowerCase().includes(search);
      const companyIdMatch = request.company?.companyId?.toString().includes(search);
      const oauthClientNameMatch = request.oauthClient?.name?.toLowerCase().includes(search);

      return requestIdMatch || clientIdMatch || companyNameMatch || companyIdMatch || oauthClientNameMatch;
    });
  }, [searchInput, publishRequests]);

  const columnData = useMemo<ColumnDef<PublishRequestForSuperadmin, any>[]>(
    () => [
      {
        header: () => 'Client ID',
        accessorFn: (row) => row,
        id: 'clientId',
        enableSorting: true,
        cell: (info) => {
          const row = info.getValue();
          return (
            <Box>
              <Typography variant="caption">{row?.oauthClient?.name}</Typography>
              <br />
              <Typography variant="captionSmall">{row?.oauthClient?.clientId}</Typography>
            </Box>
          );
        },
      },
      {
        header: () => 'Company',
        accessorFn: (row) => row,
        id: 'companyId',
        enableSorting: true,
        cell: (info) => {
          const row = info.getValue();
          return (
            <Box>
              <Typography variant="caption">{`${row?.company?.name} (${row?.companyId})`}</Typography>
            </Box>
          );
        },
      },
      // {
      //   header: () => 'Requested Scopes',
      //   accessorFn: (row) => row,
      //   id: 'requestedScopes',
      //   enableSorting: false,
      //   cell: ({ row: { original } }) => (
      //     <List dense>
      //       {original.requestedScopes.map((scope) => (
      //         <ListItem key={scope} sx={{ pl: 0 }}>
      //           <ListItemText primary={<Typography variant="caption">{scope}</Typography>} />
      //         </ListItem>
      //       ))}
      //     </List>
      //   ),
      // },
      {
        header: () => 'Scopes / Reasons',
        accessorFn: (row) => row,
        id: 'scopeReasons',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <>
            {original.scopeReasons ? (
              <List dense>
                {Object.entries(original.scopeReasons).map(([scope, reason]) => (
                  <ListItem key={scope} sx={{ pl: 0 }}>
                    <ListItemText
                      primary={<Typography variant="caption">{scope}</Typography>}
                      secondary={<Typography variant="captionSmall">{reason}</Typography>}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <EmptyCell />
            )}
          </>
        ),
      },
      {
        header: () => 'Reviewed by',
        accessorFn: (row) => row.reviewedBy,
        id: 'reviewedBy',
        enableSorting: true,
        cell: (info) => (
          <Box>
            <Typography variant="caption">{info.getValue()}</Typography>
          </Box>
        ),
      },
      {
        header: () => 'Reviewed at',
        accessorFn: (row) => row.reviewedAt,
        id: 'reviewedAt',
        enableSorting: false,
        cell: (info) => (
          <Box>
            {info.getValue() ? (
              <Typography variant="caption">{new Date(info.getValue() as Date).toUTCString()}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        header: () => 'Notes',
        accessorFn: (row) => row.reviewNotes,
        id: 'reviewNotes',
        enableSorting: false,
        cell: (info) => (
          <Box>
            <Typography variant="caption">{info.getValue()}</Typography>
          </Box>
        ),
      },
      {
        header: () => 'Created At',
        accessorFn: (row) => row.createdAt,
        id: 'createdAt',
        enableSorting: false,
        cell: (info) => (
          <Box>
            <Typography variant="caption">{new Date(info.getValue() as Date).toUTCString()}</Typography>
          </Box>
        ),
      },
      {
        header: () => 'Status',
        accessorFn: (row) => row.status,
        id: 'status',
        enableSorting: true,
        cell: (info) => {
          const status = info.getValue();

          const getStatusIcon = (status: string) => {
            switch (status) {
              case 'pending':
                return <WaitingEmpty />;
              case 'approved':
                return <OkGreen fill={themeColors.Green} />;
              case 'rejected':
                return <Rejected fill={themeColors.Red} />;
              default:
                return null;
            }
          };

          return (
            <Box display="flex" alignItems="center">
              {getStatusIcon(status)}
              <Typography variant="caption" sx={{ ml: 1 }}>
                {toTitleCase(status)}
              </Typography>
            </Box>
          );
        },
      },
    ],
    []
  );

  const handleRowClick = useCallback((row: Row<PublishRequestForSuperadmin>) => {
    if (row.original.status !== 'pending') return;
    setSelectedRequest(row.original);
    setIsDrawerOpen(true);
  }, []);

  return (
    <RootStyle>
      <TopHeader title={<Typography variant="title2">Publish requests for Partner API</Typography>} showAction />

      <ContentWrapper loading={loadingRequests} sx={{}}>
        <Box>
          <TableSearch
            placeholder="Search"
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
            style={{ width: '250px', minWidth: '250px' }}
          />
        </Box>
        <Box sx={{ mt: '10px' }}>
          <BasicTable<PublishRequestForSuperadmin>
            rowData={filteredRequests}
            columnData={columnData}
            loading={loadingRequests}
            hidePagination
            rowClick={handleRowClick}
          />
        </Box>
        {selectedRequest && (
          <DrawerModal isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen}>
            <PublishRequestsApproveRejectDrawerForm
              initialValues={selectedRequest}
              onClose={() => {
                setIsDrawerOpen(false);
                refresh!();
              }}
            />
          </DrawerModal>
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
