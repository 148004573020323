import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CellContext, ColumnDef, PaginationState } from '@tanstack/react-table';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { BasicServerTable } from '@v2/components/table/server-side-table.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceAPI, DeviceEndpoints } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DeviceExternalMatching } from '@v2/feature/device/device.interface';
import {
  getDeviceOwnerAsSuperadminByDevicePossession,
  getMdmServer,
  getUserNamesKeyedByUserIds,
  isEnroled,
} from '@v2/feature/device/device.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { SuperAdminOverviewDeviceDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-overview-device-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { secondaryCTABtn } from '@v2/styles/buttons.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { pipe } from 'fp-ts/function';
import { keyBy } from 'lodash';
import CsvDownloader from 'react-csv-downloader';
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { Subject } from 'rxjs';
import * as RX from 'rxjs/operators';

import { SiteEndpoints } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

interface Props {
  readonly pageConfig: PageConfig;
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly companies: readonly SuperAdminCompanyInfo[];
}

export const SuperAdminAllDevicesOverviewPage = ({ users, companies, pageConfig }: Props): JSX.Element => {
  const DEFAULT_PAGE_SIZE = 20; // You can adjust this value as needed
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [devicePossessions, setDevicePossessions] = useState<DevicePossessionDto[]>([]);
  const [matchings, setMatchings] = useState<DeviceExternalMatching>({});
  const [devicePossessionToBeEdited, setDevicePossessionToBeEdited] = useState<DevicePossessionDto | null>(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [sites, setSitesById] = useState<{ [id: number]: SiteDto }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showMessage] = useMessage();
  const [searchInput, setSearchInput] = useState<string | null>(null);
  const [changeSearchInput$] = useState(() => new Subject<string>());
  const { data: siteData } = useApiClient(SiteEndpoints.getSites(), { suspense: false });
  const { data: superAdminDevicesResponse, mutate: refreshDevicePossessions } = useApiClient(
    DeviceEndpoints.getAllDevicesAsSuperAdmin(null, searchInput, pagination.pageIndex, pagination.pageSize),
    {
      suspense: false,
    }
  );
  const refreshDevicePossessionsData = useCallback(async () => {
    if (refreshDevicePossessions) await refreshDevicePossessions();
  }, [refreshDevicePossessions]);

  useEffect(() => {
    if (siteData) {
      setSitesById(keyBy(siteData, 'id'));
    }
  }, [siteData]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      await refreshDevicePossessionsData();
    } catch (error) {
      showMessage(`Could not retrieve data. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [refreshDevicePossessionsData, showMessage]);

  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);

  useEffect(() => {
    const subscription = pipe(
      changeSearchInput$,
      RX.debounceTime(1000),
      RX.tap((value) => setSearchInput(value)),
      RX.tap(() => fetchData())
    ).subscribe();

    return () => subscription.unsubscribe();
  }, [changeSearchInput$, fetchData]);

  const getCompanyNameById = useCallback(
    (companyId: number | null): string | undefined => {
      if (!companyId) return undefined;
      return companies.find((company) => company.companyId === companyId)?.name;
    },
    [companies]
  );

  const formatDevicePossessionForCsv = async () => {
    const dps = await DeviceAPI.getAllDevicesAsSuperAdminWithoutPagination(null, searchInput);
    return dps.devicePossessions?.items?.map((devicePossession) => ({
      'Device Id': devicePossession.device?.id || '',
      'Serial number': devicePossession.device?.serialNumber || '',
      'Company name': getCompanyNameById(devicePossession.companyId) ?? (devicePossession.possessionType || ''),
      'Owned By': getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sites) || '',
      'Device model': devicePossession.device?.modelName || '',
      Ownership: devicePossession.device?.ownership || '',
      Price: devicePossession.device?.price ? `£${devicePossession.device.price}` : '',
      'Contract Length': devicePossession.device?.contractLength
        ? `${devicePossession.device.contractLength} months`
        : '',
      'Contract start': devicePossession.device?.contractStartDate || '',
      isEnrolled: isEnroled(devicePossession.device?.enrollmentStatus),
      MDM: getMdmServer(devicePossession.device, matchings),
    }));
  };
  const tableData = devicePossessions;

  const columnData = useMemo<ColumnDef<DevicePossessionDto, DevicePossessionDto>[]>(() => {
    return [
      {
        header: () => 'Device Id',
        accessorFn: (row) => row,
        id: 'device.id',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return possession.device ? <Box>{possession.device.id}</Box> : <EmptyCell />;
        },
        size: 55,
      },
      {
        header: () => 'Serial number',
        accessorFn: (row) => row,
        id: 'device.id',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return possession.device ? <Box>{possession.device.serialNumber}</Box> : <EmptyCell />;
        },
        size: 55,
      },
      {
        header: () => 'Company name',
        accessorFn: (row) => row,
        id: 'companyId',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const companyName = getCompanyNameById(possession.companyId) ?? possession.possessionType;
          return companyName ? <Box>{companyName}</Box> : <EmptyCell />;
        },
        size: 150,
      },
      {
        header: () => 'Owned By',
        accessorFn: (row) => row,
        id: 'possessionId',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const ownedBy = getDeviceOwnerAsSuperadminByDevicePossession(possession, userNames, sites);
          return <Box>{ownedBy}</Box>;
        },
        size: 150,
      },
      {
        header: () => 'Device model',
        accessorFn: (row) => row,
        id: 'device.modelName',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const modelName = possession.device?.modelName;
          return modelName ? <Box>{modelName}</Box> : <EmptyCell />;
        },
        size: 125,
      },
      {
        header: () => 'Ownership',
        accessorFn: (row) => row,
        id: 'device.ownership',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const ownership = possession.device?.ownership;

          return ownership ? <Box>{ownership}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Price',
        accessorFn: (row) => row,
        id: 'device.price',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const price = possession.device?.price;

          return price ? <Box>£{price}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Contract Length',
        accessorFn: (row) => row,
        id: 'device.contractLength',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const contractLength = possession.device?.contractLength;

          return contractLength ? <Box>{contractLength} months</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Contract start',
        accessorFn: (row) => row,
        id: 'device.contractStart',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const contractStartDate = possession.device?.contractStartDate;

          return contractStartDate ? <Box>{contractStartDate}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'MDM',
        accessorFn: (row) => row,
        id: 'device.mdm',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const mdmResult = getMdmServer(possession.device, matchings);
          return <Box>{mdmResult}</Box>;
        },
        size: 75,
      },
    ];
  }, [getCompanyNameById, userNames, sites, matchings]);

  const refresh = useCallback(async () => {
    try {
      const devicePossessions = superAdminDevicesResponse?.devicePossessions?.items ?? [];
      setDevicePossessions(devicePossessions);
      setMatchings(superAdminDevicesResponse?.matchings ?? {});
      setTotalPages(superAdminDevicesResponse?.devicePossessions?.totalPages ?? 1);
      setTotalItems(superAdminDevicesResponse?.devicePossessions?.totalItems ?? 0);
      setDevicePossessionToBeEdited(devicePossessions.find((d) => d.id === devicePossessionToBeEdited?.id) ?? null);
    } catch (error) {
      showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, 'error');
    }
  }, [devicePossessionToBeEdited, showMessage, superAdminDevicesResponse]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await refresh();
      } catch (error) {
        showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    })();
  }, [refresh, showMessage]);

  return (
    <BackofficeRootStyle>
      <TopHeader title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Devices</Typography>} />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={loading} secondLevel>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', ...spacing.mb20, gap: 2 }}>
            <TableSearch
              query={searchInput ?? undefined}
              handleChange={(e) => {
                setSearchInput(e.target.value);
              }}
            />
          </Box>

          <CsvDownloader
            filename="all_devices"
            separator=","
            datas={async () => (formatDevicePossessionForCsv() as unknown) as Datas}
            disabled={devicePossessions.length === 0}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: spacing.g5,
                ...secondaryCTABtn,
                cursor: 'pointer',
                height: '20px',
              }}
            >
              <Export {...iconSize} />{' '}
              <Typography sx={{ ...themeFonts.caption, color: themeColors.DarkGrey }}>Export</Typography>
            </Box>
          </CsvDownloader>
        </Box>

        <BasicServerTable<DevicePossessionDto>
          rowData={tableData}
          columnData={columnData}
          rowClick={(row) => {
            setDevicePossessionToBeEdited(row.original);
            setIsEditDrawerOpen(true);
          }}
          pagination={pagination}
          setPagination={setPagination}
          totalPages={totalPages}
          totalItems={totalItems}
        />

        {devicePossessionToBeEdited && (
          <SuperAdminOverviewDeviceDrawer
            isOpen={isEditDrawerOpen}
            setIsOpen={setIsEditDrawerOpen}
            devicePossession={devicePossessionToBeEdited}
            setDevicePossession={setDevicePossessionToBeEdited}
            userNames={userNames}
            matchings={matchings}
            sites={sites}
            refresh={refresh}
          />
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
