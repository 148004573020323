import '@rc-component/color-picker/assets/index.css';
import { useMemo, useState } from 'react';

import { StrikethroughS } from '@mui/icons-material';
import CodeIcon from '@mui/icons-material/Code';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Popover, Stack } from '@mui/material';
import ColorPicker, { Color as RCColor } from '@rc-component/color-picker';
import { Editor } from '@tiptap/react';

import { ReactComponent as ArrowDownACIcon } from '@/images/fields/ArrowDown.svg';
import { ReactComponent as Bold } from '@/images/fields/Bold.svg';
import { ReactComponent as Italic } from '@/images/fields/Italic.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  fontFamilyMap,
  fontFamilyOptions,
  pixelOptions,
  tableOptions,
  lineSpacingOptions,
} from '@/v2/feature/templates/components/editor';
import { themeColors } from '@/v2/styles/colors.styles';
import { getSelectProps, iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface EditorButtonsProps {
  editor: Editor;
  setLink: () => void;
  color: string;
  setColor: (color: string) => void;
}

export const EditorButtons = ({ editor, setLink, color, setColor }: EditorButtonsProps) => {
  const [fontSize, setFontSize] = useState('12px');
  const [fontFamily, setFontFamily] = useState('arial');
  const [lineHeight, setLineHeight] = useState('1');
  const [showColorPicker, setShowColorPicker] = useState<HTMLDivElement | null>(null);

  const colorButton = useMemo(() => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'column' }} onClick={(e) => setShowColorPicker(e.currentTarget)}>
        <span style={{ color: 'black', fontFamily: 'arial' }}>A</span>
        <div style={{ height: 5, width: 30, backgroundColor: color }} />
      </Box>
    );
  }, [color]);

  const manageTable = (value: string) => {
    switch (value) {
      case 'insert_table':
        editor?.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
        break;
      case 'add_column':
        editor?.chain().focus().addColumnAfter().run();
        break;
      case 'delete_column':
        editor?.chain().focus().deleteColumn().run();
        break;
      case 'add_row':
        editor?.chain().focus().addRowBefore().run();
        break;
      case 'delete_row':
        editor?.chain().focus().deleteRow().run();
        break;
      case 'merge_cells':
        editor?.chain().focus().mergeCells().run();
        break;
      case 'split_cell':
        editor?.chain().focus().splitCell().run();
        break;
    }
  };

  const setColorHandler = (color: RCColor) => {
    setColor(color.toHexString());
    editor?.chain().focus().setColor(color.toHexString()).run();
  };

  return (
    <>
      <Box
        style={{
          display: 'flex',
          gap: spacing.g3,
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: '100%',
          paddingTop: '10px',
          paddingBottom: '10px',
          borderBottom: `1px solid ${themeColors.Background}`,
        }}
      >
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <UndoIcon />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <RedoIcon />
        </IconButton>
        <IconButton sizeVariant="small" colorVariant="secondary" type="button">
          {colorButton}
        </IconButton>
        <Box sx={{ flex: '0 0 75px', boxSizing: 'border-box', p: 0, m: 0 }}>
          <SelectComponent
            value={fontFamily}
            className="small-icon-button"
            onChange={(e) => {
              const fontFamily = e.target.value;
              setFontFamily(fontFamily);
              //@ts-ignore
              editor.chain().focus().setFontFamily(fontFamilyMap?.[fontFamily]).run();
            }}
            variant="standard"
            size="small"
            options={fontFamilyOptions}
            name="fontSize"
            maxLabelDisplayCharacters={5}
            sx={{
              '&.MuiTextField-root': {
                '& div.MuiInputBase-root.MuiInput-root': {
                  height: '32px',
                  minHeight: '32px',
                },
              },
            }}
            SelectProps={{
              ...getSelectProps,
              IconComponent: () => <ArrowDownACIcon style={{ position: 'static' }} />,
            }}
          />
        </Box>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <Bold {...iconSize} />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <Italic {...iconSize} />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          className={editor.isActive('strike') ? 'is-active' : ''}
        >
          <StrikethroughS {...iconSize} />
        </IconButton>
        <Box sx={{ flex: '0 0 75px', boxSizing: 'border-box', p: 0, m: 0 }}>
          <SelectComponent
            value={fontSize}
            className="small-icon-button"
            onChange={(e) => {
              const fontSize = e.target.value;
              setFontSize(fontSize);
              editor.chain().focus().setFontSize(fontSize).run();
            }}
            variant="standard"
            size="small"
            options={pixelOptions}
            SelectProps={{
              ...getSelectProps,
              IconComponent: () => <ArrowDownACIcon style={{ position: 'static' }} />,
            }}
            sx={{
              '&.MuiTextField-root': {
                '& div.MuiInputBase-root.MuiInput-root': {
                  height: '32px',
                  minHeight: '32px',
                },
              },
            }}
            name="fontSize"
          />
        </Box>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          className={editor.isActive('code') ? 'is-active' : ''}
        >
          <CodeIcon {...iconSize} />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={setLink}
          className={editor.isActive('code') ? 'is-active' : ''}
        >
          <InsertLinkIcon {...iconSize} />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <FormatListBulletedIcon {...iconSize} />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active' : ''}
        >
          <FormatListNumberedIcon {...iconSize} />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
        >
          <FormatAlignLeftIcon {...iconSize} />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
        >
          <FormatAlignCenterIcon {...iconSize} />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
        >
          <FormatAlignRightIcon {...iconSize} />
        </IconButton>
        <IconButton
          sizeVariant="small"
          colorVariant="secondary"
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('justify').run()}
          className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
        >
          <FormatAlignJustifyIcon {...iconSize} />
        </IconButton>
        <Box sx={{ flex: '0 0 60px', boxSizing: 'border-box', p: 0, m: 0 }}>
          <SelectComponent
            value={lineHeight}
            className="small-icon-button"
            placeholderOverride={
              <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
                <FormatLineSpacingIcon style={{ position: 'static', alignSelf: 'center', fontSize: 24 }} />
              </Stack>
            }
            onChange={(e) => {
              const value = e.target.value;
              setLineHeight(value);
              editor?.commands.setLineHeight(value);
            }}
            variant="standard"
            size="small"
            options={lineSpacingOptions}
            name="lineHeight"
            sx={{
              '&.MuiTextField-root': {
                '& div.MuiInputBase-root.MuiInput-root': {
                  height: '32px',
                  minHeight: '32px',
                },
              },
            }}
            SelectProps={{
              ...getSelectProps,
              IconComponent: () => <ArrowDownACIcon style={{ position: 'static' }} />,
            }}
          />
        </Box>
        <Box sx={{ flex: '0 0 75px', boxSizing: 'border-box', p: 0, m: 0 }}>
          <SelectComponent
            placeholderOverride={
              <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
                <Typography variant="title4">Table</Typography>
              </Stack>
            }
            className="small-icon-button"
            value={''}
            onChange={(e) => {
              const value = e.target.value;
              manageTable(value);
            }}
            variant="standard"
            size="small"
            options={tableOptions}
            name="fontSize"
            sx={{
              '&.MuiTextField-root': {
                '& div.MuiInputBase-root.MuiInput-root': {
                  height: '32px',
                  minHeight: '32px',
                },
              },
            }}
            SelectProps={{
              ...getSelectProps,
              IconComponent: () => <ArrowDownACIcon style={{ position: 'static' }} />,
            }}
          />
        </Box>
      </Box>
      {showColorPicker && (
        <Popover
          open={true}
          anchorEl={showColorPicker}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={() => setShowColorPicker(null)}
        >
          <ColorPicker
            value={color}
            defaultValue={'black'}
            onChangeComplete={(color: RCColor) => setColorHandler(color)}
          />
        </Popover>
      )}
    </>
  );
};
