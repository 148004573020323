import React, { useState } from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { KeyedMutator } from 'swr';

import { GeneralSettingsDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { CompanySettingsDetailsComponent } from '@/v2/feature/company/company-settings/features/components/company-settings/company-details/company-settings-details.component';

interface CompanySettingsGeneralProps {
  readonly generalSettings: GeneralSettingsDto | null;
  readonly refreshSettings?: KeyedMutator<GeneralSettingsDto>;
}

export const CompanySettingsGeneral = ({
  generalSettings,
  refreshSettings,
}: CompanySettingsGeneralProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <SettingsSectionContent
      title={polyglot.t('CompanySettingsHeader.company')}
      contentWidth="100%"
      onEdit={() => setEditMode(true)}
    >
      <CompanySettingsDetailsComponent
        generalSettings={generalSettings}
        refreshSettings={refreshSettings}
        setEditMode={setEditMode}
        editMode={editMode}
      />
    </SettingsSectionContent>
  );
};
