import { CompanyPayroll, PayrollPayScheduleDto, TaxYear } from '@shared/modules/payroll/payroll.types';
import { HMRCSettings } from '@v2/feature/payroll/features/payroll-uk/payroll-uk.interface';
import { BankPaymentInstructionDto } from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface';
import {
  CSVResponse,
  ExternalFpsDto,
  ExternalPayRunDto,
  ExternalPayRunEntryDto,
  ExternalPayRunPaymentDto,
  StaffologyPaySchedule,
} from '@v2/feature/payroll/payroll-external.dto';
import {
  CreateExternalPaySchedulePayload,
  ExternalFPSLateReason,
  PayPeriod,
  UpdateExternalPaySchedulePayload,
} from '@v2/feature/payroll/payroll-external.interface';
import {
  PayrollHmrcLiabilityDto,
  PayRunDto,
  PayrunEntryIncomeUpdateDto,
  PayrunFPSDto,
  PayrunsStatsDto,
} from '@v2/feature/payroll/payroll.dto';
import { CompanyPayrollType, PayrollHMRCSetting } from '@v2/feature/payroll/payroll.interface';
import axios from 'axios';

export class PayrollExternalApi {
  static async getExternalPayRun(
    payrollId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number
  ): Promise<ExternalPayRunDto> {
    return (
      await axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/external?payrollId=${payrollId}`)
    ).data;
  }

  static async getExternalPayRunPayments(
    payrollId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number | string
  ): Promise<ExternalPayRunPaymentDto[]> {
    return (
      await axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/payments?payrollId=${payrollId}`)
    ).data;
  }

  static async getExternalPayRunEntries(
    payrollId: number,
    taxYear: string,
    payPeriod: PayPeriod,
    periodNumber: number
  ): Promise<ExternalPayRunEntryDto[]> {
    return (
      await axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/entries?payrollId=${payrollId}`)
    ).data;
  }

  static async getAnalysisSummaryReport(taxYear: string, payPeriod: PayPeriod, period: number): Promise<CSVResponse> {
    return (await axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}/summary-report`)).data;
  }

  static async getExternalFPS(
    taxYear: string,
    payPeriod: string,
    periodNumber: number | string
  ): Promise<ExternalFpsDto[]> {
    return (await axios.get(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/fps`)).data;
  }

  static async getExternalFPSReportByPeriod(
    payrollId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number | string,
    contentType: string
  ): Promise<{ type: string; content: string }> {
    return (
      await axios.get(
        `/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/fps/report?payrollId=${payrollId}&contentType=${contentType}`
      )
    ).data;
  }

  static async finalisePayRun(
    payrollId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number,
    paymentDate: string
  ): Promise<void> {
    await axios.put(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/finalise?payrollId=${payrollId}`, {
      paymentDate,
    });
  }

  static async createPayRun(payrollId: number, taxYear: string, payPeriod: string): Promise<PayRunDto> {
    return (await axios.post(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}?payrollId=${payrollId}`)).data;
  }

  static async createNewPaySchedule(
    payrollId: number,
    taxYear: TaxYear,
    payPeriod: PayPeriod,
    payload: CreateExternalPaySchedulePayload
  ): Promise<PayrollPayScheduleDto> {
    return (
      await axios.post(`/apiv2/company/payroll/payschedule/${taxYear}/${payPeriod}?payrollId=${payrollId}`, payload)
    ).data;
  }

  static async updatePaySchedule(
    payrollId: number,
    taxYear: TaxYear,
    payPeriod: PayPeriod,
    ordinal: number,
    payload: UpdateExternalPaySchedulePayload
  ): Promise<PayrollPayScheduleDto> {
    return (
      await axios.patch(
        `/apiv2/company/payroll/payschedule/${taxYear}/${payPeriod}?payrollId=${payrollId}&ordinal=${ordinal}`,
        payload
      )
    ).data;
  }

  static async getPaySchedules(payrollId: number, taxYear: string): Promise<StaffologyPaySchedule[]> {
    return (await axios.post(`/apiv2/company/payroll/payschedule/${taxYear}?payrollId=${payrollId}`)).data;
  }

  static async getPaySchedulesForAllTaxYears(payrollId: number): Promise<StaffologyPaySchedule[]> {
    return (await axios.get(`/apiv2/company/payroll/payschedules?payrollId=${payrollId}`)).data;
  }

  static async getPaySchedulesAsSuperadmin(
    companyId: number,
    payrollId: number,
    taxYear: string
  ): Promise<StaffologyPaySchedule[]> {
    return (
      await axios.get(`/apiv2/company/payroll/superadmin/payschedule/${companyId}/${taxYear}?payrollId=${payrollId}`)
    ).data;
  }

  static async moveUsersToPaySchedule(
    companyId: number,
    payrollId: number,
    payPeriod: PayPeriod,
    ordinal: number
  ): Promise<{ count: number }> {
    return (
      await axios.post(
        `/apiv2/company/payroll/superadmin/payschedule/migrate/${companyId}/${payPeriod}/${ordinal}?payrollId=${payrollId}`
      )
    ).data;
  }

  static async getPayrunsStatsForTaxYear(payrollId: number, taxYear: string): Promise<PayrunsStatsDto> {
    return (await axios.get(`/apiv2/payroll/payruns/stats/${taxYear}?payrollId=${payrollId}`)).data;
  }

  static async fetchExternalFPSStatus(payRunId: number): Promise<ExternalFpsDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payRunId}/fps-submission/external`)).data;
  }

  static async submitFPS(payRunId: number, lateReason: ExternalFPSLateReason | undefined): Promise<ExternalFpsDto> {
    return (await axios.post(`/apiv2/payroll/payruns/${payRunId}/fps/submit`, { lateReason })).data;
  }

  static async markFPSAsSent(payRunId: number): Promise<PayrunFPSDto> {
    return (await axios.post(`/apiv2/payroll/payruns/${payRunId}/fps/markassent`)).data;
  }

  static async createCompanyPayroll(entityId: number, payrollType: CompanyPayrollType): Promise<CompanyPayroll> {
    return (await axios.post('/apiv2/company/payroll', { entityId, payrollType })).data;
  }

  static async updatePayroll(payrollId: number, payroll: PayrollHMRCSetting): Promise<HMRCSettings> {
    return (await axios.patch(`/apiv2/company/payroll?payrollId=${payrollId}`, payroll)).data;
  }

  static async getHmrcLiabilities(payrollId: number): Promise<PayrollHmrcLiabilityDto[]> {
    return (await axios.get(`/apiv2/company/payroll/hmrcpayment/liabilities?payrollId=${payrollId}`)).data;
  }

  static async getHmrcLiabilityByPeriod(
    payrollId: number,
    taxYear: string,
    period: number
  ): Promise<PayrollHmrcLiabilityDto> {
    return (
      await axios.get(`/apiv2/company/payroll/hmrcpayment/liabilities/${taxYear}/${period}?payrollId=${payrollId}`)
    ).data;
  }

  static async downloadP30Url(payrollId: number, taxYear: string, periodEnding: string): Promise<string> {
    return `/apiv2/company/payroll/reports/p30/${taxYear}/${periodEnding}?payrollId=${payrollId}`;
  }

  static async downloadP32Url(payrollId: number, taxYear: string): Promise<string> {
    return `/apiv2/company/payroll/reports/p32/${taxYear}?payrollId=${payrollId}`;
  }

  static async downloadPayslipsUrl(
    payrollId: number,
    taxYear: string,
    payPeriod: PayPeriod,
    period: number,
    ordinal: number
  ): Promise<string> {
    return `/apiv2/company/payroll/reports/payslips/${taxYear}/${payPeriod}/${period}?ordinal=${ordinal}&payrollId=${payrollId}`;
  }

  static async getBankPaymentInstructionsForPayrun(
    payRunId: number,
    contentType: string
  ): Promise<BankPaymentInstructionDto> {
    return (await axios.get(`/apiv2/payroll/payruns/${payRunId}/payments/instructions?contentType=${contentType}`))
      .data;
  }

  static async getBankPaymentInstructionsForPayrunByPeriod(
    taxYear: string,
    payPeriod: PayPeriod,
    period: number,
    contentType: string
  ): Promise<BankPaymentInstructionDto> {
    return (
      await axios.get(
        `/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${period}/payments/instructions?contentType=${contentType}`
      )
    ).data;
  }

  static async updateIncome(
    payrollId: number,
    taxYear: string,
    payPeriod: string,
    periodNumber: number | string,
    income: PayrunEntryIncomeUpdateDto[]
  ) {
    await axios.patch(`/apiv2/payroll/payruns/${taxYear}/${payPeriod}/${periodNumber}/income?payrollId=${payrollId}`, {
      income,
    });
  }

  // TODO: @payroll-now - is this needed?
  static async getUpcomingPayrollChanges(): Promise<ScheduledUserPayrollChange[]> {
    return (await axios.get('/apiv2/company/payroll/dps/notices/upcoming')).data;
  }
}
