import React from 'react';

import { Box, Skeleton, Typography } from '@mui/material';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  RowData,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ReactComponent as Desc } from '@/images/side-bar-icons/ArrowDownSmall.svg';
import { ReactComponent as Asc } from '@/images/side-bar-icons/ArrowUpSmall.svg';
import { ReactComponent as SortIcon } from '@/images/side-bar-icons/SortIcon.svg';
import { PaginationDetail } from '@/v2/components/table/pagination-detail.component';
import '@/v2/components/table/styles/basic-table.scss';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import {
  iconSize,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableHeadRow,
  TableRow,
} from '@/v2/styles/table.styles';

export const DEFAULT_PAGE_SIZE = 20;
export const BasicServerTable = <TData extends RowData>({
  rowData,
  columnData,
  sorting,
  setSorting,
  pagination,
  setPagination,
  loading,
  totalPages,
  rowClick,
  customRowStyle,
  hiddenColumns,
  totalItems,
  stickyHeader,
}: {
  rowData: TData[];
  columnData: ColumnDef<TData, any>[];
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  sorting?: SortingState;
  setSorting?: React.Dispatch<React.SetStateAction<SortingState>>;
  loading?: boolean;
  totalPages?: number; // totalPages will exist if paginating from backend
  rowClick?: (row: Row<TData>) => void;
  customRowStyle?: (row: Row<TData>) => React.CSSProperties;
  hiddenColumns?: string[];
  totalItems?: number;
  stickyHeader?: boolean;
}): JSX.Element => {
  const { polyglot } = usePolyglot();

  const paginatedData = rowData.slice(pagination.pageIndex * 10, pagination.pageIndex * 10 + 10);
  //todo: this has to be moved soon too backend for pagination.
  const table = useReactTable({
    data: totalPages ? rowData : paginatedData,
    columns: columnData.filter((column) => !column.id || !hiddenColumns?.includes(column.id)),
    pageCount: totalPages ? totalPages + 1 : Math.ceil(rowData.length / pagination.pageSize),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    debugTable: process.env.REACT_APP_ENV !== 'production',
    manualPagination: true,
  });

  return (
    <>
      <div
        style={
          stickyHeader
            ? {
                overflowX: 'auto',
                width: '100%',
                height: 'calc(100vh - 200px)',
                overflowY: 'auto',
                position: 'relative',
              }
            : { overflowX: 'auto', maxHeight: 'inherit', width: '100%' }
        }
      >
        <Table>
          <TableHead style={stickyHeader ? { position: 'sticky', top: 0, zIndex: 3 } : {}}>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <TableHeadRow key={headerGroup.id}>
                {headerGroup.headers.map((header, idx, arr) => {
                  const cellStyle: React.CSSProperties = {};
                  return (
                    <TableHeading
                      key={header.id}
                      maxWidth={header.column.columnDef.maxSize || 100}
                      minWidth={header.column.columnDef.minSize}
                      style={{ ...cellStyle }}
                      fixedFirstColumn={idx === 0}
                      fixedLastColumn={idx === arr.length - 1}
                      className="tableHeading"
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            style: {
                              display: 'flex',
                              alignItems: 'center',
                              width: header.column.getSize(),
                            },
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          <span
                            style={{
                              color: themeColors.Grey,
                              ...themeFonts.caption,
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </span>
                          <div className="sortIcon">
                            {(header.column.columnDef.enableSorting &&
                              {
                                asc: <Asc {...iconSize} style={{ color: themeColors.DarkGrey }} />,
                                desc: <Desc {...iconSize} style={{ color: themeColors.DarkGrey }} />,
                              }[header.column.getIsSorted() as string]) ?? <SortIcon {...iconSize} />}
                          </div>
                        </div>
                      )}
                    </TableHeading>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHead>
          {loading && (
            <TableBody>
              {[1, 2, 3, 4].map((row) => (
                <TableRow key={row}>
                  {table.getAllColumns().map((cell, i) => (
                    <TableData key={i} maxWidth={150} minWidth={100}>
                      <Skeleton variant="text" sx={{ backgroundColor: themeColors.Background }} />
                    </TableData>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
          {(totalPages ? rowData : paginatedData).length > 0 && !loading && (
            <TableBody>
              {table.getRowModel()?.rows.map((row) => (
                <TableRow
                  key={row.id}
                  onClick={() => rowClick && rowClick(row)}
                  style={{ cursor: rowClick ? 'pointer' : 'default' }}
                >
                  {row.getVisibleCells().map((cell, idx, arr) => {
                    const cellStyle: React.CSSProperties = {};
                    return (
                      <TableData
                        key={cell.id}
                        maxWidth={cell.column.columnDef.maxSize || 100}
                        minWidth={cell.column.columnDef.minSize}
                        cellStyle={{ ...cellStyle, ...(!!customRowStyle && customRowStyle(row)) }}
                        meta={cell.column?.columnDef?.meta as string}
                        fixedFirstColumn={idx === 0}
                        fixedLastColumn={idx === arr.length - 1}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableData>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {(totalPages ? rowData : paginatedData).length < 1 && !loading && (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              marginTop: spacing.m10,
              height: '50px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ ...themeFonts.caption, color: themeColors.Grey }}>
              {polyglot.has('BasicServerTable.countNotFindAnything')
                ? polyglot.t('BasicServerTable.countNotFindAnything')
                : "Sorry, we couldn't find anything..."}
            </Typography>
          </Box>
        )}
      </div>
      <PaginationDetail
        totalPageCount={totalPages ? totalPages : table.getPageCount()}
        totalRecordCount={totalItems ?? 0}
        current={table.getState().pagination.pageIndex}
        onNextAction={() => {
          table.nextPage();
        }}
        nextDisabled={pagination.pageIndex === totalPages}
        onPreviousAction={() => {
          table.previousPage();
        }}
        previousDisabled={pagination.pageIndex === 1}
        paginationState={table.getState().pagination}
        setPaginationState={(pageSize: number, pageIndex: number) => {
          table.setPagination({ pageSize, pageIndex });
        }}
        handleState={(page: number) => {
          let pageState = page > 0 ? Number(page) : 1;
          if (pageState < 1) pageState = 1;
          if (totalPages && pageState > totalPages) pageState = totalPages;
          table.setPageIndex(pageState);
        }}
      />
    </>
  );
};
