import './document-editor.component.css';
import '@rc-component/color-picker/assets/index.css';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, MenuItem, Popover } from '@mui/material';
import { default as Document } from '@tiptap/extension-document';
import { default as Dropcursor } from '@tiptap/extension-dropcursor';
import { default as FontFamily } from '@tiptap/extension-font-family';
import { default as Gapcursor } from '@tiptap/extension-gapcursor';
import { default as Link } from '@tiptap/extension-link';
import { default as Table } from '@tiptap/extension-table';
import { default as TableCell } from '@tiptap/extension-table-cell';
import { default as TableHeader } from '@tiptap/extension-table-header';
import { default as TableRow } from '@tiptap/extension-table-row';
import { default as Text } from '@tiptap/extension-text';
import { default as TextAlign } from '@tiptap/extension-text-align';
import { default as TextStyle } from '@tiptap/extension-text-style';
import { Node } from '@tiptap/pm/model';
import { EditorView } from '@tiptap/pm/view';
import { EditorContent, useEditor } from '@tiptap/react';
import { default as StarterKit } from '@tiptap/starter-kit';
import FontSizeExtension from 'tiptap-extension-font-size';

import useMessage from '@/hooks/notification.hook';
import { EditorButtons } from '@/v2/components/forms/document-editor/components/editor-buttons/editor-buttons.component';
import { ImageDimensionsEditor } from '@/v2/components/forms/document-editor/components/image-dimensions-editor/image-dimensions-editor.component';
import { SideBar } from '@/v2/components/forms/document-editor/components/side-bar/side-bar.components';
import { MouseEventExtended, MouseEventTarget } from '@/v2/components/forms/document-editor/document-editor.util';
import { ColorExtension } from '@/v2/components/forms/document-editor/tiptap/extensions/color.extension';
import { ClickExtension } from '@/v2/components/forms/document-editor/tiptap/extensions/on-click.extension';
import { CopyExtension } from '@/v2/components/forms/document-editor/tiptap/extensions/on-copy.extension';
import { HandleDropExtension } from '@/v2/components/forms/document-editor/tiptap/extensions/on-drop.extension';
import { HoverExtension } from '@/v2/components/forms/document-editor/tiptap/extensions/on-hover.extension';
import {
  parseFontFamily,
  PasteExtension,
} from '@/v2/components/forms/document-editor/tiptap/extensions/on-paste.extension';
import { LineHeightMark } from '@/v2/components/forms/document-editor/tiptap/marks/line-height.mark';
import { SmartFieldMark } from '@/v2/components/forms/document-editor/tiptap/marks/smart-field.mark';
import { CustomImage } from '@/v2/components/forms/document-editor/tiptap/nodes/image.node';
import { CustomParagraphNode } from '@/v2/components/forms/document-editor/tiptap/nodes/paragraph.node';
import { SmartFieldNode, cleanHTML } from '@/v2/components/forms/document-editor/tiptap/nodes/smart-field.node';
import { themeColors } from '@/v2/styles/colors.styles';

interface DocumentEditorProps {
  onChange: (InnerHTML: string) => void;
  editable?: boolean | undefined;
  content: string;
}

export const DocumentEditor = ({ onChange, content, editable = true }: DocumentEditorProps) => {
  const [color, setColor] = useState('black');
  const [showMessage] = useMessage();
  const [anchorEl, setAnchorEl] = useState<
    | undefined
    | {
        top: number;
        left: number;
        option: 'link' | 'image';
        text?: string;
        target?: MouseEventTarget;
        height?: number;
        width?: number;
        pos?: number;
      }
  >();
  const [showImageModal, setShowImageModal] = useState(false);

  const handleHover = (view: EditorView, event: MouseEventExtended) => {
    if (event?.target?.localName === 'a' || event.target?.parentElement?.localName === 'a') {
      setAnchorEl({ top: event?.y, left: event?.x, option: 'link', target: event.target });
    }
  };

  const handleClick = (view: EditorView, event: MouseEventExtended) => {
    if (event?.target?.localName === 'img') {
      setAnchorEl({
        top: event?.clientY,
        left: event?.clientX,
        option: 'image',
        height: event.target.height,
        width: event.target.width,
        text: event.target.innerText,
        target: event.target,
      });
    }
  };

  const showErrorMessage = (message: string) => {
    showMessage(message, 'error');
  };

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: 'ProseMirror-document tiptap-document',
      },
    },
    extensions: [
      Document,
      CustomParagraphNode,
      Text,
      TextStyle,
      ColorExtension,

      FontFamily.configure({
        types: ['textStyle'],
      }),
      Gapcursor.configure({
        types: ['span'],
      }),
      Dropcursor,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      CustomImage.configure({
        inline: true,
        allowBase64: true,
      }),
      FontSizeExtension,
      LineHeightMark,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Link.configure({
        openOnClick: false,
        autolink: true,
        protocols: ['https'],
      }),
      HandleDropExtension.configure({
        handleError: showErrorMessage,
      }),
      PasteExtension.configure({
        handleError: showErrorMessage,
      }),
      HoverExtension.configure({
        onHover: handleHover,
      }),
      ClickExtension.configure({
        onClick: handleClick,
      }),
      CopyExtension,
      SmartFieldMark,
      SmartFieldNode,
    ],
    editable: editable,
    content: parseFontFamily(content),
    onUpdate(props) {
      onChange(cleanHTML(props.editor.getHTML()));
    },
    onSelectionUpdate({ editor }) {
      const { from, to } = editor.state.selection;
      editor.state.doc.nodesBetween(from, to, (node) => {
        recursivelyLookForColor(node);
      });
    },
  });

  const setLink = useCallback(() => {
    if (!editor) {
      return;
    }
    const previousUrl = editor?.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    if (url === null) {
      return;
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().setColor('black').run();

      return;
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).setColor('blue').run();
  }, [editor]);

  const handleLinkOption = (option: string) => {
    switch (option) {
      case 'edit':
        setLink();
        break;
      case 'remove':
        editor?.chain().focus().extendMarkRange('link').unsetLink().setColor('black').run();
        break;
    }
    setAnchorEl(undefined);
  };

  const handleImage = (option: string) => {
    switch (option) {
      case 'edit':
        setShowImageModal(true);
        break;
      case 'remove':
        editor?.chain().focus().deleteSelection().run();
        setAnchorEl(undefined);
        break;
    }
  };

  const handlePopover = (option: string) => {
    switch (anchorEl?.option) {
      case 'link':
        handleLinkOption(option);
        break;
      case 'image':
        handleImage(option);
        break;
    }
  };

  const recursivelyLookForColor = (node: Node) => {
    if (node.marks?.length) {
      node.marks.forEach((mark) => {
        if (mark.type.name === 'textStyle' && mark.attrs.color) {
          setColor(mark.attrs.color);
          return;
        }
      });
    } else if (node.text) {
      setColor('black');
      return;
    }

    if (node.content && node.content.childCount > 0) {
      node.content.forEach((child) => {
        recursivelyLookForColor(child);
      });
    }
  };

  function setDefaultColor(htmlString: string) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    function wrapTextNodes(node: HTMLDivElement | ChildNode) {
      node.childNodes.forEach((child) => {
        //need to apply a default color to all text nodes and wrap then in a span field as this is what tiptap does by default
        if (child.nodeType === 3 && child?.nodeValue?.trim() !== '') {
          const span = document.createElement('span');
          span.style.color = 'black';
          span.textContent = child.nodeValue;
          child.replaceWith(span);
        } else if (child.nodeType === 1) {
          wrapTextNodes(child);
        } else {
          //setting paragraph to default color of black
          const span = document.createElement('span');
          span.style.color = 'black';
          span.textContent = child.nodeValue;
          child.replaceWith(span);
        }
      });
    }
    wrapTextNodes(tempDiv);
    return tempDiv.innerHTML;
  }

  useEffect(() => {
    if (!editor) {
      return;
    }
    if (!content) {
      return;
    }
    editor
      .chain()
      .focus()
      .setContent(setDefaultColor(parseFontFamily(content)), false)
      .setColor('black')
      .setTextSelection(0)
      .run();
  }, [content, editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    editor.setEditable(editable);
    setAnchorEl(undefined);
  }, [editor, editable]);

  const editorButtons = useMemo(() => {
    if (!editor) {
      return null;
    }
    return <EditorButtons editor={editor} setLink={setLink} color={color} setColor={setColor} />;
  }, [editor, color, setLink, setColor]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <Box className="document-container">
        {editable && <SideBar />}
        <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', marginLeft: editable ? '300px' : 0 }}>
          <Box>{editable && editorButtons}</Box>
          <Box
            style={{
              display: 'flex',
              backgroundColor: themeColors.lightGrey,
              justifyContent: 'center',
              minWidth: '100%',
              overflowY: 'scroll',
            }}
          >
            <Box
              sx={{
                mt: '1%',
                justifyContent: 'center',
                alignContent: 'center',
              }}
              className="document-editor-container"
              onClick={() => {
                if (!editor.isFocused) {
                  editor.view.dom.focus();
                }
              }}
            >
              <EditorContent editor={editor} />
            </Box>
          </Box>
        </Box>
      </Box>
      {anchorEl !== undefined && editable && !showImageModal && (
        <>
          <Popover
            id="basic-menu"
            onClose={() => setAnchorEl(undefined)}
            anchorPosition={anchorEl}
            open={true}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{ minWidth: '36px' }}
            style={{
              top: anchorEl?.top,
              left: anchorEl?.left,
            }}
            disableRestoreFocus
          >
            <MenuItem onClick={() => handlePopover('edit')}>
              {anchorEl.text ? `Edit: ${anchorEl.text}` : 'Edit'}
            </MenuItem>
            <MenuItem onClick={() => handlePopover('remove')}>
              {anchorEl.text ? `Remove: ${anchorEl.text}` : 'Remove'}
            </MenuItem>
          </Popover>
        </>
      )}
      <ImageDimensionsEditor
        editor={editor}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setShowImageModal={setShowImageModal}
        showImageModal={showImageModal}
      />
    </>
  );
};
