import React, { Suspense, useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { Typography } from '@v2/components/typography/typography.component';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceDto, DeviceOrderDto } from '@v2/feature/device/device.dto';
import { SiteDto } from '@v2/feature/site/site.dto';
import { SuperAdminDeviceOrdersTable } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-device-orders-table.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { BackofficeRootStyle } from '@v2/styles/root.styles';
import { keyBy } from 'lodash';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { themeColors } from '@/v2/styles/colors.styles';

interface SuperAdminDeviceOrdersPageProps {
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly pageConfig: PageConfig;
}

export const SuperAdminDeviceOrdersPage = ({
  users,
  companies,
  pageConfig,
}: SuperAdminDeviceOrdersPageProps): React.JSX.Element => {
  const [stockDevices, setStockDevices] = useState<readonly DeviceDto[]>([]);
  const [deviceOrders, setDeviceOrders] = useState<readonly DeviceOrderDto[]>([]);
  const [sites, setSitesById] = useState<{ [id: number]: SiteDto }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showMessage] = useMessage();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [stockDevicesData, deviceOrdersData, sitesData] = await Promise.all([
        DeviceAPI.getStockDevicesAsSuperadmin(),
        DeviceAPI.getDeviceOrdersAsSuperadmin(),
        SiteAPI.listSitesAsSuperAdmin(),
      ]);
      setStockDevices(stockDevicesData);
      setDeviceOrders(deviceOrdersData);
      setSitesById(keyBy(sitesData, 'id'));
    } catch (error) {
      showMessage(`Could not retrieve data. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <BackofficeRootStyle>
      <TopHeader title="Device Orders" />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={loading} secondLevel>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <SkeletonLoader
              variant="rectangular"
              width="83%"
              height="100%"
              sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
            />
          </Box>
        ) : (
          <>
            {deviceOrders.length > 0 ? (
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="83%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <SuperAdminDeviceOrdersTable
                  deviceOrders={deviceOrders}
                  users={users}
                  companies={companies}
                  refresh={fetchData}
                  devices={stockDevices}
                  sites={sites}
                />
              </Suspense>
            ) : (
              <Typography variant="caption">No device orders.</Typography>
            )}
          </>
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
