import { ReactNode, useCallback, useMemo, useState } from 'react';

import { IconButton, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { CompanyEndpoints } from '@/api-client/company.api';
import { ReactComponent as EditIcon } from '@/images/side-bar-icons/Edit.svg';
import { ReactComponent as DeleteIcon } from '@/images/side-bar-icons/Trash.svg';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CompanyAddress, CompanyUnitDto } from '@/v2/feature/company/company-settings/features/company-settings.dto';
import { CompanyEntityDrawerPage } from '@/v2/feature/company/company-settings/features/components/company-settings/entity-details/company-entity-drawer.component';
import { DeleteCompanyEntityDrawer } from '@/v2/feature/company/company-settings/features/components/company-settings/entity-details/delete-company-entity-drawer.component';
import { getCountryNameForCode } from '@/v2/feature/payments/payments.util';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { tableIconButtonSx } from '@/v2/styles/icon-button.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { actionIconSize } from '@/v2/styles/table.styles';

export const CompanySettingsEntityTable = () => {
  const { polyglot } = usePolyglot();
  const {
    data: generalSettings,
    isValidating: loadingGeneralSettings,
    mutate: refreshEntities,
  } = useApiClient(CompanyEndpoints.getGeneralSettings(), { suspense: false });
  const [drawer, setDrawer] = useState<{ entity: CompanyUnitDto | null; isDeleting?: boolean }>();

  // initially sort by name
  const sortedEntities = useMemo(
    () =>
      (generalSettings?.entities || []).sort((a, b) =>
        a.legalName.localeCompare(b.legalName, undefined, { sensitivity: 'base' })
      ),
    [generalSettings]
  );

  const columns = useMemo<ColumnDef<CompanyUnitDto, CompanyUnitDto>[]>(() => {
    const formatAddress = ({ addressLine1, addressLine2, city, postCode, countryCode, region }: CompanyAddress) =>
      [addressLine1, addressLine2, city, postCode, countryCode, region].filter(Boolean).join(' ');
    const NoWrap = ({ children }: { children?: ReactNode }) => (
      <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{children}</div>
    );
    return [
      {
        id: 'legal-name',
        header: () => polyglot.t('CompanySettingsEntityTable.legalName'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.legalName, { sensitivity: 'base' }),
        cell: ({ row }) => <NoWrap>{row.original.legalName}</NoWrap>,
      },
      {
        id: 'country',
        header: () => polyglot.t('CompanySettingsEntityTable.country'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => getCountryNameForCode(item.address?.countryCode), {
            sensitivity: 'base',
          }),
        cell: ({ row }) => <NoWrap>{getCountryNameForCode(row.original.address?.countryCode)}</NoWrap>,
      },
      {
        id: 'address',
        header: () => polyglot.t('CompanySettingsEntityTable.address'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => item.address && formatAddress(item.address), { sensitivity: 'base' }),
        cell: ({ row }) => <NoWrap>{row.original.address && formatAddress(row.original.address)}</NoWrap>,
      },
      {
        id: 'company-id',
        header: () => polyglot.t('CompanySettingsEntityTable.companyid'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.nationalId, { sensitivity: 'base' }),
        cell: ({ row }) => <NoWrap>{row.original.nationalId}</NoWrap>,
      },
      {
        id: 'vat-no',
        header: () => polyglot.t('CompanySettingsEntityTable.vat'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.taxId, { sensitivity: 'base' }),
        cell: ({ row }) => <NoWrap>{row.original.taxId}</NoWrap>,
      },
      {
        id: 'currency',
        header: () => polyglot.t('General.currency'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.currency),
        cell: ({ row }) => <NoWrap>{row.original.currency}</NoWrap>,
      },
      {
        id: 'is-default-billing-entity',
        header: () => polyglot.t('CompanySettingsEntityTable.billingEntity'),
        accessorFn: (row) => row,
        enableSorting: true,
        cell: ({ row }) => <NoWrap>{row.original.isDefaultBillingEntity ? 'Yes' : 'No'}</NoWrap>,
      },
      {
        id: 'actions',
        header: () => '',
        accessorFn: (row) => row,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Stack sx={{ flexFlow: 'row', justifyContent: 'flex-end', gap: spacing.g5 }}>
              <IconButton
                title={polyglot.t('CompanySettingsEntityTable.deleteCompanyEntity')}
                onClick={() => setDrawer({ entity: row.original, isDeleting: true })}
                sx={tableIconButtonSx}
              >
                <DeleteIcon {...actionIconSize} />
              </IconButton>
              <IconButton
                title={polyglot.t('CompanySettingsEntityTable.editCompanyEntity')}
                onClick={() => setDrawer({ entity: row.original })}
                sx={tableIconButtonSx}
              >
                <EditIcon {...actionIconSize} />
              </IconButton>
            </Stack>
          );
        },
      },
    ];
  }, [polyglot]);

  const closeDrawer = useCallback(
    (updated: boolean) => {
      setDrawer(undefined);
      if (updated) refreshEntities?.();
    },
    [refreshEntities]
  );

  const isDrawerOpen = typeof drawer !== 'undefined';

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('CompanySettingsEntityTable.entityInformation')}</Typography>}
        showBack
        showAction
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setDrawer({ entity: null })}>
            {polyglot.t('CompanySettingsEntityTable.buttonNewEntity')}
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={false}>
        <BasicTable
          rowData={sortedEntities}
          columnData={columns}
          hidePagination
          style={{ width: 'initial', marginTop: spacing.s1 }}
          loading={loadingGeneralSettings}
        />

        <DrawerModal isOpen={isDrawerOpen} setIsOpen={(open) => !open && closeDrawer(false)}>
          <>
            {drawer?.entity && drawer.isDeleting && (
              <DeleteCompanyEntityDrawer entity={drawer.entity} close={closeDrawer} />
            )}
            {drawer?.entity !== undefined && !drawer.isDeleting && (
              <CompanyEntityDrawerPage entity={drawer.entity} close={closeDrawer} />
            )}
          </>
        </DrawerModal>
      </ContentWrapper>
    </RootStyle>
  );
};
