import { Suspense, useCallback, useEffect, useState } from 'react';

import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DevicesStockTable } from '@v2/feature/device/components/devices-list-overview/devices-stock-table.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceDto, DeviceModelDto } from '@v2/feature/device/device.dto';
import { SuperAdminAddStockDeviceDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-add-stock-device-drawer.component';
import { BackofficeRootStyle } from '@v2/styles/root.styles';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { themeColors } from '@/v2/styles/colors.styles';

interface SuperAdminDeviceStockPageProps {
  readonly pageConfig: PageConfig;
}

export const SuperAdminDeviceStockPage = ({ pageConfig }: SuperAdminDeviceStockPageProps): JSX.Element => {
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState<boolean>(false);
  const [deviceModels, setDeviceModels] = useState<readonly DeviceModelDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showMessage] = useMessage();
  const [stockDevices, setStockDevices] = useState<readonly DeviceDto[]>([]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [deviceModelsData, stockDeviceData] = await Promise.all([
        DeviceAPI.getDeviceModels(true),
        DeviceAPI.getStockDevicesAsSuperadmin(),
      ]);
      setDeviceModels(deviceModelsData);
      setStockDevices(stockDeviceData);
    } catch (error) {
      showMessage(`Could not retrieve data. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <BackofficeRootStyle>
      <TopHeader
        title={<Typography variant="title2">Devices</Typography>}
        views={<></>}
        showAction={true}
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsAddDrawerOpen(true)}>
            Add device in stock
          </ButtonComponent>
        }
      />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={loading} secondLevel>
        {Boolean(stockDevices && stockDevices.length > 0) ? (
          <Suspense
            fallback={
              <SkeletonLoader
                variant="rectangular"
                width="83%"
                height="100%"
                sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
              />
            }
          >
            <DevicesStockTable devices={stockDevices} refresh={fetchData} />
          </Suspense>
        ) : (
          <Typography variant="title4">No devices in stock.</Typography>
        )}

        <SuperAdminAddStockDeviceDrawer
          isOpen={isAddDrawerOpen}
          setIsOpen={setIsAddDrawerOpen}
          deviceModels={deviceModels}
          refresh={fetchData}
        />
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
