import { Fragment, useContext, useMemo, useState } from 'react';

import { Dictionary, keyBy } from 'lodash';
import Polyglot from 'node-polyglot';
import { Redirect, Switch, generatePath, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import {
  SURVEYS_COMPANY_ONGOING_DETAIL_HEATMAP_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_INSIGHTS_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_RESULT_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_FORM_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE,
  SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE,
  SURVEYS_COMPANY_ONGOING_ROUTE,
} from '@/lib/routes';
import { canAccessScopes } from '@/lib/scopes';
import { CurrentUser } from '@/models';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { NavConfigItem } from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { FiltersEndpoints } from '@/v2/feature/filters/filters.api';
import { GrowthFactorEndpoints } from '@/v2/feature/growth/growth-factor/growth-factor.api';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import { SurveyCycleEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { SCUpsertGeneralPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-general/sc-upsert-general.page';
import { SCUpsertLaunchPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-launch/sc-upsert-launch.page';
import { SurveyCycleCreationParticipantsPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-participants/sc-upsert-participants.page';
import { SCUpsertTimelinePage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/sc-upsert-timeline/sc-upsert-timeline.page';
import { SurveyCycleCreationNotificationsPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-notifications/survey-cycle-creation-notifications.page';
import { SurveyCycleCreationQuestionPage } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-questions/survey-cycle-creation-question.page';
import { SURVEYS_ALL_SCOPE } from '@/v2/feature/growth/surveys/features/survey-cycle/sc-upsert/survey-cycle-creation-router/company/survey-cycle-creation-company.router';
import { SurveyCycleDetailHeatmapPage } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-heatmap/survey-cycle-detail-heatmap.page';
import { SurveyCycleDetailInsightsPage } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-insights/survey-cycle-detail-insights.page';
import { SurveyCycleDetailResultsPage } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-results/survey-cycle-detail-results.page';
import { SurveyCycle } from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';

const getPageConfig = (
  cycleId: string,
  surveyCycle: SurveyCycle | null | undefined,
  polyglot: Polyglot,
  user: CurrentUser,
  isAdmin: boolean,
  isManager: boolean
): readonly NavConfigItem[] => {
  return [
    ...(surveyCycle?.state !== CycleState.Draft
      ? [
          {
            title: 'General',
            stub: '',
            isHidden: false,
            hasChildren: true,
            subItems: [
              ...(surveyCycle?.state === CycleState.Ongoing ||
              surveyCycle?.state === CycleState.Completed ||
              surveyCycle?.state === CycleState.Paused
                ? [
                    {
                      title: 'Insights',
                      stub: 'insights',
                      path: generatePath(SURVEYS_COMPANY_ONGOING_DETAIL_INSIGHTS_ROUTE, { cycleId }),
                      isHidden: false,
                      hasChildren: false,
                    },
                    {
                      title: 'Results',
                      stub: 'result',
                      path: generatePath(SURVEYS_COMPANY_ONGOING_DETAIL_RESULT_ROUTE, { cycleId }),
                      isHidden: false,
                      hasChildren: false,
                    },
                    {
                      title: 'Heatmap',
                      stub: 'heatmap',
                      path: generatePath(SURVEYS_COMPANY_ONGOING_DETAIL_HEATMAP_ROUTE, { cycleId }),
                      isHidden: false,
                      hasChildren: false,
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isAdmin || (isManager && user.userId === surveyCycle?.owner)
      ? [
          {
            title: 'Settings',
            stub: 'settings',
            isHidden: false,
            hasChildren: true,
            subItems: [
              {
                title: polyglot.t('CycleSettingsRouter.general'),
                stub: 'general',
                path: generatePath(SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              {
                title: 'Questions',
                stub: 'question',
                path: generatePath(SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_FORM_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              {
                title: 'Participants',
                stub: 'participants',
                path: generatePath(SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              {
                title: 'Timeline',
                stub: 'timeline',
                path: generatePath(SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              {
                title: 'Notifications',
                stub: 'notifications',
                path: generatePath(SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE, { cycleId }),
                isHidden: false,
                hasChildren: false,
              },
              ...(surveyCycle?.state === CycleState.Draft || surveyCycle?.state === CycleState.Scheduled
                ? [
                    {
                      title: 'Start survey cycle',
                      stub: 'launch',
                      path: generatePath(SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE, { cycleId }),
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ] as NavConfigItem[];
};
export const SurveyCycleDetailCompanyRouter = () => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);

  const { user } = state;
  const isAdmin = canAccessScopes(user, ['surveys:all']);
  const isManager = canAccessScopes(user, ['surveys:manager']);
  const params = useParams<{ cycleId: string }>();
  const cycleId = params.cycleId;

  const [filterString, setFilterString] = useState<string>(state.user.features?.surveys?.results?.selectedFilter || '');

  const { data: surveyCycle, mutate: refreshSurveyCycle, isLoading: cycleLoading } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleById(cycleId, ReachType.Company),
    {
      suspense: false,
    }
  );

  const { data: surveyResult, isLoading: loadingResult } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleResult(cycleId, ReachType.Company, filterString),
    {
      suspense: false,
    }
  );

  const { data: allGrowthFactors } = useApiClient(GrowthFactorEndpoints.getGrowthFactorsByCompanyId(), {
    suspense: false,
  });

  const { data: filtersAndTypesOptions } = useApiClient(FiltersEndpoints.getSurveyFilterOptions(), {
    suspense: false,
  });

  const growthFactors: Dictionary<GrowthFactor> = useMemo(() => {
    return keyBy(allGrowthFactors, 'name');
  }, [allGrowthFactors]);

  const refresh = async () => {
    await refreshSurveyCycle?.();
  };

  if (!surveyCycle) return <></>;

  return (
    <Fragment>
      <DomainSideMenuContent
        title="Surveys"
        pageConfig={getPageConfig(cycleId, surveyCycle, polyglot, user, isAdmin, isManager)}
        backPath={generatePath(SURVEYS_COMPANY_ONGOING_ROUTE, {
          cycleId,
        })}
        showBack
        type="Domain"
      />
      <Switch>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_DETAIL_INSIGHTS_ROUTE}>
          <SurveyCycleDetailInsightsPage
            surveyCycle={surveyCycle}
            cycleLoading={cycleLoading || loadingResult}
            growthFactors={growthFactors}
            surveyResult={surveyResult}
            reach={ReachType.Company}
            filtersAndTypesOptions={filtersAndTypesOptions || []}
            setFilterString={setFilterString}
            filterString={filterString}
          />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_DETAIL_HEATMAP_ROUTE}>
          <SurveyCycleDetailHeatmapPage
            surveyCycle={surveyCycle}
            cycleLoading={cycleLoading}
            reach={ReachType.Company}
            growthFactors={growthFactors}
          />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_DETAIL_RESULT_ROUTE}>
          <SurveyCycleDetailResultsPage
            surveyCycle={surveyCycle}
            surveyResult={surveyResult}
            loadingResult={loadingResult}
            cycleLoading={cycleLoading}
            growthFactors={growthFactors}
            filtersAndTypesOptions={filtersAndTypesOptions || []}
            setFilterString={setFilterString}
            filterString={filterString}
          />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE}>
          <SCUpsertGeneralPage
            surveyCycle={surveyCycle}
            refresh={refresh}
            cycleLoading={cycleLoading}
            reach={ReachType.Company}
          />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_FORM_ROUTE}>
          <SurveyCycleCreationQuestionPage cycleId={cycleId} />
        </RouteScopesControl>
        <RouteScopesControl
          scopes={[SURVEYS_ALL_SCOPE]}
          path={SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_PARTICIPANTS_ROUTE}
        >
          <SurveyCycleCreationParticipantsPage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_TIMELINE_ROUTE}>
          <SCUpsertTimelinePage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <RouteScopesControl
          scopes={[SURVEYS_ALL_SCOPE]}
          path={SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_NOTIFICATION_ROUTE}
        >
          <SurveyCycleCreationNotificationsPage surveyCycle={surveyCycle} refresh={refresh} />
        </RouteScopesControl>
        <RouteScopesControl scopes={[SURVEYS_ALL_SCOPE]} path={SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_LAUNCH_ROUTE}>
          <SCUpsertLaunchPage cycleId={cycleId} reach={ReachType.Company} />
        </RouteScopesControl>
        <Redirect
          to={
            surveyCycle?.state !== CycleState.Draft
              ? SURVEYS_COMPANY_ONGOING_DETAIL_INSIGHTS_ROUTE
              : SURVEYS_COMPANY_ONGOING_DETAIL_SETTINGS_GENERAL_ROUTE
          }
          exact
        />
      </Switch>
    </Fragment>
  );
};
