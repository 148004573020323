import { useState } from 'react';

import { Box } from '@mui/material';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Form, FormikProvider, useFormik } from 'formik';

import { OauthClientAPI } from '@/api-client/oauth-client.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ViewerItem } from '@/v2/feature/payments/components/payment-details-drawer.component';
import { PublishRequestForSuperadmin } from '@/v2/feature/security/security-settings/features/developer-hub/developer-hub.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@/v2/styles/settings.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface Props {
  readonly initialValues: PublishRequestForSuperadmin;
  readonly onClose: () => void;
}

export const PublishRequestsApproveRejectDrawerForm = ({ initialValues, onClose }: Props): JSX.Element => {
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<PublishRequestForSuperadmin>({
    initialValues,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const approveRequest = async (companyId: number, id: string) => {
    setLoading(true);
    try {
      await OauthClientAPI.approvePublishRequest(companyId, id, formik.values.reviewNotes);
      showMessage(`Request approved successfully`, 'success');
      onClose();
    } catch (error) {
      showMessage(`Failed to approve request: ${nestErrorMessage(error)}; Please try again.`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const rejectRequest = async (companyId: number, id: string) => {
    setLoading(true);
    try {
      // Update request status API
      await OauthClientAPI.rejectPublishRequest(companyId, id, formik.values.reviewNotes);
      showMessage(`Request rejected successfully`, 'success');
      onClose();
    } catch (error) {
      showMessage(`Failed to rejected request: ${nestErrorMessage(error)}; Please try again.`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={drawerContentSx}>
          {/* Display Request Information */}
          <Typography variant="title2">Request details</Typography>
          <ViewerItem title="Request ID:" value={formik.values.id} />
          <ViewerItem title="Client ID:" value={formik.values.clientId} />
          <ViewerItem title="Company:" value={formik.values.company?.name ?? ''} />
          <ViewerItem
            title="Scope reasons:"
            value={
              <Box sx={{ ml: 2 }}>
                {Object.entries(formik.values.scopeReasons).map(([scope, reason]) => (
                  <Typography key={scope} variant="caption">
                    <strong>{scope}:</strong>
                    &nbsp;<em>{reason}</em>
                  </Typography>
                ))}
              </Box>
            }
          />

          {/* Notes field */}
          <Box sx={{ mt: spacing.g10 }}>
            <TextfieldComponent
              label="Reviewer Notes"
              name="reviewNotes"
              value={formik.values.reviewNotes}
              onChange={formik.handleChange}
              fullWidth
              multiline
              rows={4}
              placeholder="Add notes for approval or rejection (optional)"
            />
          </Box>

          <Box sx={buttonBoxDrawerSx}>
            <LoaderButton
              name={'Reject'}
              key="reject"
              fullWidth
              loading={loading}
              onClick={() => rejectRequest(formik.values.companyId, formik.values.id)}
              sizeVariant="medium"
              colorVariant="danger"
            />
            <LoaderButton
              name={'Approve'}
              key="approve"
              fullWidth
              loading={loading}
              onClick={() => approveRequest(formik.values.companyId, formik.values.id)}
              sizeVariant="medium"
              colorVariant="primary"
            />
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};
