import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, FormControl, Stack } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { ContractorInvoiceAPI } from '@/api-client/contractor-invoice-api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as TrashIcon } from '@/images/fields/Trash.svg';
import { ReactComponent as EditIcon } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  INVOICES_COMPANY_OVERVIEW_ROUTE,
  INVOICES_ME_OVERVIEW_ROUTE,
  INVOICES_ME_ROUTE,
  INVOICES_TEAM_OVERVIEW_ROUTE,
  USER_INVOICES_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CurrencyTextField } from '@/v2/components/forms/currency-text-field.component';
import { DatePickerComponent } from '@/v2/components/forms/date-picker.component';
import { OptionObject, SelectComponent } from '@/v2/components/forms/select.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import {
  getTaxRateForTotalSection,
  getTaxRateFromSelectedOption,
  NO_VAT_VALUE,
} from '@/v2/feature/payments/expenses.util';
import { ContractorInvoiceLineItemDrawer } from '@/v2/feature/payments/pages/components/contractor-invoice-line-item.drawer.component';
import { VatNumberModal } from '@/v2/feature/payments/pages/components/vat-number-modal.component';
import { CreateContractorInvoiceDto } from '@/v2/feature/payments/payments.dto';
import {
  ContractorInvoice,
  ContractorInvoiceLineItem,
  ContractorInvoiceStatus,
  DEFAULT_CURRENCY,
  TaxRateOptions,
} from '@/v2/feature/payments/payments.interface';
import {
  getFinalGrossForLineItemInvoice,
  getInvoiceTotalsBasedOnLineItems,
  isTaxIncluded,
} from '@/v2/feature/payments/utils/invoice.util';
import { PaymentSettingsNewTypeDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-new-type-drawer.component';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { fieldSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { normalCaptionSmallStyledChipSx, warningCaptionSmallStyledChipSx } from '@/v2/styles/chip.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatCurrency } from '@/v2/util/currency-format.util';
import { LocalDate } from '@/v2/util/local-date';
import { truncateWithEllipses } from '@/v2/util/string.util';

export const NewInvoicePage = ({
  preselectedEmployee,
  fromUserProfile = false,
  reach = 'company',
  editMode = false,
}: {
  readonly preselectedEmployee?: number;
  readonly fromUserProfile?: boolean;
  readonly reach?: 'user' | 'team' | 'company';
  editMode?: boolean;
}) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [showMessage] = useMessage();
  const { getCachedUserById } = useCachedUsers();
  const [submitting, setSubmitting] = useState(false);
  const [vatModalOpen, setVatModalOpen] = useState<boolean>(false);
  const [approverList, setApproverList] = useState<number[]>([]);
  const [addingLineItemDrawerOpen, setAddingLineItemDrawerOpen] = useState<boolean>(false);
  const [editingLineItemIndex, setEditingLineItemIndex] = useState<number | undefined>(undefined);
  const [savingDraft, setSavingDraft] = useState(false);
  const { getScopesContext, hasScopes } = useScopes();

  const { data, mutate: refreshInvoicePaymentTypeSettingsData } = useApiClient(
    PaymentTypeSettingsEndpoints.getInvoiceTypesForCompanyId(),
    {
      suspense: false,
    }
  );

  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const [typeDrawerOpen, setIsTypeDrawerOpen] = useState<boolean>(false);

  // in edit path
  const invoiceToEdit = useMemo(
    () => (routerHistory.location.state as { invoiceToEdit: ContractorInvoice })?.invoiceToEdit || null,
    [routerHistory]
  );

  const currentUserIsInvoiceAdmin = hasScopes(
    ['invoices:all', 'invoices:manager'],
    getScopesContext({ userId: user?.userId })
  );
  const currentUserIsManagerOfInvoiceOwner = invoiceToEdit?.from
    ? checkScopes(user, ['invoices:manager'], { userId: invoiceToEdit.from })
    : false;

  // if invoice to edit
  if (
    editMode &&
    invoiceToEdit &&
    invoiceToEdit.from !== user.userId &&
    !currentUserIsInvoiceAdmin &&
    !currentUserIsManagerOfInvoiceOwner
  ) {
    routerHistory.push({
      pathname: generatePath(INVOICES_ME_ROUTE),
    });
  }

  const isInvoiceAdmin = checkScopes(user, ['invoices:all'], { userId: user.userId });

  const formik = useFormik<CreateContractorInvoiceDto>({
    initialValues: {
      typeId: invoiceToEdit?.typeId ?? 0,
      invoiceDate: invoiceToEdit?.invoiceDate ?? new LocalDate().toDateString(),
      dueDate: invoiceToEdit?.dueDate ?? '',
      invoiceNumber: invoiceToEdit?.invoiceNumber ?? '',
      status: invoiceToEdit?.status ?? ContractorInvoiceStatus.Pending,
      lineItems: invoiceToEdit?.lineItems ?? [],
      isVatIncluded: invoiceToEdit?.isVatIncluded ?? true,
      vatNumber: invoiceToEdit?.vatNumber ?? null,
      taxRate: invoiceToEdit?.taxRate ?? null,
      taxAmount: invoiceToEdit?.taxAmount ?? 0,
      taxId: invoiceToEdit?.taxId ?? null,
      notes: invoiceToEdit?.notes ?? null,
      from: editMode ? invoiceToEdit.from : preselectedEmployee ?? 0,
      currency: editMode
        ? invoiceToEdit?.currency
        : preselectedEmployee === user.userId
        ? user.currency ?? DEFAULT_CURRENCY
        : DEFAULT_CURRENCY,
      totalAmount: invoiceToEdit?.totalAmount ?? 0,
      amount: invoiceToEdit?.amount ?? 0,
    },
    enableReinitialize: editMode,
    // TODO: add proper validation
    validationSchema: yup.object({
      currency: yup.string().required('Currency is required'),
      isVatIncluded: yup.boolean(),
      taxRate: yup.number().when('isVatIncluded', {
        is: true,
        then: yup.number().nullable().required('Tax rate is required when VAT is included'),
      }),
    }),
    onSubmit: async (values) => {
      try {
        setSubmitting(true);
        const {
          typeId,
          invoiceDate,
          invoiceNumber,
          totalAmount,
          from,
          vatNumber,
          amount,
          taxRate,
          taxId,
          notes,
          currency,
          lineItems,
          status,
        } = values;

        // const matchingInvoiceType = invoiceTypes?.find((it) => it.id === typeId);

        if (!totalAmount || !invoiceDate || !typeId || !from) return;

        const taxPresent = taxRate !== NO_VAT_VALUE;
        const inDraftMode = status === ContractorInvoiceStatus.Draft && savingDraft;
        const invoiceData: CreateContractorInvoiceDto = {
          typeId,
          invoiceDate,
          dueDate: new LocalDate(dayjs(invoiceDate).add(7, 'day').toDate()).toDateString(),
          invoiceNumber,
          isVatIncluded: taxPresent,
          vatNumber: taxPresent ? vatNumber : null,
          taxRate: taxPresent ? getTaxRateFromSelectedOption(taxRate) : null,
          taxAmount: 0,
          taxId: taxPresent && taxId ? taxId : null,
          notes: notes ?? null,
          from,
          amount: Number(amount),
          status: inDraftMode ? ContractorInvoiceStatus.Draft : ContractorInvoiceStatus.Pending,
          totalAmount,
          currency,
          lineItems,
        };

        if (editMode && invoiceToEdit?.id) {
          await ContractorInvoiceAPI.updateInvoice(invoiceToEdit?.id, invoiceData);
        } else {
          await ContractorInvoiceAPI.createInvoice(invoiceData);
        }

        showMessage(
          polyglot.t(
            editMode ? 'NewInvoicePage.successMessages.invoiceEdit' : 'NewInvoicePage.successMessages.invoiceCreation'
          ),
          'success'
        );
        routerHistory.push(
          reach === 'team'
            ? INVOICES_TEAM_OVERVIEW_ROUTE
            : fromUserProfile && preselectedEmployee
            ? generatePath(USER_INVOICES_ROUTE, { userId: preselectedEmployee })
            : preselectedEmployee
            ? INVOICES_ME_OVERVIEW_ROUTE
            : INVOICES_COMPANY_OVERVIEW_ROUTE
        );
      } catch (error) {
        showMessage(
          polyglot.t(
            editMode ? 'NewInvoicePage.errorMessages.invoiceEdit' : 'NewInvoicePage.errorMessages.invoiceCreation',
            { errorMessage: nestErrorMessage(error) }
          ),
          'error'
        );
      } finally {
        setSubmitting(false);
      }
    },
    validateOnMount: true,
  });

  const invoiceTypes = useMemo(() => {
    if (data?.settings) {
      if (!preselectedEmployee && !formik.values.from) return data?.settings;
      if (preselectedEmployee)
        return data.settings.filter((setting) => setting?.selectedMembersIds?.includes(preselectedEmployee));
      if (formik.values.from)
        return data.settings.filter((setting) =>
          formik.values.from ? setting?.selectedMembersIds?.includes(formik.values.from) : true
        );
    } else {
      return [];
    }
  }, [data, formik.values.from, preselectedEmployee]);

  const invoiceTypeOptions: OptionObject[] = useMemo(() => {
    return invoiceTypes && invoiceTypes.length > 0
      ? invoiceTypes
          .filter((it) => Boolean(it.id))
          .map((it) => ({
            label: it.name,
            value: it.id != null ? it.id : '',
          }))
      : [];
  }, [invoiceTypes]);

  const multipleLineItems = useMemo(() => Boolean(formik.values?.lineItems?.length > 1), [
    formik.values?.lineItems?.length,
  ]);

  useEffect(() => {
    let finalAmount = 0;
    if (formik.values.amount) {
      finalAmount += +formik.values.amount;
    }
    formik.setFieldValue('totalAmount', +finalAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.isVatIncluded, formik.values.amount]);

  useEffect(() => {
    const fetchApprovalList = async () => {
      if (formik.values.from && formik.values.typeId) {
        const list = await ContractorInvoiceAPI.getApprovalListForNewInvoice(formik.values.from, formik.values.typeId);
        setApproverList(list);
      } else {
        setApproverList([]);
      }
    };
    fetchApprovalList();
  }, [formik.values.from, formik.values.typeId]);

  useEffect(() => {
    const generateInvoiceNumber = (userId: number | null | undefined) => {
      if (!userId) return '';
      const today = new Date();
      const year = today.getFullYear();
      const fromUser = getCachedUserById(userId);
      if (!fromUser) return '';
      const { firstName, lastName } = fromUser;
      const userInitials = `${firstName[0]}${lastName[0]}`;
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      const hour = today.getHours().toString().padStart(2, '0');
      const minute = today.getMinutes().toString().padStart(2, '0');
      const second = today.getSeconds().toString().padStart(2, '0');

      return `INV-${userInitials}-${year}${month}${day}-${hour}${minute}${second}`;
    };
    const invNumber = editMode ? invoiceToEdit?.invoiceNumber : generateInvoiceNumber(formik.values.from);
    formik.setFieldValue('invoiceNumber', invNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.from]);

  const noVat = formik.values.taxRate === NO_VAT_VALUE;

  const updateAmountsBasedOnGrossAndRateForSingleItemInvoice = useCallback(
    (amount: number, taxRate: number | null, lineItems: Partial<ContractorInvoiceLineItem>[]) => {
      const lineItemsToUpdate = lineItems ? [...lineItems] : [];
      const { totalAmount, totalTaxAmount } = getInvoiceTotalsBasedOnLineItems(lineItemsToUpdate);
      const grossForLineItem = totalAmount + totalTaxAmount;
      if (lineItemsToUpdate.length > 0) {
        const firstLineItem = lineItems[0];
        lineItemsToUpdate[0] = {
          ...firstLineItem,
          amount: firstLineItem.amount,
          isTaxIncluded: isTaxIncluded(firstLineItem.taxRate ?? NO_VAT_VALUE),
          gross: grossForLineItem,
        };
      } else {
        // no line items yet - need to insert new one
        lineItemsToUpdate.push({
          amount,
          isTaxIncluded: isTaxIncluded(taxRate ?? NO_VAT_VALUE),
          gross: grossForLineItem,
        });
      }
      formik.setFieldValue('lineItems', lineItemsToUpdate);
      formik.setFieldValue('gross', grossForLineItem);
      formik.setFieldValue('amount', totalAmount);
      formik.setFieldValue('taxAmount', totalTaxAmount);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.taxRate]
  );

  const updateLineItems = useCallback(
    (updatedValues: Partial<ContractorInvoiceLineItem>[]) => {
      formik.setFieldValue('lineItems', updatedValues);
      const { totalGross, totalAmount, totalTaxAmount } = getInvoiceTotalsBasedOnLineItems(updatedValues);
      formik.setFieldValue('gross', totalGross);
      formik.setFieldValue('amount', totalAmount);
      formik.setFieldValue('taxAmount', totalTaxAmount);
    },
    [formik]
  );

  const handleEditLineItem = (index: number) => {
    setEditingLineItemIndex(index);
    setAddingLineItemDrawerOpen(true);
  };

  const handleAddLineItems = () => {
    setEditingLineItemIndex(undefined);
    setAddingLineItemDrawerOpen(true);
  };

  const handleDeleteLineItem = useCallback(
    (index: number) => {
      const updatedLineItems = [...formik.values.lineItems];
      updatedLineItems.splice(index, 1);
      updateLineItems(updatedLineItems);
    },
    [formik.values.lineItems, updateLineItems]
  );

  const selectedInvoiceType = useMemo(() => {
    return formik.values.typeId ? invoiceTypes?.find((type) => type.id === formik.values.typeId) : null;
  }, [invoiceTypes, formik.values.typeId]);

  const selectedTypeHasAccountingCodesConfigured = useMemo(() => {
    if (formik.values.typeId) {
      return selectedInvoiceType?.accountingCodeConfig && selectedInvoiceType?.accountingCodeConfig?.length > 0;
    }
  }, [formik.values.typeId, selectedInvoiceType?.accountingCodeConfig]);

  const accountingCodeOptions = useMemo(() => {
    if (
      formik.values.typeId &&
      selectedInvoiceType &&
      selectedInvoiceType?.accountingCodeConfig?.length &&
      selectedInvoiceType?.accountingCodeConfig?.length > 0
    ) {
      return selectedInvoiceType?.accountingCodeConfig?.length > 0
        ? selectedInvoiceType.accountingCodeConfig?.map((eachOption) => {
            return { label: eachOption.accountingCodeDescription, value: eachOption.accountingCode };
          })
        : [];
    }
  }, [formik.values.typeId, selectedInvoiceType]);

  const columns = useMemo<ColumnDef<Partial<ContractorInvoiceLineItem>, Partial<ContractorInvoiceLineItem>>[]>(() => {
    return [
      {
        header: () => polyglot.t('NewExpensePage.accountingCode'),
        accessorFn: (row) => row,
        id: 'accountingCodeDescription',
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">
              {original.accountingCodeDescription
                ? truncateWithEllipses(original.accountingCodeDescription, 15)
                : polyglot.t('NewExpensePage.noConfiguredCode')}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('NewExpensePage.amount'),
        accessorFn: (row) => row,
        id: 'amount',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.amount ? (
            <Typography variant="caption">
              {formatCurrency(original.amount, undefined, formik.values.currency ?? DEFAULT_CURRENCY)}
            </Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => polyglot.t('NewInvoicePage.taxRate'),
        accessorFn: (row) => row,
        id: 'taxRate',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.taxRate ? (
            <Typography variant="caption">{getTaxRateForTotalSection(original.taxRate, false)}</Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => polyglot.t('NewExpensePage.taxAmount'),
        accessorFn: (row) => row,
        id: 'taxAmount',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.gross && original.amount && original.isTaxIncluded ? (
            <Typography variant="caption">
              {formatCurrency(original.gross - original.amount, undefined, formik.values.currency ?? DEFAULT_CURRENCY)}
            </Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => polyglot.t('NewExpensePage.description'),
        accessorFn: (row) => row,
        id: 'description',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.description ? <Typography variant="caption">{original.description}</Typography> : <EmptyCell />,
      },
      {
        header: () => polyglot.t('NewInvoicePage.gross'),
        accessorFn: (row) => row,
        id: 'gross',
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.gross ? (
            <Typography variant="caption">
              {' '}
              {formatCurrency(original.gross, undefined, formik.values.currency ?? DEFAULT_CURRENCY)}
            </Typography>
          ) : (
            <EmptyCell />
          ),
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        cell: ({ row }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: spacing.g5 }}>
            <StyledMenuComponent
              options={[
                {
                  icon: <TrashIcon {...iconSize} />,
                  label: 'Delete',
                  handler: () => {
                    handleDeleteLineItem(row.index);
                  },
                },
                {
                  icon: <EditIcon {...iconSize} />,
                  label: 'Edit',
                  handler: () => {
                    handleEditLineItem(row.index);
                  },
                },
              ]}
              actionButtonDetails={{
                type: 'iconButton',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: 'actions',
                icon: <ActionsSmall {...iconSize} />,
              }}
            />
          </Box>
        ),
      },
    ];
  }, [formik.values.currency, handleDeleteLineItem, polyglot]);

  const hasMoreThanOneLineItem = formik.values.lineItems?.length && formik.values.lineItems?.length > 1 ? true : false;

  const handleSaveDraft = useCallback(() => {
    setSavingDraft(true);
    formik.setFieldValue('status', ContractorInvoiceStatus.Draft);
    formik.handleSubmit();
  }, [formik]);

  const handleSubmit = useCallback(() => {
    setSavingDraft(false);
    formik.handleSubmit();
  }, [formik]);

  return (
    <RootStyle>
      <TopHeader
        title={
          <Typography variant="title2">
            {polyglot.t(editMode ? 'NewInvoicePage.titleEdit' : 'NewInvoicePage.title')}
          </Typography>
        }
        showBack
        sx={{ width: '618px', mx: 'auto' }}
      />
      <Box sx={{ width: '100%', overflowY: 'auto', mx: 'auto' }}>
        <Box sx={{ width: '618px', mx: 'auto' }}>
          <ContentWrapper
            noHorizontalPadding
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '618px',
              px: '9px',
              pt: '2px',
            }}
            loading={false}
          >
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <Box sx={{ width: '618px', mx: 'auto', mt: spacing.mt20, minHeight: '50vh', overflowY: 'auto' }}>
                <FormikProvider value={formik}>
                  <Form>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g20 }}>
                      {!preselectedEmployee && (
                        <FormControl sx={fieldSx} size="small" fullWidth>
                          <SingleUserSelect
                            name="from"
                            options={reach}
                            value={formik.values.from ? Number(formik.values.from) : null}
                            onChange={async (_, x: unknown) => {
                              const userId = (x as { value: number })?.value ?? null;
                              await formik.setFieldValue('from', userId);
                            }}
                            label={polyglot.t('NewInvoicePage.employee')}
                            error={Boolean(formik.errors.from)}
                            helperText={formik.errors.from}
                          />
                        </FormControl>
                      )}
                      <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                        <DatePickerComponent
                          inputFormat="DD/MM/YYYY"
                          value={formik.values.invoiceDate ?? null}
                          onChange={(value) => {
                            if (dayjs(value).isValid()) {
                              formik.setFieldValue('invoiceDate', value);
                            }
                          }}
                          name="invoiceDate"
                          label={polyglot.t('NewInvoicePage.invoiceDate')}
                          error={formik.touched.invoiceDate && Boolean(formik.errors.invoiceDate)}
                          helperText={(formik.touched.invoiceDate && formik.errors.invoiceDate) as string}
                        />
                      </FormControl>
                      <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                        <SelectComponent
                          name="typeId"
                          label={polyglot.t('NewInvoicePage.policy')}
                          options={invoiceTypeOptions}
                          value={formik.values.typeId}
                          onChange={(e) => {
                            formik.setFieldValue('typeId', e.target.value);
                          }}
                          editList={{
                            handler: () => setIsTypeDrawerOpen(true),
                            isHidden: !isInvoiceAdmin,
                          }}
                        />
                      </FormControl>
                      {selectedTypeHasAccountingCodesConfigured && !multipleLineItems ? (
                        <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                          <SelectComponent
                            name="accountingCode"
                            label={polyglot.t('NewExpensePage.accountingCode')}
                            options={accountingCodeOptions ?? []}
                            value={
                              formik.values.lineItems?.length > 0 && formik.values.lineItems[0]
                                ? formik.values.lineItems[0].accountingCode
                                : null
                            }
                            onChange={(e) => {
                              const matchingType = selectedInvoiceType?.accountingCodeConfig?.find(
                                (o) => o.accountingCode === e.target.value
                              );
                              const lineItemsToUpdate = formik.values.lineItems ? [...formik.values.lineItems] : [];
                              if (lineItemsToUpdate.length > 0) {
                                lineItemsToUpdate[0] = {
                                  ...formik.values.lineItems[0],
                                  accountingCode: e.target.value,
                                  accountingCodeDescription: matchingType?.accountingCodeDescription ?? '',
                                };
                              } else {
                                // no line items yet - need to insert new one
                                lineItemsToUpdate.push({
                                  accountingCode: e.target.value,
                                  accountingCodeDescription: matchingType?.accountingCodeDescription ?? '',
                                });
                              }
                              formik.setFieldValue('lineItems', lineItemsToUpdate);
                            }}
                          />
                        </FormControl>
                      ) : (
                        <></>
                      )}

                      {multipleLineItems ? (
                        <BasicTable<Partial<ContractorInvoiceLineItem>>
                          rowData={formik.values.lineItems}
                          columnData={columns}
                          loading={false}
                          hidePagination
                        />
                      ) : (
                        <></>
                      )}
                      {!multipleLineItems && (
                        <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                          <Typography variant="captionSmall" sx={{ color: themeColors.grey }}>
                            {polyglot.t('NewInvoicePage.amount')}
                          </Typography>
                          <CurrencyTextField
                            name="amount"
                            label=""
                            value={formik.values.amount ?? 0}
                            onChange={(value) => {
                              const lineItemsToUpdate = formik.values.lineItems ? [...formik.values.lineItems] : [];
                              if (lineItemsToUpdate.length > 0) {
                                lineItemsToUpdate[0] = {
                                  ...formik.values.lineItems[0],
                                  amount: value ? +value : 0,
                                };
                              } else {
                                // no line items yet - need to insert new one
                                lineItemsToUpdate.push({
                                  amount: value ? +value : 0,
                                });
                              }
                              formik.setFieldValue('lineItems', lineItemsToUpdate, true);
                              formik.setFieldValue('amount', value);
                              updateAmountsBasedOnGrossAndRateForSingleItemInvoice(
                                value ? +value : 0,
                                formik.values.taxRate,
                                lineItemsToUpdate
                              );
                            }}
                            selectedCurrency={formik.values.currency ?? DEFAULT_CURRENCY}
                            onCurrencyChange={(newCurrency) => {
                              formik.setFieldValue('currency', newCurrency);
                            }}
                            error={formik.touched.amount && Boolean(formik.errors.amount)}
                            helperText={(formik.touched.amount && formik.errors.amount) as string}
                          />
                        </FormControl>
                      )}
                      {!multipleLineItems && (
                        <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                          <Typography variant="captionSmall" sx={{ color: themeColors.grey }}>
                            {polyglot.t('NewInvoicePage.taxRate')}
                          </Typography>
                          <SelectComponent
                            label=""
                            name="taxRate"
                            options={TaxRateOptions}
                            value={formik.values.taxRate}
                            onChange={(e) => {
                              const lineItemsToUpdate = formik.values.lineItems ? [...formik.values.lineItems] : [];
                              if (lineItemsToUpdate.length > 0) {
                                lineItemsToUpdate[0] = {
                                  ...formik.values.lineItems[0],
                                  taxRate: e.target.value,
                                };
                              } else {
                                // no line items yet - need to insert new one
                                lineItemsToUpdate.push({
                                  taxRate: e.target.value,
                                });
                              }
                              formik.setFieldValue('lineItems', lineItemsToUpdate, true);
                              formik.setFieldValue('taxRate', e.target.value, true);
                              updateAmountsBasedOnGrossAndRateForSingleItemInvoice(
                                formik.values.amount ?? 0,
                                e.target.value,
                                lineItemsToUpdate
                              );
                            }}
                          />
                        </FormControl>
                      )}

                      <ButtonComponent
                        sizeVariant="small"
                        colorVariant="secondary"
                        onClick={() => handleAddLineItems()}
                        startIcon={<Plus {...iconSize} />}
                        style={{ borderRadius: '30px' }}
                      >
                        {polyglot.t('NewInvoicePage.addLineItems')}
                      </ButtonComponent>

                      <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                        <TextfieldComponent
                          name="invoiceNumber"
                          label={polyglot.t('NewInvoicePage.invoiceNumber')}
                          value={formik.values.invoiceNumber}
                          type="text"
                          onChange={formik.handleChange}
                          error={formik.touched.invoiceNumber && Boolean(formik.errors.invoiceNumber)}
                          helperText={(formik.touched.invoiceNumber && formik.errors.invoiceNumber) as string}
                          clearText={() => formik.setFieldValue('invoiceNumber', '')}
                        />
                      </FormControl>
                      <FormControl sx={{ ...fieldSx }} size="small" fullWidth>
                        <TextfieldComponent
                          name="notes"
                          label={polyglot.t('NewInvoicePage.notes')}
                          value={formik.values.notes}
                          type="text"
                          onChange={formik.handleChange}
                          error={formik.touched.notes && Boolean(formik.errors.notes)}
                          helperText={(formik.touched.notes && formik.errors.notes) as string}
                          clearText={() => formik.setFieldValue('notes', '')}
                          multiline
                          minRows={1}
                        />
                      </FormControl>

                      {vatModalOpen && <VatNumberModal setOpen={setVatModalOpen} open={vatModalOpen} formik={formik} />}

                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Stack direction="column">
                          <Typography variant="title4">{polyglot.t('NewInvoicePage.amount')}</Typography>
                          <Stack direction="row" sx={{ height: '20px' }}>
                            <Typography variant="caption">
                              {polyglot.t('NewInvoicePage.taxAmount')}
                              {!hasMoreThanOneLineItem ? getTaxRateForTotalSection(formik.values.taxRate) : ''}
                            </Typography>

                            {!noVat && (
                              <Box
                                sx={{
                                  ...(!formik.values.vatNumber
                                    ? warningCaptionSmallStyledChipSx
                                    : normalCaptionSmallStyledChipSx),
                                  height: '20px',
                                }}
                                onClick={() => {
                                  setVatModalOpen(true);
                                }}
                              >
                                {polyglot.t(
                                  !formik.values.vatNumber
                                    ? 'NewInvoicePage.missingVatNumber'
                                    : 'NewInvoicePage.vatNumberProvided'
                                )}
                              </Box>
                            )}
                          </Stack>
                          <Typography variant="title4">{polyglot.t('NewInvoicePage.gross')}</Typography>
                        </Stack>
                        <Stack direction="column" sx={{ alignItems: 'flex-end' }}>
                          <Typography variant="title4">
                            {formatCurrency(
                              formik.values.amount ? (formik.values.amount as number) : 0,
                              undefined,
                              formik.values.currency ?? DEFAULT_CURRENCY
                            )}
                          </Typography>
                          <Typography variant="caption">
                            {formatCurrency(
                              formik.values.taxAmount ?? 0,
                              undefined,
                              formik.values.currency ?? DEFAULT_CURRENCY
                            )}
                          </Typography>
                          <Typography variant="title4">
                            {formatCurrency(
                              getFinalGrossForLineItemInvoice(
                                formik.values.lineItems ?? [
                                  { amount: formik.values.amount, taxRate: formik.values.taxRate },
                                ]
                              ) ?? 0,
                              undefined,
                              formik.values.currency ?? DEFAULT_CURRENCY
                            )}
                          </Typography>
                        </Stack>
                      </Box>

                      {approverList?.length > 0 && (
                        <Box sx={{ mt: spacing.mt20 }}>
                          <Typography variant="captionSmall" sx={{ color: themeColors.grey, mb: spacing.mb5 }}>
                            {polyglot.t('NewExpensePage.approvers')}
                          </Typography>
                          <Stack direction="column" sx={{ gap: spacing.g10 }}>
                            {approverList?.map((approver) => (
                              <UserCell userId={approver as number} />
                            ))}
                          </Stack>
                        </Box>
                      )}

                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'center',
                          mt: spacing.mt30,
                          gap: spacing.s2,
                        }}
                      >
                        {((invoiceToEdit && invoiceToEdit.status === ContractorInvoiceStatus.Draft) ||
                          !invoiceToEdit) && (
                          <ButtonComponent
                            fullWidth
                            sizeVariant="medium"
                            colorVariant="secondary"
                            onClick={() => handleSaveDraft()}
                            loading={submitting}
                          >
                            {polyglot.t('OnboardingUserInvite.saveDraft')}
                          </ButtonComponent>
                        )}
                        <ButtonComponent
                          fullWidth
                          loading={submitting}
                          colorVariant="primary"
                          sizeVariant="medium"
                          onClick={() => handleSubmit()}
                        >
                          {polyglot.t('General.submit')}
                        </ButtonComponent>
                      </Box>
                    </Box>
                  </Form>
                </FormikProvider>
              </Box>
              {typeDrawerOpen && (
                <PaymentSettingsNewTypeDrawer
                  isOpen={typeDrawerOpen}
                  setIsOpen={setIsTypeDrawerOpen}
                  refreshAllSettings={refreshInvoicePaymentTypeSettingsData}
                  typeForEdit={undefined}
                />
              )}
              <ContractorInvoiceLineItemDrawer
                isOpen={addingLineItemDrawerOpen}
                setIsOpen={setAddingLineItemDrawerOpen}
                updateLineItems={updateLineItems}
                existingLineItems={formik.values.lineItems}
                accountingCodeOptions={accountingCodeOptions ?? []}
                indexToEdit={editingLineItemIndex}
              />
            </Box>
          </ContentWrapper>
        </Box>
      </Box>
    </RootStyle>
  );
};
