import { Extension } from '@tiptap/core';
import { DOMParser, Fragment } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';

import { blobToBase64 } from '@/v2/components/forms/document-editor/tiptap/extensions/helpers/file-download.util';
import {
  generateSmartNodeWrapperElements,
  SMART_FIELD_CUSTOM_MARKER,
} from '@/v2/components/forms/document-editor/tiptap/nodes/smart-field.node';

const DELETE_POSITION_DATA_TRANSFER = 'delete-position';

function wrapHtmlInTemplate(value: string): HTMLSpanElement {
  const element = document.createElement('span');
  element.innerHTML = value.trim();
  return element;
}

export const HandleDropExtension = Extension.create({
  name: 'HandleDropExtension',
  addOptions() {
    return {
      handleError: () => {},
    };
  },

  addProseMirrorPlugins() {
    const { handleError } = this.options;
    return [
      new Plugin({
        key: new PluginKey('HandleDropExtension'),
        props: {
          handleDOMEvents: {
            dragstart: (view, event) => {
              const target = event.target as Node | null;
              if (target) {
                const pos = view.posAtDOM(target, 0);
                const node = view.state.doc.nodeAt(pos);
                //@ts-ignore
                event?.dataTransfer?.setData('text/plain', target?.outerHTML);
                if (node) {
                  event?.dataTransfer?.setData(
                    DELETE_POSITION_DATA_TRANSFER,
                    JSON.stringify({ to: pos + node?.nodeSize, from: pos })
                  );
                }
                return true;
              }
              return false;
            },
            //@ts-ignore
            drop: async (view, event): Promise<boolean> => {
              const { state, dispatch } = view;
              const { tr } = state;
              if (!event) return false;
              event.preventDefault();

              const htmlContent = event?.dataTransfer?.getData('snippet') || event?.dataTransfer?.getData('text/plain');
              if (htmlContent) {
                const transactions: { to: number; tr: () => void }[] = [];

                const deleteContentStr = event?.dataTransfer?.getData(DELETE_POSITION_DATA_TRANSFER);
                const deleteContent = deleteContentStr ? JSON.parse(deleteContentStr) : undefined;

                const coordinates = view.posAtCoords({
                  left: event.clientX,
                  top: event.clientY,
                });
                // if you are trying to drag and drop the smart element into the same location then ignore
                if (coordinates && coordinates?.pos <= deleteContent?.to && coordinates?.pos >= deleteContent?.from) {
                  return false;
                }

                let fragment: Fragment | undefined = undefined;
                const parsedContent = DOMParser.fromSchema(view.state.schema).parseSlice(
                  wrapHtmlInTemplate(htmlContent)
                );

                if (htmlContent.includes(SMART_FIELD_CUSTOM_MARKER)) {
                  fragment = generateSmartNodeWrapperElements(view.state, parsedContent?.content?.child?.(0)?.text);
                }

                if (coordinates) {
                  const node = view.state.doc.nodeAt(coordinates?.pos);
                  if (!node || (node && node.content.size === 0)) {
                    if (deleteContent) {
                      transactions.push({
                        to: deleteContent.from,
                        tr: () => tr.delete(deleteContent.from, deleteContent.to),
                      });
                    }
                    transactions.push({
                      to: coordinates.pos,
                      tr: () =>
                        tr.insert(
                          coordinates.pos,
                          fragment && fragment?.childCount > 0 ? fragment : parsedContent.content
                        ),
                    });
                  }
                }
                transactions.sort((a, b) => b.to - a.to);

                transactions.forEach((t) => {
                  t.tr();
                });

                dispatch(tr);
                return true;
              } else if (event?.dataTransfer?.files && (event?.dataTransfer?.files || []).length) {
                const files = event?.dataTransfer?.files;
                try {
                  const src = await blobToBase64(files[0]);
                  src && this.editor.chain().focus().setImage({ src: src?.toString() }).run();
                } catch (err) {
                  handleError('Failed to load your image. Please try again.');
                }
              }
              return false;
            },
          },
        },
      }),
    ];
  },
});
