import { Node } from '@tiptap/core';
import { Fragment } from '@tiptap/pm/model';
import { EditorState, TextSelection } from '@tiptap/pm/state';
import { Plugin } from 'prosemirror-state';
import { v4 } from 'uuid';

import { SMART_FIELD_MAP_BY_LABEL, SMART_FIELD_TYPE, SMART_FIELDS } from '@/v2/feature/templates/components/editor';

const smartFieldRegex = /\{\{(\w+)\}\}/;
export const SMART_FIELD_CUSTOM_MARKER = 'smart-field-custom-marker';

export const cleanHTML = (html: string): string => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  const markers = tempDiv.querySelectorAll(`.${SMART_FIELD_CUSTOM_MARKER}`);

  markers.forEach((marker) => {
    if (!marker?.textContent) return;
    const value = SMART_FIELD_MAP_BY_LABEL.get(marker.textContent);
    if (!value) return;
    if (marker) marker.textContent = value;
  });

  //need to remove gap cursor elements to pass api calls
  const separators = tempDiv.querySelectorAll('.ProseMirror-separator');
  separators.forEach((element) => {
    element.remove();
  });

  const trailingBreak = tempDiv.querySelectorAll('.ProseMirror-trailingBreak');
  trailingBreak.forEach((element) => {
    element.remove();
  });

  const emptyImages = tempDiv.querySelectorAll('img[alt=""]');
  emptyImages.forEach((element) => {
    element.remove();
  });

  return tempDiv.innerHTML;
};

export const generateSmartNodeWrapperElements = (
  newState: EditorState,
  label: string | undefined
): Fragment | undefined => {
  const customMark = newState.schema.marks.customMark.create({
    class: SMART_FIELD_CUSTOM_MARKER,
    contenteditable: false,
    draggable: true,
    id: v4(),
  });

  if (!label) return;
  const textNode = newState.schema.text(label);
  const img = newState.schema.nodes.image.create({
    class: 'ProseMirror-separator',
    alt: '',
  });
  const nodes = [img, textNode.mark([customMark]), img];
  return Fragment.fromArray(nodes);
};

export const SmartFieldNode = Node.create({
  name: 'placeholderReplace',
  group: 'block',
  content: 'inline*',
  draggable: true,
  allowGapCursor: true,
  addOptions() {
    return {};
  },
  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const { state, dispatch } = editor.view;
        const { selection } = state;
        const { empty, from } = selection;

        if (!empty) return false;

        const nodeBefore = state.doc.nodeAt(from - 1);

        if (!nodeBefore) return false;

        const mark = nodeBefore.marks.find((mark) => mark.attrs.class === SMART_FIELD_CUSTOM_MARKER);
        if (mark) {
          const spanStartPos = from - nodeBefore.nodeSize;
          const spanEndPos = from;
          dispatch(state.tr.delete(spanStartPos, spanEndPos));
          return true;
        }

        return false;
      },
      Enter: ({ editor }) => {
        const { state, view } = editor;
        const { selection } = state;
        const { $from, $to } = selection;

        const nodeBefore = $from.nodeBefore;
        if (!nodeBefore) return false;
        const mark = nodeBefore.marks.find((mark) => mark.attrs.class === SMART_FIELD_CUSTOM_MARKER);
        if (mark) {
          const transaction = state.tr.split($to.pos);
          const newSelection = TextSelection.create(transaction.doc, $to.pos + 1);
          transaction.setSelection(newSelection);
          view.dispatch(transaction);
          return true;
        }

        return false;
      },
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction(transactions, oldState, newState) {
          let tr = newState.tr;
          let replaced = false;
          //@ts-ignore
          let updates: { from: number; to: number; element: any }[] = [];
          newState.doc.descendants((node, pos) => {
            if (node.isText) {
              const { text } = node;

              const match = text?.match(smartFieldRegex);
              if (match) {
                SMART_FIELDS.forEach((smartField: SMART_FIELD_TYPE) => {
                  if (smartField.value === match[0]) {
                    const fragment = generateSmartNodeWrapperElements(newState, smartField.label);
                    if (!fragment) return;
                    updates.push({
                      from: pos + (match?.index || 0),
                      to: pos + (match?.index || 0) + match[0].length,
                      element: fragment,
                    });
                  }
                });

                replaced = true;
              }
            }
          });
          updates.reverse().forEach(({ from, to, element }) => {
            tr.replaceWith(from, to, element);
          });

          return replaced ? tr : null;
        },
      }),
    ];
  },
});
