import React, { useCallback, useContext, useMemo } from 'react';

import { Box, Slide, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  NavConfigItem,
  SubItemsProps,
  SubMenuProps,
} from '@/v2/feature/app-layout/features/v2/component/navigation-item.component';
import { TopNavigationMenu } from '@/v2/feature/app-layout/features/v2/component/top-navigation-menu.component';
import { RoleTypes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';
import {
  decomposePath,
  findSubItemByStub,
  getSecondMenuItems,
  navigateToFirstChildOrParent,
} from '@/v2/feature/app-layout/features/v2/menu/menu.util';
import '@/v2/feature/app-layout/features/v2/second-menu/second-menu.scss';
import { renderSingleItem, renderSubMenu } from '@/v2/feature/app-layout/features/v2/second-menu/second-menu.util';
import {
  getGrowthConfig,
  getMoneyConfig,
  getPeopleConfig,
  getReportsConfig,
  getTechConfig,
  getTimeConfig,
} from '@/v2/feature/app-layout/features/v2/sidebar-config.util';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const SECOND_DRAWER_WIDTH = 199;

const SecondMenuDesktopRootStyle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
  flex: `0 0 ${SECOND_DRAWER_WIDTH}px`,
  maxWidth: `${SECOND_DRAWER_WIDTH}px`,
  borderRight: borders.light,
});

const SecondMenuTabletInnerStyle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
  width: '100%',
  background: themeColors.white,
  flex: `0 0 ${SECOND_DRAWER_WIDTH}px`,
  maxWidth: `${SECOND_DRAWER_WIDTH}px`,
  borderRight: borders.light,
});
const SecondMenuTabletStyle = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
  width: '100%',
  background: '#e2dfdc78',
  zIndex: 4,
  position: 'absolute',
  left: 60,
});

export const SecondMenuSection = React.memo(
  ({
    activeParent,
    setActiveRole,
    activeRole,
    setActiveSubMenuParent,
    activeSubMenuParent,
    roles,
    setShowSecondMenu,
  }: {
    activeParent: string | undefined;
    setActiveRole: (val: RoleTypes) => void;
    activeRole: RoleTypes | undefined;
    setActiveSubMenuParent: (sunMenuParent: string | undefined) => void;
    activeSubMenuParent: string | undefined;
    roles: string[];
    setShowSecondMenu: (val: boolean) => void;
  }) => {
    const { polyglot } = usePolyglot();
    const theme = useTheme();
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up('md'));
    const isTabletScreen = useMediaQuery(
      `(min-width:${theme.breakpoints.values.sm + 1}px) and (max-width:${theme.breakpoints.values.md - 1}px)`
    );
    const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const routerHistory = useHistory();
    const routerLocation = useLocation();

    const [state] = useContext(GlobalContext);
    const { user } = state;
    const { pathname } = useLocation();

    const items = useMemo(() => {
      if (!activeRole || !user) return undefined;
      return activeParent === 'people'
        ? (getPeopleConfig(user, activeRole, polyglot) as NavConfigItem)
        : activeParent === 'time'
        ? (getTimeConfig(user, activeRole, polyglot) as NavConfigItem)
        : activeParent === 'money'
        ? (getMoneyConfig(user, activeRole, polyglot) as NavConfigItem)
        : activeParent === 'report'
        ? (getReportsConfig(user, activeRole, polyglot, !!state.showHiddenFeatures) as NavConfigItem)
        : activeParent === 'growth'
        ? (getGrowthConfig(user, activeRole, polyglot) as NavConfigItem)
        : activeParent === 'tech'
        ? (getTechConfig(user, activeRole, polyglot) as NavConfigItem)
        : undefined;
    }, [polyglot, user, activeRole, activeParent, state.showHiddenFeatures]);

    const handleParentClick = useCallback(
      (list: SubItemsProps) => {
        setActiveSubMenuParent(list.stub);
        if (!list.hasChildren && list.path) {
          routerHistory.push(list.path);
        } else {
          const firstChild = list.subMenu?.find((s) => !s.isHidden && s.path);
          if (firstChild && firstChild.path) {
            routerHistory.push(firstChild.path);
          }
        }
      },
      [routerHistory, setActiveSubMenuParent]
    );

    const handleSubmenuClick = useCallback(
      (sm: SubMenuProps) => {
        if (!sm.hasChildren && sm.path) {
          routerHistory.push(sm.path);
        }
      },
      [routerHistory]
    );

    const renderContent = (
      <Box
        sx={{
          background: themeColors.white,
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          gap: spacing.g20,
          marginTop: spacing.s2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="title2" sx={{ paddingRight: spacing.p10, paddingLeft: spacing.p10 }}>
            {items?.title}
          </Typography>

          {(isTabletScreen || isMobileScreen) && (
            <IconButton sizeVariant="small" colorVariant="secondary" onClick={() => setShowSecondMenu(false)}>
              <Close {...iconSize} fill={themeColors.DarkGrey} stroke={themeColors.DarkGrey} />
            </IconButton>
          )}
        </Box>
        <TopNavigationMenu
          roles={roles}
          setActiveRole={(r) => {
            const decomposed = decomposePath(routerLocation.pathname);
            const { domain, subDomain, subMenu } = decomposed;
            setActiveRole(r);
            const routedSecondMenuItems = getSecondMenuItems(r as RoleTypes, domain, user, polyglot);
            const parent = findSubItemByStub(routedSecondMenuItems?.subItems, subDomain);
            navigateToFirstChildOrParent(parent, subMenu, routedSecondMenuItems, routerHistory);
          }}
          activeRole={activeRole}
        />
        <Box sx={{ width: '100%' }}>
          {items?.subItems?.map((list, i) =>
            list.hasChildren
              ? renderSubMenu(list, handleParentClick, pathname, activeSubMenuParent, handleSubmenuClick, i)
              : renderSingleItem(list, handleParentClick, pathname, i)
          )}
        </Box>
      </Box>
    );

    return (
      <>
        {isDesktopScreen && (
          <SecondMenuDesktopRootStyle>
            <Slide
              direction={user.language !== 'ar' ? 'right' : 'left'}
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 300, exit: 300 }}
            >
              <Box sx={{ height: '100%', px: spacing.p10, width: '100%' }}>{renderContent}</Box>
            </Slide>
          </SecondMenuDesktopRootStyle>
        )}

        {(isTabletScreen || isMobileScreen) && (
          <SecondMenuTabletStyle>
            <SecondMenuTabletInnerStyle>
              <Slide
                direction={user.language !== 'ar' ? 'right' : 'left'}
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 300, exit: 300 }}
              >
                <Box sx={{ height: '100%', px: spacing.p10, width: '100%' }}>{renderContent}</Box>
              </Slide>
            </SecondMenuTabletInnerStyle>
          </SecondMenuTabletStyle>
        )}
      </>
    );
  }
);
