import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { TabFilterButtons } from '@v2/components/tab-filter-buttons.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { Typography } from '@v2/components/typography/typography.component';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceEndpoints } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { EnrolmentType } from '@v2/feature/device/device.interface';
import {
  getCompanyNamesKeyedByCompanyIds,
  getDeviceOwnerAsSuperadminByDevicePossession,
  getUserNamesKeyedByUserIds,
} from '@v2/feature/device/device.util';
import { DEBOUNCE_300_MS } from '@v2/feature/documents/documents.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { SuperAdminOverviewInHouseMdmDeviceDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-overview-in-house-mdm-device-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { secondaryCTABtn } from '@v2/styles/buttons.styles';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { pipe } from 'fp-ts/function';
import { keyBy } from 'lodash';
import CsvDownloader from 'react-csv-downloader';
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { Subject } from 'rxjs';
import * as RX from 'rxjs/operators';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Clock } from '@/images/side-bar-icons/Clock.svg';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { nestErrorMessage } from '@/lib/errors';
import { timeSince } from '@/lib/moment.lib';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

interface Props {
  readonly pageConfig: PageConfig;
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly companies: readonly SuperAdminCompanyInfo[];
}

export const SuperAdminInHouseMdmDevicesOverviewPage = ({ users, pageConfig, companies }: Props): React.JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [devicePossessions, setDevicePossessions] = useState<DevicePossessionDto[]>([]);
  const [devicePossessionToBeEdited, setDevicePossessionToBeEdited] = useState<DevicePossessionDto | null>(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const companiesNames = useMemo(() => getCompanyNamesKeyedByCompanyIds(companies), [companies]);

  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);
  const [showMessage] = useMessage();
  const [searchInput, setSearchInput] = useState('');
  const [filterString, setFilterString] = useState<string>('all');
  const [changeSearchInput$] = useState(() => new Subject<string>());
  const [sitesById, setSitesById] = useState<{ [id: number]: SiteDto }>({});
  const { data: superAdminDevicesResponseDto } = useApiClient(DeviceEndpoints.getAllDevicesAsSuperAdmin(true), {
    suspense: false,
  });
  const getAllSites = useCallback(async () => {
    try {
      const sites = await SiteAPI.listSitesAsSuperAdmin();
      setSitesById(keyBy(sites, 'id'));
    } catch (error) {
      showMessage(`Could not get sites list. ${nestErrorMessage(error)}`, 'error');
    }
  }, [showMessage]);

  useEffect(() => {
    pipe(
      changeSearchInput$,
      RX.debounceTime(DEBOUNCE_300_MS),
      ($) => $.subscribe((value) => setSearchInput(value)),
      (s) => () => s.unsubscribe()
    );
    getAllSites();
  }, [changeSearchInput$, getAllSites]);

  const filterDevicePossessions = useCallback(
    (devicePossessions: DevicePossessionDto[]) =>
      devicePossessions.filter((devicePossession) => {
        const userName = getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sitesById);
        const modelName = devicePossession.device?.modelName;
        const serialNumber = devicePossession.device?.serialNumber;
        const os = devicePossession.device?.os;
        const isAppleOS =
          os?.toLowerCase().includes('ios') ||
          os?.toLowerCase().includes('macos') ||
          os?.toLowerCase().includes('ipados');
        const manufacturer = devicePossession.device?.manufacturer;
        const isAppleDevice = manufacturer?.toLowerCase().includes('apple');
        const companyName = devicePossession.companyId ? companiesNames[devicePossession.companyId] : null;
        return (
          (isAppleDevice || isAppleOS) &&
          (userName?.toLowerCase().includes(searchInput.toLowerCase()) ||
            modelName?.toLowerCase().includes(searchInput.toLowerCase()) ||
            serialNumber?.toLowerCase().includes(searchInput.toLowerCase()) ||
            companyName?.toLowerCase().includes(searchInput.toLowerCase()))
        );
      }),
    [userNames, sitesById, searchInput, companiesNames]
  );

  const tableData = useMemo(() => {
    const searchFilteredDevices = filterDevicePossessions(devicePossessions);

    if (filterString === 'all') return searchFilteredDevices;
    return searchFilteredDevices.filter((devicePossession) => devicePossession.device?.os === filterString);
  }, [devicePossessions, filterString, filterDevicePossessions]);

  const columnData = useMemo<ColumnDef<DevicePossessionDto, DevicePossessionDto>[]>(() => {
    return [
      {
        header: () => 'Device Id',
        accessorFn: (row) => row,
        id: 'device.id',
        enableSorting: true,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return possession.device ? <Box>{possession.device.id}</Box> : <EmptyCell />;
        },
        size: 55,
      },
      {
        header: () => 'Serial Number',
        accessorFn: (row) => row,
        id: 'device.serialNumber',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const serialNumber = possession.device?.serialNumber;
          return serialNumber ? <Box>{serialNumber}</Box> : <EmptyCell />;
        },
        size: 125,
      },
      {
        header: () => 'Company Name',
        accessorFn: (row) => row,
        id: 'device.companyName',
        enableSorting: true,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const companyName = possession.companyId ? companiesNames[possession.companyId] : null;

          return companyName ? <Box>{companyName}</Box> : <EmptyCell />;
        },
        size: 125,
      },
      {
        header: () => 'Device',
        accessorFn: (row) => row,
        id: 'device.modelName',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const modelName = possession.device?.modelName;
          return modelName ? <Box>{modelName}</Box> : <EmptyCell />;
        },
        size: 125,
      },
      {
        header: () => 'Used By',
        accessorFn: (row) => row,
        id: 'usedBy',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const ownedBy = getDeviceOwnerAsSuperadminByDevicePossession(possession, userNames, sitesById);
          return <Box>{ownedBy}</Box>;
        },
        size: 150,
      },
      {
        header: () => 'MDM Database',
        accessorFn: (row) => row,
        id: 'device.inHouseMdm',
        enableSorting: true,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const inHouseMdm = possession.device?.inHouseMdm;
          return inHouseMdm ? (
            <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
              <OkGreen {...iconSize} style={{ fill: themeColors.Green }} /> Migrated
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center', color: themeColors.Grey }}>
              <Clock {...iconSize} style={{ fill: themeColors.Grey }} /> Not migrated
            </Box>
          );
        },
        size: 100,
      },
      {
        header: () => 'Enrolment Type',
        accessorFn: (row) => row,
        id: 'device.enrolmentType',
        enableSorting: true,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const enrolmentType = possession.device?.enrolmentType.valueOf();
          let textColor;
          let text;
          if (enrolmentType === EnrolmentType.ABM_DEP) {
            textColor = 'green';
            text = 'DEP';
          } else if (enrolmentType === EnrolmentType.OPEN_ENROLMENT) {
            textColor = 'black';
            text = 'Open enrolment';
          } else if (enrolmentType === EnrolmentType.ABM) {
            textColor = 'red';
            textColor = 'Not full DEP';
          } else if (enrolmentType === EnrolmentType.NONE) {
            textColor = 'orange';
            text = 'None';
          }

          return text ? <Box style={{ color: textColor }}>{text}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Enrolment Status',
        accessorFn: (row) => row,
        id: 'device.enrolmentStatus',
        enableSorting: true,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const enrolmentStatus = possession.device?.enrollmentStatus;
          let textColor;
          let text;
          if (enrolmentStatus === 'ENROLMENT_FINISHED') {
            textColor = 'green';
            text = 'Enroled successfully';
          } else {
            textColor = 'black';
            text = enrolmentStatus;
          }

          return enrolmentStatus ? <Box style={{ color: textColor }}>{text}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Last Sync',
        accessorFn: (row) => row,
        id: 'device.encryptionEnabled',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const syncTime = possession.device?.lastCheckIn;
          return syncTime ? <Box>{syncTime.toString()}</Box> : <EmptyCell />;
        },
        size: 100,
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return possession.companyId ? (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <IconButton
                sx={tableIconButtonSx}
                onClick={() => {
                  setIsEditDrawerOpen(true);
                }}
              >
                {'...'}
              </IconButton>
            </Box>
          ) : (
            <EmptyCell />
          );
        },
        size: 100,
      },
    ];
  }, [userNames, sitesById, companiesNames]);

  const refresh = useCallback(async () => {
    try {
      const devicePossessions = superAdminDevicesResponseDto?.devicePossessions?.items ?? [];
      setDevicePossessions(devicePossessions);

      setDevicePossessionToBeEdited(devicePossessions.find((d) => d.id === devicePossessionToBeEdited?.id) ?? null);
    } catch (error) {
      showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, 'error');
    }
  }, [devicePossessionToBeEdited?.id, showMessage, superAdminDevicesResponseDto?.devicePossessions?.items]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await refresh();
      } catch (error) {
        showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, 'error');
      } finally {
        setLoading(false);
      }
    })();
  }, [refresh, showMessage]);
  const getCompanyNameById = useCallback(
    (companyId: number | null): string | undefined => {
      if (!companyId) return undefined;
      return companies.find((company) => company.companyId === companyId)?.name;
    },
    [companies]
  );

  const formatDevicePossessionForCsv = (devicePossessions: DevicePossessionDto[]) =>
    devicePossessions.map((devicePossession) => ({
      possessionId: devicePossession.id,
      deviceId: devicePossession?.device?.id,
      company: getCompanyNameById(devicePossession.companyId) ?? devicePossession.possessionType,
      ownedBy: getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sitesById),
      serialNumber: devicePossession.device?.serialNumber || '',
      modelName: devicePossession.device?.modelName || '',
      isEnrolled: devicePossession.device?.enrollmentStatus === 'enrolled',
      lastCheckIn: devicePossession.device?.lastCheckIn
        ? timeSince(new Date(devicePossession.device?.lastCheckIn))
        : '',
      price: `£${devicePossession.device?.price}`,
      contractLength: devicePossession.device?.contractLength
        ? `${devicePossession.device?.contractLength} months`
        : '',
    }));
  return (
    <BackofficeRootStyle>
      <TopHeader title="Devices" />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={loading} secondLevel>
        {devicePossessions.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', ...spacing.mb20, gap: 2 }}>
              <TabFilterButtons
                filters={[
                  { name: 'All', value: 'all' },
                  { name: 'Mac', value: 'macos' },
                  { name: 'IPhone', value: 'ios' },
                  { name: 'IPad', value: 'ipados' },
                ]}
                filterValue={filterString}
                setFilterValue={setFilterString}
                hasSearch
                onFilterChange={({ filterValue, searchInput }) => {
                  setFilterString(filterValue);
                  setSearchInput(searchInput);
                }}
              />
            </Box>
            <CsvDownloader
              filename="inhouse_mdm_devices"
              separator=","
              datas={() => (formatDevicePossessionForCsv(filterDevicePossessions(tableData)) as unknown) as Datas}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.g5,
                  ...secondaryCTABtn,
                  cursor: 'pointer',
                  height: '20px',
                }}
              >
                <Export {...iconSize} />
                <Typography variant="caption">Export</Typography>{' '}
              </Box>
            </CsvDownloader>
          </Box>
        )}

        <BasicTable<DevicePossessionDto>
          rowData={tableData}
          columnData={columnData}
          rowClick={(row) => {
            setDevicePossessionToBeEdited(row.original);
            setIsEditDrawerOpen(true);
          }}
        />

        {/* TODO: as companyId can be null as well, for now allow only possessions with a valid company assigned.
             If we need to open this drawer for stock devices too, then we should use replacers for companyId (e.g.: 0) and companyName (e.g.: ZeltStock)*/}
        {devicePossessionToBeEdited?.companyId && (
          <SuperAdminOverviewInHouseMdmDeviceDrawer
            isOpen={isEditDrawerOpen}
            setIsOpen={setIsEditDrawerOpen}
            devicePossession={devicePossessionToBeEdited}
            refresh={refresh}
            companyName={companiesNames[devicePossessionToBeEdited.companyId]}
          />
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
