import {
  CreateOnboardingTemplate,
  OnboardingTemplate,
  OnboardingUser,
  OnboardingUserState,
} from '@shared/modules/onboarding/onboarding';
import axios from 'axios';

import {
  CheckCountry,
  CheckPackage,
  IDVerificationResultDto,
  ZincIDCheckPackage,
} from '@/v2/feature/onboarding/onboarding-items/id-verify/id-verification.interface';
import {
  AppUsedInOnboardingResult,
  CreateUserOnboarding,
  OnboardingStateInit,
  UpdateUserOnboarding,
} from '@/v2/feature/user-onboarding/by-admin/interface/onboarding.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class OnboardingAPI {
  static async createOnboardingTemplate(values: CreateOnboardingTemplate): Promise<OnboardingTemplate> {
    return (await axios.post('/apiv2/onboarding/template', values)).data;
  }

  static async getOnboardingTemplates(): Promise<OnboardingTemplate[]> {
    return (await axios.get('/apiv2/onboarding/template')).data;
  }

  static updateOnboardingTemplate = async (value: OnboardingTemplate): Promise<OnboardingTemplate> => {
    return (await axios.patch('/apiv2/onboarding/template', value)).data;
  };

  static deleteOnboardingTemplate = async (templateId: number): Promise<void> => {
    await axios.delete(`/apiv2/onboarding/template/${templateId}`);
  };

  static getOnboardingUser = async (userId: number): Promise<OnboardingUserState | null> => {
    return (await axios.get(`/apiv2/onboarding/users/${userId}`)).data || null;
  };

  static async assignTemplateTasksToUser(userId: number): Promise<void> {
    return (await axios.post(`/apiv2/onboarding/tasks/${userId}`)).data;
  }

  static async getInitOnboardingState(userId: number): Promise<OnboardingStateInit> {
    return (await axios.get(`/apiv2/onboarding/users/${userId}/init-state`)).data || null;
  }

  static createUserOnboarding = async (
    values: CreateUserOnboarding,
    refreshUserCache: () => Promise<void>
  ): Promise<number> => {
    const userId = (await axios.post('/apiv2/users-onboarding/create-user', values)).data;
    // always make sure we refresh the cache when a new user is created
    await refreshUserCache();
    return userId;
  };

  static updateUserOnboarding = async (
    values: UpdateUserOnboarding,
    refreshUserCache: () => Promise<void>
  ): Promise<number> => {
    const userId = (await axios.patch('/apiv2/users-onboarding/update-user', values)).data;
    // always make sure we refresh the cache when a new user is updated
    await refreshUserCache();
    return userId;
  };

  static onboardingSteps = async (): Promise<OnboardingStep[]> => {
    return (await axios.get('/apiv2/users-onboarding/onboarding-steps')).data;
  };

  static onboardingMissingFields = async (): Promise<OnboardingMissingFields> => {
    return (await axios.get('/apiv2/users-onboarding/missing-fields')).data;
  };

  static async initiateIdentityChecks(userId: number): Promise<IDVerificationResultDto> {
    // this should be called only from Onboarding page when there is no need to select a country.
    // if package requires a country to be selected, either update this endpoint or user /id-check/${userId}/profile
    return (await axios.post(`/apiv2/onboarding/id-check/${userId}`)).data;
  }

  static checkPasswordExists = async (userId: number): Promise<boolean> => {
    return (await axios.get(`/apiv2/users-onboarding/password-exist/${userId}`)).data;
  };

  static async initiateIdentityChecksFromUserProfile(
    userId: number,
    packageForIdCheck: CheckPackage,
    checkCountry?: CheckCountry
  ): Promise<IDVerificationResultDto> {
    return (await axios.post(`/apiv2/onboarding/id-check/${userId}/profile/${packageForIdCheck.id}`, { checkCountry }))
      .data;
  }

  static async refreshIdentityCheckResult(userId: number): Promise<IDVerificationResultDto[]> {
    return (await axios.get(`/apiv2/onboarding/id-check/${userId}/result`)).data;
  }

  static async downloadIdCheckReport(
    state: IDVerificationResultDto
  ): Promise<{ file: { data: Buffer }; name: string }> {
    return (await axios.post(`/apiv2/onboarding/id-check-download-result`, state)).data;
  }

  static isChecklistCurrentlyUsedInOnboarding = async (checklistId: number): Promise<boolean> => {
    return (await axios.get(`/apiv2/onboarding/is-checklist-used/${checklistId}`)).data;
  };
}

export class OnboardingEndpoints {
  static getOnboardingUsers(): Endpoint<OnboardingUser[]> {
    return {
      url: '/apiv2/onboarding/users',
    };
  }

  static isAppCurrentlyUsedInOnboarding(appStub: string): Endpoint<AppUsedInOnboardingResult> {
    return {
      url: `/apiv2/onboarding/is-app-used/${appStub}`,
    };
  }

  static isContractTemplateCurrentlyUsedInOnboarding(contractTemplateId: string): Endpoint<boolean> {
    return {
      url: `/apiv2/onboarding/is-contract-template-used/${contractTemplateId}`,
    };
  }

  static isIdCheckPackageCurrentlyUsedInOnboarding(packageId: number): Endpoint<boolean> {
    return {
      url: `/apiv2/onboarding/is-id-check-package-used/${packageId}`,
    };
  }

  static isChecklistCurrentlyUsedInOnboarding(checklistId: number): Endpoint<boolean> {
    return {
      url: `/apiv2/onboarding/is-checklist-used/${checklistId}`,
    };
  }

  static getIDCheckPackages(): Endpoint<{
    packages: CheckPackage[];
    internationalSurcharge: number;
    internationalSupport: ZincIDCheckPackage[];
  }> {
    return {
      url: '/apiv2/onboarding/id-check/packages',
    };
  }

  static getUserMissingFields(): Endpoint<OnboardingMissingFields> {
    return {
      url: '/apiv2/users-onboarding/missing-fields',
    };
  }

  static getIDCheckResult(userId: number): Endpoint<IDVerificationResultDto[]> {
    return {
      url: `/apiv2/onboarding/id-check/${userId}/result`,
    };
  }
}
