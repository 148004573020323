import React, { useContext, useEffect, useRef } from 'react';

import styled from '@emotion/styled';
import { Box, BoxProps, ListItemIcon } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Lottie from 'lottie-react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { CurrentUser } from '@/models';
import { Typography } from '@/v2/components/typography/typography.component';
import { getActiveSub } from '@/v2/feature/app-layout/features/sidebar/super-admin-sidebar-config.util';
import { RoleTypes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';
import { getRolesByDomain, getSidebarConfig } from '@/v2/feature/app-layout/features/v2/sidebar-config.util';
import { NavItemStyle } from '@/v2/styles/list-button.styles';

interface Props extends BoxProps {
  readonly navConfig: readonly NavConfigItem[];
  readonly setActiveParent: (val: string | undefined) => void;
  readonly activeParent: string | undefined;
  readonly setShowSecondMenu: (val: boolean) => void;
  setActiveRole: (val: RoleTypes) => void;
}

export interface SubMenuProps {
  readonly title: string;
  readonly stub: string;
  readonly path?: string;
  readonly hasChildren: boolean;
  readonly isHidden: boolean;
}

export interface SubItemsProps {
  readonly title: string;
  readonly stub: string;
  readonly path?: string;
  readonly hasChildren: boolean;
  readonly isHidden: boolean;
  readonly subMenu?: SubMenuProps[];
  readonly deprecated?: boolean;
}

export interface NavConfigItem {
  readonly title: string;
  readonly stub: string;
  readonly path?: string;
  readonly icon?: JSX.Element;
  readonly iconActive?: JSX.Element;
  readonly isHidden: boolean;
  readonly hasChildren: boolean;
  readonly alerts?: number;
  readonly subItems?: SubItemsProps[];
  readonly loopAnimation?: unknown;
  readonly loopFilledAnimation?: unknown;
}

interface NavItemProps {
  readonly item: NavConfigItem;
  readonly setActiveParent: (val: string | undefined) => void;
  readonly activeParent: string | undefined;
  readonly setShowSecondMenu: (val: boolean) => void;
  setActiveRole: (val: RoleTypes) => void;
  user: CurrentUser;
}

const LottieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  height: 1.875rem;
`;

export const NavItem = ({
  item,
  setActiveParent,
  activeParent,
  setShowSecondMenu,
  setActiveRole,
  user,
}: NavItemProps): React.JSX.Element | null => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);

  const { pathname } = useLocation();
  const routerHistory = useHistory();
  const { isHidden, stub, path, hasChildren, title, loopAnimation, loopFilledAnimation } = item;

  const lottieRef1 = useRef<any>(null);
  const lottieRef2 = useRef<any>(null);

  useEffect(() => {
    lottieRef1.current?.setSpeed(1.3);
    lottieRef2.current?.setSpeed(1.3);
  }, []);

  const handleItemClick = () => {
    setActiveParent(stub);
    const roles = getRolesByDomain(stub, user);
    if (roles && roles.length > 0) setActiveRole(roles[0] as RoleTypes);

    if (!hasChildren && path) {
      handleDirectNavigation(path);
    } else if (roles && roles.length > 0) {
      handleNestedNavigation(stub, roles[0] as RoleTypes);
    }
  };

  const handleDirectNavigation = (path: string) => {
    setShowSecondMenu(false);
    routerHistory.push(path);
  };

  const handleNestedNavigation = (stub: string, role: RoleTypes) => {
    const config = getSidebarConfig(user, role, polyglot, !!state.showHiddenFeatures);
    const targetItem = config.find((c) => c.stub === stub);
    const firstChild = targetItem?.subItems?.find((s) => !s.isHidden);
    if (firstChild?.hasChildren) {
      handleChildWithSubMenu(firstChild);
    } else if (firstChild?.path) {
      setShowSecondMenu(true);
      routerHistory.push(firstChild?.path);
    }
  };

  const handleChildWithSubMenu = (firstChild: SubItemsProps) => {
    setShowSecondMenu(true);
    const firstSubMenu = firstChild.subMenu?.find((a) => !a.isHidden && a.path && !a.hasChildren);
    if (firstSubMenu && firstSubMenu.path) routerHistory.push(firstSubMenu.path);
  };

  if (isHidden) return null;

  return (
    <NavItemStyle
      onClick={handleItemClick}
      key={stub}
      component={RouterLink}
      // @ts-ignore
      to={item.path}
      onMouseOver={() => {
        lottieRef1.current?.play();
        lottieRef2.current?.play();
      }}
      onMouseEnter={() => {
        lottieRef1.current?.play();
        lottieRef2.current?.play();
      }}
      onMouseLeave={() => {
        lottieRef1.current?.stop();
        lottieRef2.current?.stop();
      }}
    >
      <ListItemIcon sx={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
        <LottieContainer
          style={{
            display: activeParent === stub || (path && getActiveSub(pathname, path)) ? 'flex' : 'none',
          }}
        >
          <Lottie
            lottieRef={lottieRef2}
            animationData={loopFilledAnimation}
            loop={true}
            autoplay={false}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </LottieContainer>

        <LottieContainer
          style={{
            display: activeParent === stub || (path && getActiveSub(pathname, path)) ? 'none' : 'flex',
          }}
        >
          <Lottie
            lottieRef={lottieRef1}
            animationData={loopAnimation}
            loop={true}
            autoplay={false}
            rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          />
        </LottieContainer>
      </ListItemIcon>
      <Typography variant={activeParent === stub || (path && getActiveSub(pathname, path)) ? 'title5' : 'captionSmall'}>
        {title}
      </Typography>
    </NavItemStyle>
  );
};
export const NavigationItem = ({
  navConfig,
  setActiveParent,
  activeParent,
  setShowSecondMenu,
  setActiveRole,
}: Props) => {
  const [state] = useContext(GlobalContext);
  const { user } = state;

  return (
    <Box>
      {navConfig.map((list: NavConfigItem) => (
        <NavItem
          key={list.title}
          item={list}
          setActiveParent={setActiveParent}
          activeParent={activeParent}
          setShowSecondMenu={setShowSecondMenu}
          setActiveRole={setActiveRole}
          user={user}
        />
      ))}
    </Box>
  );
};
