import React, { useContext, useState } from 'react';

import { Box } from '@mui/material';
import { CheckboxComponent } from '@v2/components/forms/checkbox.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DeviceDto, DeviceTechSpecs } from '@v2/feature/device/device.dto';
import { AssignDeviceToABM } from '@v2/feature/device/device.interface';
import {
  DeviceOSValueLabelOptions,
  DeviceTypesValueLabelOptions,
  ManufacturerValueLabelOptions,
} from '@v2/feature/device/device.util';
import { useDepAccountDetails } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/components/dep/dep-config.hook';
import { InHouseMdmAPI } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface EditTechSpecsDrawerContentProps {
  readonly device: DeviceDto;
  readonly refresh: () => Promise<void>;
  readonly devicePossessionId: number;
}

export const EditTechSpecsDrawerContent = ({
  device,
  devicePossessionId,
  refresh,
}: EditTechSpecsDrawerContentProps): React.JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const [globalState] = useContext(GlobalContext);
  const { depAccountDetails } = useDepAccountDetails(
    globalState.user.company.companyId + globalState.user.company.name
  );
  const initialValues: DeviceTechSpecs = {
    type: device.type,
    deviceName: device.deviceName,
    modelName: device.modelName,
    modelNumber: device.modelNumber,
    manufacturer: device.manufacturer,
    os: device.os,
    ram: device.ram,
    storage: device.storage,
    screenSize: device.screenSize,
    cpu: device.cpu,
    gpu: device.gpu,
    serialNumber: device.serialNumber,
    internalNotes: device.internalNotes,
    customerNotes: device.customerNotes,
    isDep: device.isDep ?? false,
    depDeviceUsername: device.depDeviceUsername ?? null,
  };

  const validationSchema = yup.object({
    type: yup.string().required('You must specify a value.'),
    modelName: yup.string().required('You must specify a value.'),
    deviceName: yup.string().nullable().notRequired(),
    modelNumber: yup.string().nullable().notRequired(),
    manufacturer: yup.string().nullable().notRequired(),
    os: yup.string().nullable().notRequired(),
    ram: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    storage: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    screenSize: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    cpu: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    gpu: yup.number().nullable().typeError('You must specify a number.').notRequired(),
    serialNumber: yup.string().nullable().notRequired(),
    customerNotes: yup.string().nullable().notRequired(),
    internalNotes: yup.string().nullable().notRequired(),
    isDep: yup.boolean().nullable().notRequired(),
    depDeviceUsername: yup.string().nullable().notRequired(),
  });

  const updateDeviceTechSpecs = async (deviceUpdate: DeviceTechSpecs) => {
    try {
      setLoading(true);
      const update = {
        type: deviceUpdate.type,
        deviceName: deviceUpdate.deviceName,
        modelName: deviceUpdate.modelName,
        modelNumber: deviceUpdate.modelNumber ?? null,
        os: deviceUpdate.os && deviceUpdate.os !== 'none' ? deviceUpdate.os : null,
        manufacturer: deviceUpdate.manufacturer ?? null,
        serialNumber: deviceUpdate.serialNumber ?? null,
        ram: deviceUpdate.ram ? Number(deviceUpdate.ram) : null,
        storage: deviceUpdate.storage ? Number(deviceUpdate.storage) : null,
        screenSize: deviceUpdate.screenSize ? Number(deviceUpdate.screenSize) : null,
        cpu: deviceUpdate.cpu ? Number(deviceUpdate.cpu) : null,
        gpu: deviceUpdate.gpu ? Number(deviceUpdate.gpu) : null,
        internalNotes: deviceUpdate.internalNotes ?? null,
        customerNotes: deviceUpdate.customerNotes ?? null,
        isDep: deviceUpdate.isDep ?? false,
        depDeviceUsername: deviceUpdate.isDep ? deviceUpdate.depDeviceUsername : null,
      };
      if (depAccountDetails && deviceUpdate.isDep) {
        if (deviceUpdate.depDeviceUsername && deviceUpdate.serialNumber) {
          const depEvent: AssignDeviceToABM = {
            depDeviceUsername: deviceUpdate.depDeviceUsername,
            depTokenName: globalState.user.company.companyId + globalState.user.company.name,
            deviceId: device.id,
            serialNumber: deviceUpdate.serialNumber,
          };
          await InHouseMdmAPI.assignDeviceToABM(depEvent);
        } else {
          showMessage(`Serial number and device username have to be provided to assign to DEP`, 'error');
          return;
        }
      }
      await DeviceAPI.updateDeviceTechSpecsByPossessionId(devicePossessionId, update);
      showMessage('Device successfully updated.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<DeviceTechSpecs>({
    initialValues,
    validationSchema,
    onSubmit: async (values: DeviceTechSpecs) => {
      await updateDeviceTechSpecs(values);
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">Edit tech specs</Typography>

        <SelectComponent
          name="type"
          label="Type"
          options={DeviceTypesValueLabelOptions}
          value={formik.values.type ?? undefined}
          compareValue={formik.values.type ?? undefined}
          onChange={formik.handleChange}
          error={formik.touched.type && !!formik.errors.type}
          helperText={formik.touched.type && formik.errors.type}
        />

        <TextfieldComponent
          label="Device Name"
          name="deviceName"
          value={formik.values.deviceName}
          onChange={formik.handleChange}
          error={formik.touched.deviceName && Boolean(formik.errors.deviceName)}
          helperText={formik.touched.deviceName && formik.errors.deviceName}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="Model Number"
          name="modelNumber"
          value={formik.values.modelNumber}
          onChange={formik.handleChange}
          error={formik.touched.modelNumber && Boolean(formik.errors.modelNumber)}
          helperText={formik.touched.modelNumber && formik.errors.modelNumber}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="Serial Number"
          name="serialNumber"
          value={formik.values.serialNumber}
          onChange={formik.handleChange}
          error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
          helperText={formik.touched.serialNumber && formik.errors.serialNumber}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <SelectComponent
          name="manufacturer"
          label="Manufacturer"
          options={ManufacturerValueLabelOptions}
          value={formik.values.manufacturer ?? null}
          compareValue={formik.values.manufacturer ?? null}
          error={!!formik.errors.manufacturer && formik.touched.manufacturer}
          onChange={formik.handleChange}
          helperText={formik.touched.manufacturer && formik.errors.manufacturer}
        />

        <SelectComponent
          name="os"
          label="OS"
          options={[{ label: 'None', value: 'none' }, ...DeviceOSValueLabelOptions]}
          value={formik.values.os ?? undefined}
          compareValue={formik.values.os ?? undefined}
          error={!!formik.errors.os && formik.touched.os}
          onChange={formik.handleChange}
          helperText={formik.touched.os && formik.errors.os}
        />

        <TextfieldComponent
          label="RAM (GB)"
          name="ram"
          value={formik.values.ram}
          onChange={formik.handleChange}
          error={formik.touched.ram && !!formik.errors.ram}
          helperText={formik.touched.ram && formik.errors.ram}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="CPU Cores"
          name="cpu"
          value={formik.values.cpu}
          onChange={formik.handleChange}
          error={formik.touched.cpu && Boolean(formik.errors.cpu)}
          helperText={formik.touched.cpu && formik.errors.cpu}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="GPU Cores"
          name="gpu"
          value={formik.values.gpu}
          onChange={formik.handleChange}
          error={formik.touched.gpu && Boolean(formik.errors.gpu)}
          helperText={formik.touched.gpu && formik.errors.gpu}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="Storage (GB)"
          name="storage"
          value={formik.values.storage}
          onChange={formik.handleChange}
          error={formik.touched.storage && Boolean(formik.errors.storage)}
          helperText={formik.touched.storage && formik.errors.storage}
          fullWidth
          size="small"
          endAdornment="none"
        />

        <TextfieldComponent
          label="Screen Size (inch)"
          name="screenSize"
          value={formik.values.screenSize}
          onChange={formik.handleChange}
          error={formik.touched.screenSize && Boolean(formik.errors.screenSize)}
          helperText={formik.touched.screenSize && formik.errors.screenSize}
          fullWidth
          size="small"
          endAdornment="none"
        />

        {depAccountDetails && (
          <CheckboxComponent
            name="isDep"
            label="Device is added in the connected Apple Business Manager"
            checked={formik.values.isDep ?? undefined}
            onChange={formik.handleChange}
          />
        )}

        {depAccountDetails && formik.values.isDep && (
          <TextfieldComponent
            label="Device username"
            name="depDeviceUsername"
            value={formik.values.depDeviceUsername}
            onChange={formik.handleChange}
            error={formik.touched.depDeviceUsername && Boolean(formik.errors.depDeviceUsername)}
            helperText={formik.touched.depDeviceUsername && formik.errors.depDeviceUsername}
            size="small"
            endAdornment="none"
            placeholder="TestAccount"
          />
        )}

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton name="Save" loading={loading} fullWidth colorVariant="primary" sizeVariant="medium" />
        </Box>
      </Form>
    </FormikProvider>
  );
};
