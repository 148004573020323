import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { SwitchButton } from '@v2/components/split-switch-button.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

import { Typography } from '@/v2/components/typography/typography.component';
import { RolesOptions, RoleTypes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';
import { radius } from '@/v2/styles/radius.styles';

import '@/v2/scss/components/top-navigation-menu.scss';

export const TopNavigationMenu = ({
  roles,
  setActiveRole,
  activeRole,
}: {
  roles: string[];
  setActiveRole: (val: RoleTypes) => void;
  activeRole: string | undefined;
}): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const showRoles = useMemo(() => roles.length > 1, [roles]);

  return showRoles ? (
    <Box
      sx={{
        width: '100%',
        height: '30px',
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {roles.map((r) => (
        <SwitchButton
          key={r}
          isActive={r === activeRole}
          color={themeColors.DarkGrey}
          action={() => setActiveRole(r as RoleTypes)}
        >
          <Typography
            variant="caption"
            sx={{ color: r === activeRole ? themeColors.white : themeColors.DarkGrey }}
            className={r === activeRole ? 'tnm-switch-active-text' : 'tnm-switch-text'}
          >
            {RolesOptions(polyglot)[r as RoleTypes]}
          </Typography>
        </SwitchButton>
      ))}
    </Box>
  ) : (
    <></>
  );
};
