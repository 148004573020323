import { useCallback, useContext, useMemo, useState } from 'react';

import { Box, Stack, SxProps, Theme } from '@mui/material';
import {
  AddToPayrollResult,
  CompanyPayroll,
  PayRunSchedule,
  RemoveFromPayrollResult,
  UserPayrollForTableDto,
} from '@shared/modules/payroll/payroll.types';
import { ColumnDef } from '@tanstack/react-table';
import { DEFAULT_CURRENCY } from '@v2/feature/payments/payments.interface';
import {
  CompensationBreakdown,
  SalaryBasisEnum,
} from '@v2/feature/user/features/user-forms/user-compensation/user-compensation.dto';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { UserLifecycleStatuses } from '@/models';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { AuthAPI } from '@/v2/feature/auth/auth.api';
import { UserPayrollStatusCell } from '@/v2/feature/payroll/components/user-payroll-status-cell.component';
import {
  formatPayPeriodIncome,
  formatSalaryBasis,
  formatUserPayrollStatus,
} from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { PayrollUserActionMenu } from '@/v2/feature/payroll/features/payroll-global/global-payroll-payruns/payroll-user-action-menu.component';
import { EditPayrollRecordDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/edit-payroll-record-drawer.component';
import { EmployeeActionsCell } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/employee-actions-column.component';
import { PayrollMissingInformationDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/missing-information/payroll-missing-information.component';
import { ViewUserPayrollDrawer } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/components/view-user-payroll-drawer.component';
import {
  canAddToPayroll,
  canRemoveFromPayroll,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-employees/payroll-company-employees.util';
import {
  getUserStatusFromUserPayrollForTableEntry,
  PayrollStatusLabel,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-uk.util';
import { CurrencyWithDiff } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/value-with-diff.component';
import { PayrollLocalEndpoints } from '@/v2/feature/payroll/payroll-local.api';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { ApiError } from '@/v2/infrastructure/api-error/api-error.interface';
import { getApiErrorMessage } from '@/v2/infrastructure/api-error/api-error.util';
import { isSameCountryCode } from '@/v2/infrastructure/country/country.util';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { filterByTextSearch } from '@/v2/util/array.util';
import { formatCurrency } from '@/v2/util/currency-format.util';

type UpcomingPayrunTableProps = {
  payroll: CompanyPayroll;
  nextPayrun: PayRunSchedule;
  searchQuery?: string;
  setSearchQuery: (query: string) => void;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  refreshPayroll: () => Promise<void>;
  selectedUsers?: number[];
  setSelectedUsers?: (users: number[]) => void;
  noCurrencySymbol: boolean;
  stickyHeader?: boolean;
};

export const UpcomingPayrunTable = ({
  payroll,
  nextPayrun,
  searchQuery,
  setSearchQuery,
  sx,
  disabled,
  refreshPayroll,
  selectedUsers,
  setSelectedUsers,
  noCurrencySymbol,
  stickyHeader,
}: UpcomingPayrunTableProps) => {
  const { polyglot } = usePolyglot();
  const [_globalState, dispatch] = useContext(GlobalContext);
  const { getCachedUserById } = useCachedUsers();

  const [showMessage] = useMessage();
  const [drawer, setDrawer] = useState<{ userId: number; mode: 'edit' | 'view' | 'missing-info' } | null>(null);

  const [usersBeingUpdated, setUsersBeingUpdated] = useState(new Set<number>());
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);

  const {
    data: rawPayrollList,
    mutate: refreshPayrollUsers,
    isValidating: loadingPayrollUsers,
  } = useApiClient(PayrollLocalEndpoints.getUserPayrollMembershipList(), { suspense: false });
  const employeeList = useMemo(() => rawPayrollList?.all ?? [], [rawPayrollList?.all]);

  const drawerRecord = useMemo(() => employeeList.find((e) => e.userId === drawer?.userId), [
    drawer?.userId,
    employeeList,
  ]);

  const refreshPayrollState = useCallback(async () => {
    await Promise.all([refreshPayroll(), refreshPayrollUsers?.()]);
  }, [refreshPayroll, refreshPayrollUsers]);

  const getUserDisplayName = useCallback(
    (userId: number) => {
      const user = getCachedUserById(userId);
      if (user) return UserCell.getDisplayedName(user);
      return `(User ${userId})`;
    },
    [getCachedUserById]
  );

  const markUserUpdating = useCallback((userIds: number[], isUpdating: boolean) => {
    setUsersBeingUpdated((currentUserIds) => {
      return new Set(
        isUpdating ? [...currentUserIds, ...userIds] : [...currentUserIds].filter((userId) => !userIds.includes(userId))
      );
    });
  }, []);

  const contractCountryMatchesUserPayrollCountry = useCallback((record: UserPayrollForTableDto) => {
    return isSameCountryCode(record.payrollJurisdiction ?? 'GB', record.userPayroll?.countryCode ?? 'GB');
  }, []);

  const refreshBillingRestrictions = useCallback(async () => {
    // this is used to refresh the billing restrictions when payroll users are added/removed
    const response = await AuthAPI.getAuthMe(false);
    const authUser = response?.user ?? null;
    dispatch({
      type: GlobalStateActions.UPDATE_USER,
      payload: authUser,
    });
  }, [dispatch]);

  const addToPayroll = useCallback(
    async (userIds: number[]): Promise<boolean> => {
      if (!userIds.length) return true;
      // OLD RESTRICTION
      // if (currentUser?.restrictions?.MONEY?.disablePayroll) {
      //   setUpgradeModalOpen(true);
      //   return false;
      // }
      let userAddedToPayroll: AddToPayrollResult | null = null;
      markUserUpdating(userIds, true);
      try {
        userAddedToPayroll = await PayrollAPI.addUsersToPayroll(payroll.id, userIds);
        if (userAddedToPayroll.failed.length === 0) {
          showMessage(
            polyglot.t('PayrunTable.usersAddedToPayroll', { smart_count: userAddedToPayroll.added.length }),
            'success'
          );
        } else {
          const failureList = userAddedToPayroll.failed.map((f) => `${f.name}: ${f.reason}`).join('\n');
          showMessage(polyglot.t('PayrunTable.usersNotAddedToPayroll', { reason: failureList }), 'warning');
        }
      } catch (error) {
        if (doesErrorRequireCompanyToUpgrade(error)) {
          setUpgradeModalOpen(true);
        } else {
          showMessage(getApiErrorMessage(error as ApiError), 'warning');
        }
      } finally {
        markUserUpdating(userIds, false);
      }
      refreshPayrollState?.();
      refreshBillingRestrictions();
      return userAddedToPayroll?.failed.length === 0;
    },
    [payroll.id, markUserUpdating, refreshBillingRestrictions, refreshPayrollState, showMessage, polyglot]
  );

  const removeFromPayroll = useCallback(
    async (userIds: number[]): Promise<boolean> => {
      if (!userIds.length) return true;
      let userRemovedFromPayroll: RemoveFromPayrollResult | null = null;
      markUserUpdating(userIds, true);
      try {
        userRemovedFromPayroll = await PayrollAPI.removeUsersFromPayroll(payroll.id, userIds);
        if (userRemovedFromPayroll.failed.length === 0) {
          showMessage(
            polyglot.t('PayrunTable.usersRemovedToPayroll', { smart_count: userRemovedFromPayroll.removed.length }),
            'success'
          );
        } else {
          const failureList = userRemovedFromPayroll.failed.map((f) => `${f.name}: ${f.reason}`).join('\n');
          showMessage(polyglot.t('PayrunTable.usersNotRemovedFromPayroll', { reason: failureList }), 'warning');
        }
      } catch (error) {
        showMessage(getApiErrorMessage(error as ApiError), 'warning');
      } finally {
        markUserUpdating(userIds, false);
      }
      refreshPayrollState?.();
      refreshBillingRestrictions();
      return userRemovedFromPayroll?.failed.length === 0;
    },
    [payroll.id, markUserUpdating, refreshBillingRestrictions, refreshPayrollState, showMessage, polyglot]
  );

  const rows = useMemo(() => {
    const result = [] as UserPayrollForTableDto[];
    // add any remaining payroll users who are in the same company entity
    for (const payrollUser of employeeList) {
      const { entityId, user } = payrollUser;
      const paySchedule = payrollUser.compensationBreakdown?.paySchedule;
      // exclude users from other company entities
      if (entityId !== payroll.entity.id) continue;

      // exclude terminated users
      if (user.status === UserLifecycleStatuses.Terminated && user.leaveDate && nextPayrun.startDate > user.leaveDate)
        continue;
      // exclude users who start after the upcoming payrun
      if (user.startDate && user.startDate > nextPayrun.endDate) continue;
      // exclude users whose compensation paySchedule is different from the payroll payPeriod
      if (paySchedule && paySchedule !== nextPayrun.payPeriod) continue;
      result.push(payrollUser);
    }

    const statusOrder: PayrollStatusLabel[] = [
      PayrollStatusLabel.NotInPayroll,
      PayrollStatusLabel.NewJoiner,
      PayrollStatusLabel.Leaver,
      PayrollStatusLabel.Current,
    ];

    return result.sort((a, b) => {
      const [aStatus, bStatus] = [
        getUserStatusFromUserPayrollForTableEntry(a, nextPayrun).label,
        getUserStatusFromUserPayrollForTableEntry(b, nextPayrun).label,
      ];
      return (
        statusOrder.indexOf(aStatus) - statusOrder.indexOf(bStatus) ||
        getUserDisplayName(a.userId).localeCompare(getUserDisplayName(b.userId), polyglot.locale(), {
          sensitivity: 'base',
        })
      );
    });
  }, [employeeList, getUserDisplayName, payroll.entity.id, nextPayrun, polyglot]);

  const filteredRows = useMemo(() => {
    return filterByTextSearch(searchQuery, rows, (user) => [getUserDisplayName(user.userId)]);
  }, [getUserDisplayName, searchQuery, rows]);

  const salaries = useMemo(() => {
    const result = {
      byUserId: new Map<number, CompensationBreakdown>(),
      totalSalary: 0,
    };
    for (const user of rows) {
      if (!user.compensationBreakdown) continue;
      result.byUserId.set(user.userId, user.compensationBreakdown);
      result.totalSalary += user.compensationBreakdown.payScheduleRate;
    }
    return result;
  }, [rows]);

  const selectedUserIds = useMemo(() => new Set(selectedUsers), [selectedUsers]);

  const updateSelectedUsers = useCallback(
    ({ select, deselect }: { select?: number[]; deselect?: number[] }) => {
      const updatedUserIds = new Set(selectedUserIds);
      select?.forEach((userId) => updatedUserIds.add(userId));
      deselect?.forEach((userId) => updatedUserIds.delete(userId));
      setSelectedUsers?.([...updatedUserIds]);
    },
    [selectedUserIds, setSelectedUsers]
  );

  const columnData = useMemo<ColumnDef<UserPayrollForTableDto, UserPayrollForTableDto>[]>(() => {
    return [
      {
        id: 'employee',
        header: () => (
          <Stack sx={{ flexFlow: 'row', alignItems: 'center' }}>
            <div onClick={(e) => e.stopPropagation() /* don't allow checkbox clicks to activate column sorting */}>
              {setSelectedUsers && (
                <CheckboxComponent
                  checked={filteredRows.length > 0 && filteredRows.every(({ userId }) => selectedUserIds.has(userId))}
                  disabled={filteredRows.length === 0}
                  onChange={(_, checked) => {
                    const filteredUserIds = filteredRows.map(({ userId }) => userId);
                    if (checked) updateSelectedUsers({ select: filteredUserIds });
                    else updateSelectedUsers({ deselect: filteredUserIds });
                  }}
                />
              )}
            </div>
            {polyglot.t('PayrunTable.employee')}
          </Stack>
        ),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getUserDisplayName(item.userId)),
        cell: (c) => (
          <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
            {setSelectedUsers && (
              <CheckboxComponent
                checked={selectedUserIds.has(c.row.original.userId)}
                onChange={(_, checked) => {
                  const { userId } = c.row.original;
                  if (checked) updateSelectedUsers({ select: [userId] });
                  else updateSelectedUsers({ deselect: [userId] });
                }}
              />
            )}
            <UserCell userId={c.row.original.userId} />
          </Stack>
        ),
        size: 100,
        footer: () => polyglot.t('PayrunTable.total'),
      },
      {
        id: 'salary-basis',
        header: () => polyglot.t('PayrunTable.salaryBasis'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => formatSalaryBasis(salaries.byUserId.get(item.userId)?.salaryBasis, polyglot)),
        cell: (c) => formatSalaryBasis(salaries.byUserId.get(c.row.original.userId)?.salaryBasis, polyglot),
        size: 60,
      },
      {
        id: 'salary-rate',
        header: () => polyglot.t('PayrunTable.salaryRate'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => salaries.byUserId.get(item.userId)?.rate),
        cell: (c) => {
          const userSalary = salaries.byUserId.get(c.row.original.userId);
          const currency = userSalary?.currency ?? DEFAULT_CURRENCY;
          return formatCurrency(userSalary?.rate, { noCurrencySymbol }, currency);
        },
        size: 80,
      },
      {
        id: 'units',
        header: () => polyglot.t('PayrunTable.units'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.compensationBreakdown?.units || 0),
        cell: (c) => {
          if (
            c.row.original?.compensationBreakdown?.salaryBasis &&
            [SalaryBasisEnum.Daily, SalaryBasisEnum.Hourly].includes(c.row.original.compensationBreakdown.salaryBasis)
          ) {
            return c.row.original.compensationBreakdown.units ?? <EmptyCell />;
          }
          return <EmptyCell />;
        },
        size: 80,
      },
      {
        id: 'earnings',
        header: () => formatPayPeriodIncome(nextPayrun.payPeriod, polyglot),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => salaries.byUserId.get(item.userId)?.payScheduleRate),
        cell: (c) => {
          const userSalary = salaries.byUserId.get(c.row.original.userId);
          const currency = userSalary?.currency ?? DEFAULT_CURRENCY;
          return formatCurrency(userSalary?.payScheduleRate, { noCurrencySymbol }, currency);
        },
        footer: () => <CurrencyWithDiff currentValue={salaries.totalSalary} formatOptions={{ noCurrencySymbol }} />,
        size: 80,
      },
      {
        id: 'status',
        header: () => polyglot.t('PayrunTable.status'),
        accessorFn: (row) => row,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) =>
            formatUserPayrollStatus(getUserStatusFromUserPayrollForTableEntry(item, nextPayrun).label, polyglot)
          ),
        cell: (c) => (
          <UserPayrollStatusCell status={getUserStatusFromUserPayrollForTableEntry(c.row.original, nextPayrun)} />
        ),
        size: 60,
      },
      {
        header: () => '',
        id: 'actions',
        enableSorting: false,
        accessorFn: (row) => row,
        cell: ({ row: { original: payrollUser } }) => {
          return (
            <EmployeeActionsCell
              user={payrollUser}
              payroll={payroll}
              openDrawer={(mode, { userId }) => setDrawer({ userId, mode })}
              disabled={disabled}
              updating={usersBeingUpdated.has(payrollUser.userId)}
              addToPayroll={async (userId) => addToPayroll([userId])}
              removeFromPayroll={async (userId) => removeFromPayroll([userId])}
            />
          );
        },
      },
    ];
  }, [
    polyglot,
    getUserDisplayName,
    selectedUserIds,
    setSelectedUsers,
    salaries,
    nextPayrun,
    payroll,
    disabled,
    usersBeingUpdated,
    addToPayroll,
    removeFromPayroll,
    filteredRows,
    updateSelectedUsers,
    noCurrencySymbol,
  ]);

  return (
    <>
      <Box sx={sx}>
        <Stack
          sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mr: '20px', mb: '20px' }}
        >
          <TableSearch query={searchQuery} handleChange={(e) => setSearchQuery(e.target.value)} />
          {selectedUserIds.size > 0 && (
            <PayrollUserActionMenu
              onAddToPayrollClick={() => {
                addToPayroll([...selectedUserIds]);
              }}
              onRemoveFromPayrollClick={() => {
                removeFromPayroll([...selectedUserIds]);
              }}
            />
          )}
        </Stack>
        <BasicTable
          loading={loadingPayrollUsers && !rawPayrollList}
          rowData={filteredRows}
          columnData={columnData}
          hidePagination
          maxUnpaginatedRows={500}
          showFooter
          initialSort={[{ id: 'employee', desc: true }]}
          stickyHeader={stickyHeader}
        />
      </Box>
      <PayrollMissingInformationDrawer
        isOpen={drawer?.mode === 'missing-info'}
        close={() => setDrawer(null)}
        payrollRecord={drawerRecord}
        refreshPayroll={refreshPayrollState}
      />
      <EditPayrollRecordDrawer
        isOpen={drawer?.mode === 'edit'}
        close={() => setDrawer(null)}
        payrollRecord={
          (drawerRecord && contractCountryMatchesUserPayrollCountry(drawerRecord) && drawerRecord.userPayroll) || null
        }
        mode={
          drawerRecord?.userPayroll && contractCountryMatchesUserPayrollCountry(drawerRecord) ? 'append' : 'initial'
        }
        userId={drawer?.userId}
        onUpdateStarted={() => !!drawer && markUserUpdating([drawer.userId], true)}
        onUpdateFinished={(success) => {
          !!drawer && markUserUpdating([drawer.userId], false);
          if (success) refreshPayrollState?.();
        }}
      />
      <ViewUserPayrollDrawer
        isOpen={drawer?.mode === 'view'}
        close={(options?: { switchToEdit: boolean }) => {
          if (options?.switchToEdit && drawer) {
            setDrawer({ userId: drawer.userId, mode: 'edit' });
            return;
          }
          setDrawer(null);
        }}
        onClose={() => drawer?.mode === 'view' && setDrawer(null)}
        record={drawerRecord || null}
        isUserUpdating={!!drawer && usersBeingUpdated.has(drawer.userId)}
        addToPayroll={
          drawerRecord && canAddToPayroll(drawerRecord, payroll)
            ? (record) => addToPayroll([record.user.userId])
            : undefined
        }
        removeFromPayroll={
          drawerRecord && canRemoveFromPayroll(drawerRecord, payroll)
            ? (record) => removeFromPayroll([record.user.userId])
            : undefined
        }
        canEdit
      />
      <UpgradeToProModal
        isOpen={upgradeModalOpen}
        setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
        planName={PlanNames.MONEY_PRO}
        messageSuffix="proGeneric"
      />
    </>
  );
};
