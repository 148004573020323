import React from 'react';

import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { SuperAdminAllDevicesOverviewPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-all-devices-overview.page';
import { SuperAdminDeviceDetailsPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-device-details.page';
import { SuperAdminDeviceModelsPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-device-models.page';
import { SuperAdminDeviceOrdersPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-device-orders.page';
import { SuperAdminDeviceStockPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-devices-stock.page';
import { SuperAdminDevicesTransitsPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-devices-transits.page';
import { SuperAdminHexnodeDevicesOverviewPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-hexnode-devices-overview.page';
import { SuperAdminInHouseMdmDevicesOverviewPage } from '@v2/feature/super-admin/features/super-admin-devices/pages/super-admin-in-house-mdm-devices-overview.page';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  SETTINGS_DEVICES_ROUTE,
  SUPER_ADMIN_ALL_DEVICES_OVERVIEW_ROUTE,
  SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE,
  SUPER_ADMIN_DEVICE_STOCK_DETAILS_ROUTE,
  SUPER_ADMIN_DEVICES_MODELS_ROUTE,
  SUPER_ADMIN_DEVICES_ORDERS_ROUTE,
  SUPER_ADMIN_DEVICES_ROUTE,
  SUPER_ADMIN_DEVICES_STOCK_ROUTE,
  SUPER_ADMIN_DEVICES_TRANSITS_ROUTE,
  SUPER_ADMIN_HEXNODE_DEVICES_OVERVIEW_ROUTE,
  SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE,
} from '@/lib/routes';

const DEVICES_PAGE_CONFIG = {
  header: {
    settingsPath: SETTINGS_DEVICES_ROUTE,
    tabs: [
      { label: 'Hexnode', value: SUPER_ADMIN_HEXNODE_DEVICES_OVERVIEW_ROUTE, scopes: ['devices'] },
      { label: 'Zelt MDM', value: SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE, scopes: ['devices'] },
      { label: 'All devices', value: SUPER_ADMIN_ALL_DEVICES_OVERVIEW_ROUTE, scopes: ['devices'] },
      { label: 'Inventory', value: SUPER_ADMIN_DEVICES_STOCK_ROUTE, scopes: ['devices'] },
      { label: 'Orders', value: SUPER_ADMIN_DEVICES_ORDERS_ROUTE, scopes: ['devices'] },
      { label: 'Transits', value: SUPER_ADMIN_DEVICES_TRANSITS_ROUTE, scopes: ['devices'] },
      { label: 'Shop', value: SUPER_ADMIN_DEVICES_MODELS_ROUTE, scopes: ['devices'] },
    ],
  },
} as const;

interface SuperAdminDevicesRouterProps {
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly users: readonly UserDetailsSuperAdminDto[];
}

export const SuperAdminDevicesRouter = ({ companies, users }: SuperAdminDevicesRouterProps): React.JSX.Element => {
  const activeCompanies = companies.filter((company) => company.isActive);

  return (
    <Switch>
      <Route path={SUPER_ADMIN_IN_HOUSE_MDM_DEVICES_OVERVIEW_ROUTE}>
        <SuperAdminInHouseMdmDevicesOverviewPage
          users={users}
          companies={activeCompanies}
          pageConfig={DEVICES_PAGE_CONFIG}
        />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_MODELS_ROUTE}>
        <SuperAdminDeviceModelsPage pageConfig={DEVICES_PAGE_CONFIG} />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_ORDERS_ROUTE}>
        <SuperAdminDeviceOrdersPage users={users} companies={companies} pageConfig={DEVICES_PAGE_CONFIG} />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_TRANSITS_ROUTE}>
        <SuperAdminDevicesTransitsPage users={users} companies={activeCompanies} pageConfig={DEVICES_PAGE_CONFIG} />
      </Route>
      <Route path={[SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE, SUPER_ADMIN_DEVICE_STOCK_DETAILS_ROUTE]}>
        <SuperAdminDeviceDetailsPage />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_STOCK_ROUTE}>
        <SuperAdminDeviceStockPage pageConfig={DEVICES_PAGE_CONFIG} />
      </Route>
      <Route path={SUPER_ADMIN_HEXNODE_DEVICES_OVERVIEW_ROUTE}>
        <SuperAdminHexnodeDevicesOverviewPage
          users={users}
          companies={activeCompanies}
          pageConfig={DEVICES_PAGE_CONFIG}
        />
      </Route>
      <Route path={SUPER_ADMIN_ALL_DEVICES_OVERVIEW_ROUTE}>
        <SuperAdminAllDevicesOverviewPage users={users} companies={activeCompanies} pageConfig={DEVICES_PAGE_CONFIG} />
      </Route>
      <Route path={SUPER_ADMIN_DEVICES_ROUTE}>
        <Redirect to={SUPER_ADMIN_HEXNODE_DEVICES_OVERVIEW_ROUTE} />
      </Route>
    </Switch>
  );
};
