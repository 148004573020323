import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { SingleUserSelect } from '@v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { DevicePossessionDto, ReassignDeviceDataDto } from '@v2/feature/device/device.dto';
import {
  DevicePossessionType,
  DeviceShippingMethod,
  EnrolmentType,
  WipeMethod,
} from '@v2/feature/device/device.interface';
import { DeviceShippingMethodsOptions } from '@v2/feature/device/device.util';
import { EnrollmentStatus } from '@v2/feature/device/features/enrollment-device/in-house-mdm.api';
import { UserAddressAPI } from '@v2/feature/user/features/user-forms/user-address/user-address.api';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { StyledRadio } from '@v2/styles/radio.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { formatAddress } from '@v2/util/user-data.util';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { nestErrorMessage } from '@/lib/errors';

interface ReassignDeviceFormProps {
  readonly currentDevicePossession: DevicePossessionDto;
  readonly isInventory: boolean;
  readonly reassignDevice: (currentDevicePossessionId: number, transitData: ReassignDeviceDataDto) => Promise<void>;
}

export const ReassignDeviceUserForm = ({
  currentDevicePossession,
  reassignDevice,
  isInventory,
}: ReassignDeviceFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [senderAddress, setSenderAddress] = useState<string>('');
  const [sitesOptions, setSitesOptions] = useState<{ value: number; label: string; address: string }[]>([]);
  const { hasScopes, getScopesContext } = useScopes();
  const [showMessage] = useMessage();

  const validationSchema = yup.object({
    shippingMethod: yup.string().required(),
    newUserId: yup.number().required('New user is required'),
    // senderAddress: yup.string().when('shippingMethod', {
    //   is: DeviceShippingMethod.ManualTracking,
    //   then: (schema) => schema.required('Sender Address is required'),
    //   otherwise: (schema) => schema.nullable().notRequired(),
    // }),
    // deliveryAddress: yup.string().when('shippingMethod', {
    //   is: DeviceShippingMethod.ManualTracking,
    //   then: (schema) => schema.required('Sender Address is required'),
    //   otherwise: (schema) => schema.nullable().notRequired(),
    // }),
    deviceAccountName: yup
      .string()
      .nullable()
      .matches(/^[a-zA-Z0-9_.-@]*$/, 'Device account name must not contain spaces or special characters.'),
    wipeMethod: yup.string().required(),
  });

  const formik = useFormik<ReassignDeviceDataDto>({
    initialValues: {
      shippingMethod: DeviceShippingMethod.NoTrackingReassignInstantly,
      newUserId: 0,
      senderAddress,
      deliveryAddress: '',
      deviceAccountName: null,
      wipeMethod: currentDevicePossession?.device?.inHouseMdm ? WipeMethod.WipeNow : WipeMethod.WipeLater,
      inventory: isInventory,
    },
    validationSchema,
    onSubmit: async (values) => {
      const { shippingMethod, newUserId, wipeMethod, deliveryAddress, deviceAccountName, inventory } = values;
      if (
        currentDevicePossession.possessionType === DevicePossessionType.User &&
        currentDevicePossession.possessionId === newUserId
      ) {
        showMessage('The sender and the receiver should not be the same.', 'error');
        return;
      }

      if (!inventory && !newUserId) {
        showMessage('No receiver has been selected.', 'error');
        return;
      }

      const reassignDeviceData: ReassignDeviceDataDto = {
        shippingMethod,
        newUserId: newUserId,
        senderAddress: senderAddress,
        deliveryAddress,
        wipeMethod,
        deviceAccountName,
        inventory: isInventory,
      };

      setLoading(true);
      await reassignDevice(currentDevicePossession.id, reassignDeviceData);
      setLoading(false);
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const handleDeviceAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Remove any invalid characters
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9_.-@]/g, '');
    if (sanitizedValue !== inputValue) {
      showMessage('Device account name must not contain spaces or special characters.', 'error');
    }
    formik.setFieldValue('deviceAccountName', sanitizedValue);
  };

  const fetchOwnerAddress = useCallback(
    async (devicePossession: DevicePossessionDto): Promise<string> => {
      try {
        if (devicePossession.possessionType === DevicePossessionType.User) {
          const hasReadAddressScope = hasScopes(
            ['user.address:read'],
            getScopesContext({ userId: devicePossession.possessionId })
          );
          if (!hasReadAddressScope) return '';

          const userAddress = await UserAddressAPI.findByUserId(devicePossession.possessionId);
          if (userAddress && userAddress?.effectiveRecord) {
            const effectiveAddress = userAddress.effectiveRecord;
            return formatAddress(effectiveAddress);
          }
        }
      } catch (error) {
        showMessage(`Could not retrieve the user details. ${nestErrorMessage(error)}`, 'error');
      }

      return '';
    },
    [getScopesContext, hasScopes, showMessage]
  );

  useEffect(() => {
    if (!isInventory) return;
    (async () => {
      try {
        const sites = await SiteAPI.listSites();
        const defaultSite = { value: -1, label: 'Zelt cloud storage', address: '' };
        setSitesOptions([
          defaultSite,
          ...sites.map((site) => {
            return { value: site.id, label: site.name, address: site.address ?? '' };
          }),
        ]);
      } catch (error) {
        showMessage(`Could not retrieve sites list. ${nestErrorMessage(error)}`, 'error');
      }
    })();
  }, [showMessage, isInventory]);

  const fetchReceiverAddress = useCallback(async (possessionId: number): Promise<string> => {
    try {
      setLoading(true);
      // const hasReadAddressScope = hasScopes(['user.address:read'], getScopesContext({ userId: possessionId }));
      // if (!hasReadAddressScope) showMessage(`Could not fetch the user address no permission.`, 'error');
      const userAddress = await UserAddressAPI.findByUserId(possessionId);
      if (userAddress && userAddress?.effectiveRecord) {
        const effectiveAddress = userAddress.effectiveRecord;
        return formatAddress(effectiveAddress);
      }
    } catch (error) {
      console.error('Could not retrieve user address.');
    } finally {
      setLoading(false);
    }

    return '';
  }, []);

  const setOwnerAddress = useCallback(async () => {
    const ownerAddress = await fetchOwnerAddress(currentDevicePossession);
    setSenderAddress(ownerAddress);
  }, [fetchOwnerAddress, currentDevicePossession]);

  useEffect(() => {
    (async () => {
      await setOwnerAddress();
    })();
  }, [setOwnerAddress]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        {isInventory ? (
          <>
            <Typography variant="title2">Step 1: Inventory</Typography>
            <SelectComponent
              name="newUserId"
              label="Site"
              options={sitesOptions}
              value={formik.values.newUserId}
              compareValue={formik.values.newUserId}
              onChange={async (e) => {
                const siteId = Number(e.target.value);
                const receiverAddress = sitesOptions.find((s) => s.value === siteId)?.address ?? '';
                await formik.setFieldValue('newUserId', siteId);
                await formik.setFieldValue('deliveryAddress', receiverAddress);
              }}
              placeholder="Select a site"
            />
          </>
        ) : (
          <>
            <Typography variant="title2">Step 1: New user</Typography>
            <SingleUserSelect
              name="newUserId"
              options="company"
              value={formik.values.newUserId === 0 ? null : formik.values.newUserId}
              onChange={async (_, x: unknown) => {
                const userId = (x as { value: number })?.value ?? null;
                await formik.setFieldValue('newUserId', userId);

                if (userId && userId !== 0) {
                  const receiverAddress = await fetchReceiverAddress(userId);
                  await formik.setFieldValue('deliveryAddress', receiverAddress);
                } else {
                  await formik.setFieldValue('deliveryAddress', '');
                }
              }}
              label="Employee"
              error={Boolean(formik.errors.newUserId)}
              helperText={formik.errors.newUserId}
              placeholder="Select an employee"
            />
            {currentDevicePossession?.device?.inHouseMdm && (
              <TextfieldComponent
                name="deviceAccountName"
                label="Device account name"
                value={formik.values.deviceAccountName ?? ''}
                type="text"
                onChange={handleDeviceAccountNameChange}
                error={formik.touched.deviceAccountName && !!formik.errors.deviceAccountName}
                helperText={(formik.touched.deviceAccountName && formik.errors.deviceAccountName) as string}
                clearText={() => formik.setFieldValue('deviceAccountName', '')}
                tooltip={
                  'To set up your device, enter a unique name for the account. If not provided, the email of the user will be used as the account name.'
                }
              />
            )}
          </>
        )}
        <Typography variant="title2">Step 2: Shipping</Typography>
        <Typography variant="caption">Set the shipping you would like to use for this reassignment.</Typography>

        <SelectComponent
          name="shippingMethod"
          label="Method"
          options={DeviceShippingMethodsOptions}
          value={formik.values.shippingMethod}
          compareValue={formik.values.shippingMethod}
          error={!!formik.errors.shippingMethod && formik.touched.shippingMethod}
          onChange={formik.handleChange}
          helperText={(formik.touched.shippingMethod && formik.errors.shippingMethod) as string}
        />

        <Typography variant="title2">Step 3: Wipe</Typography>
        <Typography variant="caption">
          Remove old settings and files to prepare this device for the new user. Device must be online for a successful
          wipe.
        </Typography>

        <FormControl sx={{ width: '100%' }}>
          <RadioGroup
            aria-labelledby="payment-method-group-label"
            name="wipeMethod"
            value={formik.values.wipeMethod}
            onChange={formik.handleChange}
            sx={{ gap: '10px' }}
          >
            {currentDevicePossession?.device?.inHouseMdm && (
              <FormControlLabel
                key="wipeNow"
                labelPlacement="end"
                value="wipeNow"
                control={<StyledRadio />}
                label={<Typography variant="caption">Wipe now</Typography>}
              />
            )}
            <FormControlLabel
              key="wipeLater"
              labelPlacement="end"
              value="wipeLater"
              control={<StyledRadio />}
              label={<Typography variant="caption">Wipe manually</Typography>}
            />
            {currentDevicePossession.device?.enrolmentType.toString() === EnrolmentType.OPEN_ENROLMENT &&
              currentDevicePossession.device?.enrollmentStatus === EnrollmentStatus.ENROLMENT_FINISHED.toString() && (
                <FormControlLabel
                  key="disenrol"
                  labelPlacement="end"
                  value="disenrol"
                  control={<StyledRadio />}
                  label={<Typography variant="caption">Disenrol from Zelt MDM</Typography>}
                />
              )}
          </RadioGroup>
        </FormControl>

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name="Save"
            colorVariant="primary"
            sizeVariant="medium"
            type="submit"
            loading={loading}
            fullWidth
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
