import axios from 'axios';
import {
  CompanyDepartmentDto,
  CreateCompanyDepartmentDto,
  UpdateCompanyDepartmentDto,
} from '@/models/company-department.model';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class CompanyDepartmentAPI {
  static async getCompanyDepartments(includeUserCount = false): Promise<CompanyDepartmentDto[]> {
    const query = `includeUserCount=${includeUserCount ? 'true' : 'false'}`;
    return (await axios.get(`/apiv2/companies/departments?${query}`)).data;
  }

  static async getCompanyDepartmentsWithMemberDetails(): Promise<CompanyDepartmentDto[]> {
    return (await axios.get(`/apiv2/companies/departments-members`)).data;
  }

  static async getCompanyDepartmentById(departmentId: number): Promise<CompanyDepartmentDto> {
    return (await axios.get(`/apiv2/companies/departments/${departmentId}`)).data;
  }

  static async addCompanyDepartment(department: CreateCompanyDepartmentDto): Promise<number> {
    return (await axios.post('/apiv2/companies/departments', department)).data;
  }

  static async deleteCompanyDepartment(departmentId: number): Promise<void> {
    await axios.delete(`/apiv2/companies/departments/${departmentId}`);
  }

  static async updateCompanyDepartment(departmentId: number, department: UpdateCompanyDepartmentDto): Promise<void> {
    await axios.patch(`/apiv2/companies/departments/${departmentId}`, department);
  }
}

export class DepartmentEndpoints {
  static getCompanyDepartments(includeUserCount = false): Endpoint<CompanyDepartmentDto[]> {
    const query = `includeUserCount=${includeUserCount ? 'true' : 'false'}`;
    return {
      url: `/apiv2/companies/departments?${query}`,
    };
  }

  static getCompanyDepartmentsCount(): Endpoint<{ count: number }> {
    return {
      url: `/apiv2/companies/departments-count`,
    };
  }
}
