import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { getDateString } from '@v2/components/forms/date-label.component';
import { ColumnsDrawer } from '@v2/components/table/columns-drawer.component';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { ProfileModal } from '@v2/components/theme-components/profile-modal.component';
import { FiltersEndpoints } from '@v2/feature/filters/filters.api';
import { UserProfileBar } from '@v2/feature/user/components/user-profile-bar.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { DEFAULT_LIFECYCLE_STATUS_FILTER, PeopleDirectoryUserDto } from '@v2/feature/user/user-directory.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { capitalize } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { ReactComponent as Mail } from '@/images/side-bar-icons/Mail.svg';
import { nestErrorMessage } from '@/lib/errors';
import { USER_PERSONAL_TAB } from '@/lib/routes';
import { AccountStatus } from '@/lib/users';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { RevealableCell, RevealableColumnHeader } from '@/v2/components/table/revealable-column-header.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortDate, sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import '@/v2/feature/user/pages/components/peeple-list-view.scss';
import { UserAPI, UserEndpoints } from '@/v2/feature/user/user.api';
import { INVITABLE_USER_ACCOUNT_STATUS } from '@/v2/feature/user/user.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import {
  doesErrorRequireCompanyToIncreaseFixedSeatsLimit,
  doesErrorRequireCompanyToUpgrade,
} from '@/v2/infrastructure/restrictions/restriction.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { formatAsCompensationCurrency } from '@/v2/util/string-format.util';
import { isDefined, pluralText } from '@/v2/util/string.util';

const PEOPLE_DIRECTORY_COLUMNS_OPTIONS: string[] = [
  'name',
  'emailAddress',
  'jobTitle',
  'department',
  'site',
  'salary',
  'reportsTo',
  'startDate',
  'lifecycleStatus',
  'accountStatus',
  'gender',
  'phone',
  'personalEmail',
  'employeeId',
  'workerType',
  'employmentContract',
  'ftePercent',
  'attendanceSchedule',
];

const nonActiveStatus: AccountStatus[] = [
  AccountStatus.Invited,
  AccountStatus.InvitedToOnboard,
  AccountStatus.Created,
  AccountStatus.Deactivated,
];

export const PeopleListView = () => {
  const { polyglot } = usePolyglot();

  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const { trackPage } = useJune();
  const [state, dispatch] = useContext(GlobalContext);
  const { user: currentUser } = state;
  const { getScopesContext, hasScopes } = useScopes();
  const isAdminUser = hasScopes(['user:all'], getScopesContext(currentUser));

  const { data: filtersConfig, isLoading } = useApiClient(FiltersEndpoints.getUsersDirectoryFiltersConfig(), {
    suspense: false,
  });

  const currentFilterString = isDefined(currentUser.features?.user?.table?.selectedFilters)
    ? currentUser.features?.user?.table?.selectedFilters
    : null;

  const [filterString, setFilterString] = useState<string>(currentFilterString || DEFAULT_LIFECYCLE_STATUS_FILTER);

  const {
    data: users,
    mutate: refreshUsersMutation,
    isLoading: usersLoading,
  } = useApiClient(UserEndpoints.getAllUsersDirectoryV2(filterString), { suspense: false });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedUserId, setSetSelectedUserId] = useState<number | null>(null);

  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [seatsLimitError, setSeatsLimitError] = useState<boolean>(false);

  const [salaryVisible, setSalaryVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const [selectionModel, setSelectionModel] = useState<number[]>([]);

  const { getCachedUserById } = useCachedUsers();
  const [searchInput, setSearchInput] = useState('');

  const filteredUsersBySearch = useMemo(() => {
    if (!searchInput || !users) return users ?? [];
    const search = searchInput.toLowerCase();
    return users.filter((user) => {
      const userRole = user.roles && user.roles[0];
      const userContract = user.userContracts && user.userContracts[0];
      const userEvent = user.userEvents && user.userEvents[0];

      return (
        selectedColumns &&
        ((selectedColumns.includes('name') &&
          (user.name.toLowerCase().includes(search) || user.displayName.toLowerCase().includes(search))) ||
          (selectedColumns.includes('emailAddress') &&
            user.emailAddress &&
            user.emailAddress.toLowerCase().includes(search)) ||
          (selectedColumns.includes('personalEmail') &&
            user?.personalEmail &&
            user.personalEmail.toLowerCase().includes(search)) ||
          (selectedColumns.includes('phone') && user?.phone && user.phone.toLowerCase().includes(search)) ||
          (selectedColumns.includes('accountStatus') &&
            user?.accountStatus &&
            user.accountStatus.toLowerCase().includes(search)) ||
          (selectedColumns.includes('gender') &&
            user?.gender &&
            user.gender.toLowerCase().includes(search) &&
            ((search === 'male' && user.gender === 'male') || search !== 'male')) ||
          (selectedColumns.includes('employeeId') &&
            user.employeeId &&
            user.employeeId.toLowerCase().includes(search)) ||
          (selectedColumns.includes('startDate') &&
            user?.startDate &&
            getDateString(user.startDate)?.toLowerCase().includes(search)) ||
          (selectedColumns.includes('jobTitle') &&
            userRole &&
            userRole?.jobTitle &&
            userRole.jobTitle.toLowerCase().includes(search)) ||
          (selectedColumns.includes('department') &&
            userRole &&
            userRole?.department?.name &&
            userRole.department.name.toLowerCase().includes(search)) ||
          (selectedColumns.includes('site') &&
            userRole &&
            userRole?.site?.name &&
            userRole.site.name.toLowerCase().includes(search)) ||
          (selectedColumns.includes('reportsTo') &&
            userRole &&
            userRole?.manager?.name &&
            userRole.manager.name.toLowerCase().includes(search)) ||
          (selectedColumns.includes('workerType') &&
            userContract &&
            userContract?.type &&
            userContract.type.toLowerCase().includes(search)) ||
          (selectedColumns.includes('employmentContract') &&
            userContract &&
            userContract?.contract &&
            userContract.contract.toLowerCase().includes(search)) ||
          (selectedColumns.includes('ftePercent') &&
            userContract &&
            (userContract?.attendanceSchedule?.ftePercent || userContract?.attendanceSchedule?.ftePercent === 0) &&
            `${userContract.attendanceSchedule.ftePercent}%`.toLowerCase().includes(search)) ||
          (selectedColumns.includes('attendanceSchedule') &&
            userContract &&
            userContract?.attendanceSchedule?.name &&
            userContract.attendanceSchedule?.name.toLowerCase().includes(search)) ||
          (selectedColumns.includes('lifecycleStatus') &&
            userEvent &&
            userEvent?.status &&
            userEvent.status.toLowerCase().includes(search)))
      );
    });
  }, [searchInput, users, selectedColumns]);

  const hiddenColumns = useMemo(() => {
    const userHiddenColumns = currentUser.features?.user?.table?.hiddenColumns ?? [];
    const allAllowedColumns = filtersConfig?.columnOptions?.map((column) => column.value) ?? [];

    return PEOPLE_DIRECTORY_COLUMNS_OPTIONS.filter(
      (column) => userHiddenColumns.includes(column) || !allAllowedColumns.includes(column)
    );
  }, [filtersConfig?.columnOptions, currentUser.features?.user?.table?.hiddenColumns]);

  useEffect(() => {
    if (!filtersConfig?.columnOptions || !hiddenColumns) return;

    const selectedColumns = filtersConfig.columnOptions
      .filter((column) => !hiddenColumns.includes(column.value))
      .map((column) => column.value);

    setSelectedColumns(selectedColumns);
  }, [filtersConfig, hiddenColumns]);

  const refreshUsers = useCallback(async () => {
    try {
      if (refreshUsersMutation) await refreshUsersMutation();
    } catch (error) {
      showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.load')}. ${nestErrorMessage(error)}`, 'error');
    }
  }, [refreshUsersMutation, showMessage, polyglot]);

  useEffect(() => {
    if (filtersConfig) refreshUsers();
  }, [refreshUsers, filtersConfig]);

  const allTableColumns = useMemo(() => filtersConfig?.columnOptions ?? [], [filtersConfig]);

  const updateUserFeaturesSelectedFilters = useCallback(
    async (filterString: string) => {
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures('user', 'table', 'selectedFilters', filterString);
        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.update')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [dispatch, showMessage, polyglot]
  );

  const updateUserFeaturesHiddenColumns = useCallback(
    async (selectedColumns: string[]) => {
      const newHiddenColumns = allTableColumns
        .filter((option) => !selectedColumns.includes(option.value))
        .map((option) => option.value);
      try {
        const updatedGlobalUser = await UserAPI.updateOwnUserFeatures(
          'user',
          'table',
          'hiddenColumns',
          newHiddenColumns
        );

        dispatch({
          type: GlobalStateActions.UPDATE_USER,
          payload: updatedGlobalUser,
        });
      } catch (error) {
        showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.update')}. ${nestErrorMessage(error)}`, 'error');
      }
    },
    [allTableColumns, dispatch, showMessage, polyglot]
  );

  const tableColumns = useMemo<ColumnDef<PeopleDirectoryUserDto, PeopleDirectoryUserDto>[]>(
    () => [
      {
        id: 'select',
        enableSorting: false,
        minSize: 20,
        maxSize: 20,
        header: () => {
          const allSelected = selectionModel.length > 0 && selectionModel.length === filteredUsersBySearch.length;
          return isAdminUser ? (
            <Box onClick={(e) => e.stopPropagation()}>
              <CheckboxComponent
                label={undefined}
                name="allSelected"
                checked={allSelected}
                value="allSelected"
                onChange={() => {
                  if (!allSelected) setSelectionModel(filteredUsersBySearch?.map((user) => user.userId));
                  else setSelectionModel([]);
                }}
              />
            </Box>
          ) : (
            <></>
          );
        },
        cell: ({ row: { original } }) => {
          return isAdminUser ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m10 }}>
              <Box onClick={(e) => e.stopPropagation()}>
                <CheckboxComponent
                  label={undefined}
                  name={original.userId?.toString() ?? ''}
                  checked={selectionModel.includes(original.userId)}
                  value={original.userId?.toString() ?? ''}
                  onChange={() => {
                    let finalArray = [];
                    if (selectionModel?.includes(original.userId)) {
                      finalArray = selectionModel.filter((sm) => sm !== original.userId);
                    } else finalArray = [...selectionModel, original.userId];
                    setSelectionModel(finalArray);
                  }}
                />
              </Box>
            </Box>
          ) : (
            <></>
          );
        },
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.name'),
        accessorFn: (row) => row,
        id: 'name',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => (
          <>
            {original.userId ? (
              <Box
                sx={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}
                className="user-cell-container"
              >
                <UserCell
                  userId={original.userId}
                  endAdornment={
                    <ButtonComponent
                      sizeVariant="small"
                      colorVariant="secondary"
                      className="preview-button"
                      onClick={(e) => {
                        setIsOpen(true);
                        setSetSelectedUserId(original.userId);
                        e.stopPropagation();
                      }}
                    >
                      {polyglot.t('PeopleDirectoryPage.preview')}
                    </ButtonComponent>
                  }
                  nameSx={
                    original.accountStatus && nonActiveStatus.includes(original.accountStatus)
                      ? { color: themeColors.Grey }
                      : { color: themeColors.DarkGrey }
                  }
                />
              </Box>
            ) : (
              <EmptyCell />
            )}
          </>
        ),
        maxSize: 160,
        minSize: 160,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.emailAddress'),
        accessorFn: (row) => row,
        id: 'emailAddress',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.emailAddress),
        cell: ({
          row: {
            original: { emailAddress },
          },
        }) => {
          return emailAddress ? <div>{emailAddress}</div> : <EmptyCell />;
        },
        maxSize: 180,
        minSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.jobTitle'),
        accessorFn: (row) => row,
        id: 'jobTitle',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => (item.roles && item.roles[0] ? item.roles[0].jobTitle : undefined)),
        cell: ({
          row: {
            original: { roles },
          },
        }) => {
          const jobTitle = roles && roles[0] ? roles[0].jobPosition?.title : undefined;
          return jobTitle ? <div>{polyglot.t(jobTitle)}</div> : <EmptyCell />;
        },
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.department'),
        accessorFn: (row) => row,
        id: 'department',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => (item.roles && item.roles[0] ? item.roles[0].department?.name : undefined)),
        cell: ({
          row: {
            original: { roles },
          },
        }) => {
          const department = roles && roles[0] ? roles[0].department?.name : undefined;
          return department ? <div>{polyglot.t(department)}</div> : <EmptyCell />;
        },
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.site'),
        accessorFn: (row) => row,
        id: 'site',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => (item.roles && item.roles[0] ? item.roles[0].site?.name : undefined)),
        cell: ({
          row: {
            original: { roles },
          },
        }) => {
          const site = roles && roles[0] ? roles[0].site?.name : undefined;
          return site ? <div>{polyglot.t(site)}</div> : <EmptyCell />;
        },
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => (
          <RevealableColumnHeader
            label={polyglot.t('PeopleDirectoryPage.salary')}
            visible={salaryVisible}
            onVisibilityChange={(visible) => setSalaryVisible(visible)}
          />
        ),
        id: 'salary',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.userCompensationBreakdown?.fixedRates.annual ?? 0),
        accessorFn: (row) => row,
        cell: ({ row: { original } }) =>
          original?.userCompensationBreakdown ? (
            <RevealableCell visible={salaryVisible}>
              {formatAsCompensationCurrency(
                original.userCompensationBreakdown.currency,
                original.userCompensationBreakdown.fixedRates.annual
              )}
            </RevealableCell>
          ) : (
            <EmptyCell />
          ),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.reportsTo'),
        accessorFn: (row) => row,
        id: 'reportsTo',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) =>
            item.roles && item.roles[0] && item.roles[0].managerId
              ? getCachedUserById(item.roles[0].managerId)?.displayName
              : undefined
          ),
        cell: ({
          row: {
            original: { roles, accountStatus },
          },
        }) => {
          return roles && roles[0]?.managerId ? (
            <UserCell
              userId={roles[0].managerId}
              nameSx={
                accountStatus && nonActiveStatus.includes(accountStatus)
                  ? { color: themeColors.Grey }
                  : { color: themeColors.DarkGrey }
              }
            />
          ) : (
            <EmptyCell />
          );
        },
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.startDate'),
        accessorFn: (row) => row,
        id: 'startDate',
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item?.startDate),
        cell: ({ row: { original } }) =>
          original.startDate ? <div>{getDateString(original.startDate)}</div> : <EmptyCell />,
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.lifecycleStatus'),
        accessorFn: (row) => row,
        id: 'lifecycleStatus',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) =>
            item?.userEvents && item.userEvents[0]?.status ? item?.userEvents[0].status : undefined
          ),
        cell: ({
          row: {
            original: { userEvents },
          },
        }) => (userEvents && userEvents[0]?.status ? <div>{userEvents[0].status}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.accountStatus'),
        accessorFn: (row) => row,
        id: 'accountStatus',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.accountStatus),
        cell: ({
          row: {
            original: { accountStatus },
          },
        }) => (accountStatus ? <div>{accountStatus}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.gender'),
        accessorFn: (row) => row,
        id: 'gender',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.gender),
        cell: ({
          row: {
            original: { gender },
          },
        }) => (gender ? <div>{capitalize(gender)}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.phone'),
        accessorFn: (row) => row,
        id: 'phone',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.phone),
        cell: ({
          row: {
            original: { phone },
          },
        }) => (phone ? <div>{phone}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.personalEmail'),
        accessorFn: (row) => row,
        id: 'personalEmail',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.personalEmail),
        cell: ({
          row: {
            original: { personalEmail },
          },
        }) => (personalEmail ? <div>{personalEmail}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.employeeId'),
        accessorFn: (row) => row,
        id: 'employeeId',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.employeeId),
        cell: ({
          row: {
            original: { employeeId },
          },
        }) => (employeeId ? <div>{employeeId}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.workerType'),
        accessorFn: (row) => row,
        id: 'workerType',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.userContracts && item.userContracts[0]?.type),
        cell: ({
          row: {
            original: { userContracts },
          },
        }) => (userContracts && userContracts[0]?.type ? <div>{userContracts[0].type}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.employmentContract'),
        accessorFn: (row) => row,
        id: 'employmentContract',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.userContracts && item.userContracts[0]?.contract),
        cell: ({
          row: {
            original: { userContracts },
          },
        }) => (userContracts && userContracts[0]?.contract ? <div>{userContracts[0].contract}</div> : <EmptyCell />),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.ftePercent'),
        accessorFn: (row) => row,
        id: 'ftePercent',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortNumeric(
            a,
            b,
            (item) => (item?.userContracts && item.userContracts[0]?.attendanceSchedule?.ftePercent) ?? undefined
          ),
        cell: ({
          row: {
            original: { userContracts },
          },
        }) =>
          userContracts &&
          (userContracts[0]?.attendanceSchedule?.ftePercent ||
            userContracts[0]?.attendanceSchedule?.ftePercent === 0) ? (
            <div>{userContracts[0].attendanceSchedule.ftePercent}%</div>
          ) : (
            <EmptyCell />
          ),
        minSize: 120,
        maxSize: 150,
      },
      {
        header: () => polyglot.t('PeopleDirectoryPage.attendanceSchedule'),
        accessorFn: (row) => row,
        id: 'attendanceSchedule',
        enableSorting: true,
        sortingFn: (a, b) =>
          sortNumeric(
            a,
            b,
            (item) => (item?.userContracts && item.userContracts[0]?.attendanceScheduleId) ?? undefined
          ),
        cell: ({
          row: {
            original: { userContracts },
          },
        }) =>
          userContracts && userContracts[0]?.attendanceSchedule?.name ? (
            <div>{userContracts[0].attendanceSchedule.name}</div>
          ) : (
            <EmptyCell />
          ),
        minSize: 120,
        maxSize: 150,
      },
    ],
    [selectionModel, filteredUsersBySearch, isAdminUser, polyglot, salaryVisible, getCachedUserById]
  );

  const handleRowClick = useCallback(
    (row: Row<PeopleDirectoryUserDto>) => {
      routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: row.original?.userId ?? '' }));
    },
    [routerHistory]
  );

  const customRowStyle = (row: Row<PeopleDirectoryUserDto>) => {
    if (row.original.accountStatus && nonActiveStatus.includes(row.original.accountStatus))
      return { color: themeColors.Grey };
    return {};
  };

  // Uncomment if users should be able to export table content
  // const exportTable = useCallback(() => {
  //   return exportTableAsCSV(filteredUsersBySearch, filtersConfig?.columnOptions ?? [], hiddenColumns, showMessage);
  // }, [filteredUsersBySearch, filtersConfig?.columnOptions, hiddenColumns, showMessage]);

  const exportSelectedPeopleDataCSV = useCallback(async () => {
    try {
      const csvData = await UserAPI.exportSelectedPeopleDirectory(selectionModel);
      const blob = new Blob([csvData], { type: 'text/csv' });
      saveAs(blob, `zelt-people-export${format(new Date(), 'ddMMyy')}.csv`);
    } catch (error) {
      showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.smthWrong')}. ${nestErrorMessage(error)}`, 'error');
    }
  }, [polyglot, selectionModel, showMessage]);

  const exportPeopleDataCSV = useCallback(async () => {
    try {
      window.location.href = UserAPI.exportPeopleDirectory();
    } catch (error) {
      showMessage(`${polyglot.t('PeopleDirectoryPage.errorMessages.smthWrong')}. ${nestErrorMessage(error)}`, 'error');
    }
  }, [polyglot, showMessage]);

  const usersThatCanBeInvited = useMemo(() => {
    return selectionModel
      .map((userId) => getCachedUserById(userId))
      .filter((u) => u?.accountStatus && INVITABLE_USER_ACCOUNT_STATUS.includes(u?.accountStatus));
  }, [getCachedUserById, selectionModel]);

  const inviteSelectedPeople = useCallback(async () => {
    try {
      let finalInvitees = usersThatCanBeInvited.map((u) => u?.userId);

      if (selectionModel.length > 0 && usersThatCanBeInvited.length === 0) {
        showMessage(polyglot.t('PeopleDirectoryPage.errorMessages.alreadyActive'), 'warning');
      }

      if (finalInvitees && finalInvitees.length > 0) {
        const filteredInvitees = finalInvitees.filter(Boolean) as number[];
        const inviteResult = await UserAPI.inviteSelectedPeople(filteredInvitees);
        const messageSuffix = `account${pluralText(filteredInvitees.length)}`;
        if (inviteResult.success) {
          showMessage(
            `${polyglot.t('PeopleDirectoryPage.successMessages.inviteSelected', {
              inviteCount: inviteResult?.emailAddresses?.length,
              messageSuffix,
            })}`,
            'success'
          );
        } else {
          throw new Error(inviteResult.message);
        }
      }
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(false);
      } else if (doesErrorRequireCompanyToIncreaseFixedSeatsLimit(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(true);
      } else {
        showMessage(
          `${polyglot.t('PeopleDirectoryPage.errorMessages.smthWrong')}. ${nestErrorMessage(error)}`,
          'error'
        );
      }
    }
  }, [polyglot, selectionModel.length, showMessage, usersThatCanBeInvited]);

  const bulkInviteUserWrapper = useCallback(() => {
    // if (userCannotBulkInvite) {
    //   setUpgradeModalOpen(true);
    // } else {
    inviteSelectedPeople();
    // }
  }, [inviteSelectedPeople]);

  useEffect(() => {
    trackPage('People directory');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPeopleDirectoryBulkActionsOptions = () => {
    const actionOptions = [];
    if (isAdminUser && selectionModel?.length > 0)
      actionOptions.push({
        icon: <Mail {...iconSize} />,
        handler: () => bulkInviteUserWrapper(),
        label: polyglot.t('PeopleDirectoryPage.inviteSelected'),
        disabled: false,
      });
    if (isAdminUser && selectionModel?.length > 0)
      actionOptions.push({
        icon: <Export {...iconSize} />,
        handler: () => exportSelectedPeopleDataCSV(),
        label: polyglot.t('PeopleDirectoryPage.exportSelected'),
        disabled: false,
      });
    actionOptions.push({
      icon: <Export {...iconSize} />,
      handler: () => exportPeopleDataCSV(),
      label: polyglot.t('PeopleDirectoryPage.export'),
      disabled: false,
    });
    return actionOptions;
  };

  return (
    <ContentWrapper loading={false} hideFooter loaderSx={{ minHeight: 'inherit' }} sx={{ paddingBottom: 0 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', gap: '5px', alignItems: 'center' }}>
          <ColumnsDrawer
            columnsOptions={filtersConfig?.columnOptions ?? []}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            onApply={updateUserFeaturesHiddenColumns}
          />
          <FiltersDrawer
            filtersOptions={[{ filters: filtersConfig?.filterOptions ?? {} }]}
            selectedFilters={filterString}
            setSelectedFilters={setFilterString}
            onApply={updateUserFeaturesSelectedFilters}
          />
          <TableSearch
            query={searchInput}
            handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')}
            placeholder={polyglot.t('PeopleDirectoryPage.search')}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: spacing.g5 }}>
          <StyledMenuComponent
            options={getPeopleDirectoryBulkActionsOptions()}
            actionButtonDetails={{
              type: 'button',
              colorVariant: 'secondary',
              sizeVariant: 'small',
              title: polyglot.t('General.actions'),
              icon: <ArrowDown {...iconSize} />,
              iconPosition: 'end',
            }}
          />
        </Box>
      </Box>

      <Box sx={{ mt: spacing.s2 }}>
        <BasicTable<PeopleDirectoryUserDto>
          rowData={filteredUsersBySearch}
          columnData={tableColumns}
          loading={isLoading || usersLoading}
          initialSort={[
            { id: 'accountStatus', desc: true },
            { id: 'name', desc: true },
          ]}
          rowClick={handleRowClick}
          hiddenColumns={hiddenColumns}
          customRowStyle={customRowStyle}
          fixedLastColumn={false}
          stickyHeader
        />
      </Box>

      <ProfileModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        openProfile={() => {
          if (selectedUserId) routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId: selectedUserId }));
        }}
        openInNewWindow={() => {
          if (selectedUserId) window.open(generatePath(USER_PERSONAL_TAB, { userId: selectedUserId }), '_blank');
        }}
      >
        {selectedUserId ? <UserProfileBar userId={selectedUserId} /> : undefined}
      </ProfileModal>
      <UpgradeToProModal
        isOpen={upgradeModalOpen}
        setIsDrawerOpen={setUpgradeModalOpen}
        planName={PlanNames.PEOPLE_PRO}
        messageSuffix="proPeople"
        seatsLimitError={seatsLimitError}
      />
    </ContentWrapper>
  );
};
