import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { DOMParser as ProseMirrorDOMParser } from 'prosemirror-model';

import { isLocalFilePath } from '@/v2/components/forms/document-editor/tiptap/extensions/helpers/file-download.util';
import { fontFamilyMap } from '@/v2/feature/templates/components/editor';

const regex = /font-family:([^;]+);/;

export const parseFontFamily = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const elementsWithFontFamily = doc.querySelectorAll('[style*="font-family"]');
  elementsWithFontFamily.forEach((element) => {
    const style = element.getAttribute('style');
    if (!style) {
      return;
    }
    const match = style?.match(regex);
    if (!match) {
      return;
    }
    let allowedFont = false;
    Object.keys(fontFamilyMap).forEach((fontFamily) => {
      let family = match[1].toLocaleLowerCase().replace(/\s/g, '');
      family = family.replaceAll(`'`, ``);
      const foundIndex = family.lastIndexOf(',');
      if (foundIndex) {
        family = family.slice(0, foundIndex);
      }

      if (family === fontFamily) {
        allowedFont = true;
        //@ts-ignore
        element.setAttribute('style', style.replace(match[1], fontFamilyMap[fontFamily] as string));
      }
    });
    if (!allowedFont) {
      const updatedStyle = style.replace(match[1], 'arial');
      element.setAttribute('style', updatedStyle);
    }
  });

  return doc.body.innerHTML;
};

export const PasteExtension = Extension.create({
  name: 'PastePlugin',

  addOptions() {
    return {
      handleError: () => {},
    };
  },

  addProseMirrorPlugins() {
    const { handleError } = this.options;
    return [
      new Plugin({
        key: new PluginKey('PastePlugin'),
        props: {
          handleDOMEvents: {
            //@ts-ignore
            paste: async (view, event) => {
              const clipboardData = event?.clipboardData;
              if (clipboardData) {
                const html = clipboardData.getData('text/html');
                if (html) {
                  const modifiedHTML = parseFontFamily(html);
                  const schema = view.state.schema;
                  const dom = document.createElement('div');
                  dom.innerHTML = modifiedHTML;

                  const imgElements = dom.querySelectorAll('img');

                  let error = false;
                  await Promise.all(
                    Array.from(imgElements).map((img: HTMLImageElement) => {
                      if (isLocalFilePath(img.src)) {
                        img.src = '';
                        error = true;
                      }
                      return true;
                    })
                  );
                  if (error) {
                    handleError(`Failed to copy and paste image. Please try to drag and drop your image.`);
                  }
                  const slice = ProseMirrorDOMParser.fromSchema(schema).parseSlice(dom);

                  const transaction = view.state.tr.replaceSelection(slice);
                  view.dispatch(transaction);
                  event.preventDefault();
                  return true;
                }
              }
              return false;
            },
          },
        },
      }),
    ];
  },
});
