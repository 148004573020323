import { useContext, useMemo } from 'react';

import { Box } from '@mui/material';
import { EmptyStateBox } from '@v2/components/empty-state-box.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { getDefaultBenefitImageByCategory } from '@v2/feature/benefits/benefits.util';
import { UserCustomBenefitDto } from '@v2/feature/benefits/subfeature/custom-benefit/user-custom-benefit/user-custom-benefit.dto';
import { UserInsuranceDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { getLogoByProviderName } from '@v2/feature/benefits/subfeature/insurance/insurance.util';
import { UserPensionDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import {
  BENEFITS_ME_ROUTE,
  CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  INSURANCE_ME_OVERVIEW_ROUTE,
  PENSION_ME_SUMMARY_ROUTE,
  USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE,
  USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE,
  USER_BENEFITS_OVERVIEW_ROUTE,
  USER_BENEFITS_PENSION_SUMMARY_ROUTE,
} from '@/lib/routes';
import { UserOverviewConfig } from '@/models';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';

interface BenefitsCompanyOverviewRouterProps {
  readonly userId: number;
  readonly userPension: UserPensionDto | null;
  readonly userInsurance: UserInsuranceDto | null;
  readonly userBenefits: UserCustomBenefitDto[] | null;
  readonly userOverviewConfig: UserOverviewConfig;
  readonly loading: boolean;
}

export const BenefitsUserOverviewPage = ({
  userId,
  userPension,
  userInsurance,
  userBenefits,
  userOverviewConfig,
  loading,
}: BenefitsCompanyOverviewRouterProps) => {
  const routerHistory = useHistory();
  const location = useLocation();
  const [state] = useContext(GlobalContext);

  const isMePage = useMemo(() => {
    return location.pathname.startsWith(BENEFITS_ME_ROUTE);
  }, [location]);

  const showForCurrentUser = useMemo(() => userId === state.user.userId, [state.user.userId, userId]);

  return (
    <RootStyle>
      <ContentWrapper loading={false}>
        <SettingsSectionContent title="Overview" contentWidth="100%" headerWidth="100%">
          <SettingsSubsectionContent
            loadingAll={loading}
            sections={[
              {
                contentWidth: '100%',
                items: [
                  {
                    type: SectionItemType.Component,
                    value: (
                      <Box sx={flexContainerStyle}>
                        {userOverviewConfig.inPension && userPension && userPension.pensionProviderName && (
                          <SettingsItemCard
                            key="pension"
                            title={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
                                {getPensionLogoByProviderName(userPension.pensionProviderName, 40)}
                                <Typography variant="title4">Pension scheme</Typography>{' '}
                              </Box>
                            }
                            boxSx={responsiveCardStyle}
                            boxAction={() => {
                              routerHistory.push(
                                matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE)
                                  ? generatePath(USER_BENEFITS_PENSION_SUMMARY_ROUTE, { userId })
                                  : PENSION_ME_SUMMARY_ROUTE
                              );
                            }}
                            contentItemsSets={[
                              {
                                name:
                                  PensionProvidersValueToLabel[userPension.pensionProviderName] ??
                                  userPension.pensionProviderName,
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                            ]}
                          />
                        )}

                        {userOverviewConfig.inInsurance && userInsurance?.policy && (
                          <SettingsItemCard
                            key="insurance"
                            title={
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
                                {getLogoByProviderName(userInsurance.policy.providerName, 40)}
                                <Typography variant="title4">{userInsurance.policy.providerName}</Typography>{' '}
                              </Box>
                            }
                            boxSx={responsiveCardStyle}
                            boxAction={() => {
                              routerHistory.push(
                                matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE)
                                  ? generatePath(USER_BENEFITS_INSURANCE_OVERVIEW_ROUTE, { userId })
                                  : INSURANCE_ME_OVERVIEW_ROUTE
                              );
                            }}
                            contentItemsSets={[
                              {
                                name: 'Health Insurance',
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                            ]}
                          />
                        )}

                        {userOverviewConfig.inCustomBenefits &&
                          userBenefits?.map((benefit, index) => (
                            <SettingsItemCard
                              key={`benefit-${index}`}
                              title={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
                                  {benefit?.customBenefit?.url ? (
                                    <img src={benefit.customBenefit.url} width={40} height={40} alt="benefit-logo" />
                                  ) : (
                                    getDefaultBenefitImageByCategory(benefit?.customBenefit?.category, '50px', 'auto')
                                  )}
                                  <Typography variant="title4">
                                    {benefit.customBenefit?.name ?? 'Custom benefit'}
                                  </Typography>{' '}
                                </Box>
                              }
                              boxSx={responsiveCardStyle}
                              boxAction={() => {
                                if (matchPath(location.pathname, USER_BENEFITS_OVERVIEW_ROUTE)) {
                                  routerHistory.push(
                                    generatePath(USER_BENEFITS_CUSTOM_BENEFIT_OVERVIEW_ROUTE, {
                                      userId,
                                      id: benefit.customBenefitId,
                                    })
                                  );
                                } else
                                  routerHistory.push(
                                    generatePath(CUSTOM_BENEFIT_OVERVIEW_ROUTE, { id: benefit.customBenefitId })
                                  );
                              }}
                              contentItemsSets={[
                                {
                                  name: 'Benefit',
                                  type: ContentItemType.chip,
                                  textColor: 'DarkGrey',
                                  backgroundColor: 'white',
                                  border: 'middle',
                                },
                              ]}
                            />
                          ))}
                      </Box>
                    ),
                  },
                  {
                    type: SectionItemType.Component,
                    value:
                      !userOverviewConfig.inPension &&
                      !userOverviewConfig.inInsurance &&
                      !userOverviewConfig.inCustomBenefits ? (
                        <EmptyStateBox
                          title={isMePage || showForCurrentUser ? 'Your benefits' : 'User benefits'}
                          subtitle={
                            isMePage || showForCurrentUser
                              ? 'You will see your benefits here. At the moment there is nothing to show'
                              : "You will see employee's benefits here. At the moment there is nothing to show"
                          }
                        />
                      ) : (
                        <></>
                      ),
                  },
                ],
              },
            ]}
          />
        </SettingsSectionContent>
      </ContentWrapper>
    </RootStyle>
  );
};
