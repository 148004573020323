import React, { forwardRef, ReactNode } from 'react';

import { Box, Stack, StandardTextFieldProps, TextField } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { themeColors } from '@v2/styles/colors.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Edit } from '@/images/fields/Edit.svg';
import { ReactComponent as ChoseIcon } from '@/images/side-bar-icons/Chose.svg';
import { getSelectProps, StyledMenuItem } from '@/v2/styles/menu.styles';
import { StyledTextfield } from '@/v2/styles/textfield.styles';

const iconSize = { width: '14px', height: '14px' } as const;

export interface OptionObject {
  readonly label: string | React.ReactNode;
  readonly value: string | number;
  readonly icon?: React.ReactNode;
  readonly description?: string;
  readonly disabled?: boolean;
  readonly isHidden?: boolean;
  readonly handler?: () => void;
  readonly editListOption?: boolean;
}

export interface EditListOption {
  readonly handler: () => void;
  readonly isHidden: boolean;
}

export type SelectComponentProps = StandardTextFieldProps & {
  readonly name: string;
  readonly label?: React.ReactNode;
  readonly placeholder?: string | React.ReactNode;
  readonly placeholderOverride?: React.ReactNode;
  readonly options: readonly OptionObject[];
  readonly value: string | number | undefined | null;
  readonly compareValue?: string | number | undefined | null;
  readonly onChange?: (e: React.ChangeEvent<any>) => any;
  readonly footer?: React.ReactNode;
  readonly editList?: EditListOption;
  readonly maxLabelDisplayCharacters?: number;
};

export const SelectComponent = forwardRef<typeof TextField, SelectComponentProps>((props, ref) => {
  const { options, footer, label, editList, placeholder, placeholderOverride, ...textFieldProps } = props;
  const selectedValue = props.value;
  const selectProps = textFieldProps.SelectProps ? textFieldProps.SelectProps : getSelectProps;

  const getLabelText = (labelValue: string | ReactNode) => {
    if (!textFieldProps.maxLabelDisplayCharacters) return labelValue;
    if (typeof labelValue !== 'string') return labelValue;
    if (labelValue.length < textFieldProps.maxLabelDisplayCharacters) return labelValue;

    return labelValue.substring(0, textFieldProps.maxLabelDisplayCharacters) + '...';
  };

  return (
    <StyledTextfield
      {...textFieldProps}
      select
      label={label}
      inputRef={ref}
      variant="standard"
      size="small"
      InputLabelProps={{ shrink: true }}
      fullWidth
      SelectProps={{
        ...selectProps,
        displayEmpty: true,
        renderValue: (value) => {
          if (placeholderOverride) {
            return placeholderOverride;
          }
          const option = options.find((opt) => opt.value && opt.value === value);
          const label = getLabelText(option ? option.label : placeholder);
          return !option ? (
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
              {placeholder && (
                <Typography variant="caption" color="Grey">
                  {label}
                </Typography>
              )}
            </Stack>
          ) : (
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
              <>{option.icon}</>
              <Typography variant="title4">{label}</Typography>
            </Stack>
          );
        },
      }}
    >
      {options.map(({ value, label, icon, description, disabled }) => {
        return (
          <StyledMenuItem key={value} value={value} disabled={disabled}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
                <>{icon}</>
                <Typography variant={value === selectedValue ? 'title4' : 'caption'}>{label}</Typography>
                {value === selectedValue && <ChoseIcon {...iconSize} style={{ marginLeft: 'auto' }} />}
              </Stack>
              {description && (
                <Typography
                  variant="caption"
                  color="Grey"
                  sx={{
                    maxWidth: '350px',
                    whiteSpace: 'pre-wrap',
                    mt: spacing.m5,
                  }}
                >
                  {description}
                </Typography>
              )}
            </Box>
          </StyledMenuItem>
        );
      })}
      {footer && (
        <StyledMenuItem disabled sx={{ borderTop: '1px solid #ddd' }}>
          <Typography
            variant="caption"
            sx={{
              maxWidth: '350px',
              whiteSpace: 'pre-wrap',
              mt: spacing.m5,
            }}
          >
            {footer}
          </Typography>
        </StyledMenuItem>
      )}
      {editList && !editList.isHidden && (
        <StyledMenuItem
          sx={{
            borderTop: `1px solid ${themeColors.Background}`,
            position: 'sticky',
            zIndex: 3,
            bottom: 0,
            left: 0,
            background: themeColors.white,
          }}
          onClick={() => editList.handler?.()}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g10 }}>
              <Edit {...iconSize} />
              <Typography variant="caption" color="Grey">
                Edit list
              </Typography>
            </Stack>
          </Box>
        </StyledMenuItem>
      )}
    </StyledTextfield>
  );
});
