import { useMemo } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ColumnsListSection } from '@v2/feature/reports/reports-advanced/components/columns-list.section';
import { ReportColumnCategory, ReportColumnType, SelectedColumnsRequest } from '@v2/feature/reports/reports.interface';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';

import { ReactComponent as Plus } from '@/images/side-bar-icons/Plus.svg';

interface Props {
  readonly selectedColumns: SelectedColumnsRequest;
  readonly setSelectedColumns: React.Dispatch<React.SetStateAction<SelectedColumnsRequest>>;
  readonly openAddColumns: () => void;
  readonly editColumn: (stub: string, col: string, type: ReportColumnType, order: number) => void;
  readonly reportColumns: readonly ReportColumnCategory[];
}

export const ReportColumns = ({
  selectedColumns,
  setSelectedColumns,
  openAddColumns,
  editColumn,
  reportColumns,
}: Props) => {
  const hasColumns = useMemo(() => {
    return Object.keys(selectedColumns).length > 0;
  }, [selectedColumns]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        height: '100%',
        overflowY: 'auto',
      }}
    >
      {hasColumns ? (
        <ColumnsListSection
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          editColumn={editColumn}
          reportColumns={reportColumns}
        />
      ) : (
        <Typography variant="caption">No columns selected</Typography>
      )}
      <Box sx={{ pb: spacing.s1 }}>
        <ButtonComponent
          sizeVariant="medium"
          colorVariant="dashed"
          style={{ marginTop: '10px' }}
          fullWidth
          onClick={openAddColumns}
          startIcon={<Plus {...iconSize} />}
        >
          Add columns
        </ButtonComponent>
      </Box>
    </Box>
  );
};
