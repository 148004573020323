import React, { useState } from 'react';

import { Box } from '@mui/material';
import { ColumnEditSection } from '@v2/feature/reports/reports-advanced/components/column-edit.section';
import { ColumnsFiltersSwitch } from '@v2/feature/reports/reports-advanced/components/columns-filters-switch.component';
import { FilterEditSection } from '@v2/feature/reports/reports-advanced/components/filter-edit.section';
import { ReportColumnsSelection } from '@v2/feature/reports/reports-advanced/components/report-columns-selection.component';
import { ReportColumns } from '@v2/feature/reports/reports-advanced/components/report-columns.component';
import { ReportConfigBackButton } from '@v2/feature/reports/reports-advanced/components/report-config-back-button.component';
import { ReportFiltersSelection } from '@v2/feature/reports/reports-advanced/components/report-filters-selection.component';
import { ReportFilters } from '@v2/feature/reports/reports-advanced/components/report-filters.component';
import { ReportSettingsSection } from '@v2/feature/reports/reports-advanced/components/report-settings.section';
import {
  ReportColumnCategory,
  ReportColumnType,
  ReportFilterCategory,
  ReportFilterSelected,
  SelectedColumnsRequest,
  SelectedFiltersRequest,
} from '@v2/feature/reports/reports.interface';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { borders } from '@v2/styles/borders.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE, REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE } from '@/lib/routes';

interface Props {
  readonly selectedColumns: SelectedColumnsRequest;
  readonly setSelectedColumns: React.Dispatch<React.SetStateAction<SelectedColumnsRequest>>;
  readonly selectedFilters: SelectedFiltersRequest;
  readonly setSelectedFilters: React.Dispatch<React.SetStateAction<SelectedFiltersRequest>>;
  readonly reportColumns: readonly ReportColumnCategory[];
  readonly reportFilters: readonly ReportFilterCategory[];
  readonly target: 'all' | 'team';
  readonly effectiveDate: string;
  readonly setEffectiveDate: React.Dispatch<React.SetStateAction<string>>;
}

export type ReportSideBarView =
  | 'columns-list'
  | 'columns-selection'
  | 'column-edit'
  | 'filters-list'
  | 'filters-selection'
  | 'filter-edit'
  | 'settings';

export const ReportConfigSection = ({
  selectedColumns,
  setSelectedColumns,
  selectedFilters,
  setSelectedFilters,
  effectiveDate,
  setEffectiveDate,
  reportColumns,
  reportFilters,
  target,
}: Props) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();

  const [view, setView] = useState<ReportSideBarView>('columns-list');
  const [columnToEdit, setColumnToEdit] = useState<{
    stub: string;
    col: string;
    type: ReportColumnType;
    order: number;
  } | null>(null);
  const [filterToEdit, setFilterToEdit] = useState<ReportFilterSelected | null>(null);

  return (
    <Box
      sx={{
        ...drawerContentSx,
        width: '264px',
        borderRight: borders.background,
        px: '20px',
      }}
    >
      {['columns-list', 'filters-list', 'settings'].includes(view) && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s2 }}>
          <ReportConfigBackButton
            title={polyglot.t('getSidebarConfig.reportsV2')}
            goBack={() => {
              if (target === 'all') history.push(REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE);
              else history.push(REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE);
            }}
          />
          <ColumnsFiltersSwitch view={view} setView={setView} />
        </Box>
      )}

      {view === 'columns-list' ? (
        // COLUMNS sTART
        <ReportColumns
          openAddColumns={() => {
            setView('columns-selection');
          }}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          editColumn={(stub: string, col: string, type: ReportColumnType, order: number) => {
            setColumnToEdit({ stub, col, type, order });
            setView('column-edit');
          }}
          reportColumns={reportColumns}
        />
      ) : view === 'columns-selection' ? (
        <ReportColumnsSelection
          goBack={() => {
            setView('columns-list');
          }}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          reportColumns={reportColumns}
        />
      ) : view === 'column-edit' ? (
        <ColumnEditSection
          goBack={() => {
            setView('columns-list');
            setColumnToEdit(null);
          }}
          selectedColumns={selectedColumns}
          setSelectedColumns={setSelectedColumns}
          reportColumns={reportColumns}
          column={columnToEdit}
        />
      ) : // COLUMNS END
      //   FILTERS START
      view === 'filters-list' ? (
        <ReportFilters
          openAddFilters={() => {
            setView('filters-selection');
          }}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          editFilter={(selectedFilter: ReportFilterSelected) => {
            setFilterToEdit(selectedFilter);
            setView('filter-edit');
          }}
          reportFilters={reportFilters}
        />
      ) : view === 'filters-selection' ? (
        <ReportFiltersSelection
          goBack={() => {
            setView('filters-list');
          }}
          goToEdit={() => {
            setView('filter-edit');
          }}
          reportFilters={reportFilters}
          setFilterToEdit={setFilterToEdit}
        />
      ) : view === 'filter-edit' ? (
        <FilterEditSection
          setSelectedFilters={setSelectedFilters}
          goBack={() => {
            setView('filters-list');
            setFilterToEdit(null);
          }}
          reportFilters={reportFilters}
          filter={filterToEdit}
        />
      ) : // FILTERS END
      view === 'settings' ? (
        <ReportSettingsSection effectiveDate={effectiveDate} setEffectiveDate={setEffectiveDate} />
      ) : null}
    </Box>
  );
};
