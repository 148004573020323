import React, { useEffect } from 'react';

import { Box, FormControl, FormControlLabel } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { OauthClientAPI, OauthClientEndpoints } from '@/api-client/oauth-client.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { TextfieldComponent } from '@/v2/components/forms/textfield.component';
import { NonSensitiveOauthClientConfiguration } from '@/v2/feature/security/security-settings/security.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { spacing } from '@/v2/styles/spacing.styles';

interface DeveloperHubPermissionsEditDrawerProps {
  isOpen: boolean;
  readonly oauthApp: NonSensitiveOauthClientConfiguration | undefined;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly onClose: () => void;
  readonly onUpdate: () => void;
}
interface FormValues {
  scopes: string[];
  scopeReasons: { [key: string]: string };
}

export const DeveloperHubPermissionsEditDrawer = ({
  isOpen,
  oauthApp,
  setIsOpen,
  onClose,
  onUpdate,
}: DeveloperHubPermissionsEditDrawerProps): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const { data: ALL_AVAILABLE_SCOPES } = useApiClient(OauthClientEndpoints.getAvailableScopes(), {
    suspense: false,
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      scopes: oauthApp?.scopes || [],
      scopeReasons: oauthApp?.scopeReasons || {},
    },
    validationSchema: Yup.object().shape({
      scopes: Yup.array().of(Yup.string()).min(1, 'At least one scope must be selected'),
      scopeReasons: Yup.object().test(
        'scope-reasons',
        'Some selected scopes are missing reasons',
        function (scopeReasons: { [key: string]: string } | undefined, context) {
          const scopes = context.parent.scopes as string[];
          const missingReasons = scopes.filter((scope) => {
            const reason = scopeReasons?.[scope];
            return !reason || reason.trim() === '';
          });

          if (missingReasons.length > 0) {
            return this.createError({
              message: `Missing reasons for: ${missingReasons.join(', ')}`,
            });
          }

          return true;
        }
      ),
    }),
    onSubmit: (values) => {
      updateScopesForOauthAppClient(values);
    },
  });

  useEffect(() => {
    if (oauthApp) {
      formik.setValues({
        scopes: oauthApp.scopes || [],
        scopeReasons: oauthApp.scopeReasons || {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oauthApp]);

  const updateScopesForOauthAppClient = async (values: typeof formik.values) => {
    if (!oauthApp || oauthApp.published) {
      showMessage(polyglot.t('DeveloperHub.cannotUpdatePublishedApp'), 'error');
      return;
    }

    try {
      await OauthClientAPI.updateScopeAndScopeReasonsForExistingClient(
        oauthApp.clientId,
        oauthApp.id,
        values.scopes,
        values.scopeReasons
      );
      showMessage(polyglot.t('DeveloperHubClientGenerateDrawer.successMessages.update'), 'success');
      onClose();
      onUpdate();
    } catch (error) {
      showMessage(
        polyglot.t('DeveloperHubClientGenerateDrawer.errorMessages.update', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    }
  };

  const handleScopeChange = (scope: string, checked: boolean) => {
    formik.setValues((prevValues) => {
      const newScopes = checked ? [...prevValues.scopes, scope] : prevValues.scopes.filter((s) => s !== scope);

      const newScopeReasons = { ...prevValues.scopeReasons };
      if (!checked) {
        delete newScopeReasons[scope];
      }

      return {
        ...prevValues,
        scopes: newScopes,
        scopeReasons: newScopeReasons,
      };
    }, true);

    // Trigger form validation
    setTimeout(() => formik.validateForm(), 0);
  };

  useEffect(() => {
    console.log('Current formik values:', formik.values);
    console.log('Current formik errors:', formik.errors);
  }, [formik.values, formik.errors]);

  const handleScopeReasonChange = (scope: string, reason: string) => {
    formik.setFieldValue('scopeReasons', {
      ...formik.values.scopeReasons,
      [scope]: reason,
    });
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form>
          <Typography variant="title2">{polyglot.t('DeveloperHub.permissions')}</Typography>

          <Box
            sx={{
              mt: spacing.mt10,
              ml: spacing.ml10,
              maxHeight: '300px',
              overflowY: 'auto',
              px: spacing.px20,
              pb: spacing.pb20,
            }}
          >
            {(ALL_AVAILABLE_SCOPES ?? []).map((scope, index) => (
              <Box key={scope.value} sx={{ mb: spacing.mb10 }}>
                <FormControlLabel
                  control={
                    <CheckboxComponent
                      name={`scopeValue-${index}`}
                      checked={formik.values.scopes.includes(scope.value)}
                      onChange={(_, checked) => handleScopeChange(scope.value, checked)}
                    />
                  }
                  label={<Typography variant="caption">{scope.value}</Typography>}
                />
                {formik.values.scopes.includes(scope.value) && (
                  <FormControl sx={{ marginTop: 1 }} size="small" fullWidth>
                    <TextfieldComponent
                      name={`scopeReasons[${scope.value}]`}
                      label={polyglot.t('DeveloperHub.reasonFor', { scope: scope.label })}
                      value={formik.values.scopeReasons[scope.value] || ''}
                      onChange={(e) => handleScopeReasonChange(scope.value, e.target.value)}
                      error={formik.touched.scopeReasons && !!formik.errors.scopeReasons}
                      helperText={
                        formik.touched.scopeReasons &&
                        typeof formik.errors.scopeReasons === 'string' &&
                        formik.errors.scopeReasons
                      }
                      fullWidth
                    />
                  </FormControl>
                )}
              </Box>
            ))}
          </Box>

          {formik.touched.scopes && formik.errors.scopes && (
            <Typography color="red" variant="caption">
              {formik.errors.scopes as string}
            </Typography>
          )}

          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent
              fullWidth
              colorVariant="primary"
              sizeVariant="medium"
              onClick={(e) => {
                e.preventDefault();
                formik.handleSubmit();
              }}
              disabled={!formik.isValid || oauthApp?.published}
            >
              {polyglot.t('DeveloperHub.updateScopes')}
            </ButtonComponent>
          </Box>
        </Form>
      </FormikProvider>
    </DrawerModal>
  );
};
