import { useCallback, useContext, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { OnboardingTemplate } from '@shared/modules/onboarding/onboarding';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { OnboardingScopes } from '@/v2/feature/onboarding/onboarding.scopes';
import { goToEditTemplate } from '@/v2/feature/onboarding/onboarding.util';
import { PlanNames, UpgradeToProModal } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { OnboardingAPI } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { doesErrorRequireCompanyToUpgrade } from '@/v2/infrastructure/restrictions/restriction.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { useEscapeKey } from '@/v2/util/keyboard-hook.util';
import { pluralText } from '@/v2/util/string.util';

type OnboardingSettingsProps = {
  templates: OnboardingTemplate[];
  refresh?: () => void;
};

export const OnboardingSettings = ({ templates: initialTemplates, refresh }: OnboardingSettingsProps) => {
  const { polyglot } = usePolyglot();
  const sortTemplatesByName = useCallback(
    (templates: OnboardingTemplate[]) =>
      templates.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })),
    []
  );
  const [templates, setTemplates] = useState(sortTemplatesByName(initialTemplates));
  const [creatingTemplate, setCreatingTemplate] = useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });

  const routerHistory = useHistory();
  const [showMessage] = useMessage();

  const refreshTemplates = useCallback(() => {
    OnboardingAPI.getOnboardingTemplates()
      .then(sortTemplatesByName)
      .then(setTemplates)
      .catch((error) =>
        showMessage(
          polyglot.t('OnboardingSettings.errorMessages.load', { errorMessage: nestErrorMessage(error) }),
          'error'
        )
      );
  }, [showMessage, sortTemplatesByName, polyglot]);

  useEffect(refreshTemplates, [refreshTemplates]);

  const closeOnboardingSettings = useCallback(() => routerHistory.goBack(), [routerHistory]);
  useEscapeKey(closeOnboardingSettings);

  const createNewTemplate = useCallback(async () => {
    try {
      setCreatingTemplate(true);
      const currentTemplates = await OnboardingAPI.getOnboardingTemplates();
      let newTemplateName: string;
      for (let i = 1; ; i++) {
        const templateName = polyglot.t('OnboardingSettings.flowNumber', { num: i.toString() });
        if (currentTemplates.find((t) => t.name === templateName)) {
          continue;
        }
        newTemplateName = templateName;
        break;
      }
      const template = await OnboardingAPI.createOnboardingTemplate({
        name: newTemplateName,
        template: {
          basic: true,
        },
      });
      refresh?.();
      goToEditTemplate(routerHistory, template.templateId, 'new-template');
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
      } else {
        showMessage(
          polyglot.t('OnboardingSettings.errorMessages.create', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
      setCreatingTemplate(false);
    }
  }, [refresh, routerHistory, showMessage, polyglot]);

  return (
    <ContentWrapper loading={false} sx={{ ...spacing.pt20, pb: 0 }}>
      <SettingsSectionContent
        title={polyglot.t('OnboardingSettings.flows')}
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          hasScopes(OnboardingScopes.CREATE_TEMPLATE, scopesContext) && (
            <LoaderButton
              name={polyglot.t('OnboardingSettings.add')}
              type="button"
              onClick={() => createNewTemplate()}
              loading={creatingTemplate}
              colorVariant="primary"
              sizeVariant="small"
              style={{ width: 'fit-content', marginTop: '20px' }}
            />
          ),
        ]}
      >
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {templates.map((item) => (
                        <SettingsItemCard
                          key={item.name}
                          title={item.name}
                          boxSx={responsiveCardStyle}
                          contentItemsSets={[
                            {
                              name: `${Object.keys(item?.template)?.length || 0} item${pluralText(
                                Object.keys(item?.template)?.length || 0
                              )}`,
                              type: ContentItemType.chip,
                              textColor: 'DarkGrey',
                              backgroundColor: 'white',
                              border: 'middle',
                            },
                          ]}
                          boxActionDisabled={!hasScopes(OnboardingScopes.EDIT_TEMPLATE, scopesContext)}
                          boxAction={() => goToEditTemplate(routerHistory, item.templateId, 'edit-template')}
                          advanceActionHidden={!hasScopes(OnboardingScopes.DELETE_TEMPLATE, scopesContext)}
                          advanceActionOptions={[
                            {
                              label: 'Delete',
                              icon: <Trash />,
                              handler: (e) => {
                                try {
                                  OnboardingAPI.deleteOnboardingTemplate(item.templateId).then(refreshTemplates);
                                } catch (error) {
                                  showMessage(
                                    polyglot.t('OnboardingSettings.errorMessages.delete', {
                                      errorMessage: nestErrorMessage(error),
                                    }),
                                    'error'
                                  );
                                }
                                e?.stopPropagation();
                              },
                            },
                          ]}
                        />
                      ))}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
      <UpgradeToProModal
        isOpen={upgradeModalOpen}
        setIsDrawerOpen={(isOpen) => setUpgradeModalOpen(isOpen)}
        planName={PlanNames.PEOPLE_PRO}
        messageSuffix="proGeneric"
      />
    </ContentWrapper>
  );
};
