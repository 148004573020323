import React, { Dispatch, SetStateAction, useMemo } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_BILLING_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

interface UpgradeToProModalProps {
  readonly isOpen: boolean;
  readonly setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  readonly planName: string;
  readonly messageSuffix:
    | 'proApps'
    | 'proPeople'
    | 'proGeneric'
    | 'proMoney'
    | 'proPermissions'
    | 'proForms'
    | 'proTemplates'
    | 'proCustomisation';
  readonly markup?: React.JSX.Element;
  readonly seatsLimitError?: boolean;
}

export enum PlanNames {
  PEOPLE_PRO = 'People Pro',
  APPS_PRO = 'Apps Pro',
  DEVICES_PRO = 'Devices Pro',
  MONEY_PRO = 'Money Pro',
  TECH_PRO = 'Tech Pro',
  GROWTH_PRO = 'Growth Pro',
}

export const UPGRADE_REQUIRED_ERROR = 'PLAN UPGRADE REQUIRED';
export const SEAT_LIMIT_ERROR = 'SEATS LIMIT REACHED';

export const UpgradeToProModal = ({
  isOpen,
  setIsDrawerOpen,
  planName,
  messageSuffix,
  markup,
  seatsLimitError,
}: UpgradeToProModalProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const SUPPORT_EMAIL = 'support@zelt.app';
  const upgradeMessage = useMemo(() => {
    return {
      proApps: () => polyglot.t('UpgradeToProModal.proApps', { planName }),
      proPeople: () => polyglot.t('UpgradeToProModal.proPeople', { planName }),
      proMoney: () => polyglot.t('UpgradeToProModal.proMoney', { planName }),
      proPermissions: () => polyglot.t('UpgradeToProModal.proPermissions', { planName }),
      proForms: () => polyglot.t('UpgradeToProModal.proForms', { planName }),
      proTemplates: () => polyglot.t('UpgradeToProModal.proTemplates', { planName }),
      proCustomisation: () => polyglot.t('UpgradeToProModal.proCustomisation', { planName }),
      proGeneric: () => polyglot.t('UpgradeToProModal.proGeneric', { planName }),
    }[messageSuffix]();
  }, [polyglot, messageSuffix, planName]);

  const seatLimitMessage = useMemo(() => {
    return seatsLimitError ? polyglot.t('UpgradeToProModal.seatsLimitError') : null;
  }, [seatsLimitError, polyglot]);

  const handleButtonClick = () => {
    if (seatsLimitError) {
      // Open the mailto link to contact support
      const subject = encodeURIComponent(`Contract Plan - Seats limit reached`);
      const body = encodeURIComponent(
        `Hello Support,\n\nWe have reached our fixed seats limit on the current plan. Please assist us with upgrading our plan or increasing the seats limit.\n\nThank you.`
      );
      window.location.href = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
    } else {
      // Redirect to the billing settings if not a seat limit error
      routerHistory.push(generatePath(SETTINGS_BILLING_ROUTE));
    }
  };

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsDrawerOpen}>
      <Box sx={drawerContentSx}>
        <Typography variant="title2">
          {seatsLimitError ? polyglot.t('UpgradeToProModal.seatsLimitTitle') : polyglot.t('UpgradeToProModal.toPro')}
        </Typography>

        <Typography variant="caption" sx={{ lineHeight: '24px' }}>
          {seatsLimitError ? seatLimitMessage : polyglot.t('UpgradeToProModal.discoveredPro')}
          {!seatsLimitError && upgradeMessage}
          {markup}
        </Typography>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent fullWidth sizeVariant="medium" colorVariant="primary" onClick={handleButtonClick}>
            {seatsLimitError ? polyglot.t('UpgradeToProModal.contactSupport') : polyglot.t('UpgradeToProModal.upgrade')}
          </ButtonComponent>
        </Box>
      </Box>
    </DrawerModal>
  );
};
