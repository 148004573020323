import { useMemo, useState } from 'react';

import { ButtonComponent } from '@/v2/components/forms/button.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { JobPositionAddEditFormDrawer } from '@/v2/feature/job-position/job-position-settings/features/components/job-position-setting-add-edit-form-drawer.component';
import { JobPositionSettingTable } from '@/v2/feature/job-position/job-position-settings/features/components/job-position-table.component';
import { JobPositionForTable } from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { JobPositionEndpoints } from '@/v2/feature/job-position/job-position.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@/v2/styles/root.styles';

export const JobPositionSettingPage = () => {
  const { polyglot } = usePolyglot();
  const [postSave, setPostSave] = useState<boolean>(false);
  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);
  const [notificationToEdit, setJobPositionToEdit] = useState<JobPositionForTable | undefined>(undefined);

  const {
    data: jobPositions,
    isValidating: loadingJobPositions,
    mutate: refreshJobPositions,
  } = useApiClient(JobPositionEndpoints.listJobPositions(), { suspense: false });

  const nextPossibleId = useMemo(() => {
    if (!jobPositions) return Math.floor(Math.random() * 1000000).toString();

    const lastJobPosition = jobPositions.sort((a, b) => Number(a.internalCode) - Number(b.internalCode));
    const lastId = lastJobPosition[jobPositions.length - 1]?.internalCode;
    if (lastId && !Number.isNaN(Number(lastId))) {
      return String(Number(lastId) + 1);
    } else {
      // if not a valid number that can be incremented - generate random number for internal code
      // can be overridden by user if they want to
      return Math.floor(Math.random() * 1000000).toString();
    }
  }, [jobPositions]);

  const DEFAULT_NEW_JOB_POSITION: JobPositionForTable = {
    id: undefined,
    internalCode: nextPossibleId,
    title: '',
    description: '',
    levelId: undefined,
  };

  const refreshJobPositionData = async () => {
    if (!refreshJobPositions) return;
    setPostSave(false);
    await refreshJobPositions();
    setPostSave(true);
  };

  const onEditClick = (jobPosition: JobPositionForTable) => {
    setJobPositionToEdit(jobPosition);
    setEditDrawerOpen(true);
  };

  const onNewJobPositionClick = () => {
    setJobPositionToEdit(DEFAULT_NEW_JOB_POSITION);
    setEditDrawerOpen(true);
  };

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">Job positions</Typography>}
        showBack
        showAction
        actions={
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => onNewJobPositionClick()}>
            {polyglot.t('JobPositionSettingsPage.newPosition')}
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={false}>
        <JobPositionSettingTable
          jobPositions={jobPositions ?? []}
          loading={!postSave ? loadingJobPositions || loadingJobPositions : false}
          onEditClick={onEditClick}
        />

        {notificationToEdit && (
          <JobPositionAddEditFormDrawer
            isOpen={editDrawerOpen}
            setIsOpen={setEditDrawerOpen}
            jobPositionToEdit={notificationToEdit}
            onClose={() => {
              setEditDrawerOpen(false);
              setJobPositionToEdit(undefined);
            }}
            refreshJobPositions={refreshJobPositionData}
          />
        )}
      </ContentWrapper>
    </RootStyle>
  );
};
