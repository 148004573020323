import { useCallback, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE } from '@/lib/routes';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SurveyTemplateAPI, SurveyTemplateEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-template.api';
import { UpsertSurveyTemplateModal } from '@/v2/feature/growth/surveys/features/survey-template/survey-template-list/components/upsert-survey-template.component-modal';
import { SurveyTemplate } from '@/v2/feature/growth/surveys/interfaces/survey-template.interface';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const SurveyTemplateListPage = () => {
  const routerHistory = useHistory();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();
  const {
    data: templates,
    mutate: refreshTemplates,
    isLoading: templatesLoading,
  } = useApiClient(SurveyTemplateEndpoints.getSurveyTemplatesCompanyId(searchInput), { suspense: false });

  const deleteAction = useCallback(
    async (templateId: string) => {
      try {
        await SurveyTemplateAPI.deleteTemplatebyId(templateId);
        showMessage('Succesfully deleted the item', 'success');
        refreshTemplates?.();
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    },
    [refreshTemplates, showMessage, polyglot]
  );

  const getTemplateActionsOptions = useCallback(
    (original: SurveyTemplate) => {
      const menuOptions = [
        {
          icon: <Edit {...iconSize} />,
          handler: () => {
            routerHistory.push(
              generatePath(SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE, {
                templateId: original?.id,
              })
            );
          },
          label: 'Edit item',
          disabled: false,
        },
        {
          icon: <Trash {...iconSize} />,
          handler: () => {
            deleteAction(original.id);
          },
          label: 'Delete item',
          disabled: false,
        },
      ];
      return menuOptions;
    },
    [deleteAction, routerHistory]
  );

  return (
    <ContentWrapper loading={false}>
      <SettingsSectionContent
        title="Templates"
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsOpen(true)}>
            New template
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          loadingAll={templatesLoading}
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <TableSearch
                      query={searchInput}
                      handleChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {(templates || []).map((item) => {
                        const avatar =
                          item.companyId === null ? (
                            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
                              <PayrollLogoZelt height="0.9em" width="0.9em" padding=".25em" />
                              Zelt
                            </Stack>
                          ) : (
                            <UserCell userId={item.createdBy} />
                          );
                        return (
                          <SettingsItemCard
                            key={item.name}
                            title={item.name}
                            boxSx={responsiveCardStyle}
                            contentItemsSets={[
                              {
                                type: ContentItemType.component,
                                contentComponent: (
                                  <ChipComponent
                                    name={avatar}
                                    backgroundColor="white"
                                    textColor="DarkGrey"
                                    textVariant="caption"
                                    border="middle"
                                  />
                                ),
                              },
                              {
                                name: `${new LocalDate(item.createdAt).getDate().toLocaleDateString(undefined, {
                                  day: 'numeric',
                                  month: 'short',
                                  year: 'numeric',
                                })}`,
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                            ]}
                            boxAction={() => {
                              routerHistory.push(
                                generatePath(SETTINGS_GROWTH_SURVEYS_TEMPLATE_BYID_ROUTE, {
                                  templateId: item?.id,
                                })
                              );
                            }}
                            advanceActionHidden={!item.companyId}
                            advanceActionOptions={getTemplateActionsOptions(item)}
                          />
                        );
                      })}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>

      <UpsertSurveyTemplateModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        template={undefined}
      />
    </ContentWrapper>
  );
};
