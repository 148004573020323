import { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { AttendanceScheduleDto } from '@v2/feature/attendance/attendance.dto';
import { AttendanceSettingsNewScheduleDrawer } from '@v2/feature/attendance/settings/components/attendance-settings-new-schedule-drawer.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_ATTENDANCE_SCHEDULE_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';

interface AttendanceCompanySettingsAttendancePageProps {
  readonly attendanceSchedules: readonly AttendanceScheduleDto[];
  readonly refreshSchedules: () => Promise<void>;
}

export const AttendanceCompanySettingsAttendanceSchedulesPage = ({
  attendanceSchedules,
  refreshSchedules,
}: AttendanceCompanySettingsAttendancePageProps) => {
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const [loading, setLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <ContentWrapper loading={loading}>
      <SettingsSectionContent
        title={polyglot.t('SettingsRouterPageConfig.schedules')}
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent colorVariant="primary" sizeVariant="small" onClick={() => setIsOpen(true)}>
            {polyglot.t('AttendanceCompanySettingsAttendanceSchedulesPage.new')}
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {attendanceSchedules &&
                        attendanceSchedules.length > 0 &&
                        attendanceSchedules.map((sch) => {
                          return (
                            <SettingsItemCard
                              key={sch.name}
                              title={sch.name}
                              boxSx={responsiveCardStyle}
                              contentItemsSets={[
                                {
                                  name: sch.trackingType,
                                  type: ContentItemType.chip,
                                  textColor: 'DarkGrey',
                                  backgroundColor: 'white',
                                  border: 'middle',
                                },
                                {
                                  name: sch.timesheetType,
                                  type: ContentItemType.chip,
                                  textColor: 'DarkGrey',
                                  backgroundColor: 'white',
                                  border: 'middle',
                                },
                              ]}
                              boxAction={() => {
                                routerHistory.push(
                                  generatePath(SETTINGS_ATTENDANCE_SCHEDULE_ROUTE, { scheduleId: sch.id })
                                );
                              }}
                            />
                          );
                        })}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
      <AttendanceSettingsNewScheduleDrawer isOpen={isOpen} setIsOpen={setIsOpen} refresh={refreshSchedules} />
    </ContentWrapper>
  );
};
