import { useCallback, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { PayrollPayScheduleDto, PayRunSchedule } from '@shared/modules/payroll/payroll.types';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE } from '@/lib/routes';
import {
  ContentItem,
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import {
  formatDueDateMessage,
  formatPayrunPeriod,
} from '@/v2/feature/payroll/features/payroll-company/payroll-i18n.util';
import { navigateToPayrunsView } from '@/v2/feature/payroll/payroll-router.util';
import { PayrollAPI, PayrollEndpoints } from '@/v2/feature/payroll/payroll.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { useJune } from '@/v2/infrastructure/june/june.hook';

export const PayrollCompanyListPayrolls = () => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [loadingPayroll, setLoadingPayroll] = useState<number>();
  const { trackPage } = useJune();

  const { data } = useApiClient(PayrollEndpoints.getCompanyPayrolls(), { suspense: false });
  const payrolls = data?.items;

  const viewPayrunsForPayroll = useCallback(
    (payrollId: number) => {
      navigateToPayrunsView(routerHistory, 'push', payrollId);
    },
    [routerHistory]
  );

  const onPayrollStartClick = useCallback(
    async (payrollId: number) => {
      setLoadingPayroll(payrollId);
      try {
        const payrolls = await PayrollAPI.getCompanyPayrolls();
        const payroll = payrolls.items.find((p) => p.id === payrollId);
        if (!payroll) {
          throw new Error(polyglot.t('CompanyPayrollList.payrollNotFound'));
        }
        if (payroll.currentPayRun) {
          viewPayrunsForPayroll(payroll.id);
          return;
        }
        if (payroll.nextPayRun) {
          viewPayrunsForPayroll(payroll.id);
          return;
        }
        // if we don't have a current or next payrun defined, show the setup page
        routerHistory.push(generatePath(SETTINGS_MONEY_SALARY_PAYROLL_SETTINGS_ROUTE, { payrollId }));
      } catch (e) {
        setLoadingPayroll(undefined);
      }
    },
    [routerHistory, viewPayrunsForPayroll, polyglot]
  );

  const loading = !payrolls;

  useEffect(() => {
    trackPage('Payroll hub');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayrun = (payrun: PayRunSchedule | null, activePaySchedule: PayrollPayScheduleDto | null | undefined) => {
    if (payrun) return formatDueDateMessage(payrun.paymentDate, polyglot);
    if (activePaySchedule) return formatDueDateMessage(activePaySchedule.firstPaymentDate, polyglot);
    return [undefined, false];
  };

  return (
    <ContentWrapper loading={loading} sx={{ px: '40px' }}>
      <SettingsSectionContent title={polyglot.t('CompanyPayroll.salary')} contentWidth="100%" headerWidth="100%">
        <SettingsSubsectionContent
          loadingAll={loading}
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {(payrolls || []).map((item) => {
                        const activePaySchedule = item.schedule;
                        const payrun = item.currentPayRun ?? item.nextPayRun;
                        const [dueDateMessage, isOverdue] = getPayrun(payrun, activePaySchedule);

                        return (
                          <SettingsItemCard
                            key={item.displayName}
                            title={item.displayName}
                            boxSx={responsiveCardStyle}
                            boxAction={() => {
                              onPayrollStartClick(item.id);
                            }}
                            boxActionDisabled={!!loadingPayroll}
                            contentItemsSets={
                              [
                                {
                                  name: polyglot.t('CompanyPayroll.noOfEmployees', {
                                    smart_count: item.employeeCount,
                                  }),
                                  type: ContentItemType.chip,
                                  textColor: 'DarkGrey',
                                  backgroundColor: 'white',
                                  border: 'middle',
                                },
                                ...(dueDateMessage
                                  ? [
                                      {
                                        name: dueDateMessage,
                                        type: ContentItemType.chip,
                                        textColor: isOverdue ? 'Red' : 'DarkGrey',
                                        backgroundColor: 'white',
                                        border: 'middle',
                                      },
                                    ]
                                  : []),
                                ...(!activePaySchedule
                                  ? [
                                      {
                                        name: polyglot.t('CompanyPayrollList.noPayrollSchedule'),
                                        type: ContentItemType.chip,
                                        textColor: 'DarkGrey',
                                        backgroundColor: 'white',
                                        border: 'middle',
                                      },
                                    ]
                                  : []),
                                ...(payrun
                                  ? [
                                      {
                                        name: formatPayrunPeriod(payrun, polyglot.locale()),
                                        type: ContentItemType.chip,
                                        textColor: 'DarkGrey',
                                        backgroundColor: 'white',
                                        border: 'middle',
                                      },
                                    ]
                                  : []),
                                ...(!payrun && activePaySchedule
                                  ? [
                                      {
                                        name: formatPayrunPeriod(activePaySchedule, polyglot.locale()),
                                        type: ContentItemType.chip,
                                        textColor: 'DarkGrey',
                                        backgroundColor: 'white',
                                        border: 'middle',
                                      },
                                    ]
                                  : []),
                              ] as ContentItem[]
                            }
                          />
                        );
                      })}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
    </ContentWrapper>
  );
};
