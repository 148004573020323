import React, { useContext, useEffect, useRef, useState } from 'react';

import { SxProps, Theme } from '@mui/material';
import { PaymentsAPI } from '@v2/feature/payments/payments.api';
import {
  BankPaymentInstructionDto,
  PayrunPaymentsState,
  PayrunPaymentsStates,
  PayrunProcessStepState,
  PayrunProcessStepStates,
} from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface';
import { PayrollExternalApi } from '@v2/feature/payroll/payroll-external.api';
import { PayrollLocalApi } from '@v2/feature/payroll/payroll-local.api';
import { PayRunDto } from '@v2/feature/payroll/payroll.dto';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PAYMENT_COMPANY_ROUTE } from '@/lib/routes';
import { PayrunProcessingItem } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component';

interface PayrunPaymentsProps {
  readonly payRun: PayRunDto;
  readonly payrunPaymentsState: PayrunPaymentsState;
  readonly setPayrunPaymentsState: React.Dispatch<React.SetStateAction<PayrunPaymentsState>>;
  sx?: SxProps<Theme>;
}

export const PayrunPayments = ({
  payRun,
  payrunPaymentsState,
  setPayrunPaymentsState,
  sx,
}: PayrunPaymentsProps): JSX.Element => {
  const routerHistory = useHistory();
  const [accordionState, setAccordionState] = useState<PayrunProcessStepState>(PayrunProcessStepStates.pending);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const [showMessage] = useMessage();
  const [, dispatch] = useContext(GlobalContext);

  const csvRef = useRef<any>();
  const [csvFile, setCSVFile] = useState<{ name: string; data: string | BankPaymentInstructionDto | never[] }>({
    name: 'default.csv',
    data: [],
  });

  useEffect(() => {
    if (csvFile?.data && csvFile.name !== 'default.csv' && csvRef.current && csvRef.current.link) {
      // @ts-ignore
      csvRef.current.link.click();
    }
  }, [csvFile]);

  const downloadCSVFile = async () => {
    try {
      setIsDownloading(true);
      const req = await PayrollExternalApi.getBankPaymentInstructionsForPayrun(payRun.id, 'text/csv');
      setCSVFile({
        name: `payrun-${payRun.taxYear}-Month-${payRun.period}-wires.csv`,
        data: req.content ?? req,
      });
    } catch (error) {
      showMessage(`Could not download the payments CSV. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    (async () => {
      setAccordionState(PayrunProcessStepStates.pending);
      try {
        const payRunPaymentsDetails = await PayrollLocalApi.getPayrunPaymentsRecord(payRun.id);
        setPayrunPaymentsState(payRunPaymentsDetails.state);

        if (PayrunPaymentsStates.created === payRunPaymentsDetails.state) {
          setAccordionState(PayrunProcessStepStates.success);
          return;
        }

        const updatedPayrunPaymentsDetails = await PayrollLocalApi.createPayrunBankPaymentsForPayrun(payRun.id);
        setPayrunPaymentsState(updatedPayrunPaymentsDetails.state);
        setAccordionState(PayrunProcessStepStates.success);
      } catch (error) {
        setAccordionState(PayrunProcessStepStates.failure);
        showMessage(`Could not create payrun bank payments. ${nestErrorMessage(error)}`, 'error');
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payRun]);

  const createPayrunBankPayments = async () => {
    try {
      const updatedPayrunPaymentsDetails = await PayrollLocalApi.createPayrunBankPaymentsForPayrun(payRun.id);
      setPayrunPaymentsState(updatedPayrunPaymentsDetails.state);
      setAccordionState(PayrunProcessStepStates.success);
    } catch (error) {
      showMessage(`Error: Could not create payrun bank payments. ${nestErrorMessage(error)}`, 'error');
      setAccordionState(PayrunProcessStepStates.failure);
    }

    try {
      await PaymentsAPI.getAlerts(dispatch);
    } catch (error) {
      showMessage(`Error: Could not update alerts. ${nestErrorMessage(error)}`, 'error');
    }
  };

  const canDownload = payrunPaymentsState === PayrunPaymentsStates.created;

  return (
    <>
      <PayrunProcessingItem
        title="Payments"
        key="payments"
        description={
          {
            pending: 'Creating bank payments...',
            failure: `Payrun bank payments could not be generated.`,
            success: 'Payrun bank payments have been created. You can find them in the Payments section.',
            warning: 'Payrun bank payments generated a warning.',
          }[accordionState]
        }
        buttons={[
          {
            style: 'primary',
            label: 'Retry',
            show: accordionState !== PayrunProcessStepStates.success,
            onClick: () => createPayrunBankPayments(),
            type: 'button',
          },
          {
            style: 'secondary',
            label: 'Download',
            show: canDownload,
            loading: isDownloading,
            onClick: () => downloadCSVFile(),
            type: 'button',
          },
          {
            style: 'primary',
            label: 'Go to Payments',
            show: canDownload,
            onClick: () => routerHistory.push(PAYMENT_COMPANY_ROUTE),
            type: 'button',
          },
        ]}
        success={
          accordionState === PayrunProcessStepStates.pending
            ? undefined
            : accordionState === PayrunProcessStepStates.success
        }
        sx={sx}
      />
      <CSVLink
        ref={csvRef}
        filename={csvFile.name}
        data={Array.isArray(csvFile.data) || typeof csvFile.data === 'string' ? csvFile.data : []}
      />
    </>
  );
};
