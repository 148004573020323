import { useState } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { ReactComponent as Goals } from '@/images/app-icons/Goals.svg';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { NotificationModal } from '@/v2/components/theme-components/notification-modal.component';
import { OptionProps, StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography, TypographyVariant } from '@/v2/components/typography/typography.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const flexContainerStyle = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing.s2,
  justifyContent: 'space-between',
};

export const responsiveCardStyle = {
  flex: '1 1 100%',
  maxWidth: '50%',
  '@media (max-width: 768px)': {
    flex: '1 1 100%',
  },
};

export enum ContentItemType {
  text = 'text',
  chip = 'chip',
  component = 'component',
}

export type ContentItemText = {
  readonly name: string;
  readonly type: ContentItemType.text;
  readonly textColor: keyof typeof themeColors;
  readonly textVariant: TypographyVariant;
};

export type ContentItemComponent = {
  readonly type: ContentItemType.component;
  readonly contentComponent: JSX.Element;
};

export type ContentItem = ContentItemChip | ContentItemText | ContentItemComponent;

export type ContentItemChip = {
  readonly name: string;
  readonly type: ContentItemType.chip;
  readonly textColor: keyof typeof themeColors;
  readonly backgroundColor: keyof typeof themeColors;
  readonly border: keyof typeof borders;
};

interface ISettingsItemCard {
  readonly title: string | JSX.Element;
  readonly contentItemsSets?: ContentItem[];
  readonly boxWidth?: string | undefined;
  readonly boxAction?: (
    e?: React.MouseEvent<HTMLElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => void;
  readonly boxSx?: SxProps<Theme>;
  readonly boxActionDisabled?: boolean;
  readonly description?: string | JSX.Element;
  readonly advanceActionOptions?: OptionProps[];
  readonly advanceActionHidden?: boolean;
  readonly loading?: boolean;
}
export const SettingsItemCard = ({
  title,
  contentItemsSets,
  boxWidth,
  boxAction,
  boxSx,
  boxActionDisabled,
  description,
  advanceActionOptions,
  advanceActionHidden,
  loading,
}: ISettingsItemCard) => {
  const { polyglot } = usePolyglot();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return loading ? (
    loadingState()
  ) : (
    <Box
      sx={{
        width: boxWidth ?? '600px',
        borderRadius: radius.br10,
        border: borders.middle,
        padding: spacing.s2,
        cursor: boxAction && !boxActionDisabled ? 'pointer' : 'default',
        ':hover': boxActionDisabled ? undefined : { bgcolor: themeColors.TableHover },
        opacity: boxActionDisabled ? 0.5 : 1,
        bgcolor: boxActionDisabled ? themeColors.GreyHover : undefined,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: spacing.s3,
        boxSizing: 'border-box',
        ...boxSx,
      }}
      onClick={boxActionDisabled ? undefined : boxAction}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', gap: spacing.sm, minHeight: description ? '64px' : '0px' }}
        >
          <Typography variant="title4">{title}</Typography>
          <Typography variant="caption" color="DarkGrey">
            {description}
          </Typography>
        </Box>

        {advanceActionOptions &&
          advanceActionOptions.length &&
          !advanceActionHidden &&
          (advanceActionOptions.length === 1 ? (
            <IconButton
              colorVariant="secondary"
              sizeVariant="small"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                event.stopPropagation();
              }}
            >
              {advanceActionOptions[0]?.icon || <Goals {...iconSize} />}
            </IconButton>
          ) : (
            <StyledMenuComponent
              options={advanceActionOptions}
              actionButtonDetails={{
                type: 'iconButton',
                colorVariant: 'secondary',
                sizeVariant: 'small',
                title: 'actions',
                icon: <ActionsSmall {...iconSize} />,
              }}
            />
          ))}
      </Box>

      <Box sx={{ marginTop: 'auto' }}>
        {contentItemsSets && contentItemsSets.length && (
          <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', flexWrap: 'wrap' }}>
            {contentItemsSets.map((item, index) => {
              switch (item.type) {
                case ContentItemType.chip: {
                  return (
                    <ChipComponent
                      key={`chip-${index}`}
                      name={item.name}
                      showTooltip={true}
                      textColor={item.textColor}
                      backgroundColor={item.backgroundColor}
                      border={item.border}
                      style={{
                        display: item.name ? 'inline-flex' : 'none',
                        maxWidth: '120px',
                      }}
                      textSx={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        width: 'inherit',
                        textAlign: 'center',
                        display: 'block',
                        overflow: 'hidden',
                      }}
                    />
                  );
                }
                case ContentItemType.text: {
                  return (
                    <Typography key={`text-${index}`} color={item.textColor} variant={item.textVariant}>
                      {item.name}
                    </Typography>
                  );
                }
                case ContentItemType.component:
                  return item.contentComponent;
                default:
                  return null;
              }
            })}
          </Box>
        )}
      </Box>

      <NotificationModal
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        takeAction={async () => {
          if (advanceActionOptions) {
            advanceActionOptions[0]?.handler();
          }
        }}
        message="Are you sure you want to complete this action"
        callToAction={polyglot.t('General.yes')}
      />
    </Box>
  );
};

const loadingState = () => {
  return (
    <Box
      sx={{
        width: '600px',
        borderRadius: radius.br10,
        border: borders.activeGrey,
        padding: spacing.s2,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.sm, minHeight: '84px' }}>
          <SkeletonLoader variant="rectangular" width="130px" height={15} rowGap="5px" />
          <SkeletonLoader variant="rectangular" width="260px" height={15} rowGap="5px" />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', flexWrap: 'wrap' }}>
        <SkeletonLoader variant="rectangular" width="130px" height={15} rowGap="5px" />
        <SkeletonLoader variant="rectangular" width="130px" height={15} rowGap="5px" />
        <SkeletonLoader variant="rectangular" width="130px" height={15} rowGap="5px" />
      </Box>
    </Box>
  );
};
