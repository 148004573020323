import { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/material';

import {
  ContentItem,
  ContentItemChip,
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { ApprovalRuleDrawer } from '@/v2/feature/approval-rule/approval-rule-settings/components/approval-rule-drawer.component';
import { ApprovalRuleDto } from '@/v2/feature/approval-rule/approval-rule.dto';
import { getStepDescription, isApprovalRuleAutoApprove } from '@/v2/feature/approval-rule/approval-rule.util';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@/v2/infrastructure/i18n/i8n.util';
import { translateDefaultApprovalRule } from '@/v2/infrastructure/i18n/translate.util';

interface ComponentProps {
  readonly approvalRules: ApprovalRuleDto[];
  readonly refresh: () => Promise<void>;
}

export const ApprovalRulesList = ({ approvalRules, refresh }: ComponentProps) => {
  const { polyglot } = usePolyglot();
  const { getCachedUserById } = useCachedUsers();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedApprovalRule, setSelectedApprovalRule] = useState<ApprovalRuleDto | null>(null);
  const [searchInput, setSearchInput] = useState('');

  const filteredApprovalRules = useMemo(() => {
    if (!searchInput) return approvalRules;
    const search = searchInput.trim().toLowerCase();
    return approvalRules.filter((approvalRule) => {
      return approvalRule.name?.toLowerCase().includes(search);
    });
  }, [approvalRules, searchInput]);

  const getChipsFromApprovalRule = useCallback(
    (approvalRule: ApprovalRuleDto): ContentItem[] => {
      const chips: ContentItem[] = [];
      if (approvalRule.approvalSteps.length === 0 || isApprovalRuleAutoApprove(approvalRule)) {
        chips.push({
          type: ContentItemType.chip,
          name: 'Auto approval',
          textColor: 'DarkGrey',
          backgroundColor: 'white',
          border: 'middle',
        });

        return chips;
      }

      chips.push({
        type: ContentItemType.chip,
        name: polyglot.t('ApprovalRuleModule.noOfSteps', { smart_count: approvalRule.approvalSteps.length }),
        textColor: 'DarkGrey',
        backgroundColor: 'white',
        border: 'middle',
      });

      const labels = approvalRule.approvalSteps.map((approvalStep) =>
        getStepDescription(approvalStep, getCachedUserById, polyglot, 4)
      );

      chips.push(
        ...labels.map(
          (label) =>
            ({
              type: ContentItemType.chip,
              name: label,
              textColor: 'DarkGrey',
              backgroundColor: 'white',
              border: 'middle',
            } as ContentItemChip)
        )
      );

      return chips;
    },
    [polyglot, getCachedUserById]
  );

  return (
    <Box>
      <TableSearch
        query={searchInput}
        handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')}
        style={{ marginBottom: '16px' }}
      />
      <Box sx={flexContainerStyle}>
        {filteredApprovalRules.map((aRule) => (
          <SettingsItemCard
            key={aRule.id}
            title={aRule.name}
            description={aRule.isDefault ? translateDefaultApprovalRule(aRule.name, polyglot) : aRule.description || ''}
            boxSx={responsiveCardStyle}
            contentItemsSets={getChipsFromApprovalRule(aRule) as ContentItem[]}
            boxAction={() => {
              setSelectedApprovalRule(aRule);
              setIsDrawerOpen(true);
            }}
          />
        ))}
      </Box>

      <ApprovalRuleDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        approvalRule={selectedApprovalRule}
        afterClose={() => {
          setSelectedApprovalRule(null);
          setIsDrawerOpen(false);
        }}
        refresh={refresh}
      />
    </Box>
  );
};
