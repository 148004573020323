import {
  REPORT_COMPANY_REPORTS_OVERVIEW,
  REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE,
  REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE,
  REPORTS_COMPANY_DASHBOARD_ROUTE,
  REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE,
  REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE,
} from '@/lib/routes';
import { RoleRoutes } from '@/v2/feature/app-layout/features/v2/menu/menu.interface';

export const REPORT_REPORTS_OVERVIEW_ROUTES: RoleRoutes = {
  company: REPORT_COMPANY_REPORTS_OVERVIEW,
};

export const REPORT_REPORTS_V2_NEW_OVERVIEW_ROUTES: RoleRoutes = {
  company: REPORT_COMPANY_REPORTS_V2_OVERVIEW_ROUTE,
  team: REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE,
};

export const REPORT_REPORTS_DASHBOARD_ROUTES: RoleRoutes = {
  company: REPORTS_COMPANY_DASHBOARD_ROUTE,
};

export const REPORT_REPORTS_DASHBOARD_PEOPLE_ROUTES: RoleRoutes = {
  company: REPORTS_COMPANY_PEOPLE_ANALYTICS_ROUTE,
};

export const REPORTS_COMPANY_SALARY_ROUTE: RoleRoutes = {
  company: REPORTS_COMPANY_SALARY_ANALYTICS_ROUTE,
};
