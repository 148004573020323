import { Endpoint } from '@v2/infrastructure/api-client/api-client.interface';
import axios from 'axios';

import { ReportDto } from '@/v2/feature/reports/reports.dto';
import {
  CreateReport,
  CreateReportV2,
  DateType,
  PeopleFiltersObject,
  ReportColumnCategory,
  ReportConfig,
  ReportEntity,
  ReportFilterCategory,
  ReportResponse,
  SelectedColumnsRequest,
  SelectedFiltersRequest,
  UpdateReportOld,
  UpdateReportV2,
} from '@/v2/feature/reports/reports.interface';

export class ReportsAPI {
  static async generatePeopleCreatedChangeReport(
    reportData: ReportConfig
  ): Promise<{
    [key: string]: Record<string, string | number>[];
  }> {
    return (await axios.post('/apiv2/reports/people/change-reports', reportData)).data;
  }

  static async generatePeopleCreatedCurrentReport(
    reportData: ReportConfig
  ): Promise<Record<string, string | number>[]> {
    return (await axios.post('/apiv2/reports/people/current-records', reportData)).data;
  }

  static async generateTimeCreatedReport(
    reportData: ReportConfig
  ): Promise<{
    [key: string]: Record<string, string | number>[];
  }> {
    return (await axios.post('/apiv2/reports/time', reportData)).data;
  }

  static async generateAttendanceCreatedReport(
    reportData: ReportConfig
  ): Promise<{
    [key: string]: Record<string, string | number>[];
  }> {
    return (await axios.post('/apiv2/reports/attendance', reportData)).data;
  }

  static async generateDevicesCreatedReports(
    reportData: ReportConfig
  ): Promise<{
    [key: string]: Record<string, string | number>[];
  }> {
    return (await axios.post('/apiv2/reports/devices', reportData)).data;
  }

  static async exportReportById(id: number): Promise<{ allCsvs: string[]; allKeys: string[] }> {
    return (await axios.get(`/apiv2/reports/filtered?id=${id}`)).data;
  }

  static async exportCurrentReportById(id: number): Promise<string> {
    return (await axios.get(`/apiv2/reports/current-filtered?id=${id}`)).data;
  }

  static async saveReport(create: CreateReport) {
    return (await axios.post('/apiv2/reports/save', create)).data;
  }

  static async saveAdvancedReport(create: CreateReportV2) {
    return (await axios.post('/apiv2/reports/save-advanced', create)).data;
  }

  static async patchReport(id: number, update: UpdateReportOld): Promise<void> {
    await axios.patch(`/apiv2/reports/${id}/update`, update);
  }

  static async updateAdvancedReport(id: number, update: UpdateReportV2): Promise<void> {
    await axios.patch(`/apiv2/reports/${id}/update-advanced`, update);
  }

  static async patchReportDates(id: number, update: { dateType: DateType; start: string; end: string }): Promise<void> {
    await axios.patch(`/apiv2/reports/${id}/update-dates`, update);
  }

  static async deleteReport(id: number): Promise<number> {
    return (await axios.delete<number>(`/apiv2/reports/${id}`)).data;
  }

  static async generateTestReport(
    selectedColumns: SelectedColumnsRequest,
    selectedFilters: SelectedFiltersRequest
  ): Promise<{
    table?: any;
    query?: (string | undefined)[];
    result?: ReportResponse;
    customFieldsFilteredUsers?: number[];
    customFieldsData?: Record<number, any>;
  }> {
    return (await axios.post('/apiv2/reports/test', { selectedColumns, selectedFilters })).data;
  }

  static async generateReport(
    selectedColumns: SelectedColumnsRequest,
    selectedFilters: SelectedFiltersRequest,
    effectiveDate: string,
    target: 'all' | 'team'
  ): Promise<ReportResponse> {
    return (
      await axios.post(`/apiv2/reports/generate/advanced?target=${target}`, {
        selectedColumns,
        selectedFilters,
        effectiveDate,
      })
    ).data;
  }
}

export class ReportsEndpoints {
  static getDevicesReportsFiltersOptions(): Endpoint<Record<string, string[]>> {
    return {
      url: '/apiv2/reports/devices/filters-options',
    };
  }

  static getPeopleReportsFiltersOptions(): Endpoint<PeopleFiltersObject> {
    return {
      url: '/apiv2/reports/people/filters-options',
    };
  }

  static getPeopleReportsAdditionalColumnsOptions(): Endpoint<
    {
      label: string;
      value: string;
      entity: ReportEntity;
    }[]
  > {
    return {
      url: '/apiv2/reports/people/columns-additional-options',
    };
  }

  static getTestReportsFiltersOptions(): Endpoint<{
    filters: readonly ReportFilterCategory[];
    columns: readonly ReportColumnCategory[];
    defaultSelectedColumns: Partial<SelectedColumnsRequest>;
    defaultSelectedFilters: Partial<SelectedFiltersRequest>;
  }> {
    return { url: '/apiv2/reports/setup' };
  }

  static getGrowthReportsFiltersOptions(): Endpoint<{
    filters: readonly ReportFilterCategory[];
    columns: readonly ReportColumnCategory[];
    defaultSelectedColumns: Partial<SelectedColumnsRequest>;
    defaultSelectedFilters: Partial<SelectedFiltersRequest>;
  }> {
    return { url: '/apiv2/reports/growth/filters' };
  }

  static getAllReports(): Endpoint<ReportDto[]> {
    return { url: '/apiv2/reports/all' };
  }

  static getAllNewReports(target: 'all' | 'team'): Endpoint<ReportDto[]> {
    return { url: `/apiv2/reports/all/new?target=${target}` };
  }

  static getReportById(id: number): Endpoint<ReportDto> {
    return { url: `/apiv2/reports/${id}` };
  }
}
