import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { getDateString } from '@v2/components/forms/date-label.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { sortDate, sortString } from '@v2/components/table/table-sorting.util';
import { UserCell } from '@v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { ReportDeleteDrawer } from '@v2/feature/reports/reports-advanced/components/report-delete-drawer.component';
import { ReportsAPI } from '@v2/feature/reports/reports.api';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { exportReportCSV } from '@v2/feature/reports/util/report.util';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { LocalDate } from '@v2/util/local-date';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { ReactComponent as Report } from '@/images/side-bar-icons/Report.svg';
import { ReactComponent as Trash } from '@/images/side-bar-icons/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  REPORTS_COMPANY_CREATE_ROUTE,
  REPORTS_COMPANY_VIEW_REPORT_ROUTE,
  REPORTS_TEAM_CREATE_ROUTE,
  REPORTS_TEAM_VIEW_REPORT_ROUTE,
} from '@/lib/routes';

interface PageProps {
  readonly reports: ReportDto[];
  readonly isLoading: boolean;
  readonly refreshReportsList: () => Promise<void>;
  readonly target: 'all' | 'team';
}

export const ReportsAdvancedOverviewPage = ({ reports, refreshReportsList, isLoading, target }: PageProps) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const { getCachedUserById } = useCachedUsers();
  const [showMessage] = useMessage();

  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredData, setFilteredData] = useState<ReportDto[] | undefined>(undefined);

  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState<boolean>(false);
  const [reportToDelete, setReportToDelete] = useState<ReportDto | null>(null);

  useEffect(() => {
    if (!reports) return;

    let filteredData;
    if (searchInput.length) {
      filteredData = reports.filter((r) => r.name.includes(searchInput) || r.type.includes(searchInput));
      setFilteredData(filteredData);
    } else {
      setFilteredData(reports);
    }
  }, [searchInput, reports]);

  const exportCsv = useCallback(
    async (report: ReportDto) => {
      if (!report?.columns || !report?.filters) {
        showMessage('Could not find the report to be downloaded.', 'error');
        return;
      }
      try {
        const response = await ReportsAPI.generateReport(
          report.columns,
          report.filters,
          new LocalDate().toDateString(),
          target
        );
        exportReportCSV(report.name, response);
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    },
    [polyglot, showMessage, target]
  );

  const copyReport = useCallback(
    async (report: ReportDto) => {
      if (!report?.name || !report.columns || !report.filters) {
        showMessage('Could not find the report to be copied.', 'error');
        return;
      }

      const copyName = `${report.name} - Copy`;
      try {
        await ReportsAPI.saveAdvancedReport({ name: copyName, columns: report.columns, filters: report.filters });
        await refreshReportsList();
      } catch (error) {
        showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
      }
    },
    [refreshReportsList, polyglot, showMessage]
  );

  const columns = useMemo<ColumnDef<ReportDto, ReportDto>[]>(
    () => [
      {
        id: 'name',
        header: () => polyglot.t('ReportsPage.name'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.name),
        cell: ({ row: { original } }) => <div>{original.name ? <Box>{original.name}</Box> : <EmptyCell />}</div>,
      },
      {
        header: () => polyglot.t('ReportsPage.createdBy'),
        accessorFn: (row) => row,
        id: 'createdBy',
        maxSize: 200,
        minSize: 150,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => (item.createdBy ? getCachedUserById(item.createdBy)?.displayName : '')),
        cell: ({ row: { original } }) => {
          const user = getCachedUserById(original.createdBy);
          return (
            user && (
              <div>
                <UserCell userId={original.createdBy} />
              </div>
            )
          );
        },
      },
      {
        id: 'updatedAt',
        header: () => polyglot.t('ReportsPage.lastUpdated'),
        accessorFn: (row) => row,
        maxSize: 250,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortDate(a, b, (item) => item?.updatedAt),
        cell: ({ row: { original } }) => (
          <div>{original.updatedAt ? getDateString(original.updatedAt) : <EmptyCell />}</div>
        ),
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'actions',
        maxSize: 20,
        minSize: 20,
        enableSorting: false,
        cell: ({ row: { original } }) =>
          original.id ? (
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', cursor: 'default' }}
              onClick={(e) => e.stopPropagation()}
            >
              <StyledMenuComponent
                options={[
                  {
                    icon: <Export {...iconSize} />,
                    handler: async () => exportCsv(original),
                    label: polyglot.t('General.exportAsCSV'),
                  },
                  {
                    icon: <Report {...iconSize} />,
                    handler: async () => {
                      await copyReport(original);
                    },
                    label: 'Save as new report',
                  },
                  {
                    icon: <Trash {...iconSize} />,
                    handler: () => {
                      setReportToDelete(original);
                      setIsDeleteDrawerOpen(true);
                    },
                    label: polyglot.t('General.delete'),
                  },
                ]}
                actionButtonDetails={{
                  type: 'iconButton',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: 'actions',
                  icon: <ActionsSmall {...iconSize} />,
                }}
              />
            </Box>
          ) : null,
      },
    ],
    [polyglot, getCachedUserById, exportCsv, copyReport]
  );

  const handleRowClick = useCallback(
    (row: Row<ReportDto>) => {
      if (target === 'all')
        routerHistory.push(generatePath(REPORTS_COMPANY_VIEW_REPORT_ROUTE, { reportId: row.original.id }));
      else routerHistory.push(generatePath(REPORTS_TEAM_VIEW_REPORT_ROUTE, { reportId: row.original.id }));
    },
    [routerHistory, target]
  );

  return (
    <RootStyle>
      <TopHeader
        title={<Typography variant="title2">{polyglot.t('ReportsPage.reports')}</Typography>}
        showAction
        actions={
          <ButtonComponent
            sizeVariant="small"
            colorVariant="primary"
            onClick={() =>
              target === 'all'
                ? routerHistory.push(REPORTS_COMPANY_CREATE_ROUTE)
                : routerHistory.push(REPORTS_TEAM_CREATE_ROUTE)
            }
          >
            {polyglot.t('ReportsPage.new')}
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={isLoading} sx={{ pt: 0, pb: 0, height: '100%' }} border={false}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setSearchInput(e.target.value);
            }}
          />
        </Box>
        <Box sx={spacing.mt20}>
          <BasicTable
            rowData={filteredData ? [...filteredData] : []}
            columnData={columns}
            loading={isLoading}
            rowClick={handleRowClick}
          />
        </Box>

        <ReportDeleteDrawer
          isOpen={isDeleteDrawerOpen}
          setIsOpen={setIsDeleteDrawerOpen}
          report={reportToDelete}
          refresh={refreshReportsList}
          onClose={() => {
            setReportToDelete(null);
          }}
          target={target}
        />
      </ContentWrapper>
    </RootStyle>
  );
};
