import { useMemo } from 'react';

import { Box } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { nanoid } from 'nanoid';

import { NonSensitiveOauthClientConfiguration, TokenResult } from '../../security.interface';

import { OauthClientEndpoints } from '@/api-client/oauth-client.api';
import { ReactComponent as ExternalLink } from '@/images/icons/external-link-alt-solid.svg';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { sortNumeric } from '@/v2/components/table/table-sorting.util';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  DescriptionLine,
  PaymentSettingsPageSection,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-section.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';
import { LocalDate } from '@/v2/util/local-date';

interface PageProps {
  oauthApp: NonSensitiveOauthClientConfiguration;
}

export const DeveloperHubCredentialsSettingsPage = ({ oauthApp }: PageProps) => {
  const { polyglot } = usePolyglot();
  const { data: activeTokens } = useApiClient(OauthClientEndpoints.getAllTokenPairsForClientId(oauthApp.clientId), {
    suspense: false,
  });

  const initiateCodeFlow = (client: NonSensitiveOauthClientConfiguration) => {
    const PARTNER_API_AUTHORISE_CODE_FLOW_ROUTE =
      '/partner-api/authorize?response_type=code&client_id=:clientId&state=:state&redirect_uri=:redirectUri';
    const codeFlowPath = PARTNER_API_AUTHORISE_CODE_FLOW_ROUTE.replace(':clientId', client.clientId)
      .replace(':state', nanoid(9))
      .replace(':redirectUri', client.redirectionUri);
    window.open(codeFlowPath, '_blank');
  };

  const columns = useMemo<ColumnDef<TokenResult, TokenResult>[]>(() => {
    return [
      {
        header: () => polyglot.t('DeveloperHub.accessToken'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'accessToken',
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.access_token}</Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('DeveloperHub.expiringOn'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'atExpiringOn',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.expiryForAT),
        cell: ({ row: { original } }) => (
          <Box>
            {original.expiryForAT ? (
              <Typography variant="caption">{new LocalDate(original.expiryForAT).toDateTimeString()}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        header: () => polyglot.t('DeveloperHub.refreshToken'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'refreshToken',
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.refresh_token}</Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('DeveloperHub.expiringOn'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'rtExpiringOn',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.expiryForRT),
        cell: ({ row: { original } }) => (
          <Box>
            {original.expiryForRT ? (
              <Typography variant="caption">{new LocalDate(original.expiryForRT).toDateTimeString()}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
      {
        header: () => polyglot.t('DeveloperHub.tokenGeneratedOn'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'tokenGeneratedOn',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.tokenPairGeneratedOn),
        cell: ({ row: { original } }) => (
          <Box>
            {original.tokenPairGeneratedOn ? (
              <Typography variant="caption">
                {new LocalDate(original.tokenPairGeneratedOn).toDateTimeString()}
              </Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
    ];
  }, [polyglot]);

  return (
    <PaymentSettingsPageSection title={polyglot.t('DeveloperHub.appCredentials')}>
      {/* onEdit={handleEditForName}> */}
      <Typography variant="caption">{polyglot.t('DeveloperHub.credentialsPageExplainer')}</Typography>
      <DescriptionLine field={polyglot.t('DeveloperHub.appId')} value={oauthApp.id} />
      <DescriptionLine field={polyglot.t('DeveloperHub.dateOfCreation')} value={dateAPItoDisplay(oauthApp.createdAt)} />
      <DescriptionLine
        field={polyglot.t('DeveloperHub.clientId')}
        value={
          oauthApp.clientId && {
            isLink: true,
            label: oauthApp.clientId,
            icon: <Copy {...iconSize} />,
            onClick: () => navigator.clipboard.writeText(oauthApp.clientId),
          }
        }
      />
      <DescriptionLine field={polyglot.t('DeveloperHub.clientSecret')} value={'*'.repeat(10)} />
      <ButtonComponent
        startIcon={<ExternalLink {...iconSize} />}
        sizeVariant="small"
        colorVariant="secondary"
        onClick={() => initiateCodeFlow(oauthApp)}
      >
        Code flow
      </ButtonComponent>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, mt: spacing.mt20, mb: spacing.mb20 }}>
        <Typography variant="title3" sx={{ color: themeColors.DarkGrey }}>
          {polyglot.t('DeveloperHub.activeTokens')}
        </Typography>
        {activeTokens?.length && activeTokens?.length > 0 ? (
          <BasicTable<TokenResult>
            rowData={activeTokens ?? []}
            columnData={columns}
            loading={false}
            hidePagination
            externalTdAndThPadding="0 0px"
            style={{ width: '900px' }}
          />
        ) : (
          <Box>
            <Typography variant="caption" color="Grey" sx={{ mt: '8px' }}>
              {polyglot.t('DeveloperHub.noTokensYet')}
            </Typography>
          </Box>
        )}
      </Box>
    </PaymentSettingsPageSection>
  );
};
