import { Extension } from '@tiptap/core';
import { EditorView } from '@tiptap/pm/view';
import { DOMSerializer } from 'prosemirror-model';
import { Plugin, PluginKey } from 'prosemirror-state';

import { cleanHTML } from '@/v2/components/forms/document-editor/tiptap/nodes/smart-field.node';

const handleCutCopy = (view: EditorView, event: ClipboardEvent) => {
  const { selection } = view.state;
  const { $from, $to } = selection;
  const fragment = view.state.doc.slice($from.pos, $to.pos);
  const serializer = DOMSerializer.fromSchema(view.state.schema);
  const div = document.createElement('div');
  div.appendChild(serializer.serializeFragment(fragment.content));
  const innerHTML = cleanHTML(div.innerHTML);
  div.innerHTML = innerHTML;

  event.clipboardData?.setData('text/html', innerHTML);
  event.clipboardData?.setData('text/plain', div?.textContent || '');

  event.preventDefault();
};

export const CopyExtension = Extension.create({
  name: 'copy',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('copy'),
        props: {
          handleDOMEvents: {
            copy(view, event) {
              handleCutCopy(view, event);
              return true;
            },
            cut(view, event) {
              handleCutCopy(view, event);
              const transaction = view.state.tr.delete(view.state.selection.from, view.state.selection.to);
              view.dispatch(transaction);
              return false;
            },
          },
        },
      }),
    ];
  },
});
