import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import { ContractAPI, ContractEndpoints } from '@/api-client/contract.api';
import { TemplateAPI } from '@/api-client/templates.api';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Close } from '@/images/fields/Close.svg';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Download } from '@/images/side-bar-icons/Download.svg';
import { ReactComponent as Eye } from '@/images/side-bar-icons/Eye.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  DASHBOARD_ROUTE,
  DOCUMENTS_COMPANY_ROUTE,
  DOCUMENTS_ME_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE,
  SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE,
  TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX,
} from '@/lib/routes';
import { DocumentEditor } from '@/v2/components/forms/document-editor/document-editor.component';
import { IconButton } from '@/v2/components/forms/icon-button.component';
import { SingleUserSelect } from '@/v2/components/forms/user-select/single-user-select.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { StyledTooltip } from '@/v2/components/theme-components/styled-tooltip.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { CompanySignatorySignSignContractModal } from '@/v2/feature/templates/components/company-signatory-sign-contract-modal.component';
import { ContractTemplateEditorActionsButton } from '@/v2/feature/templates/components/contract-template-editor-actions-button.component';
import { MissingPersonalEmployeeFieldsModal } from '@/v2/feature/templates/components/missing-personal-employee-fields-modal.component';
import { UserSignContractModal } from '@/v2/feature/templates/components/user-sign-contract-modal.component';
import '@/v2/feature/templates/pages/template-editor.scss';
import {
  ContractTemplate,
  RequiredContractFields,
  TemplatePageParam,
  UNPROCESSABLE_FONT_ERROR,
} from '@/v2/feature/templates/templates.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { base64Decode, toBase64 } from '@/v2/util/base64.util';

interface Props {
  readonly action?: 'send' | 'sign' | 'update_sign_send' | 'company_sign' | 'preview' | 'update_before_sending';
  readonly mode: 'edit' | 'readonly' | 'editForRecipient';
}

const EDIT_MODES = new Set(['edit', 'editForRecipient']);
const MODES_TO_HIDE_CLOSE_BUTTON = new Set(['editForRecipient']);

export const TemplateEditorPage = ({ mode, action }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [innerHtml, setInnerHtml] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [savingTemplate, setSavingTemplate] = useState<boolean>(false);
  const [contractTemplate, setContractTemplate] = useState<ContractTemplate>();
  const [signContractPreviewText, setSignContractPreviewText] = useState<string>('');
  const [signaturePendingForCurrentUser, setSignaturePendingForCurrentUser] = useState<boolean>(false);
  const [witnessPendingForCurrentUser, setWitnessPendingForCurrentUser] = useState<boolean>(false);
  const [showMessage] = useMessage();
  const { templateId, userId, contractId } = useParams<TemplatePageParam>();
  const [isSendingContract, setIsSendingContract] = useState<boolean>(false);
  const [missingFields, setMissingFields] = useState<RequiredContractFields>({});
  const [openMissingEmployeeFieldModal, setOpenMissingEmployeeFieldModal] = useState(false);
  const [openSignContractModal, setOpenSignContractModal] = useState<boolean>(false);
  const { nonTerminatedCachedUsers, getCachedUserById } = useCachedUsers();
  const routerHistory = useHistory();
  const routerLocation = useLocation<{
    returnPath: string;
    companySignatoryUserId: number;
    contractTemplateData: string | undefined;
  }>();
  const returnPath = routerLocation?.state?.returnPath || DOCUMENTS_COMPANY_ROUTE;
  const companySignatoryUserId = routerLocation?.state?.companySignatoryUserId;
  const contractTemplateData = routerLocation?.state?.contractTemplateData;
  const [globalState] = useContext(GlobalContext);
  const { user } = globalState;
  const { data: contractFinalised } = useApiClient(
    contractId ? ContractEndpoints.contractFinalised(contractId) : { url: undefined },
    {
      suspense: false,
    }
  );
  const currentUserIsCompanySignatory =
    companySignatoryUserId === user?.userId || (userId && Number(userId) === user?.userId);
  const companySignMode = routerLocation.pathname.includes('company-sign');
  const recipientSignMode = routerLocation.pathname.includes(TEMPLATE_CONTRACT_SIGN_ROUTE_PREFIX);
  const [contractPreviewUserId, setContractPreviewUserId] = useState<number>();
  const hasMode = useMemo(() => EDIT_MODES.has(mode), [mode]);

  const refreshMissingFieldsForEmployee = useCallback(async (contractId: string) => {
    const missingFieldData = await ContractAPI.getEmployeeFieldsForContractById(contractId);
    setMissingFields(missingFieldData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contractHasMissingFields = Object.values(missingFields)?.some((v) => v === true);

  useEffect(() => {
    if (contractId && !companySignMode) refreshMissingFieldsForEmployee(contractId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInnerHtml(signContractPreviewText || contractTemplate?.templateText || '');
  }, [signContractPreviewText, contractTemplate?.templateText]);

  const contractRecipientPreviewOptions = useMemo(() => {
    return nonTerminatedCachedUsers.map((u) => ({ label: u.displayName, value: u.userId, ...u }));
  }, [nonTerminatedCachedUsers]);

  const getMissingFieldsFromContractTemplateData = useCallback(() => {
    return contractTemplateData ? JSON.parse(base64Decode(contractTemplateData))?.missingTemplateData : undefined;
  }, [contractTemplateData]);

  const checkPendingSignature = useCallback(async () => {
    if (contractId) {
      const { signaturePending, witnessPending } = await ContractAPI.checkIfSignatureAndWitnessPendingForCurrentUser(
        contractId
      );

      setSignaturePendingForCurrentUser(signaturePending);
      setWitnessPendingForCurrentUser(witnessPending);
    }
  }, [contractId]);

  const loadTemplate = useCallback(async () => {
    try {
      setLoading(true);

      if (templateId && userId) {
        let missingTemplateData;

        if (contractTemplateData) {
          missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
        }

        const compiledContractTemplate = await TemplateAPI.getContractPreview({
          templateId,
          contractRecipientUserId: Number(userId),
          missingTemplateFields: missingTemplateData || undefined,
          companySignatoryUserId: companySignatoryUserId ? Number(companySignatoryUserId) : undefined,
        });

        setSignContractPreviewText(compiledContractTemplate.compiledTemplate);
      }

      const contractTemplate = await TemplateAPI.getTemplateById({ templateId });
      setContractTemplate(contractTemplate);

      await checkPendingSignature();
    } catch (error) {
      showMessage(
        polyglot.t('TemplateEditorPage.errorMessages.loadTemplate', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [polyglot, templateId, userId, checkPendingSignature, contractTemplateData, companySignatoryUserId, showMessage]);

  const loadContract = useCallback(async () => {
    try {
      if (!contractId) {
        setLoading(false);
        return;
      }

      setLoading(true);
      const { compiledTemplate: contractPreviewText } = companySignMode
        ? await ContractAPI.getContractByIdForCompanySign({ contractId })
        : await ContractAPI.getContractPreviewById({ contractId });

      setSignContractPreviewText(contractPreviewText);

      await checkPendingSignature();
    } catch (error) {
      showMessage(
        polyglot.t('TemplateEditorPage.errorMessages.loadContract', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
      routerHistory.push(generatePath(DASHBOARD_ROUTE));
    } finally {
      setLoading(false);
    }
  }, [polyglot, contractId, companySignMode, checkPendingSignature, showMessage, routerHistory]);

  const loadUpdatedContract = useCallback(async () => {
    try {
      setLoading(true);

      if (templateId && userId && contractTemplateData) {
        const decodedTemplateData = JSON.parse(base64Decode(contractTemplateData));

        setSignContractPreviewText(decodedTemplateData.compiledTemplateText);
      }

      const contractTemplate = await TemplateAPI.getTemplateById({ templateId });
      setContractTemplate(contractTemplate);
    } catch (error) {
      showMessage(
        polyglot.t('TemplateEditorPage.errorMessages.loadTemplate', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [polyglot, showMessage, templateId, setSignContractPreviewText, userId, contractTemplateData]);

  const saveTemplate = useCallback(
    async (forPreview = false) => {
      try {
        if (!contractTemplate || !innerHtml) return;
        setSavingTemplate(true);
        const result = await TemplateAPI.updateTemplate({
          id: contractTemplate.id,
          templateText: innerHtml,
          type: contractTemplate.type,
        });
        if (result.valid) {
          showMessage('Template saved successfully', 'success');
        } else {
          if (result.errorStack?.includes(UNPROCESSABLE_FONT_ERROR)) {
            showMessage(`${result.errorMessage}: ${result.errorStack}`, 'warning');
          } else {
            showMessage(
              `WARNING: Incompatible fonts used in this template. Please review the template before sending or singing and ensure only Default Font is used.`,
              'warning'
            );
          }
        }
        setSavingTemplate(false);

        if (forPreview) return;
      } catch (error) {
        showMessage(
          polyglot.t('TemplateEditorPage.errorMessages.save', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setSavingTemplate(false);
      }
    },
    [polyglot, contractTemplate, innerHtml, showMessage]
  );

  const getEditorActionsOptions = () => {
    const actionOptions = [];
    actionOptions.push({
      icon: <Eye {...iconSize} />,
      handler: () => previewTemplate(),
      label: polyglot.t('TemplateEditorPage.preview'),
      disabled: false,
    });
    actionOptions.push({
      icon: <Download {...iconSize} />,
      handler: () => downloadPreview(),
      label: polyglot.t('General.download'),
      disabled: false,
    });
    return actionOptions;
  };

  const previewTemplate = useCallback(async () => {
    try {
      if (!contractTemplate || !innerHtml) return;

      saveTemplate(true);

      routerHistory.push(generatePath(SETTINGS_DOCUMENTS_TEMPLATE_PREVIEW_ROUTE, { templateId: contractTemplate.id }));
    } catch (error) {
      showMessage(
        polyglot.t('TemplateEditorPage.errorMessages.preview', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setSavingTemplate(false);
    }
  }, [polyglot, contractTemplate, innerHtml, routerHistory, saveTemplate, showMessage]);

  const editContractOnlyForRecipient = useCallback(async () => {
    try {
      setLoading(true);
      if (!contractTemplate || !innerHtml || !userId) return;

      const missingFieldData = getMissingFieldsFromContractTemplateData();

      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_EDIT_CONTRACT_ROUTE, {
          templateId: contractTemplate.id,
          userId,
        }),
        {
          companySignatoryUserId,
          contractTemplateData: toBase64({
            compiledTemplateText: innerHtml,
            missingTemplateData: missingFieldData || undefined,
          }),
        }
      );
    } catch (error) {
      showMessage(
        polyglot.t('TemplateEditorPage.errorMessages.edit', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setSavingTemplate(false);
      setLoading(false);
    }
  }, [
    polyglot,
    companySignatoryUserId,
    contractTemplate,
    innerHtml,
    getMissingFieldsFromContractTemplateData,
    routerHistory,
    showMessage,
    userId,
  ]);

  const downloadPreview = useCallback(async () => {
    try {
      let missingTemplateData;

      if (contractTemplateData) {
        missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
      }
      const response = await TemplateAPI.downloadContractTemplatePreview({
        templateId,
        contractRecipientUserId: Number(userId),
        missingTemplateFields: missingTemplateData || undefined,
        companySignatoryUserId: companySignatoryUserId ? Number(companySignatoryUserId) : undefined,
      });

      const recipientUser = getCachedUserById(Number(userId));

      // Extract the array of bytes from the response data
      const pdfBytes = new Uint8Array(response.data);

      // Create a Blob from the Uint8Array and specify the MIME type as 'application/pdf'
      const file = new Blob([pdfBytes], { type: 'application/pdf' });

      // Create an Object URL for the Blob.
      const fileURL = URL.createObjectURL(file);

      // Create a temporary link element and trigger a click event to download the PDF.
      let link = document.createElement('a');
      link.download = `${contractTemplate?.name}-${recipientUser?.displayName}-Draft.pdf`;
      link.href = fileURL;
      link.click();
    } catch (error) {
      showMessage(
        polyglot.t('TemplateEditorPage.errorMessages.loadTemplate', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setLoading(false);
    }
  }, [
    polyglot,
    companySignatoryUserId,
    contractTemplate?.name,
    contractTemplateData,
    getCachedUserById,
    showMessage,
    templateId,
    userId,
  ]);

  const previewTemplateAsUser = useCallback(
    async (userIdForPreview: number) => {
      try {
        setLoading(true);
        setContractPreviewUserId(userIdForPreview);

        if (templateId && userIdForPreview) {
          let missingTemplateData;

          if (contractTemplateData) {
            missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
          }

          const compiledContractTemplate = await TemplateAPI.getContractPreview({
            templateId,
            contractRecipientUserId: Number(userIdForPreview),
            missingTemplateFields: missingTemplateData || undefined,
          });

          setSignContractPreviewText(compiledContractTemplate.compiledTemplate);
        }

        const contractTemplate = await TemplateAPI.getTemplateById({ templateId });
        setContractTemplate(contractTemplate);
      } catch (error) {
        showMessage(
          polyglot.t('TemplateEditorPage.errorMessages.loadTemplate', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    },
    [polyglot, showMessage, templateId, setSignContractPreviewText, contractTemplateData]
  );

  const sendContract = useCallback(async () => {
    try {
      setIsSendingContract(true);

      let missingTemplateData;

      if (contractTemplateData) {
        missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
      }

      await ContractAPI.create({
        templateId,
        recipient: Number(userId),
        missingTemplateFields: missingTemplateData || undefined,
        companySignatory: companySignatoryUserId,
        updatedTemplateText: contractTemplateData ? toBase64({ compiledTemplateText: innerHtml }) : undefined,
      });

      showMessage(polyglot.t('TemplateEditorPage.successMessages.send'), 'success');

      routerHistory.push(returnPath);

      setIsSendingContract(false);
    } catch (error) {
      showMessage(
        polyglot.t('TemplateEditorPage.errorMessages.send', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setIsSendingContract(false);
    }
  }, [
    polyglot,
    contractTemplateData,
    innerHtml,
    templateId,
    userId,
    companySignatoryUserId,
    showMessage,
    routerHistory,
    returnPath,
  ]);

  const sendContractWithCompanySignature = useCallback(
    async (companySignatoryUserId: number, companySignature: string) => {
      try {
        setErrorMessage(undefined);
        setIsSendingContract(true);

        let missingTemplateData;

        if (contractTemplateData) {
          missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
          missingTemplateData = { ...missingTemplateData, company_signature: companySignature };
        }

        await ContractAPI.create({
          templateId,
          recipient: Number(userId),
          companySignature,
          missingTemplateFields: missingTemplateData || undefined,
          companySignatory: companySignatoryUserId,
          updatedTemplateText: contractTemplateData ? toBase64({ compiledTemplateText: innerHtml }) : undefined,
        });

        showMessage(polyglot.t('TemplateEditorPage.successMessages.send'), 'success');

        routerHistory.push(returnPath);

        setIsSendingContract(false);
      } catch (error) {
        setErrorMessage(
          error?.response?.data?.message ||
            error?.message ||
            'Issues encountered in creating this document, please try again'
        );
        showMessage(
          polyglot.t('TemplateEditorPage.errorMessages.send', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setIsSendingContract(false);
      }
    },
    [polyglot, contractTemplateData, innerHtml, templateId, userId, showMessage, routerHistory, returnPath]
  );

  const signContractWithCompanySignature = useCallback(
    async (_companySignatoryUserId: number, companySignature: string) => {
      try {
        setIsSendingContract(true);
        setErrorMessage(undefined);

        if (!contractId) showMessage(polyglot.t('TemplateEditorPage.errorMessages.missingId'), 'error');

        if (contractId) {
          await ContractAPI.companySign({
            contractId,
            signature: companySignature,
          });
        }

        showMessage(polyglot.t('TemplateEditorPage.successMessages.signed'), 'success');

        routerHistory.push(returnPath);

        setIsSendingContract(false);
      } catch (error) {
        setErrorMessage(
          error?.response?.data?.message ||
            error?.message ||
            'Issues encountered in creating this document, please try again'
        );
        showMessage(
          polyglot.t('TemplateEditorPage.errorMessages.send', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setIsSendingContract(false);
      }
    },
    [polyglot, contractId, showMessage, routerHistory, returnPath]
  );

  const userFinalisesContract = useCallback(async () => {
    try {
      setIsSendingContract(true);

      if (!contractId) showMessage(polyglot.t('TemplateEditorPage.errorMessages.missingId'), 'error');

      if (contractId) {
        await ContractAPI.finalise({
          contractId,
        });
      }

      showMessage(polyglot.t('TemplateEditorPage.successMessages.signed'), 'success');

      routerHistory.push(returnPath);

      setIsSendingContract(false);
    } catch (error) {
      showMessage(
        polyglot.t('TemplateEditorPage.errorMessages.send', { errorMessage: nestErrorMessage(error) }),
        'error'
      );
    } finally {
      setIsSendingContract(false);
    }
  }, [polyglot, contractId, showMessage, routerHistory, returnPath]);

  const saveSignAndSendContract = useCallback(async () => {
    if (currentUserIsCompanySignatory) {
      setOpenSignContractModal(true);
    } else {
      sendContract();
    }
  }, [currentUserIsCompanySignatory, sendContract]);

  const updateContractForRecipient = useCallback(async () => {
    setLoading(true);

    const missingFieldData = getMissingFieldsFromContractTemplateData();

    if (templateId && contractTemplateData && userId && innerHtml) {
      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE, {
          templateId,
          userId,
        }),
        {
          companySignatoryUserId,
          contractTemplateData: toBase64({
            compiledTemplateText: innerHtml,
            missingTemplateData: missingFieldData || undefined,
          }),
        }
      );
    }
    setLoading(false);
  }, [
    companySignatoryUserId,
    contractTemplateData,
    innerHtml,
    getMissingFieldsFromContractTemplateData,
    routerHistory,
    templateId,
    userId,
  ]);

  const hasCompiledTemplateText = (contractTemplateData: string | undefined) => {
    if (!contractTemplateData) return false;
    const decodedTemplateData = JSON.parse(base64Decode(contractTemplateData));
    return !!decodedTemplateData.compiledTemplateText;
  };

  useEffect(() => {
    (async () => {
      if (!hasCompiledTemplateText(contractTemplateData)) {
        await loadTemplate();
        await loadContract();
      } else {
        loadUpdatedContract();
      }
    })();
  }, [loadTemplate, loadContract, contractTemplateData, loadUpdatedContract, missingFields]);

  const missingFieldsHaveToBeFilledBeforeSigning =
    !recipientSignMode || (recipientSignMode && contractHasMissingFields);

  useEffect(() => {}, [contractTemplate, loading, mode, contractTemplateData, signContractPreviewText, action]);

  const handleCloseAction = () => {
    if (action === 'sign') {
      routerHistory.push(returnPath ? returnPath : generatePath(DOCUMENTS_ME_ROUTE));
    } else if (action === 'preview') {
      setSignContractPreviewText('');
      routerHistory.push(generatePath(SETTINGS_DOCUMENTS_TEMPLATE_EDIT_ROUTE, { templateId }));
      loadTemplate();
    } else if (action === 'update_before_sending' && contractTemplateData && userId) {
      let missingTemplateData = JSON.parse(base64Decode(contractTemplateData)).missingTemplateData;
      routerHistory.push(
        generatePath(SETTINGS_DOCUMENTS_TEMPLATE_CONTRACT_UPDATE_SIGN_SEND_ROUTE, {
          templateId,
          userId,
        }),
        {
          companySignatoryUserId,
          contractTemplateData: contractTemplateData
            ? contractTemplateData
            : toBase64({
                missingTemplateData,
              }),
        }
      );
    } else {
      routerHistory.push(generatePath(returnPath));
    }
  };

  const handleContractSignAction = useCallback(async () => {
    // handle company signatory mode
    if (companySignMode && currentUserIsCompanySignatory) {
      setOpenSignContractModal(true);
    } else if (recipientSignMode && signaturePendingForCurrentUser && action === 'sign' && !contractHasMissingFields) {
      // handle no missing fields mode for recipient
      setOpenSignContractModal(true);
    } else if (recipientSignMode && !signaturePendingForCurrentUser) {
      // handle no pending signature mode for recipient, but post filling missing fields
      await userFinalisesContract();
    } else if (recipientSignMode && signaturePendingForCurrentUser && contractHasMissingFields) {
      // handle missing fields mode for recipient
      setOpenMissingEmployeeFieldModal(true);
    }
  }, [
    action,
    companySignMode,
    contractHasMissingFields,
    currentUserIsCompanySignatory,
    recipientSignMode,
    signaturePendingForCurrentUser,
    userFinalisesContract,
  ]);

  return (
    <RootStyle style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <TopHeader
        sx={{ paddingLeft: '320px', width: '100%', ...spacing.mx16 }}
        title={
          <Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>
            {contractTemplate?.name} {action === 'preview' ? ' (preview)' : ''}
          </Typography>
        }
        showAction={true}
        actions={
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1, width: '100%' }}>
            {mode === 'readonly' && action === 'preview' && (
              <Box sx={{ display: 'flex', mb: spacing.mb10, width: '100%', minWidth: '300px' }}>
                <SingleUserSelect
                  name="contractPreviewUserId"
                  options={contractRecipientPreviewOptions}
                  onChange={(_, option) => {
                    if (option?.value) previewTemplateAsUser(option?.value);
                  }}
                  value={contractPreviewUserId}
                  label={polyglot.t('TemplateEditorPage.contractPreviewUserId')}
                  error={false}
                  helperText={false}
                />
              </Box>
            )}
            {mode === 'editForRecipient' && (
              <>
                <LoaderButton
                  name={polyglot.t('TemplateEditorPage.cancel')}
                  loading={loading || savingTemplate}
                  onClick={() => handleCloseAction()}
                  sizeVariant="small"
                  colorVariant="secondary"
                />
                <LoaderButton
                  name={polyglot.t('TemplateEditorPage.update')}
                  loading={loading || savingTemplate}
                  onClick={() => updateContractForRecipient()}
                  sizeVariant="small"
                  colorVariant="primary"
                />
              </>
            )}
            {mode === 'edit' && (
              <StyledMenuComponent
                options={getEditorActionsOptions()}
                actionButtonDetails={{
                  type: 'button',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: polyglot.t('General.actions'),
                  icon: <ArrowDown {...iconSize} />,
                  iconPosition: 'end',
                }}
              />
            )}

            {mode === 'edit' && (
              <LoaderButton
                name={polyglot.t('General.save')}
                loading={loading || savingTemplate}
                onClick={() => saveTemplate(false)}
                sizeVariant="small"
                colorVariant="primary"
              />
            )}

            {action === 'send' && (
              <>
                <ContractTemplateEditorActionsButton
                  onEditClick={() => editContractOnlyForRecipient()}
                  onDownloadClick={() => downloadPreview()}
                />
                <StyledTooltip
                  title={
                    <Typography sx={{ ...themeFonts.tooltip }}>
                      {polyglot.t('TemplateEditorPage.reviewContract')}
                    </Typography>
                  }
                >
                  <LoaderButton
                    name="Send"
                    loading={loading || isSendingContract}
                    onClick={() => sendContract()}
                    sizeVariant="small"
                    colorVariant="primary"
                  />
                </StyledTooltip>
              </>
            )}

            {mode === 'readonly' && action === 'update_sign_send' && (
              <>
                <ContractTemplateEditorActionsButton
                  onEditClick={() => editContractOnlyForRecipient()}
                  onDownloadClick={() => downloadPreview()}
                />
                {!contractFinalised && (
                  <StyledTooltip
                    title={
                      <Typography sx={{ ...themeFonts.tooltip }}>
                        {polyglot.t('TemplateEditorPage.reviewContract')}
                      </Typography>
                    }
                  >
                    <LoaderButton
                      name={currentUserIsCompanySignatory ? 'Sign' : 'Send'}
                      loading={loading || isSendingContract}
                      onClick={() => saveSignAndSendContract()}
                      sizeVariant="small"
                      colorVariant="primary"
                    />
                  </StyledTooltip>
                )}
              </>
            )}

            {mode === 'readonly' &&
              action === 'company_sign' &&
              signaturePendingForCurrentUser &&
              !contractFinalised && (
                <LoaderButton
                  name={currentUserIsCompanySignatory ? 'Sign' : 'Send'}
                  loading={loading || isSendingContract}
                  onClick={() => handleContractSignAction()}
                  sizeVariant="small"
                  colorVariant="primary"
                />
              )}

            {!contractFinalised &&
              ((action === 'sign' && signaturePendingForCurrentUser) || (action === 'sign' && recipientSignMode)) && (
                <LoaderButton
                  name={
                    signaturePendingForCurrentUser
                      ? polyglot.t('TemplateEditorPage.signedEletronically')
                      : polyglot.t('OnboardingUserStatus.send')
                  }
                  loading={loading}
                  onClick={() => handleContractSignAction()}
                  sizeVariant="small"
                  colorVariant="primary"
                />
              )}

            {!MODES_TO_HIDE_CLOSE_BUTTON.has(mode) && (
              <IconButton
                disabled={loading || savingTemplate}
                sizeVariant="small"
                colorVariant="secondary"
                onClick={() => handleCloseAction()}
              >
                <Close stroke={themeColors.DarkGrey} {...iconSize} />
              </IconButton>
            )}
          </Box>
        }
      />
      <DocumentEditor
        onChange={setInnerHtml}
        editable={hasMode}
        content={signContractPreviewText || contractTemplate?.templateText || ''}
      />

      {contractId && action && action === 'sign' && !missingFieldsHaveToBeFilledBeforeSigning && (
        <UserSignContractModal
          open={openSignContractModal}
          setOpen={setOpenSignContractModal}
          contractId={contractId}
          returnPath={returnPath}
          witnessPending={witnessPendingForCurrentUser}
        />
      )}

      {mode === 'readonly' && action && ['update_sign_send', 'company_sign'].includes(action) && (
        <CompanySignatorySignSignContractModal
          open={openSignContractModal}
          setOpen={setOpenSignContractModal}
          companySignatoryUserId={companySignatoryUserId}
          signAndSendContract={companySignMode ? signContractWithCompanySignature : sendContractWithCompanySignature}
          errorMessage={errorMessage}
        />
      )}

      {recipientSignMode &&
        missingFields &&
        contractHasMissingFields &&
        contractId &&
        signaturePendingForCurrentUser && (
          <MissingPersonalEmployeeFieldsModal
            open={openMissingEmployeeFieldModal}
            setOpen={setOpenMissingEmployeeFieldModal}
            templateId={templateId}
            contractId={contractId}
            missingFields={missingFields}
            contractRecipientId={user?.userId}
            refreshMissingFields={refreshMissingFieldsForEmployee}
          />
        )}
    </RootStyle>
  );
};
