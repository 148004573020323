import React, { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ReportConfigBackButton } from '@v2/feature/reports/reports-advanced/components/report-config-back-button.component';
import {
  ReportDisplayFormat,
  ReportDisplayFormatDateOptions,
  ReportDisplayFormatDateTimeOptions,
  ReportDisplayFormatNumberOptions,
} from '@v2/feature/reports/reports-formatting.util';
import {
  FilterTypeOption,
  ReportColumnCategory,
  ReportColumnType,
  ReportSelectedColumn,
  SelectedColumnsRequest,
} from '@v2/feature/reports/reports.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

const OperatorToLabel: Record<ReportColumnType, string> = {
  [ReportColumnType.PLAIN]: 'None',
  [ReportColumnType.AGE]: 'Time since',
  [ReportColumnType.SUM]: 'Total',
  [ReportColumnType.AVG]: 'Average',
  [ReportColumnType.MIN]: 'Min',
  [ReportColumnType.MAX]: 'Max',
  [ReportColumnType.COUNT]: 'Count',
};

function getColumnToBeReplaced(
  selectedColumns: SelectedColumnsRequest,
  column: {
    stub: string;
    col: string;
    type: ReportColumnType;
    order: number;
  } | null
): ReportSelectedColumn | null {
  if (!column) return null;
  const col =
    selectedColumns[column.stub]?.find(
      (c) => c.col === column.col && c.type === column.type && c.order === column.order
    ) ?? null;

  return col ? { ...col } : null;
}

interface SectionProps {
  readonly selectedColumns: SelectedColumnsRequest;
  readonly setSelectedColumns: React.Dispatch<React.SetStateAction<SelectedColumnsRequest>>;
  readonly reportColumns: readonly ReportColumnCategory[];
  readonly goBack: () => void;
  readonly column: {
    stub: string;
    col: string;
    type: ReportColumnType;
    order: number;
  } | null;
}

const FilterTypeOptionToReportDisplayFormatFunction: Record<
  FilterTypeOption,
  (reportColumnType: ReportColumnType | undefined) => ReportDisplayFormat
> = {
  [FilterTypeOption.string]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.none;
  },
  [FilterTypeOption.number]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.number1;
  },
  [FilterTypeOption.boolean]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.none;
  },
  [FilterTypeOption.intOption]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.none;
  },
  [FilterTypeOption.stringOption]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    return ReportDisplayFormat.none;
  },
  [FilterTypeOption.dateonly]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    if (opType === ReportColumnType.AGE) return ReportDisplayFormat.none;
    return ReportDisplayFormat.dateonly1;
  },
  [FilterTypeOption.datetime]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    if (opType === ReportColumnType.AGE) return ReportDisplayFormat.none;
    return ReportDisplayFormat.datetime1;
  },
  [FilterTypeOption.time]: (opType) => {
    if (opType === ReportColumnType.COUNT) return ReportDisplayFormat.number1;
    if (opType === ReportColumnType.AGE) return ReportDisplayFormat.none;
    return ReportDisplayFormat.none;
  },
};

function getDefaultOutputDisplayFormat(
  columnObjType: FilterTypeOption | undefined,
  reportColumnType: ReportColumnType | undefined
): ReportDisplayFormat | null {
  if (!columnObjType) return ReportDisplayFormat.none;

  const fn = FilterTypeOptionToReportDisplayFormatFunction[columnObjType];
  if (!fn) return ReportDisplayFormat.none;

  return fn(reportColumnType);
}

export const ColumnEditSection = ({
  selectedColumns,
  setSelectedColumns,
  reportColumns,
  column,
  goBack,
}: SectionProps) => {
  const { polyglot } = usePolyglot();
  const columnToBeReplaced = useMemo(() => getColumnToBeReplaced(selectedColumns, column), [selectedColumns, column]);

  const columnObj = useMemo(() => {
    if (!column) return null;
    const tableStub = reportColumns.find((c) => c.stub === column.stub);
    return tableStub?.columns[column.col];
  }, [column, reportColumns]);

  const aggregationOptions = useMemo(() => {
    return columnObj?.operators
      ? columnObj.operators.map((o) => ({ label: OperatorToLabel[o], value: o }))
      : [
          {
            label: OperatorToLabel[ReportColumnType.PLAIN],
            value: ReportColumnType.PLAIN,
          },
        ];
  }, [columnObj]);

  const onSubmit = useCallback(
    (values: Pick<ReportSelectedColumn, 'label' | 'type' | 'format'>) => {
      if (!column) return;
      setSelectedColumns((prev) => {
        const prevCopy = { ...prev };
        const index = prevCopy[column.stub].findIndex(
          (c) => c.col === column.col && c.type === column.type && c.order === column.order
        );
        if (index >= 0 && prevCopy[column.stub][index]) {
          prevCopy[column.stub][index].type = values.type;
          prevCopy[column.stub][index].label = values.label;
          // if (values.format && values.format !== ReportDisplayFormat.none)
          prevCopy[column.stub][index].format = values.format;
        }
        return prevCopy;
      });
      goBack();
    },
    [column, setSelectedColumns, goBack]
  );

  const formik = useFormik<Pick<ReportSelectedColumn, 'label' | 'type' | 'format'>>({
    initialValues: {
      label: columnToBeReplaced?.label ?? '',
      type: columnToBeReplaced?.type ?? ReportColumnType.PLAIN,
      format:
        columnToBeReplaced?.format ??
        getDefaultOutputDisplayFormat(columnObj?.type, columnToBeReplaced?.type) ??
        ReportDisplayFormat.none,
    },
    validationSchema: yup.object({
      label: yup.string().required(polyglot.t('ValidationMessages.requiredField')),
      type: yup
        .string()
        .oneOf(Object.values(ReportColumnType), polyglot.t('ValidationMessages.validValue'))
        .required(polyglot.t('ValidationMessages.requiredField')),
      format: yup
        .string()
        .nullable()
        .oneOf([null, ...Object.values(ReportDisplayFormat)], polyglot.t('ValidationMessages.validValue'))
        .notRequired(),
    }),
    onSubmit,
  });

  const formatOptions = useMemo(() => {
    if (!columnObj || !columnToBeReplaced) return [];
    return columnObj.type === 'number' || formik.values.type === ReportColumnType.COUNT
      ? ReportDisplayFormatNumberOptions
      : columnObj.type === 'dateonly'
      ? ReportDisplayFormatDateOptions
      : columnObj.type === 'datetime'
      ? ReportDisplayFormatDateTimeOptions
      : [];
  }, [columnObj, columnToBeReplaced, formik.values.type]);

  return (
    <FormikProvider value={formik}>
      <Form style={{ display: 'flex', flexDirection: 'column', gap: '10px', height: '100%' }}>
        <ReportConfigBackButton title="Columns" goBack={goBack} />

        <Typography variant="title4">Edit column</Typography>

        {columnObj && <TextfieldComponent label="Field" value={columnObj.label} disabled />}
        {columnToBeReplaced && (
          <TextfieldComponent
            label="Label"
            name="label"
            value={formik.values.label}
            onChange={formik.handleChange}
            error={formik.touched.label && !!formik.errors.label}
            helperText={(formik.touched.label && formik.errors.label) ?? ' '}
            endAdornment="none"
          />
        )}

        {columnObj && columnToBeReplaced && (
          <SelectComponent
            label="Aggregation"
            name="type"
            options={aggregationOptions}
            value={formik.values.type}
            onChange={(e) => {
              formik.handleChange(e);
              const reportColumnType = e.target.value as ReportColumnType;
              const format = getDefaultOutputDisplayFormat(columnObj?.type, reportColumnType);
              formik.setFieldValue('format', format);
            }}
            compareValue={formik.values.type}
            error={!!formik.errors.type && formik.touched.type}
            helperText={formik.touched.type && (formik.errors.type as string)}
            disabled={aggregationOptions.length <= 1}
          />
        )}

        {formatOptions.length > 0 && (
          <SelectComponent
            label="Format"
            name="format"
            options={formatOptions}
            value={formik.values.format}
            onChange={formik.handleChange}
            compareValue={formik.values.format}
            error={!!formik.errors.format && formik.touched.format}
            helperText={formik.touched.format && (formik.errors.format as string)}
          />
        )}

        {column && columnToBeReplaced && (
          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent sizeVariant="medium" colorVariant="primary" fullWidth>
              Update
            </ButtonComponent>
          </Box>
        )}
      </Form>
    </FormikProvider>
  );
};
