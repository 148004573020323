import React, { useEffect, useMemo, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { keyBy } from 'lodash';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { JobLevelEndpoints } from '@/v2/feature/job-level/job-level.api';
import {
  JobPosition,
  JobPositionForTable,
} from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { tableSecondaryIconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { truncateWithEllipses } from '@/v2/util/string.util';

interface JobPositionSettingTableProps {
  readonly jobPositions: JobPosition[];
  readonly loading: boolean;
  readonly onEditClick: (jobPosition: JobPositionForTable) => void;
}

export const JobPositionSettingTable = ({
  jobPositions,
  loading,
  onEditClick,
}: JobPositionSettingTableProps): React.JSX.Element => {
  const { data: jobLevels } = useApiClient(JobLevelEndpoints.listJobLevel(), { suspense: false });
  const [tableData, setTableData] = useState<JobPositionForTable[]>([]);
  const { nonTerminatedCachedUsers } = useCachedUsers();
  const [searchInput, setSearchInput] = useState<string>('');

  const jobsLookup = useMemo(() => {
    const groupMap = new Map();
    jobLevels?.forEach((item) => {
      if (!groupMap.has(item.trackName)) {
        groupMap.set(item.trackName, { counter: 1, options: [] });
      }
      const group = groupMap.get(item.trackName);
      group.options.push({
        value: item.levelId,
        label: `${item.trackName
          .split(' ')
          .map((word) => word[0])
          .join('')}
       ${group.counter}`,
      });
      group.counter += 1;
    });
    return keyBy(
      Array.from(groupMap.values()).flatMap((group) => group.options),
      'value'
    );
  }, [jobLevels]);

  useEffect(() => {
    const filteredData = [...jobPositions]
      .filter((n) => {
        const s = searchInput.toLowerCase();
        return (
          n.title?.toLowerCase().includes(s) ||
          n.description?.toLowerCase().includes(s) ||
          n.internalCode.toLowerCase().includes(s)
        );
      })
      .sort((a, b) => a.title.localeCompare(b.title));

    // Set the combined data to the tableData state
    setTableData(filteredData);
  }, [searchInput, nonTerminatedCachedUsers, jobPositions]);

  const columnData = useMemo<ColumnDef<JobPositionForTable, JobPositionForTable>[]>(() => {
    return [
      {
        header: () => 'Internal code',
        accessorFn: (row) => row,
        id: 'id',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.id),
        maxSize: 50,
        minSize: 50,
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return <div>{info.getValue().internalCode}</div>;
        },
      },
      {
        header: () => 'Level code',
        accessorFn: (row) => row,
        id: 'levelId',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.levelId),
        maxSize: 50,
        minSize: 50,
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return <div>{info.getValue().levelId ? jobsLookup[info.getValue().levelId as number]?.label : '-'}</div>;
        },
      },
      {
        header: () => 'Job title',
        accessorFn: (row) => row,
        id: 'title',
        maxSize: 100,
        minSize: 100,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.title),
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return <>{info.getValue()?.title}</>;
        },
      },
      {
        header: () => 'Description',
        accessorFn: (row) => row,
        id: 'description',
        maxSize: 120,
        minSize: 120,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.description),
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return (
            <>{info.getValue()?.description ? truncateWithEllipses(info.getValue()?.description ?? '', 50) : '–'}</>
          );
        },
      },
      {
        header: () => '',
        accessorFn: (row) => row,
        id: 'action',
        enableSorting: false,
        maxSize: 10,
        minSize: 10,
        cell: (info: CellContext<JobPositionForTable, JobPositionForTable>) => {
          return (
            <>
              <IconButton
                sx={tableSecondaryIconButtonSx}
                onClick={(e) => {
                  onEditClick(info.getValue());
                  e.stopPropagation();
                }}
              >
                <Edit {...iconSize} />
              </IconButton>
            </>
          );
        },
      },
    ];
  }, [jobsLookup, onEditClick]);

  return (
    <>
      <TableSearch
        query={searchInput}
        handleChange={(e) => {
          setSearchInput(e.target.value);
        }}
      />

      <Box sx={{ ...spacing.mt20 }}>
        <BasicTable rowData={[...tableData]} columnData={columnData} loading={loading} />
      </Box>
    </>
  );
};
