import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { CreateHolidayCalendarDrawer } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/components/create-holiday-calendar-drawer.component';
import { HolidayCalendarDto } from '@v2/feature/absence/subfeatures/settings/holiday-calendar/holiday-calendar.dto';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE } from '@/lib/routes';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import {
  ContentItem,
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { RootStyle } from '@/v2/styles/root.styles';
import { pluralText } from '@/v2/util/string.util';

interface PageProps {
  readonly holidayCalendars: HolidayCalendarDto[];
  readonly refresh: () => Promise<void>;
  readonly isLoading: boolean;
}

export const HolidayCalendarsListPage = ({ holidayCalendars, refresh, isLoading }: PageProps) => {
  const { polyglot } = usePolyglot();
  const history = useHistory();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');

  const filteredCalendars = useMemo(() => {
    if (!searchInput) return holidayCalendars;

    const search = searchInput.toLowerCase();
    return holidayCalendars.filter((c) => c.name.toLowerCase().includes(search));
  }, [holidayCalendars, searchInput]);

  return (
    <RootStyle>
      <ContentWrapper>
        <SettingsSectionContent
          title={polyglot.t('HolidayCalendarModule.publicHolidays')}
          headerWidth="100%"
          contentWidth="100%"
          buttons={[
            <ButtonComponent
              colorVariant="primary"
              sizeVariant="small"
              onClick={() => {
                setIsDrawerOpen(true);
              }}
            >
              {polyglot.t('HolidayCalendarModule.newCalendar')}
            </ButtonComponent>,
          ]}
        >
          <SettingsSubsectionContent
            sections={[
              {
                contentWidth: '100%',
                items: [
                  {
                    type: SectionItemType.Component,
                    value: (
                      <TableSearch
                        query={searchInput}
                        handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')}
                      />
                    ),
                  },
                  {
                    type: SectionItemType.Component,
                    value: (
                      <Box sx={flexContainerStyle}>
                        {(filteredCalendars || []).map((item) => {
                          return (
                            <SettingsItemCard
                              key={item.name}
                              title={item.name}
                              loading={isLoading}
                              boxSx={responsiveCardStyle}
                              boxAction={() => {
                                history.push(
                                  generatePath(SETTINGS_TIME_HOLIDAY_CALENDAR_GENERAL_ROUTE, { calendarId: item.id })
                                );
                              }}
                              contentItemsSets={[
                                ...(item.createdBy
                                  ? [
                                      {
                                        type: ContentItemType.component,
                                        contentComponent: (
                                          <ChipComponent
                                            name={<UserCell userId={item.createdBy} nameColor="DarkGrey" />}
                                            backgroundColor="white"
                                            textColor="DarkGrey"
                                            textVariant="caption"
                                            border="middle"
                                          />
                                        ),
                                      } as ContentItem,
                                    ]
                                  : []),
                                {
                                  name: `${item.holidays?.length || 0} holiday${pluralText(
                                    item.holidays?.length || 0
                                  )}`,
                                  type: ContentItemType.chip,
                                  textColor: 'DarkGrey',
                                  backgroundColor: 'white',
                                  border: 'middle',
                                },
                              ]}
                            />
                          );
                        })}
                      </Box>
                    ),
                  },
                ],
              },
            ]}
          />

          <CreateHolidayCalendarDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} refresh={refresh} />
        </SettingsSectionContent>
      </ContentWrapper>
    </RootStyle>
  );
};
