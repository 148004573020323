import React, { Dispatch, SetStateAction, Suspense, useState } from 'react';

import { Box } from '@mui/material';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceModelDto } from '@v2/feature/device/device.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DeviceAPI } from '@/v2/feature/device/device.api';
import { themeColors } from '@/v2/styles/colors.styles';

interface SuperAdminDeviceModelDrawerProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly deviceModels: readonly DeviceModelDto[];
  readonly refresh: () => void;
}

export const SuperAdminAddStockDeviceDrawer = ({
  isOpen,
  setIsOpen,
  deviceModels,
  refresh,
}: SuperAdminDeviceModelDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <SuperAdminAddDeviceDrawerContent deviceModels={deviceModels} refresh={refresh} close={() => setIsOpen(false)} />
    </Suspense>
  </DrawerModal>
);

interface AddToStockFormData {
  deviceModelId: number | undefined;
  quantity: number;
}

interface SuperAdminAddDeviceDrawerContentProps {
  readonly deviceModels: readonly DeviceModelDto[];
  readonly refresh: () => void;
  readonly close: () => void;
}

export const SuperAdminAddDeviceDrawerContent = ({
  deviceModels,
  refresh,
  close,
}: SuperAdminAddDeviceDrawerContentProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showMessage] = useMessage();

  const deviceModelOptions = deviceModels.map((deviceModel) => ({
    value: deviceModel.id,
    label: `Model ID: ${deviceModel.id} | ${deviceModel.modelName}`,
  }));

  const formik = useFormik<AddToStockFormData>({
    initialValues: {
      deviceModelId: undefined,
      quantity: 1,
    },
    validationSchema: yup.object({
      deviceModelId: yup.number().typeError('You must select a Device Model').required('Device Model is required'),
      quantity: yup.number().typeError('You must specify a number').required('Quantity is required'),
    }),
    onSubmit: async (values: AddToStockFormData) => addDeviceInStock(values),
  });

  const addDeviceInStock = async ({ deviceModelId, quantity }: AddToStockFormData) => {
    if (!deviceModelId || !quantity) {
      showMessage('Please select a device model and quantity.', 'error');
      return;
    }
    try {
      setLoading(true);
      await DeviceAPI.addDeviceInStockAsSuperadmin(deviceModelId, Number(quantity));
      showMessage('Device successfully added.', 'success');
      await refresh();
      close();
    } catch (error) {
      showMessage(`Something went wrong: ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Typography variant="title2">Add to stock</Typography>

        <SelectComponent
          name="deviceModelId"
          label="Select Model"
          options={deviceModelOptions}
          value={formik.values.deviceModelId ?? ''}
          onChange={formik.handleChange}
          compareValue={formik.values.deviceModelId ?? ''}
          error={!!formik.errors.deviceModelId && formik.touched.deviceModelId}
          helperText={formik.errors.deviceModelId && formik.touched.deviceModelId}
        />

        <TextfieldComponent
          label="Quantity"
          name="quantity"
          value={formik.values.quantity}
          onChange={formik.handleChange}
          error={formik.touched.quantity && Boolean(formik.errors.quantity)}
          helperText={formik.touched.quantity && formik.errors.quantity}
          size="small"
          inputProps={{ inputmode: 'numeric', pattern: '[0-9]*' }}
          endAdornment="none"
        />

        <Box sx={buttonBoxDrawerSx}>
          <LoaderButton
            name="Add device in stock"
            loading={loading}
            disabled={!formik.values.deviceModelId || !formik.values.quantity}
            fullWidth
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
