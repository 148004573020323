import React from 'react';

import { Box } from '@mui/material';
import { StyledTooltip } from '@v2/components/theme-components/styled-tooltip.component';
import { iconSize } from '@v2/styles/menu.styles';

import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';

export const ReportsAlertMissingScopes = ({ tooltip }: { tooltip: string }) => {
  return (
    <StyledTooltip placement="top" title={tooltip}>
      <Box sx={iconSize}>
        <Mistake {...iconSize} />
      </Box>
    </StyledTooltip>
  );
};
