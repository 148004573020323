import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { TabFilterButtons } from '@v2/components/tab-filter-buttons.component';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell } from '@v2/components/table/empty-cell.component';
import { Typography } from '@v2/components/typography/typography.component';
import { PageConfig } from '@v2/feature/app-layout/features/main-content/layout.interface';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { SecondaryHeaderMenu } from '@v2/feature/app-layout/features/main-content/layouts/components/secondary-header-menu.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { DeviceEndpoints } from '@v2/feature/device/device.api';
import { DevicePossessionDto } from '@v2/feature/device/device.dto';
import { DeviceExternalMatching, DeviceOwnership } from '@v2/feature/device/device.interface';
import {
  getDeviceOwnerAsSuperadminByDevicePossession,
  getUserNamesKeyedByUserIds,
} from '@v2/feature/device/device.util';
import { DEBOUNCE_300_MS } from '@v2/feature/documents/documents.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { SuperAdminOverviewDeviceDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-overview-device-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { secondaryCTABtn } from '@v2/styles/buttons.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { pipe } from 'fp-ts/function';
import { keyBy } from 'lodash';
import CsvDownloader from 'react-csv-downloader';
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { Subject } from 'rxjs';
import * as RX from 'rxjs/operators';

import { SiteAPI } from '@/api-client/site.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { timeSince } from '@/lib/moment.lib';
import { iconSize } from '@/v2/styles/menu.styles';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

interface Props {
  readonly pageConfig: PageConfig;
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly companies: readonly SuperAdminCompanyInfo[];
}

export const SuperAdminHexnodeDevicesOverviewPage = ({ users, companies, pageConfig }: Props): JSX.Element => {
  const [devicePossessions, setDevicePossessions] = useState<DevicePossessionDto[]>([]);
  const [matchings, setMatchings] = useState<DeviceExternalMatching>({});
  const [devicePossessionToBeEdited, setDevicePossessionToBeEdited] = useState<DevicePossessionDto | null>(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [sites, setSitesById] = useState<{ [id: number]: SiteDto }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [showMessage] = useMessage();
  const [searchInput, setSearchInput] = useState('');
  const [filterString, setFilterString] = useState<string>('all');
  const [changeSearchInput$] = useState(() => new Subject<string>());
  const { data: superAdminDevicesResponse, mutate: refreshDevicePossessions } = useApiClient(
    DeviceEndpoints.getAllDevicesAsSuperAdmin(false),
    {
      suspense: false,
    }
  );
  // Fetch all required data when the component is mounted
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [sitesData] = await Promise.all([SiteAPI.listSitesAsSuperAdmin()]);
      setSitesById(keyBy(sitesData, 'id'));
      const devicePossessions = superAdminDevicesResponse?.devicePossessions?.items ?? [];
      const matchings = superAdminDevicesResponse?.matchings ?? {};
      const filteredDevicePossessions = devicePossessions.filter((dp) => dp.id in matchings);
      setDevicePossessions(filteredDevicePossessions);
      setMatchings(matchings);
    } catch (error) {
      showMessage(`Could not retrieve data. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage, superAdminDevicesResponse?.devicePossessions?.items, superAdminDevicesResponse?.matchings]);

  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);

  useEffect(() => {
    pipe(
      changeSearchInput$,
      RX.debounceTime(DEBOUNCE_300_MS),
      ($) => $.subscribe((value) => setSearchInput(value)),
      (s) => () => s.unsubscribe()
    );
    fetchData();
  }, [changeSearchInput$, fetchData]);

  const getCompanyNameById = useCallback(
    (companyId: number | null): string | undefined => {
      if (!companyId) return undefined;
      return companies.find((company) => company.companyId === companyId)?.name;
    },
    [companies]
  );

  const filterDevicePossessions = useCallback(
    (devicePossessions: DevicePossessionDto[]) =>
      devicePossessions.filter((devicePossession) => {
        const userName = getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sites);
        const modelName = devicePossession.device?.modelName;
        const serialNumber = devicePossession.device?.serialNumber;
        const companyName = getCompanyNameById(devicePossession.companyId ?? 0) ?? devicePossession.possessionType;

        return (
          userName?.toLowerCase().includes(searchInput.toLowerCase()) ||
          modelName?.toLowerCase().includes(searchInput.toLowerCase()) ||
          serialNumber?.toLowerCase().includes(searchInput.toLowerCase()) ||
          companyName?.toLowerCase().includes(searchInput.toLowerCase())
        );
      }),
    [userNames, sites, searchInput, getCompanyNameById]
  );

  const formatDevicePossessionForCsv = (devicePossessions: DevicePossessionDto[]) =>
    devicePossessions.map((devicePossession) => ({
      possessionId: devicePossession.id,
      deviceId: devicePossession?.device?.id,
      company: getCompanyNameById(devicePossession.companyId) ?? devicePossession.possessionType,
      ownedBy: getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sites),
      serialNumber: devicePossession.device?.serialNumber || '',
      modelName: devicePossession.device?.modelName || '',
      isEnrolled: devicePossession.device?.enrollmentStatus === 'enrolled',
      lastCheckIn: devicePossession.device?.lastCheckIn
        ? timeSince(new Date(devicePossession.device?.lastCheckIn))
        : '',
      price: `£${devicePossession.device?.price}`,
      contractLength: devicePossession.device?.contractLength
        ? `${devicePossession.device?.contractLength} months`
        : '',
    }));

  const tableData = useMemo(() => {
    const searchFilteredDevices = filterDevicePossessions(devicePossessions);

    if (filterString === 'all') return searchFilteredDevices;
    return searchFilteredDevices.filter((devicePossession) => devicePossession.device?.ownership === filterString);
  }, [devicePossessions, filterString, filterDevicePossessions]);

  const columnData = useMemo<ColumnDef<DevicePossessionDto, DevicePossessionDto>[]>(() => {
    return [
      {
        header: () => 'Possession Id',
        accessorFn: (row) => row,
        id: 'id',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return <Box>{possession.id}</Box>;
        },
        size: 75,
      },
      {
        header: () => 'Device Id',
        accessorFn: (row) => row,
        id: 'device.id',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          return possession.device ? <Box>{possession.device.id}</Box> : <EmptyCell />;
        },
        size: 55,
      },
      {
        header: () => 'Company',
        accessorFn: (row) => row,
        id: 'companyId',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const companyName = getCompanyNameById(possession.companyId) ?? possession.possessionType;
          return companyName ? <Box>{companyName}</Box> : <EmptyCell />;
        },
        size: 150,
      },
      {
        header: () => 'Owned By',
        accessorFn: (row) => row,
        id: 'possessionId',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const ownedBy = getDeviceOwnerAsSuperadminByDevicePossession(possession, userNames, sites);
          return <Box>{ownedBy}</Box>;
        },
        size: 150,
      },
      {
        header: () => 'Serial Number',
        accessorFn: (row) => row,
        id: 'device.serialNumber',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const serialNumber = possession.device?.serialNumber;
          return serialNumber ? <Box>{serialNumber}</Box> : <EmptyCell />;
        },
        size: 125,
      },
      {
        header: () => 'Model Name',
        accessorFn: (row) => row,
        id: 'device.modelName',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const modelName = possession.device?.modelName;
          return modelName ? <Box>{modelName}</Box> : <EmptyCell />;
        },
        size: 125,
      },
      {
        header: () => 'Encryption',
        accessorFn: (row) => row,
        id: 'device.encryptionEnabled',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const encryptionEnabled = possession.device?.encryptionEnabled;
          const isEnrolled = possession.device?.enrollmentStatus === 'enrolled';

          return encryptionEnabled ? (
            <CheckIcon color="success" />
          ) : isEnrolled ? (
            <WarningAmberIcon color="error" />
          ) : (
            <LinkOffIcon color="warning" />
          );
        },
        size: 75,
      },
      {
        header: () => 'Last Check-In',
        accessorFn: (row) => row,
        id: 'device.lastCheckIn',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const lastCheckIn = possession.device?.lastCheckIn;
          return lastCheckIn ? <Box>{timeSince(new Date(lastCheckIn))}</Box> : <EmptyCell />;
        },
        size: 100,
      },
      {
        header: () => 'Price',
        accessorFn: (row) => row,
        id: 'device.price',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const price = possession.device?.price;

          return price ? <Box>£{price}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Contract Length',
        accessorFn: (row) => row,
        id: 'device.contractLength',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const contractLength = possession.device?.contractLength;

          return contractLength ? <Box>{contractLength} months</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'Ownership',
        accessorFn: (row) => row,
        id: 'device.ownership',
        enableSorting: false,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const ownership = possession.device?.ownership;

          return ownership ? <Box>{ownership}</Box> : <EmptyCell />;
        },
        size: 75,
      },
      {
        header: () => 'External id',
        accessorFn: (row) => row,
        id: 'device.externalId',
        enableSorting: true,
        cell: (info: CellContext<DevicePossessionDto, DevicePossessionDto>) => {
          const possession: DevicePossessionDto = info.getValue();
          const externalId = possession.device?.id ? matchings[possession.device.id] : null;
          return externalId ? <Box>{externalId}</Box> : <EmptyCell />;
        },
        size: 100,
      },
    ];
  }, [getCompanyNameById, userNames, sites, matchings]);

  const refresh = useCallback(async () => {
    try {
      if (refreshDevicePossessions) {
        const response = await refreshDevicePossessions();
        const devicePossessions = response?.devicePossessions?.items ?? [];
        const matchings = superAdminDevicesResponse?.matchings ?? {};
        const filteredDevicePossessions = devicePossessions.filter((dp) => dp.id in matchings);
        setDevicePossessions(filteredDevicePossessions);
        setDevicePossessions(devicePossessions);
        setMatchings(response?.matchings ?? {});

        setDevicePossessionToBeEdited(devicePossessions.find((d) => d.id === devicePossessionToBeEdited?.id) ?? null);
      }
    } catch (error) {
      showMessage(`Could not retrieve devices. ${nestErrorMessage(error)}`, 'error');
    }
  }, [devicePossessionToBeEdited?.id, refreshDevicePossessions, showMessage, superAdminDevicesResponse?.matchings]);

  return (
    <BackofficeRootStyle>
      <TopHeader title="Devices" />
      {pageConfig?.header?.tabs && <SecondaryHeaderMenu tabs={pageConfig?.header?.tabs} />}

      <ContentWrapper loading={loading} secondLevel>
        {devicePossessions.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', ...spacing.mb20, gap: 2 }}>
              <TabFilterButtons
                filters={[
                  { name: 'All', value: 'all' },
                  { name: 'Rental', value: DeviceOwnership.Rental },
                  { name: 'Company', value: DeviceOwnership.Company },
                ]}
                filterValue={filterString}
                setFilterValue={setFilterString}
                hasSearch
                onFilterChange={({ filterValue, searchInput }) => {
                  setFilterString(filterValue);
                  setSearchInput(searchInput);
                }}
              />
            </Box>

            <CsvDownloader
              filename="hexnode_devices"
              separator=","
              datas={() => (formatDevicePossessionForCsv(filterDevicePossessions(tableData)) as unknown) as Datas}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: spacing.s1,
                  ...secondaryCTABtn,
                  cursor: 'pointer',
                  height: '20px',
                }}
              >
                <Export {...iconSize} />
                <Typography variant="caption">Export</Typography>
              </Box>
            </CsvDownloader>
          </Box>
        )}

        <BasicTable<DevicePossessionDto>
          rowData={tableData}
          columnData={columnData}
          rowClick={(row) => {
            setDevicePossessionToBeEdited(row.original);
            setIsEditDrawerOpen(true);
          }}
        />

        {devicePossessionToBeEdited && (
          <SuperAdminOverviewDeviceDrawer
            isOpen={isEditDrawerOpen}
            setIsOpen={setIsEditDrawerOpen}
            devicePossession={devicePossessionToBeEdited}
            setDevicePossession={setDevicePossessionToBeEdited}
            userNames={userNames}
            matchings={matchings}
            sites={sites}
            refresh={refresh}
          />
        )}
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
