import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';

import { AxiosInstance } from 'axios';
import * as E from 'fp-ts/lib/Either';
import { constVoid, identity, pipe } from 'fp-ts/lib/function';
import * as DIO from 'io-ts/lib/Decoder';
import { useHistory } from 'react-router-dom';

import { GlobalContext, MessageType } from '@/GlobalState';
import { useEventSource } from '@/hooks/event-source-hook';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { APPS_COMPANY_OVERVIEW_ROUTE } from '@/lib/routes';
import { AppIntegrationEndpoints } from '@/v2/feature/app-integration/app-integration.api';
import { AppRequestEndpoints } from '@/v2/feature/app-integration/features/app-request/app-request.api';
import { PaymentTypeSettingsEndpoints } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/payment-type-settings.api';
import { SEAT_LIMIT_ERROR, UPGRADE_REQUIRED_ERROR } from '@/v2/feature/user/components/upgrade-to-pro-modal.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { eventSourceErrorHandler } from '@/v2/infrastructure/push-events/push-events.util';
import { pusherClient } from '@/v2/infrastructure/pusher/pusher.hook';

const DIO_NotifiableError = DIO.struct({
  message: DIO.string,
  response: DIO.struct({
    status: DIO.literal(403, 401),
    data: DIO.struct({
      message: DIO.string,
      method: DIO.literal('GET', 'POST', 'PATCH', 'PUT', 'DELETE'),
    }),
  }),
});

const ErrorNotificationContext = createContext(null);

interface Props {
  readonly children: ReactNode;
  readonly axios: AxiosInstance;
}

export const ErrorNotificationProvider = ({ children, axios }: Props): JSX.Element => {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const [state] = useContext(GlobalContext);
  const { hasScopes, getScopesContext } = useScopes();
  const isAppsAdmin = hasScopes(['apps:all'], getScopesContext(state.user));
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const environmentPrefix = `${isProduction ? 'prod' : 'test'}`;
  const { data: isAccountingAppConfigured } = useApiClient(PaymentTypeSettingsEndpoints.accountingAppConfigured(), {
    suspense: false,
  });

  const BASE_APP_ACTIONS_CHANNEL = 'app-actions';
  const BASE_GOOGLE_CALENDAR_CHANNEL = 'google-calendar';
  const ACCOUNT_SUCCESSFULLY_CREATED_EVENT = 'account-creation-success';
  const ACCOUNT_GROUP_MEMBERSHIP_DELETION_SUCCESS_EVENT = 'account-group-membership-deletion-success';
  const ACCOUNT_GROUP_MEMBERSHIP_ADDITION_SUCCESS_EVENT = 'account-group-membership-addition-success';
  const ACCOUNT_CREATION_FAILED_EVENT = 'account-creation-failed';
  const ACCOUNT_CREATION_FAILED_EMAIL_DOMAIN_INCORRECT_EVENT = 'account-creation-failed-email-domain-incorrect';
  const ACCOUNT_CREATION_FAILED_MISSING_TOKEN_EXCEPTION_EVENT = 'account-creation-failed-missing-token-exception';
  const ON_DEMAND_CALENDAR_SYNC_FAILED_MISSING_TOKEN = 'google-calendar-on-demand-sync-failed-missing-token-exception';
  const ON_DEMAND_CALENDAR_SYNC_FAILED_OTHER_ERROR = 'google-calendar-on-demand-sync-failed-other-exception';
  const APP_REQUEST_CREATED = 'request-created';

  const EXPENSE_CHANNEL_NAME = `expense-actions-${environmentPrefix}-user-${state.user.userId}`;
  const EXPENSE_SYNC_FAILURE_EVENT_SOURCE_URL = `/apiv2/push-events/${EXPENSE_CHANNEL_NAME}-expense-external-sync-failed`;
  // const expenseExternalSyncHandler = (data: PushEvent) => {
  //   if (data.message && data.companyId === user.company.companyId && data.userId === user.userId) {
  //     // refreshOngoingCycles?.();
  //     return;
  //   }
  // };

  const { mutate: refreshAppRequests } = useApiClient(
    !isAppsAdmin ? { url: undefined } : AppRequestEndpoints.getAppRequestsForCompany(),
    {
      suspense: false,
    }
  );
  const { mutate: refreshUserApps } = useApiClient(
    !isAppsAdmin ? { url: undefined } : AppIntegrationEndpoints.getUserApps(state?.user?.userId),
    {
      suspense: false,
    }
  );

  const refreshRequests = useCallback(() => {
    setTimeout(() => {
      if (refreshAppRequests) refreshAppRequests();
    }, 1500);
  }, [refreshAppRequests]);

  const refreshAppsForUser = useCallback(() => {
    setTimeout(() => {
      if (refreshUserApps) refreshUserApps();
    }, 1500);
  }, [refreshUserApps]);

  const generalMessageHandling = useCallback(
    (data: { message: string; type: MessageType }) => {
      if (data && data.message && data.type) showMessage(data.message, data.type);
    },
    [showMessage]
  );

  const expenseSyncEventSourceURL = useMemo(() => {
    if (isAccountingAppConfigured) {
      return EXPENSE_SYNC_FAILURE_EVENT_SOURCE_URL;
    } else {
      return null;
    }
  }, [EXPENSE_SYNC_FAILURE_EVENT_SOURCE_URL, isAccountingAppConfigured]);

  useEventSource(expenseSyncEventSourceURL, {
    onMessage: generalMessageHandling,
    onError: eventSourceErrorHandler,
  });

  useEffect(() => {
    const appRequestCreationHandler = (data: { message: string; type: MessageType }) => {
      generalMessageHandling(data);
    };
    const missingTokenExceptionHandler = (data: { message: string; type: MessageType }) => {
      refreshAppsForUser();
      refreshRequests();
      generalMessageHandling(data);
      routerHistory.push(APPS_COMPANY_OVERVIEW_ROUTE);
    };
    const googleCalendarSyncExceptionHandler = (data: { message: string; type: MessageType }) => {
      generalMessageHandling(data);
    };
    const accountCreationSuccessHandler = (data: { message: string; type: MessageType }) => {
      generalMessageHandling(data);
      refreshRequests();
      refreshAppsForUser();
    };
    const accountCreationFailureHandler = (data: { message: string; type: MessageType }) => {
      generalMessageHandling(data);
      refreshRequests();
      refreshAppsForUser();
    };
    if (isAppsAdmin) {
      pusherClient
        .subscribe(`${BASE_APP_ACTIONS_CHANNEL}-${environmentPrefix}-company-${state.user.company.companyId}`)
        .bind(APP_REQUEST_CREATED, appRequestCreationHandler)
        .bind(ACCOUNT_SUCCESSFULLY_CREATED_EVENT, accountCreationSuccessHandler)
        .bind(ACCOUNT_CREATION_FAILED_EVENT, accountCreationFailureHandler)
        .bind(ACCOUNT_CREATION_FAILED_EMAIL_DOMAIN_INCORRECT_EVENT, accountCreationFailureHandler)
        .bind(ACCOUNT_GROUP_MEMBERSHIP_DELETION_SUCCESS_EVENT, generalMessageHandling)
        .bind(ACCOUNT_GROUP_MEMBERSHIP_ADDITION_SUCCESS_EVENT, generalMessageHandling)
        .bind(ACCOUNT_CREATION_FAILED_MISSING_TOKEN_EXCEPTION_EVENT, missingTokenExceptionHandler);
      pusherClient
        .subscribe(`${BASE_APP_ACTIONS_CHANNEL}-${environmentPrefix}-user-${state.user.userId}`)
        .bind(APP_REQUEST_CREATED, appRequestCreationHandler)
        .bind(ACCOUNT_SUCCESSFULLY_CREATED_EVENT, accountCreationSuccessHandler)
        .bind(ACCOUNT_CREATION_FAILED_EVENT, accountCreationFailureHandler);
      pusherClient
        .subscribe(`${BASE_GOOGLE_CALENDAR_CHANNEL}-${environmentPrefix}-company-${state.user.company.companyId}`)
        .bind(ON_DEMAND_CALENDAR_SYNC_FAILED_MISSING_TOKEN, googleCalendarSyncExceptionHandler)
        .bind(ON_DEMAND_CALENDAR_SYNC_FAILED_OTHER_ERROR, googleCalendarSyncExceptionHandler);
    } else {
      pusherClient.subscribe(`${BASE_APP_ACTIONS_CHANNEL}-${environmentPrefix}-user-${state.user.userId}`);
    }
  }, [
    environmentPrefix,
    generalMessageHandling,
    isAppsAdmin,
    refreshAppRequests,
    refreshAppsForUser,
    refreshRequests,
    refreshUserApps,
    routerHistory,
    state.user.company.companyId,
    state.user.userId,
  ]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(identity, (error: unknown) => {
      const notifiableErrorE = DIO_NotifiableError.decode(error);
      pipe(
        notifiableErrorE,
        E.fold(constVoid, (e) => {
          switch (e.response.status) {
            case 403: {
              // ONLY SHOW ERROR MESSAGE IF NOT REQUIRED TO UPGRADE
              if (e.response.data.message !== UPGRADE_REQUIRED_ERROR && e.response.data.message !== SEAT_LIMIT_ERROR) {
                showMessage(
                  `Oops. You don't have permissions to access a feature on this page. Please contact your admin. [${
                    e.response.data.message ?? e.message
                  }]`,
                  'error'
                );
              }
              break;
            }
            case 401: {
              routerHistory.push('/login');
              break;
            }
            default: {
              showMessage(`Service is unavailable at the moment. [${e.response.data.message ?? e.message}]`, 'error');
              break;
            }
          }
        })
      );
      return Promise.reject(error);
    });
    return () => axios.interceptors.response.eject(interceptor);
  }, [showMessage, axios, routerHistory]);

  return <ErrorNotificationContext.Provider value={null}>{children}</ErrorNotificationContext.Provider>;
};
