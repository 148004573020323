import React, { Dispatch, SetStateAction, useContext } from 'react';

import { Box } from '@mui/material';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ReassignDeviceUserForm } from '@v2/feature/device/components/device-details/reassign-device-form.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import { DevicePossessionDto, ReassignDeviceDataDto } from '@v2/feature/device/device.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { generatePath, useHistory } from 'react-router-dom';

import { GlobalContext, GlobalStateActions } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { DEVICES_COMPANY_DIRECTORY_ROUTE } from '@/lib/routes';

interface ReassignDeviceDrawerProps {
  readonly isOpen: boolean;
  readonly onClose: () => Promise<void>;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly devicePossession: DevicePossessionDto;
  readonly isInventory: boolean;
}

export const ReassignDeviceDrawer = ({
  isOpen,
  onClose,
  devicePossession,
  setIsOpen,
  isInventory,
}: ReassignDeviceDrawerProps): React.JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <ReassignDeviceDrawerContent devicePossession={devicePossession} closePage={onClose} isInventory={isInventory} />
  </DrawerModal>
);

interface ReassignDeviceDrawerContentProps {
  readonly devicePossession: DevicePossessionDto;
  readonly closePage: () => Promise<void>;
  readonly isInventory: boolean;
}

const ReassignDeviceDrawerContent = ({
  devicePossession,
  closePage,
  isInventory,
}: ReassignDeviceDrawerContentProps) => {
  const [showMessage] = useMessage();
  const [state, dispatch] = useContext(GlobalContext);
  const routerHistory = useHistory();

  const reassignDevice = async (currentDevicePossessionId: number, reassignDeviceData: ReassignDeviceDataDto) => {
    let isChangeOwnerRequested = false;
    try {
      await DeviceAPI.reassignDevice(currentDevicePossessionId, reassignDeviceData);
      isChangeOwnerRequested = true;
      showMessage('Reassign device action has been initiated successfully', 'success');

      routerHistory.push(generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE));
      const alertsForDevices = await DeviceAPI.getAlerts(state.user.userId);
      dispatch({
        type: GlobalStateActions.UPDATE_ALERTS,
        payload: { devices: alertsForDevices },
      });
      await closePage();
    } catch (error) {
      if (isChangeOwnerRequested) {
        showMessage(`Something went wrong. Please refresh the page. ${nestErrorMessage(error)}`, 'error');
      } else {
        showMessage(`Could not initiate device reassign action. ${nestErrorMessage(error)}`, 'error');
      }
    }
  };

  return (
    <Box sx={drawerContentSx}>
      <Typography variant="title2">{isInventory ? 'Return' : 'Reassign'}</Typography>{' '}
      <ReassignDeviceUserForm
        currentDevicePossession={devicePossession}
        reassignDevice={reassignDevice}
        isInventory={isInventory}
      />
    </Box>
  );
};
