import { useContext, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { getDetailByDomain } from '@v2/feature/dashboard/dashboard.util';
import { DeviceTransitActionItem } from '@v2/feature/dashboard/features/sections/user-todos/components/item-action-drawers/device-transit-action-item.component';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as Device } from '@/images/dashboard-icons/Device.svg';
import { TodoCard } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/to-do-card.components';
import { DeviceTransitTaskBigWidget, DeviceTransitTodo } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { UserAvatar } from '@/v2/feature/user/components/user-avatar.component';
import { iconSize } from '@/v2/styles/menu.styles';

export const DeviceTransitTaskComponent = ({
  todo,
  setLastChild,
  refreshTodos,
}: {
  todo: DeviceTransitTaskBigWidget;
  setLastChild: boolean;
  refreshTodos: () => Promise<void>;
}) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const { user: currentUser } = state;
  const { getCachedUserById } = useCachedUsers();
  const [selectedRow, setSelectedRow] = useState<DeviceTransitTodo | undefined>(undefined);

  const getTitle = (task: DeviceTransitTodo): string => {
    return getDetailByDomain(task, getCachedUserById, currentUser, polyglot);
  };

  return (
    <Box>
      {todo.devices.map((device, idx) => (
        <TodoCard
          key={`${device.id}-task-${idx}`}
          icon={<Device {...iconSize} />}
          title={
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '100%',
              }}
            >
              {getTitle(device)}
            </Typography>
          }
          actionOnclick={() => setSelectedRow(device)}
          showBorder={!setLastChild}
          userAvatar={device.userId ? <UserAvatar key={device.userId} userId={device.userId} size="xxxsmall" /> : <></>}
        />
      ))}

      {selectedRow && (
        <DeviceTransitActionItem
          deviceTransitTodo={selectedRow as DeviceTransitTodo}
          refresh={refreshTodos}
          afterClose={() => setSelectedRow(undefined)}
        />
      )}
    </Box>
  );
};
