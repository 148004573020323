import { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { AppDetailsEndpoints } from '@v2/feature/app-integration/features/app-details/app-details.api';
import { AuthEndpoints } from '@v2/feature/auth/auth.api';
import { SSOConfigureDrawer } from '@v2/feature/security/security-settings/components/sso-configure-drawer.component';
import { SSOApp, SSOAppsEnum } from '@v2/feature/security/security-settings/security.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import {
  SETTINGS_SECURITY_AUDIT_TRAIL_REPORT,
  SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE,
  SETTINGS_SECURITY_SSO_DETAIL_ROUTE,
} from '@/lib/routes';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';

export const SecuritySettingPage = () => {
  const { polyglot } = usePolyglot();
  const { trackPage } = useJune();

  const { data: mfaEnabled, isLoading: loadingMFa } = useApiClient(AuthEndpoints.mfaCheck(), {
    suspense: false,
  });

  const { data: ssoState, isLoading: loadingSSO, mutate: refreshSSO } = useApiClient(
    AppDetailsEndpoints.getSSOMetadata(),
    {
      suspense: false,
    }
  );

  // const [edit, setEdit] = useState<boolean>(false);
  const [editSSO, setEditSSO] = useState<boolean>(false);
  const routerHistory = useHistory();

  // const [generateDrawer, setGenerateDrawer] = useState<boolean>(false);
  // const [selectedClient, setSelectedClient] = useState<NonSensitiveOauthClientConfiguration | undefined>(undefined);

  useEffect(() => {
    trackPage('Company security settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshSSOState = useCallback(async () => {
    if (refreshSSO) await refreshSSO();
  }, [refreshSSO]);

  const { certificate: _certificate, apikey: _apikey, enabledEntry } = useMemo(() => {
    const gwEntry = ssoState?.find((eachEntry) => eachEntry.app === 'google-workspace');
    const ssoGwEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-google-workspace');
    const ssoAzureAdEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-azure-ad');
    const ssoOktaEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-okta');
    const enabledEntry = ssoState?.find((eachEntry) => eachEntry.state.enabled);

    const apikey = ssoOktaEntry?.state?.apikey ?? '';
    const ssoProvider = enabledEntry ? SSOAppsEnum[enabledEntry.app as SSOApp] : 'None';
    let certificate = '';
    if (gwEntry) certificate = gwEntry?.state?.certificate;
    if (ssoGwEntry) certificate = ssoGwEntry?.state?.certificate;
    if (ssoAzureAdEntry) certificate = ssoAzureAdEntry?.state?.certificate;

    return { certificate, apikey, enabledEntry, ssoProvider };
  }, [ssoState]);

  return (
    <ContentWrapper loading={loadingSSO || loadingMFa}>
      <SettingsSectionContent title={polyglot.t('SettingsRouterPageConfig.security')} contentWidth="100%">
        <SettingsSubsectionContent
          sections={[
            {
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      <SettingsItemCard
                        key={'sso'}
                        title={polyglot.t('SSOMetadata.singleSignOn')}
                        description={polyglot.t('SSOMetadata.subtitle')}
                        boxSx={responsiveCardStyle}
                        contentItemsSets={[
                          {
                            name: enabledEntry ? 'Enabled' : 'Disabled',
                            type: ContentItemType.chip,
                            textColor: enabledEntry ? 'DarkGreen' : 'darkRed',
                            backgroundColor: 'white',
                            border: 'middle',
                          },
                        ]}
                        boxAction={() => routerHistory.push(SETTINGS_SECURITY_SSO_DETAIL_ROUTE)}
                      />
                    </Box>
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      <SettingsItemCard
                        key={'audit-trail'}
                        title={polyglot.t('SecurityAuditReportSection.auditTrail')}
                        description={polyglot.t('SecurityAuditReportSection.subtitle')}
                        boxSx={responsiveCardStyle}
                        boxAction={() => routerHistory.push(SETTINGS_SECURITY_AUDIT_TRAIL_REPORT)}
                      />
                    </Box>
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      <SettingsItemCard
                        key={'developer-hub'}
                        title={polyglot.t('DeveloperHub.title')}
                        description={polyglot.t('DeveloperHub.subtitle')}
                        boxSx={responsiveCardStyle}
                        boxAction={() => routerHistory.push(SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE)}
                      />
                    </Box>
                  ),
                },
              ],
              contentWidth: '100%',
            },
          ]}
        />
      </SettingsSectionContent>
      <SSOConfigureDrawer
        isOpen={editSSO}
        setIsOpen={setEditSSO}
        onClose={() => setEditSSO(false)}
        mfaEnabled={!!mfaEnabled}
        ssoState={ssoState ?? []}
        onUpdate={async () => {
          await refreshSSOState();
        }}
      />
    </ContentWrapper>
  );
};
