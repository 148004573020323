import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { Typography } from '@v2/components/typography/typography.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import { OauthClientAPI } from '@/api-client/oauth-client.api';
import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { NonSensitiveOauthClientConfiguration } from '@/v2/feature/security/security-settings/security.interface';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';

interface DeleteCompanyEntityDrawerProps {
  readonly oauthApp: NonSensitiveOauthClientConfiguration;
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  readonly onDelete: () => void;
}

export const DeveloperHubDeleteClientConfirmDrawer = ({
  oauthApp,
  isOpen,
  setIsOpen,
  onDelete,
}: DeleteCompanyEntityDrawerProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();

  const [isDeleting, setIsDeleting] = useState(false);
  const [showMessage] = useMessage();

  const deleteOauthClient = useCallback(async (): Promise<void> => {
    try {
      setIsDeleting(true);
      await OauthClientAPI.deleteSpecificClient(oauthApp.clientId);
      showMessage(`${polyglot.t('DeveloperHub.successMessages.delete')}`, 'success');
      setIsOpen(false);
      onDelete();
      routerHistory.push(SETTINGS_SECURITY_DEVELOPER_HUB_ROUTE);
    } catch (error) {
      showMessage(
        `${polyglot.t('DeveloperHubClientGenerateDrawer.errorMessages.delete')}: ${nestErrorMessage(error)}`,
        'error'
      );
      setIsDeleting(false);
    }
  }, [oauthApp.clientId, showMessage, polyglot, setIsOpen, onDelete, routerHistory]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Box style={drawerContentSx}>
        <Typography variant="title2">{polyglot.t('DeveloperHub.clientTable.deleteConfirm')}</Typography>

        <Box sx={buttonBoxDrawerSx}>
          <ButtonComponent
            sizeVariant="medium"
            colorVariant="secondary"
            onClick={() => setIsOpen(false)}
            disabled={isDeleting}
            fullWidth
          >
            {polyglot.t('General.cancel')}
          </ButtonComponent>
          <LoaderButton
            name={polyglot.t('General.delete')}
            loading={isDeleting}
            onClick={deleteOauthClient}
            colorVariant="danger"
            sizeVariant="medium"
            fullWidth
          />
        </Box>
      </Box>
    </DrawerModal>
  );
};
