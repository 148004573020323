import { useCallback, useEffect, useState } from 'react';

import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import {
  HelperRouter,
  SUPER_ADMIN_HELPER_ROUTE,
} from '@v2/feature/super-admin/features/helper-dashboard/helper.router';
import { SuperAdminAppsPage } from '@v2/feature/super-admin/features/super-admin-apps/super-admin-apps.page';
import { SuperAdminDevicesRouter } from '@v2/feature/super-admin/features/super-admin-devices/super-admin-devices.router';
import { SuperAdminInsuranceRouter } from '@v2/feature/super-admin/features/super-admin-insurance/super-admin-insurance.router';
import { SuperAdminMdmMigratorPage } from '@v2/feature/super-admin/features/super-admin-mdm-migrator/super-mdm-miration.page';
import { SuperAdminRefinancingRouter } from '@v2/feature/super-admin/features/super-admin-refinancing/super-admin-refinancing.router';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { Redirect, Route, Switch } from 'react-router-dom';

import { SuperAdminPartnerAPIMonitoringRouter } from './features/super-admin-partner-api-monitoring/super-admin-partner-api-monitoring.router';
import { PublishRequestsBackofficePage } from '../security/security-settings/features/developer-hub/components/publish-requests-backoffice.page';

import { CompanyAPI } from '@/api-client/index.api';
import AlertError from '@/component/widgets/AlertError';
import { nestErrorMessage } from '@/lib/errors';
import {
  SUPER_ADMIN_APPS_ROUTE,
  SUPER_ADMIN_BILLING_ROUTE,
  SUPER_ADMIN_BILLING_V2_ROUTE,
  SUPER_ADMIN_COMPANIES,
  SUPER_ADMIN_DEVICES_ROUTE,
  SUPER_ADMIN_ID_CHECKS_ROUTE,
  SUPER_ADMIN_INSURANCE_ROUTE,
  SUPER_ADMIN_LINKEDIN_USER_DATA_FETCH_ROUTE,
  SUPER_ADMIN_MDM_MIGRATION_ROUTE,
  SUPER_ADMIN_MONITORING_ROUTE,
  SUPER_ADMIN_PARTNER_API_ROUTE,
  SUPER_ADMIN_PARTNER_APP_PUBLISH_REQUEST_API_ROUTE,
  SUPER_ADMIN_REFINANCING_ROUTE,
  SUPER_ADMIN_ROUTE,
  SUPER_ADMIN_SALARY_ROUTE,
  SUPER_ADMIN_STATISTICS_ROUTE,
  SUPER_ADMIN_USERS,
} from '@/lib/routes';
import { IndividualCheckBackofficePage } from '@/v2/feature/id-check/individual-check-backoffice.page';
import { MonitoringPage } from '@/v2/feature/monitoring/monitoring.page';
import { SuperAdminBillingRouter } from '@/v2/feature/super-admin/features/super-admin-billing/super-admin-billing.router';
import { SuperAdminBillingV2Router } from '@/v2/feature/super-admin/features/super-admin-billing-v2/super-admin-billing-v2-new.router';
import { SuperAdminCompaniesPage } from '@/v2/feature/super-admin/features/super-admin-companies/super-admin-companies.page';
import { SuperAdminLinkedinImportPage } from '@/v2/feature/super-admin/features/super-admin-linkedin-import/super-admin-linkedin-import.page';
import { SuperAdminOverviewPage } from '@/v2/feature/super-admin/features/super-admin-overview/super-admin-overview.page';
import { SuperAdminSalaryRouter } from '@/v2/feature/super-admin/features/super-admin-salary/super-admin-salary.router';
import { SuperAdminUsersPage } from '@/v2/feature/super-admin/features/super-admin-users/super-admin-users.page';
import { UserAPI } from '@/v2/feature/user/user.api';

export const SuperAdminRouter = (): JSX.Element => {
  const [users, setUsers] = useState<readonly UserDetailsSuperAdminDto[]>([]);
  const [activeCompanies, setActiveCompanies] = useState<readonly SuperAdminCompanyInfo[]>([]);
  const [error, setError] = useState<string>('');

  const refresh = useCallback(async (): Promise<void> => {
    try {
      const [{ users }, { companies }] = await Promise.all([
        UserAPI.getAllUsersAsSuperAdmin({ shouldLimitResult: 'false' }),
        CompanyAPI.getAllCompaniesAsSuperAdmin({ shouldLimitResult: 'false' }),
      ]);
      const activeCompanies = companies.filter((company) => company.isActive);

      setUsers(users);
      setActiveCompanies(activeCompanies);
    } catch (error) {
      setError(nestErrorMessage(error));
    }
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      {error && <AlertError title="There was an error while retrieving the users list." error={error} />}
      <Switch>
        <Route path={SUPER_ADMIN_DEVICES_ROUTE}>
          <SuperAdminDevicesRouter users={users} companies={activeCompanies} />
        </Route>
        <Route path={SUPER_ADMIN_USERS}>
          <SuperAdminUsersPage />
        </Route>
        <Route path={SUPER_ADMIN_COMPANIES}>
          <SuperAdminCompaniesPage />
        </Route>
        <Route path={SUPER_ADMIN_STATISTICS_ROUTE}>
          <SuperAdminOverviewPage />
        </Route>
        <Route path={SUPER_ADMIN_APPS_ROUTE}>
          <SuperAdminAppsPage />
        </Route>
        <Route path={SUPER_ADMIN_BILLING_ROUTE}>
          <SuperAdminBillingRouter />
        </Route>
        <Route path={SUPER_ADMIN_BILLING_V2_ROUTE}>
          <SuperAdminBillingV2Router />
        </Route>
        <Route path={SUPER_ADMIN_SALARY_ROUTE}>
          <SuperAdminSalaryRouter companies={activeCompanies} />
        </Route>
        <Route path={SUPER_ADMIN_LINKEDIN_USER_DATA_FETCH_ROUTE}>
          <SuperAdminLinkedinImportPage />
        </Route>
        <Route path={SUPER_ADMIN_MDM_MIGRATION_ROUTE}>
          <SuperAdminMdmMigratorPage />
        </Route>
        <Route path={SUPER_ADMIN_INSURANCE_ROUTE}>
          <SuperAdminInsuranceRouter companies={activeCompanies} users={users} />
        </Route>
        <Route path={SUPER_ADMIN_MONITORING_ROUTE}>
          <MonitoringPage />
        </Route>
        <Route path={SUPER_ADMIN_PARTNER_API_ROUTE}>
          <SuperAdminPartnerAPIMonitoringRouter />
        </Route>
        <Route path={SUPER_ADMIN_PARTNER_APP_PUBLISH_REQUEST_API_ROUTE}>
          <PublishRequestsBackofficePage />
        </Route>
        <Route path={SUPER_ADMIN_ID_CHECKS_ROUTE}>
          <IndividualCheckBackofficePage />
        </Route>
        <Route path={SUPER_ADMIN_REFINANCING_ROUTE}>
          <SuperAdminRefinancingRouter companies={activeCompanies} />
        </Route>
        <Route path={SUPER_ADMIN_HELPER_ROUTE} component={HelperRouter} />
        <Route path={SUPER_ADMIN_ROUTE}>
          <Redirect to={SUPER_ADMIN_USERS} />
        </Route>
      </Switch>
    </>
  );
};
