import { Fragment, useCallback, useContext } from 'react';

import { Box } from '@mui/material';
import { BenefitRequestTodoComponent } from '@v2/feature/dashboard/features/sections/user-todos/components/version-big/benefit-request-todo.component';
import { DeviceTransitTaskComponent } from '@v2/feature/dashboard/features/sections/user-todos/components/version-big/device-transit-task.component';
import { ExpenseRequestTodoComponent } from '@v2/feature/dashboard/features/sections/user-todos/components/version-big/expense-request-todo.component';
import { InvoiceRequestTodoComponent } from '@v2/feature/dashboard/features/sections/user-todos/components/version-big/invoice-request-todo.component';
import { PendingBenefitPaymentTodoComponent } from '@v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-benefit-payment-todo.component';
import { PendingPHDeletionRequestTodo } from '@v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-ph-deletion-request-todo.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { useHistory } from 'react-router-dom';

import { PendingSurveysTodo } from './components/version-big/pending-surveys-todo.component';

import { GlobalContext } from '@/GlobalState';
import { PEOPLE_ME_ACTION_ITEMS } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/components/widget-layout.component';
import { UserTodosEmptyState } from '@/v2/feature/dashboard/features/sections/user-todos/components/user-todos-empty-state.component';
import { AppRequestBigTodo } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/app-request-todo.component';
import { MissingDocumentsTodo } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/missing-documents-todo.component';
import { OverdueTaskTodo } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/overdue-task.todo.component';
import { PendingContractsTodo } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-contracts-todo.component';
import { PendingRequestTodo } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-request-todo.component';
import { PendingReviewsTodo } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/pending-reviews-todo.component';
import { SubmittedAttendanceTodo } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/submitted-attendance-todo.component';
import { SubmittedRequestBigTodo } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/submitted-request-todo.component';
import { UnfinishedAttendanceShiftTodo } from '@/v2/feature/dashboard/features/sections/user-todos/components/version-big/unfinished-attendance-shift-todo.component';
import '@/v2/feature/dashboard/features/sections/user-todos/user-todos.scss';
import { KindTypes, TodoWidgetBigReach } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const UserTodosVersionBig = ({
  todos,
  refreshtodoList,
  readOnly = false,
}: {
  todos: TodoWidgetBigReach | null | undefined;
  refreshtodoList: () => Promise<void>;
  readOnly?: boolean;
}) => {
  const { polyglot } = usePolyglot();

  const routerHistory = useHistory();
  const [globalState] = useContext(GlobalContext);
  const userId = globalState.user.userId;
  const limit = 5;

  const refreshTodos = useCallback(async () => {
    if (refreshtodoList) await refreshtodoList();
  }, [refreshtodoList]);

  return (
    <WidgetLayout size="big" readOnly={readOnly}>
      <Fragment>
        <Box
          sx={{
            width: '100%',
            '&:hover': { background: themeColors.transparency },
            borderRadius: radius.br10,
            cursor: 'pointer',
          }}
          onClick={() => routerHistory.push(PEOPLE_ME_ACTION_ITEMS)}
        >
          <div style={{ padding: spacing.p5 }}>
            <Typography variant="title2">{polyglot.t('UserTodosVersionBig.action')}</Typography>
          </div>
        </Box>
        <Box sx={{ height: '100%' }}>
          {Boolean(todos && todos?.personal?.totalCount === 0) ? (
            <UserTodosEmptyState />
          ) : (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'column',
              }}
            >
              {todos?.personal?.todosItems?.map((todo, idx) => {
                const setLastChild = todos?.personal?.todosItems.length === idx + 1;
                switch (todo.kind) {
                  case KindTypes.IncompleteTasks:
                    return (
                      <OverdueTaskTodo
                        key={`overdue-task-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.PendingBenefitPayment:
                    return (
                      <PendingBenefitPaymentTodoComponent
                        key={`benefit-payment-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.BenefitRequest:
                    return (
                      <BenefitRequestTodoComponent
                        key={`benefit-request-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.ExpenseRequest:
                    return (
                      <ExpenseRequestTodoComponent
                        key={`expense-request-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.InvoiceRequest:
                    return (
                      <InvoiceRequestTodoComponent
                        key={`invoice-request-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.PendingRequest:
                    return (
                      <PendingRequestTodo
                        key={`pending-req-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.PendingPHCancellation:
                    return (
                      <PendingPHDeletionRequestTodo
                        key={`pending-ph-req-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.SubmittedAttendance:
                    return (
                      <SubmittedAttendanceTodo
                        key={`submitted-req-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.UnfinishedAttendance:
                    return (
                      <UnfinishedAttendanceShiftTodo
                        key={`unfinished-shift-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.MissingDocuments:
                    return (
                      <MissingDocumentsTodo
                        key={`missing-docs-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        userId={userId}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.PendingContracts:
                    return (
                      <PendingContractsTodo
                        key={`pending-contracts-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        userId={userId}
                      />
                    );
                  case KindTypes.PendingReviews:
                    return (
                      <PendingReviewsTodo key={`pending-reviews-${idx}`} setLastChild={setLastChild} todo={todo} />
                    );
                  case KindTypes.PendingSurveys:
                    return (
                      <PendingSurveysTodo key={`pending-surveys-${idx}`} setLastChild={setLastChild} todo={todo} />
                    );
                  case KindTypes.PendingRequests:
                    return (
                      <AppRequestBigTodo
                        key={`app-request-${idx}`}
                        todo={todo}
                        currentUserId={userId}
                        setLastChild={setLastChild}
                      />
                    );
                  case KindTypes.SubmittedRequestForm:
                    return (
                      <SubmittedRequestBigTodo
                        key={`submitted-request-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  case KindTypes.DeviceTransit:
                    return (
                      <DeviceTransitTaskComponent
                        key={`device-transit-${idx}`}
                        todo={todo}
                        setLastChild={setLastChild}
                        refreshTodos={refreshTodos}
                      />
                    );
                  default:
                    return <></>;
                }
              })}

              {Boolean(todos?.personal?.totalCount && todos?.personal?.totalCount - limit > 0) && (
                <div
                  onClick={() => routerHistory.push(PEOPLE_ME_ACTION_ITEMS)}
                  role="button"
                  className="clickable"
                  style={{ padding: spacing.p5 }}
                >
                  <Typography variant="caption" sx={{ color: themeColors.DarkGrey }}>
                    {todos?.personal?.totalCount
                      ? polyglot.t('UserTodosVersionBig.more', {
                          smart_count: todos?.personal?.totalCount && todos?.personal?.totalCount - limit,
                        })
                      : ''}
                  </Typography>
                </div>
              )}
            </Box>
          )}
        </Box>
      </Fragment>
    </WidgetLayout>
  );
};
