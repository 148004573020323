import { useState } from 'react';

import { Box, Stack } from '@mui/material';

import { ReactComponent as Edit } from '@/images/new-theme-icon/Edit.svg';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { GrowthScaleFormModal } from '@/v2/feature/growth/growth-scale/components/growth-scale-form-modal.component';
import { GrowthScaleViewModal } from '@/v2/feature/growth/growth-scale/components/growth-scale-view-modal.component';
import { GrowthScaleEndpoints } from '@/v2/feature/growth/growth-scale/growth-scale.api';
import { GrowthScale } from '@/v2/feature/growth/growth-scale/growth-scale.interface';
import { PayrollLogoZelt } from '@/v2/feature/payroll/features/payroll-uk/components/payroll-logo-zelt.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { iconSize } from '@/v2/styles/menu.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { LocalDate } from '@/v2/util/local-date';

export const GrowthScalePage = () => {
  const { data: allScales, isLoading, mutate: refreshScales } = useApiClient(
    GrowthScaleEndpoints.getGrowthScalesByCompanyId(),
    {
      suspense: false,
    }
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [selectedScale, setSelectedScale] = useState<GrowthScale | undefined>(undefined);

  return (
    <ContentWrapper loading={false}>
      <SettingsSectionContent
        title="Scales"
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent colorVariant="primary" sizeVariant="small" onClick={() => setIsOpen(true)}>
            New scale
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          loadingAll={isLoading}
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {(allScales || []).map((item) => {
                        const avatar =
                          item.companyId === null ? (
                            <Stack sx={{ flexFlow: 'row', alignItems: 'center', gap: spacing.g8 }}>
                              <PayrollLogoZelt height="0.9em" width="0.9em" padding=".25em" />
                              Zelt
                            </Stack>
                          ) : (
                            <UserCell userId={item.createdBy} />
                          );
                        return (
                          <SettingsItemCard
                            key={item.type}
                            title={item.type}
                            boxSx={responsiveCardStyle}
                            contentItemsSets={[
                              {
                                type: ContentItemType.component,
                                contentComponent: (
                                  <ChipComponent
                                    name={avatar}
                                    backgroundColor="white"
                                    textColor="DarkGrey"
                                    textVariant="caption"
                                    border="middle"
                                  />
                                ),
                              },
                              {
                                name: `${new LocalDate(item.createdAt).getDate().toLocaleDateString(undefined, {
                                  day: 'numeric',
                                  month: 'short',
                                  year: 'numeric',
                                })}`,
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                            ]}
                            boxAction={() => {
                              setSelectedScale(item);
                              setIsViewOpen(true);
                            }}
                            advanceActionHidden={!item.companyId}
                            advanceActionOptions={[
                              {
                                icon: <Edit {...iconSize} />,
                                handler: () => {
                                  setSelectedScale(item);
                                  setIsOpen(true);
                                },
                                label: 'Edit item',
                                disabled: false,
                              },
                            ]}
                          />
                        );
                      })}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
      <GrowthScaleFormModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
          setTimeout(() => setSelectedScale(undefined), 300);
        }}
        afterClose={() => setSelectedScale(undefined)}
        growthScale={selectedScale}
        refresh={async () => {
          await refreshScales?.();
        }}
      />

      <GrowthScaleViewModal
        isOpen={isViewOpen}
        setIsOpen={setIsViewOpen}
        onClose={() => {
          setIsViewOpen(false);
          setTimeout(() => setSelectedScale(undefined), 300);
        }}
        afterClose={() => setSelectedScale(undefined)}
        growthScale={selectedScale}
      />
    </ContentWrapper>
  );
};
