import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from 'prosemirror-state';

export const HoverExtension = Extension.create({
  name: 'hover',

  addOptions() {
    return {
      onHover: () => {},
    };
  },

  addProseMirrorPlugins() {
    const { onHover } = this.options;
    return [
      new Plugin({
        key: new PluginKey('hover'),
        props: {
          handleDOMEvents: {
            mouseover(view, event) {
              if (onHover) {
                onHover(view, event);
              }
              return false;
            },
          },
        },
      }),
    ];
  },
});
