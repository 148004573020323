import { useCallback, useMemo, useState } from 'react';

import { TaxYear } from '@shared/modules/payroll/payroll.types';
import { ColumnDef } from '@tanstack/react-table';
import {
  isPaycodeForAdditions,
  isPaycodeForDeductions,
  isPaycodeForOtherEmployerPayments,
} from '@v2/feature/payroll/features/payroll-uk/payrun-flow/payrun-flow.util';
import { sumBy } from 'lodash';

import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { EditPayrunEntryDrawer } from '@/v2/feature/payroll/features/payroll-global/components/edit-payrun-entry-drawer.component';
import {
  GlobalPayrollAPI,
  GlobalPayrollEndpoints,
} from '@/v2/feature/payroll/features/payroll-global/global-payroll.api';
import { GlobalPayrollSalarySummaryDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/global-payroll-salary-summary-drawer.component';
import { PayPeriod } from '@/v2/feature/payroll/payroll-external.interface';
import { useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { filterByTextSearch } from '@/v2/util/array.util';
import { formatCurrency } from '@/v2/util/currency-format.util';

type GlobalPayrollTableProps = {
  payrun: GlobalPayrun;
  searchQuery?: string;
  allowEdit?: boolean;
  refreshPayrun: () => Promise<void>;
};

// TODO: @NOW___PAYROLL - reports => 2 report types => by entry & by payCode. need to migrate
export const GlobalPayrollTable = ({ payrun, searchQuery, allowEdit, refreshPayrun }: GlobalPayrollTableProps) => {
  const { getCachedUserById } = useCachedUsers();
  const [viewingSummaryDrawerForEntry, setViewingSummaryDrawerForEntry] = useState<GlobalPayrunEntry | null>(null);
  const [editPayrunEntry, setEditPayrunEntry] = useState<GlobalPayrunEntry | null>(null);

  const payCodesByCode = useMemo(
    () =>
      new Map(
        payrun.paycodes
          .filter((p) => isPaycodeForAdditions(p) || isPaycodeForDeductions(p) || isPaycodeForOtherEmployerPayments(p))
          .map((paycode) => [paycode.code, paycode])
      ),
    [payrun]
  );

  const paycodesInPayrun = useMemo(() => {
    const paycodeList = new Set<string>();

    for (const entry of payrun.entries) {
      entry.paylines.forEach((p) => paycodeList.add(p.code));
    }

    return paycodeList;
  }, [payrun]);

  const calculateEmployeeNet = useCallback(
    (paylines: GlobalPayline[]) => {
      let netTotal = 0;
      for (const payline of paylines) {
        const paycode = payCodesByCode.get(payline.code);
        if (!paycode) continue;
        if (paycode.credit === 'Employee') {
          netTotal += payline.amount;
        }
        if (paycode.debit === 'Employee') {
          netTotal -= payline.amount;
        }
      }
      return netTotal;
    },
    [payCodesByCode]
  );

  const [employeeNetValues, totalEmployeeNet] = useMemo(() => {
    const netValues = new Map(payrun.entries.map((entry) => [entry.userId, calculateEmployeeNet(entry.paylines)]));
    const total = sumBy([...netValues.values()]);
    return [netValues, total];
  }, [calculateEmployeeNet, payrun.entries]);

  const calculateTotalsForEachCode = (entries: GlobalPayrunEntry[], payCodes: GlobalPaycode[]) => {
    const totals = new Map();
    payCodes.forEach(({ code }) => {
      const totalForCode = entries.reduce((sum, entry) => {
        const payline = entry.paylines.filter((p) => p.code === code);
        const total = sumBy(payline, (item) => item.amount);
        return sum + (payline ? total : 0);
      }, 0);
      totals.set(code, totalForCode);
    });

    return totals;
  };

  const totalsForEachCode = useMemo(() => {
    const relevantPayCodes = [...payCodesByCode.values()].filter(({ code }) => paycodesInPayrun.has(code));
    return calculateTotalsForEachCode(payrun.entries, relevantPayCodes);
  }, [payrun.entries, payCodesByCode, paycodesInPayrun]);

  const getUserDisplayName = useCallback(
    (userId: number) => {
      const user = getCachedUserById(userId);
      if (user) return UserCell.getDisplayedName(user);
      return `(User ${userId})`;
    },
    [getCachedUserById]
  );

  const columns = useMemo<ColumnDef<GlobalPayrunEntry, GlobalPayrunEntry>[]>(() => {
    const buildColumnForPaycode = ({ code, name }: GlobalPaycode): ColumnDef<GlobalPayrunEntry, GlobalPayrunEntry> => {
      return {
        id: code,
        accessorFn: (x) => x,
        header: name || code,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortNumeric(a, b, (item) =>
            sumBy(
              item.paylines.filter((p) => p.code === code),
              (item) => item.amount
            )
          ),
        cell: (c) => {
          const payline = c.row.original.paylines.filter((p) => p.code === code);
          const total = sumBy(payline, (item) => item.amount);
          return payline.length ? formatCurrency(total, { noCurrencySymbol: true }) : '';
        },
        footer: () => formatCurrency(totalsForEachCode.get(code), { noCurrencySymbol: true }),
      };
    };

    return [
      {
        id: 'employee',
        header: () => 'Employee',
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => getUserDisplayName(item.userId)),
        cell: (c) => <UserCell userId={c.row.original.userId} />,
        footer: () => <>Totals</>,
      },
      ...[...payCodesByCode.values()]
        .sort((a, b) => a.order - b.order)
        .filter(({ code }) => paycodesInPayrun.has(code))
        .map((p) => buildColumnForPaycode(p)),

      {
        id: 'total',
        header: () => 'Employee net',
        accessorFn: (x) => x,
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => employeeNetValues.get(item.userId)),
        cell: (cellInfo) => {
          return (
            <Typography variant="title4">
              {formatCurrency(employeeNetValues.get(cellInfo.row.original.userId), { noCurrencySymbol: true })}
            </Typography>
          );
        },
        footer: () => formatCurrency(totalEmployeeNet, { noCurrencySymbol: true }),
      },
    ];
  }, [payCodesByCode, totalsForEachCode, getUserDisplayName, paycodesInPayrun, employeeNetValues, totalEmployeeNet]);

  const filteredRows = useMemo(() => {
    return filterByTextSearch(searchQuery, payrun.entries, (entry) => {
      const user = getCachedUserById(entry.userId);
      return user ? [UserCell.getDisplayedName(user)] : [];
    });
  }, [getCachedUserById, payrun.entries, searchQuery]);

  const editPayrunEntryFromDrawer = (entry: GlobalPayrunEntry) => {
    setEditPayrunEntry(entry);
    setViewingSummaryDrawerForEntry(null);
  };

  const userForPayrunEntryDrawer = useMemo(() => {
    if (!viewingSummaryDrawerForEntry) return null;
    return getCachedUserById(viewingSummaryDrawerForEntry.userId);
  }, [viewingSummaryDrawerForEntry, getCachedUserById]);

  return (
    <>
      <BasicTable
        rowData={filteredRows}
        columnData={columns}
        showFooter
        rowClick={allowEdit ? (row) => setViewingSummaryDrawerForEntry(row.original) : undefined}
        fixedLastColumn={false}
        fixedFirstColumn={false}
        initialSort={[{ id: 'employee', desc: true }]}
        stickyHeader
      />

      <EditPayrunEntryDrawer
        payrollId={payrun.payrollId}
        payrunClosed={payrun.state !== 'DRAFT'}
        savePaylineUpdates={async (update: GlobalPayrunEntryUpdate) => {
          const { payrollId, taxYear, payPeriod, period } = payrun;
          await GlobalPayrollAPI.updatePayrunEntries(payrollId, taxYear, payPeriod, period, update);
          await refreshPayrun();
          return true;
        }}
        payrunEntry={editPayrunEntry}
        onClose={() => setEditPayrunEntry(null)}
      />

      <GlobalPayrollSalarySummaryDrawer
        payslip={null}
        onClose={() => setViewingSummaryDrawerForEntry(null)}
        providedPayrunEntry={viewingSummaryDrawerForEntry}
        providedPayrun={payrun}
        providedUser={userForPayrunEntryDrawer}
        handleEditAction={payrun.state === 'DRAFT' ? editPayrunEntryFromDrawer : undefined}
        payrun={payrun}
      />
    </>
  );
};

type Props = {
  payrollId: number;
  taxYear: TaxYear;
  payPeriod: PayPeriod;
  period: number;
  searchQuery?: string;
};

export const GlobalPayrollTableByPeriod = ({ payrollId, taxYear, payPeriod, period, searchQuery }: Props) => {
  const { data: payrun, mutate } = useApiClient(
    GlobalPayrollEndpoints.getPayrun(payrollId, taxYear, payPeriod, period),
    {
      suspense: false,
    }
  );

  const refreshPayrun = useCallback(async () => {
    if (mutate) await mutate();
  }, [mutate]);

  return (
    <>
      {payrun && (
        <GlobalPayrollTable payrun={payrun} searchQuery={searchQuery} refreshPayrun={refreshPayrun} allowEdit />
      )}
    </>
  );
};
