import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { SuperAdminCompanyInfo } from '@shared/modules/company/company.types';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { EmptyCell, IconEmptyCell } from '@v2/components/table/empty-cell.component';
import { TableFilter } from '@v2/components/table/table-filter.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { DeviceDto, DeviceOrderDto } from '@v2/feature/device/device.dto';
import { DeviceOrderStatus, DevicePossessionType } from '@v2/feature/device/device.interface';
import {
  getCompanyNamesKeyedByCompanyIds,
  getDeviceOwnerAsSuperadminByDevicePossession,
  getModelImage,
  getOrderStatus,
  getUserNamesKeyedByUserIds,
} from '@v2/feature/device/device.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { SuperAdminDeviceOrderDrawer } from '@v2/feature/super-admin/features/super-admin-devices/components/super-admin-device-order-drawer.component';
import { UserDetailsSuperAdminDto } from '@v2/feature/user/dtos/user-superadmin.dto';
import { filterStringToObject } from '@v2/feature/user/user.util';
import { spacing } from '@v2/styles/spacing.styles';

interface SuperAdminDeviceOrdersTableProps {
  readonly deviceOrders: readonly DeviceOrderDto[];
  readonly users: readonly UserDetailsSuperAdminDto[];
  readonly companies: readonly SuperAdminCompanyInfo[];
  readonly refresh: () => Promise<void>;
  readonly devices: readonly DeviceDto[];
  readonly sites: { [siteId: number]: SiteDto };
}

export const SuperAdminDeviceOrdersTable = ({
  deviceOrders,
  users,
  companies,
  refresh,
  devices,
  sites,
}: SuperAdminDeviceOrdersTableProps): JSX.Element => {
  const [searchInput, setSearchInput] = useState('');
  const [filterString, setFilterString] = useState<string>(
    `Status=${DeviceOrderStatus.Placed},${DeviceOrderStatus.Accepted},${DeviceOrderStatus.Shipping}`
  );
  const [filterTypes, setFilterTypes] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<DeviceOrderDto | undefined>(undefined);

  const userNames = useMemo(() => getUserNamesKeyedByUserIds(users), [users]);
  const companiesNames = useMemo(() => getCompanyNamesKeyedByCompanyIds(companies), [companies]);

  const getFilterOptions = useCallback(async () => {
    const orderStatus = [
      { label: 'Requested', value: DeviceOrderStatus.Requested },
      { label: 'Cancelled', value: DeviceOrderStatus.Cancelled },
      { label: 'Rejected', value: DeviceOrderStatus.Rejected },
      { label: 'Accepted', value: DeviceOrderStatus.Accepted },
      { label: 'Pending', value: DeviceOrderStatus.Placed },
      { label: 'Shipping', value: DeviceOrderStatus.Shipping },
      { label: 'Delivered', value: DeviceOrderStatus.Delivered },
    ];
    const FILTERS = ['Status'];
    let filters = {};
    FILTERS.forEach((filter) => {
      switch (filter) {
        case 'Status':
          filters = { ...filters, [filter]: orderStatus };
          break;
        default:
          break;
      }
    });
    setFilterTypes(filters);
  }, []);

  useEffect(() => {
    getFilterOptions();
  }, [getFilterOptions]);

  const filteredDevices = useMemo(() => {
    let filteredDeviceOrders = [...deviceOrders];

    if (searchInput) {
      filteredDeviceOrders = filteredDeviceOrders.filter((deviceOrder) => {
        let userName;
        if (deviceOrder.possession?.possessionType === DevicePossessionType.User) {
          const user = users.find((user) => user.userId === deviceOrder.possession?.possessionId);
          userName = user ? `${user.firstName} ${user.lastName}` : undefined;
        }
        const modelName = deviceOrder.deviceModel?.modelName;

        return (
          userName?.toLowerCase().includes(searchInput.toLowerCase()) ||
          modelName?.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    if (filterString) {
      const filterOptions = filterStringToObject(filterString);
      if (filterOptions) {
        for (const key of Object.keys(filterOptions)) {
          switch (key) {
            case 'Status': {
              filteredDeviceOrders = filteredDeviceOrders.filter((order) => filterOptions[key]?.includes(order.status));
              break;
            }
            default:
              break;
          }
        }
      }
    }

    return filteredDeviceOrders;
  }, [deviceOrders, searchInput, filterString, users]);

  const columnData = useMemo<ColumnDef<DeviceOrderDto, DeviceOrderDto>[]>(() => {
    return [
      {
        header: () => 'Model',
        accessorFn: (row) => row,
        id: 'deviceModelId',
        enableSorting: false,
        cell: (info: CellContext<DeviceOrderDto, DeviceOrderDto>) => {
          const deviceOrder: DeviceOrderDto = info.getValue();
          return <Box>{deviceOrder.deviceModelId}</Box>;
        },
        size: 30,
      },
      {
        header: () => 'Device',
        accessorFn: (row) => row,
        id: 'deviceModel.id',
        enableSorting: false,
        cell: (info: CellContext<DeviceOrderDto, DeviceOrderDto>) => {
          const deviceOrder: DeviceOrderDto = info.getValue();
          return deviceOrder.deviceModel?.modelName ? (
            <Box sx={{ display: 'flex', gap: spacing.g10, alignItems: 'center' }}>
              <Box>
                {deviceOrder &&
                  getModelImage(deviceOrder.deviceModel.type, deviceOrder.deviceModel.modelName, {
                    width: '30px',
                    height: 'auto',
                  })}
              </Box>

              {deviceOrder.deviceModel?.modelName}
            </Box>
          ) : (
            <IconEmptyCell />
          );
        },
        size: 180,
      },
      {
        header: () => 'Price',
        accessorFn: (row) => row,
        id: 'price',
        enableSorting: false,
        cell: (info: CellContext<DeviceOrderDto, DeviceOrderDto>) => {
          const deviceOrder: DeviceOrderDto = info.getValue();
          return (
            <Box>
              £{deviceOrder.price} {deviceOrder.isPurchase ? 'purchase' : 'per month'}
            </Box>
          );
        },
        size: 140,
      },
      {
        header: () => 'Company',
        accessorFn: (row) => row,
        id: 'company',
        enableSorting: false,
        cell: (info: CellContext<DeviceOrderDto, DeviceOrderDto>) => {
          const deviceOrder: DeviceOrderDto = info.getValue();
          const companyName = companiesNames[deviceOrder.companyId];
          return companyName ? <Box>{companyName}</Box> : <EmptyCell />;
        },
        size: 140,
      },
      {
        header: () => 'Ordered for',
        accessorFn: (row) => row,
        id: 'possessionId',
        enableSorting: false,
        cell: (info: CellContext<DeviceOrderDto, DeviceOrderDto>) => {
          const deviceOrder: DeviceOrderDto = info.getValue();
          return deviceOrder.possession ? (
            <Box>{getDeviceOwnerAsSuperadminByDevicePossession(deviceOrder.possession, userNames, sites)}</Box>
          ) : (
            <IconEmptyCell />
          );
        },
        size: 180,
      },

      {
        header: () => 'Status',
        accessorFn: (row) => row,
        id: 'status',
        enableSorting: false,
        cell: (info: CellContext<DeviceOrderDto, DeviceOrderDto>) => {
          const deviceOrder: DeviceOrderDto = info.getValue();
          return <>{getOrderStatus(deviceOrder.status)}</>;
        },
        size: 150,
      },
      {
        header: () => 'Delivery date',
        accessorFn: (row) => row,
        id: 'deliveryDate',
        enableSorting: false,
        cell: (info: CellContext<DeviceOrderDto, DeviceOrderDto>) => {
          const deviceOrder: DeviceOrderDto = info.getValue();
          return (
            <Box>
              {deviceOrder?.deliveryDate ? new Date(deviceOrder?.deliveryDate).toLocaleDateString() : <IconEmptyCell />}
            </Box>
          );
        },
        size: 180,
      },
    ];
  }, [companiesNames, userNames, sites]);

  return (
    <>
      {deviceOrders.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '100%', ...spacing.mb20, gap: 1 }}>
          {filterTypes && (
            <TableFilter filterTypes={filterTypes} setFilterString={setFilterString} filterString={filterString} />
          )}
          <TableSearch query={searchInput} handleChange={(e) => setSearchInput(e.target.value?.trim() ?? '')} />
        </Box>
      )}
      <BasicTable<DeviceOrderDto>
        rowData={filteredDevices}
        columnData={columnData}
        rowClick={(row) => {
          setSelectedRecord(row.original);
          setIsOpen(true);
        }}
      />

      {selectedRecord && isOpen && (
        <SuperAdminDeviceOrderDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          deviceOrder={selectedRecord}
          setDeviceOrder={setSelectedRecord}
          refresh={refresh}
          userNames={userNames}
          companyNames={companiesNames}
          devices={devices}
          sites={sites}
        />
      )}
    </>
  );
};
