import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Avatar, Box } from '@mui/material';
import { TableSearch } from '@v2/components/table/table-search.component';
import { UserCell } from '@v2/components/table/user-cell.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { GlobalContext } from '@/GlobalState';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NewIdCheckPackageDrawer } from '@/v2/feature/id-check/components/new-id-check-package-drawer.page';
import { IdCheckEndpoints } from '@/v2/feature/id-check/id-check.api';
import { IdCheckPackage } from '@/v2/feature/id-check/id-check.interface';
import { mapPackageDetailsToString } from '@/v2/feature/id-check/id-check.util';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';
import { formatAsGBPCurrency } from '@/v2/util/string-format.util';

const zeltLogo = '/app-icons-v2/images/zelt.png';

export const IdCheckSettingsPage = (): React.JSX.Element => {
  const {
    data: idCheckPackages,
    isLoading: loadingIdCheckPackages,
    mutate: refreshIdCheckPackages,
  } = useApiClient(IdCheckEndpoints.getIdCheckPackages(), { suspense: false });
  const {
    data: individualChecks,
    isLoading: loadingIndividualChecks,
    mutate: refreshIndividualChecks,
  } = useApiClient(IdCheckEndpoints.getIndividualChecks(), { suspense: false });
  const [globalState] = useContext(GlobalContext);
  const currentUser = globalState.user;
  const { polyglot } = usePolyglot();

  const [searchInput, setSearchInput] = useState<string>('');
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<IdCheckPackage | null>(null);

  const refresh = useCallback(async () => {
    if (refreshIdCheckPackages) await refreshIdCheckPackages();
    if (refreshIndividualChecks) await refreshIndividualChecks();
  }, [refreshIdCheckPackages, refreshIndividualChecks]);

  const filteredPackages = useMemo(() => {
    if (!idCheckPackages) return [];
    if (!searchInput) return idCheckPackages.sort((a, b) => a.id - b.id);

    const search = searchInput.toLowerCase();
    return idCheckPackages
      .sort((a, b) => a.id - b.id)
      .filter((checkPackage) => checkPackage.name.toLowerCase().includes(search));
  }, [searchInput, idCheckPackages]);

  const isLoading = loadingIdCheckPackages || loadingIndividualChecks;
  return (
    <ContentWrapper loading={isLoading} sx={{}}>
      <SettingsSectionContent
        title={polyglot.t('SettingsRouterPageConfig.idCheckPackages')}
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent
            colorVariant="primary"
            sizeVariant="small"
            onClick={() => {
              setSelectedPackage(null);
              setIsDrawerOpen(true);
            }}
          >
            {polyglot.t('IdCheckPackage.newPackage')}
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <TableSearch
                      query={searchInput}
                      handleChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {filteredPackages.map((item) => {
                        const { packageDetails } = item;
                        const usesCustomPackage = item && packageDetails ? 'customPackage' in packageDetails : false;
                        const packageName =
                          !usesCustomPackage && item.packageDetails
                            ? mapPackageDetailsToString(item.packageDetails, polyglot)
                            : item.name;
                        return (
                          <SettingsItemCard
                            key={item.name}
                            title={item.name}
                            description={packageName}
                            boxSx={responsiveCardStyle}
                            contentItemsSets={[
                              {
                                type: ContentItemType.component,
                                contentComponent: (
                                  <ChipComponent
                                    name={
                                      !item.createdBy ? (
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            gap: spacing.s1,
                                          }}
                                        >
                                          <Avatar src={zeltLogo} alt={'zelt'} style={iconSize} />
                                          <Typography variant="caption">Zelt</Typography>
                                        </Box>
                                      ) : (
                                        <UserCell userId={item.createdBy} nameColor="DarkGrey" />
                                      )
                                    }
                                    backgroundColor="white"
                                    textColor="DarkGrey"
                                    textVariant="caption"
                                    border="middle"
                                  />
                                ),
                              },
                              {
                                name: `${formatAsGBPCurrency(item.price, 2)}`,
                                type: ContentItemType.chip,
                                textColor: 'DarkGrey',
                                backgroundColor: 'white',
                                border: 'middle',
                              },
                            ]}
                            boxAction={() => {
                              setSelectedPackage(item);
                              setIsDrawerOpen(true);
                            }}
                          />
                        );
                      })}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
      {isDrawerOpen && (
        <NewIdCheckPackageDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          currentUser={currentUser}
          idCheckPackage={selectedPackage}
          individualChecks={individualChecks ?? []}
          refresh={refresh}
        />
      )}
    </ContentWrapper>
  );
};
