import { absurd, pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as RA from 'fp-ts/lib/ReadonlyArray';
import { Subject } from 'rxjs';

import { BELONGS_TO_EVERYONE, ZeltDocument, ZeltDocumentCategory, ZeltDocumentType } from '@/lib/documents';
import { OptionObject } from '@/v2/components/forms/select.component';
import { DocumentAPI } from '@/v2/feature/documents/document.api';
import {
  ActionReach,
  CATEGORIES_FOR_PRIVATE_DOCUMENTS,
  DocumentAction,
  PREVIEWABLE_FILE_FORMATS,
} from '@/v2/feature/documents/documents.interface';
import { SignatoryType } from '@/v2/feature/templates/templates.interface';
import { filterStringToObject } from '@/v2/feature/user/user.util';

export const DEFAULT_DOCUMENT_TYPE_NAME = 'other';
export const REDUCER_NAME = 'reduceDocumentFormModal';

/** duplicate of backend/web-api-nest/src/domain/document/document.interface.ts */
export const OTHER_TYPE = {
  value: DEFAULT_DOCUMENT_TYPE_NAME,
  label: 'Other',
  category: 'Other',
  icon: 'file',
  bgColor: 'bg-red-500',
  canUserAdd: true,
  onePersonOnly: false,
  canBelongToEveryone: true,
  onboardingDocument: true,
  needsVerification: false,
} as const;

export interface BelongsTo {
  readonly value: number;
  readonly label: string;
}

export type DocumentFormModalOwnership =
  | {
      readonly multiownership: false;
      readonly owner?: BelongsTo;
    }
  | ({
      readonly multiownership: true;
    } & (
      | {
          readonly canBelongToEveryone: false;
          readonly owners: readonly BelongsTo[];
        }
      | ({
          readonly canBelongToEveryone: true;
        } & (
          | {
              readonly belongsToEveryone: true;
            }
          | {
              readonly belongsToEveryone: false;
              readonly owners: readonly BelongsTo[];
            }
        ))
    ));

export interface DocumentType {
  readonly label: string;
  readonly value: string;
  readonly onePersonOnly: boolean;
  readonly canBelongToEveryone: boolean;
  readonly category: ZeltDocumentCategory;
}

export enum DocumentsDomain {
  company = 'company',
  employee = 'employee',
}

export interface EmployeeDocumentsSettings {
  hideDocument: boolean;
}

export interface VisibilitySettings {
  [DocumentsDomain.employee]?: EmployeeDocumentsSettings;
  private?: boolean; // private document - only employee and admin (somebody in the admin permission group) can see (unless hidden from employee too - on document level)
}

export interface ExpirySettings {
  expiryDate: string;
  documentExpires: boolean;
}

export type DocumentFormModalState = {
  readonly type: DocumentType | undefined;
  readonly types: readonly (ZeltDocumentType | typeof OTHER_TYPE)[];
  readonly id?: number;
  readonly name?: string;
  readonly fileName?: string;
  readonly fileUuid?: string;
  readonly belongsToUserId?: number;
  readonly visibility?: VisibilitySettings;
  readonly expirySettings?: ExpirySettings;
  readonly pinned?: boolean;
  readonly documentLink?: string;
  readonly note?: string;
  readonly canBelongToEveryone?: boolean;
  readonly belongsToEveryone?: boolean;
  readonly owners?: readonly BelongsTo[];
  readonly owner?: BelongsTo;
  readonly uploadedBy?: number;
  readonly currentlyUploading?: boolean;
} & DocumentFormModalOwnership;

export type DocumentFormModalAction =
  | {
      readonly kind: 'set_types';
      readonly value: readonly ZeltDocumentType[];
    }
  | {
      readonly kind: 'change_type';
      readonly type: DocumentType;
      readonly keepOwnership: boolean;
    }
  | {
      readonly kind: 'change_name';
      readonly value: string;
    }
  | {
      readonly kind: 'change_note';
      readonly value: string;
    }
  | {
      readonly kind: 'add_document_link';
      readonly value: string;
    }
  | {
      readonly kind: 'set_expiry';
      readonly value: ExpirySettings;
    }
  | {
      readonly kind: 'set_visibility';
      readonly value: VisibilitySettings;
    }
  | {
      readonly kind: 'set_pinned';
      readonly value: boolean;
    }
  | {
      readonly kind: 'upload_file';
      readonly value: {
        fileUuid: string;
        fileName: string;
      };
    }
  | {
      readonly kind: 'prepare_file_for_upload';
      readonly value: {
        owner?: BelongsTo;
      };
    }
  | {
      readonly kind: 'clear_file';
    }
  | {
      readonly kind: 'select_everyone';
    }
  | {
      readonly kind: 'unselect_owners';
    }
  | {
      readonly kind: 'select_owners';

      readonly value: readonly BelongsTo[];
    }
  | {
      readonly kind: 'select_owner';
      readonly value: BelongsTo;
    }
  | {
      readonly kind: 'add_document';
      readonly owner?: BelongsTo;
    }
  | {
      readonly kind: 'request_document';
      readonly owner?: BelongsTo;
      readonly reach?: ActionReach;
    }
  | {
      readonly kind: 'edit_document';
      readonly value: ZeltDocument;
      readonly userList?: OptionObject[];
    }
  | {
      readonly kind: 'bulk_upload_edit_document';
      readonly value: ZeltDocument;
      readonly userList?: OptionObject[];
    }
  | { readonly kind: 'currently_uploading'; readonly value: boolean };

export function documentCanBeSeenByEveryoneFromDto(d: ZeltDocument) {
  return d.canAllEmployeesSee && (d.belongsTo ?? []).length === 0;
}

export function documentFormModalOwnershipToDto(
  o: DocumentFormModalOwnership
): readonly [typeof BELONGS_TO_EVERYONE] | readonly BelongsTo[] {
  return o.multiownership
    ? o.canBelongToEveryone
      ? o.belongsToEveryone
        ? [BELONGS_TO_EVERYONE]
        : o.owners
      : o.owners
    : o.owner
    ? [o.owner]
    : [];
}

export const uploadDocumentIfNeeded = async (documentLink: string): Promise<string | undefined> => {
  if (!documentLink) return undefined;
  return DocumentAPI.uploadFileViaURL(documentLink);
};

export function documentBelongsToEveryone(o: DocumentFormModalOwnership): boolean {
  return o.multiownership && o.canBelongToEveryone && o.belongsToEveryone;
}

export function documentHasOwner(o: DocumentFormModalOwnership): boolean {
  return o.multiownership
    ? o.canBelongToEveryone
      ? o.belongsToEveryone || o.owners.length > 0
      : o.owners.length > 0
    : !!o.owner;
}

export function getOwnersUserIds(o: DocumentFormModalOwnership): Set<number> {
  return new Set(
    (o.multiownership
      ? o.canBelongToEveryone && o.belongsToEveryone
        ? []
        : o.owners ?? []
      : o.owner
      ? [o.owner]
      : []
    ).map((x) => x.value)
  );
}

export function initialiseState(
  types: readonly (ZeltDocumentType | typeof OTHER_TYPE)[],
  owner?: BelongsTo
): DocumentFormModalState {
  const type = OTHER_TYPE;
  return {
    types,
    // TODO take from type list
    type: undefined,
    id: undefined,
    name: undefined,
    fileUuid: undefined,
    note: undefined,
    canBelongToEveryone: type.canBelongToEveryone,
    multiownership: !type.onePersonOnly,
    belongsToEveryone: false,
    owners: owner ? [owner] : [],
    uploadedBy: undefined,
    currentlyUploading: false,
  } as const;
}

export function initialiseStateForDocumentRequest(
  types: readonly (ZeltDocumentType | typeof OTHER_TYPE)[],
  owner?: BelongsTo,
  reach?: ActionReach
): DocumentFormModalState {
  return {
    types,
    // TODO take from type list
    type: undefined,
    id: undefined,
    name: undefined,
    fileUuid: undefined,
    note: undefined,
    canBelongToEveryone: false,
    multiownership: false,
    belongsToEveryone: false,
    owners: owner && reach !== 'company' ? [owner] : [],
    owner: owner && reach !== 'company' ? owner : undefined,
    uploadedBy: undefined,
    currentlyUploading: false,
  } as const;
}

export function initialiseStateFromDocument(
  types: readonly (ZeltDocumentType | typeof OTHER_TYPE)[],
  doc: ZeltDocument,
  userList?: OptionObject[]
): DocumentFormModalState {
  const belongsTo = pipe(
    doc.belongsTo ?? [],
    RA.filterMap((b) => {
      const userId = Number(b.User?.userId);
      return isNaN(userId)
        ? O.none
        : O.some({
            value: userId,
            label: b.User?.displayName ?? `${b.User?.firstName} ${b.User?.lastName}`,
          } as const);
    })
  );

  const type = types.find((x) => x.value === doc.type) ?? OTHER_TYPE;

  const ownership: DocumentFormModalOwnership = type.onePersonOnly
    ? {
        multiownership: !type.onePersonOnly,
        owner:
          belongsTo[0] ?? (doc.belongsToUserId && userList)
            ? (userList?.find((x) => x.value === doc.belongsToUserId || x.value === belongsTo[0]?.value) as BelongsTo)
            : undefined,
      }
    : type.canBelongToEveryone
    ? {
        multiownership: !type.onePersonOnly,
        canBelongToEveryone: type.canBelongToEveryone,
        belongsToEveryone: documentCanBeSeenByEveryoneFromDto(doc),
        owners: belongsTo,
      }
    : {
        multiownership: !type.onePersonOnly,
        canBelongToEveryone: type.canBelongToEveryone,
        owners: belongsTo,
      };

  const attachment = doc.attachments && doc.attachments.length > 0 ? doc.attachments[0] : undefined;
  return {
    types,
    type: type ?? OTHER_TYPE,
    id: doc.id,
    name: doc.name,
    note: doc.note,
    fileUuid: attachment?.fileUuid ?? doc?.fileUuid,
    fileName: attachment?.fileName ?? doc?.fileName,
    ...ownership,
    uploadedBy: doc.uploadedBy,
    pinned: doc.isPinned,
    visibility: doc.visibilitySettings,
    expirySettings: doc.expirySettings,
  };
}

export function initialiseStateForBulkUploadDocument(
  types: readonly (ZeltDocumentType | typeof OTHER_TYPE)[],
  doc: ZeltDocument,
  userList?: OptionObject[]
): DocumentFormModalState {
  const belongsTo = pipe(
    doc.belongsTo ?? [],
    RA.filterMap((b) => {
      const userId = Number(b.User?.userId);
      return isNaN(userId)
        ? O.none
        : O.some({
            value: userId,
            label: b.User?.displayName ?? `${b.User?.firstName} ${b.User?.lastName}`,
          } as const);
    })
  );

  // default to ONLY SINGLE OWNERSHIP for bulk upload edit
  const ownership: DocumentFormModalOwnership = {
    multiownership: false,
    owner:
      belongsTo[0] ?? (doc.belongsToUserId && userList)
        ? (userList?.find((x) => x.value === doc.belongsToUserId || x.value === belongsTo[0]?.value) as BelongsTo)
        : undefined,
  };

  const attachment = doc.attachments && doc.attachments.length > 0 ? doc.attachments[0] : undefined;
  return {
    types,
    type: OTHER_TYPE,
    id: doc.id,
    name: doc.name,
    note: doc.note,
    fileUuid: attachment?.fileUuid ?? doc?.fileUuid,
    fileName: attachment?.fileName ?? doc?.fileName,
    ...ownership,
    uploadedBy: doc.uploadedBy,
    pinned: doc.isPinned,
    visibility: doc.visibilitySettings,
    expirySettings: doc.expirySettings,
  };
}

export function reduceDocumentFormModal(
  state: DocumentFormModalState,
  action: DocumentFormModalAction
): DocumentFormModalState {
  switch (action.kind) {
    case 'set_types': {
      return { ...state, types: action.value };
    }
    case 'change_name': {
      return { ...state, name: action.value };
    }
    case 'set_visibility': {
      return { ...state, visibility: action.value };
    }
    case 'set_expiry': {
      return { ...state, expirySettings: action.value };
    }
    case 'set_pinned': {
      return { ...state, pinned: action.value };
    }
    case 'change_type': {
      return { ...state, ...(action.type ? { type: action.type } : {}) };
    }
    case 'change_note': {
      return { ...state, note: action.value };
    }
    case 'upload_file': {
      return { ...state, ...action.value };
    }
    case 'prepare_file_for_upload': {
      return { ...state, ...action.value };
    }
    case 'clear_file': {
      return { ...state, fileUuid: undefined };
    }
    case 'add_document_link': {
      return { ...state, documentLink: action.value };
    }
    case 'select_everyone': {
      if (state.type && (state.type.onePersonOnly || !state.type.canBelongToEveryone)) {
        throw new Error(
          `${REDUCER_NAME}/${action.kind}: document of type '${state.type.value}' can't belong to everyone.`
        );
      }
      const { type, types, fileName, fileUuid, id, name, note } = state;
      return {
        canBelongToEveryone: true,
        multiownership: true,
        belongsToEveryone: true,
        type,
        types,
        fileName,
        fileUuid,
        id,
        name,
        note,
      };
    }
    case 'select_owner': {
      if (state.multiownership) {
        throw new Error(
          `${REDUCER_NAME}/${action.kind}: document of type '${
            state.type?.value ?? 'Unknown'
          }' must be owned by one or more.`
        );
      }
      return { ...state, owner: action.value };
    }
    case 'select_owners': {
      if (!state.multiownership) {
        throw new Error(
          `${REDUCER_NAME}/${action.kind}: document of type '${
            state.type?.value ?? 'Unknown'
          }' can't be owned by more than one person`
        );
      }
      return state.canBelongToEveryone
        ? { ...state, belongsToEveryone: false, owners: action.value }
        : { ...state, owners: action.value };
    }
    case 'unselect_owners': {
      return state.multiownership
        ? state.canBelongToEveryone
          ? { ...state, belongsToEveryone: false, owners: [] }
          : { ...state, owners: [] }
        : { ...state, owner: undefined };
    }
    case 'add_document': {
      return initialiseState(state.types, action.owner);
    }
    case 'edit_document': {
      return initialiseStateFromDocument(state.types, action.value, action.userList);
    }
    case 'bulk_upload_edit_document': {
      return initialiseStateForBulkUploadDocument(state.types, action.value, action.userList);
    }
    case 'request_document': {
      return initialiseStateForDocumentRequest(state.types, action.owner, action.reach);
    }
    case 'currently_uploading': {
      return { ...state, currentlyUploading: action.value };
    }
    default: {
      return absurd(action);
    }
  }
}

export const DEBOUNCE_300_MS = 300;
export const DEBOUNCE_500_MS = 500;
export const DEBOUNCE_1000_MS = 1000;

const filterByType = (documents: ZeltDocument[], types: string[]) => {
  return documents.filter((document) => types.includes(document.type));
};

export const filterDocuments = (searchInput: string) => (documents: ZeltDocument[]) => (
  filterString: string
): readonly ZeltDocument[] => {
  let filteredDocuments = documents;
  if (filterString) {
    const filterOptions = filterStringToObject(filterString);
    if (filterOptions) {
      for (const key of Object.keys(filterOptions)) {
        switch (key) {
          case 'type': {
            filteredDocuments = filterByType(filteredDocuments, filterOptions[key]);
            break;
          }
          default:
            break;
        }
      }
    }
  }
  if (searchInput) {
    const search = searchInput.toLowerCase().trim().replaceAll(' ', '');
    filteredDocuments = filteredDocuments.filter(
      (document) =>
        document.name.toLowerCase().trim().replaceAll(' ', '').includes(search) ||
        document.type.toLowerCase().trim().replaceAll(' ', '').includes(search) ||
        (document.belongsTo &&
          document.belongsTo
            .map((user) => {
              return `${user.User.displayName
                .toLowerCase()
                .trim()
                .replaceAll(' ', '')},${user.User.firstName
                .toLowerCase()
                .trim()
                .replaceAll(' ', '')},${user.User.lastName.toLowerCase().trim().replaceAll(' ', '')}`;
            })
            .toString()
            .includes(search))
    );
  }

  return filteredDocuments;
};

export const getPinnedDocuments = (documents: ZeltDocument[]) => {
  return documents.filter((doc) => doc.isPinned);
};

const filterDocumentsByCategory = (
  viewType: string,
  allDocuments: readonly (ZeltDocumentType | typeof OTHER_TYPE)[]
): (ZeltDocumentType | typeof OTHER_TYPE)[] => {
  return viewType === 'company'
    ? allDocuments.filter((document) => document.category === 'Company Documents')
    : allDocuments.filter((document) => document.category !== 'Company Documents');
};
export const getDocumentTypeListBasedOnAudience = (
  baseTypeList: readonly (ZeltDocumentType | typeof OTHER_TYPE)[],
  currentUserIsAdmin = false,
  viewType?: 'company' | 'personal'
): readonly (ZeltDocumentType | typeof OTHER_TYPE)[] => {
  if (currentUserIsAdmin) {
    if (!viewType) return baseTypeList;
    else return filterDocumentsByCategory(viewType, baseTypeList);
  }

  const privateDocuments = baseTypeList?.filter((t) => CATEGORIES_FOR_PRIVATE_DOCUMENTS.has(t?.category));
  return !viewType ? privateDocuments : filterDocumentsByCategory(viewType, privateDocuments);
};

export const getDefaultNameForSingleUserDocument = (
  state: DocumentFormModalState,
  subjectToChange: Subject<string>
): string | undefined => {
  if (
    (!state.name || state.name.length === 0) &&
    state.type &&
    ((state.owners && state.owners.length === 1) || state.owner)
  ) {
    const defaultName = `${state.type.label} - ${
      state?.owners && state?.owners?.length ? state.owners[0].label : state?.owner?.label
    }`;
    subjectToChange.next(defaultName);
    return defaultName;
  }
};

export const getTypeState = (
  state: DocumentFormModalState,
  subjectToChange: Subject<DocumentType>,
  filterValue: 'company' | 'personal'
): DocumentType | undefined => {
  // if ((!state.name || state.name.length === 0) && state.type && state.owners && state.owners.length === 1) {
  //   const defaultName = `${state.type.label} - ${state.owners[0].label}`;
  //   subjectToChange.next(defaultName);
  //   return defaultName;
  // }

  if (filterValue) {
    if (filterValue === 'company') {
      if (state.type) {
        if (state.type.category === 'Company Documents') {
          subjectToChange.next(state.type);
          return state.type;
        }
        const defaultType = state.types.find((t) => t.category === 'Company Documents') as DocumentType;
        subjectToChange.next(defaultType);
        return defaultType;
      }
    } else {
      if (state.type) {
        if (state.type.category !== 'Company Documents') {
          subjectToChange.next(state.type);
          return state.type;
        }
        const defaultType = state.types.find((t) => t.category === 'Other') as DocumentType;
        subjectToChange.next(defaultType);
        return defaultType;
      }
    }
  }
};

function extractFileName(url: string): string {
  const lastSlashIndex = url.lastIndexOf('/');
  if (lastSlashIndex === -1) return url;
  return url.slice(lastSlashIndex + 1);
}

export function downloadFileByUrl(fileUrl: string, fileName = 'document'): void {
  if (!fileUrl) return;
  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = fileUrl;
  const finalFileName = extractFileName(fileName);
  link.download = finalFileName;

  // It needs to be added to the DOM, so it can be clicked
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait
  // a little while before removing it.
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link?.parentNode?.removeChild(link);
  }, 0);
}

export function previewFileInNewTab(fileUrl: string): void {
  if (!fileUrl) return;

  // Open the file URL in a new tab
  window.open(fileUrl, '_blank');
}

export function getDocumentParentCategory(
  reach: ActionReach,
  mode: DocumentAction,
  state: ZeltDocument | undefined,
  currentUserIsAdmin: boolean,
  documentTypes: readonly ZeltDocumentType[]
) {
  if (!currentUserIsAdmin) {
    return 'personal';
  }

  if (mode === 'edit' && state) {
    const documentTypeName = documentTypes.find((d) => d.value === state.type)?.category;
    return documentTypeName === 'Company Documents' ? 'company' : 'personal';
  }

  if (mode === 'request') return 'personal';

  return reach === 'me' ? 'personal' : 'company';
}

export const isPreviewableFile = (doc: ZeltDocument): boolean => {
  const fileNameFromAttachments = doc.attachments && doc.attachments[0] && doc.attachments[0]?.fileName;
  const finalFilename = doc?.fileName ?? fileNameFromAttachments;
  if (!finalFilename) return false;
  const fileExtension = finalFilename.substring(finalFilename.lastIndexOf('.'));
  return PREVIEWABLE_FILE_FORMATS.includes(fileExtension.toLowerCase());
};

export const companySignatureRequired = (row: ZeltDocument) => {
  return (
    row.contract?.signatoriesRequired &&
    row.contract?.signatoriesRequired?.includes(SignatoryType.additional) &&
    row.contract?.companySignatory
  );
};

export const recipientSignatureRequired = (row: ZeltDocument) => {
  return (
    row.contract?.signatoriesRequired &&
    row.contract?.signatoriesRequired?.includes(SignatoryType.recipient) &&
    row.contract?.recipient
  );
};

export const allPartiesHaveSignedContract = (row: ZeltDocument) => {
  const { contractId, contract } = row;
  const companySignatureExpected = companySignatureRequired(row);
  const recipientSignatureExpected = recipientSignatureRequired(row);

  if (!contractId || !contract) {
    return false;
  }

  // no signatories expected
  if (
    (!companySignatureExpected && !recipientSignatureExpected) ||
    !Array.isArray(contract.signatoriesRequired) ||
    contract.signatoriesRequired.length === 0
  ) {
    return true;
  }

  if (companySignatureExpected && !contract.companySignatureTimestamp) {
    return false;
  }

  if (recipientSignatureExpected && !contract.recipientSignatureTimestamp) {
    return false;
  }

  return true;
};
