import { Box } from '@mui/material';
import { Dictionary } from 'lodash';

import { ReactComponent as Comment } from '@/images/growth/Comment.svg';
import { ReactComponent as SmileHappy } from '@/images/growth/Smile_Happy.svg';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import {
  getAnalyticsByType,
  getReviewerTypesChipsByAnswer,
} from '@/v2/feature/growth/reviews/features/review-cycle/review-cycle.util';
import { QuestionType, QuestionTypeMap } from '@/v2/feature/growth/reviews/interfaces/review-question.interface';
import { QuestionResponseSummary } from '@/v2/feature/growth/reviews/interfaces/review.interface';
import { GridDisplayBarV2 } from '@/v2/feature/growth/shared/components/grid-display-bar.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const ResultsOverviewQuestionDisplayBar = ({
  question,
  growthFactors,
  onClick,
}: {
  question: QuestionResponseSummary;
  growthFactors: Dictionary<GrowthFactor>;
  onClick?: () => void;
}) => {
  return (
    <GridDisplayBarV2
      onClick={onClick}
      cells={[
        {
          content: (
            <Box sx={{ display: 'flex', width: '85%', flexDirection: 'column', gap: spacing.g8 }}>
              {question && question.questionText && <Typography variant="caption">{question.questionText}</Typography>}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g8 }}>
                {question.factor && (
                  <ChipComponent
                    backgroundColor="white"
                    border="background"
                    name={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: spacing.g4,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: growthFactors[question.factor]?.color ?? themeColors.Grey,
                            height: '8px',
                            width: '8px',
                            borderRadius: radius.br25,
                          }}
                        />
                        <Typography variant="caption" color="Grey">
                          {question.factor}
                        </Typography>
                      </Box>
                    }
                  />
                )}
                {question.type === QuestionType.ScaleQuestion && question.scaleConfig && (
                  <ChipComponent
                    backgroundColor="white"
                    border="background"
                    name={
                      <Typography variant="caption" color="Grey">
                        {question.scaleConfig.type}
                      </Typography>
                    }
                  />
                )}
                {question.answers && question.answers.length > 0 && getReviewerTypesChipsByAnswer(question.answers)}
                {question.type && (
                  <ChipComponent
                    backgroundColor="white"
                    border="background"
                    name={
                      <Typography variant="caption" color="Grey">
                        {QuestionTypeMap[question.type as QuestionType]}
                      </Typography>
                    }
                  />
                )}
                <ChipComponent
                  backgroundColor="white"
                  border="background"
                  name={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: spacing.g4,
                      }}
                    >
                      <SmileHappy {...iconSize} />
                      <Typography variant="caption" color="Grey">
                        {question.answersCount}
                      </Typography>
                    </Box>
                  }
                />
                {question.type !== QuestionType.OpenEnded && (
                  <ChipComponent
                    backgroundColor="white"
                    border="background"
                    name={
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: spacing.g4,
                        }}
                      >
                        <Comment {...iconSize} />
                        <Typography variant="caption" color="Grey">
                          {question.commentsCount}
                        </Typography>
                      </Box>
                    }
                  />
                )}
              </Box>
            </Box>
          ),
          gridXs: 8,
        },
        {
          content: getAnalyticsByType(question),
          gridXs: 3,
          sx: { display: 'flex', justifyContent: 'flex-start' },
        },
        {
          content: <></>,
          gridXs: 1,
        },
      ]}
    />
  );
};
