import { Fragment, Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ChipComponentWithIcon } from '@v2/components/chip/chip-with-icon.component';
import { DeviceAPI } from '@v2/feature/device/device.api';
import {
  AppliedDevicePoliciesDto,
  AppliedDevicePoliciesZeltDtoWithConfigurableFeature,
  ConfigurableDeviceData,
  DeviceDto,
  DevicePossessionDto,
  DeviceTransitDto,
} from '@v2/feature/device/device.dto';
import { DevicePossessionType, DeviceTransitStatus } from '@v2/feature/device/device.interface';
import {
  getDeviceOwnerByDevicePossession,
  getDeviceOwnerByDevicePossessionTable,
} from '@v2/feature/device/device.util';
import { DeviceActivityLogPage } from '@v2/feature/device/features/devices-company/pages/device-activity-log.page';
import { DeviceMdmDetailsPage } from '@v2/feature/device/features/devices-company/pages/device-mdm-details.page';
import { DeviceMdmSecurityPage } from '@v2/feature/device/features/devices-company/pages/device-mdm-security.page';
import { DeviceNotesPage } from '@v2/feature/device/features/devices-company/pages/device-notes.page';
import { InHouseMdmDeviceInstalledAppsPage } from '@v2/feature/device/features/devices-company/pages/inhouse-mdm-device-installed-apps.page';
import { NoAppliedPolicies } from '@v2/feature/device/features/devices-settings/features/zelt-mdm/policy.util';
import { UserAvatar } from '@v2/feature/user/components/user-avatar.component';
import { CachedUser, useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { generatePath, Redirect, Switch, useParams } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useMessage from '@/hooks/notification.hook';
import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as Mistake } from '@/images/side-bar-icons/Mistake.svg';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { nestErrorMessage } from '@/lib/errors';
import {
  DEVICE_COMPANY_DEVICE_ENROLL_ROUTE,
  DEVICES_ACTIVITY_LOG_ROUTE,
  DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE,
  DEVICES_COMPANY_DEVICE_DETAILS_ROUTE,
  DEVICES_COMPANY_DEVICE_INSTALED_APPS_ROUTE,
  DEVICES_COMPANY_DEVICE_LOCATION_ROUTE,
  DEVICES_COMPANY_DEVICE_POLICIES_ROUTE,
  DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE,
  DEVICES_COMPANY_DIRECTORY_ROUTE,
  DEVICES_COMPANY_INHOUSE_MDM_DEVICE_INSTALLED_APPS_ROUTE,
  DEVICES_NOTES_ROUTE,
} from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { ScopeContext } from '@/models/scopes.model';
import { CurrentUser } from '@/models/user.model';
import { DomainSideMenuContent } from '@/v2/components/domain-side-menu-content.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SkeletonLoader } from '@/v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceActionsButton } from '@/v2/feature/device/components/device-details/device-actions-button.component';
import { getModelImage } from '@/v2/feature/device/device.util';
import { DeviceInstalledAppsPage } from '@/v2/feature/device/features/devices-company/pages/device-installed-apps.page';
import { DeviceLocationPage } from '@/v2/feature/device/features/devices-company/pages/device-location.page';
import { DevicePoliciesPage } from '@/v2/feature/device/features/devices-company/pages/device-policies.page';
import { EnrollExistingDevice } from '@/v2/feature/device/features/order-device/enroll-existing-device.page';
import { themeColors } from '@/v2/styles/colors.styles';
import { radius } from '@/v2/styles/radius.styles';

const getDevicePageConfig = (
  devicePossessionId: number,
  device: DeviceDto | undefined,
  activeDeviceTransitNotConfirmed: boolean,
  isUserAssign: boolean,
  currentUser: CurrentUser,
  context: ScopeContext
) => {
  return [
    {
      title: '',
      stub: '',
      isHidden: false,
      hasChildren: true,
      subItems: [
        {
          title: 'Security',
          stub: 'security',
          path: generatePath(DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE, {
            devicePossessionId: devicePossessionId,
          }),
          isHidden: !device || !isUserAssign,
          hasChildren: false,
        },
        {
          title: 'Details',
          stub: 'details',
          path: generatePath(DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE, {
            devicePossessionId: devicePossessionId,
          }),
          isHidden: !checkScopes(currentUser, ['devices:all', 'devices'], context),
          hasChildren: false,
        },
        {
          title: 'Activity log',
          stub: 'activity_log',
          path: generatePath(DEVICES_ACTIVITY_LOG_ROUTE, { devicePossessionId: devicePossessionId }),
          isHidden: !checkScopes(currentUser, ['devices:all', 'devices'], context) || !device,
          hasChildren: false,
        },
        {
          title: 'Apps',
          stub: 'apps',
          path: generatePath(DEVICES_COMPANY_DEVICE_INSTALED_APPS_ROUTE, { devicePossessionId: devicePossessionId }),
          isHidden:
            (context ? checkScopes(currentUser, ['devices'], context) : true) &&
            (!device?.applications ||
              device.applications.length === 0 ||
              !(device?.enrollmentStatus === 'enrolled' || device?.enrollmentStatus === 'ENROLMENT_FINISHED')),
          hasChildren: false,
        },
        {
          title: 'Location',
          stub: 'location',
          path: generatePath(DEVICES_COMPANY_DEVICE_LOCATION_ROUTE, { devicePossessionId: devicePossessionId }),
          isHidden:
            !checkScopes(currentUser, ['devices:all', 'devices'], context) ||
            !device?.lastLocation?.longitude ||
            !device?.lastLocation?.latitude ||
            device.lastLocation.locationTrackingDisabled ||
            device?.inHouseMdm ||
            !(device?.enrollmentStatus === 'enrolled'),
          hasChildren: false,
        },
        {
          title: 'Notes',
          stub: 'notes',
          path: generatePath(DEVICES_NOTES_ROUTE, {
            devicePossessionId: devicePossessionId,
          }),
          isHidden: !checkScopes(currentUser, ['devices:all', 'devices'], context),
          hasChildren: false,
        },
      ],
    },
  ];
};

interface DevicesDetailsRouterProps {
  readonly refreshDeviceAppData: () => Promise<void>;
}

export const DevicesDetailsRouter = ({ refreshDeviceAppData }: DevicesDetailsRouterProps): React.JSX.Element | null => {
  const [devicePossession, setDevicePossession] = useState<DevicePossessionDto | null>(null);
  const [configurableDevice, setConfigurableDevice] = useState<ConfigurableDeviceData | null>(null);
  const [activeDeviceTransit, setActiveDeviceTransit] = useState<DeviceTransitDto | null>(null);
  const [activeDeviceTransitNotConfirmed, setActiveDeviceTransitNotConfirmed] = useState<boolean>(false);
  const [appliedPolicies, setAppliedPolicies] = useState<AppliedDevicePoliciesDto>(NoAppliedPolicies);
  const [loading, setLoading] = useState<boolean>(false);
  const { getCachedUserById } = useCachedUsers();
  const [assignedUser] = useState<CachedUser | undefined>(undefined);
  const [appliedZeltPolicies, setAppliedZeltPolicies] = useState<AppliedDevicePoliciesZeltDtoWithConfigurableFeature>(
    NoAppliedPolicies
  );
  const [
    appliedZeltPoliciesForMobile,
    setAppliedZeltPoliciesForMobile,
  ] = useState<AppliedDevicePoliciesZeltDtoWithConfigurableFeature>(NoAppliedPolicies);
  const [showMessage] = useMessage();
  const params = useParams<{ readonly devicePossessionId: string }>();
  const devicePossessionId = Number(params.devicePossessionId);
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });

  const fetchDeviceDetails = useCallback(async () => {
    try {
      setLoading(true);
      const [
        configurableDevice,
        activeDeviceTransit,
        appliedPolicies,
        zeltPolicies,
        zeltPoliciesForMobile,
      ] = await Promise.all([
        DeviceAPI.getDirectoryDeviceByPossessionId(devicePossessionId),
        DeviceAPI.getActiveTransitForDevicePossession(devicePossessionId),
        DeviceAPI.getAppliedCompanyDevicePolicies(),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeature(),
        DeviceAPI.getAppliedCompanyDevicePoliciesByZeltMdmWithConfigurableFeatureForMobile(),
      ]);
      setDevicePossession(configurableDevice.devicePossession);
      setConfigurableDevice(configurableDevice);
      setAppliedZeltPolicies(zeltPolicies);
      setAppliedZeltPoliciesForMobile(zeltPoliciesForMobile);
      setActiveDeviceTransit(activeDeviceTransit ?? null);
      setActiveDeviceTransitNotConfirmed(
        activeDeviceTransit
          ? [DeviceTransitStatus.Pending].includes(activeDeviceTransit.status) &&
              !activeDeviceTransit.isShippingManagedByZelt
          : false
      );
      setAppliedPolicies(Boolean(appliedPolicies) ? appliedPolicies : NoAppliedPolicies);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
    }
  }, [devicePossessionId, showMessage]);

  useEffect(() => {
    fetchDeviceDetails();
  }, [fetchDeviceDetails]);

  const refreshDevice = useCallback(async () => {
    try {
      setLoading(true);
      const configurableDevice = await DeviceAPI.getDirectoryDeviceByPossessionId(devicePossessionId);
      setDevicePossession(configurableDevice.devicePossession);
      setConfigurableDevice(configurableDevice);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showMessage(`Something bad happened. ${nestErrorMessage(error)}`, 'error');
    }
  }, [devicePossessionId, showMessage]);

  const isUserPossession = !(devicePossession?.possessionType === DevicePossessionType.User ?? false);
  const isSecurityHidden = !devicePossession?.device || isUserPossession;
  const { polyglot } = usePolyglot();

  const customTitle = useMemo(() => {
    const enrolled =
      devicePossession?.device?.enrollmentStatus === 'enrolled' ||
      devicePossession?.device?.enrollmentStatus === 'ENROLMENT_FINISHED';
    const getUserName = (): string => {
      return getDeviceOwnerByDevicePossession(
        devicePossession ?? undefined,
        configurableDevice?.sites?.find((site) => site.id === devicePossession?.possessionId) ?? null,
        getCachedUserById
      );
    };
    return (
      <Box>
        {loading ? (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <SkeletonLoader
              variant="rectangular"
              width="120px"
              height="70px"
              sx={{ background: themeColors.Background, borderRadius: radius.br10 }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              gap: spacing.s2,
              boxSizing: 'border-box',
            }}
          >
            {getModelImage(devicePossession?.device?.type, devicePossession?.device?.modelName, {
              width: '120px',
              height: '120px',
            }) ?? (
              <SkeletonLoader
                variant="rectangular"
                width="120px"
                height="100%"
                sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
              />
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                gap: spacing.s1,
                boxSizing: 'border-box',
              }}
            >
              <ChipComponentWithIcon
                name={enrolled ? 'Enroled' : 'Not enroled'}
                backgroundColor="white"
                textColor="DarkGrey"
                icon={
                  enrolled ? (
                    <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
                  ) : (
                    <Mistake {...iconSize} fill={themeColors.Red} />
                  )
                }
                border="light"
                textVariant="caption"
              />

              {devicePossession && (
                <>
                  {devicePossession.possessionType === DevicePossessionType.User ? (
                    <Box
                      sx={{
                        width: '90%',
                        boxSizing: 'border-box',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1, justifyContent: 'center' }}>
                        <UserAvatar
                          userId={devicePossession.possessionId}
                          key={devicePossession.possessionId}
                          size="xxsmall"
                        />
                        <Typography
                          variant="caption"
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            textAlign: 'center',
                            width: 'inherit',
                          }}
                        >
                          {getUserName()}
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    getDeviceOwnerByDevicePossessionTable(
                      devicePossession,
                      configurableDevice?.sites?.find((item) => item.id === devicePossession.possessionId),
                      polyglot,
                      assignedUser?.displayName
                    )
                  )}
                </>
              )}
            </Box>

            <DeviceActionsButton
              configurableDevice={configurableDevice!}
              fetchDeviceDetails={fetchDeviceDetails}
              activeTransit={activeDeviceTransit}
              refreshDevicesAppData={refreshDeviceAppData}
              assignedUser={
                configurableDevice?.devicePossession.possessionType === DevicePossessionType.User
                  ? getCachedUserById(configurableDevice?.devicePossession.possessionId)
                  : undefined
              }
            />
          </Box>
        )}
      </Box>
    );
  }, [
    activeDeviceTransit,
    assignedUser?.displayName,
    configurableDevice,
    devicePossession,
    fetchDeviceDetails,
    getCachedUserById,
    loading,
    polyglot,
    refreshDeviceAppData,
  ]);
  return (
    <Fragment>
      {devicePossession ? (
        <>
          <DomainSideMenuContent
            pageConfig={getDevicePageConfig(
              devicePossessionId,
              devicePossession?.device,
              activeDeviceTransitNotConfirmed,
              devicePossession?.possessionType === DevicePossessionType.User ?? false,
              state.user,
              scopesContext
            )}
            showBack
            backPath={generatePath(DEVICES_COMPANY_DIRECTORY_ROUTE, {
              devicePossessionId: devicePossession?.id,
            })}
            customTitle={customTitle}
            type="Domain"
          />
          <Switch>
            <RouteScopesControl
              exact
              scopes={['devices:all']}
              path={DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE}
              context={scopesContext}
            >
              <DeviceMdmDetailsPage
                configurableDevice={configurableDevice}
                ownerName={getDeviceOwnerByDevicePossession(
                  devicePossession,
                  configurableDevice?.sites.find((site) => site.id === devicePossession.possessionId),
                  getCachedUserById
                )}
                loading={loading}
                refresh={refreshDevice}
              />
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices:all']}
              path={DEVICES_COMPANY_DEVICE_INSTALED_APPS_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceInstalledAppsPage devicePossession={devicePossession} loading={loading} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl exact scopes={['devices:all']} path={DEVICES_NOTES_ROUTE} context={scopesContext}>
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceNotesPage devicePossession={devicePossession} loading={loading} refresh={refreshDevice} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices:all']}
              path={DEVICES_ACTIVITY_LOG_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceActivityLogPage devicePossession={devicePossession} loading={loading} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices:all']}
              path={DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceMdmSecurityPage
                  devicePossession={devicePossession}
                  fetchDeviceDetails={fetchDeviceDetails}
                  loading={loading}
                  activeTransit={activeDeviceTransit}
                  appliedPolicies={appliedPolicies}
                  appliedZeltPolicies={appliedZeltPolicies}
                  appliedZeltPoliciesForMobile={appliedZeltPoliciesForMobile}
                />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices:all']}
              path={DEVICES_COMPANY_INHOUSE_MDM_DEVICE_INSTALLED_APPS_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <InHouseMdmDeviceInstalledAppsPage devicePossession={devicePossession} loading={loading} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices:all']}
              path={DEVICES_COMPANY_DEVICE_POLICIES_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DevicePoliciesPage devicePossession={devicePossession} loading={loading} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices:all']}
              path={DEVICES_COMPANY_DEVICE_LOCATION_ROUTE}
              context={scopesContext}
            >
              <Suspense
                fallback={
                  <SkeletonLoader
                    variant="rectangular"
                    width="31%"
                    height="100%"
                    sx={{ borderRadius: '10px', backgroundColor: themeColors.Background }}
                  />
                }
              >
                <DeviceLocationPage devicePossession={devicePossession} assignedUser={assignedUser} loading={loading} />
              </Suspense>
            </RouteScopesControl>

            <RouteScopesControl
              exact
              scopes={['devices.enroll', 'devices:all']}
              path={DEVICE_COMPANY_DEVICE_ENROLL_ROUTE}
              context={scopesContext}
            >
              <EnrollExistingDevice initialDevicePossession={devicePossession} refreshAppData={refreshDeviceAppData} />
            </RouteScopesControl>

            {isSecurityHidden ? (
              <Redirect
                exact
                from={DEVICES_COMPANY_DEVICE_DETAILS_ROUTE}
                to={DEVICES_COMPANY_DEVICE_DETAILS_OVERVIEW_ROUTE}
              />
            ) : (
              <Redirect
                exact
                from={DEVICES_COMPANY_DEVICE_DETAILS_ROUTE}
                to={DEVICES_COMPANY_DEVICE_SECURITY_OVERVIEW_ROUTE}
              />
            )}
          </Switch>
        </>
      ) : null}
    </Fragment>
  );
};
