import { EXCEL_CLOCK_HOURS_COLUMNS_SET } from '@v2/feature/reports/components/result-table.component';
import {
  ReportColumnsOld,
  ReportEntity,
  ReportResponse,
  SelectedColumnsRequest,
  SelectedFiltersRequest,
} from '@v2/feature/reports/reports.interface';
import { LocalDate } from '@v2/util/local-date';
import { differenceInDays } from 'date-fns';
import saveAs from 'file-saver';
import { Base64 } from 'js-base64';
import JSZip from 'jszip';
import { kebabCase } from 'lodash';
import Polyglot from 'node-polyglot';
import Papa from 'papaparse';

function getCSVKeyMapping(
  data: Record<string, string | number | Date>[],
  resultColumnNameMapping?: Record<string, string>
): { [key: string]: string } {
  const keys = new Set(data.flatMap((entry) => Object.keys(entry)));
  return Object.fromEntries(
    [...keys].map((key) => {
      if (resultColumnNameMapping?.[key]) {
        return [key, resultColumnNameMapping?.[key]];
      }
      // if the key looks like 'Section: fieldName', convert it to 'section.field-name' which is
      // closer to the format used by the people import csv
      const m = key.match(/^(.+?): (.+)$/);
      if (m) {
        return [key, `${kebabCase(m[1])}.${kebabCase(m[2])}`];
      }
      return [key, key];
    })
  );
}

export const getNormalisedKeysOldReports = (
  data: Record<string, string | number | Date>[],
  resultColumnNameMapping?: Record<string, string>
): string[] => {
  const allKeys: string[] = [];
  for (const record of data)
    for (const d of Object.keys(record)) {
      const finalKey = resultColumnNameMapping?.[d] ?? d;
      if (allKeys.includes(finalKey)) continue;
      else {
        allKeys.push(resultColumnNameMapping?.[finalKey] ? resultColumnNameMapping?.[finalKey] : finalKey);
      }
    }

  return allKeys;
};

export const getNormalisedValue = (value: string | Date | number, key: string): string | number => {
  if (
    key.toLowerCase().includes('lastexternalsync') ||
    key.toLowerCase().includes('createdat') ||
    key.toLowerCase().includes('updatedat') ||
    key.toLowerCase().includes('lasttimeonline')
  )
    return value ? new LocalDate(value).toDateTimeString() : value;
  if (EXCEL_CLOCK_HOURS_COLUMNS_SET.has(key)) {
    const [hourValue, minutesValue] =
      value && typeof value === 'string' && (value as string).includes(':') ? (value as string).split(':') : [];

    const h = hourValue ? Number.parseInt(hourValue) : 0;
    const m = minutesValue ? Number.parseInt(minutesValue) : 0;

    return `${h}h ${m < 10 ? '0' : ''}${m}m`;
  }

  if (value instanceof Date) {
    return new LocalDate(value).toFullString();
  }
  return value;
};

export const capitalize = (s: string) => {
  let name = s && s[0].toUpperCase() + s.slice(1);
  if (name.includes(':')) {
    const colonIndex = name.indexOf(':');
    name = name.slice(0, colonIndex + 2) + name[colonIndex + 2].toUpperCase() + name.slice(colonIndex + 3);
  }
  return name;
};

export const getDateType = (start: string, end: string, polyglot: Polyglot): string => {
  const dateDifference = differenceInDays(new Date(end), new Date(start));
  if (dateDifference === 30) return polyglot.t('getDateType.thirty');
  if (dateDifference === 7) return polyglot.t('getDateType.seven');
  return polyglot.t('getDateType.custom');
};

export const exportReportCSVOldReports = (
  reportName: string,
  filteredData:
    | {
        [key: string]: Record<string, string | number>[];
      }
    | undefined,
  fileName?: string
) => {
  if (filteredData) {
    const allKeys = Object.keys(filteredData).filter((key) => filteredData[key].length > 0);
    const allFiles = allKeys.map((data) =>
      Papa.unparse({ data: filteredData[data], fields: getNormalisedKeysOldReports(filteredData[data]) })
    );
    const zip = new JSZip();
    allFiles.map((file, idx) => zip.file(fileName ?? `report-${allKeys[idx]}.csv`, file));
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${reportName}.zip`);
    });
  }
};

export const exportReportCSV = (reportName: string, reportResponse: ReportResponse) => {
  // Header of CSV
  const keys = Object.keys(reportResponse.header.order);
  // Sort keys by order
  keys.sort((a, b) => reportResponse.header.order[a] - reportResponse.header.order[b]);
  const fields = keys.map((key) => reportResponse.header.labels[key] ?? key);

  // Data of CSV
  const data = reportResponse.data.map((row) => {
    const objResponse: string[] = [];
    for (const key of keys) {
      objResponse.push(row[key].value);
    }
    return objResponse;
  });

  // Convert JSON data to CSV format
  const csv = Papa.unparse({ fields, data });

  // Create a blob from the CSV string
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  // Create a link element
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  // Set the download attribute with a filename
  link.setAttribute('href', url);
  link.setAttribute('download', `${reportName}.csv`);

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};

export const handleMappingForCSVExport = (data: Record<string, any>[], customMappings: Record<string, string>) => {
  const allMappedRecords: unknown[] = [];
  const mapping = getCSVKeyMapping(data, customMappings);
  data.forEach((d) => {
    const mappedRecord: Record<string, unknown> = {};
    for (const key of Object.keys(d)) {
      const finalKey = mapping[key] ?? key;
      mappedRecord[finalKey] = d[key];
    }
    allMappedRecords.push(mappedRecord);
  });
  return {
    data: allMappedRecords,
    fields: Object.values(mapping),
  };
};

export const exportCurrentReportCSV = (
  reportName: string,
  filteredData: Record<string, any>[] | undefined,
  resultColumnNameMapping?: Record<string, string>
) => {
  if (filteredData) {
    const { data, fields } = handleMappingForCSVExport(filteredData, resultColumnNameMapping ?? {});
    const csvData = Papa.unparse({
      data,
      fields,
    });

    const filename = `zelt-report-${new LocalDate().toDateString()}-${kebabCase(reportName)}.csv`;
    saveAs(new Blob([csvData], { type: 'text/csv' }), filename);
  }
};

export const extractAllColumnsFromColumnsConfig = (columnsConfig: ReportColumnsOld): string[] => {
  return Object.keys(columnsConfig).flatMap((entity: string): string[] => {
    return columnsConfig[entity as ReportEntity] ?? [];
  });
};

export function getReportShareURL(
  selectedColumns: SelectedColumnsRequest,
  selectedFilters: SelectedFiltersRequest
): { success: true; url: string; errorMessage: undefined } | { success: false; url: null; errorMessage: string } {
  try {
    // const queryColumnsJSON = encodeURIComponent(JSON.stringify(selectedColumns));
    // const queryFiltersJSON = encodeURIComponent(JSON.stringify(selectedFilters));
    const queryColumnsJSON = Base64.encode(JSON.stringify(selectedColumns));
    const queryFiltersJSON = Base64.encode(JSON.stringify(selectedFilters));
    const isProduction = process.env.REACT_APP_ENV === 'production';
    const envDomain = `${isProduction ? 'go.zelt.app' : 'go.testzelt.com'}`;

    const url = `https://${envDomain}/report/company/reports-advanced/create?columns=${queryColumnsJSON}&filters=${queryFiltersJSON}`;
    return { success: true, url, errorMessage: undefined };
  } catch (error) {
    console.error(error);
    return { success: false, url: null, errorMessage: error.message };
  }
}
