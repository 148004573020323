import axios from 'axios';
import { SiteDto, SiteEditableFieldsDto, SiteWithMembersDto } from '@v2/feature/site/site.dto';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class SiteAPI {
  static async listSites(includeUserCount = false): Promise<SiteDto[]> {
    const query = `includeUserCount=${includeUserCount ? 'true' : 'false'}`;
    return (await axios.get<SiteDto[]>(`/apiv2/companies/sites?${query}`)).data;
  }

  static async getSitesWithMemberDetails(): Promise<SiteWithMembersDto[]> {
    return (await axios.get(`/apiv2/companies/sites-members`)).data;
  }

  static async getSite(siteId: number, includeUserCount = false): Promise<SiteDto> {
    const query = `includeUserCount=${includeUserCount ? 'true' : 'false'}`;
    return (await axios.get<SiteDto>(`/apiv2/companies/sites/${siteId}?${query}`)).data;
  }

  static async saveNewSite(site: SiteEditableFieldsDto): Promise<number> {
    return (await axios.post('/apiv2/companies/sites', site)).data;
  }

  static async updateSite(siteId: number, update: SiteEditableFieldsDto): Promise<void> {
    await axios.patch(`/apiv2/companies/sites/${siteId}`, update);
  }

  static async deleteSite(siteId: number): Promise<void> {
    await axios.delete(`/apiv2/companies/sites/${siteId}`);
  }

  static async listSitesAsSuperAdmin(): Promise<SiteDto[]> {
    return (await axios.get<SiteDto[]>(`/apiv2/companies/sites/superadmin`)).data;
  }
}

export class SiteEndpoints {
  static getSites(includeUserCount = false): Endpoint<SiteDto[]> {
    const query = `includeUserCount=${includeUserCount ? 'true' : 'false'}`;
    return {
      url: `/apiv2/companies/sites?${query}`,
    };
  }

  static getSitesCount(): Endpoint<{ count: number }> {
    return {
      url: `/apiv2/companies/sites-count`,
    };
  }

  static getUserSite(userId: number): Endpoint<SiteDto | null> {
    return {
      url: `/apiv2/users/${userId}/site`,
    };
  }
}
