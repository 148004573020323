import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { NewBenefitDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/components/new-benefit-drawer.component';
import {
  CustomBenefitCategory,
  CustomBenefitDto,
  CustomBenefitType,
} from '@v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { SETTINGS_BENEFITS_DETAILS_ROUTE } from '@/lib/routes';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { getDefaultBenefitImageByCategory } from '@/v2/feature/benefits/benefits.util';
import { InsuranceType } from '@/v2/feature/benefits/subfeature/insurance/insurance.interface';
import { getLogoByProviderName } from '@/v2/feature/benefits/subfeature/insurance/insurance.util';
import { getPensionLogoByProviderName } from '@/v2/feature/benefits/subfeature/pension/pension.util';
import { spacing } from '@/v2/styles/spacing.styles';

interface RowDataI {
  id: number;
  category: CustomBenefitCategory | InsuranceType.Health;
  type: CustomBenefitType;
  name: string;
  status?: string | null;
  benefitType: 'pension' | 'insurance' | 'custom-benefit';
  productType: 'standard' | 'custom';
  url?: string | null;
}

interface BenefitsSettingsListPageProps {
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly customBenefits: CustomBenefitDto[] | null;
  readonly refreshCustomBenefits: () => Promise<void>;
  readonly loading: boolean;
}

export const BenefitsSettingsListPage = ({
  insuranceQuote,
  pensionSchemes,
  customBenefits,
  refreshCustomBenefits,
  loading,
}: BenefitsSettingsListPageProps) => {
  const { polyglot } = usePolyglot();
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const history = useHistory();

  const rows = useMemo(() => {
    const data: RowDataI[] = [];
    if (pensionSchemes) {
      data.push(
        ...pensionSchemes.map(
          (pS) =>
            ({
              id: pS.id,
              name: pS.displayName,
              category: 'Pension',
              type: 'Recurring',
              benefitType: 'pension',
              productType: 'standard',
              url: null,
            } as RowDataI)
        )
      );
    }
    if (insuranceQuote) {
      data.push({
        id: insuranceQuote.id,
        name: insuranceQuote.policy?.providerName ?? insuranceQuote.type,
        category: InsuranceType.Health,
        type: CustomBenefitType.Recurring,
        status: insuranceQuote.status,
        benefitType: 'insurance',
        productType: 'standard',
        url: null,
      } as RowDataI);
    }
    if (customBenefits) {
      data.push(
        ...customBenefits.map(
          (cB) =>
            ({
              id: cB.id,
              name: cB.name,
              category: cB.category,
              type: cB.type,
              benefitType: 'custom-benefit',
              productType: 'custom',
              url: cB.url || null,
            } as RowDataI)
        )
      );
    }

    if (!searchInput) return data;

    const search = searchInput.toLowerCase();
    return data.filter((r) => r.name.toLowerCase().includes(search));
  }, [insuranceQuote, pensionSchemes, customBenefits, searchInput]);

  const renderBenefitCard = (item: RowDataI) => {
    const image =
      item.benefitType === 'insurance' ? (
        getLogoByProviderName(item.name, 40)
      ) : item.benefitType === 'custom-benefit' ? (
        item.url ? (
          <img src={item.url} width={40} height={40} alt="benefit-logo" />
        ) : (
          getDefaultBenefitImageByCategory(item.category as CustomBenefitCategory, '50px', 'auto')
        )
      ) : item.benefitType === 'pension' ? (
        getPensionLogoByProviderName(item.name, 40)
      ) : (
        <></>
      );

    return (
      <SettingsItemCard
        key={item.id}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
            <Box>{image}</Box>
            <Typography variant="title4">{item.name}</Typography>
          </Box>
        }
        boxSx={responsiveCardStyle}
        boxAction={() => {
          history.push(
            generatePath(SETTINGS_BENEFITS_DETAILS_ROUTE, {
              productType: item.productType,
              category: item.category,
              id: item.id,
            })
          );
        }}
        contentItemsSets={[
          {
            name: item.type,
            type: ContentItemType.chip,
            textColor: 'DarkGrey',
            backgroundColor: 'white',
            border: 'middle',
          },
          {
            name: item.category,
            type: ContentItemType.chip,
            textColor: 'DarkGrey',
            backgroundColor: 'white',
            border: 'middle',
          },
        ]}
      />
    );
  };

  return (
    <ContentWrapper loading={false}>
      <SettingsSectionContent
        title={polyglot.t('BenefitModule.benefits')}
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsOpen(true)}>
            {polyglot.t('BenefitModule.newBenefit')}
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          loadingAll={loading}
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <TableSearch
                      query={searchInput}
                      handleChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value:
                    rows.length > 0 ? (
                      <Box sx={flexContainerStyle}>{rows.map(renderBenefitCard)}</Box>
                    ) : (
                      <Typography variant="caption">{polyglot.t('BenefitModule.benefitsNotFound')}</Typography>
                    ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
      <NewBenefitDrawer isOpen={isOpen} setIsOpen={setIsOpen} refresh={refreshCustomBenefits} />
    </ContentWrapper>
  );
};
