import { useContext, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as Copy } from '@/images/side-bar-icons/Copy.svg';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  PaymentSettingsPageSection,
  DescriptionLine,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-section.component';
import { DeveloperHubClientGenerateDrawer } from '@/v2/feature/security/security-settings/components/developer-hub-client-generate-drawer.component';
import { DeveloperHubDeleteClientConfirmDrawer } from '@/v2/feature/security/security-settings/features/developer-hub/components/developer-hub-delete-client-confirm-drawer.component';
import { NonSensitiveOauthClientConfiguration } from '@/v2/feature/security/security-settings/security.interface';
import { spacing } from '@/v2/styles/spacing.styles';
import { iconSize } from '@/v2/styles/table.styles';

const API_TERMS_LINK = 'https://zelt.app/legal/api-terms-of-use/';
const README_LINK = 'https://zelt.readme.io/';

interface PageProps {
  oauthApp: NonSensitiveOauthClientConfiguration;
  onUpdate: () => void;
  onDelete: () => void;
}

export const DeveloperHubGeneralSettingsPage = ({ oauthApp, onUpdate, onDelete }: PageProps) => {
  const { polyglot } = usePolyglot();
  const [state] = useContext(GlobalContext);
  const [isDeleteDrawerOpen, setIsDeleteDrawerOpen] = useState<boolean>(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);

  return (
    <PaymentSettingsPageSection
      title={polyglot.t('General.general')}
      onEdit={() => {
        setIsEditDrawerOpen(true);
      }}
    >
      {/* onEdit={handleEditForName}> */}
      <Typography variant="caption">{polyglot.t('DeveloperHub.generalPageExplainer')}</Typography>
      <DescriptionLine field={polyglot.t('DeveloperHub.appName')} value={oauthApp.name} />
      <DescriptionLine
        field={polyglot.t('DeveloperHub.appIcon')}
        value={
          oauthApp.logo ? <img src={`${state.publicURL}/${oauthApp.logo}`} alt="app-icon" width={30} /> : <EmptyCell />
        }
      />
      <DescriptionLine field={polyglot.t('DeveloperHub.clientTable.description')} value={oauthApp.description} />
      <DescriptionLine
        field={polyglot.t('DeveloperHub.clientTable.redirectionUri')}
        value={
          oauthApp.redirectionUri && {
            isLink: true,
            label: oauthApp.redirectionUri,
            icon: <Copy {...iconSize} />,
            onClick: () => navigator.clipboard.writeText(oauthApp.redirectionUri),
          }
        }
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: spacing.mt20 }}>
        <Typography variant="title3" sx={{ color: themeColors.DarkGrey }}>
          {polyglot.t('DeveloperHub.clientTable.deleteApp')}
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption">
          {polyglot.t('DeveloperHub.clientTable.deleteSubtitle')}
          <ButtonComponent
            sizeVariant="small"
            colorVariant="text"
            onClick={() => {
              setIsDeleteDrawerOpen(true);
            }}
            style={{
              margin: 0,
              padding: 0,
              color: themeColors.RedDark,
              display: 'inline',
              textDecoration: 'underline',
            }}
          >
            {polyglot.t('PaymentSettingsPage.deleteHere')}
          </ButtonComponent>
          .
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: spacing.mt20 }}>
        <Typography variant="title3" sx={{ color: themeColors.DarkGrey }}>
          {polyglot.t('DeveloperHub.resourcesTitle')}
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption">
          {polyglot.t('DeveloperHub.resourcesSubtitleFirst')}
          <ButtonComponent
            sizeVariant="small"
            colorVariant="text"
            onClick={() => {
              window.open(API_TERMS_LINK, '_blank');
            }}
            style={{
              margin: 0,
              padding: 0,
              color: themeColors.RedDark,
              display: 'inline',
              textDecoration: 'underline',
            }}
          >
            terms of use
          </ButtonComponent>
          .{polyglot.t('DeveloperHub.resourcesSubtitleSecond')}
          <ButtonComponent
            sizeVariant="small"
            colorVariant="text"
            onClick={() => {
              window.open(README_LINK, '_blank');
            }}
            style={{
              margin: 0,
              padding: 0,
              color: themeColors.RedDark,
              display: 'inline',
              textDecoration: 'underline',
            }}
          >
            here
          </ButtonComponent>
          .
        </Typography>
      </Box>
      {oauthApp && (
        <DeveloperHubDeleteClientConfirmDrawer
          oauthApp={oauthApp}
          isOpen={isDeleteDrawerOpen}
          setIsOpen={setIsDeleteDrawerOpen}
          onDelete={onDelete}
        />
      )}
      <DeveloperHubClientGenerateDrawer
        isOpen={isEditDrawerOpen}
        existingClient={oauthApp}
        setIsOpen={setIsEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
        onUpdate={onUpdate}
      />
    </PaymentSettingsPageSection>
  );
};
