import { useMemo, useState } from 'react';

import { List, ListItem, ListItemText } from '@mui/material';
import { Box } from '@mui/system';
import { ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { OauthClientAPI, OauthClientEndpoints } from '@/api-client/oauth-client.api';
import useMessage from '@/hooks/notification.hook';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as WaitingEmpty } from '@/images/side-bar-icons/WaitingEmpty.svg';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { Typography } from '@/v2/components/typography/typography.component';
import {
  DescriptionLine,
  PaymentSettingsPageSection,
} from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-section.component';
import { DeveloperHubPermissionsEditDrawer } from '@/v2/feature/security/security-settings/features/developer-hub/components/developer-hub-permissions-edit-drawer.component';
import { getIconForAppStatus } from '@/v2/feature/security/security-settings/features/developer-hub/developer-hub-build-apps.page';
import {
  PublishRequest,
  PublishStatus,
} from '@/v2/feature/security/security-settings/features/developer-hub/developer-hub.interface';
import { NonSensitiveOauthClientConfiguration } from '@/v2/feature/security/security-settings/security.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { dateAPItoDisplay } from '@/v2/util/date-format.util';
import { LocalDate } from '@/v2/util/local-date';
import { toTitleCase } from '@/v2/util/string.util';

interface PageProps {
  oauthApp: NonSensitiveOauthClientConfiguration;
  onUpdate: () => void;
}

export const DeveloperHubPublishSettingsPage = ({ oauthApp, onUpdate }: PageProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const { data: publishHistory, mutate: refresh } = useApiClient(
    oauthApp.clientId ? OauthClientEndpoints.getPublishRequestsForClientId(oauthApp.clientId) : { url: undefined },
    {
      suspense: false,
    }
  );

  // if they already have a pending publish request they cannot submit another one
  const cannotSubmitAnotherPublishRequest =
    publishHistory?.some((request) => request.status === 'pending') || oauthApp.published;

  const columns = useMemo<ColumnDef<PublishRequest, PublishRequest>[]>(() => {
    return [
      {
        header: () => polyglot.t('DeveloperHub.clientTable.createdAt'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'createdAt',
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{dateAPItoDisplay(original.createdAt)}</Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('DeveloperHub.reasons'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 200, // Increased max width to accommodate the list
        id: 'scopeReasons',
        cell: ({ row: { original } }) => (
          <>
            {original.scopeReasons ? (
              <List dense>
                {Object.entries(original.scopeReasons).map(([scope, reason]) => (
                  <ListItem key={scope} sx={{ pl: 0 }}>
                    <ListItemText
                      primary={<Typography variant="caption">{scope}</Typography>}
                      secondary={<Typography variant="captionSmall">{reason}</Typography>}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <EmptyCell />
            )}
          </>
        ),
      },
      {
        header: () => polyglot.t('DeveloperHub.clientTable.scopes'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 150, // Increased max width to accommodate the list
        id: 'scopes',
        cell: ({ row: { original } }) => (
          <List dense>
            {original.requestedScopes.map((scope) => (
              <ListItem key={scope} sx={{ pl: 0 }}>
                <ListItemText primary={<Typography variant="caption">{scope}</Typography>} />
              </ListItem>
            ))}
          </List>
        ),
      },
      {
        header: () => polyglot.t('DeveloperHub.status'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'status',
        cell: (info) => {
          const status = info.getValue().status;

          const getStatusIcon = (status: PublishStatus) => {
            switch (status) {
              case 'pending':
                return <WaitingEmpty />;
              case 'approved':
                return <OkGreen fill={themeColors.Green} />;
              case 'rejected':
                return <Rejected fill={themeColors.Red} />;
              default:
                return null;
            }
          };

          return (
            <Box display="flex" alignItems="center">
              {getStatusIcon(status)}
              <Typography variant="caption" sx={{ ml: 1 }}>
                {toTitleCase(status)}
              </Typography>
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('DeveloperHub.reviewedAt'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'reviewedBy',
        enableSorting: true,
        cell: ({ row: { original } }) => (
          <Box>
            {original.reviewedAt ? (
              <Typography variant="caption">{new LocalDate(original.reviewedAt).toDateTimeString()}</Typography>
            ) : (
              <EmptyCell />
            )}
          </Box>
        ),
      },
    ];
  }, [polyglot]);

  const submitNewPublishRequest = async () => {
    try {
      const { clientId, scopes, scopeReasons } = oauthApp;
      await OauthClientAPI.submitOAuthClientPublishRequest(clientId, scopes, scopeReasons);
      showMessage('Successfully submitted publish request!', 'success');
      refresh!();
    } catch (error) {
      showMessage(`Encountered an error while trying to submit publish request: ${nestErrorMessage(error)}`, 'error');
    }
  };

  return (
    <>
      <PaymentSettingsPageSection
        title={polyglot.t('DeveloperHub.publish')}
        editSection={
          !oauthApp.published ? (
            <ButtonComponent
              colorVariant="primary"
              sizeVariant="small"
              onClick={submitNewPublishRequest}
              disabled={cannotSubmitAnotherPublishRequest}
            >
              Submit
            </ButtonComponent>
          ) : (
            <></>
          )
        }
      >
        {!oauthApp.published && (
          <Typography variant="caption">{polyglot.t('DeveloperHub.publishExplainer')}</Typography>
        )}
        {isEditOpen && (
          <DeveloperHubPermissionsEditDrawer
            isOpen={isEditOpen}
            setIsOpen={setIsEditOpen}
            oauthApp={oauthApp}
            onClose={function (): void {
              setIsEditOpen(false);
            }}
            onUpdate={onUpdate}
          />
        )}
        <DescriptionLine
          field={polyglot.t('DeveloperHub.status')}
          value={getIconForAppStatus(polyglot, oauthApp.published)}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g10, mt: spacing.mt20, mb: spacing.mb20 }}>
          <Typography variant="title3" sx={{ color: themeColors.DarkGrey }}>
            {polyglot.t('DeveloperHub.publishHistory')}
          </Typography>
          {publishHistory?.length && publishHistory?.length > 0 ? (
            <BasicTable<PublishRequest>
              rowData={publishHistory}
              columnData={columns}
              loading={false}
              hidePagination
              externalTdAndThPadding="0 0px"
              style={{ width: '900px' }}
            />
          ) : (
            <Box>
              <Typography variant="caption" color="Grey" sx={{ mt: '8px' }}>
                {polyglot.t('DeveloperHub.publishHistoryNoneYet')}
              </Typography>
            </Box>
          )}
        </Box>
      </PaymentSettingsPageSection>
    </>
  );
};
