import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, SxProps, Theme, Typography } from '@mui/material';
import { PensionAPI } from '@v2/feature/benefits/subfeature/pension/pension.api';
import { PayrunPensionStates } from '@v2/feature/benefits/subfeature/pension/pension.interface';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { PensionContributionStatusDto } from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.dto';
import {
  PayrunProcessStepState,
  PayrunProcessStepStates,
  PensionContributionState,
  PensionContributionStates,
  PensionContributionStatuses,
} from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface';
import { PayrollLocalApi } from '@v2/feature/payroll/payroll-local.api';
import { PayRunDto } from '@v2/feature/payroll/payroll.dto';
import { ExternalDataProvider } from '@v2/feature/payroll/payroll.interface';
import { themeColors } from '@v2/styles/colors.styles';
import { themeFonts } from '@v2/styles/fonts.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { CSVLink } from 'react-csv';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PensionProviders } from '@/lib/pensions';
import { PENSION_SETTINGS_ROUTE } from '@/lib/routes';
import { PayrunProcessingItem } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component';
import { PensionConfirmDoneDrawer } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/sections/pension/pension-confirm-done-drawer.component';

interface PensionContributionsProps {
  readonly payRun: PayRunDto;
  readonly pensionSchemeId: number;
  readonly providerName: string | undefined;
  readonly pensionContributionsState: {
    [key: number]: PensionContributionState;
  };
  readonly setPensionContributionsState: React.Dispatch<
    React.SetStateAction<{
      [key: number]: PensionContributionState;
    }>
  >;
  readonly isPensionSchemeSetUp: boolean | null;
  readonly externalProviders: readonly ExternalDataProvider[] | null;
  sx?: SxProps<Theme>;
}

const RETRY_COUNTER = 5;

export const PensionContributions = ({
  payRun,
  pensionSchemeId,
  providerName,
  isPensionSchemeSetUp,
  externalProviders,
  pensionContributionsState,
  setPensionContributionsState,
  sx,
}: PensionContributionsProps): JSX.Element => {
  const [accordionState, setAccordionState] = useState<PayrunProcessStepState>(PayrunProcessStepStates.pending);
  const [isPensionConnected, setIsPensionConnected] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [contributionStatus, setContributionStatus] = useState<PensionContributionStatusDto | undefined>(undefined);
  const [openConfirmMarkAsSent, setOpenConfirmMarkAsSent] = useState(false);

  const csvRef = useRef<any>();
  const [csvFile, setCSVFile] = useState<{ readonly name: string; readonly data: unknown }>({
    name: 'default.csv',
    data: [],
  });
  const [showMessage] = useMessage();

  const fetchPensionSchemeContributions = useCallback(
    async (payrunId: number, count: number, poll: (count: number) => void): Promise<void> => {
      const contributionStatus = await PayrollLocalApi.getPensionContributionStatusForPayrun(payrunId, pensionSchemeId);
      setContributionStatus(contributionStatus);

      if (contributionStatus.status === PensionContributionStatuses.Unknown) {
        return;
      }
      // SENT
      if (contributionStatus.status === PensionContributionStatuses.Sent) {
        contributionStatus.statusMessage !== 'Manually marked as Sent'
          ? setPensionContributionsState((prev) => ({
              ...prev,
              [pensionSchemeId]: PensionContributionStates.submitted,
            }))
          : setPensionContributionsState((prev) => ({
              ...prev,
              [pensionSchemeId]: PensionContributionStates.markedAsSent,
            }));
        setAccordionState(PayrunProcessStepStates.success);
        return;
      }

      // IN PROGRESS - poll limit not reached - poll again
      if (
        [PensionContributionStatuses.Queued, PensionContributionStatuses.Processing].includes(
          contributionStatus.status
        ) &&
        count < RETRY_COUNTER
      ) {
        poll(count + 1);
        return;
      }

      // IN PROGRESS - poll limit reached - show Refresh button
      if (
        [PensionContributionStatuses.Queued, PensionContributionStatuses.Processing].includes(
          contributionStatus.status
        ) &&
        count >= RETRY_COUNTER
      ) {
        setAccordionState(PayrunProcessStepStates.warning);
        return;
      }

      // ERROR
      if (contributionStatus.status === PensionContributionStatuses.Failed) {
        setContributionStatus(contributionStatus);
        setAccordionState(PayrunProcessStepStates.failure);
        return;
      }
    },
    [pensionSchemeId, setPensionContributionsState]
  );

  const poll = useCallback(
    (count: number) => {
      setTimeout(() => fetchPensionSchemeContributions(payRun.id, count, poll), count === 0 ? 0 : 3000);
    },
    [fetchPensionSchemeContributions, payRun]
  );

  useEffect(() => {
    const handleMissingPensionSchemeId = () => {
      setContributionStatus({
        title: 'Payrun pensionSchemeId is missing.',
        status: PensionContributionStatuses.Unknown,
        statusMessage:
          "One of the reason the pensionSchemeId is missing is that the pension scheme used for this payrun was deleted and can't be found anymore.",
        errorsDetails: [],
      });
      setAccordionState(PayrunProcessStepStates.warning);
    };
    (async () => {
      setAccordionState(PayrunProcessStepStates.pending);
      try {
        if (isPensionSchemeSetUp === null || externalProviders === null) {
          // don't do anything until we have the information needed to determine pension status
          return;
        }

        // If PENSION NOT SET UP
        if (!isPensionSchemeSetUp) {
          setAccordionState(PayrunProcessStepStates.warning);
          return;
        }

        if (!pensionSchemeId) {
          handleMissingPensionSchemeId();
          return;
        }
        const payrunPensionRecord = await PensionAPI.getPayrunPensionRecordForPensionScheme(payRun.id, pensionSchemeId);

        // If COMPLETED don't poll
        if ([PayrunPensionStates.submitted, PayrunPensionStates.markedAsSent].includes(payrunPensionRecord.state)) {
          setContributionStatus({
            title: payrunPensionRecord.title,
            status: payrunPensionRecord.status,
            statusMessage: payrunPensionRecord.statusMessage,
            errorsDetails: [],
          });
          payrunPensionRecord.state === PayrunPensionStates.submitted
            ? setPensionContributionsState((prev) => ({
                ...prev,
                [pensionSchemeId]: PensionContributionStates.submitted,
              }))
            : setPensionContributionsState((prev) => ({
                ...prev,
                [pensionSchemeId]: PensionContributionStates.markedAsSent,
              }));
          setAccordionState(PayrunProcessStepStates.success);
          return;
        }

        const isPensionConnected = Boolean(
          // If there are 2 providers of the same type (eg. Nest), and only one of them is connected, staffology returns only one record for Nest which says it is connected
          // the non-connected pension will try to submit, but it will fail and ask for a retry. No mark as sent button should be shown, the company should reach to Zelt and the pension should be connected in staffology
          // In most cases the pension will already be connected so this situation should not happen.
          externalProviders.find((provider) => providerName === provider.id && provider.connected)
        );
        setIsPensionConnected(isPensionConnected);
        // If PENSION NOT CONNECTED
        if (!isPensionConnected) {
          setAccordionState(PayrunProcessStepStates.warning);
          return;
        }

        // If not COMPLETED & PENSION CONNECTED
        poll(0);
      } catch (error) {
        showMessage(
          `Something went wrong. Could not get pension submission status. ${nestErrorMessage(error)}`,
          'error'
        );
        setAccordionState(PayrunProcessStepStates.failure);
      }
    })();
  }, [
    payRun.id,
    setPensionContributionsState,
    isPensionSchemeSetUp,
    pensionSchemeId,
    poll,
    showMessage,
    externalProviders,
    providerName,
  ]);

  const resubmitPensionContributions = useCallback(async () => {
    try {
      await PensionAPI.resubmitPensionSchemeContribution(
        pensionSchemeId,
        payRun.taxYear,
        payRun.payPeriod,
        payRun.period
      );
    } catch (error) {
      showMessage(`Could not re-submit pension scheme contribution. ${nestErrorMessage(error)}`, 'error');
    }
  }, [payRun.payPeriod, payRun.period, payRun.taxYear, pensionSchemeId, showMessage]);

  useEffect(() => {
    if (csvFile?.data && csvFile.name !== 'default.csv' && csvRef.current && csvRef.current.link) {
      // @ts-ignore
      csvRef.current.link.click();
    }
  }, [csvFile]);

  const exportPensionContributions = async () => {
    const downloadCSVFile = (name: string, data: any) => {
      setCSVFile({ name, data });
    };

    try {
      setIsDownloading(true);
      const { taxYear, payPeriod, period } = payRun;

      const pensionContributions = await PensionAPI.getPapdisFile(pensionSchemeId, taxYear, payPeriod, period);
      downloadCSVFile(`payrun-${taxYear}-Month-${period}-pension-contributions.csv`, pensionContributions.content);
    } catch (error) {
      showMessage(`Could not download contributions file. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsDownloading(false);
    }
  };

  const exportCushonContributionsFile = async () => {
    const downloadCSVFile = (name: string, data: any) => {
      setCSVFile({ name, data });
    };

    try {
      setIsDownloading(true);
      const { taxYear, payPeriod, period } = payRun;

      const cushonExport = await PensionAPI.getCushonFile(pensionSchemeId, taxYear, payPeriod, period);
      downloadCSVFile(`payrun-${taxYear}-${payPeriod}-${period}-cushon.csv`, cushonExport.content);
    } catch (error) {
      showMessage(`Could not download Cushon contributions file. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setIsDownloading(false);
    }
  };

  const handlePensionContributionDownload = () => {
    if (providerName === PensionProviders.Cushon) {
      exportCushonContributionsFile();
    } else {
      exportPensionContributions();
    }
  };

  const isSubmitting =
    !!contributionStatus &&
    [PensionContributionStatuses.Queued, PensionContributionStatuses.Processing].includes(contributionStatus.status);

  const isMarkedAsSent = pensionContributionsState[pensionSchemeId] === PensionContributionStates.markedAsSent;

  return (
    <>
      <PayrunProcessingItem
        title={
          providerName ? (
            <Box sx={{ display: 'flex', gap: spacing.gap5, alignItems: 'center' }}>
              <Box>{getPensionLogoByProviderName(providerName, 20)}</Box>
              <Typography sx={{ ...themeFonts.title4, whiteSpace: 'nowrap', color: themeColors.DarkGrey }}>
                {PensionProvidersValueToLabel[providerName] ?? providerName}
              </Typography>
            </Box>
          ) : pensionSchemeId ? (
            'Pension'
          ) : (
            'Pension | Unknown State'
          )
        }
        key={`pension-${providerName}`}
        description={
          {
            pending: 'Submitting pension contributions...',
            failure: `Pension contribution submission failed. ${
              contributionStatus?.statusMessage || ''
            } (Current status: ${contributionStatus?.status || ''})`,
            success: isMarkedAsSent
              ? 'Pension contributions have been marked as sent.'
              : 'Pension contributions have been submitted.',
            warning: !pensionSchemeId
              ? 'The payrun pension record is missing the id of the pension scheme used when the payrun was opened.'
              : !isPensionSchemeSetUp
              ? 'There is no pension provider set up. You may be reported to the Pension Regulator if you are not enrolling eligible employees into a pension scheme. All UK employers must offer a workplace pension scheme by law.'
              : !isPensionConnected
              ? 'Zelt is not connected to your pension provider and cannot submit pension contributions. You should download and send the contribution data to your provider to ensure pension contributions are kept up-to-date.'
              : isSubmitting
              ? 'Pension contributions are being submitted. For some providers it can take a few minutes.'
              : '',
          }[accordionState]
        }
        buttons={[
          {
            style: 'primary',
            label: 'Set up Pension',
            show: !isPensionSchemeSetUp && !!pensionSchemeId,
            onClick: () => window.open(PENSION_SETTINGS_ROUTE, '_blank'),
            type: 'button',
          },
          {
            style: 'primary',
            label: 'Retry',
            show: !!isPensionSchemeSetUp && isPensionConnected && accordionState !== PayrunProcessStepStates.success,
            onClick: async () => {
              if (contributionStatus?.status === PensionContributionStatuses.Failed)
                await resubmitPensionContributions();

              setAccordionState(PayrunProcessStepStates.pending);
              await poll(0);
            },
            type: 'button',
          },
          {
            style: 'secondary',
            label: 'Mark as sent',
            show: (!!isPensionSchemeSetUp && !isPensionConnected && !isMarkedAsSent) || accordionState === 'failure',
            onClick: () => setOpenConfirmMarkAsSent(true),
            type: 'button',
          },
          {
            style: 'secondary',
            label: 'Download',
            show: !!isPensionSchemeSetUp,
            loading: isDownloading,
            onClick: () => handlePensionContributionDownload(),
            type: 'button',
          },
        ]}
        success={
          accordionState === PayrunProcessStepStates.pending
            ? undefined
            : accordionState === PayrunProcessStepStates.success
        }
        sx={sx}
      />
      <CSVLink
        ref={csvRef}
        filename={csvFile.name}
        data={Array.isArray(csvFile.data) || typeof csvFile.data === 'string' ? csvFile.data : []}
      />
      <PensionConfirmDoneDrawer
        open={openConfirmMarkAsSent}
        onClose={() => setOpenConfirmMarkAsSent(false)}
        markAsSent={async () => {
          const updatedPensionStatus = await PayrollLocalApi.markPensionContributionAsSent(payRun.id, pensionSchemeId);
          setContributionStatus(updatedPensionStatus);
          setPensionContributionsState((prev) => ({
            ...prev,
            [pensionSchemeId]: PensionContributionStates.markedAsSent,
          }));
          setAccordionState(PayrunProcessStepStates.success);
        }}
      />
    </>
  );
};
