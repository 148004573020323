import { Box } from '@mui/material';
import { SwitchButton } from '@v2/components/split-switch-button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ReportSideBarView } from '@v2/feature/reports/reports-advanced/components/report-config.section';
import { themeColors } from '@v2/styles/colors.styles';
import { radius } from '@v2/styles/radius.styles';

interface Props {
  readonly view: ReportSideBarView;
  readonly setView: React.Dispatch<React.SetStateAction<ReportSideBarView>>;
}

export const ColumnsFiltersSwitch = ({ view, setView }: Props) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '30px',
        background: themeColors.Background,
        borderRadius: radius.br20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <SwitchButton
        key="columns"
        isActive={view === 'columns-list'}
        color={themeColors.DarkGrey}
        action={() => setView('columns-list')}
      >
        <Typography
          variant="caption"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            color: view === 'columns-list' ? themeColors.white : themeColors.DarkGrey,
          }}
          className={view === 'columns-list' ? 'tnm-switch-active-text' : 'tnm-switch-text'}
        >
          Columns
        </Typography>
      </SwitchButton>
      <SwitchButton
        key="filters"
        isActive={view === 'filters-list'}
        color={themeColors.DarkGrey}
        action={() => setView('filters-list')}
      >
        <Typography
          variant="caption"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            color: view === 'filters-list' ? themeColors.white : themeColors.DarkGrey,
          }}
          className={view === 'filters-list' ? 'tnm-switch-active-text' : 'tnm-switch-text'}
        >
          Filters
        </Typography>
      </SwitchButton>
      <SwitchButton
        key="settings"
        isActive={view === 'settings'}
        color={themeColors.DarkGrey}
        action={() => setView('settings')}
      >
        <Typography
          variant="caption"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            color: view === 'settings' ? themeColors.white : themeColors.DarkGrey,
          }}
          className={view === 'settings' ? 'tnm-switch-active-text' : 'tnm-switch-text'}
        >
          Settings
        </Typography>
      </SwitchButton>
    </Box>
  );
};
