import { DeviceTransitStatus } from '@v2/feature/device/device.interface';
import { getDeviceOwnerByDevicePossession } from '@v2/feature/device/device.util';
import {
  format,
  getDate,
  isBefore,
  isMonday,
  isSameDay,
  isSameMonth,
  isWithinInterval,
  previousMonday,
} from 'date-fns';
import Polyglot from 'node-polyglot';
import { Layout } from 'react-grid-layout';

import { CurrentUser } from '@/models';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { WidgetLayout } from '@/v2/feature/dashboard/features/dashboard-edit-mode.page';
import { ActionListItems, KindTypes } from '@/v2/feature/dashboard/interfaces/dashboard.interface';
import { CachedUser } from '@/v2/feature/user/context/cached-users.context';
import { LocalDate } from '@/v2/util/local-date';

export const getDateFormation = (start: string | Date, end?: Date | string | null | undefined) => {
  const startDate = new LocalDate(start).getDate();
  if (!end) return `${format(startDate, 'd MMM')}`;
  else {
    const endDate = new LocalDate(end).getDate();
    if (isSameMonth(startDate, endDate)) return `${getDate(startDate)}-${format(endDate, 'd MMM')}`;
    if (!isSameMonth(startDate, endDate)) return `${format(startDate, 'd MMM')}-${format(endDate, 'd MMM')}`;
  }
};

export const getDayMonthFormation = (start: string) => {
  const [_year, month, day] = start.split('-').map(Number);
  const placeholderYear = 1700;

  const date = new Date(placeholderYear, month - 1, day);
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' });

  return formatter.format(date);
};

export const getPlaceholderDate = (dateString: string) => {
  const [month, day] = dateString.split('-').map(Number);
  const placeholderYear = 1700;
  return new LocalDate(new Date(placeholderYear, month - 1, day)).toDateString();
};

export const isAnniversaryToday = (date: Date | string, currentDate: Date): boolean => {
  const eventDate = new LocalDate(date).getDate();

  if (isSameDay(eventDate, currentDate)) return false;
  if (!isBefore(eventDate, currentDate)) return false;

  eventDate.setFullYear(currentDate.getFullYear());

  return isSameDay(eventDate, currentDate);
};

export function isAnniversaryInPeriod(date: Date | string, weekRange: string[]): boolean {
  const eventDate = new LocalDate(date).getDate();
  const startDate = new LocalDate(weekRange[0]).getDate();
  const endDate = new LocalDate(weekRange[1]).getDate();

  if (isWithinInterval(eventDate, { start: startDate, end: endDate })) return false;
  if (!isBefore(eventDate, endDate)) return false; //Edgecase: today 5-04-2023 & eventDate: 5-04-2024

  eventDate.setFullYear(startDate.getFullYear());

  return isWithinInterval(eventDate, { start: startDate, end: endDate });
}

export const getWeekRange = (): string[] => {
  const today = new Date();
  if (isMonday(today)) {
    const nextSunday = new Date();
    nextSunday.setDate(nextSunday.getDate() + 6);
    return [new LocalDate(today).toDateString(), new LocalDate(nextSunday).toDateString()];
  }
  const lastMonday = new Date(previousMonday(today));
  const comingSunday = new Date(previousMonday(today));
  comingSunday.setDate(comingSunday.getDate() + 6);
  return [new LocalDate(lastMonday).toDateString(), new LocalDate(comingSunday).toDateString()];
};

export const getTodayToLastDayOfMonth = (): string[] => {
  const todayDate = new Date();
  const firstDayOfNextMonth = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 1);
  // Subtract 1 day from the first day of the next month to get the last day of the current month
  const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);
  return [new LocalDate(todayDate).toDateString(), new LocalDate(lastDayOfMonth).toDateString()];
};

export const getTodayAndDateAfter30Days = (): string[] => {
  const todayDate = new LocalDate();
  const dateAfter30Days = new LocalDate(todayDate.getDate().getTime() + 30 * 24 * 60 * 60 * 1000); // Adding 30 days in milliseconds

  const todayDateString = todayDate.toDateString();
  const dateAfter30DaysString = dateAfter30Days.toDateString();

  return [todayDateString, dateAfter30DaysString];
};

export const getDatesBetweenMonths = (startDate: string, endDate: string): string[] => {
  const start = new LocalDate(startDate);
  const end = new LocalDate(endDate);
  const result = [];

  while (start.toDateString() <= end.toDateString()) {
    result.push(start.toDateString()); // Format the date as 'YYYY-MM-DD'
    start.getDate().setDate(start.getDate().getDate() + 1); // Increment the date
  }

  return result;
};

export const editGridLayout = (order: string[] | undefined, preferenceLookup: any): WidgetLayout[] => {
  let x = 0;
  let y = 0;
  const layout: WidgetLayout[] = [];

  order?.forEach((widget) => {
    const widgetDetails = preferenceLookup[widget];
    const w = widgetDetails.size === 'small' ? 4 : 8;

    if (x + w > 12) {
      x = 0;
      y += 1;
    }

    layout.push({
      x: x,
      y: y,
      w: w,
      h: 1,
      i: widget,
      static: widgetDetails.isStatic,
      isBounded: true,
      isResizable: false,
    });

    x += w;
  });

  return layout;
};

export function getStringArrayInOrder(arr: Layout[]) {
  const sortedArray = arr.sort((a, b) => {
    if (a.y === b.y) {
      return a.x - b.x;
    }
    return a.y - b.y;
  });

  let result = [];
  let prevY = sortedArray[0].y;

  for (const item of sortedArray) {
    if (item.y !== prevY) {
      prevY = item.y;
    }
    result.push(item.i);
  }

  return result;
}

export const getDetailByDomain = (
  item: ActionListItems,
  getCachedUserById: (userId: number) => CachedUser | undefined,
  currentUser: CurrentUser,
  polyglot: Polyglot
) => {
  switch (item.kind) {
    case KindTypes.IncompleteTasks: {
      const { name, dueDate } = item;
      return polyglot.t('getDetailByDomain.incompleteTask', {
        name: polyglot.t(name),
        date: format(new LocalDate(dueDate).getDate(), 'd MMM'),
      });
    }

    case KindTypes.PendingRequest: {
      const { cancellationRequested, userId, policyName, start, end } = item;
      return cancellationRequested
        ? polyglot.t('getDetailByDomain.pendingCancellationRequest', {
            displayName: polyglot.t(getCachedUserById(userId)?.displayName ?? ''),
            policyName: policyName ? polyglot.t(policyName) : null,
            date: getDateFormation(start, end),
          })
        : polyglot.t('getDetailByDomain.pendingRequest', {
            displayName: polyglot.t(getCachedUserById(userId)?.displayName ?? ''),
            policyName: policyName ? polyglot.t(policyName) : null,
            date: getDateFormation(start, end),
          });
    }
    case KindTypes.PendingPHCancellation: {
      const { userId } = item;
      return polyglot.t('getDetailByDomain.holidayDeletion', {
        displayName: polyglot.t(getCachedUserById(userId)?.displayName ?? ''),
      });
    }

    case KindTypes.SubmittedAttendance: {
      const { userId, weekNo } = item;
      return polyglot.t('getDetailByDomain.attendanceAwaiting', {
        displayName: polyglot.t(getCachedUserById(userId)?.displayName ?? ''),
        weekNo: weekNo,
      });
    }

    case KindTypes.UnfinishedAttendance: {
      const { userId, logDate } = item;
      return polyglot.t('getDetailByDomain.unfinishedShift', {
        displayName: polyglot.t(getCachedUserById(userId)?.displayName ?? ''),
        date: getDateFormation(logDate),
      });
    }

    case KindTypes.MissingDocuments: {
      const { name } = item;
      return polyglot.t('getDetailByDomain.missingDocument', { name: polyglot.t(name) });
    }

    case KindTypes.PendingContracts: {
      return polyglot.t('getDetailByDomain.pendingContracts');
    }

    case KindTypes.PendingReviews: {
      const { dueDate, userId, cycleName, reviewType } = item;
      return polyglot.t('getDetailByDomain.completeReview', {
        cycleName: cycleName,
        date: getDateString(dueDate, false, 'd MMM yyyy'),
        user: getCachedUserById(userId)?.displayName,
        reviewType: reviewType,
      });
    }

    case KindTypes.PendingSurveys: {
      const { dueDate, cycleName } = item;
      return polyglot.t('getDetailByDomain.completeSurvey', {
        cycleName: cycleName,
        date: getDateString(dueDate, false, 'd MMM yyyy'),
      });
    }

    case KindTypes.PendingRequests: {
      const { userId, appName } = item;
      return currentUser.userId === userId
        ? polyglot.t('getDetailByDomain.youRequested', { appName: appName })
        : polyglot.t('getDetailByDomain.requestedAccessTo', {
            appName: appName,
            displayName: polyglot.t(getCachedUserById(userId)?.displayName ?? ''),
          });
    }

    case KindTypes.PendingBenefitPayment: {
      const { userId } = item;
      // TODO: @polyglot-later
      return currentUser.userId === userId
        ? 'You requested a benefit payment'
        : `${polyglot.t(getCachedUserById(userId)?.displayName ?? '')} requested a benefit payment`;
    }

    case KindTypes.BenefitRequest: {
      const { userId } = item;
      // TODO: @polyglot-later
      return currentUser.userId === userId
        ? 'You requested a benefit change'
        : `${polyglot.t(getCachedUserById(userId)?.displayName ?? '')} requested a benefit change`;
    }

    case KindTypes.ExpenseRequest: {
      const { userId } = item;
      // TODO: @polyglot-later
      return currentUser.userId === userId
        ? 'You requested an expense'
        : `${polyglot.t(getCachedUserById(userId)?.displayName ?? '')} requested an expense`;
    }

    case KindTypes.InvoiceRequest: {
      const { userId } = item;
      // TODO: @polyglot-later
      return currentUser.userId === userId
        ? 'You requested an invoice'
        : `${polyglot.t(getCachedUserById(userId)?.displayName ?? '')} requested an invoice`;
    }

    case KindTypes.SubmittedRequestForm: {
      const { formName, status } = item;
      return status === 'pending'
        ? polyglot.t('getDetailByDomain.awaitingApproval', {
            formName: formName,
          })
        : polyglot.t('getDetailByDomain.requestApproved', {
            formName: formName,
          });
    }

    case KindTypes.DeviceTransit: {
      const { modelName, status } = item;
      if (currentUser.userId === item.senderPossession.possessionId && status === DeviceTransitStatus.Pending) {
        return polyglot.t('getDetailByDomain.deviceTransitSender', {
          model: modelName,
          receiverName: getDeviceOwnerByDevicePossession(item.receiverPossession, null, getCachedUserById),
        });
      } else if (
        currentUser.userId === item.receiverPossession.possessionId &&
        status === DeviceTransitStatus.Shipping
      ) {
        return polyglot.t('getDetailByDomain.deviceTransitReceiver', {
          model: modelName,
        });
      }
      // return polyglot.t('getDetailByDomain.deviceTransitGeneral', {
      //   model: modelName,
      //   receiverName: getDeviceOwnerByDevicePossession(item.receiverPossession, null, getCachedUserById),
      //   senderName: getDeviceOwnerByDevicePossession(item.senderPossession, null, getCachedUserById),
      // });
      return '';
    }
    default:
      return '';
  }
};
