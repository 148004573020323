import { useContext, useMemo } from 'react';

import { ReportCompanyRouter } from '@v2/feature/app-layout/features/v2/routers/report-company.router';
import { ReportTeamRouter } from '@v2/feature/app-layout/features/v2/routers/report-team.router';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import { DASHBOARD_ROUTE, REPORT_COMPANY_ROUTE, REPORT_TEAM_ROUTE, REPORTS_ROUTE } from '@/lib/routes';
import { canAccessScopes } from '@/lib/scopes';

export const ReportRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });

  const location = useLocation();
  const { isCompanyTab, isRootPath, isAdmin, isManager } = useMemo(() => {
    const isCompanyTab = location.pathname.startsWith(REPORT_COMPANY_ROUTE);
    const isRootPath = location.pathname === REPORTS_ROUTE;
    const isAdmin = canAccessScopes(state.user, ['reports:all']);
    const isManager = canAccessScopes(state.user, ['reports:manager']);

    return { isCompanyTab, isRootPath, isAdmin, isManager };
  }, [location.pathname, state.user]);

  return (
    <Switch>
      <RouteScopesControl
        scopes={['reports:all']}
        context={scopesContext}
        path={REPORT_COMPANY_ROUTE}
        component={ReportCompanyRouter}
      />

      <RouteScopesControl
        scopes={['reports:manager']}
        context={scopesContext}
        path={REPORT_TEAM_ROUTE}
        component={ReportTeamRouter}
      />

      <Redirect
        to={
          isAdmin && (isCompanyTab || isRootPath)
            ? REPORT_COMPANY_ROUTE
            : isManager
            ? REPORT_TEAM_ROUTE
            : DASHBOARD_ROUTE
        }
      />
    </Switch>
  );
};
