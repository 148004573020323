import { Box } from '@mui/material';
import { capitalize } from 'lodash';

import { CurrentUser } from '@/models';
import { Typography } from '@/v2/components/typography/typography.component';
import { CycleState } from '@/v2/feature/growth/shared/interfaces/growth-common.interface';
import {
  AnonymityType,
  MeasureBy,
  ShowBy,
  SurveyCycle,
  SurveyHeatmap,
  SurveyResultByFactor,
  SurveyResultByQuestion,
  SurveyVisibilitySettings,
} from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { borders } from '@/v2/styles/borders.styles';
import { styledChipSx } from '@/v2/styles/chip.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const getStatus = () => {
  return {
    [CycleState.Draft]: getStatusChip(CycleState.Draft, 'white', 'DarkGrey'),
    [CycleState.Scheduled]: getStatusChip(CycleState.Scheduled, 'DarkGrey', 'white'),
    [CycleState.Launching]: getStatusChip(CycleState.Launching, 'Background', 'DarkGrey'),
    [CycleState.Finalising]: getStatusChip(CycleState.Finalising, 'Background', 'DarkGrey'), // doesnt exist for survey
    [CycleState.Paused]: getStatusChip(CycleState.Paused, 'Background', 'DarkGrey'),
    [CycleState.Ongoing]: getStatusChip(CycleState.Ongoing, 'Green', 'white'),
    [CycleState.Completed]: getStatusChip(CycleState.Completed, 'Background', 'DarkGrey'),
  };
};

export const getStatusChip = (
  label: CycleState,
  backgroundColor: keyof typeof themeColors,
  color: keyof typeof themeColors
) => (
  <Box
    sx={{
      ...styledChipSx,
      backgroundColor: themeColors[backgroundColor],
      border: backgroundColor === 'white' ? borders.background : 'none',
      width: 'fit-content',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Typography variant="caption" color={color}>
      {capitalize(label)}
    </Typography>
  </Box>
);

export const TitleStatusComponent = ({ surveyCycle }: { surveyCycle: SurveyCycle }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.g16 }}>
    <Typography variant="title2">{surveyCycle.internalName ?? 'Overview'}</Typography>
    {getStatus()[surveyCycle.state]}
  </Box>
);

export const getPercentByMeasurementQuestion = (
  resultItem: SurveyResultByQuestion,
  measureByFilter: MeasureBy
): number | null => {
  if (measureByFilter === MeasureBy.Average) return (resultItem && resultItem.normPercent) ?? null;
  if (measureByFilter === MeasureBy.NPS) return (resultItem && resultItem.npsPercent) ?? null;
  return (resultItem && resultItem.positivePercent) ?? null;
};

export const getPercentByMeasurementFactor = (
  resultItem: SurveyResultByFactor,
  measureByFilter: MeasureBy
): number | null => {
  if (measureByFilter === MeasureBy.Average) return (resultItem && resultItem.normPercent) ?? null;
  if (measureByFilter === MeasureBy.NPS) return (resultItem && resultItem.npsPercent) ?? null;
  return (resultItem && resultItem.positivePercent) ?? null;
};

const processQuestions = (surveyHeatmap: SurveyHeatmap[], loggedInUser: CurrentUser) => {
  const uniqueQuestions = new Map<string, string>();
  surveyHeatmap.forEach((item) => {
    item.result.resultByQuestion.forEach((questionItem) => {
      const questionId = questionItem.question.id;
      const questionText = questionItem.question.questionText.replaceAll(
        '{company_name}',
        loggedInUser.company.name || 'company'
      );
      uniqueQuestions.set(questionId, questionText);
    });
  });
  return Array.from(uniqueQuestions, ([id, text]) => ({ id, text }));
};

const processFactors = (surveyHeatmap: SurveyHeatmap[]) => {
  const uniqueFactors = new Set<string>();
  surveyHeatmap.forEach((item) => {
    item.result.resultByFactor.forEach((result) => {
      const factor = result.factor;
      if (factor) uniqueFactors.add(factor);
    });
  });
  return Array.from(uniqueFactors);
};

const generateSeriesQuestion = (surveyHeatmap: SurveyHeatmap[], categories: any[], measureByFilter: MeasureBy) => {
  return surveyHeatmap.map((data) => {
    const dataPoints = categories.map((category) => {
      const resultItem = data.result.resultByQuestion.find((qItem) => qItem.question['id'] === category['id']);
      return resultItem ? getPercentByMeasurementQuestion(resultItem, measureByFilter) : null;
    });
    return {
      name: data.name,
      data: dataPoints,
    };
  });
};

const generateSeriesFactor = (surveyHeatmap: SurveyHeatmap[], categories: any[], measureByFilter: MeasureBy) => {
  return surveyHeatmap.map((data) => {
    const dataPoints = categories.map((category) => {
      const resultItem = data.result.resultByFactor.find((fItem) => fItem.factor === category);
      return resultItem ? getPercentByMeasurementFactor(resultItem, measureByFilter) : null;
    });
    return {
      name: data.name,
      data: dataPoints,
    };
  });
};

export const transformData = (
  surveyHeatmap: SurveyHeatmap[] | null | undefined,
  showByFilter: ShowBy,
  measureByFilter: MeasureBy,
  loggedInUser: CurrentUser
) => {
  if (!surveyHeatmap) return { series: [{ name: 'NA', data: [] }], categories: [] };

  if (showByFilter === ShowBy.Questions) {
    const questionArray = processQuestions(surveyHeatmap, loggedInUser);
    const series = generateSeriesQuestion(surveyHeatmap, questionArray, measureByFilter);
    return { series, categories: questionArray.map((q) => q.id) };
  } else {
    const factorArray = processFactors(surveyHeatmap);
    const series = generateSeriesFactor(surveyHeatmap, factorArray, measureByFilter);
    return { series, categories: factorArray };
  }
};

export const isDataEmpty = (surveyHeatmap: SurveyHeatmap[] | null | undefined, showByFilter: ShowBy) => {
  if (!surveyHeatmap) return true;

  if (showByFilter === ShowBy.Factor) return surveyHeatmap.every((item) => item.result.resultByFactor.length === 0);

  if (showByFilter === ShowBy.Questions)
    return surveyHeatmap.every((item) => item.result.resultByQuestion.length === 0);

  return true;
};

export const getAnonymitySetting = (visibilitySettings: SurveyVisibilitySettings) => {
  if (visibilitySettings.anonymiseAnswers)
    return visibilitySettings.anonymityType === AnonymityType.Semi ? 'Semi' : 'Full';

  return 'Not anonymous';
};
