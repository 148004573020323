import React, { JSXElementConstructor, ReactNode, Suspense, useCallback, useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, PaginationState, Row, SortingState } from '@tanstack/react-table';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { Typography } from '@v2/components/typography/typography.component';
import { AbsenceDto, AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { convertMinutesToClockHours, getPeriodFromAbsence, isHourlyPolicy } from '@v2/feature/absence/absence.util';
import { AbsenceTableActions } from '@v2/feature/absence/sections/absence-table-actions.component';
import { canApproveOrRejectRequest } from '@v2/feature/approval-rule/approval-rule.util';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { FiltersEndpoints } from '@v2/feature/filters/filters.api';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateAbsenceStatuses } from '@v2/infrastructure/i18n/translate.util';
import { spacing } from '@v2/styles/spacing.styles';
import { iconSize } from '@v2/styles/table.styles';
import Polyglot from 'node-polyglot';

import { GlobalContext } from '@/GlobalState';
import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Rejected } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as WaitingEmpty } from '@/images/side-bar-icons/WaitingEmpty.svg';
import { ReactComponent as WaitingEmptyRed } from '@/images/side-bar-icons/WaitingEmptyRed.svg';
import { CheckboxComponent } from '@/v2/components/forms/checkbox.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { BasicServerTable } from '@/v2/components/table/server-side-table.component';
import { sortNumeric, sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { AbsenceStatus } from '@/v2/feature/absence/absence.interface';
import { themeColors } from '@/v2/styles/colors.styles';

interface AbsenceActionsProps {
  readonly onEdit?: (_: AbsenceDto) => void;
  readonly onDelete?: (_: AbsenceDto) => Promise<void>;
  readonly onApprove?: (_: AbsenceDto) => Promise<AbsenceDto | void>;
  readonly onReject?: (_: AbsenceDto) => Promise<AbsenceDto | void>;
}

interface AbsenceTableProps extends AbsenceActionsProps {
  readonly rows: readonly AbsenceDto[];
  readonly loading?: boolean;
  readonly showUserColumn?: boolean;
  readonly canExport?: boolean;
  readonly emptyState?: JSXElementConstructor<unknown>;
  readonly onEdit?: (absence: AbsenceDto) => void;
  readonly onDelete?: (absence: AbsenceDto) => Promise<void>;
  readonly onApprove?: (absence: AbsenceDto) => Promise<AbsenceDto | undefined>;
  readonly onReject?: (absence: AbsenceDto, rejectionNote?: string) => Promise<AbsenceDto | undefined>;
  readonly onRequestCancellation?: (absence: AbsenceDto) => Promise<void>;
  readonly onRejectCancellation?: (absence: AbsenceDto) => Promise<void>;
  readonly onForceApproval?: (
    absence: AbsenceDto,
    status: AbsenceStatus.Approved | AbsenceStatus.Rejected
  ) => Promise<void>;
  readonly onRowClick?: (absence: AbsenceDto) => void;
  readonly newRequestButton?: ReactNode;
  readonly setSelectionModel?: React.Dispatch<React.SetStateAction<number[]>>;
  readonly selectionModel?: number[];
  readonly pagination: PaginationState;
  readonly setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  readonly totalPages: number;
  readonly totalItems: number;
  readonly searchInput: string;
  readonly setDebouncedSearchInput: (value: string) => void;
  readonly filterString: string;
  readonly setFilterString: React.Dispatch<React.SetStateAction<string>>;
  readonly view: 'company' | 'team' | 'user';
  readonly userId: number | undefined; // if view = 'user', userId is required
  readonly hideTimePolicyFilter?: boolean;
  readonly hidePolicyColumn?: boolean;
  readonly stickyHeader?: boolean;
}

export function AbsenceTable({
  rows,
  showUserColumn = false,
  loading,
  onRowClick,
  newRequestButton,
  setSelectionModel,
  selectionModel,
  pagination,
  setPagination,
  totalPages,
  searchInput,
  setDebouncedSearchInput,
  filterString,
  setFilterString,
  view,
  totalItems,
  userId,
  hideTimePolicyFilter,
  hidePolicyColumn,
  stickyHeader,
  ...handlers
}: AbsenceTableProps): React.JSX.Element {
  const { polyglot } = usePolyglot();

  const { data: filters } = useApiClient(FiltersEndpoints.getTimeFiltersConfig(view, userId), {
    suspense: false,
  });

  const [state] = useContext(GlobalContext);

  const timeFilters = useMemo(() => {
    const filterOptions = { ...(filters?.timeFilters ?? {}) };
    if (hideTimePolicyFilter) delete filterOptions['Time policy'];

    return filterOptions;
  }, [filters, hideTimePolicyFilter]);

  const { getCachedUserById } = useCachedUsers();
  const [sorting, setSorting] = useState<SortingState>([]);

  const pendingRequests = useMemo(() => {
    return rows.filter(
      (absence) => absence.status === AbsenceStatus.Pending && canApproveOrRejectRequest(absence, state.user.userId)
    );
  }, [rows, state.user]);

  const hiddenTableColumns = useMemo(() => {
    const hasNonHourly = rows && rows.some((a) => !isHourlyPolicy(a.policy as Pick<AbsencePolicyDto, 'allowanceType'>));
    return hasNonHourly ? [] : ['workdayCount'];
  }, [rows]);

  const columns = useMemo<ColumnDef<AbsenceDto, AbsenceDto>[]>(
    () => [
      ...(setSelectionModel && selectionModel && pendingRequests.length > 0
        ? [
            {
              id: 'select',
              enableSorting: false,
              minSize: 20,
              maxSize: 20,
              header: () => {
                const allSelected = selectionModel.length > 0 && selectionModel.length === pendingRequests.length;
                return (
                  <Box onClick={(e) => e.stopPropagation()}>
                    <CheckboxComponent
                      label={undefined}
                      name="allSelected"
                      checked={allSelected}
                      value="allSelected"
                      onChange={() => {
                        if (!allSelected)
                          setSelectionModel(
                            rows
                              .filter(
                                (absence) =>
                                  absence.status === AbsenceStatus.Pending &&
                                  canApproveOrRejectRequest(absence, state.user.userId)
                              )
                              ?.map((a) => a.absenceId)
                          );
                        else setSelectionModel([]);
                      }}
                    />
                  </Box>
                );
              },
              cell: ({ row: { original } }: { row: Row<AbsenceDto> }) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.m10 }}>
                  <Box onClick={(e) => e.stopPropagation()}>
                    {original.status === AbsenceStatus.Pending &&
                    canApproveOrRejectRequest(original, state.user.userId) ? (
                      <CheckboxComponent
                        label={undefined}
                        name={original.userId?.toString() ?? ''}
                        checked={selectionModel.includes(original.absenceId)}
                        value={original.userId?.toString() ?? ''}
                        onChange={() => {
                          let finalArray: number[];
                          if (selectionModel?.includes(original.absenceId)) {
                            finalArray = selectionModel.filter((sm) => sm !== original.absenceId);
                          } else finalArray = [...selectionModel, original.absenceId];
                          setSelectionModel(finalArray);
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
              ),
            },
          ]
        : []),
      ...(showUserColumn
        ? [
            {
              header: () => polyglot.t('AbsenceTable.name'),
              accessorFn: (row: AbsenceDto) => row,
              id: 'displayName',
              minSize: 100,
              maxSize: 150,
              enableSorting: true,
              sortingFn: (a: Row<AbsenceDto>, b: Row<AbsenceDto>) =>
                sortString(a, b, (item) => (item.user ? `${item.user?.firstName} ${item.user?.lastName}` : '')),
              cell: ({ row: { original } }: { row: Row<AbsenceDto> }) => {
                return <UserCell userId={original.userId} />;
              },
            },
          ]
        : []),
      {
        header: () => polyglot.t('AbsenceTable.start'),
        accessorFn: (row) => row,
        id: 'start',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item?.start ?? ''),
        cell: ({ row }) => {
          const period = getPeriodFromAbsence(row.original, false);
          return (
            <Box sx={{ display: 'flex', gap: spacing.g5, whiteSpace: 'pre', alignItems: 'center' }}>
              <time>{period}</time>
            </Box>
          );
        },
      },
      {
        header: () => polyglot.t('AbsenceTable.length'),
        id: 'totalLength',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.totalLength ?? 0),
        accessorFn: (row) => row,
        cell: ({ row }) => {
          const isHourly = isHourlyPolicy(row.original.policy as Pick<AbsencePolicyDto, 'allowanceType'>);
          const totalLength = row.original.totalLength ?? 0;

          if (isHourly) {
            const startHour = row.original.startHourTimestamp
              ? new Date(row.original.startHourTimestamp)
                  .toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .toUpperCase()
              : undefined;
            const endHour = row.original.endHourTimestamp
              ? new Date(row.original.endHourTimestamp)
                  .toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .toUpperCase()
              : undefined;
            return startHour && endHour ? (
              <Typography variant="caption">{`${convertMinutesToClockHours(
                totalLength,
                polyglot
              )} (${startHour}-${endHour})`}</Typography>
            ) : (
              <Typography variant="caption">{convertMinutesToClockHours(totalLength, polyglot)}</Typography>
            );
          }

          return <Typography variant="caption">{convertMinutesToClockHours(totalLength, polyglot)}</Typography>;
        },
      },
      {
        header: () => polyglot.t('AbsenceTable.workdayCount'),
        id: 'workdayCount',
        enableSorting: true,
        sortingFn: (a, b) => sortNumeric(a, b, (item) => item.workdayCount ?? 0),
        accessorFn: (row) => row,
        cell: ({ row }) => {
          const isHourly = isHourlyPolicy(row.original.policy as Pick<AbsencePolicyDto, 'allowanceType'>);
          if (isHourly) return <EmptyCell />;

          return (
            <Typography variant="caption">
              {row.original.workdayCount} day{row.original.workdayCount === 1 ? '' : 's'}
            </Typography>
          );
        },
      },
      ...(hidePolicyColumn
        ? []
        : [
            {
              header: () => polyglot.t('AbsenceTable.policyId'),
              accessorFn: (row: AbsenceDto) => row,
              id: 'policyId',
              enableSorting: true,
              sortingFn: (a: Row<AbsenceDto>, b: Row<AbsenceDto>) =>
                sortString(a, b, (item) => item.policy?.name ?? ''),
              cell: ({ row }: { row: Row<AbsenceDto> }) => <Box>{row.original.policy?.name ?? <EmptyCell />}</Box>,
            },
          ]),
      {
        header: () => polyglot.t('AbsenceTable.status'),
        accessorFn: (row: AbsenceDto) => row,
        id: 'status',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item: AbsenceDto) => item.status ?? ''),
        cell: ({ row }) => <Box>{getStatusCell({ status: row.original.status, absence: row.original }, polyglot)}</Box>,
      },
      ...(handlers.onApprove ||
      handlers.onDelete ||
      handlers.onEdit ||
      handlers.onReject ||
      handlers.onRequestCancellation
        ? [
            {
              header: () => '',
              id: 'actions',
              enableSorting: false,
              maxSize: 100,
              cell: ({ row: { original } }: { row: Row<AbsenceDto> }) => {
                return !original.isPublicHoliday && original.absenceId ? (
                  <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', cursor: 'default' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Suspense
                      fallback={
                        <SkeletonLoader
                          variant="rectangular"
                          width="80%"
                          height="40%"
                          sx={{ borderRadius: 2, backgroundColor: themeColors.Background }}
                        />
                      }
                    >
                      <AbsenceTableActions absence={original} {...handlers} />
                    </Suspense>
                  </Box>
                ) : (
                  <></>
                );
              },
            },
          ]
        : []),
    ],
    [
      hidePolicyColumn,
      showUserColumn,
      rows,
      handlers,
      polyglot,
      selectionModel,
      setSelectionModel,
      pendingRequests,
      state.user,
    ]
  );

  const handleRowClick = useCallback(
    (row: Row<AbsenceDto>) => {
      onRowClick && onRowClick(row.original);
    },
    [onRowClick]
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
          <FiltersDrawer
            name={polyglot.t('AbsenceTable.filters')}
            filtersOptions={[{ filters: timeFilters }]}
            selectedFilters={filterString}
            setSelectedFilters={setFilterString}
          />
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              setDebouncedSearchInput(e.target.value);
            }}
          />
        </Box>
        {newRequestButton && <Box>{newRequestButton}</Box>}
      </Box>
      <Box sx={{ ...spacing.mt20 }}>
        <BasicServerTable
          rowData={[...rows?.map((row) => ({ ...row, user: getCachedUserById(row.userId) }))]}
          columnData={columns}
          loading={loading}
          rowClick={handleRowClick}
          pagination={pagination}
          setPagination={setPagination}
          sorting={sorting}
          setSorting={setSorting}
          totalPages={totalPages}
          totalItems={totalItems}
          hiddenColumns={hiddenTableColumns}
          stickyHeader={stickyHeader}
        />
      </Box>
    </Box>
  );
}

export const getInitialFilterString = (filters: string, availablePolicies: readonly AbsencePolicyDto[]): string => {
  const [upToTypeFilters, fromTypeFiltersOnwards] = filters.split('Policy=');
  if (!fromTypeFiltersOnwards) return filters;

  const [typeFilters, afterTypeFilters] = fromTypeFiltersOnwards.split('&');

  const updatedListOfFilters = typeFilters
    .split(',')
    .filter((stringId) =>
      availablePolicies.some((policy) => String(policy.id) === stringId || stringId === 'publicHoliday')
    );

  if (updatedListOfFilters.length === 0)
    return upToTypeFilters && afterTypeFilters
      ? `${upToTypeFilters}&${afterTypeFilters ?? ''}`
      : upToTypeFilters
      ? upToTypeFilters ?? ''
      : afterTypeFilters ?? '';

  const updatedStringListOfFilters = updatedListOfFilters.join(',');
  if (typeFilters === updatedStringListOfFilters) return filters;

  return filters.replace(`Policy=${typeFilters}`, `Policy=${updatedStringListOfFilters}`);
};

const getStatusCell = ({ status, absence }: { status: AbsenceStatus; absence: AbsenceDto }, polyglot: Polyglot) => {
  if (absence.isPublicHoliday) {
    if (status === AbsenceStatus.Pending)
      return (
        <Box sx={{ gap: spacing.sm, display: 'flex', alignItems: 'center' }}>
          <WaitingEmpty {...iconSize} />
          <Typography variant="caption" color="Grey">
            {translateAbsenceStatuses('deletionrequested', polyglot)}
          </Typography>
        </Box>
      );

    // status of absence, not of deletion request!
    if (status === AbsenceStatus.Rejected)
      return (
        <Box sx={{ gap: spacing.sm, display: 'flex', alignItems: 'center' }}>
          <Rejected {...iconSize} fill={themeColors.Red} />
          <Typography variant="caption">{translateAbsenceStatuses('deleted', polyglot)}</Typography>
        </Box>
      );

    // AUTO APPROVED
    return (
      <Box sx={{ gap: spacing.sm, display: 'flex', alignItems: 'center' }}>
        <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
        <Typography variant="caption">{translateAbsenceStatuses('autoapproved', polyglot)}</Typography>
      </Box>
    );
  }

  switch (status) {
    case AbsenceStatus.Pending:
      return (
        <Box sx={{ gap: spacing.sm, display: 'flex', alignItems: 'center' }}>
          <WaitingEmpty {...iconSize} />
          <Typography variant="caption" color="Grey">
            {translateAbsenceStatuses(absence.status, polyglot)}
          </Typography>
        </Box>
      );

    case AbsenceStatus.Approved:
      return absence.cancellationRequested ? (
        <Box sx={{ gap: spacing.sm, display: 'flex', alignItems: 'center' }}>
          <WaitingEmptyRed {...iconSize} />
          <Typography variant="caption" color="Red">
            {polyglot.t('AbsenceViewDrawerContent.pendingCancellation')}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ gap: spacing.sm, display: 'flex', alignItems: 'center' }}>
          <OkGreen {...iconSize} style={{ fill: themeColors.Green }} />
          <Typography variant="caption">{translateAbsenceStatuses(absence.status, polyglot)}</Typography>
        </Box>
      );

    case AbsenceStatus.Rejected:
      return (
        <Box sx={{ gap: spacing.sm, display: 'flex', alignItems: 'center' }}>
          <Rejected {...iconSize} fill={themeColors.Red} />
          <Typography variant="caption">{translateAbsenceStatuses(absence.status, polyglot)}</Typography>
        </Box>
      );

    default:
      return <></>;
  }
};
