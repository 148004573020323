import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { ColumnDef, PaginationState, Row } from '@tanstack/react-table';
import { FiltersDrawer } from '@v2/components/table/filters-drawer.component';
import { TableSearch } from '@v2/components/table/table-search.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { AbsenceAPI } from '@v2/feature/absence/absence.api';
import { AbsencePolicyDto, UserBalanceDetailedStatsDto } from '@v2/feature/absence/absence.dto';
import { AbsencePolicyAllowanceType } from '@v2/feature/absence/absence.interface';
import { convertMinutesToClockHours } from '@v2/feature/absence/absence.util';
import {
  getCarryOverFromBreakdown,
  getExpiredCarryOver,
  getOneOffAdjustmentFromBreakdown,
} from '@v2/feature/absence/me/policies/policy-breakdown/absence-breakdown.util';
import { useCachedUsers } from '@v2/feature/user/context/cached-users.context';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeFonts } from '@v2/styles/fonts.styles';
import { iconSize } from '@v2/styles/menu.styles';
import { spacing } from '@v2/styles/spacing.styles';
import Polyglot from 'node-polyglot';
import { generatePath, useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Export } from '@/images/side-bar-icons/Export.svg';
import { nestErrorMessage } from '@/lib/errors';
import { USER_ABSENCE_ROUTE } from '@/lib/routes';
import { getDateString } from '@/v2/components/forms/date-label.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { BasicServerTable, DEFAULT_PAGE_SIZE } from '@/v2/components/table/server-side-table.component';
import { UserCell } from '@/v2/components/table/user-cell.component';

const getYearOptions = (polyglot: Polyglot): { label: string; value: 'last' | 'current' | 'next'; type: 'radio' }[] => {
  return [
    { label: polyglot.t('PageFilters.last'), value: 'last', type: 'radio' },
    { label: polyglot.t('PageFilters.current'), value: 'current', type: 'radio' },
    { label: polyglot.t('PageFilters.next'), value: 'next', type: 'radio' },
  ];
};

interface TableRowData extends UserBalanceDetailedStatsDto {
  readonly displayName: string;
  readonly userStatus: string | undefined;
  /** was previously typed as a Date */
  readonly userStartDate: string | undefined;
}

interface AbsenceBalancesTableProps {
  readonly absencePolicies: readonly AbsencePolicyDto[];
  readonly view: 'company' | 'team';
  readonly stickyHeader?: boolean;
}

export const AbsenceBalancesTable = ({
  absencePolicies,
  view,
  stickyHeader,
}: AbsenceBalancesTableProps): React.JSX.Element => {
  const { polyglot } = usePolyglot();

  const initialFilterValue = useMemo(() => `Absence policy=${absencePolicies[0]?.id}&Calendar=current`, [
    absencePolicies,
  ]);

  const [filterString, setFilterString] = useState<string>(initialFilterValue);

  const [usersBalances, setUsersBalances] = useState<UserBalanceDetailedStatsDto[]>([]);
  const [showMessage] = useMessage();
  const [loading, setLoading] = useState<boolean>(true);

  const [filterValue, setFilterValue] = useState<number | null>(absencePolicies[0]?.id ?? null);
  const [yearValue, setYearValue] = useState<'last' | 'current' | 'next'>('current');

  useEffect(() => {
    setFilterString(initialFilterValue);
  }, [initialFilterValue]);

  useEffect(() => {
    const filters = filterString.split('&');
    const absenceIdString = filters.find((f) => f.startsWith('Absence policy'))?.split('=')[1];
    const absenceId = absenceIdString ? Number(absenceIdString) : null;
    setFilterValue((prev) => (absenceId && prev === absenceId ? prev : absenceId));

    const year = filters.find((f) => f.startsWith('Calendar'))?.split('=')[1] as 'last' | 'current' | 'next';
    setYearValue((prev) => (year && prev === year ? prev : year));
  }, [filterString]);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const routerHistory = useHistory();
  const { getCachedUserById } = useCachedUsers();
  const [searchInput, setSearchInput] = useState<string>('');

  const debouncedSearch = useDebouncedCallback((value) => {
    try {
      setSearchInput(!value ? '' : value);
      // Resets pagination index once we perform a new search
      setPagination({ pageIndex: 1, pageSize: DEFAULT_PAGE_SIZE });
    } catch (error) {
      showMessage(polyglot.t('AbsenceBalancesTable.errorMessages.handleSearch'), 'error');
    }
  }, 300);

  const selectedPolicy = useMemo(() => {
    let defaultPolicy = absencePolicies[0];
    if (!filterValue) return defaultPolicy;

    const policyId = Number(filterValue);
    const newPolicy = absencePolicies.find((p) => p.id === policyId);

    return newPolicy ? newPolicy : defaultPolicy;
  }, [filterValue, absencePolicies]);

  useEffect(() => {
    (async function () {
      if (!selectedPolicy) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        const usersBalancesResponse =
          view === 'team'
            ? await AbsenceAPI.fetchTeamUsersAbsenceBalanceBreakdown(
                selectedPolicy.id,
                yearValue,
                pagination.pageIndex,
                pagination.pageSize,
                searchInput ? searchInput : undefined
              )
            : await AbsenceAPI.fetchCompanyUsersAbsenceBalanceBreakdown(
                selectedPolicy.id,
                yearValue,
                pagination.pageIndex,
                pagination.pageSize,
                searchInput ? searchInput : undefined
              );
        setUsersBalances(usersBalancesResponse.items);
        setTotalPages(usersBalancesResponse.totalPages);
        setTotalItems(usersBalancesResponse.totalItems);
      } catch (error) {
        showMessage(
          polyglot.t('AbsenceBalancesTable.errorMessages.fetch', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [polyglot, showMessage, view, selectedPolicy, pagination.pageIndex, pagination.pageSize, searchInput, yearValue]);

  const rowData = useMemo(() => {
    return usersBalances.map((record) => {
      const user = getCachedUserById(record.userId);
      return {
        ...record,
        displayName: user?.displayName ?? 'Unknown',
        userStatus: user?.userEvent?.status,
        userStartDate: user?.startDate,
        site: user?.role?.site?.name ?? null,
      };
    });
  }, [usersBalances, getCachedUserById]);

  const tableColumns = useMemo<ColumnDef<TableRowData, TableRowData>[]>(() => {
    if (!selectedPolicy) return [];

    const balanceColumn: ColumnDef<TableRowData, TableRowData> = {
      header: () => polyglot.t('AbsenceBalancesTable.balanceHours'),
      accessorFn: (row) => row,
      id: 'balance',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!selectedPolicy || !original[selectedPolicy.id]) return <EmptyCell />;

        const value = original[selectedPolicy.id]?.currentBalance;
        const balance = value || value === 0 ? convertMinutesToClockHours(value, polyglot) : null;
        return balance ? <Typography sx={themeFonts.caption}>{balance}</Typography> : <EmptyCell />;
      },
    };

    const balanceDaysColumn: ColumnDef<TableRowData, TableRowData> = {
      header: () => polyglot.t('AbsenceBalancesTable.balanceDays'),
      accessorFn: (row) => row,
      id: 'balanceInDays',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!selectedPolicy || !original[selectedPolicy.id]) return <EmptyCell />;

        const value = original[selectedPolicy.id]?.currentBalanceInDays;
        const balance = value || value === 0 ? `${value} day${value === 1 ? '' : 's'}` : null;
        return balance ? <Typography sx={themeFonts.caption}>{balance}</Typography> : <EmptyCell />;
      },
    };

    const oneOffAdjustmentColumn: ColumnDef<TableRowData, TableRowData> = {
      header: () => polyglot.t('AbsenceBalancesTable.oneOffAdjustment'),
      accessorFn: (row) => row,
      id: 'oneOffAdjustment',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!selectedPolicy) return <EmptyCell />;
        const breakdown = original[selectedPolicy.id];
        if (!breakdown) return <EmptyCell />;

        const value = original[selectedPolicy.id]?.oneOffAdjustment;
        if (value === null) return <EmptyCell />;

        return (
          <Typography sx={themeFonts.caption}>
            {getOneOffAdjustmentFromBreakdown(selectedPolicy, breakdown, polyglot)}
          </Typography>
        );
      },
    };

    const carryOverColumn: ColumnDef<TableRowData, TableRowData> = {
      header: () => polyglot.t('AbsenceBalancesTable.carryOver'),
      accessorFn: (row) => row,
      id: 'ca',
      enableSorting: false,
      cell: ({ row: { original } }) => {
        if (!selectedPolicy) return <EmptyCell />;
        const breakdown = original[selectedPolicy.id];
        if (!breakdown) return <EmptyCell />;

        const value = original[selectedPolicy.id]?.carryOver?.allowedUnitsThisCycle;
        if (value === null || value === undefined) return <EmptyCell />;

        const expiredCarryOver =
          breakdown.carryOver.isCarryOverExpired &&
          breakdown.carryOver.expiredCarryOver &&
          breakdown.carryOver.expiredCarryOver > 0
            ? `(Expired: ${getExpiredCarryOver(selectedPolicy, breakdown, polyglot)})`
            : '';
        return (
          <Typography sx={themeFonts.caption}>
            {getCarryOverFromBreakdown(selectedPolicy, breakdown, polyglot)} {expiredCarryOver}
          </Typography>
        );
      },
    };

    return [
      {
        header: () => polyglot.t('AbsenceBalancesTable.name'),
        accessorFn: (row) => row,
        id: 'displayName',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const user = getCachedUserById(original.userId);
          return user ? (
            <div>
              <UserCell userId={original.userId} />
            </div>
          ) : (
            <EmptyCell />
          );
        },
      },
      ...(selectedPolicy.allowanceType === AbsencePolicyAllowanceType.Unlimited
        ? []
        : [balanceColumn, balanceDaysColumn, oneOffAdjustmentColumn, carryOverColumn]),
      {
        header: () => polyglot.t('AbsenceBalancesTable.hoursTaken'),
        accessorFn: (row) => row,
        id: `units-taken-${selectedPolicy.id}`,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          if (!selectedPolicy || !original[selectedPolicy.id]) return <EmptyCell />;

          const value = original[selectedPolicy.id]?.unitsTaken.history;
          const unitsTaken = value || value === 0 ? convertMinutesToClockHours(value, polyglot) : null;
          return unitsTaken ? <Typography sx={themeFonts.caption}>{unitsTaken}</Typography> : <EmptyCell />;
        },
      },
      {
        header: () => polyglot.t('AbsenceBalancesTable.hoursBooked'),
        accessorFn: (row) => row,
        id: `units-booked-${selectedPolicy?.id ?? ''}`,
        enableSorting: false,
        cell: ({ row: { original } }) => {
          if (!selectedPolicy || !original[selectedPolicy.id]) return <EmptyCell />;

          const value = original[selectedPolicy.id]?.unitsTaken.upcoming;
          const unitsBooked = value || value === 0 ? convertMinutesToClockHours(value, polyglot) : null;
          return unitsBooked ? <Typography sx={themeFonts.caption}>{unitsBooked}</Typography> : <EmptyCell />;
        },
      },
      {
        header: () => polyglot.t('AbsenceBalancesTable.userStartDate'),
        accessorFn: (row) => row,
        id: 'userStartDate',
        enableSorting: false,
        cell: ({ row: { original } }) => (
          <Box>
            <Typography sx={themeFonts.caption}>
              {original.userStartDate
                ? getDateString(original.userStartDate)
                : polyglot.t('AbsenceBalancesTable.missingStartDate')}
            </Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('AbsenceBalancesTable.reportsTo'),
        accessorFn: (row) => row,
        id: 'reportsTo',
        enableSorting: false,
        cell: ({ row: { original } }) => {
          const userM = getCachedUserById(original.userId);
          const managerM = userM?.role?.managerId ? getCachedUserById(userM.role.managerId) : undefined;
          return managerM ? (
            <div>
              <UserCell userId={managerM.userId} />
            </div>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: () => polyglot.t('AbsenceBalancesTable.site'),
        accessorFn: (row: TableRowData) => row,
        id: 'site',
        enableSorting: false,
        cell: ({ row: { original } }: { row: Row<TableRowData> }) =>
          original.site ? (
            <Box>
              <Typography sx={themeFonts.caption}>{polyglot.t(original.site)}</Typography>
            </Box>
          ) : (
            <EmptyCell />
          ),
      },
    ] as ColumnDef<TableRowData, TableRowData>[];
  }, [polyglot, getCachedUserById, selectedPolicy]);

  const handleRowClick = useCallback(
    (row: Row<TableRowData>) => {
      routerHistory.push(generatePath(USER_ABSENCE_ROUTE, { userId: row.original?.userId ?? '' }));
    },
    [routerHistory]
  );

  const exportBalancesDataCSV = useCallback(async () => {
    if (!selectedPolicy || view !== 'company') return;
    try {
      window.location.href = AbsenceAPI.exportCompanyUsersBalancesAsCSV(selectedPolicy.id, yearValue);
    } catch (error) {
      showMessage(polyglot.t('AbsenceBalancesTable.errorMessages.badRequest'), 'error');
    }
  }, [yearValue, polyglot, view, selectedPolicy, showMessage]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center' }}>
          <FiltersDrawer
            name={polyglot.t('AbsenceTable.filters')}
            filtersOptions={[
              {
                filters: {
                  'Absence policy': absencePolicies.map((p) => ({
                    label: p.fullName ?? p.name,
                    value: p.id,
                    type: 'radio',
                  })),
                  Calendar: getYearOptions(polyglot),
                },
              },
            ]}
            selectedFilters={filterString}
            setSelectedFilters={setFilterString}
            hideClearAll
          />
          <TableSearch
            query={searchInput}
            handleChange={(e) => {
              debouncedSearch.callback(e.target.value);
            }}
          />
        </Box>

        {view === 'company' && (
          <Box>
            <LoaderButton loading={false} onClick={exportBalancesDataCSV} sizeVariant="small" colorVariant="secondary">
              <Box sx={{ display: 'flex', gap: spacing.g5, alignItems: 'center' }}>
                <Export {...iconSize} />
                <Typography sx={themeFonts.caption}>{polyglot.t('General.export')}</Typography>
              </Box>
            </LoaderButton>
          </Box>
        )}
      </Box>
      <Box sx={{ ...spacing.mt20 }}>
        <BasicServerTable<TableRowData>
          rowData={[...rowData]}
          columnData={tableColumns}
          loading={loading}
          rowClick={handleRowClick}
          pagination={pagination}
          setPagination={setPagination}
          totalPages={totalPages}
          totalItems={totalItems}
          stickyHeader={stickyHeader}
        />
      </Box>
    </Box>
  );
};
