import { useCallback, useContext } from 'react';

import { ReportsAdvancedCreatePage } from '@v2/feature/reports/reports-advanced/reports-advanced-create.page';
import { ReportsAdvancedOverviewPage } from '@v2/feature/reports/reports-advanced/reports-advanced-overview.page';
import { ReportsAdvancedViewPage } from '@v2/feature/reports/reports-advanced/reports-advanced-view.page';
import { ReportsEndpoints } from '@v2/feature/reports/reports.api';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { Redirect, Switch } from 'react-router-dom';

import { RouteScopesControl } from '@/component/widgets/Scopes';
import { GlobalContext } from '@/GlobalState';
import useScopes from '@/hooks/scopes.hook';
import {
  REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE,
  REPORTS_TEAM_CREATE_ROUTE,
  REPORTS_TEAM_VIEW_REPORT_ROUTE,
} from '@/lib/routes';

export const ReportsV2TeamRouter = () => {
  const [state] = useContext(GlobalContext);
  const { getScopesContext } = useScopes();
  const scopesContext = getScopesContext({ userId: state.user.userId });

  const { data: reports, mutate: refreshReports, isLoading } = useApiClient(ReportsEndpoints.getAllNewReports('team'), {
    suspense: false,
  });

  const refreshReportsList = useCallback(async () => {
    if (refreshReports) await refreshReports();
  }, [refreshReports]);

  return (
    <Switch>
      <RouteScopesControl scopes={['reports:manager']} path={REPORTS_TEAM_CREATE_ROUTE} context={scopesContext}>
        <ReportsAdvancedCreatePage refreshReportsList={refreshReportsList} target="team" />
      </RouteScopesControl>

      <RouteScopesControl scopes={['reports:manager']} path={REPORTS_TEAM_VIEW_REPORT_ROUTE} context={scopesContext}>
        <ReportsAdvancedViewPage
          reports={reports ?? []}
          refreshReportsList={refreshReportsList}
          isLoading={!!isLoading}
          target="team"
        />
      </RouteScopesControl>

      <RouteScopesControl
        scopes={['reports:manager']}
        path={REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE}
        context={scopesContext}
      >
        <ReportsAdvancedOverviewPage
          reports={reports ?? []}
          isLoading={!!isLoading}
          refreshReportsList={refreshReportsList}
          target="team"
        />
      </RouteScopesControl>

      <Redirect to={REPORT_TEAM_REPORTS_V2_OVERVIEW_ROUTE} />
    </Switch>
  );
};
