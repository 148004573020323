import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReportConfigSection } from '@v2/feature/reports/reports-advanced/components/report-config.section';
import { ReportViewResult } from '@v2/feature/reports/reports-advanced/components/report-view-result.component';
import { ReportsAPI, ReportsEndpoints } from '@v2/feature/reports/reports.api';
import { ReportResponse, SelectedColumnsRequest, SelectedFiltersRequest } from '@v2/feature/reports/reports.interface';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { deepCopy } from '@v2/infrastructure/object/object.util';
import { RootStyle } from '@v2/styles/root.styles';
import { LocalDate } from '@v2/util/local-date';
import { Base64 } from 'js-base64';
import { useLocation } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';

interface PageProps {
  readonly refreshReportsList: () => Promise<void>;
  readonly target: 'all' | 'team';
}

export const ReportsAdvancedCreatePage = ({ refreshReportsList, target }: PageProps) => {
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const { data: filtersAndColumns, isLoading } = useApiClient(ReportsEndpoints.getTestReportsFiltersOptions(), {
    suspense: false,
  });

  const location = useLocation();

  const [selectedColumns, setSelectedColumns] = useState<SelectedColumnsRequest>({});
  const [selectedFilters, setSelectedFilters] = useState<SelectedFiltersRequest>({});
  const [effectiveDate, setEffectiveDate] = useState<string>(new LocalDate().toDateString());

  const [reportResponse, setReportResponse] = useState<ReportResponse | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const querySettings: { queryColumns: string | null; queryFilters: string | null } | null = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryColumns = queryParams.get('columns');
    const queryFilters = queryParams.get('filters');

    if (!queryColumns && !queryFilters) return null;

    return { queryColumns, queryFilters };
  }, [location]);

  useEffect(() => {
    if (!filtersAndColumns) return;

    let initialSelectedColumns: SelectedColumnsRequest = {};
    let initialSelectedFilters: SelectedFiltersRequest = {};

    try {
      if (querySettings) {
        initialSelectedColumns = querySettings.queryColumns
          ? // ? JSON.parse(decodeURIComponent(querySettings.queryColumns))
            JSON.parse(Base64.decode(querySettings.queryColumns))
          : {};
        initialSelectedFilters = querySettings.queryFilters
          ? // ? JSON.parse(decodeURIComponent(querySettings.queryFilters))
            JSON.parse(Base64.decode(querySettings.queryFilters))
          : {};
      } else {
        initialSelectedColumns = deepCopy(filtersAndColumns.defaultSelectedColumns ?? {}) as SelectedColumnsRequest;
        initialSelectedFilters = deepCopy(filtersAndColumns.defaultSelectedFilters ?? {}) as SelectedFiltersRequest;
      }
    } catch (error) {
      console.error(error);
      showMessage(`Something went wrong. Could not load report setup. ${error.message}`, 'error');
    }
    setSelectedColumns(initialSelectedColumns);
    setSelectedFilters(initialSelectedFilters);
  }, [filtersAndColumns, querySettings, showMessage]);

  const generateReport = useCallback(async () => {
    if (Object.keys(selectedColumns).length === 0 && Object.keys(selectedFilters).length === 0) {
      setReportResponse(null);
      return;
    }
    try {
      setIsGenerating(true);
      const response = await ReportsAPI.generateReport(selectedColumns, selectedFilters, effectiveDate, target);
      setReportResponse(response);
    } catch (error) {
      setReportResponse(null);
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setIsGenerating(false);
  }, [selectedColumns, selectedFilters, effectiveDate, polyglot, showMessage, target]);

  useEffect(() => {
    // generate report only if there are columns selected
    if (Object.keys(selectedColumns).length > 0) generateReport();
    else setReportResponse(null);
  }, [selectedColumns, generateReport]);

  return (
    <RootStyle style={{ display: 'flex', flexDirection: 'row' }}>
      <ReportConfigSection
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        reportColumns={filtersAndColumns?.columns ?? []}
        reportFilters={filtersAndColumns?.filters ?? []}
        target={target}
        effectiveDate={effectiveDate}
        setEffectiveDate={setEffectiveDate}
      />
      <ReportViewResult
        report={null}
        reportResponse={reportResponse}
        isLoading={!!isLoading || isGenerating}
        selectedColumns={selectedColumns}
        selectedFilters={selectedFilters}
        refreshReportsList={refreshReportsList}
        refreshReport={generateReport}
        target={target}
      />
    </RootStyle>
  );
};
