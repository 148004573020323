import { CountryName } from '@/v2/infrastructure/country/country.interface';

export const UserImportResultTabsLabels = [
  'Basic',
  'Details',
  'Family',
  'About',
  'Role',
  'Contract',
  'Lifecycle',
  'Salary',
  'Bank account',
  'Equity',
  'Address',
  // 'Right to work', - being hidden as Zinc integration is underway
  'Emergency',
] as const;

export enum InUseByTypes {
  Email = 'email',
  Site = 'site',
  Inventory = 'inventory',
}

export type UserImportResultDomain = typeof UserImportResultTabsLabels[number];

export interface MappedUserFromAzureAD {
  id: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  workEmail?: string;
  emailAddress: string;
  personalEmail: string;
  passportNumber?: string;
  workMobile?: string;
  phone?: string;

  // dates from profile
  dateOfBirth: string;
  startDate: string;
  leaveDate?: string;
  visaExpirationDate: string;

  // User role fields
  jobTitle: string;
  department: string;
  site: string;

  // UserAddress fields
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  postCode?: string;
  country?: CountryName;
}
