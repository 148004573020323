import { useCallback, useState } from 'react';

import { IconButton, Stack, Typography } from '@mui/material';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { PlanNames, UpgradeToProModal } from '../../user/components/upgrade-to-pro-modal.component';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Close } from '@/images/app-icons/Close.svg';
import { ReactComponent as Back } from '@/images/side-bar-icons/BackBtn.svg';
import { nestErrorMessage } from '@/lib/errors';
import { inviteUser } from '@/lib/users';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { LoaderButton } from '@/v2/components/theme-components/loading-button.component';
import { UserSummaryDto } from '@/v2/feature/user/dtos/user-summary.dto';
import {
  doesErrorRequireCompanyToIncreaseFixedSeatsLimit,
  doesErrorRequireCompanyToUpgrade,
} from '@/v2/infrastructure/restrictions/restriction.util';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { iconButtonSx } from '@/v2/styles/icon-button.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const iconSize = { width: 14, height: 14 } as const;

interface OnboardingUserInviteProps {
  user: Pick<UserSummaryDto, 'userId' | 'firstName' | 'lastName'>;
  onBackClick: () => void;
  onFinish: () => void;
}

export const OnboardingUserInvite = ({ user, onBackClick, onFinish }: OnboardingUserInviteProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const [isInviting, setIsInviting] = useState<boolean>(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [seatsLimitError, setSeatsLimitError] = useState<boolean>(false);

  const inviteUserAndFinish = useCallback(async () => {
    try {
      setIsInviting(true);
      await inviteUser(user.userId, showMessage, 'onboard');
      onFinish();
    } catch (error) {
      if (doesErrorRequireCompanyToUpgrade(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(false);
      } else if (doesErrorRequireCompanyToIncreaseFixedSeatsLimit(error)) {
        setUpgradeModalOpen(true);
        setSeatsLimitError(true);
      } else {
        showMessage(
          polyglot.t('OnboardingUserInvite.errorMessages.invitation', { errorMessage: nestErrorMessage(error) }),
          'error'
        );
      }
    } finally {
      setIsInviting(false);
    }
  }, [polyglot, onFinish, showMessage, user.userId]);

  return (
    <Stack sx={{ flex: 1, pt: spacing.p30, position: 'absolute', zIndex: 10, inset: 0, background: themeColors.white }}>
      <Stack sx={{ flexFlow: 'row', width: '70%', mx: 'auto' }}>
        <IconButton
          sx={{ ...iconButtonSx, flex: 0, position: 'absolute', left: spacing.m50 }}
          onClick={() => onBackClick()}
        >
          <Back {...iconSize} />
        </IconButton>
        <IconButton
          sx={{ ...iconButtonSx, flex: 0, position: 'absolute', right: spacing.m50 }}
          onClick={() => onFinish()}
        >
          <Close {...iconSize} stroke={themeColors.DarkGrey} />
        </IconButton>
      </Stack>
      <Stack sx={{ overflow: 'hidden auto', pb: spacing.p30 }}>
        <Stack sx={{ width: '400px', maxWidth: '90vw', mx: 'auto', mt: spacing.mt40 }}>
          <Typography sx={{ ...themeFonts.title2, mb: spacing.mb40 }}>
            {polyglot.t('OnboardingUserInvite.inviteMessage', { firstName: user.firstName, lastName: user.lastName })}
          </Typography>
          <Stack sx={{ flexFlow: 'row', gap: spacing.g20 }}>
            <ButtonComponent
              disabled={isInviting}
              colorVariant="secondary"
              sizeVariant="large"
              fullWidth
              type="button"
              onClick={() => onFinish()}
            >
              {polyglot.t('OnboardingUserInvite.saveDraft')}
            </ButtonComponent>
            <LoaderButton
              name={polyglot.t('OnboardingUserInvite.invite')}
              loading={isInviting}
              colorVariant="primary"
              sizeVariant="large"
              fullWidth
              type="button"
              onClick={() => inviteUserAndFinish()}
            />
          </Stack>
        </Stack>
      </Stack>
      <UpgradeToProModal
        isOpen={upgradeModalOpen}
        setIsDrawerOpen={setUpgradeModalOpen}
        planName={PlanNames.PEOPLE_PRO}
        messageSuffix="proPeople"
        seatsLimitError={seatsLimitError}
      />
    </Stack>
  );
};
