import { Box, Stack } from '@mui/material';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';

import { SecurityAuditReportTable } from './security-audit-report-table.component';

import { CompanyEndpoints } from '@/api-client/company.api';
import { Typography } from '@/v2/components/typography/typography.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const SecurityAuditTrailReport = () => {
  const { data: auditTrailRows, isValidating: loadingAuditTrail } = useApiClient(
    CompanyEndpoints.getCompanyAuditTrail(),
    {
      suspense: false,
    }
  );
  return (
    <RootStyle>
      <TopHeader
        title={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="title2" sx={{ color: themeColors.DarkGrey }}>
              Audit Trail
            </Typography>
          </Box>
        }
      />
      <Stack sx={{ overflow: 'hidden auto', pb: spacing.p30 }}>
        <Stack sx={{ gap: spacing.g30, ml: spacing.ml10 }}>
          <Box>
            <SecurityAuditReportTable
              auditTrailRows={auditTrailRows}
              loadingAuditTrail={loadingAuditTrail}
              activityLog={false}
            />
          </Box>
        </Stack>
      </Stack>
    </RootStyle>
  );
};
