import { useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { Typography } from '@v2/components/typography/typography.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { getDefaultBenefitImageByCategory } from '@v2/feature/benefits/benefits.util';
import { NewBenefitDrawer } from '@v2/feature/benefits/subfeature/benefits-settings/components/new-benefit-drawer.component';
import { InsuranceQuoteDto } from '@v2/feature/benefits/subfeature/insurance/insurance.dto';
import { InsuranceType } from '@v2/feature/benefits/subfeature/insurance/insurance.interface';
import { getLogoByProviderName } from '@v2/feature/benefits/subfeature/insurance/insurance.util';
import { PensionSchemeDto } from '@v2/feature/benefits/subfeature/pension/pension.dto';
import {
  getPensionLogoByProviderName,
  PensionProvidersValueToLabel,
} from '@v2/feature/benefits/subfeature/pension/pension.util';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { translateCustomBenefitCategory } from '@v2/infrastructure/i18n/translate.util';
import { spacing } from '@v2/styles/spacing.styles';
import { sortBy } from 'lodash';
import { generatePath, useHistory } from 'react-router-dom';

import useScopes from '@/hooks/scopes.hook';
import { ReactComponent as InsuranceBig } from '@/images/benefits/Insurance.svg';
import {
  DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE,
  INSURANCE_COMPANY_OVERVIEW_ROUTE,
  PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE,
} from '@/lib/routes';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import {
  CustomBenefitCategory,
  CustomBenefitDto,
} from '@/v2/feature/benefits/subfeature/custom-benefit/custom-benefit.interface';
import { pluralText } from '@/v2/util/string.util';

interface BenefitsCompanyOverviewRouterProps {
  readonly insuranceQuote: InsuranceQuoteDto | null;
  readonly pensionSchemes: readonly PensionSchemeDto[];
  readonly customBenefits: CustomBenefitDto[] | null;
  readonly refreshAllBenefits: () => Promise<void>;
  readonly loading: boolean;
}

export const BenefitsCompanyOverviewSection = ({
  insuranceQuote,
  pensionSchemes,
  customBenefits,
  refreshAllBenefits,
  loading,
}: BenefitsCompanyOverviewRouterProps) => {
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();
  const { hasScopes } = useScopes();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hasInsuranceAllScope = hasScopes(['insurance:all']);

  return (
    <ContentWrapper loading={loading}>
      <SettingsSubsectionContent
        loadingAll={loading}
        sections={[
          {
            contentWidth: '100%',
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <Box sx={flexContainerStyle}>
                    {sortBy(pensionSchemes, ['displayName']).map((pensionScheme, index) => {
                      return (
                        <SettingsItemCard
                          key={`pension-${index}`}
                          title={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
                              <Box>{getPensionLogoByProviderName(pensionScheme.providerName, 40)}</Box>
                              <Typography variant="title4">{pensionScheme.displayName}</Typography>{' '}
                            </Box>
                          }
                          boxSx={responsiveCardStyle}
                          boxAction={() => {
                            routerHistory.push(
                              generatePath(PENSION_COMPANY_PROVIDER_SUMMARY_ROUTE, {
                                pensionSchemeId: pensionScheme.id,
                              })
                            );
                          }}
                          contentItemsSets={[
                            {
                              name:
                                PensionProvidersValueToLabel[pensionScheme.providerName] ?? pensionScheme.providerName,
                              type: ContentItemType.chip,
                              textColor: 'DarkGrey',
                              backgroundColor: 'white',
                              border: 'middle',
                            },
                            {
                              name: polyglot.t('PensionModule.noOfWorkerGroups', {
                                smart_count: pensionScheme.workerGroups.length,
                              }),
                              type: ContentItemType.chip,
                              textColor: 'DarkGrey',
                              backgroundColor: 'white',
                              border: 'middle',
                            },
                          ]}
                        />
                      );
                    })}

                    {hasInsuranceAllScope && insuranceQuote?.policy ? (
                      <SettingsItemCard
                        key="insurance"
                        title={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
                            <Box>{getLogoByProviderName(insuranceQuote.policy.providerName, 40)}</Box>
                            <Typography variant="title4">{insuranceQuote.policy.providerName}</Typography>{' '}
                          </Box>
                        }
                        boxSx={responsiveCardStyle}
                        boxAction={() => {
                          routerHistory.push(INSURANCE_COMPANY_OVERVIEW_ROUTE);
                        }}
                        contentItemsSets={[
                          {
                            name:
                              insuranceQuote.policy.type === InsuranceType.Health
                                ? translateCustomBenefitCategory(CustomBenefitCategory.Health, polyglot)
                                : insuranceQuote.policy.type,
                            type: ContentItemType.chip,
                            textColor: 'DarkGrey',
                            backgroundColor: 'white',
                            border: 'middle',
                          },
                          {
                            name: polyglot.t('BenefitModule.noOfMembers', {
                              smart_count: insuranceQuote.noOfPolicyMembers ?? 0,
                            }),
                            type: ContentItemType.chip,
                            textColor: 'DarkGrey',
                            backgroundColor: 'white',
                            border: 'middle',
                          },
                        ]}
                      />
                    ) : insuranceQuote && hasInsuranceAllScope ? (
                      <SettingsItemCard
                        key="insurance"
                        title={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
                            <InsuranceBig style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                            <Typography variant="title4">{polyglot.t('BenefitModule.quoteRequested')}</Typography>{' '}
                          </Box>
                        }
                        description={polyglot.t('BenefitModule.quotePending')}
                        boxSx={responsiveCardStyle}
                        boxAction={() => {
                          routerHistory.push(INSURANCE_COMPANY_OVERVIEW_ROUTE);
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    {sortBy(customBenefits, ['name'])?.map((benefit, index) => {
                      return (
                        <SettingsItemCard
                          key={`benefit-${index}`}
                          title={
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: spacing.s1 }}>
                              {benefit?.url ? (
                                <img src={benefit.url} width={40} height={40} alt="benefit-logo" />
                              ) : (
                                getDefaultBenefitImageByCategory(benefit.category, '50px', 'auto')
                              )}
                              <Typography variant="title4">{benefit.name}</Typography>{' '}
                            </Box>
                          }
                          boxSx={responsiveCardStyle}
                          boxAction={() => {
                            routerHistory.push(
                              generatePath(DYNAMIC_CUSTOM_BENEFIT_COMPANY_ROUTE, { benefitId: benefit.id })
                            );
                          }}
                          contentItemsSets={[
                            {
                              name: benefit.type,
                              type: ContentItemType.chip,
                              textColor: 'DarkGrey',
                              backgroundColor: 'white',
                              border: 'middle',
                            },
                            {
                              name: `${benefit.includedUserIds.length} member${pluralText(
                                benefit.includedUserIds.length
                              )}`,
                              type: ContentItemType.chip,
                              textColor: 'DarkGrey',
                              backgroundColor: 'white',
                              border: 'middle',
                            },
                          ]}
                        />
                      );
                    })}
                  </Box>
                ),
              },
              {
                type: SectionItemType.Component,
                value: pensionSchemes.length === 0 && !insuranceQuote && (customBenefits ?? []).length === 0 && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s2 }}>
                    <Typography variant="caption">No benefit has been set up yet.</Typography>
                    <ButtonComponent
                      sizeVariant="small"
                      colorVariant="primary"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      {polyglot.t('BenefitModule.newBenefit')}
                    </ButtonComponent>

                    <NewBenefitDrawer isOpen={isOpen} setIsOpen={setIsOpen} refresh={refreshAllBenefits} />
                  </Box>
                ),
              },
            ],
          },
        ]}
      />
    </ContentWrapper>
  );
};
