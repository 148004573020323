import React, { Suspense, useCallback, useState } from 'react';

import { Box } from '@mui/material';
import { ButtonComponent } from '@v2/components/forms/button.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { Typography } from '@v2/components/typography/typography.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { ReportsAPI } from '@v2/feature/reports/reports.api';
import { ReportDto } from '@v2/feature/reports/reports.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { themeColors } from '@v2/styles/colors.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { REPORT_COMPANY_REPORTS_V2_ROUTE, REPORT_TEAM_REPORTS_V2_ROUTE } from '@/lib/routes';

interface DrawerProps {
  readonly isOpen: boolean;
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly report: ReportDto | null;
  readonly refresh: () => Promise<void>;
  readonly onClose?: () => void;
  readonly target: 'all' | 'team';
}

export const ReportDeleteDrawer = ({ isOpen, setIsOpen, report, refresh, onClose, target }: DrawerProps) => {
  const { polyglot } = usePolyglot();
  const [showMessage] = useMessage();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const deleteReport = useCallback(async () => {
    if (!report) return;
    try {
      setIsLoading(true);
      await ReportsAPI.deleteReport(report.id);

      await refresh();
      if (target === 'all') history.push(REPORT_COMPANY_REPORTS_V2_ROUTE);
      else history.push(REPORT_TEAM_REPORTS_V2_ROUTE);
    } catch (error) {
      showMessage(polyglot.t('ErrorMessages.somethingWentWrong', { errorMessage: nestErrorMessage(error) }), 'error');
    }
    setIsLoading(false);
  }, [showMessage, polyglot, history, report, refresh, target]);

  return (
    <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <Suspense
        fallback={
          <SkeletonLoader
            variant="rectangular"
            width="90%"
            height="90vh"
            sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
          />
        }
      >
        <Box sx={drawerContentSx}>
          <Typography variant="title2">Delete report</Typography>

          <Typography variant="caption">Are you sure you want to delete the {report?.name} report?</Typography>

          <Box sx={buttonBoxDrawerSx}>
            <ButtonComponent sizeVariant="medium" colorVariant="secondary" onClick={() => setIsOpen(false)} fullWidth>
              {polyglot.t('General.cancel')}
            </ButtonComponent>

            <ButtonComponent
              sizeVariant="medium"
              colorVariant="danger"
              fullWidth
              loading={isLoading}
              onClick={deleteReport}
            >
              {polyglot.t('General.delete')}
            </ButtonComponent>
          </Box>
        </Box>
      </Suspense>
    </DrawerModal>
  );
};
