import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as Trash } from '@/images/fields/Trash.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE } from '@/lib/routes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import {
  ContentItemType,
  flexContainerStyle,
  responsiveCardStyle,
  SettingsItemCard,
} from '@/v2/components/settings/settings-item-card.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { DrawerModal } from '@/v2/components/theme-components/drawer-modal.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { PermissionGroupEditDrawerPage } from '@/v2/feature/permission-group/components/permission-group-edit-drawer.component';
import { PermissionGroupAPI } from '@/v2/feature/permission-group/permission-group.api';
import { PermissionGroupDto } from '@/v2/feature/permission-group/permission-group.dto';
import { PERMISSION_GROUP_EDIT_DRAWER_MODES } from '@/v2/feature/permission-group/permission-group.interface';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { pluralText } from '@/v2/util/string.util';

export function PermissionGroupsSettingsPage(): React.JSX.Element {
  const [showMessage] = useMessage();
  const routerHistory = useHistory();
  const { polyglot } = usePolyglot();

  const [loading, setLoading] = useState<boolean>(false);
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroupDto[]>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [permissionGroupDrawerMode, setPermissionGroupDrawerMode] = useState<PERMISSION_GROUP_EDIT_DRAWER_MODES>(
    PERMISSION_GROUP_EDIT_DRAWER_MODES.scope
  );
  const [editDrawerOpen, setEditDrawerOpen] = useState<boolean>(false);

  const { trackPage } = useJune();

  const refreshPermissionGroups = useCallback(async () => {
    setLoading(true);
    try {
      const permissionGroupData = await PermissionGroupAPI.listPermissionGroupsWithMembers();
      const sortedGroups = permissionGroupData.sort((a, b) => Number(b.isDefault) - Number(a.isDefault));
      setPermissionGroups(sortedGroups);
    } catch (error) {
      showMessage(`Permission group info could not be loaded: ${nestErrorMessage(error)}`, 'error');
      showMessage(nestErrorMessage(error), 'error');
    } finally {
      setLoading(false);
    }
  }, [showMessage]);

  useEffect(() => {
    refreshPermissionGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackPage('Company permission groups settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editPermissionGroup = useCallback(
    (permissionGroup: PermissionGroupDto) => {
      routerHistory.push(
        generatePath(SETTINGS_PERMISSION_GROUP_GENERAL_ROUTE, { permissionGroupId: permissionGroup.id })
      );
    },
    [routerHistory]
  );

  const addNewPermissionGroup = () => {
    setPermissionGroupDrawerMode(PERMISSION_GROUP_EDIT_DRAWER_MODES.add);
    setEditDrawerOpen(true);
  };

  const tableData = useMemo(() => {
    return (searchInput.length > 0
      ? permissionGroups.filter((eachGroup) => eachGroup.name.toLowerCase().includes(searchInput.toLowerCase()))
      : permissionGroups
    ).map((grp) => {
      return {
        displayName: grp.name,
        description: grp.description,
        employees: grp.members.length,
        action: () => editPermissionGroup(grp),
        isDefault: grp.isDefault,
        id: grp.id,
      };
    });
  }, [permissionGroups, searchInput, editPermissionGroup]);

  const deletePermissionGroup = async (id: number) => {
    try {
      setLoading(true);
      if (id) await PermissionGroupAPI.deletePermissionGroup(id);
      else {
        throw new Error(polyglot.t('PermissionGroupListingTable.errorMessages.noGroup'));
      }
      refreshPermissionGroups();
      showMessage(polyglot.t('PermissionGroupListingTable.successMessages.delete'), 'success');
    } catch (error) {
      showMessage(
        `${polyglot.t('PermissionGroupListingTable.errorMessages.delete')}: ${nestErrorMessage(error)}`,
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentWrapper loading={loading}>
      <SettingsSectionContent
        title="Permissions"
        contentWidth="100%"
        headerWidth="100%"
        buttons={[
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => addNewPermissionGroup()}>
            {polyglot.t('permissionListingTable.addGroup')}
          </ButtonComponent>,
        ]}
      >
        <SettingsSubsectionContent
          sections={[
            {
              contentWidth: '100%',
              items: [
                {
                  type: SectionItemType.Component,
                  value: (
                    <PermissionsTable
                      refreshPermissionGroups={refreshPermissionGroups}
                      setLoading={setLoading}
                      setSearchInput={setSearchInput}
                      searchInput={searchInput}
                      setEditDrawerOpen={setEditDrawerOpen}
                      editDrawerOpen={editDrawerOpen}
                      permissionGroupDrawerMode={permissionGroupDrawerMode}
                    />
                  ),
                },
                {
                  type: SectionItemType.Component,
                  value: (
                    <Box sx={flexContainerStyle}>
                      {tableData.map((item) => (
                        <SettingsItemCard
                          key={item.displayName}
                          title={item.displayName}
                          description={item.description}
                          boxSx={responsiveCardStyle}
                          contentItemsSets={[
                            {
                              name: `${item?.employees} member${pluralText(item?.employees)}`,
                              type: ContentItemType.chip,
                              textColor: 'DarkGrey',
                              backgroundColor: 'white',
                              border: 'middle',
                            },
                          ]}
                          boxAction={item.action}
                          advanceActionHidden={item.isDefault}
                          advanceActionOptions={[
                            {
                              label: 'Delete',
                              icon: <Trash />,
                              handler: (e) => {
                                deletePermissionGroup(item.id);
                                e?.stopPropagation();
                              },
                            },
                          ]}
                        />
                      ))}
                    </Box>
                  ),
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>
    </ContentWrapper>
  );
}

const PermissionsTable = ({
  refreshPermissionGroups,
  setLoading,
  setSearchInput,
  searchInput,
  setEditDrawerOpen,
  editDrawerOpen,
  permissionGroupDrawerMode,
}: {
  readonly refreshPermissionGroups: () => Promise<void>;
  readonly setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  readonly searchInput: string;
  readonly setEditDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readonly editDrawerOpen: boolean;
  readonly permissionGroupDrawerMode: PERMISSION_GROUP_EDIT_DRAWER_MODES;
}) => {
  return (
    <Box>
      <TableSearch
        query={searchInput}
        handleChange={(e) => {
          setSearchInput(e.target.value);
        }}
      />

      <DrawerModal isOpen={editDrawerOpen} setIsOpen={setEditDrawerOpen}>
        <PermissionGroupEditDrawerPage
          refreshPermissionGroup={async () => {}}
          selectedPermission={undefined}
          selectedPermissionCategory={undefined}
          permissionGroupMembers={[]}
          closePage={async () => {
            setEditDrawerOpen(false);
            await refreshPermissionGroups();
          }}
          mode={permissionGroupDrawerMode}
          setLoading={setLoading}
        />
      </DrawerModal>
    </Box>
  );
};
