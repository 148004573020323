import React, { Suspense, useCallback } from 'react';

import { Box } from '@mui/material';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceEndpoints } from '@v2/feature/device/device.api';
import { SuperAdminDeviceDetailsOrders } from '@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-orders.component';
import { SuperAdminDeviceDetailsPossessions } from '@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-possessions.component';
import { SuperAdminDeviceDetailsTransits } from '@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details-transits.component';
import { SuperAdminDeviceDetails } from '@v2/feature/super-admin/features/super-admin-devices/components/device-details/super-admin-device-details.component';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { useParams } from 'react-router-dom';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { BackofficeRootStyle } from '@/v2/styles/root.styles';

export const SuperAdminDeviceDetailsPage = () => {
  const params = useParams<{ deviceId: string }>();
  const deviceId = Number(params.deviceId);
  const {
    data: device,
    isLoading: isLoadingDevice,
    mutate: refreshDeviceM,
  } = useApiClient(DeviceEndpoints.getDeviceByIdAsSuperAdmin(deviceId), { suspense: false });

  const refreshDevice = useCallback(async () => {
    if (refreshDeviceM) await refreshDeviceM();
  }, [refreshDeviceM]);

  const deviceIdentification = device ? `[${device.id} | ${device.serialNumber}]` : `[${deviceId}]`;
  return (
    <BackofficeRootStyle>
      <TopHeader title={`Device details ${deviceIdentification}`} />
      <ContentWrapper loading={isLoadingDevice}>
        <Suspense fallback={<SkeletonLoader variant="rectangular" width="100%" height="80%" />}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
              <SuperAdminDeviceDetails device={device ?? null} refreshDevice={refreshDevice} />
            </Box>
            <SuperAdminDeviceDetailsPossessions deviceId={deviceId} />
            <SuperAdminDeviceDetailsTransits deviceId={deviceId} />
            <SuperAdminDeviceDetailsOrders deviceId={deviceId} refreshDeviceDetails={refreshDevice} />
          </Box>
        </Suspense>
      </ContentWrapper>
    </BackofficeRootStyle>
  );
};
