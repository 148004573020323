import {
  AvailableScopesForPartnerAPI,
  PublishRequest,
  PublishRequestForSuperadmin,
} from '@/v2/feature/security/security-settings/features/developer-hub/developer-hub.interface';
import {
  NonSensitiveOauthClientConfiguration,
  SensitiveOauthClientConfiguration,
  TokenResult,
} from '@/v2/feature/security/security-settings/security.interface';
import { StatusGroupedRequests } from '@/v2/feature/super-admin/features/super-admin-partner-api-monitoring/partner-api.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';
import axios, { AxiosResponse } from 'axios';

export class OauthClientAPI {
  static async createNewClient(
    name: string,
    description: string,
    redirectionUri: string,
    scopes: string[]
  ): Promise<SensitiveOauthClientConfiguration> {
    return (await axios.post('/apiv2/oauth/client', { name, description, redirectionUri, scopes })).data;
  }

  static async deleteClient(): Promise<void> {
    await axios.delete('/apiv2/oauth/client');
  }

  static async deleteSpecificClient(clientId: string): Promise<void> {
    await axios.delete(`/apiv2/oauth/client/${clientId}`);
  }

  static getLogoUploadURL(clientId: string): string {
    return `/apiv2/oauth/client/${clientId}/upload-logo`;
  }

  static async deleteClientLogo(clientId: string, key: string): Promise<void> {
    await axios.post(`/apiv2/oauth/client/${clientId}/delete-logo`, { remoteFileName: key });
  }

  static async validateAuthorizationAttempt(
    clientId: string,
    state: string,
    redirectionUri: string
  ): Promise<AxiosResponse> {
    return await axios.get(
      `/apiv2/oauth/authorize?response_type=code&client_id=${clientId}&state=${state}&redirect_uri=${redirectionUri}`
    );
  }

  static async updateExistingClient(
    clientId: string,
    appId: string,
    name: string,
    description: string,
    redirectionUri: string,
    scopes: string[],
    logo?: string
  ): Promise<SensitiveOauthClientConfiguration> {
    return (
      await axios.patch(`/apiv2/oauth/client/${clientId}`, {
        id: appId,
        name,
        description,
        redirectionUri,
        scopes,
        logo,
      })
    ).data;
  }

  static async updateScopeAndScopeReasonsForExistingClient(
    clientId: string,
    appId: string,
    scopes: string[],
    scopeReasons?: Record<string, string>
  ): Promise<SensitiveOauthClientConfiguration> {
    return (
      await axios.patch(`/apiv2/oauth/client-scopes/${clientId}`, {
        id: appId,
        scopes,
        scopeReasons,
      })
    ).data;
  }

  static async submitOAuthClientPublishRequest(
    clientId: string,
    requestedScopes: string[],
    scopeReasons: { [key: string]: string }
  ): Promise<void> {
    await axios.post('/apiv2/oauth/publish-requests', { clientId, requestedScopes, scopeReasons });
  }

  static async approvePublishRequest(companyId: number, clientId: string, reviewNotes?: string): Promise<void> {
    await axios.post(`/apiv2/oauth/publish-requests/superadmin/${companyId}/${clientId}/approve`, { reviewNotes });
  }

  static async rejectPublishRequest(companyId: number, clientId: string, reviewNotes?: string): Promise<void> {
    await axios.post(`/apiv2/oauth/publish-requests/superadmin/${companyId}/${clientId}/reject`, { reviewNotes });
  }
}

export class OauthClientEndpoints {
  static getSpecificClientForCompanyForConsentStart(
    clientId: string,
    redirectionUri: string
  ): Endpoint<NonSensitiveOauthClientConfiguration> {
    return {
      url: `/apiv2/oauth/client/${clientId}?redirectionUri=${redirectionUri}`,
    };
  }

  static getAuthorizationCode(): Endpoint<NonSensitiveOauthClientConfiguration[]> {
    return {
      url: '/apiv2/oauth/clients',
    };
  }

  static getAllClientsForCompany(): Endpoint<NonSensitiveOauthClientConfiguration[]> {
    return {
      url: '/apiv2/oauth/clients',
    };
  }

  static getTokenPairForClientId(clientId: string): Endpoint<TokenResult> {
    return {
      url: `/apiv2/oauth/client/${clientId}/token-pair`,
    };
  }

  static getGroupedStats(): Endpoint<StatusGroupedRequests> {
    return {
      url: '/apiv2/oauth/superadmin/partner-api/grouped-stats',
    };
  }

  static getSpecificAppDetails(appId: string): Endpoint<NonSensitiveOauthClientConfiguration> {
    return {
      url: `/apiv2/oauth/app/${appId}`,
    };
  }

  static getAllTokenPairsForClientId(clientId: string): Endpoint<TokenResult[]> {
    return {
      url: `/apiv2/oauth/client/${clientId}/token-pairs-for-client`,
    };
  }

  static getPublishRequestsForCompany(): Endpoint<PublishRequest[]> {
    return {
      url: '/apiv2/oauth/publish-requests/',
    };
  }

  static getPublishRequestsForClientId(clientId: string): Endpoint<PublishRequest[]> {
    return {
      url: `/apiv2/oauth/publish-requests/${clientId}`,
    };
  }

  static getPublishRequestsForAllCompanies(): Endpoint<PublishRequestForSuperadmin[]> {
    return {
      url: '/apiv2/oauth/publish-requests/superadmin',
    };
  }

  static getAvailableScopes(): Endpoint<AvailableScopesForPartnerAPI[]> {
    return {
      url: '/apiv2/oauth/publish-requests/available-scopes/',
    };
  }
}
