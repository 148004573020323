import axios from 'axios';

import { JobPosition } from '@/v2/feature/job-position/job-position-settings/job-position.interface';
import { Endpoint } from '@/v2/infrastructure/api-client/api-client.interface';

export class JobPositionAPI {
  static async listJobPositions(): Promise<JobPosition[]> {
    return await (await axios.get(`/apiv2/job-positions`)).data;
  }
  static async getJobPositionById(id: number): Promise<JobPosition> {
    return await (await axios.get(`/apiv2/job-positions/${id}`)).data;
  }

  static async createJobPosition(jobPosition: JobPosition): Promise<void> {
    await axios.post('/apiv2/job-positions', jobPosition);
  }

  static async updateJobPosition(id: number, jobPosition: JobPosition): Promise<void> {
    await axios.put(`/apiv2/job-positions/${id}`, jobPosition);
  }

  static async deleteJobPosition(id: number): Promise<void> {
    await axios.delete(`/apiv2/job-positions/${id}`);
  }
}

export class JobPositionEndpoints {
  static listJobPositions(): Endpoint<JobPosition[]> {
    return {
      url: '/apiv2/job-positions',
    };
  }

  static countJobPositions(): Endpoint<{ count: number }> {
    return {
      url: '/apiv2/job-positions/count',
    };
  }
}
