import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import Polyglot from 'node-polyglot';
import { generatePath, useHistory } from 'react-router-dom';

import { ReactComponent as OkGreen } from '@/images/side-bar-icons/ok-green.svg';
import { SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE } from '@/lib/routes';
import { ChipComponent } from '@/v2/components/chip/chip.component';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { Typography } from '@/v2/components/typography/typography.component';
import { PaymentSettingsPageSection } from '@/v2/feature/payroll/features/payroll-uk/payroll-company-settings/payment-settings/components/payment-settings-page-section.component';
import { DeveloperHubClientGenerateDrawer } from '@/v2/feature/security/security-settings/components/developer-hub-client-generate-drawer.component';
import { NonSensitiveOauthClientConfiguration } from '@/v2/feature/security/security-settings/security.interface';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const publishedPoly = 'DeveloperHub.clientTable.published';
const notPublishedPoly = 'DeveloperHub.clientTable.notPublished';
export const getIconForAppStatus = (polyglot: Polyglot, published: boolean): JSX.Element => {
  return published ? (
    <>
      <ChipComponent
        name={
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <OkGreen style={{ fill: themeColors.Green, marginRight: '4px' }} />
            {polyglot.t(`${publishedPoly}`)}
          </Box>
        }
        backgroundColor="white"
        textColor={'Grey'}
        border={'light'}
        textVariant="caption"
      />
    </>
  ) : (
    <>
      <ChipComponent
        name={polyglot.t(`${notPublishedPoly}`)}
        backgroundColor="white"
        textColor={'Grey'}
        border={'light'}
        textVariant="caption"
      />
    </>
  );
};

interface HubPageProps {
  oauthClients: NonSensitiveOauthClientConfiguration[] | null | undefined;
  onUpdate: () => void;
}

export const DeveloperHubBuildAppsPage = ({ oauthClients, onUpdate }: HubPageProps) => {
  const { polyglot } = usePolyglot();
  const routerHistory = useHistory();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);

  const columns = useMemo<
    ColumnDef<NonSensitiveOauthClientConfiguration, NonSensitiveOauthClientConfiguration>[]
  >(() => {
    return [
      {
        header: () => polyglot.t('DeveloperHub.appName'),
        accessorFn: (row) => row,
        minWidth: 50,
        maxWidth: 80,
        id: 'appName',
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.name),
        cell: ({ row: { original } }) => (
          <Box>
            <Typography variant="caption">{original.name}</Typography>
          </Box>
        ),
      },
      {
        header: () => polyglot.t('DeveloperHub.status'),
        accessorFn: (row) => row,
        id: 'status',
        minWidth: 30,
        maxWidth: 80,
        enableSorting: false,
        cell: ({ row: { original } }) => getIconForAppStatus(polyglot, original.published),
      },
    ];
  }, [polyglot]);

  //   const connectAccountingApp = useCallback(() => {
  //     if (!paymentTypeSetting.id) return;
  //     routerHistory.push({
  //       pathname: generatePath(APP_STORE_ROUTE),
  //       state: { category: ACCOUNTING_FINANCE_CATEGORY },
  //     });
  //   }, [paymentTypeSetting.id, routerHistory]);

  const handleRowClicked = (row: Row<NonSensitiveOauthClientConfiguration>) => {
    const rowData = row.original;
    console.log('row clicked: ', rowData);
    routerHistory.push(generatePath(SETTINGS_SECURITY_DEVELOPER_HUB_APP_GENERAL_ROUTE, { appId: rowData.id }));
    console.log('ROUTE pushed!');
  };

  return (
    <PaymentSettingsPageSection
      title={polyglot.t('DeveloperHub.mainPageSideTitle')}
      editSection={
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: spacing.g5 }}>
          <ButtonComponent sizeVariant="small" colorVariant="primary" onClick={() => setIsEditDrawerOpen(true)}>
            {polyglot.t('DeveloperHub.newApp')}
          </ButtonComponent>
        </Box>
      }
    >
      <Typography variant="caption">{polyglot.t('DeveloperHub.buildAppsPageExplainer')}</Typography>
      {oauthClients?.length && oauthClients?.length > 0 ? (
        <Box sx={{ width: '100%' }}>
          <BasicTable<NonSensitiveOauthClientConfiguration>
            rowData={oauthClients ?? []}
            columnData={columns}
            loading={false}
            hidePagination
            externalTdAndThPadding="0 0px"
            rowClick={handleRowClicked}
          />
        </Box>
      ) : (
        <Box>
          <Typography variant="caption" color="Grey" sx={{ mt: '8px' }}>
            {polyglot.t('DeveloperHub.noAppsYet')}
          </Typography>
        </Box>
      )}
      <DeveloperHubClientGenerateDrawer
        isOpen={isEditDrawerOpen}
        existingClient={undefined}
        setIsOpen={setIsEditDrawerOpen}
        onClose={() => setIsEditDrawerOpen(false)}
        onUpdate={onUpdate}
      />
    </PaymentSettingsPageSection>
  );
};
