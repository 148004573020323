import { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Drag } from '@/images/side-bar-icons/Drag.svg';
import { SETTINGS_DOCUMENTS_TEMPLATES_ROUTE } from '@/lib/routes';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { ReportConfigBackButton } from '@/v2/feature/reports/reports-advanced/components/report-config-back-button.component';
import { SMART_FIELD_TYPE, SMART_FIELDS } from '@/v2/feature/templates/components/editor';
import { drawerContentSx } from '@/v2/feature/user/features/user-profile/details/components/styles.layout';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { textOverflowSx } from '@/v2/styles/root.styles';

export const SideBar = () => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const onDragStart = (event: React.DragEvent<HTMLParagraphElement>, content: string) => {
    event?.dataTransfer?.setData('snippet', ` ${content} `);
  };

  const draggableContent = useMemo(() => {
    return (
      <>
        {SMART_FIELDS.map((snippet: SMART_FIELD_TYPE) => {
          if (searchInput && !snippet.label?.toLowerCase().includes(searchInput.toLowerCase())) return null;
          if (snippet?.group) {
            return (
              <Box key={snippet?.group}>
                <Typography
                  variant={'title4'}
                  sx={{
                    ...textOverflowSx,
                    ...{
                      textAlign: 'left',
                      padding: '1px',
                      paddingLeft: 1,
                      cursor: 'cursor',
                    },
                  }}
                  key={snippet.group}
                  draggable={false}
                  truncateHtml={false}
                >
                  <>{snippet.group}</>
                </Typography>
              </Box>
            );
          }
          return (
            <Box
              key={snippet?.value}
              sx={{
                bgcolor: themeColors.Background,
                borderRadius: radius.br5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '4px',
                marginBottom: '4px',
              }}
              onDragStart={(event: React.DragEvent<HTMLParagraphElement>) => onDragStart(event, snippet.value)}
              draggable
            >
              <Box sx={{ p: '4px' }}>
                <Drag {...iconSize} stroke={themeColors.Grey} />
              </Box>
              <Typography
                variant="caption"
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  ':hover': { background: themeColors.GreyHover },
                  p: '4px',
                }}
                draggable
              >
                {snippet.label}
              </Typography>
            </Box>
          );
        })}
      </>
    );
  }, [searchInput]);

  return (
    <Box
      sx={{
        ...drawerContentSx,
        width: '260px',
        borderRight: borders.background,
        textAlign: 'left',
        display: 'flex',
        backgroundColor: themeColors.white,

        flexDirection: 'column',
        gap: '10px',
        height: null,
        paddingLeft: '20px',
        paddingRight: '10px',
        position: 'absolute',
        top: 0,
        left: 60,
        bottom: 0,
        zIndex: 4,
      }}
    >
      <ReportConfigBackButton title="Templates" goBack={() => history.push(SETTINGS_DOCUMENTS_TEMPLATES_ROUTE)} />

      <TableSearch
        query={searchInput}
        handleChange={(e) => {
          setSearchInput(e.target.value);
        }}
        style={{ width: '220px' }}
      />
      <Box
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'left',
          width: '100%',
          flexDirection: 'column',
          overflowY: 'scroll',
          paddingRight: '5px',
        }}
      >
        {draggableContent}
      </Box>
    </Box>
  );
};
