import { useCallback, useEffect, useMemo, useState } from 'react';

import { SettingsSectionContent } from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { AppDetailsEndpoints } from '@v2/feature/app-integration/features/app-details/app-details.api';
import { AuthEndpoints } from '@v2/feature/auth/auth.api';
import { SSOConfigureDrawer } from '@v2/feature/security/security-settings/components/sso-configure-drawer.component';
import { SSOApp, SSOAppsEnum } from '@v2/feature/security/security-settings/security.interface';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';

export const SecuritySettingSSODetailPage = () => {
  const { polyglot } = usePolyglot();
  const { trackPage } = useJune();

  const { data: mfaEnabled, isLoading: loadingMFa } = useApiClient(AuthEndpoints.mfaCheck(), {
    suspense: false,
  });

  const { data: ssoState, isLoading: loadingSSO, mutate: refreshSSO } = useApiClient(
    AppDetailsEndpoints.getSSOMetadata(),
    {
      suspense: false,
    }
  );

  // const [edit, setEdit] = useState<boolean>(false);
  const [editSSO, setEditSSO] = useState<boolean>(false);

  useEffect(() => {
    trackPage('Company security settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshSSOState = useCallback(async () => {
    if (refreshSSO) await refreshSSO();
  }, [refreshSSO]);

  const { certificate, apikey, enabledEntry, ssoProvider } = useMemo(() => {
    const gwEntry = ssoState?.find((eachEntry) => eachEntry.app === 'google-workspace');
    const ssoGwEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-google-workspace');
    const ssoAzureAdEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-azure-ad');
    const ssoOktaEntry = ssoState?.find((eachEntry) => eachEntry.app === 'sso-okta');
    const enabledEntry = ssoState?.find((eachEntry) => eachEntry.state.enabled);

    const apikey = ssoOktaEntry?.state?.apikey ?? '';
    const ssoProvider = enabledEntry ? SSOAppsEnum[enabledEntry.app as SSOApp] : 'None';
    let certificate = '';
    if (gwEntry) certificate = gwEntry?.state?.certificate;
    if (ssoGwEntry) certificate = ssoGwEntry?.state?.certificate;
    if (ssoAzureAdEntry) certificate = ssoAzureAdEntry?.state?.certificate;

    return { certificate, apikey, enabledEntry, ssoProvider };
  }, [ssoState]);

  const secondConfigTitle = useMemo(() => {
    if (
      enabledEntry &&
      (enabledEntry.app === 'sso-google-workspace' || enabledEntry.app === 'sso-azure-ad') &&
      certificate?.length > 0
    )
      return 'IdP Metadata';
    else if (enabledEntry && enabledEntry.app === 'sso-okta' && apikey?.length > 0) return 'API token';
    else return '';
  }, [enabledEntry, certificate, apikey]);

  const secondConfigValue = useMemo(() => {
    if (
      enabledEntry &&
      (enabledEntry.app === 'sso-google-workspace' || enabledEntry.app === 'sso-azure-ad') &&
      certificate?.length > 0
    )
      return certificate;
    else if (enabledEntry && enabledEntry.app === 'sso-okta' && apikey?.length > 0) return apikey;
    else return '';
  }, [enabledEntry, certificate, apikey]);

  return (
    <ContentWrapper loading={loadingSSO || loadingMFa}>
      <SettingsSectionContent title={polyglot.t('SettingsRouterPageConfig.security')}>
        <SettingsSubsectionContent
          sections={[
            {
              title: polyglot.t('SSOMetadata.singleSignOn'),
              onEdit: () => setEditSSO(true),
              items: [
                {
                  type: SectionItemType.TextLine,
                  value: polyglot.t('SSOMetadata.connectYourCompany'),
                },
                {
                  type: SectionItemType.Pair,
                  label: polyglot.t('SSOMetadata.ssoProvider'),
                  value: ssoProvider,
                },
                {
                  type: SectionItemType.Pair,
                  label: secondConfigTitle,
                  value: secondConfigValue,
                },
              ],
            },
          ]}
        />
      </SettingsSectionContent>

      <SSOConfigureDrawer
        isOpen={editSSO}
        setIsOpen={setEditSSO}
        onClose={() => setEditSSO(false)}
        mfaEnabled={!!mfaEnabled}
        ssoState={ssoState ?? []}
        onUpdate={async () => {
          await refreshSSOState();
        }}
      />
    </ContentWrapper>
  );
};
