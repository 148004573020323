import React, { ReactNode } from 'react';

import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { OptionProps, StyledMenuComponent } from '@v2/components/theme-components/styled-menu.component';
import { iconSize } from '@v2/styles/table.styles';

import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { ReactComponent as Success } from '@/images/side-bar-icons/ok-green.svg';
import { ReactComponent as Failed } from '@/images/side-bar-icons/Rejected.svg';
import { ReactComponent as Loading } from '@/images/side-bar-icons/Spin.svg';
import { LoaderButton, LoadingButtonColorVariant } from '@/v2/components/theme-components/loading-button.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { spacing } from '@/v2/styles/spacing.styles';

const iconSize20px = { height: '20px', width: '20px' };

export type PayrunProcessingItemButton = {
  style: LoadingButtonColorVariant;
  loading?: boolean;
  label: string;
  show: boolean;
  onClick?: () => void;
  type: 'button' | 'dropdown';
  dropdownOptions?: readonly OptionProps[];
};

type PayrunProcessingItemProps = {
  title: ReactNode;
  description: string | React.JSX.Element;
  success?: boolean;
  buttons?: PayrunProcessingItemButton[];
  sx?: SxProps<Theme>;
};

export const PayrunProcessingItem = ({ title, description, success, buttons, sx }: PayrunProcessingItemProps) => {
  const isProcessing = typeof success !== 'boolean';

  const header = (
    <Stack sx={{ gap: spacing.g5 }}>
      <Typography sx={{ ...themeFonts.title4, color: themeColors.DarkGrey }}>{title}</Typography>
      {typeof description === 'string' ? (
        <Typography sx={{ ...themeFonts.caption, color: isProcessing ? themeColors.Grey : themeColors.DarkGrey }}>
          {description}
        </Typography>
      ) : (
        description
      )}
    </Stack>
  );

  return (
    <Stack
      sx={{
        flexDirection: 'row',
        width: '600px',
        borderTop: `1px solid`,
        py: spacing.p25,
        borderTopColor: themeColors.middleGrey,
        ...sx,
      }}
    >
      {isProcessing && (
        <>
          <Loading {...iconSize20px} style={{ flexShrink: 0, marginRight: spacing.m15 }} />
          {header}
        </>
      )}
      {!isProcessing && (
        <>
          {success ? (
            <Success {...iconSize20px} style={{ fill: themeColors.Green, flexShrink: 0 }} />
          ) : (
            <Failed {...iconSize20px} style={{ fill: themeColors.Red, flexShrink: 0 }} />
          )}
          <Stack sx={{ mx: spacing.m15 }}>
            {header}
            <Stack sx={{ flexDirection: 'row', gap: spacing.g10 }}>
              {buttons?.map((b) =>
                b.show && b.type === 'dropdown' && b.dropdownOptions ? (
                  <StyledMenuComponent
                    options={b.dropdownOptions}
                    loading={b.loading}
                    actionButtonDetails={{
                      icon: <ArrowDown {...iconSize} />,
                      iconPosition: 'end',
                      fullWidth: true,
                      type: 'button',
                      colorVariant: b.style,
                      sizeVariant: 'small',
                      title: 'Download',
                    }}
                    sx={{ marginTop: '20px', minWidth: '100px' }}
                  />
                ) : b.show && b.type === 'button' && b.onClick ? (
                  <LoaderButton
                    type="button"
                    loading={b.loading}
                    onClick={b.onClick}
                    sizeVariant="small"
                    colorVariant={b.style}
                    style={{ minWidth: '100px', marginTop: '20px' }}
                    name={b.label}
                  />
                ) : null
              )}
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
