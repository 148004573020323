import { useContext } from 'react';

import { Box } from '@mui/material';
import { AbsencePolicyDto } from '@v2/feature/absence/absence.dto';
import { AbsenceBalancesTable } from '@v2/feature/absence/components/absence-balances-table.component';
import { ContentWrapper } from '@v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { RootStyle } from '@v2/styles/root.styles';
import { spacing } from '@v2/styles/spacing.styles';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '@/GlobalState';
import { SETTINGS_TIME_ROUTE } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';

interface Props {
  readonly absencePolicies: readonly AbsencePolicyDto[];
}

export const AbsenceTeamBalancesPage = ({ absencePolicies }: Props): JSX.Element => {
  const { polyglot } = usePolyglot();
  const [globalState] = useContext(GlobalContext);
  const { userId } = globalState.user;
  const routerHistory = useHistory();
  const hasAbsenceAllScope = checkScopes(globalState.user, ['absence:all'], { userId });

  return (
    <RootStyle>
      <TopHeader
        title={polyglot.t('getPageConfig.time')}
        settingsAction={() => routerHistory.push(SETTINGS_TIME_ROUTE)}
        settingsHide={!hasAbsenceAllScope}
      />
      <ContentWrapper loading={false} sx={{ ...spacing.pt20, overflow: 'hidden' }}>
        <Box sx={spacing.mt20}>
          <AbsenceBalancesTable absencePolicies={absencePolicies} view="team" stickyHeader />
        </Box>
      </ContentWrapper>
    </RootStyle>
  );
};
