import React, { Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { DatePickerComponent } from '@v2/components/forms/date-picker.component';
import { SelectComponent } from '@v2/components/forms/select.component';
import { TextfieldComponent } from '@v2/components/forms/textfield.component';
import { DrawerModal } from '@v2/components/theme-components/drawer-modal.component';
import { LoaderButton } from '@v2/components/theme-components/loading-button.component';
import { SkeletonLoader } from '@v2/feature/dashboard/components/skeleton-loader.component';
import { DeviceAPI, DeviceEndpoints } from '@v2/feature/device/device.api';
import { DevicePossessionDto, DeviceUpdateSuperadminDto } from '@v2/feature/device/device.dto';
import { DeviceExternalMatching, DeviceOwnership, DeviceType } from '@v2/feature/device/device.interface';
import {
  DeviceTypesValueLabelOptions,
  getDeviceOwnerAsSuperadminByDevicePossession,
} from '@v2/feature/device/device.util';
import { SiteDto } from '@v2/feature/site/site.dto';
import { drawerContentSx } from '@v2/feature/user/features/user-profile/details/components/styles.layout';
import { useApiClient } from '@v2/infrastructure/api-client/api-client.hook';
import { dateFieldTest } from '@v2/infrastructure/date/date-format.util';
import { tableIconButtonSx } from '@v2/styles/icon-button.styles';
import { buttonBoxDrawerSx } from '@v2/styles/settings.styles';
import { actionIconSize } from '@v2/styles/table.styles';
import dayjs from 'dayjs';
import { Form, FormikProvider, useFormik } from 'formik';
import { generatePath, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useMessage from '@/hooks/notification.hook';
import { ReactComponent as FullScreen } from '@/images/side-bar-icons/FullScreen.svg';
import { nestErrorMessage } from '@/lib/errors';
import { SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE } from '@/lib/routes';
import { Typography } from '@/v2/components/typography/typography.component';
import { themeColors } from '@/v2/styles/colors.styles';
import { spacing } from '@/v2/styles/spacing.styles';

interface SuperAdminOverviewDeviceDrawerProps {
  isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
  devicePossession: DevicePossessionDto;
  readonly setDevicePossession: Dispatch<SetStateAction<DevicePossessionDto | null>>;
  readonly userNames: { [userId: number]: string };
  readonly matchings: DeviceExternalMatching;
  readonly sites: { [siteId: number]: SiteDto };
  readonly refresh: () => Promise<void>;
}

export const SuperAdminOverviewDeviceDrawer = ({
  isOpen,
  setIsOpen,
  devicePossession,
  setDevicePossession,
  userNames,
  matchings,
  sites,
  refresh,
}: SuperAdminOverviewDeviceDrawerProps): JSX.Element => (
  <DrawerModal isOpen={isOpen} setIsOpen={setIsOpen}>
    <Suspense
      fallback={
        <SkeletonLoader
          variant="rectangular"
          width="90%"
          height="90vh"
          sx={{ borderRadius: '10px', mx: 'auto', mt: 4, backgroundColor: themeColors.Background }}
        />
      }
    >
      <SuperAdminDeviceOverviewDrawerContent
        devicePossession={devicePossession}
        refresh={refresh}
        setDevicePossession={setDevicePossession}
        userNames={userNames}
        sites={sites}
        matchings={matchings}
      />
    </Suspense>
  </DrawerModal>
);

interface SuperAdminDeviceOverviewDrawerContentProps {
  devicePossession: DevicePossessionDto;
  readonly setDevicePossession: Dispatch<SetStateAction<DevicePossessionDto | null>>;
  readonly userNames: { [userId: number]: string };
  readonly matchings: DeviceExternalMatching;
  readonly sites: { [siteId: number]: SiteDto };
  readonly refresh: () => Promise<void>;
}

export const SuperAdminDeviceOverviewDrawerContent = ({
  devicePossession,
  setDevicePossession,
  userNames,
  matchings,
  sites,
  refresh,
}: SuperAdminDeviceOverviewDrawerContentProps): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [refreshLoading, setRefreshLoading] = useState<boolean>(false);
  const [externalId, setExternalId] = useState<number | null>(matchings[devicePossession.deviceId]);
  const [showMessage] = useMessage();
  const { data: deviceOrders } = useApiClient(
    DeviceEndpoints.getOrderByCompanyIdAndDeviceIdAsSuperadmin(
      devicePossession.companyId ?? 0,
      devicePossession.deviceId
    ),
    {
      suspense: false,
    }
  );
  const initialValues: DeviceUpdateSuperadminDto = {
    serialNumber: devicePossession.device?.serialNumber ?? null,
    internalNotes: devicePossession.device?.internalNotes ?? null,
    price: devicePossession.device?.price ?? null,
    type: devicePossession.device?.type ?? DeviceType.Laptop,
    contractLength: devicePossession.device?.contractLength ?? null,
    contractStartDate: devicePossession.device?.contractStartDate ? devicePossession.device?.contractStartDate : null,
  };

  const validationSchema = yup.object({
    serialNumber: yup.string().nullable().notRequired(),
    internalNotes: yup.string().nullable().notRequired(),
    price: yup.number().nullable().notRequired(),
    type: yup.string().required('Device type is required'),
    contractLength: yup.number().integer().nullable().notRequired(),
    contractStartDate: yup.string().test(dateFieldTest).nullable().notRequired(),
  });

  const patchDeviceEntity = async (formData: DeviceUpdateSuperadminDto) => {
    try {
      const { serialNumber, internalNotes, customerNotes, price, type, contractLength, contractStartDate } = formData;
      setLoading(true);

      await DeviceAPI.updateDeviceByIdAsSuperadmin(devicePossession.deviceId, {
        serialNumber,
        internalNotes,
        customerNotes,
        price: Number(price),
        type,
        contractLength: Number(contractLength),
        contractStartDate,
      });
      showMessage('Device successfully updated.', 'success');
      await refresh();
    } catch (error) {
      showMessage(`Device could not be updated. ${nestErrorMessage(error)}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<DeviceUpdateSuperadminDto>({
    initialValues,
    validationSchema,
    onSubmit: async (values: DeviceUpdateSuperadminDto) => patchDeviceEntity(values),
  });

  useEffect(() => {
    formik.validateForm();
    // TODO add formik and check that the ref is always the same
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshDeviceCache = async () => {
    setRefreshLoading(true);

    try {
      const refreshedDevicePossession = await DeviceAPI.getRefreshedDevicePossessionDetails(devicePossession.id);
      setDevicePossession(refreshedDevicePossession);
      showMessage('Device Possession refreshed.', 'success');
    } catch (error) {
      showMessage('The device could have not been refreshed', 'error');
    } finally {
      setRefreshLoading(false);
    }
  };

  const syncDeviceById = async () => {
    try {
      const initialExternalId = externalId;
      setSyncLoading(true);
      const response = await DeviceAPI.syncWithExternalProviderById(devicePossession.id);
      setDevicePossession(response.devicePossession);
      setExternalId(response.externalId ?? null);

      if (response.externalId) showMessage('Device successfully synced.', 'success');
      else if (!initialExternalId && !response.externalId)
        showMessage('The local device could not be matched with an external device.', 'warning');
      else if (initialExternalId && !response.externalId) {
        showMessage(
          'External matching has been removed as the local device could not be matched with an external device.',
          'info'
        );
      }
    } catch (error: any) {
      // If no matching found on external provider
      if (error.response?.data?.statusCode === 404 && error.response.data.message === 'External matching not found') {
        showMessage('External matching not found', 'error');
        return;
      }

      showMessage('Could not sync device. Something went wrong.', 'error');
    } finally {
      setSyncLoading(false);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit} style={drawerContentSx}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="title2">Device details</Typography>

          <IconButton
            title="more-details"
            onClick={() => {
              history.push(
                generatePath(SUPER_ADMIN_DEVICE_OVERVIEW_DETAILS_ROUTE, { deviceId: devicePossession.deviceId })
              );
            }}
            sx={tableIconButtonSx}
          >
            <FullScreen {...actionIconSize} />
          </IconButton>
        </Box>
        {devicePossession.device?.modelName && (
          <Typography variant="title4">{devicePossession.device.modelName}</Typography>
        )}

        <TextfieldComponent
          label="External ID"
          name="externalId"
          value={externalId ?? 'N/A'}
          size="small"
          endAdornment="none"
          disabled
        />

        <TextfieldComponent
          label="Owned by"
          name="ownedBy"
          value={getDeviceOwnerAsSuperadminByDevicePossession(devicePossession, userNames, sites)}
          size="small"
          endAdornment="none"
          disabled
        />

        <TextfieldComponent
          label="Serial Number"
          name="serialNumber"
          value={formik.values.serialNumber}
          onChange={formik.handleChange}
          error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
          helperText={formik.touched.serialNumber && formik.errors.serialNumber}
          clearText={() => formik.setFieldValue('serialNumber', '')}
          size="small"
        />

        <SelectComponent
          name="type"
          label="Type"
          options={DeviceTypesValueLabelOptions}
          value={formik.values.type ?? undefined}
          compareValue={formik.values.type ?? undefined}
          onChange={formik.handleChange}
          error={formik.touched.type && !!formik.errors.type}
          helperText={formik.touched.type && formik.errors.type}
        />

        <TextfieldComponent
          label="Internal notes"
          name="internalNotes"
          value={formik.values.internalNotes}
          onChange={formik.handleChange}
          error={formik.touched.internalNotes && Boolean(formik.errors.internalNotes)}
          helperText={formik.touched.internalNotes && formik.errors.internalNotes}
          clearText={() => formik.setFieldValue('internalNotes', '')}
          size="small"
        />

        {devicePossession.device?.ownership === DeviceOwnership.Rental && (
          <TextfieldComponent
            label="Price"
            name="price"
            value={formik.values.price}
            onChange={formik.handleChange}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
            clearText={() => formik.setFieldValue('price', '')}
            size="small"
          />
        )}

        {devicePossession.device?.ownership === DeviceOwnership.Rental && (
          <TextfieldComponent
            label="Contract length"
            name="contractLength"
            value={formik.values.contractLength}
            onChange={formik.handleChange}
            error={formik.touched.contractLength && Boolean(formik.errors.contractLength)}
            helperText={formik.touched.contractLength && formik.errors.contractLength}
            clearText={() => formik.setFieldValue('contractLength', '')}
            size="small"
          />
        )}

        {devicePossession.device?.ownership === DeviceOwnership.Rental && (
          <DatePickerComponent
            inputFormat="DD/MM/YYYY"
            value={formik.values.contractStartDate ?? null}
            onChange={(value) => {
              if (dayjs(value).isValid()) {
                formik.setFieldValue('contractStartDate', value);
              }
            }}
            name="contractStartDate"
            label="Contract start date"
            error={!!formik.errors.contractStartDate && Boolean(formik.touched.contractStartDate)}
            helperText={formik.errors.contractStartDate && Boolean(formik.touched.contractStartDate)}
          />
        )}

        {devicePossession.device && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
            <Typography variant="title4">Device Summary</Typography>
            <Typography variant="caption">{devicePossession.device?.modelName ?? '-'}</Typography>
            <Typography variant="caption">{devicePossession.device?.screenSize ?? '??'} inch</Typography>
            <Typography variant="caption">{devicePossession.device?.ram ?? '??'}GB Memory</Typography>
          </Box>
        )}

        {deviceOrders &&
          deviceOrders.length > 0 &&
          deviceOrders.map((deviceOrder) => (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
              <Typography variant="title4">Order Summary</Typography>
              <Typography variant="caption">Order Status: {deviceOrder.status}</Typography>
              {deviceOrder.contractLength && (
                <Typography variant="caption">Contract Length: {deviceOrder.contractLength} months</Typography>
              )}
              {deviceOrder.deliveryDate && (
                <Typography variant="caption">
                  Delivery Date: {new Date(deviceOrder.deliveryDate).toLocaleDateString('en-GB')}
                </Typography>
              )}
              {deviceOrder.createdAt && (
                <Typography variant="caption">
                  Ordered on: {new Date(deviceOrder.createdAt).toLocaleDateString('en-GB')}
                </Typography>
              )}
              {deviceOrder.createdBy && (
                <Typography variant="caption">Ordered by: {userNames[deviceOrder.createdBy] ?? '??'}</Typography>
              )}
            </Box>
          ))}

        {devicePossession.device?.policies && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.s1 }}>
            <Typography variant="title4">Applied Policies</Typography>
            <Typography variant="caption">
              {devicePossession.device.policies.map((policy) => policy.name).join(', ')}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
            ...buttonBoxDrawerSx,
          }}
        >
          {devicePossession.device?.serialNumber && !devicePossession.device?.inHouseMdm && (
            <LoaderButton
              name="Sync with Hexnode"
              loading={syncLoading}
              onClick={syncDeviceById}
              disabled={!Boolean(devicePossession.device?.serialNumber)}
              sizeVariant="medium"
              colorVariant="primary"
            />
          )}
          {devicePossession.device?.serialNumber && !devicePossession.device?.inHouseMdm && (
            <LoaderButton
              name="Update MDM data"
              loading={refreshLoading}
              onClick={refreshDeviceCache}
              sizeVariant="medium"
              colorVariant="primary"
            />
          )}
          <LoaderButton
            name="Save"
            loading={loading}
            onClick={() => formik.handleSubmit()}
            sizeVariant="medium"
            colorVariant="primary"
          />
        </Box>
      </Form>
    </FormikProvider>
  );
};
