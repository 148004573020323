import React, { useCallback, useEffect, useState } from 'react';

import { SxProps, Theme } from '@mui/material';
import {
  DownloadPayslipsState,
  DownloadPayslipsStates,
  PayrunProcessStepState,
  PayrunProcessStepStates,
} from '@v2/feature/payroll/features/payroll-uk/payrun-process/payrun-process.interface';
import { PayrollExternalApi } from '@v2/feature/payroll/payroll-external.api';
import { PayrollLocalApi } from '@v2/feature/payroll/payroll-local.api';
import { PayRunDto } from '@v2/feature/payroll/payroll.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { sleep } from '@v2/util/time.util';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { PayrunProcessingItem } from '@/v2/feature/payroll/features/payroll-uk/payrun-flow/components/payrun-processing-item.component';
import { LocalDate } from '@/v2/util/local-date';

interface Props {
  readonly payRun: PayRunDto;
  readonly downloadPayslipsState: DownloadPayslipsState;
  readonly setDownloadPayslipsState: React.Dispatch<React.SetStateAction<DownloadPayslipsState>>;
  sx?: SxProps<Theme>;
}

export const DownloadPayslip = ({ payRun, setDownloadPayslipsState, sx }: Props): React.JSX.Element => {
  const [accordionState, setAccordionState] = useState<PayrunProcessStepState>(PayrunProcessStepStates.pending);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showMessage] = useMessage();
  const { polyglot } = usePolyglot();

  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setAccordionState(PayrunProcessStepStates.pending);
      try {
        const payRunPayslipsDetails = await PayrollLocalApi.getPayrunPayslipsDetails(payRun.id);

        const downloadPayslipsState = payRunPayslipsDetails?.state ?? DownloadPayslipsStates.notDownloaded;
        setDownloadPayslipsState(downloadPayslipsState);

        if (downloadPayslipsState === DownloadPayslipsStates.notDownloaded) {
          await sleep(1000);
          const updatedDownloadPayslips = await PayrollLocalApi.createPayslips(payRun.id);
          setDownloadPayslipsState(updatedDownloadPayslips.state);
        }
        setAccordionState(PayrunProcessStepStates.success);
      } catch (error) {
        setErrorMessage(nestErrorMessage(error));
        setAccordionState(PayrunProcessStepStates.failure);
      }
    })();
  }, [payRun, setDownloadPayslipsState]);

  const createPayslips = async (payRunId: number) => {
    try {
      setAccordionState(PayrunProcessStepStates.pending);
      const updatedDownloadPayslips = await PayrollLocalApi.createPayslips(payRunId);
      setDownloadPayslipsState(updatedDownloadPayslips.state);
      setAccordionState(PayrunProcessStepStates.success);
    } catch (error) {
      setErrorMessage(nestErrorMessage(error));
      setAccordionState(PayrunProcessStepStates.failure);
    }
  };

  const downloadPayslips = useCallback(async () => {
    try {
      setDownloadLoading(true);
      const paySchedules = await PayrollExternalApi.getPaySchedulesForAllTaxYears(payRun.payrollId);

      const payslipPayruns = (paySchedules ?? [])
        .map(({ payRuns }) => payRuns.map(({ metadata }) => metadata))
        .flat()
        .sort((a, b) => b.endDate.localeCompare(a.endDate));

      const selectedPayslipPayruns = payslipPayruns.filter(
        (e) => e.taxYear === payRun.taxYear && e.payPeriod === payRun.payPeriod && e.periodNumber === payRun.period
      );

      // get max ordinal from selectedPayslipPayruns
      let ordinal = 1;
      for (const p of selectedPayslipPayruns) if (p.ordinal > ordinal) ordinal = p.ordinal;

      const url = await PayrollExternalApi.downloadPayslipsUrl(
        payRun.payrollId,
        payRun.taxYear,
        payRun.payPeriod,
        payRun.period,
        ordinal
      );
      window.location.href = url;
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), 'error');
    }
    setDownloadLoading(false);
  }, [payRun, showMessage, polyglot]);

  const downloadP30 = useCallback(async () => {
    setDownloadLoading(true);
    try {
      const periodEndingDate = new LocalDate(payRun.endDate);
      if (periodEndingDate.getDate().getDate() <= 5) {
        // same month on 5th
        periodEndingDate.getDate().setDate(5);
      } else {
        // next month on 5th
        periodEndingDate.getDate().setMonth(periodEndingDate.getDate().getMonth() + 1, 5);
      }

      const periodEnding = periodEndingDate.toDateString();

      const url = await PayrollExternalApi.downloadP30Url(payRun.payrollId, payRun.taxYear, periodEnding);
      window.location.href = url;
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), 'error');
    }
    setDownloadLoading(false);
  }, [payRun, showMessage, polyglot]);

  const downloadP32 = useCallback(async () => {
    setDownloadLoading(true);
    try {
      const url = await PayrollExternalApi.downloadP32Url(payRun.payrollId, payRun.taxYear);
      window.location.href = url;
    } catch (error) {
      showMessage(nestErrorMessage(error, polyglot), 'error');
    }
    setDownloadLoading(false);
  }, [payRun, showMessage, polyglot]);

  return (
    <PayrunProcessingItem
      title="Documents"
      key="Documents"
      description={
        {
          pending: 'Preparing payslips and payroll reports...',
          failure: `An error occurred while generating the payslips. ${errorMessage}`,
          success: 'Payroll reports have been generated.',
          warning: `A warning occurred while generating the payslips. ${errorMessage}`,
        }[accordionState]
      }
      buttons={[
        {
          style: 'primary',
          label: 'Retry',
          show: accordionState !== PayrunProcessStepStates.success,
          onClick: () => createPayslips(payRun.id),
          type: 'button',
        },
        {
          style: 'secondary',
          label: 'Download',
          show: accordionState === PayrunProcessStepStates.success,
          type: 'dropdown',
          loading: downloadLoading,
          dropdownOptions: [
            {
              label: "P30 - Download your Employer's Payslip",
              handler: downloadP30,
            },
            {
              label: 'P32 - Download your Employer Payment Record for tax year',
              handler: downloadP32,
            },
            {
              label: 'All payslips for all employees',
              handler: downloadPayslips,
            },
          ],
        },
      ]}
      success={
        accordionState === PayrunProcessStepStates.pending
          ? undefined
          : accordionState === PayrunProcessStepStates.success
      }
      sx={sx}
    />
  );
};
