import React, { useContext, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Dictionary } from 'lodash';

import { Action, GlobalContext, GlobalContextState } from '@/GlobalState';
import { ReactComponent as ArrowDown } from '@/images/side-bar-icons/ArrowDownSelect.svg';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { Typography } from '@/v2/components/typography/typography.component';
import { SettingsSectionContent } from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-section-content.component';
import {
  SectionItemType,
  SettingsSubsectionContent,
} from '@/v2/feature/absence/subfeatures/settings/policy-details/components/settings-subsection-content.component';
import { GrowthFactor } from '@/v2/feature/growth/growth-factor/growth-factor.interface';
import { ReachType } from '@/v2/feature/growth/reviews/interfaces/review-cycle.interface';
import { SurveyCycleEndpoints } from '@/v2/feature/growth/surveys/api-client/survey-cycle.api';
import { ChartHeatmapTable } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-heatmap/components/chart-heatmap/chart-heatmap-table.component';
import { SurveyCycleDetailHeatmapFilters } from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle-detail/survey-cycle-detail-heatmap/components/survey-cycle-detail-heatmap.filters.component';
import {
  TitleStatusComponent,
  isDataEmpty,
  transformData,
} from '@/v2/feature/growth/surveys/features/survey-cycle/survey-cycle.util';
import {
  AnonymityType,
  ComparedBy,
  MeasureBy,
  ShowBy,
  SurveyCycle,
  SurveyHeatmap,
} from '@/v2/feature/growth/surveys/interfaces/survey-cycle.interface';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { borders } from '@/v2/styles/borders.styles';
import { iconSize } from '@/v2/styles/menu.styles';
import { radius } from '@/v2/styles/radius.styles';
import { spacing } from '@/v2/styles/spacing.styles';
import { captureExcel, capturePdf } from '@/v2/util/export-reports.util';
import { replaceParamsInQuestionText, stripHtml } from '@/v2/util/string.util';

export const SurveyCycleDetailHeatmapPage = ({
  surveyCycle,
  cycleLoading,
  reach,
  growthFactors,
}: {
  readonly surveyCycle: SurveyCycle;
  readonly cycleLoading: boolean | undefined;
  readonly reach: ReachType;
  readonly growthFactors: Dictionary<GrowthFactor>;
}) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { user } = state;
  const company_name = user?.company?.name ?? 'Company';
  const [filter, setFilter] = useState<ComparedBy>(
    !surveyCycle.visibilitySettings?.anonymiseAnswers ||
      (surveyCycle.visibilitySettings &&
        surveyCycle.visibilitySettings.anonymityType &&
        surveyCycle.visibilitySettings.anonymityType === AnonymityType.Semi)
      ? state.user.features?.surveys?.heatMap?.comparedBy || ComparedBy.Site
      : ComparedBy.None
  );
  const { data: surveyHeatmap, isLoading: validatingHeatmap } = useApiClient(
    SurveyCycleEndpoints.getSurveyCycleHeatmap(surveyCycle.id, filter, reach),
    {
      suspense: false,
    }
  );

  const reportName = useMemo(() => `${surveyCycle.internalName}-heatmap`, [surveyCycle]);

  const generateExcel = () => {
    const uniqueFactors = new Set();
    const uniqueQuestions = new Set();
    const dynamicNames = new Set();
    const scoreSurvey = (surveyHeatmap || []).find((survey) => survey.name === 'score');

    if (scoreSurvey) {
      scoreSurvey.result.resultByQuestion.forEach((item) => {
        uniqueQuestions.add(item.question.id);
      });

      scoreSurvey.result.resultByFactor.forEach((item) => {
        uniqueFactors.add(item.factor);
      });
    }
    (surveyHeatmap || []).forEach((survey) => {
      dynamicNames.add(survey.name);
    });

    const dynamicColumnsArray = Array.from(dynamicNames).flatMap((name) => [
      `${name}:NPS`,
      `${name}:Avg`,
      `${name}:Positive`,
    ]);
    const header = ['Type', 'Text', ...dynamicColumnsArray];

    const data = [];
    data.push(header);
    scoreSurvey?.result.resultByQuestion.forEach((item) => {
      const row = [];
      row.push('Question');
      row.push(replaceParamsInQuestionText(stripHtml(item.question.questionText), { company_name }));
      dynamicColumnsArray.forEach((col) => {
        const [surveyName, metric] = col.split(':');
        const survey = (surveyHeatmap || []).find((s) => s.name === surveyName);
        if (survey) {
          const questionResult = survey.result.resultByQuestion.find((q) => q.question.id === item.question.id);
          if (questionResult) {
            if (metric === 'NPS') {
              row.push(questionResult.npsPercent !== null ? (questionResult.npsPercent / 100).toFixed(2) : '0.00');
            } else if (metric === 'Avg') {
              row.push(questionResult.normPercent !== null ? (questionResult.normPercent / 100).toFixed(2) : '0.00');
            } else if (metric === 'Positive') {
              row.push(
                questionResult.positivePercent !== null ? (questionResult.positivePercent / 100).toFixed(2) : '0.00'
              );
            }
          } else {
            row.push('–');
          }
        } else {
          row.push('–');
        }
      });

      data.push(row);
    });

    scoreSurvey?.result.resultByFactor.forEach((item) => {
      const row = [];
      row.push('Factor');
      row.push(item.factor);
      dynamicColumnsArray.forEach((col) => {
        const [surveyName, metric] = col.split(':');
        const survey = (surveyHeatmap || []).find((s) => s.name === surveyName);
        if (survey) {
          const factorResult = survey.result.resultByFactor.find((f) => f.factor === item.factor);
          if (factorResult) {
            if (metric === 'NPS') {
              row.push(factorResult.npsPercent !== null ? (factorResult.npsPercent / 100).toFixed(2) : '0.00');
            } else if (metric === 'Avg') {
              row.push(factorResult.normPercent !== null ? (factorResult.normPercent / 100).toFixed(2) : '0.00');
            } else if (metric === 'Positive') {
              row.push(
                factorResult.positivePercent !== null ? (factorResult.positivePercent / 100).toFixed(2) : '0.00'
              );
            }
          } else {
            row.push('n/a');
          }
        } else {
          row.push('n/a');
        }
      });

      data.push(row);
    });
    captureExcel(data, reportName);
  };

  return (
    <SettingsSectionContent
      title={<TitleStatusComponent surveyCycle={surveyCycle} />}
      noHorizontalPadding={true}
      topHeaderPaddingSx={{ px: spacing.px16 }}
      contentWidth="100%"
      headerWidth="100%"
      contentSx={{ overflowX: 'hidden' }}
      buttons={[
        <StyledMenuComponent
          options={[
            {
              handler: generateExcel,
              label: 'Excel report',
            },
            {
              handler: () => capturePdf(reportName),
              label: 'PDF report',
            },
          ]}
          actionButtonDetails={{
            type: 'button',
            colorVariant: 'secondary',
            sizeVariant: 'small',
            title: 'Export',
            icon: <ArrowDown {...iconSize} />,
            iconPosition: 'end',
          }}
        />,
      ]}
    >
      <SettingsSubsectionContent
        sections={[
          {
            contentWidth: '100%',
            headerWidth: '100%',
            title: 'Heatmap',
            headerSx: { px: spacing.px16, overflowX: 'hidden' },
            items: [
              {
                type: SectionItemType.Component,
                value: (
                  <HeatMapByCategories
                    surveyHeatmap={surveyHeatmap}
                    setFilter={setFilter}
                    filter={filter}
                    validatingHeatmap={Boolean(validatingHeatmap || cycleLoading)}
                    surveyCycle={surveyCycle}
                    state={state}
                    dispatch={dispatch}
                    reportName={reportName}
                    growthFactors={growthFactors}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </SettingsSectionContent>
  );
};

const HeatMapByCategories = ({
  surveyHeatmap,
  setFilter,
  filter,
  validatingHeatmap,
  surveyCycle,
  state,
  dispatch,
  reportName,
  growthFactors,
}: {
  surveyHeatmap: SurveyHeatmap[] | null | undefined;
  filter: ComparedBy;
  setFilter: React.Dispatch<React.SetStateAction<ComparedBy>>;
  validatingHeatmap: boolean;
  surveyCycle: SurveyCycle;
  state: GlobalContextState;
  dispatch: React.Dispatch<Action>;
  reportName: string;
  growthFactors: Dictionary<GrowthFactor>;
}) => {
  const [showByFilter, setShowByFilter] = useState<ShowBy>(
    state.user.features?.surveys?.heatMap?.showBy || ShowBy.Questions
  );
  const [measureByFilter, setMeasureByFilter] = useState<MeasureBy>(
    state.user.features?.surveys?.heatMap?.measuredBy || MeasureBy.NPS
  );

  const { user } = state;

  const emptyCheck = useMemo(() => isDataEmpty(surveyHeatmap, showByFilter), [surveyHeatmap, showByFilter]);

  const transformedData = useMemo(() => transformData(surveyHeatmap, showByFilter, measureByFilter, user), [
    surveyHeatmap,
    showByFilter,
    measureByFilter,
    user,
  ]);

  const questionMap = useMemo(() => {
    const map: Record<string, string> = {};
    surveyHeatmap?.forEach((category) => {
      category.result.resultByQuestion.forEach((questionResult) => {
        const { questionText, id } = questionResult.question;
        const questionTextReplaced = questionText.replaceAll('{company_name}', user.company.name || 'company');
        map[id] = questionTextReplaced;
      });
    });

    return map;
  }, [surveyHeatmap, user]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g16, width: '100%' }}>
      <Box sx={{ ...spacing.px16 }}>
        <SurveyCycleDetailHeatmapFilters
          setShowByFilter={setShowByFilter}
          dispatch={dispatch}
          setFilter={setFilter}
          setMeasureByFilter={setMeasureByFilter}
          showByFilter={showByFilter}
          measureByFilter={measureByFilter}
          filter={filter}
          surveyCycle={surveyCycle}
        />
      </Box>
      <Box
        id={reportName}
        sx={{
          width: '100%',
          borderRadius: radius.br12,
          paddingY: spacing.p16,
          justifyContent: 'flex-end',
          boxSizing: 'border-box',
        }}
      >
        {emptyCheck && !validatingHeatmap ? (
          <Box
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              borderTop: borders.light,
              borderBottom: borders.light,
              paddingY: spacing.p24,
            }}
          >
            <Typography variant="caption" color="Grey">
              No data available.
            </Typography>
          </Box>
        ) : (
          <ChartHeatmapTable
            series={transformedData.series}
            categories={transformedData.categories}
            filter={showByFilter}
            loadingHeatmap={validatingHeatmap}
            growthFactors={growthFactors}
            measureByFilter={measureByFilter}
            surveyHeatmap={surveyHeatmap}
            questionMap={questionMap}
            showByFilter={showByFilter}
          />
        )}
      </Box>
    </Box>
  );
};
