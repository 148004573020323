import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { CompanyPayroll } from '@shared/modules/payroll/payroll.types';
import { StaffologyPaySchedule } from '@v2/feature/payroll/payroll-external.dto';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';

import useMessage from '@/hooks/notification.hook';
import { nestErrorMessage } from '@/lib/errors';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { SelectComponent } from '@/v2/components/forms/select.component';
import { PayrollExternalApi } from '@/v2/feature/payroll/payroll-external.api';
import { PayrollAPI } from '@/v2/feature/payroll/payroll.api';
import { PayrollHmrcLiabilityDto } from '@/v2/feature/payroll/payroll.dto';
import { borders } from '@/v2/styles/borders.styles';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { StyledRadio } from '@/v2/styles/radio.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const PayrollReportType = () => {
  const { polyglot } = usePolyglot();

  const PayrollReportTypes = [
    { name: 'P30', desc: polyglot.t('PayrollReportType.p30') },
    { name: 'P32', desc: polyglot.t('PayrollReportType.p32') },
    { name: 'Payslips', desc: polyglot.t('PayrollReportType.payslips') },
  ] as const;
  const [payrolls, setPayrolls] = useState<CompanyPayroll[]>();
  const [selectedPayrollId, setSelectedPayrollId] = useState<number>();
  const [payrollPeriods, setPayrollPeriods] = useState<PayrollHmrcLiabilityDto[]>();
  const [paySchedules, setPaySchedules] = useState<StaffologyPaySchedule[]>();
  const [selectedType, setSelectedType] = useState<string>('');

  const [selectedP32TaxYear, setSelectedP32TaxYear] = useState<string>('');
  const [selectedP30Period, setSelectedP30Period] = useState<string>('');
  const [selectedPayslipPayrun, setSelectedPayslipPayrun] = useState<string>('');

  const [showMessage] = useMessage();

  const taxYears = useMemo(() => [...new Set((payrollPeriods ?? []).map(({ taxYear }) => taxYear))].sort(), [
    payrollPeriods,
  ]);

  const p30Periods = useMemo(
    () =>
      (payrollPeriods ?? [])
        .map((p) => `${p.periodEnding}|${p.taxYear}`)
        .sort()
        .reverse(), // show in date order, most-recent first
    [payrollPeriods]
  );

  const payslipPayruns = useMemo(
    () =>
      (paySchedules ?? [])
        .map(({ payRuns }) => payRuns.map(({ metadata }) => metadata))
        .flat()
        .sort((a, b) => b.endDate.localeCompare(a.endDate)),
    [paySchedules]
  );

  useEffect(() => {
    PayrollAPI.getCompanyPayrolls()
      .then((payrolls) =>
        [...payrolls.items].sort((a, b) => {
          if (!a.entity) return -1;
          if (!b.entity) return 1;
          return a.entity.legalName.localeCompare(b.entity.legalName, undefined, { sensitivity: 'base' });
        })
      )
      .then(setPayrolls);
  }, []);

  useEffect(() => {
    // clear the state if the payrollId changes
    setSelectedType('');
    setPayrollPeriods(undefined);
    setPaySchedules(undefined);
    if (typeof selectedPayrollId === 'number') {
      PayrollExternalApi.getHmrcLiabilities(selectedPayrollId).then(setPayrollPeriods);
      PayrollExternalApi.getPaySchedulesForAllTaxYears(selectedPayrollId).then(setPaySchedules);
    }
  }, [selectedPayrollId]);

  useEffect(() => {
    // clear the selected periods if the type changes
    setSelectedP30Period('');
    setSelectedP32TaxYear('');
    setSelectedPayslipPayrun('');
  }, [selectedType]);

  const formatPeriodEndDate = useCallback(
    (date: string) =>
      new Date(date).toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      }),

    []
  );

  const showDownloadButton =
    (selectedType === 'P32' && !!selectedP32TaxYear) ||
    (selectedType === 'P30' && !!selectedP30Period) ||
    (selectedType === 'Payslips' && !!selectedPayslipPayrun);

  const performDownload = useCallback(async () => {
    try {
      if (selectedType === 'P30' && !!selectedP30Period && selectedPayrollId) {
        const [periodEnding, taxYear] = selectedP30Period.split('|');
        window.location.href = await PayrollExternalApi.downloadP30Url(selectedPayrollId, taxYear, periodEnding);
      } else if (selectedType === 'P32' && !!selectedP32TaxYear && selectedPayrollId) {
        window.location.href = await PayrollExternalApi.downloadP32Url(selectedPayrollId, selectedP32TaxYear);
      } else if (selectedType === 'Payslips' && !!selectedPayslipPayrun && selectedPayrollId) {
        const { taxYear, payPeriod, periodNumber, ordinal } = JSON.parse(selectedPayslipPayrun);
        window.location.href = await PayrollExternalApi.downloadPayslipsUrl(
          selectedPayrollId,
          taxYear,
          payPeriod,
          periodNumber,
          ordinal
        );
      }
    } catch (error) {
      showMessage(`${polyglot.t('PayrollReportType.errorMessages.download')}. ${nestErrorMessage(error)}`, 'error');
    }
  }, [
    polyglot,
    selectedP30Period,
    selectedP32TaxYear,
    selectedPayrollId,
    selectedPayslipPayrun,
    selectedType,
    showMessage,
  ]);

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ maxWidth: '400px', width: '100%' }}>
        <Typography sx={themeFonts.title2}>{polyglot.t('PayrollReportType.select')}</Typography>

        <FormControl sx={{ width: '100%', ...spacing.mt20 }}>
          <SelectComponent
            name="payroll-id"
            label="Payroll"
            options={
              payrolls?.map((payroll) => ({
                label: payroll.entity?.legalName ?? `PayrollID ${payroll.id}`,
                value: payroll.id,
              })) ?? []
            }
            onChange={(e) => setSelectedPayrollId(e.target.value)}
            value={selectedPayrollId}
            sx={{ my: spacing.my20 }}
          />
          <RadioGroup
            value={selectedType}
            onChange={(e) => {
              setSelectedType(e.target.value);
            }}
            sx={{ flexFlow: 'column' }}
          >
            {PayrollReportTypes.map((d, idx, arr) => (
              <React.Fragment key={d.name}>
                <FormControlLabel
                  labelPlacement="start"
                  value={d.name}
                  control={<StyledRadio />}
                  label={
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: spacing.g5 }}>
                      <Typography sx={{ ...themeFonts.caption }}>{d.name}</Typography>
                      <Typography sx={{ ...themeFonts.captionSmall, color: themeColors.Grey }}>{d.desc}</Typography>
                    </Box>
                  }
                  disabled={!paySchedules?.length}
                  sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    display: 'flex',
                    margin: 0,
                    paddingY: spacing.p15,
                    borderBottom: idx < arr.length - 1 ? borders.background : 'none',
                  }}
                />
                {d.name === 'P30' && selectedType === d.name && (
                  <SelectComponent
                    name="p30period"
                    label={polyglot.t('PayrollReportType.p30period')}
                    options={p30Periods.map((period) => ({
                      label: formatPeriodEndDate(period.slice(0, 10)),
                      value: period,
                    }))}
                    onChange={(e) => setSelectedP30Period(e.target.value)}
                    value={selectedP30Period}
                    sx={{ my: spacing.my20 }}
                  />
                )}
                {d.name === 'P32' && selectedType === d.name && (
                  <SelectComponent
                    name="taxYear"
                    label={polyglot.t('PayrollReportType.taxYear')}
                    options={taxYears.map((taxYear) => ({
                      label: taxYear.slice(-4),
                      value: taxYear,
                    }))}
                    onChange={(e) => setSelectedP32TaxYear(e.target.value)}
                    value={selectedP32TaxYear}
                    sx={{ my: spacing.my20 }}
                  />
                )}
                {d.name === 'Payslips' && selectedType === d.name && (
                  <SelectComponent
                    name="payslipsPeriod"
                    label={polyglot.t('PayrollReportType.payslipsPeriod')}
                    options={payslipPayruns.map((payslipPayrun) => ({
                      label: formatPeriodEndDate(payslipPayrun.endDate),
                      value: JSON.stringify(payslipPayrun),
                    }))}
                    onChange={(e) => setSelectedPayslipPayrun(e.target.value)}
                    value={selectedPayslipPayrun}
                    sx={{ my: spacing.my20 }}
                  />
                )}
              </React.Fragment>
            ))}
          </RadioGroup>
        </FormControl>

        {showDownloadButton && (
          <ButtonComponent
            type="button"
            name={polyglot.t('General.download')}
            style={{ marginTop: spacing.m25 }}
            sizeVariant="large"
            colorVariant="primary"
            onClick={performDownload}
            fullWidth
          >
            {polyglot.t('General.download')}
          </ButtonComponent>
        )}
      </Box>
    </Box>
  );
};
