// eslint-disable-next-line filenames/match-regex
export class LocalDate {
  private date: Date;

  /**
   * Creates a LocalDate from a string or a JS local date
   * @param initDate string or local date
   * @param options values for hour, minute, seconds and ms when date is today
   */
  constructor(
    initDate?: string | Date | number | null,
    options?: {
      h?: number;
      min?: number;
      s?: number;
      ms?: number;
    }
  ) {
    if (!initDate) {
      this.date = new Date();
      if (options?.h !== undefined && options?.h !== null) this.date.setHours(options.h);
      if (options?.min !== undefined && options?.min !== null) this.date.setMinutes(options.min);
      if (options?.s !== undefined && options?.s !== null) this.date.setSeconds(options.s);
      if (options?.ms !== undefined && options?.ms !== null) this.date.setMilliseconds(options.ms);
    } else if (typeof initDate !== 'string') {
      this.date = new Date(initDate);
    } else if (initDate.includes('T')) {
      // Creates local date from '2023-05-23T14:18:00' string format
      // new Date('2023-05-23T14:18:00') creates a date in local timezone, no need to extract y, m, d, h, min, sec from dateString
      // !! Should make sure the date string does not include the timezone
      this.date = new Date(initDate);
    } else if (LocalDate.isISOShortDate(initDate)) {
      // Creates local date from '2023-05-23' string format
      // If we use "new Date('2023-05-23')", '2023-05-23' will be considered a UTC date, and it will be converted to the local date
      // which in some cases, depending on the client's time zone, it may be a different date.
      const [y, m, d] = initDate.split('-');
      this.date = new Date(`${m}/${d}/${y}`);
    } else {
      this.date = new Date(initDate);
    }
  }

  public static isISOShortDate(value: string): boolean {
    if (typeof value !== 'string') return false;
    if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) return false;

    const [y, m, d] = value.split('-');

    const date = new Date(Number(y), Number(m) - 1, Number(d));
    if (date.getFullYear() !== Number(y)) return false;
    if (date.getMonth() !== Number(m) - 1) return false;
    if (date.getDate() !== Number(d)) return false;

    return true;
  }

  public getDate(): Date {
    return this.date;
  }

  public setDate(date: Date): void {
    this.date = date;
  }

  // Converts local date to '2023-05-23' string format
  public toDateString(): string {
    const y = this.date.getFullYear();
    const m = (100 + this.date.getMonth() + 1).toString().slice(-2);
    const d = (100 + this.date.getDate()).toString().slice(-2);
    return `${y}-${m}-${d}`;
  }

  // Converts local date to '2023-05-23T12:14:00' string format
  public toFullString(): string {
    const y = this.date.getFullYear();
    const m = (100 + this.date.getMonth() + 1).toString().slice(-2);
    const d = (100 + this.date.getDate()).toString().slice(-2);
    const time = this.date.toTimeString().split(' ')[0];
    return `${y}-${m}-${d}T${time}`;
  }

  /**
   * Converts local date to '2023-08-04 11:25:00' string format
   */
  public toDateTimeString(): string {
    const y = this.date.getFullYear();
    const m = (100 + this.date.getMonth() + 1).toString().slice(-2);
    const d = (100 + this.date.getDate()).toString().slice(-2);
    const time = this.date.toTimeString().split(' ')[0];
    return `${y}-${m}-${d} ${time}`;
  }

  // Converts local date to '12:14:00' string format
  public toTimeOnlyString(): string {
    return this.date.toTimeString().split(' ')[0];
  }

  // Converts local date to ISO FORMAT - should be used only for timestamps
  public toISOString(): string {
    return this.date.toISOString();
  }

  public getNextDateString(): string {
    const nextDate = new Date(this.date);
    nextDate.setDate(nextDate.getDate() + 1);

    const y = nextDate.getFullYear();
    const m = (100 + nextDate.getMonth() + 1).toString().slice(-2);
    const d = (100 + nextDate.getDate()).toString().slice(-2);
    return `${y}-${m}-${d}`;
  }

  public getPreviousDateString(): string {
    const prevDate = new Date(this.date);
    prevDate.setDate(prevDate.getDate() - 1);

    const y = prevDate.getFullYear();
    const m = (100 + prevDate.getMonth() + 1).toString().slice(-2);
    const d = (100 + prevDate.getDate()).toString().slice(-2);
    return `${y}-${m}-${d}`;
  }

  public toLocaleDateString(locales?: string | string[] | undefined, options?: Intl.DateTimeFormatOptions): string {
    return this.date.toLocaleDateString(locales, options);
  }

  public getFirstDayOfMonth(): LocalDate {
    const auxDate = new Date(this.date);
    auxDate.setDate(1);
    return new LocalDate(auxDate);
  }

  public getLastDayOfMonth(): LocalDate {
    const auxDate = this.getFirstDayOfMonth().getDate();
    auxDate.setMonth(auxDate.getMonth() + 1, 0);
    return new LocalDate(auxDate);
  }

  public getFirstDayOfYear(): LocalDate {
    const year = this.date.getFullYear();
    return new LocalDate(`${year}-01-01`);
  }

  public getLastDayOfYear(): LocalDate {
    const year = this.date.getFullYear();
    return new LocalDate(`${year}-12-31`);
  }

  public isDate(): boolean {
    return !Number.isNaN(this.date.getTime());
  }
}
