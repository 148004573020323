import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { OnboardingUser } from '@shared/modules/onboarding/onboarding';
import { ColumnDef } from '@tanstack/react-table';
import { usePolyglot } from '@v2/infrastructure/i18n/i8n.util';
import { generatePath, useHistory } from 'react-router-dom';

import { UserDetailsScopes } from '@/component/dashboard/userDetails/user-details.scopes';
import { GlobalContext } from '@/GlobalState';
import { ReactComponent as ActionsSmall } from '@/images/fields/ActionDots.svg';
import { ONBOARDING_USER_STATUS_ROUTE, USER_PERSONAL_TAB } from '@/lib/routes';
import { checkScopes } from '@/lib/scopes';
import { ButtonComponent } from '@/v2/components/forms/button.component';
import { DateLabelComponent } from '@/v2/components/forms/date-label.component';
import { TabFilterButtons } from '@/v2/components/tab-filter-buttons.component';
import { BasicTable } from '@/v2/components/table/basic-table.component';
import { CategoryFilters } from '@/v2/components/table/category-filters.component';
import { EmptyCell } from '@/v2/components/table/empty-cell.component';
import { TableSearch } from '@/v2/components/table/table-search.component';
import { sortString } from '@/v2/components/table/table-sorting.util';
import { UserCell } from '@/v2/components/table/user-cell.component';
import { StyledMenuComponent } from '@/v2/components/theme-components/styled-menu.component';
import { ContentWrapper } from '@/v2/feature/app-layout/features/main-content/layouts/components/content-wrapper.component';
import { TopHeader } from '@/v2/feature/app-layout/features/main-content/layouts/components/top-header.component';
import { OnboardNewEmployee } from '@/v2/feature/onboarding/components/onboard-new-employee.component';
import { filterByLifecycle } from '@/v2/feature/onboarding/onboarding.util';
import { CachedUser, useCachedUsers } from '@/v2/feature/user/context/cached-users.context';
import { UserAPI } from '@/v2/feature/user/user.api';
import { filterStringToObject } from '@/v2/feature/user/user.util';
import { OnboardingEndpoints } from '@/v2/feature/user-onboarding/by-admin/api-client/onboarding.api';
import { useApiClient } from '@/v2/infrastructure/api-client/api-client.hook';
import { useJune } from '@/v2/infrastructure/june/june.hook';
import { themeColors } from '@/v2/styles/colors.styles';
import { themeFonts } from '@/v2/styles/fonts.styles';
import { RootStyle } from '@/v2/styles/root.styles';
import { spacing } from '@/v2/styles/spacing.styles';

export const OnboardingUserTable = () => {
  const { polyglot } = usePolyglot();
  const TabFilter = [
    { name: polyglot.t('OnboardingUserTable.tabFilter.all'), value: 'all' },
    { name: polyglot.t('OnboardingUserTable.tabFilter.draft'), value: 'draft' },
    { name: polyglot.t('OnboardingUserTable.tabFilter.employee'), value: 'employee' },
    { name: polyglot.t('OnboardingUserTable.tabFilter.contractor'), value: 'contractor' },
  ] as const;
  type TabFilterValue = typeof TabFilter[number]['value'];

  const [state] = useContext(GlobalContext);
  const { user: currentUser } = state;
  const [searchInput, setSearchInput] = useState('');
  const [filterString, setFilterString] = useState('all');
  const [categoryFilterString, setCategoryFilterString] = useState<string>('lifecycle=Employed,Hired');

  const { getCachedUserById, refreshCachedUsers } = useCachedUsers({ refresh: true });
  const [isOnboardNewUserOpen, setOnboardNewUserOpen] = useState(false);
  const onboardNewUser = useRef<CachedUser>();
  const routerHistory = useHistory();
  const { trackPage } = useJune();

  const openNewUserDrawer = useCallback(
    async (userId?: number) => {
      onboardNewUser.current = userId ? getCachedUserById(userId) : undefined;
      const additionalUserInfoForOnboarding = userId
        ? await UserAPI.getUserSummaryForOnboardingById(userId, false) // personal email is no longer in cachedUser - fetch separately
        : {};
      onboardNewUser.current = onboardNewUser.current
        ? {
            ...onboardNewUser.current,
            ...additionalUserInfoForOnboarding,
          }
        : onboardNewUser.current;
      setOnboardNewUserOpen(true);
    },
    [getCachedUserById]
  );

  const { data, mutate: refreshUserOnboardingStates } = useApiClient(OnboardingEndpoints.getOnboardingUsers(), {
    suspense: false,
  });

  const onboardingUsers = useMemo(() => {
    // // initially display the users sorted by name
    const collator = new Intl.Collator(undefined, { sensitivity: 'base' });
    return data?.sort((a, b) => collator.compare(a.displayName, b.displayName));
  }, [data]);

  const filteredUsers = useMemo(() => {
    const filter = filterString as TabFilterValue;
    if ((!categoryFilterString && !searchInput && filter === 'all') || !onboardingUsers) {
      return onboardingUsers;
    }
    const lowerSearchInput = searchInput.toLocaleLowerCase();
    let onboardingFilteredUsers = onboardingUsers.filter((u) => {
      const user = getCachedUserById(u.userId);
      if (!user) return false;
      const matchesFilter = {
        all: () => true,
        draft: () => !u.template,
        employee: () => u.contractType === 'Employee',
        contractor: () => u.contractType === 'Contractor',
      }[filter]();

      return matchesFilter && `${user.firstName} ${user.lastName}`.toLocaleLowerCase().includes(lowerSearchInput);
    });

    if (categoryFilterString) {
      const filterOptions = filterStringToObject(categoryFilterString);
      for (const key of Object.keys(filterOptions)) {
        switch (key) {
          case 'lifecycle': {
            onboardingFilteredUsers = filterByLifecycle(onboardingFilteredUsers, filterOptions[key]);
            break;
          }
          default:
            break;
        }
      }
    }

    return onboardingFilteredUsers;
  }, [filterString, getCachedUserById, onboardingUsers, searchInput, categoryFilterString]);

  const columns = useMemo<ColumnDef<OnboardingUser, OnboardingUser>[]>(
    () => [
      {
        id: 'name',
        header: () => polyglot.t('OnboardingUserTable.name'),
        accessorFn: (row: OnboardingUser) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.displayName),
        maxSize: 220,
        minSize: 150,
        cell: (c) => {
          return <UserCell userId={c.getValue().userId} />;
        },
      },
      {
        id: 'type',
        header: () => polyglot.t('OnboardingUserTable.type'),
        accessorFn: (row: OnboardingUser) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.contractType),
        maxSize: 50,
        minSize: 100,
        cell: (c) => c.getValue().contractType ?? <EmptyCell />,
      },
      {
        id: 'createdby',
        header: () => polyglot.t('OnboardingUserTable.createdBy'),
        accessorFn: (row: OnboardingUser) => row,
        enableSorting: true,
        sortingFn: (a, b) =>
          sortString(a, b, (item) => getCachedUserById(item.createdBy ?? 0)?.displayName, {
            sensitivity: 'base',
          }),
        maxSize: 220,
        minSize: 150,
        cell: (c) => {
          return c.row.original.createdBy ? <UserCell userId={c.row.original.createdBy} /> : <></>;
        },
      },
      {
        id: 'startdate',
        header: () => polyglot.t('OnboardingUserTable.startdate'),
        accessorFn: (row: OnboardingUser) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.startDate),
        maxSize: 220,
        minSize: 150,
        cell: (c) => {
          const startDate = c.getValue().startDate;
          return startDate ? <DateLabelComponent date={startDate} /> : <EmptyCell />;
        },
      },
      {
        id: 'template',
        header: () => polyglot.t('OnboardingUserTable.template'),
        accessorFn: (row: OnboardingUser) => row,
        enableSorting: true,
        sortingFn: (a, b) => sortString(a, b, (item) => item.templateName),
        maxSize: 220,
        minSize: 150,
        cell: (c) => c.row.original.templateName ?? '',
      },
      {
        id: 'action-buttons',
        header: '',
        accessorFn: (row: OnboardingUser) => row,
        enableSorting: false,
        maxSize: 50,
        minSize: 100,
        cell: (c) => {
          const { userId } = c.row.original;
          const canViewProfile = checkScopes(currentUser, UserDetailsScopes.VIEW_USER_PERSONAL, { userId });
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StyledMenuComponent
                options={[
                  {
                    label: polyglot.t('OnboardingUserTable.view'),
                    handler: () => routerHistory.push(generatePath(USER_PERSONAL_TAB, { userId })),
                    disabled: !canViewProfile,
                  },
                ]}
                actionButtonDetails={{
                  type: 'iconButton',
                  colorVariant: 'secondary',
                  sizeVariant: 'small',
                  title: 'actions',
                  icon: <ActionsSmall />,
                }}
              />
            </Box>
          );
        },
      },
    ],
    [currentUser, getCachedUserById, routerHistory, polyglot]
  );

  useEffect(() => {
    trackPage('Onboarding overview page');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <TopHeader
        title={<Typography sx={{ ...themeFonts.title2, color: themeColors.DarkGrey }}>Onboarding</Typography>}
        showAction={true}
        actions={
          <ButtonComponent onClick={() => openNewUserDrawer()} sizeVariant="small" colorVariant="primary">
            {polyglot.t('OnboardingUserTable.onboard')}
          </ButtonComponent>
        }
      />
      <ContentWrapper loading={!filteredUsers} sx={{ overflow: 'hidden' }}>
        <Stack sx={{ gap: spacing.g20, height: 'inherit' }}>
          {filteredUsers && (
            <>
              <Box sx={{ display: 'flex', gap: spacing.g5 }}>
                <TabFilterButtons
                  filters={TabFilter}
                  setFilterValue={setFilterString}
                  filterValue={filterString}
                  onFilterChange={({ filterValue }) => {
                    setFilterString(filterValue);
                  }}
                />
                <CategoryFilters
                  filterTypes={{
                    lifecycle: [
                      { label: polyglot.t('OnboardingUserTable.employed'), value: 'Employed' },
                      { label: polyglot.t('OnboardingUserTable.hired'), value: 'Hired' },
                      { label: polyglot.t('OnboardingUserTable.terminated'), value: 'Terminated' },
                      { label: polyglot.t('OnboardingUserTable.leave'), value: 'Leave' },
                    ],
                  }}
                  setFilterString={setCategoryFilterString}
                  filterString={categoryFilterString}
                />

                <TableSearch
                  query={searchInput}
                  handleChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                />
              </Box>
              <BasicTable
                rowData={filteredUsers}
                columnData={columns}
                stickyHeader
                rowClick={(row) => {
                  if (!row.original.template) {
                    openNewUserDrawer(row.original.userId);
                    return;
                  }
                  routerHistory.push(generatePath(ONBOARDING_USER_STATUS_ROUTE, { userId: row.original.userId }));
                }}
              />
            </>
          )}
        </Stack>
      </ContentWrapper>

      <OnboardNewEmployee
        isOpen={isOnboardNewUserOpen}
        setIsOpen={setOnboardNewUserOpen}
        onClose={async () => {
          if (refreshUserOnboardingStates) await refreshUserOnboardingStates();
          await refreshCachedUsers();
        }}
        initialValues={onboardNewUser.current}
        onDraftUserCreated={() => setOnboardNewUserOpen(false)}
      />
    </RootStyle>
  );
};
