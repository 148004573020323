import { useMemo } from 'react';

import { Box } from '@mui/material';
import { ColumnDef, Row } from '@tanstack/react-table';
import { BasicTable } from '@v2/components/table/basic-table.component';
import { NumberCell } from '@v2/components/table/number-cell.component';
import { sortNumeric, sortString } from '@v2/components/table/table-sorting.util';
import { Typography } from '@v2/components/typography/typography.component';
import { ColumnTypeToSign } from '@v2/feature/reports/reports-advanced/reports-advanced.util';
import { formatReportValue } from '@v2/feature/reports/reports-formatting.util';
import { ReportColumnType, ReportResponse, ReportResponseEntry } from '@v2/feature/reports/reports.interface';

import { spacing } from '@/v2/styles/spacing.styles';

interface TableProps {
  readonly reportResponse: ReportResponse | null;
  readonly stickyHeader?: boolean;
}

function getDefaultLabel(
  key: string,
  label: string | undefined | null
): {
  colLabel: string;
  appliedOperator: string | null;
} {
  const tableSplit = key.split('__').join('->');
  const [colLabel, operator] = tableSplit.split('|');

  const appliedOperator = operator ? ColumnTypeToSign[operator as ReportColumnType] : null;

  if (label) return { colLabel: label, appliedOperator };

  return { colLabel, appliedOperator };
}

type TimeSinceRawType = {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
};

function sortTimeSinceReports<Type>(
  a: Row<Type>,
  b: Row<Type>,
  getValue: (entry: Type) => TimeSinceRawType | undefined
) {
  const aValue = getValue(a.original);
  const bValue = getValue(b.original);

  if (aValue?.years !== bValue?.years) return (aValue?.years ?? 0) - (bValue?.years ?? 0);
  if (aValue?.months !== bValue?.months) return (aValue?.months ?? 0) - (bValue?.months ?? 0);
  if (aValue?.days !== bValue?.days) return (aValue?.days ?? 0) - (bValue?.days ?? 0);
  if (aValue?.hours !== bValue?.hours) return (aValue?.hours ?? 0) - (bValue?.hours ?? 0);
  if (aValue?.minutes !== bValue?.minutes) return (aValue?.minutes ?? 0) - (bValue?.minutes ?? 0);
  if (aValue?.seconds !== bValue?.seconds) return (aValue?.seconds ?? 0) - (bValue?.seconds ?? 0);
  if (aValue?.milliseconds !== bValue?.milliseconds) return (aValue?.milliseconds ?? 0) - (bValue?.milliseconds ?? 0);

  return 0;
}

export const ReportTable = ({ reportResponse, stickyHeader }: TableProps) => {
  const columns = useMemo<ColumnDef<ReportResponseEntry, ReportResponseEntry>[]>(() => {
    if (!reportResponse?.data || reportResponse.data.length === 0) return [];

    const firstRow: ReportResponseEntry = reportResponse.data[0];

    const columnsList: (ColumnDef<ReportResponseEntry, ReportResponseEntry> & { order: number })[] = Object.keys(
      firstRow
    ).map((key) => ({
      id: key,
      // minSize: 100, // size configured bellow in Box sx
      // maxSize: 500,
      enableSorting: true,
      sortingFn: (a, b) =>
        firstRow[key]?.type === 'number'
          ? sortNumeric(a, b, (item) => (item[key].raw as number) ?? 0)
          : firstRow[key]?.type === 'date' &&
            typeof firstRow[key]?.raw === 'object' &&
            key.split('|')[1] === ReportColumnType.AGE
          ? sortTimeSinceReports(a, b, (item) => (item[key].raw as TimeSinceRawType) ?? undefined)
          : sortString(a, b, (item) => (item[key].raw as string) ?? ''),
      accessorFn: (row) => row,
      header: () => {
        const { colLabel, appliedOperator } = getDefaultLabel(key, reportResponse.header.labels[key]);

        return (
          <Box sx={{ display: 'flex', gap: spacing.sm }}>
            <Typography variant="caption" color="Grey">
              {colLabel}
            </Typography>
            {appliedOperator && (
              <Typography variant="caption" color="Grey">
                ({appliedOperator})
              </Typography>
            )}
          </Box>
        );
      },
      cell: ({ row: { original } }) => {
        if (!original[key] || original[key].value === null || original[key].value === undefined) return '';

        const formattedValue = formatReportValue(original[key].value, reportResponse.header?.displayFormat[key]);

        if (original[key].type === 'number')
          return (
            <Box sx={{ maxWidth: '144px' }}>
              <NumberCell value={formattedValue} cellSx={{ minWidth: '50px' }} />
            </Box>
          );

        return (
          <Box sx={{ minWidth: '100px', maxWidth: '500px' }}>
            <Typography variant="caption" sx={{ width: '100%' }}>
              {formattedValue}
            </Typography>
          </Box>
        );
      },
      order: reportResponse.header.order[key] ?? 0,
    }));

    columnsList.sort((a, b) => a.order - b.order);
    return columnsList as ColumnDef<ReportResponseEntry, ReportResponseEntry>[];
  }, [reportResponse]);

  return (
    <Box sx={{ width: '100%', maxHeight: `${window.innerHeight - 123}px` }}>
      <BasicTable<ReportResponseEntry>
        rowData={[...(reportResponse?.data ?? [])]}
        columnData={columns}
        fixedLastColumn={false}
        stickyHeader={stickyHeader}
        paginationSx={{ px: spacing.s2 }}
      />
    </Box>
  );
};
